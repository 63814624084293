export const localisation = {
    lang_af: {
        us: 'Afrikaans',
        cz: 'afrikánsky',
        pl: 'afrykański',
        hr: 'afrikaans',
        si: 'afrikaans',
        de: 'Afrikaans',
        sk: 'afrikánsky',
        es: 'afrikaans',
        ru: 'Африканский',
        rs: 'afrikans',
        ua: 'Африканська',
        no: 'afrikaans',
        it: 'afrikaans',
        se: 'afrikaans',
    },
    lang_sq: {
        us: 'Albanian',
        cz: 'albánsky',
        pl: 'albański',
        hr: 'albanski',
        si: 'albanski',
        de: 'Albanisch',
        sk: 'albánsky',
        es: 'albanés',
        ru: 'Албанский',
        rs: 'albanski',
        ua: 'Албанська',
        no: 'albansk',
        it: 'albanese',
        se: 'albanska',
    },
    lang_am: {
        us: 'Amharic',
        cz: 'amharsky',
        pl: 'amharski',
        hr: 'amharski',
        si: 'amharsko',
        de: 'Amharisch',
        sk: 'amharsky',
        es: 'amárico',
        ru: 'Амхарский',
        rs: 'amharski',
        ua: 'Амхарська',
        no: 'amharisk',
        it: 'amarico',
        se: 'amhariska',
    },
    lang_ar: {
        us: 'Arabic',
        cz: 'arabsky',
        pl: 'arabski',
        hr: 'arapski',
        si: 'arabski',
        de: 'Arabisch',
        sk: 'arabsky',
        es: 'árabe',
        ru: 'Арабский',
        rs: 'arapski',
        ua: 'Арабська',
        no: 'arabisk',
        it: 'arabo',
        se: 'arabiska',
    },
    lang_hy: {
        us: 'Armenian',
        cz: 'arménsky',
        pl: 'ormiański',
        hr: 'armenski',
        si: 'armenski',
        de: 'Armenisch',
        sk: 'arménsky',
        es: 'armenio',
        ru: 'Армянский',
        rs: 'jermenski',
        ua: 'Вірменська',
        no: 'armensk',
        it: 'armeno',
        se: 'armeniska',
    },
    lang_az: {
        us: 'Azerbaijani',
        cz: 'ázerbájdžánsky',
        pl: 'azerbejdżański',
        hr: 'azerbajdžanski',
        si: 'azerbajdžanski',
        de: 'Aserbaidschanisch',
        sk: 'azerbajdžansky',
        es: 'azerbaiyano',
        ru: 'Азербайджанский',
        rs: 'azerbejdžanski',
        ua: 'Азербайджанська',
        no: 'aserbajdsjansk',
        it: 'azero',
        se: 'azerbajdzjanska',
    },
    lang_ba: {
        us: 'Bashkir',
        cz: 'baškirsky',
        pl: 'baszkirski',
        hr: 'baškirski',
        si: 'baškirski',
        de: 'Baschkirisch',
        sk: 'baškirsky',
        es: 'baskir',
        ru: 'Башкирский',
        rs: 'baškirski',
        ua: 'Башкірська',
        no: 'basjkirsk',
        it: 'baschiro',
        se: 'basjkiriska',
    },
    lang_eu: {
        us: 'Basque',
        cz: 'baskicky',
        pl: 'baskijski',
        hr: 'baskijski',
        si: 'baskovski',
        de: 'Baskisch',
        sk: 'baskicky',
        es: 'vasco',
        ru: 'Баскский',
        rs: 'baskijski',
        ua: 'Баскська',
        no: 'baskisk',
        it: 'basco',
        se: 'baskiska',
    },
    lang_be: {
        us: 'Belarusian',
        cz: 'bělorusky',
        pl: 'białoruski',
        hr: 'bjeloruski',
        si: 'beloruski',
        de: 'Weißrussisch',
        sk: 'bielorusky',
        es: 'bielorruso',
        ru: 'Белорусский',
        rs: 'beloruski',
        ua: 'Білоруська',
        no: 'hviterussisk',
        it: 'bielorusso',
        se: 'belarusiska',
    },
    lang_bn: {
        us: 'Bengali',
        cz: 'bengálsky',
        pl: 'bengalski',
        hr: 'bengalski',
        si: 'bengalski',
        de: 'Bengalisch',
        sk: 'bengálsky',
        es: 'bengalí',
        ru: 'Бенгальский',
        rs: 'bengalski',
        ua: 'Бенгальська',
        no: 'bengali',
        it: 'bengalese',
        se: 'bengali',
    },
    lang_bi: {
        us: 'Bislama',
        cz: 'bislamsky',
        pl: 'bislama',
        hr: 'bislama',
        si: 'bislama',
        de: 'Bislama',
        sk: 'bislamsky',
        es: 'bislama',
        ru: 'Бисламский',
        rs: 'bislama',
        ua: 'Бісламська',
        no: 'bislama',
        it: 'bislama',
        se: 'bislama',
    },
    lang_bs: {
        us: 'Bosnian',
        cz: 'bosensky',
        pl: 'bośniacki',
        hr: 'bosanski',
        si: 'bosanski',
        de: 'Bosnisch',
        sk: 'bosniansky',
        es: 'bosnio',
        ru: 'Боснийский',
        rs: 'bošnjački',
        ua: 'Боснійська',
        no: 'bosnisk',
        it: 'bosniaco',
        se: 'bosniska',
    },
    lang_bg: {
        us: 'Bulgarian',
        cz: 'bulharsky',
        pl: 'bułgarski',
        hr: 'bugarski',
        si: 'bolgarski',
        de: 'bulgarisch',
        sk: 'bulharsky',
        es: 'búlgaro',
        ru: 'Болгарский',
        rs: 'bugarski',
        ua: 'Болгарська',
        no: 'bulgarsk',
        it: 'bulgaro',
        se: 'bulgariska',
    },
    lang_my: {
        us: 'Burmese',
        cz: 'barmsky',
        pl: 'birmański',
        hr: 'burmanski',
        si: 'burmanski',
        de: 'Birmanisch',
        sk: 'barmsky',
        es: 'birmano',
        ru: 'Бирманский',
        rs: 'burmanski',
        ua: 'Бірманська',
        no: 'burmesisk',
        it: 'birmano',
        se: 'burmesiska',
    },
    lang_ca: {
        us: 'Catalan',
        cz: 'katalánsky',
        pl: 'kataloński',
        hr: 'katalonski',
        si: 'katalonski',
        de: 'Katalanisch',
        sk: 'katalánsky',
        es: 'catalán',
        ru: 'Каталанский',
        rs: 'katalonski',
        ua: 'Каталонська',
        no: 'katalansk',
        it: 'catalano',
        se: 'katalanska',
    },
    lang_zh: {
        us: 'Chinese',
        cz: 'čínsky',
        pl: 'chiński',
        hr: 'kineski',
        si: 'kitajski',
        de: 'Chinesisch',
        sk: 'čínsky',
        es: 'chino',
        ru: 'Китайский',
        rs: 'kineski',
        ua: 'Китайська',
        no: 'kinesisk',
        it: 'cinese',
        se: 'kinesiska',
    },
    lang_cnr: {
        us: 'Montenegrin',
        cz: 'černohorsky',
        pl: 'czarnogórski',
        hr: 'Crnogorski',
        si: 'črnogorščina',
        de: 'Montenegrinisch',
        sk: 'čiernohorsky',
        es: 'Montenegrino',
        ru: 'черногорский',
        rs: 'Crnogorski',
        ua: 'чорногорська',
        no: 'montenegrinsk',
        it: 'Montenegrino',
        se: 'montenegrinska',
    },
    lang_co: {
        us: 'Corsican',
        cz: 'korsicky',
        pl: 'korsykański',
        hr: 'korzikanski',
        si: 'korziški',
        de: 'Korsisch',
        sk: 'korzicky',
        es: 'corso',
        ru: 'Корсиканский',
        rs: 'korzički',
        ua: 'Корсиканська',
        no: 'korsikansk',
        it: 'còrso',
        se: 'korsikanska',
    },
    lang_hr: {
        us: 'Croatian',
        cz: 'chorvatsky',
        pl: 'chorwacki',
        hr: 'hrvatski',
        si: 'hrvaški',
        de: 'Kroatisch',
        sk: 'chorvátsky',
        es: 'croata',
        ru: 'Хорватский',
        rs: 'hrvatski',
        ua: 'Хорватська',
        no: 'kroatisk',
        it: 'croato',
        se: 'kroatiska',
    },
    lang_cs: {
        us: 'Czech',
        cz: 'česky',
        pl: 'czeski',
        hr: 'češki',
        si: 'češki',
        de: 'Tschechisch',
        sk: 'česky',
        es: 'checo',
        ru: 'Чешский',
        rs: 'češki',
        ua: 'Чеська',
        no: 'tsjekkisk',
        it: 'ceco',
        se: 'tjeckiska',
    },
    lang_da: {
        us: 'Danish',
        cz: 'dánsky',
        pl: 'duński',
        hr: 'danski',
        si: 'danski',
        de: 'Dänisch',
        sk: 'dánsky',
        es: 'danés',
        ru: 'Датский',
        rs: 'danski',
        ua: 'Данська',
        no: 'dansk',
        it: 'danese',
        se: 'danska',
    },
    lang_nl: {
        us: 'Dutch',
        cz: 'holandsky',
        pl: 'holenderski',
        hr: 'nizozemski',
        si: 'nizozemski',
        de: 'Niederländisch',
        sk: 'holandsky',
        es: 'neerlandés',
        ru: 'Голландский',
        rs: 'holandski',
        ua: 'Голландська',
        no: 'nederlandsk',
        it: 'olandese',
        se: 'nederländska',
    },
    lang_en: {
        us: 'English',
        cz: 'anglicky',
        pl: 'angielski',
        hr: 'engleski',
        si: 'angleški',
        de: 'Englisch',
        sk: 'anglicky',
        es: 'inglés',
        ru: 'Английский',
        rs: 'engleski',
        ua: 'Англійська',
        no: 'engelsk',
        it: 'inglese',
        se: 'engelska',
    },
    lang_eo: {
        us: 'Esperanto',
        cz: 'esperantem',
        pl: 'esperanto',
        hr: 'esperanto',
        si: 'esperatno',
        de: 'Esperanto',
        sk: 'esperantom',
        es: 'esperanto',
        ru: 'Эсперанто',
        rs: 'esperanto',
        ua: 'Есперанто',
        no: 'esperanto',
        it: 'esperanto',
        se: 'esperanto',
    },
    lang_et: {
        us: 'Estonian',
        cz: 'estonsky',
        pl: 'estoński',
        hr: 'estonski',
        si: 'estonski',
        de: 'Estnisch',
        sk: 'estónsky',
        es: 'estonio',
        ru: 'Эстонский',
        rs: 'estonski',
        ua: 'Естонська',
        no: 'estisk',
        it: 'estone',
        se: 'estniska',
    },
    lang_fo: {
        us: 'Faroese',
        cz: 'faersky',
        pl: 'farerski',
        hr: 'farski',
        si: 'ferski',
        de: 'Färöisch',
        sk: 'faersky',
        es: 'feroés',
        ru: 'Фарерский',
        rs: 'farski',
        ua: 'Фарерська',
        no: 'færøysk',
        it: 'faroense',
        se: 'färöiska',
    },
    lang_fj: {
        us: 'Fijian',
        cz: 'fidžijsky',
        pl: 'fidżyjski',
        hr: 'fidžijski',
        si: 'fidžijski',
        de: 'Fidschianisch',
        sk: 'fidžijsky',
        es: 'fiyiano',
        ru: 'Фиджианский',
        rs: 'fidžijski',
        ua: 'Фіджійська',
        no: 'fijiansk',
        it: 'figiano',
        se: 'fijianska',
    },
    lang_fi: {
        us: 'Finnish',
        cz: 'finsky',
        pl: 'fiński',
        hr: 'finski',
        si: 'finski',
        de: 'Finnisch',
        sk: 'fínsky',
        es: 'finlandés',
        ru: 'Финский',
        rs: 'finski',
        ua: 'Фінська',
        no: 'finsk',
        it: 'finlandese',
        se: 'finska',
    },
    lang_fr: {
        us: 'French',
        cz: 'francouzsky',
        pl: 'francuski',
        hr: 'francuski',
        si: 'francoski',
        de: 'Französisch',
        sk: 'francúzsky',
        es: 'francés',
        ru: 'Французский',
        rs: 'francuski',
        ua: 'Французька',
        no: 'fransk',
        it: 'francese',
        se: 'franska',
    },
    lang_fy: {
        us: 'Western Frisian',
        cz: 'západofrízsky',
        pl: 'fryzyjski',
        hr: 'zapadnofrizijski',
        si: 'zahodno frizijski',
        de: 'Westfriesisch',
        sk: 'západofrízsky',
        es: 'frisio occidental',
        ru: 'Западнофризский',
        rs: 'zapadnofrizijski',
        ua: 'Західнофрізька',
        no: 'vestfrisisk',
        it: 'frisone occidentale',
        se: 'västfrisiska',
    },
    lang_gd: {
        us: 'Gaelic',
        cz: 'galsky',
        pl: 'gaelicki',
        hr: 'galski',
        si: 'galski',
        de: 'Gälisch',
        sk: 'galsky',
        es: 'gaélico',
        ru: 'Гэльский',
        rs: 'gaelski',
        ua: 'Гельська',
        no: 'gælisk',
        it: 'gaelico',
        se: 'gaeliska',
    },
    lang_gl: {
        us: 'Galician',
        cz: 'galícijsky',
        pl: 'galicyjski',
        hr: 'galicijski',
        si: 'galicijski',
        de: 'Galizisch',
        sk: 'galícijsky',
        es: 'gallego',
        ru: 'Галисийский',
        rs: 'galicijski',
        ua: 'Галицька',
        no: 'galisisk',
        it: 'galiziano',
        se: 'galiciska',
    },
    lang_ka: {
        us: 'Georgian',
        cz: 'gruzínsky',
        pl: 'gruziński',
        hr: 'gruzijski',
        si: 'gruzinski',
        de: 'Georgisch',
        sk: 'gruzínsky',
        es: 'georgiano',
        ru: 'Грузинский',
        rs: 'gruzijski',
        ua: 'Грузинська',
        no: 'georgisk',
        it: 'georgiano',
        se: 'georgiska',
    },
    lang_de: {
        us: 'German',
        cz: 'německy',
        pl: 'niemiecki',
        hr: 'njemački',
        si: 'nemški',
        de: 'Deutsch',
        sk: 'nemecky',
        es: 'alemán',
        ru: 'Немецкий',
        rs: 'nemački',
        ua: 'Німецька',
        no: 'tysk',
        it: 'tedesco',
        se: 'tyska',
    },
    lang_el: {
        us: 'Greek',
        cz: 'řecky',
        pl: 'grecki',
        hr: 'grčki',
        si: 'grški',
        de: 'Griechisch',
        sk: 'grécky',
        es: 'griego',
        ru: 'Греческий',
        rs: 'grčki',
        ua: 'Грецька',
        no: 'gresk',
        it: 'greco',
        se: 'grekiska',
    },
    lang_kl: {
        us: 'Greenlandic',
        cz: 'grónsky',
        pl: 'grenlandzki',
        hr: 'grenlandski',
        si: 'grenlandski',
        de: 'Grönländisch',
        sk: 'grónsky',
        es: 'groenlandés',
        ru: 'Гренландский',
        rs: 'grenlandski',
        ua: 'Гренландська',
        no: 'grønlandsk',
        it: 'groenlandese',
        se: 'grönländska',
    },
    lang_gn: {
        us: 'Guarani',
        cz: 'guaraní',
        pl: 'guarani',
        hr: 'guarani',
        si: 'gvaranski',
        de: 'Guarani',
        sk: 'guaraní',
        es: 'guaraní',
        ru: 'Гуарани',
        rs: 'gvarani',
        ua: 'Гуарані',
        no: 'guarani',
        it: 'guarani',
        se: 'guaraní',
    },
    lang_gu: {
        us: 'Gujarati',
        cz: 'gudžarátsky',
        pl: 'gudźaracki',
        hr: 'gudžaratski',
        si: 'gudžaratski',
        de: 'Gujarati',
        sk: 'gudžarátsky',
        es: 'gujarati',
        ru: 'Гуджарати',
        rs: 'gudžarati',
        ua: 'Гуджараті',
        no: 'gujarati',
        it: 'gujarati',
        se: 'gujarati',
    },
    lang_ht: {
        us: 'Haitian',
        cz: 'haitsky',
        pl: 'haitański',
        hr: 'haićanski',
        si: 'haitijski',
        de: 'Haitianisch',
        sk: 'haitsky',
        es: 'haitiano',
        ru: 'Гаитянский',
        rs: 'haićanski',
        ua: 'Гаїтянська',
        no: 'haitisk',
        it: 'haitiano',
        se: 'haitiska',
    },
    lang_ha: {
        us: 'Hausa',
        cz: 'hausky',
        pl: 'hausa',
        hr: 'hausa',
        si: 'hausa',
        de: 'Hausa',
        sk: 'hausky',
        es: 'hawaiano',
        ru: 'Хауса',
        rs: 'hausa',
        ua: 'Хаусо',
        no: 'hausa',
        it: 'hausa',
        se: 'hausa',
    },
    lang_he: {
        us: 'Hebrew',
        cz: 'hebrejsky',
        pl: 'hebrajski',
        hr: 'hebrejski',
        si: 'hebresjki',
        de: 'Hebräisch',
        sk: 'hebrejsky',
        es: 'hebreo',
        ru: 'Иврит',
        rs: 'hebrejski',
        ua: 'Іврит',
        no: 'hebraisk',
        it: 'ebraico',
        se: 'hebreiska',
    },
    lang_hi: {
        us: 'Hindi',
        cz: 'hindsky',
        pl: 'hindi',
        hr: 'hindski',
        si: 'hindijski',
        de: 'Hindi',
        sk: 'hindsky',
        es: 'hindi',
        ru: 'Хинди',
        rs: 'hindi',
        ua: 'Гінді',
        no: 'hindi',
        it: 'hindi',
        se: 'hindi',
    },
    lang_hu: {
        us: 'Hungarian',
        cz: 'maďarsky',
        pl: 'węgierski',
        hr: 'mađarski',
        si: 'madžarski',
        de: 'Ungarisch',
        sk: 'maďarsky',
        es: 'húngaro',
        ru: 'Венгерский',
        rs: 'mađarski',
        ua: 'Угорська',
        no: 'ungarsk',
        it: 'ungherese',
        se: 'ungerska',
    },
    lang_is: {
        us: 'Icelandic',
        cz: 'islandsky',
        pl: 'islandzki',
        hr: 'islandski',
        si: 'islandski',
        de: 'Isländisch',
        sk: 'islandsky',
        es: 'islandés',
        ru: 'Исландский',
        rs: 'islandski',
        ua: 'Ісландська',
        no: 'islandsk',
        it: 'islandese',
        se: 'isländska',
    },
    lang_id: {
        us: 'Indonesian',
        cz: 'indonézsky',
        pl: 'indonezyjski',
        hr: 'indonezijski',
        si: 'indonezijski',
        de: 'Indonesisch',
        sk: 'indonézsky',
        es: 'indonesio',
        ru: 'Индонезийский',
        rs: 'indonežanski',
        ua: 'Індонезійська',
        no: 'indonesisk',
        it: 'indonesiano',
        se: 'indonesiska',
    },
    lang_iu: {
        us: 'Inuktitut',
        cz: 'inuktitutsky',
        pl: 'inuktitut',
        hr: 'inuktitut',
        si: 'inuktitut',
        de: 'Inuktitut',
        sk: 'inuktitsky',
        es: 'inuktitut',
        ru: 'Инуктитутский',
        rs: 'inuktitut',
        ua: 'Інуктітутська',
        no: 'inuktitut',
        it: 'inuktitut',
        se: 'inuktitut',
    },
    lang_ga: {
        us: 'Irish',
        cz: 'irsky',
        pl: 'irlandzki',
        hr: 'irski',
        si: 'irski',
        de: 'Irisch',
        sk: 'írsky',
        es: 'irlandés',
        ru: 'Ирландский',
        rs: 'irski',
        ua: 'Ірландська',
        no: 'irsk',
        it: 'irlandese',
        se: 'irländska',
    },
    lang_it: {
        us: 'Italian',
        cz: 'italsky',
        pl: 'włoski',
        hr: 'talijanski',
        si: 'italijanski',
        de: 'Italienisch',
        sk: 'taliansky',
        es: 'italiano',
        ru: 'Итальянский',
        rs: 'italijanski',
        ua: 'Італійська',
        no: 'italiensk',
        it: 'italiano',
        se: 'italienska',
    },
    lang_ja: {
        us: 'Japanese',
        cz: 'japonsky',
        pl: 'japoński',
        hr: 'japanski',
        si: 'japonski',
        de: 'Japanisch',
        sk: 'japonsky',
        es: 'japonés',
        ru: 'Японский',
        rs: 'japanski',
        ua: 'Японська',
        no: 'japansk',
        it: 'giapponese',
        se: 'japanska',
    },
    lang_jv: {
        us: 'Javanese',
        cz: 'jávsky',
        pl: 'jawajski',
        hr: 'javanski',
        si: 'javanski',
        de: 'Javanisch',
        sk: 'jávsky',
        es: 'javanés',
        ru: 'Яванский',
        rs: 'javanski',
        ua: 'Яванська',
        no: 'javanesisk',
        it: 'giavanese',
        se: 'javanesiska',
    },
    lang_ks: {
        us: 'Kashmiri',
        cz: 'kašmírsky',
        pl: 'kaszmirski',
        hr: 'kašmirski',
        si: 'kašmirski',
        de: 'Kaschmiri',
        sk: 'kašmírsky',
        es: 'cachemir',
        ru: 'Кашмирский',
        rs: 'kašmirski',
        ua: 'Кашмірська',
        no: 'kasjmiri',
        it: 'kashmiri',
        se: 'kashmiri',
    },
    lang_kk: {
        us: 'Kazakh',
        cz: 'kazašsky',
        pl: 'kazachski',
        hr: 'kazaški',
        si: 'kazahstanski',
        de: 'Kasachisch',
        sk: 'kazašsky',
        es: 'kazajo',
        ru: 'Казахский',
        rs: 'kazaški',
        ua: 'Казахська',
        no: 'kasakhisk',
        it: 'kazako',
        se: 'kazakiska',
    },
    lang_km: {
        us: 'Khmer',
        cz: 'khmersky',
        pl: 'khmerski',
        hr: 'kmerski',
        si: 'kmerski',
        de: 'Khmer',
        sk: 'khmérsky',
        es: 'jemer',
        ru: 'Центральнокхмерский',
        rs: 'kmerski',
        ua: 'Центральнокхмерська',
        no: 'khmer',
        it: 'khmer',
        se: 'khmer',
    },
    lang_ky: {
        us: 'Kyrgyz',
        cz: 'kyrgyzsky',
        pl: 'kirgiski',
        hr: 'kirgiski',
        si: 'kirgiški',
        de: 'Kirgisisch',
        sk: 'kirgizsky',
        es: 'kirguís',
        ru: 'Киргизский',
        rs: 'kirgiski',
        ua: 'Киргизька',
        no: 'kirgisisk',
        it: 'kirghizo',
        se: 'kirgiziska',
    },
    lang_kg: {
        us: 'Kongo',
        cz: 'konžsky',
        pl: 'kongo',
        hr: 'kongo',
        si: 'kongo',
        de: 'Kongo',
        sk: 'konžsky',
        es: 'kongo',
        ru: 'Конго',
        rs: 'kongoanski',
        ua: 'Конґська',
        no: 'kongolesisk',
        it: 'kongo',
        se: 'kongo',
    },
    lang_ko: {
        us: 'Korean',
        cz: 'korejsky',
        pl: 'koreański',
        hr: 'korejski',
        si: 'korejski',
        de: 'Koreanisch',
        sk: 'kórejsky',
        es: 'coreano',
        ru: 'Корейский',
        rs: 'korejski',
        ua: 'Корейська',
        no: 'koreansk',
        it: 'coreano',
        se: 'koreanska',
    },
    lang_ku: {
        us: 'Kurdish',
        cz: 'kurdsky',
        pl: 'kurdyski',
        hr: 'kurdski',
        si: 'kurdski',
        de: 'Kurdisch',
        sk: 'kurdsky',
        es: 'kurdo',
        ru: 'Курдский',
        rs: 'kurdski',
        ua: 'Курдська',
        no: 'kurdisk',
        it: 'curdo',
        se: 'kurdiska',
    },
    lang_lo: {
        us: 'Lao',
        cz: 'laosky',
        pl: 'laotański',
        hr: 'laoski',
        si: 'laoški',
        de: 'Laotisch',
        sk: 'laosky',
        es: 'lao',
        ru: 'Лаосский',
        rs: 'laoski',
        ua: 'Лаоська',
        no: 'laotisk',
        it: 'lao',
        se: 'laotiska',
    },
    lang_la: {
        us: 'Latin',
        cz: 'latinsky',
        pl: 'łaciński',
        hr: 'latinski',
        si: 'latinski',
        de: 'Latein',
        sk: 'latinsky',
        es: 'latín',
        ru: 'Латинский',
        rs: 'latinski',
        ua: 'Латинська',
        no: 'latin',
        it: 'latino',
        se: 'latin',
    },
    lang_lv: {
        us: 'Latvian',
        cz: 'lotyšsky',
        pl: 'łotewski',
        hr: 'latvijski',
        si: 'latvijski',
        de: 'Lettisch',
        sk: 'lotyšsky',
        es: 'letón',
        ru: 'Латышский',
        rs: 'letonski',
        ua: 'Латиська',
        no: 'latvisk',
        it: 'lettone',
        se: 'lettiska',
    },
    lang_lt: {
        us: 'Lithuanian',
        cz: 'litevsky',
        pl: 'litewski',
        hr: 'litavski',
        si: 'litovski',
        de: 'Litauisch',
        sk: 'litovsky',
        es: 'lituano',
        ru: 'Литовский',
        rs: 'litvanski',
        ua: 'Литовська',
        no: 'litauisk',
        it: 'lituano',
        se: 'litauiska',
    },
    lang_lb: {
        us: 'Luxembourgish',
        cz: 'lucembursky',
        pl: 'luksemburski',
        hr: 'luksemburški',
        si: 'luksemburški',
        de: 'Luxemburgisch',
        sk: 'luxembursky',
        es: 'luxemburgués',
        ru: 'Люксембургский',
        rs: 'luksemburški',
        ua: 'Люксембурзька',
        no: 'luxembourgsk',
        it: 'lussemburghese',
        se: 'luxemburgiska',
    },
    lang_mk: {
        us: 'Macedonian',
        cz: 'makedonsky',
        pl: 'macedoński',
        hr: 'makedonski',
        si: 'makedonski',
        de: 'Mazedonisch',
        sk: 'macedónsky',
        es: 'macedonio',
        ru: 'Македонский',
        rs: 'makedonski',
        ua: 'Македонська',
        no: 'makedonsk',
        it: 'macedone',
        se: 'makedonska',
    },
    lang_ms: {
        us: 'Malay',
        cz: 'malajsky',
        pl: 'malajski',
        hr: 'malezijski',
        si: 'malajski',
        de: 'Malaiisch',
        sk: 'malajsky',
        es: 'malayo',
        ru: 'Малайский',
        rs: 'malajski',
        ua: 'Малайська',
        no: 'malaysisk',
        it: 'malese',
        se: 'malajiska',
    },
    lang_ml: {
        us: 'Malayalam',
        cz: 'malajálamsky',
        pl: 'malajalam',
        hr: 'malajalamski',
        si: 'malajalamski',
        de: 'Malayalam',
        sk: 'malájalamsky',
        es: 'malayo',
        ru: 'Малаяламский',
        rs: 'malajalam',
        ua: 'Малаяламомська',
        no: 'malayalam',
        it: 'malayalam',
        se: 'malayalam',
    },
    lang_mt: {
        us: 'Maltese',
        cz: 'maltsky',
        pl: 'maltański',
        hr: 'malteški',
        si: 'malteški',
        de: 'Maltesisch',
        sk: 'maltsky',
        es: 'maltés',
        ru: 'Мальтийский',
        rs: 'malteški',
        ua: 'Мальтійська',
        no: 'maltesisk',
        it: 'maltese',
        se: 'maltesiska',
    },
    lang_gv: {
        us: 'Manx',
        cz: 'mansky',
        pl: 'manx',
        hr: 'manski',
        si: 'manski',
        de: 'Manx',
        sk: 'mansky',
        es: 'manés',
        ru: 'Мэнский',
        rs: 'manks',
        ua: 'Менська',
        no: 'manx',
        it: 'manx',
        se: 'manx',
    },
    lang_mi: {
        us: 'Maori',
        cz: 'maorsky',
        pl: 'maoryski',
        hr: 'maorski',
        si: 'maorski',
        de: 'Maori',
        sk: 'maorsky',
        es: 'maorí',
        ru: 'Маори',
        rs: 'maorski',
        ua: 'Маорі',
        no: 'maorisk',
        it: 'maori',
        se: 'maori',
    },
    lang_mn: {
        us: 'Mongolian',
        cz: 'mongolsky',
        pl: 'mongolski',
        hr: 'mongolski',
        si: 'mongolski',
        de: 'Mongolisch',
        sk: 'mongolsky',
        es: 'mongol',
        ru: 'Монгольский',
        rs: 'mongolski',
        ua: 'Монгольська',
        no: 'mongolsk',
        it: 'mongolo',
        se: 'mongoliska',
    },
    lang_na: {
        us: 'Nauru',
        cz: 'naursky',
        pl: 'nauruański',
        hr: 'nauruanski',
        si: 'naurujski',
        de: 'Nauru',
        sk: 'naursky',
        es: 'Nauru',
        ru: 'Науру',
        rs: 'nauruanski',
        ua: 'Науру',
        no: 'nauruansk',
        it: 'nauruano',
        se: 'nauruanska',
    },
    lang_nv: {
        us: 'Navajo',
        cz: 'navažsky',
        pl: 'nawaho',
        hr: 'navaho',
        si: 'navaho',
        de: 'Navajo',
        sk: 'navžsky',
        es: 'navajo',
        ru: 'Навахо',
        rs: 'navaho',
        ua: 'Навахо',
        no: 'navajo',
        it: 'navajo',
        se: 'navajo',
    },
    lang_ne: {
        us: 'Nepali',
        cz: 'nepálsky',
        pl: 'nepalski',
        hr: 'nepalski',
        si: 'nepalski',
        de: 'Nepali',
        sk: 'nepálsky',
        es: 'nepalí',
        ru: 'Непальский',
        rs: 'nepalski',
        ua: 'Непальська',
        no: 'nepali',
        it: 'nepalese',
        se: 'nepali',
    },
    lang_nb: {
        us: 'Norwegian',
        cz: 'norsky',
        pl: 'norweski',
        hr: 'norveški',
        si: 'norveški',
        de: 'Norwegisch',
        sk: 'nórsky',
        es: 'noruego',
        ru: 'Норвежский',
        rs: 'norveški',
        ua: 'Норвезька',
        no: 'norsk',
        it: 'norvegese',
        se: 'norska',
    },
    lang_nn: {
        us: 'Norwegian',
        cz: 'norsky',
        pl: 'norweski',
        hr: 'norveški',
        si: 'norveški',
        de: 'Norwegisch',
        sk: 'nórsky',
        es: 'noruego',
        ru: 'Норвежский',
        rs: 'norveški',
        ua: 'Норвезька',
        no: 'norsk',
        it: 'norvegese',
        se: 'norska',
    },
    lang_no: {
        us: 'Norwegian',
        cz: 'norsky',
        pl: 'norweski',
        hr: 'norveški',
        si: 'norveški',
        de: 'Norwegisch',
        sk: 'nórsky',
        es: 'noruego',
        ru: 'Норвежский',
        rs: 'norveški',
        ua: 'Норвезька',
        no: 'norsk',
        it: 'norvegese',
        se: 'norska',
    },
    lang_fa: {
        us: 'Persian',
        cz: 'persky',
        pl: 'perski',
        hr: 'perzijski',
        si: 'perzijski',
        de: 'Persisch',
        sk: 'perzsky',
        es: 'persa',
        ru: 'Персидский',
        rs: 'persijski',
        ua: 'Перська',
        no: 'persisk',
        it: 'persiano',
        se: 'persiska',
    },
    lang_pl: {
        us: 'Polish',
        cz: 'polsky',
        pl: 'polski',
        hr: 'poljski',
        si: 'poljski',
        de: 'Polnisch',
        sk: 'poľsky',
        es: 'polaco',
        ru: 'Польский',
        rs: 'poljski',
        ua: 'Польська',
        no: 'polsk',
        it: 'polacco',
        se: 'polska',
    },
    lang_pt: {
        us: 'Portuguese',
        cz: 'portugalsky',
        pl: 'portugalski',
        hr: 'portugalski',
        si: 'portugalski',
        de: 'Portugiesisch',
        sk: 'portugalsky',
        es: 'portugués',
        ru: 'Португальский',
        rs: 'portugalski',
        ua: 'Португальська',
        no: 'portugisisk',
        it: 'portoghese',
        se: 'portugisiska',
    },
    lang_pa: {
        us: 'Punjabi',
        cz: 'pandžábsky',
        pl: 'pendżabski',
        hr: 'pandžapski',
        si: 'pandžabski',
        de: 'Punjabi',
        sk: 'pandžábsky',
        es: 'panyabí',
        ru: 'Панджабийский',
        rs: 'pandžabi',
        ua: 'Панджабійська',
        no: 'panjabi',
        it: 'punjabi',
        se: 'punjabi',
    },
    lang_qu: {
        us: 'Quechua',
        cz: 'kečuánsky',
        pl: 'keczua',
        hr: 'kečuanski',
        si: 'kečuanski',
        de: 'Quechua',
        sk: 'kečuánsky',
        es: 'quechua',
        ru: 'Кечуа',
        rs: 'kečua',
        ua: 'Кечуа',
        no: 'quechua',
        it: 'quechua',
        se: 'quechua',
    },
    lang_ro: {
        us: 'Romanian',
        cz: 'rumunsky',
        pl: 'rumuński',
        hr: 'rumunjski',
        si: 'romunski',
        de: 'Rumänisch',
        sk: 'rumunsky',
        es: 'rumano',
        ru: 'Румынский',
        rs: 'rumunski',
        ua: 'Румунська',
        no: 'rumensk',
        it: 'rumeno',
        se: 'rumänska',
    },
    lang_ru: {
        us: 'Russian',
        cz: 'rusky',
        pl: 'rosyjski',
        hr: 'ruski',
        si: 'ruski',
        de: 'Russisch',
        sk: 'rusky',
        es: 'ruso',
        ru: 'Русский',
        rs: 'ruski',
        ua: 'Російська',
        no: 'russisk',
        it: 'russo',
        se: 'ryska',
    },
    lang_sc: {
        us: 'Sardinian',
        cz: 'sardinsky',
        pl: 'sardyński',
        hr: 'sardski',
        si: 'sardski',
        de: 'Sardisch',
        sk: 'sardínsky',
        es: 'sardo',
        ru: 'Сардинский',
        rs: 'sardinijski',
        ua: 'Сардинська',
        no: 'sardinsk',
        it: 'sardo',
        se: 'sardiska',
    },
    lang_sr: {
        us: 'Serbian',
        cz: 'srbsky',
        pl: 'serbski',
        hr: 'srpski',
        si: 'srbski',
        de: 'Serbisch',
        sk: 'srbsky',
        es: 'serbio',
        ru: 'Сербский',
        rs: 'srpski',
        ua: 'Сербська',
        no: 'serbisk',
        it: 'serbo',
        se: 'serbiska',
    },
    lang_sk: {
        us: 'Slovak',
        cz: 'slovensky',
        pl: 'słowacki',
        hr: 'slovački',
        si: 'slovaški',
        de: 'Slowakisch',
        sk: 'slovensky',
        es: 'eslovaco',
        ru: 'Словацкий',
        rs: 'slovački',
        ua: 'Словацька',
        no: 'slovakisk',
        it: 'slovacco',
        se: 'slovakiska',
    },
    lang_sl: {
        us: 'Slovenian',
        cz: 'slovinsky',
        pl: 'słoweński',
        hr: 'slovenski',
        si: 'slovenski',
        de: 'Slowenisch',
        sk: 'slovinsky',
        es: 'esloveno',
        ru: 'Словенский',
        rs: 'slovački',
        ua: 'Словенська',
        no: 'slovensk',
        it: 'sloveno',
        se: 'slovenska',
    },
    lang_so: {
        us: 'Somali',
        cz: 'somálsky',
        pl: 'somalijski',
        hr: 'somalski',
        si: 'somalski',
        de: 'Somalisch',
        sk: 'somálsky',
        es: 'somalí',
        ru: 'Сомалийский',
        rs: 'somalski',
        ua: 'Сомалійська',
        no: 'somali',
        it: 'somalo',
        se: 'somaliska',
    },
    lang_es: {
        us: 'Spanish',
        cz: 'španělsky',
        pl: 'hiszpański',
        hr: 'španjolski',
        si: 'španski',
        de: 'Spanisch',
        sk: 'španielsky',
        es: 'español',
        ru: 'Испанский',
        rs: 'španski',
        ua: 'Іспанська',
        no: 'spansk',
        it: 'spagnolo',
        se: 'spanska',
    },
    lang_sw: {
        us: 'Swahili',
        cz: 'svahilsky',
        pl: 'suahili',
        hr: 'svahili',
        si: 'svahili',
        de: 'Swahili',
        sk: 'svahilsky',
        es: 'suajili',
        ru: 'Суахили',
        rs: 'svahili',
        ua: 'Суахілі',
        no: 'swahili',
        it: 'swahili',
        se: 'swahili',
    },
    lang_sv: {
        us: 'Swedish',
        cz: 'švédsky',
        pl: 'szwedzki',
        hr: 'švedski',
        si: 'švedski',
        de: 'Schwedisch',
        sk: 'švédsky',
        es: 'sueco',
        ru: 'Шведский',
        rs: 'švedski',
        ua: 'Шведська',
        no: 'svensk',
        it: 'svedese',
        se: 'svenska',
    },
    lang_tl: {
        us: 'Tagalog',
        cz: 'tagalog',
        pl: 'tagalski',
        hr: 'tagaloški',
        si: 'tagaloški',
        de: 'Tagalog',
        sk: 'tagalogom',
        es: 'tagalo',
        ru: 'Тагальский',
        rs: 'tagalog',
        ua: 'Тагальська',
        no: 'tagalog',
        it: 'tagalog',
        se: 'tagalog',
    },
    lang_ty: {
        us: 'Tahitian',
        cz: 'tahitsky',
        pl: 'tahitański',
        hr: 'tahitski',
        si: 'tahitski',
        de: 'Tahitianisch',
        sk: 'tahitsky',
        es: 'tahitiano',
        ru: 'Таитянский',
        rs: 'tahićanski',
        ua: 'Таїтянська',
        no: 'tahitisk',
        it: 'tahitiano',
        se: 'tahitiska',
    },
    lang_tg: {
        us: 'Tajik',
        cz: 'tádžicky',
        pl: 'tadżycki',
        hr: 'tadžički',
        si: 'tadžiški',
        de: 'Tadschikisch',
        sk: 'tadžicky',
        es: 'tayiko',
        ru: 'Таджикский',
        rs: 'tadžički',
        ua: 'Таджицька',
        no: 'tadsjikisk',
        it: 'tagico',
        se: 'tadzjikiska',
    },
    lang_ta: {
        us: 'Tamil',
        cz: 'tamilsky',
        pl: 'tamilski',
        hr: 'tamilski',
        si: 'tamilski',
        de: 'Tamilisch',
        sk: 'tamilsky',
        es: 'tamil',
        ru: 'Тамильский',
        rs: 'tamilski',
        ua: 'Тамільська',
        no: 'tamil',
        it: 'tamil',
        se: 'tamil',
    },
    lang_tt: {
        us: 'Tatar',
        cz: 'tatarsky',
        pl: 'tatarski',
        hr: 'tatarski',
        si: 'tatarski',
        de: 'Tatarisch',
        sk: 'tatársky',
        es: 'tártaro',
        ru: 'Татарский',
        rs: 'tatarski',
        ua: 'Татарська',
        no: 'tatarisk',
        it: 'tataro',
        se: 'tatariska',
    },
    lang_te: {
        us: 'Telugu',
        cz: 'telužsky',
        pl: 'telugu',
        hr: 'teluški',
        si: 'teluški',
        de: 'Telugu',
        sk: 'telugsky',
        es: 'telugu',
        ru: 'Телугу',
        rs: 'telugu',
        ua: 'Телугу',
        no: 'telugu',
        it: 'telugu',
        se: 'telugu',
    },
    lang_th: {
        us: 'Thai',
        cz: 'thajsky',
        pl: 'tajski',
        hr: 'tajlandski',
        si: 'tajski',
        de: 'Thailändisch',
        sk: 'thajsky',
        es: 'tailandés',
        ru: 'Тайский',
        rs: 'tajlandski',
        ua: 'Тайська',
        no: 'thai',
        it: 'tailandese',
        se: 'thailändska',
    },
    lang_bo: {
        us: 'Tibetan',
        cz: 'tibetsky',
        pl: 'tybetański',
        hr: 'tibetski',
        si: 'tibetski',
        de: 'Tibetisch',
        sk: 'tibetsky',
        es: 'tibetano',
        ru: 'Тибетский',
        rs: 'tibetski',
        ua: 'Тибетська',
        no: 'tibetansk',
        it: 'tibetano',
        se: 'tibetanska',
    },
    lang_to: {
        us: 'Tonga',
        cz: 'tonžsky',
        pl: 'tonga',
        hr: 'tonganski',
        si: 'tonganski',
        de: 'Tonga',
        sk: 'tongsky',
        es: 'tonga',
        ru: 'Тонганский',
        rs: 'tonganski',
        ua: 'Тонганська',
        no: 'tongansk',
        it: 'tongano',
        se: 'tonga',
    },
    lang_tr: {
        us: 'Turkish',
        cz: 'turecky',
        pl: 'turecki',
        hr: 'turski',
        si: 'turški',
        de: 'Türkisch',
        sk: 'turecky',
        es: 'turco',
        ru: 'Турецкий',
        rs: 'turski',
        ua: 'Турецька',
        no: 'tyrkisk',
        it: 'turco',
        se: 'turkiska',
    },
    lang_tk: {
        us: 'Turkmen',
        cz: 'turkmensky',
        pl: 'turkmeński',
        hr: 'turkmenski',
        si: 'turkmenski',
        de: 'Turkmenisch',
        sk: 'turkménsky',
        es: 'turcomano',
        ru: 'Туркменский',
        rs: 'turkmenski',
        ua: 'Туркменська',
        no: 'turkmenisk',
        it: 'turkmeno',
        se: 'turkmeniska',
    },
    lang_ug: {
        us: 'Uighur',
        cz: 'ujgursky',
        pl: 'ujgurski',
        hr: 'ujgurski',
        si: 'ujgurski',
        de: 'Uigurisch',
        sk: 'ujgursky',
        es: 'uigur',
        ru: 'Уйгурский',
        rs: 'ujgurski',
        ua: 'Уйгурська',
        no: 'uigurisk',
        it: 'uiguro',
        se: 'uiguriska',
    },
    lang_uk: {
        us: 'Ukrainian',
        cz: 'ukrajinsky',
        pl: 'ukraiński',
        hr: 'ukrajinski',
        si: 'ukrajinski',
        de: 'Ukrainisch',
        sk: 'ukrajinsky',
        es: 'ucraniano',
        ru: 'Украинский',
        rs: 'ukrajinski',
        ua: 'Українська',
        no: 'ukrainsk',
        it: 'ucraino',
        se: 'ukrainska',
    },
    lang_ur: {
        us: 'Urdu',
        cz: 'urdsky',
        pl: 'urdu',
        hr: 'urdu',
        si: 'urdski',
        de: 'Urdu',
        sk: 'urdsky',
        es: 'urdu',
        ru: 'Урду',
        rs: 'urdu',
        ua: 'Урду',
        no: 'urdu',
        it: 'urdu',
        se: 'urdu',
    },
    lang_uz: {
        us: 'Uzbek',
        cz: 'uzbecky',
        pl: 'uzbecki',
        hr: 'uzbečki',
        si: 'uzbeški',
        de: 'Usbekisch',
        sk: 'uzbecky',
        es: 'uzbeko',
        ru: 'Узбекский',
        rs: 'uzbekistanski',
        ua: 'Узбецька',
        no: 'usbekisk',
        it: 'uzbeko',
        se: 'uzbekiska',
    },
    lang_vi: {
        us: 'Vietnamese',
        cz: 'vietnamsky',
        pl: 'wietnamski',
        hr: 'vijetnamski',
        si: 'vietnamski',
        de: 'Vietnamesisch',
        sk: 'vietnamsky',
        es: 'vietnamita',
        ru: 'Вьетнамский',
        rs: 'vijetnamski',
        ua: 'Вєтнамська',
        no: 'vietnamesisk',
        it: 'vietnamita',
        se: 'vietnamesiska',
    },
    lang_cy: {
        us: 'Welsh',
        cz: 'velšsky',
        pl: 'walijski',
        hr: 'velški',
        si: 'valižanski',
        de: 'Walisisch',
        sk: 'walesky',
        es: 'galés',
        ru: 'Валлийский',
        rs: 'velški',
        ua: 'Валлійська',
        no: 'walisisk',
        it: 'gallese',
        se: 'kymriska',
    },
    lang_yi: {
        us: 'Yiddish',
        cz: 'jidiš',
        pl: 'jidysz',
        hr: 'jidiš',
        si: 'jidiš',
        de: 'Jiddisch',
        sk: 'jidiš',
        es: 'yidis',
        ru: 'Идиш',
        rs: 'jidiš',
        ua: 'Ідиш',
        no: 'jiddisk',
        it: 'yiddish',
        se: 'jiddisch',
    },
    version: {
        us: 'Version',
        cz: 'Verze',
        pl: 'Wersja',
        hr: 'Verzija',
        si: 'Verzija',
        de: 'Version',
        sk: 'Verzia',
        es: 'Versión',
        ru: 'Версия',
        rs: 'Verzija',
        ua: 'Версія',
        no: 'Versjon',
        it: 'Versione',
        se: 'Version',
    },
    payment: {
        us: 'Payment',
        cz: 'Platba',
        pl: 'Płatność',
        hr: 'Plaćanje',
        si: 'Plačilo',
        de: 'Zahlung',
        sk: 'Platba',
        es: 'Pago',
        ru: 'Оплата',
        rs: 'Plaćanje',
        ua: 'Оплата',
        no: 'Betaling',
        it: 'Pagamento',
        se: 'Betalning',
    },
    continue: {
        us: 'Continue',
        cz: 'Pokračovat',
        pl: 'Kontynuuj',
        hr: 'Nastavi',
        si: 'Nadaljuj',
        de: 'Weiter',
        sk: 'Pokračovať na',
        es: 'Seguir',
        ru: 'Продолжить',
        rs: 'Nastavi',
        ua: 'Продовжити',
        no: 'Fortsette',
        it: 'Continuare',
        se: 'Fortsätt',
    },
    paymentFailed: {
        us: 'Payment was unsuccessful',
        cz: 'Platba se nezdařila',
        pl: 'Płatność nie powiodła się',
        hr: 'Plaćanje nije uspjelo',
        si: 'Plačilo ni uspelo',
        de: 'Zahlung fehlgeschlagen',
        sk: 'Platba sa nepodarila',
        es: 'Pago fallido',
        ru: 'Оплата не прошла',
        rs: 'Plaćanje nije uspelo',
        ua: 'Оплата не пройшла',
        no: 'Betalingen mislykkes',
        it: 'Pagamento non riuscito',
        se: 'Betalningen misslyckades',
    },
    paymentSuccess: {
        us: 'Payment was successful',
        cz: 'Platba proběhla v pořádku',
        pl: 'Płatność się powiodła',
        hr: 'Plaćanje je uspješno',
        si: 'Plačilo je uspešno',
        de: 'Zahlung erfolgreich',
        sk: 'Platba prebehla v poriadku',
        es: 'Pago exitoso',
        ru: 'Оплата прошла успешно',
        rs: 'Plaćanje uspelo',
        ua: 'Оплата пройшла успішно',
        no: 'Betalingen var vellykket',
        it: 'Pagamento riuscito',
        se: 'Betalningen godkänns',
    },
    automaticRedirect: {
        us: 'You will be automatically redirected to Beey in',
        cz: 'Budete automaticky přesměrováni do Beey za',
        pl: 'Zostaniesz automatycznie przekierowany do Beey za',
        hr: 'Automatski ćete biti preusmjereni na Beey za',
        si: 'Avtomatski boste preusmerjeni na Beey v',
        de: 'Sie werden automatisch zu Beey weitergeleitet in',
        sk: 'Budete automaticky presmerovaní na stránku Beey za',
        es: 'Será redirigido automáticamente a Beey en',
        ru: 'Вы будете автоматически перенаправлены на Beey через',
        rs: 'Bićete automatski redirektovani u Beey',
        ua: 'Ви будете автоматично перенаправлені на Beey через',
        no: 'Du vil bli automatisk videresendt til Beey i løpet av',
        it: 'Sarà automaticamente reindirizzato a Beey in',
        se: 'Du kommer automatiskt att omdirigeras till Beey om',
    },
    redirection: {
        us: 'Redirection...',
        cz: 'Přesměrování...',
        pl: 'Przekierowanie...',
        hr: 'Preusmjeravanje...',
        si: 'Preusmerjanje...',
        de: 'Weiterleitung...',
        sk: 'Presmerovanie...',
        es: 'Redirección...',
        ru: 'Перенаправление...',
        rs: 'Redirekcija...',
        ua: 'Перенаправлення...',
        no: 'Omdirigerer...',
        it: 'Reindirizzamento...',
        se: 'Omdirigerar...',
    },
    thankyou: {
        us: 'Thank you for your purchase',
        cz: 'Děkujeme za váš nákup',
        pl: 'Dziękujemy za zakupy',
        hr: 'Hvala na kupnji',
        si: 'Hvala za nakup',
        de: 'Vielen Dank für Ihren Einkauf',
        sk: 'Ďakujeme vám za nákup',
        es: 'Gracias por su compra',
        ru: 'Благодарим за вашу покупку',
        rs: 'Hvala vam na kupovini',
        ua: 'Дякуємо вам за покупку',
        no: 'Takk for ditt kjøp.',
        it: 'Grazie per il suo acquisto',
        se: 'Tack för ditt köp',
    },
    unsupportedBrowser: {
        us: 'Beey is not supported in your browser.',
        cz: 'Beey není kompatibilní s vaším prohlížečem.',
        pl: 'Beey nie jest kompatybilne z twoją przeglądarką.',
        hr: 'Beey nije podržan u vašem pregledniku.',
        si: 'Beey ni podprt v vašem brskalniku.',
        de: 'Beey ist von Ihrem Browser nicht unterstützt.',
        sk: 'Beey nie je kompatibilné s vaším prehliadačom.',
        es: 'Beey no es compatible con su navegador.',
        ru: 'Beey не поддерживается вашим браузером.',
        rs: 'Beey nije podržan u vašem pregledaču.',
        ua: 'Beey не підтримується у вашому браузері.',
        no: 'Beey blir ikke støttet i din nettleser.',
        it: 'Beey non è compatibile con il suo browser.',
        se: 'Beey är inte kompatibelt med din webbläsare.',
    },
    unsupportedBrowserAndOperatingSystem: {
        us: 'Beey is not supported in your browser or operating system.',
        cz: 'Beey není kompatibilní s vaším prohlížečem nebo operačním systémem.',
        pl: 'Beey nie jest kompatybilny z Twoją przeglądarką lub systemem operacyjnym.',
        hr: 'Beey nije podržan u Vašem pregledniku ili operacijskom sustavu.',
        si: 'Beey ni podprt v vašem brskalniku ali operacijskem sistemu.',
        de: 'Beey wird von Ihrem Browser oder Betriebssystem nicht unterstützt.',
        sk: 'Beey nie je kompatibilný s vaším prehliadačom alebo operačným systémom.',
        es: 'Beey no es compatible con su navegador o sistema operativo.',
        ru: 'Beey не поддерживается в вашем браузере или операционной системе.',
        rs: 'Beey nije podržan od strane vašeg pregledača ili operativnog sistema.',
        ua: 'Beey не підтримується вашим браузером або операційною системою.',
        no: 'Beey støttes ikke av din nettleser eller operativsystem.',
        it: 'Beey non è compatibile con il suo browser o sistema operativo.',
        se: 'Beey stöds inte av denna webbläsare eller ditt operativsystem. ',
    },
    unsupportedApple: {
        us: 'Beey is for iOS/macOS still in BETA version. For a more stable user experience, we recommend using a laptop or desktop with version 15+ and the following browsers:',
        cz: 'Beey pro iOS/macOS je stále v BETA verzi. Pro stabilnější uživatelské prostředí doporučujeme použít laptop nebo desktop s verzí 15+ a tyto prohlížeče:',
        pl: 'Beey dla iOS/macOS jest wciąż w wersji BETA. Aby uzyskać bardziej stabilne działanie, zalecamy korzystanie z laptopa lub komputera stacjonarnego z wersją 15+ oraz następującymi przeglądarkami:',
        hr: 'Beey je za iOS/macOS još uvijek u BETA verziji. Za stabilnije korisničko iskustvo preporučujemo upotrebu prijenosnog ili stolnog računala s verzijom 15+ i sljedećih preglednika:',
        si: 'Beey je za iOS/macOS še vedno v BETA različici. Za stabilnejšo uporabniško izkušnjo priporočamo uporabo prenosnega ali namiznega računalnika z različico 15+ in naslednjih brskalnikov:',
        de: 'Beey ist für iOS/macOS noch in der BETA-Version. Für ein stabileres Nutzererlebnis empfehlen wir die Verwendung eines Laptops oder Desktops mit Version 15+ und die folgende Browser:',
        sk: 'Beey pre iOS/macOS je stále vo verzii BETA. Pre stabilnejší používateľský zážitok odporúčame používať notebook alebo stolný počítač s verziou 15+ a nasledujúce prehliadače:',
        es: 'Beey para iOS/macOS es aún en versión BETA. Para una experiencia de usuario más estable, recomendamos utilizar un ordenador portátil o de escritorio con la versión 15+ y los siguientes navegadores:',
        ru: 'Beey для iOS/macOS пока находится в BETA-версии. Для более стабильной работы мы рекомендуем использовать ноутбук или настольный компьютер с версией 15+ и следующими браузерами:',
        rs: 'Beey za iOS/macOS je još u BETA verziji. Za stabilnije korisničko iskustvo, preporučujemo korišćenje laptop ili desktop računara sa verzijom 15+ i narednim pregledačima:',
        ua: "Beey для iOS/macOS все ще знаходиться в BETA-версії. Для більш стабільної роботи ми рекомендуємо використовувати ноутбук або настільний комп'ютер з версією 15+ і наступними браузерами:",
        no: 'Beey for iOS/macOS er fortsatt i BETA versjon. For en mer stabil brukererfaring anbefaler vi å bruke en laptop eller desktop med versjon 15+ og følgende nettlesere:',
        it: "Beey per iOS/macOS è ancora in versione BETA. Per un'esperienza d'uso più stabile, si consiglia di utilizzare un computer portatile o desktop con la versione 15+ e i seguenti browser:",
        se: 'Beey är endast tillgänglig i betaversion för iOS/macOS . För en stabilare användarupplevelse rekommenderar vi att du använder en bärbar eller stationär dator med version 15+ och en av följande webbläsare:',
    },
    unsupportedSafari: {
        us: 'Beey is for Safari still in BETA version. For a more stable user experience, we recommend using the following browsers:',
        cz: 'Beey pro Safari je stále v BETA verzi. Pro stabilnější uživatelské prostředí doporučujeme tyto prohlížeče:',
        pl: 'Beey dla Safari jest wciąż w wersji BETA. Aby uzyskać bardziej stabilne działanie, zalecamy korzystanie z następujących przeglądarek:',
        hr: 'Beey je za Safari još uvijek u BETA verziji. Za stabilnije korisničko iskustvo preporučujemo upotrebu sljedećih preglednika:',
        si: 'Beey je za Safari še vedno v BETA različici. Za stabilnejšo uporabniško izkušnjo priporočamo uporabo naslednjih brskalnikov:',
        de: 'Beey ist für Safari noch in der BETA-Version. Für ein stabileres Nutzererlebnis empfehlen wir die Verwendung der folgenden Browser:',
        sk: 'Beey pre Safari je stále vo verzii BETA. Pre stabilnejší používateľský zážitok odporúčame používať nasledujúce prehliadače:',
        es: 'Beey para Safari es todavía en versión BETA. Para una experiencia de usuario más estable, recomendamos utilizar los siguientes navegadores:',
        ru: 'Beey для Safari пока находится в BETA-версии. Для более стабильной работы мы рекомендуем использовать следующие браузеры:',
        rs: 'Beey za Safari je još u BETA verziji. Za stabilnije korisničko iskustvo, preporučujemo korišćenje narednih pregledača:',
        ua: 'Beey для Safari поки що у BETA-версії. Для більш стабільної роботи ми рекомендуємо використовувати наступні браузери:',
        no: 'Beey for Safari er fortsatt i BETA versjon. For en mer stabil brukererfaring anbefaler vi å bruke en laptop eller desktop med versjon 15+ og følgende nettlesere:',
        it: "Beey per Safari è ancora in versione BETA. Per un'esperienza d'uso più stabile, si consiglia di utilizzare i seguenti browser:",
        se: 'Beey är endast tillgänglig i betaversion för Safari. För en stabilare användarupplevelse rekommenderar vi en av följande webbläsare:',
    },
    supportedBrowser: {
        us: 'We support:',
        cz: 'Podporujeme:',
        pl: 'Obsługujemy:',
        hr: 'Podržavamo:',
        si: 'Podpiramo:',
        de: 'Wir unterstützen:',
        sk: 'Podporujeme:',
        es: 'Ofrecemos soporte para:',
        ru: 'Мы поддерживаем:',
        rs: 'Podržavamo:',
        ua: 'Ми підтримуємо:',
        no: 'Vi støtter:',
        it: 'Supportiamo:',
        se: 'Vi stödjer:',
    },
    iosAndMacVersion: {
        us: 'iOS/macOS',
        cz: 'iOS/macOS',
        pl: 'iOS/macOS',
        hr: 'iOS/macOS',
        si: 'iOS/macOS',
        de: 'iOS/macOS',
        sk: 'iOS/macOS',
        es: 'iOS/macOS',
        ru: 'iOS/macOS',
        rs: 'iOS/macOS',
        ua: 'iOS/macOS',
        no: 'iOS/macOS',
        it: 'iOS/macOS',
        se: 'iOS/macOS',
    },
    invalidChars: {
        us: 'Invalid characters',
        cz: 'Nepovolené znaky',
        pl: 'Zakazane znaki',
        hr: 'Nedozvoljeni znakovi',
        si: 'Nedovoljeni znaki',
        de: 'Ungültige Zeichen',
        sk: 'Nepovolené znaky',
        es: 'Carácteres no válidos',
        ru: 'Недопустимые символы',
        rs: 'Nevažeći karakteri',
        ua: 'Неприпустимі символи',
        no: 'Ugyldig tegn',
        it: 'Segni non autorizzati',
        se: 'Ogiltiga tecken',
    },
    phoneticErrors: {
        us: 'Phonetic errors',
        cz: 'Fonetické chyby',
        pl: 'Błędy fonetyczne',
        hr: 'Fonetska pogreška',
        si: 'Fonetična napaka',
        de: 'Phonetische Fehler',
        sk: 'Fonetické chyby',
        es: 'Errores fonéticos',
        ru: 'Фонетические ошибки',
        rs: 'Fonetske greške',
        ua: 'Фонетичні помилки',
        no: 'Fonetiske feil',
        it: 'Errori di fonetica',
        se: 'Fonetiska fel',
    },
    inputOk: {
        us: 'Input ok',
        cz: 'Vstup v pořádku',
        pl: 'W porządku',
        hr: 'Valjan unos',
        si: 'Veljaven vnos',
        de: 'Eingabe OK',
        sk: 'Vstup v poriadku',
        es: 'Entrada OK',
        ru: 'Вход произведен успешно',
        rs: 'Input ok',
        ua: 'Вхід зроблено успішно',
        no: 'Inndata i orden',
        it: 'Inserimento in ordine',
        se: 'Indata accepteras',
    },
    fix: {
        us: 'Fix',
        cz: 'Opravit',
        pl: 'Napraw',
        hr: 'Popravi',
        si: 'Popravi',
        de: 'Korrigieren',
        sk: 'Opraviť',
        es: 'Corregir',
        ru: 'Исправить',
        rs: 'Popraviti',
        ua: 'Виправити',
        no: 'Rette',
        it: 'Correggere',
        se: 'Korrigera',
    },
    language: {
        us: 'Language',
        cz: 'Jazyk',
        pl: 'Język',
        hr: 'Jezik',
        si: 'Jezik',
        de: 'Sprache',
        sk: 'Jazyk',
        es: 'Idioma',
        ru: 'Язык',
        rs: 'Jezik',
        ua: 'Мова',
        no: 'Språk',
        it: 'Lingua',
        se: 'Språk',
    },
    logout: {
        us: 'Logout',
        cz: 'Odhlásit se',
        pl: 'Wyloguj się',
        hr: 'Odjava',
        si: 'Odjava',
        de: 'Abmelden',
        sk: 'Odhlásiť sa',
        es: 'Salir',
        ru: 'Выйти',
        rs: 'Odjava',
        ua: 'Вийти',
        no: 'Logge av',
        it: 'Disconnettersi',
        se: 'Logga ut',
    },
    confirmLogout: {
        us: 'Logout?',
        cz: 'Opravdu odhlásit?',
        pl: 'Czy naprawdę wylogować?',
        hr: 'Odjava?',
        si: 'Odjava?',
        de: 'Ausloggen?',
        sk: 'Skutočne odhlásiť?',
        es: 'Cerrar sesión?',
        ru: 'Выйти?',
        rs: 'Odjava?',
        ua: 'Вийти?',
        no: 'Virkelig logge av?',
        it: 'Vuole davvero disconnettersi?',
        se: 'Vill du logga ut?',
    },
    unsavedChanges: {
        us: 'Changes you have made may not be saved.',
        cz: 'Provedené změny se nemusí uložit.',
        pl: 'Wprowadzone zmiany mogą nie zostać zapisane.',
        hr: 'Promjene koje ste napravili možda neće biti spremljene.',
        si: 'Spremembe, ki ste jih naredili morda ne bojo shranjene.',
        de: 'Ihre Änderungen können nicht gespeichert sein.',
        sk: 'Vykonané zmeny môžu byť neuložené.',
        es: 'Es posible que los cambios realizados no se guarden.',
        ru: 'произведенные изменения могут не сохраниться.',
        rs: 'Izmene koje ste uneli možda neće biti sačuvane.',
        ua: 'Внесені вами зміни можуть бути не збережені.',
        no: 'Endringene dine vil muligens ikke lagres.',
        it: 'Le modifiche apportate devono essere salvate.',
        se: 'Dina ändringar kommer möjligen inte att sparas.',
    },
    cancel: {
        us: 'Cancel',
        cz: 'Zrušit',
        pl: 'Anuluj',
        hr: 'Poništi',
        si: 'Prekliči',
        de: 'Stornieren',
        sk: 'Zrušiť',
        es: 'Anular',
        ru: 'Отменить',
        rs: 'Otkazati',
        ua: 'Відмінити',
        no: 'Avbryte',
        it: 'Cancellare',
        se: 'Avbryt',
    },
    prevPage: {
        us: 'Previous',
        cz: 'Předchozí',
        pl: 'Poprzednia',
        hr: 'Prethodna',
        si: 'Predhodna',
        de: 'Zurück',
        sk: 'Predchádzajúce',
        es: 'Anterior',
        ru: 'Предыдущий',
        rs: 'Prethodno',
        ua: 'Попередній',
        no: 'Forrige',
        it: 'Precedente',
        se: 'Föregående',
    },
    nextPage: {
        us: 'Next',
        cz: 'Následující',
        pl: 'Następna',
        hr: 'Sljedeća',
        si: 'Naslednja',
        de: 'Weiter',
        sk: 'Následujúce',
        es: 'Siguiente',
        ru: 'Следующий',
        rs: 'Naredno',
        ua: 'Наступний',
        no: 'Neste',
        it: 'Successivo',
        se: 'Följande',
    },
    newTag: {
        us: 'New Tag',
        cz: 'Nový štítek',
        pl: 'Nowy tag',
        hr: 'Nova oznaka',
        si: 'Nova oznaka',
        de: 'Neues Schlagwort',
        sk: 'Nový štítok',
        es: 'Nueva etiqueta',
        ru: 'Новая отметка',
        rs: 'Nova oznaka',
        ua: 'Нова позначка',
        no: 'Ny etikett',
        it: 'Nuova etichetta',
        se: 'Ny tagg',
    },
    done: {
        us: 'Done',
        cz: 'Hotové',
        pl: 'Gotowe',
        hr: 'Dovršeno',
        si: 'Končano',
        de: 'Fertig',
        sk: 'Hotové',
        es: 'Hecho',
        ru: 'Готово',
        rs: 'Gotovo',
        ua: 'Готово',
        no: 'Ferdig',
        it: 'Finito',
        se: 'Färdigt',
    },
    canceled: {
        us: 'Canceled',
        cz: 'Zrušené',
        pl: 'Anulowane',
        hr: 'Poništeno',
        si: 'Odpovedano',
        de: 'Abgebrochen',
        sk: 'Zrušené',
        es: 'Cancelado',
        ru: 'Отменено',
        rs: 'Otkazano',
        ua: 'Скасовано',
        no: 'Avbrutt',
        it: 'Annullato',
        se: 'Avbrutet',
    },
    failedToSaveTags: {
        us: 'Failed to save tags.',
        cz: 'Uložení štítků selhalo.',
        pl: 'Zapisanie tagów się nie powiodło.',
        hr: 'Spremanje oznaka nije uspjelo.',
        si: 'Shranjevanje oznak ni uspelo.',
        de: 'Tags konnten nicht gespeichert werden.',
        sk: 'Uloženie štítkov zlyhalo.',
        es: 'No se pudieron guardar las etiquetas.',
        ru: 'Не удалось сохранить отметку.',
        rs: 'Čuvanje oznaka nije uspelo.',
        ua: 'Не вдалося зберегти.',
        no: 'Klarte ikke å lagre etiketter.',
        it: 'Salvataggio di etichette non riuscito.',
        se: 'Taggarna kunde inte sparas.',
    },
    tryAgain: {
        us: 'Please try again.',
        cz: 'Zkuste to prosím znovu.',
        pl: 'Spróbuj ponownie.',
        hr: 'Molimo pokušajte ponovno.',
        si: 'Prosimo, poskusite ponovno.',
        de: 'Bitte versuchen Sie es erneut.',
        sk: 'Skúste to prosím znova.',
        es: 'Por favor inténtalo de nuevo.',
        ru: 'Пожалуйста, повторите попытку.',
        rs: 'Molimo pokušajte ponovo.',
        ua: 'Будь ласка, спробуйте ще раз.',
        no: 'Vennligst prøv igjen.',
        it: 'Si prega di riprovare.',
        se: 'Vänligen prova igen.',
    },
    notes: {
        us: 'Notes',
        cz: 'Poznámky',
        pl: 'Notatki',
        hr: 'Bilješke',
        si: 'Beležke',
        de: 'Anmerkungen',
        sk: 'Poznámky',
        es: 'Notas',
        ru: 'Заметки',
        rs: 'Napomene',
        ua: 'Нотатки',
        no: 'Notater',
        it: 'Note',
        se: 'Noter',
    },
    save: {
        us: 'Save',
        cz: 'Uložit',
        pl: 'Zapisz',
        hr: 'Spremi',
        si: 'Shrani',
        de: 'Speichern',
        sk: 'Uložiť',
        es: 'Guardar',
        ru: 'Сохранить',
        rs: 'Sačuvati',
        ua: 'Зберегти',
        no: 'Lagre',
        it: 'Salvare',
        se: 'Spara',
    },
    close: {
        us: 'Close',
        cz: 'Zavřít',
        pl: 'Zamknij',
        hr: 'Zatvori',
        si: 'Zapri',
        de: 'Schließen',
        sk: 'Zatvoriť',
        es: 'Cerrar',
        ru: 'Закрыть',
        rs: 'Zatvoriti',
        ua: 'Закрити',
        no: 'Lukk',
        it: 'Chiudere',
        se: 'Stäng',
    },
    notesSaved: {
        us: 'Notes were saved.',
        cz: 'Poznámky byly uloženy.',
        pl: 'Notatki zostały zapisane.',
        hr: 'Vaše bilješke su spremljene.',
        si: 'Vaše beležke so shranjene.',
        de: 'Ihre Anmerkungen sind gespeichert.',
        sk: 'Poznámky boli uložené.',
        es: 'Se han guardado las notas.',
        ru: 'Заметки были сохранены.',
        rs: 'Beleške su sačuvane.',
        ua: 'Нотатки було збережено.',
        no: 'Notater er lagret.',
        it: 'Le note sono state salvate.',
        se: 'Noterna sparades.',
    },
    failedToSaveNotes: {
        us: 'Failed to save notes.',
        cz: 'Při ukládání poznámek došlo k chybě.',
        pl: 'Zapisywanie się nie powiodło.',
        hr: 'Spremanje nije uspjelo.',
        si: 'Shranjevanje ni uspelo.',
        de: 'Fehler beim Speichern.',
        sk: 'Pri ukladaní sa vyskytla chyba.',
        es: '¡No se pudieron guardar las notas.',
        ru: 'Не удалось сохранить заметки.',
        rs: 'Čuvanje beleški nije uspelo.',
        ua: 'Не вдалося зберегти нотатки.',
        no: 'Klarte ikke å lagre notater.',
        it: 'Si è verificato un errore durante il salvataggio delle note.',
        se: 'Noterna kunde inte sparas.',
    },
    shareProjs: {
        us: 'Share Projects',
        cz: 'Sdílení projektů',
        pl: 'Udostępnianie projektów',
        hr: 'Podijeli projekt',
        si: 'Podeli projekt',
        de: 'Teilen der Projekte',
        sk: 'Zdieľanie projektov',
        es: 'Compartir proyectos',
        ru: 'Поделиться проектами',
        rs: 'Deliti projekte',
        ua: 'Поділитись проектами',
        no: 'Del prosjekter',
        it: 'Condivisione dei progetti',
        se: 'Dela projekt',
    },
    projsToShare: {
        us: 'Projects to share',
        cz: 'Počet projektů ke sdílení',
        pl: 'Liczba projektów, które zostaną udostępnione',
        hr: 'Projekti za dijeljenje',
        si: 'Projekti za delenje',
        de: 'Anzahl der zu teilenden Projekte',
        sk: 'Počet projektov na zdieľanie',
        es: 'Proyectos para compartir',
        ru: 'Список проектов доступных для обмена',
        rs: 'Projekti za deljenje',
        ua: 'Список проектів, доступних для обміну',
        no: 'Prosjekter for å dele',
        it: 'Numero di progetti da condividere',
        se: 'Antal projekt som kan delas',
    },
    shareWith: {
        us: 'Share with',
        cz: 'Sdílet s',
        pl: 'Udostępnij',
        hr: 'Podijeli s',
        si: 'Podeli z',
        de: 'Teilen mit',
        sk: 'Zdieľať s',
        es: 'Compartir con',
        ru: 'Поделиться с',
        rs: 'Deliti sa',
        ua: 'Поділитися з',
        no: 'Del med',
        it: 'Condividere con',
        se: 'Dela med',
    },
    share: {
        us: 'Share',
        cz: 'Sdílet',
        pl: 'Udostępnij',
        hr: 'Podijeli',
        si: 'Podeli',
        de: 'Teilen',
        sk: 'Zdieľať',
        es: 'Compartir',
        ru: 'Поделиться',
        rs: 'Podeliti',
        ua: 'Поділитися',
        no: 'Dele',
        it: 'Condividere',
        se: 'Dela',
    },
    emailValidation: {
        us: 'Email address is not in a valid format',
        cz: 'Emailová adresa není ve správném formátu',
        pl: 'Format adresu e-mail nie jest poprawny',
        hr: 'E-mail adresa nije u valjanom formatu',
        si: 'Naslov e-pošte ni v veljavnem formatu',
        de: 'Die Email Adresse hat kein gültiges Format',
        sk: 'Emailová adresa nie je v správnom formáte',
        es: 'La dirección de e-mail no tiene un formato válido',
        ru: 'Неверный формат электронной почты',
        rs: 'E-mail adresa nije u validnom formatu',
        ua: 'Неправильний формат електронної пошти',
        no: 'Den oppgitte e-postadressen har ikke riktig format',
        it: "L'indirizzo email non è nel formato corretto",
        se: 'E-postadressen är inte i ett giltigt format',
    },
    selfSharing: {
        us: 'Project can be shared only with other Beey users.',
        cz: 'Projekt lze sdílet pouze s dalšími Beey uživateli.',
        pl: 'Projekt może być udostępniany tylko innym użytkownikom Beey.',
        hr: 'Projekt se može dijeliti samo s drugim korisnicima Beeyja.',
        si: 'Projekt je mogoče deliti samo z drugimi uporabniki Beeyja.',
        de: 'Das Projekt kann nur mit anderen Beey-Nutzern geteilt werden.',
        sk: 'Projekt je možné zdieľať len s ostatnými používateľmi Beey.',
        es: 'El proyecto solo se puede compartir con otros usuarios de Beey.',
        ru: 'Проектом можно поделиться только с другими пользователями Beey.',
        rs: 'Projekat može da se deli samo sa drugim korisnicima Beeyja.',
        ua: 'Проектом можна поділитися тільки з іншими користувачами Beey.',
        no: 'Prosjektet kan være delt kun med andre Beey brukere.',
        it: 'Il progetto può essere condiviso solo con altri utenti Beey.',
        se: 'Projektet kan endast delas med andra användare av Beey.',
    },
    notFoundUser: {
        us: 'There is no user with this e-mail.',
        cz: 'Uživatel s tímto emailem neexistuje.',
        pl: 'Użytkownik z tym e-mailem nie istnieje.',
        hr: 'Korisnik s ovim e-mailom ne postoji',
        si: 'S tem e-poštnim naslovom ni nobenega uporabnika.',
        de: 'Es gibt keinen Benutzer mit dieser E-Mail.',
        sk: 'Používateľ s týmto e-mailom neexistuje.',
        es: 'No hay ningún usuario con este e-mail.',
        ru: 'Нет пользователя с этим адресом электронной почты.',
        rs: 'Ne postoji korisnik sa ovom email adresom.',
        ua: 'Немає користувачів із цією електронною адресою.',
        no: 'Brukeren med denne e-post adressen finnes ikke.',
        it: 'Un utente con questa email non esiste.',
        se: 'Det finns ingen användare med denna e-postadress.',
    },
    sharingSuccessful: {
        us: 'Sharing was successful',
        cz: 'Sdílení proběhlo úspěšně',
        pl: 'Udostępnianie się powiodło',
        hr: 'Dijeljenje uspješno',
        si: 'Deljenje uspešno',
        de: 'Erfolgreich geteilt',
        sk: 'Zdieľanie prebehlo úspešne',
        es: 'Compartido con éxito',
        ru: 'Обмен успешно заврешен',
        rs: 'Deljenje uspešno',
        ua: 'Обмін успішно завершено',
        no: 'Deling vellykket',
        it: 'La condivisione è riuscita',
        se: 'Projektet har delats',
    },
    alreadySharedCount: {
        us: 'Number of already shared projects: ',
        cz: 'Počet dříve sdílených projektů: ',
        pl: 'Liczba udostępnionych projektów: ',
        hr: 'Broj podijeljenih projekata: ',
        si: 'Število deljenih projektov: ',
        de: 'Anzahl bereits geteilter Projekte: ',
        sk: 'Počet predtým zdieľaných projektov: ',
        es: 'Número de proyectos ya compartidos: ',
        ru: 'Количество проектов, которыми вы поделились: ',
        rs: 'Broj već podeljenih projekata: ',
        ua: 'Кількість проектів, якими ви поділилися: ',
        no: 'Antall tidligere delt prosjekter: ',
        it: 'Numero di progetti condivisi in precedenza: ',
        se: 'Antal projekt som har delats: ',
    },
    alreadyShared: {
        us: 'These projects are already shared with this user',
        cz: 'Tyto projekty jsou už s daným uživatelem sdílené',
        pl: 'Projekt jest już udostępniony temu użytkownikowi',
        hr: 'Projekt je već podijeljen s ovim korisnikom',
        si: 'Projekt je že v skupni rabi s tem uporabnikom',
        de: 'Diese Projekte sind schon mit diesem Benutzer geteilt',
        sk: 'Tieto projekty sú už s používateľom zdieľané',
        es: 'El proyecto ya está compartido con este usuario',
        ru: 'Вы уже поделились проектом с данным пользователем',
        rs: 'Projekat je već podeljen s ovim korisnikom',
        ua: 'Ви вже поділилися проектом з цим користувачем',
        no: 'Du allerede deler disse prosjektene med denne brukeren',
        it: "Questi progetti sono già condivisi con quell'utente",
        se: 'Du har redan delat dessa projekt med denna användare',
    },
    projShareCount: {
        us: 'Number of shared projects',
        cz: 'Počet nasdílených projektů',
        pl: 'Liczba udostępnionych projektów',
        hr: 'Broj podijeljenih projekata',
        si: 'Število podeljenih projektov',
        de: 'Anzahl der geteilten Projekte',
        sk: 'Počet nazdieľaných projektov',
        es: 'Número de proyectos compartidos',
        ru: 'Количество проектов, которыми вы поделились',
        rs: 'Broj podeljenih projekata',
        ua: 'Кількість проектів, якими ви поділилися',
        no: 'Antall delt prosjekter',
        it: 'Numero di progetti condivisi',
        se: 'Antal delade projekt',
    },
    sharingBlocked: {
        us: 'Cannot share',
        cz: 'Nelze sdílet',
        pl: 'Nie można udostępnić',
        hr: 'Dijeljenje nije moguće',
        si: 'Skupna raba ni mogoča',
        de: 'Teilen unmöglich',
        sk: 'Nemožno zdieľať',
        es: 'No se puede compartir',
        ru: 'Невозможно поделиться',
        rs: 'Deljenje nije moguće',
        ua: 'Неможливо поділитися',
        no: 'Kan ikke deles',
        it: 'Non può essere condiviso',
        se: 'Kan ej delas',
    },
    sharingBlockedDescription: {
        us: 'Given user does not exist or you do not have sufficient permissions. For more information, contact our support at support@beey.io',
        cz: 'Zadaný uživatel neexistuje nebo ke sdílení nemáte oprávnění. Pro více informací kontaktujte podporu na support@beey.io',
        pl: 'Podany użytkownik nie istnieje lub nie masz wystarczających uprawnień. Aby uzyskać więcej informacji, skontaktuj się z naszym działem pomocy technicznej pod adresem support@beey.io',
        hr: 'Zadani korisnik ne postoji ili nemate dopuštenje. Za više informacija obratite se našoj podršci na adresu support@beey.io',
        si: 'Privzeti uporabnik ne obstaja ali pa nimate dovolj dovoljenj. Za več informacij se obrnite na našo podporo na support@beey.io',
        de: 'Der angegebene Benutzer existiert nicht oder Sie verfügen nicht über ausreichende Berechtigungen. Für weitere Informationen wenden Sie sich bitte an unseren Support unter support@beey.io',
        sk: 'Zadaný používateľ neexistuje alebo nemáte oprávnenie na zdieľanie. Ďalšie informácie získate na adrese podpory support@beey.io',
        es: 'Este usuario no existe o no tiene suficientes permisos. Para obtener más información, póngase en contacto con nuestro soporte en support@beey.io',
        ru: 'Данный пользователь не существует или у вас нет достаточных разрешений. Для получения дополнительной информации обратитесь в нашу службу поддержки по адресу support@beey.io',
        rs: 'Navedeni korisnik ne postoji ili nemate dovoljan nivo dozvola. Za više informacija kontaktirajte podršku na support@beey.io',
        ua: 'Даний користувач не існує або у вас немає достатніх дозволів. Для отримання додаткової інформації зверніться до нашої служби підтримки за адресою support@beey.io',
        no: 'Denne brukeren finnes ikke eller du har ikke tilgangsrettigheter til å dele prosjektet. Kontakt oss på support@beey.io for mer informasjon.',
        it: "L'utente specificato non esiste o non  ha l'autorizzazione alla condivisione. Per ulteriori informazioni, contatti l'assistenza all'indirizzo support@beey.io",
        se: 'Denna användare finns inte, eller du har inte behörighet att dela projektet. Kontakta vår kundservice på support@beey.io för mer information.',
    },
    failedToShare: {
        us: 'Failed to share',
        cz: 'Při sdílení došlo k chybě',
        pl: 'Udostępnianie się nie powiodło',
        hr: 'Dijeljenje nije uspjelo',
        si: 'Skupna raba ni uspela',
        de: 'Fehler beim Teilen',
        sk: 'Pri zdieľaní došlo k chybe',
        es: 'Se produjo un error al compartir',
        ru: 'Не удалось поделиться',
        rs: 'Deljenje nije uspelo',
        ua: 'Не вдалося поділитися',
        no: 'Deling mislykket',
        it: 'Si è verificato un errore durante la condivisione',
        se: 'Det gick inte att dela projektet',
    },
    dragAndDropSingle: {
        us: 'Click or drag file here to upload',
        cz: 'Klikněte nebo přetáhněte soubor sem',
        pl: 'Kliknij lub przeciągnij plik tutaj',
        hr: 'Kliknite ili povucite datoteku za prijenos',
        si: 'Kliknite ali povlecite datoteko za prenos',
        de: 'Zum Hochladen Datei hierher ziehen oder klicken',
        sk: 'Kliknite alebo pretiahnite súbor sem',
        es: 'Haga clic o arrastre el archivo aquí para cargarlo',
        ru: 'Нажмите или перетяните файл сюда для загрузки',
        rs: 'Kliknite ovde ili prevucite datoteku ovde da biste je otpremili',
        ua: 'Натисніть або перетягніть файл сюди, щоб завантажити',
        no: 'Klikk og dra filen her for å laste den opp',
        it: 'Fare clic o trascinare il file qui per caricarlo',
        se: 'Klicka eller dra filen hit',
    },
    upgradingAccount: {
        us: 'Your account is being upgraded...',
        cz: 'Váš účet se právě upgraduje...',
        pl: 'Twoje konto jest upgradowane...',
        hr: 'Vaš se račun nadograđuje...',
        si: 'Vaš račun se nadgrajuje...',
        de: 'Ihr Konto wird gerade aktualisiert...',
        sk: 'Vaše konto je v procese aktualizácie...',
        es: 'Su cuenta se está actualizando...',
        ru: 'Ваш профиль обновляется...',
        rs: 'Vaš nalog se nadograđuje...',
        ua: 'Ваш обліковий запис оновлюється...',
        no: 'Kontoen din oppgraderes nå...',
        it: 'Il suo account è in fase di aggiornamento...',
        se: 'Ditt konto uppgraderas nu...',
    },
    helmetSignUp: {
        us: 'Sign up',
        cz: 'Registrace',
        pl: 'Zarejestruj się',
        hr: 'Registracija',
        si: 'Registracija',
        de: 'Anmelden',
        sk: 'Registrácia',
        es: 'Registrarse',
        ru: 'Зарегистрироваться',
        rs: 'Registracija',
        ua: 'Зареєструватися',
        no: 'Logg på',
        it: 'Registrazione',
        se: 'Registrera dig',
    },
    thankYouRegistration: {
        us: 'Verify your e-mail',
        cz: 'Potvrďte svůj e-mail',
        pl: 'Potwierdź swój adres e-mail',
        hr: 'Potvrdite svoj e-mail',
        si: 'Potrdite svoj e-poštni naslov',
        de: 'Überprüfen Sie Ihre E-Mail',
        sk: 'Overte svoj e-mail',
        es: 'Verifique su correo electrónico',
        ru: 'Проверьте свою электронную почту',
        rs: 'Potvrdite svoj e-mail',
        ua: 'Підтвердіть свою електронну пошту',
        no: 'Bekreft e-postadressen din',
        it: "Confermare l'e-mail",
        se: 'Bekräfta din e-postadress',
    },
    accountActivation: {
        us: 'To access your account, click on the activation link that was sent to your e-mail after registration',
        cz: 'Pro přístup do účtu klikněte na odkaz v aktivačním e-mailu, který vám byl odeslán po registraci',
        pl: 'Aby uzyskać dostęp do swojego konta, kliknij link w e-mailu aktywacyjnym wysłanym do Ciebie po rejestracji',
        hr: 'Da biste pristupili svom računu, kliknite poveznicu za aktivaciju koja je poslana na vašu e-mail adresu nakon registracije',
        si: 'Če želite dostopati do svojega računa, kliknite povezavo za aktivacijo, ki je bila poslana na vaš e-poštni naslov po registraciji',
        de: 'Um auf Ihr Konto zuzugreifen, klicken Sie auf den Aktivierungslink, der Ihnen nach der Registrierung per E-Mail zugesandt wurde',
        sk: 'Ak chcete získať prístup k svojmu účtu, kliknite na odkaz v aktivačnom e-maile, ktorý vám bol zaslaný po registrácii',
        es: 'Para acceder a su cuenta, haga clic en el enlace de activación que se envió a su correo electrónico después del registro',
        ru: 'Чтобы получить доступ к своей учетной записи, нажмите на ссылку активации, которая была отправлена на ваш электронный адрес после регистрации',
        rs: 'Da biste pristupili svom nalogu, kliknite na link za aktivaciju koji je poslat na Vašu e-mail adresu nakon registracije',
        ua: 'Щоб отримати доступ до свого облікового запису, перейдіть за посиланням для активації, яке було надіслано на Вашу електронну пошту після реєстрації',
        no: 'For å få tilgang til kontoen din, klikk på aktiveringslenken i mailen du fikk ved registrasjon',
        it: "Per accedere al proprio account, cliccare sul link contenuto nell'e-mail di attivazione inviata dopo la registrazione",
        se: 'För att få tillgång till ditt konto, klicka på aktiveringslänken i mailet som du har fått vid registration',
    },
    noEmail: {
        us: 'Did not get the e-mail?',
        cz: 'Nepřišel vám e-mail?',
        pl: 'Nie otrzymałeś e-maila?',
        hr: 'Niste primili e-mail?',
        si: 'Niste prejeli e-pošte?',
        de: 'Haben Sie die E-Mail nicht erhalten?',
        sk: 'Nedostali ste e-mail?',
        es: '¿No recibió el correo electrónico?',
        ru: 'Не получили письмо?',
        rs: 'Niste primili e-mail?',
        ua: 'Не отримали листа?',
        no: 'Har du ikke fått aktiveringsmailen?',
        it: "Non ha ricevuto un'e-mail?",
        se: 'Har du inte fått aktiveringsmailet?',
    },
    sendEmail: {
        us: 'Send a new e-mail.',
        cz: 'Zaslat nový e-mail.',
        pl: 'Wyślij nową wiadomość e-mail.',
        hr: 'Pošalji novi e-mail.',
        si: 'Pošlji novo e-pošto.',
        de: 'Neue E-Mail senden.',
        sk: 'Odoslať nový e-mail.',
        es: 'Enviar un nuevo correo electrónico.',
        ru: 'Отправьте новое письмо.',
        rs: 'Pošaljite novi e-mail.',
        ua: 'Надішліть новий лист.',
        no: 'Send en ny e-post.',
        it: 'Invia una nuova e-mail.',
        se: 'Skicka en ny e-post.',
    },
    sendAgain: {
        us: 'Send it again.',
        cz: 'Zaslat znovu.',
        pl: 'Wyślij ponownie.',
        hr: 'Pošalji opet.',
        si: 'Pošlji še enkrat.',
        de: 'Nochmals senden.',
        sk: 'Odoslať znova.',
        es: 'Enviarlo de nuevo.',
        ru: 'Отправьте его еще раз.',
        rs: 'Pošalji opet.',
        ua: 'Надішліть його ще раз.',
        no: 'Send igjen.',
        it: 'Invialo di nuovo.',
        se: 'Skicka igen.',
    },
    lookingForward: {
        us: 'We look forward to seeing your projects in Beey.',
        cz: 'Těšíme se na vaše projekty v Beey.',
        pl: 'Cieszymy się na Twoje projekty w Beey.',
        hr: 'Veselimo se vašim projektima u Beeyju.',
        si: 'Veselimo se vaših projektov v Beeyju.',
        de: 'Wir freuen uns auf Ihre Projekte in Beey.',
        sk: 'Tešíme sa na vaše projekty v Beey.',
        es: 'Esperamos ver sus proyectos en Beey.',
        ru: 'Мы с нетерпением ждем появления ваших проектов в Beey.',
        rs: 'Radujemo se Vašim projektima u Beeyju.',
        ua: 'Будемо раді бачити ваші проекти в Beey.',
        no: 'Vi ser fram til dine prosjekter i Beey.',
        it: "Non vediamo l'ora di vedere i suoi progetti in Beey.",
        se: 'Vi ser fram emot dina projekt i Beey.',
    },
    needHelp: {
        us: 'Need help?',
        cz: 'Potřebujete poradit?',
        pl: 'Potrzebujesz pomocy?',
        hr: 'Trebate pomoć?',
        si: 'Potrebujete pomoč?',
        de: 'Brauchen Sie Hilfe?',
        sk: 'Potrebujete pomoc?',
        es: '¿Necesita ayuda?',
        ru: 'Нужна помощь?',
        rs: 'Potrebna je pomoć?',
        ua: 'Потрібна допомога?',
        no: 'Trenger du hjelp?',
        it: 'Ha bisogno di aiuto?',
        se: 'Behöver du hjälp?',
    },
    wrongEmail: {
        us: 'Wrong e-mail address?',
        cz: 'Zadali jste špatnou e-mailovou adresu?',
        pl: 'Wprowadziłeś niewłaściwy adres e-mail?',
        hr: 'Pogrešna e-mail adresa?',
        si: 'Napačen e-poštni naslov?',
        de: 'Haben Sie eine falsche E-Mail-Adresse eingegeben?',
        sk: 'Zadali ste nesprávnu e-mailovú adresu?',
        es: '¿Dirección de correo electrónico incorrecta?',
        ru: 'Ошиблись адресом электронной почты?',
        rs: 'Pogrešna e-mail adresa?',
        ua: 'Неправильна адреса електронної пошти?',
        no: 'Har du oppgitt en feil e-post adresse?',
        it: "Ha inserito l'indirizzo e-mail sbagliato?",
        se: 'Felaktig e-postadress?',
    },
    signupAgain: {
        us: 'Sign up again.',
        cz: 'Registrujte se znovu.',
        pl: 'Zarejestruj się ponownie.',
        hr: 'Ponovno se registrirajte.',
        si: 'Ponovno se registrirajte.',
        de: 'Registrieren Sie sich erneut.',
        sk: 'Zaregistrujte sa znova.',
        es: 'Regístrese de nuevo.',
        ru: 'Зарегистрируйтесь снова.',
        rs: 'Ulogujte se ponovo.',
        ua: 'Зареєструйтеся ще раз.',
        no: 'Logg deg på igjen.',
        it: 'Si registri di nuovo.',
        se: 'Registrera dig igen.',
    },
    contactHelp: {
        us: 'Contact us.',
        cz: 'Ozvěte se nám.',
        pl: 'Skontaktuj się z nami.',
        hr: 'Kontaktirajte s nama.',
        si: 'Obrnite se na nas.',
        de: 'Kontaktieren Sie uns.',
        sk: 'Kontaktujte nás.',
        es: 'Póngase en contacto con nosotros.',
        ru: 'Свяжитесь с нами.',
        rs: 'Kontanktirajte nas.',
        ua: "Зв'яжіться з нами.",
        no: 'Kontakt oss.',
        it: 'Si metta in contatto con noi.',
        se: 'Kontakta oss.',
    },
    enterEmail: {
        us: 'Enter your e-mail address below to create an account.',
        cz: 'Zadejte svou e-mailovou adresu pro vytvoření účtu.',
        pl: 'Aby wytworzyć konto, wprowadź swój adres e-mail.',
        hr: 'Unesite e-mail adresu kako biste otvorili račun.',
        si: 'Vnesite naslov e-pošte, da bi odprli račun.',
        de: 'Bitte Email Adresse eingeben, um ein Konto zu erstellen.',
        sk: 'Zadajte svoju e-mailovú adresu pre vytvorenie účtu.',
        es: 'Ingrese su dirección de e-mail para crear una cuenta.',
        ru: 'Введите свой e-mail для создания профиля.',
        rs: 'Unesite svoju e-mail adresu kako biste otvorili nalog.',
        ua: 'Введіть свою електронну адресу нижче, щоб створити обліковий запис.',
        no: 'Skriv e-postadressen din her for å opprette en konto.',
        it: 'Inserisca il suo indirizzo email per creare un account.',
        se: 'Ange din e-postadress för att skapa ett konto.',
    },
    login: {
        us: 'Log in',
        cz: 'Přihlásit se',
        pl: 'Zaloguj się',
        hr: 'Prijava',
        si: 'Prijava',
        de: 'Einloggen',
        sk: 'Prihlásiť sa',
        es: 'Iniciar sesión',
        ru: 'Войти',
        rs: 'Prijavite se',
        ua: 'Увійти',
        no: 'Logg inn',
        it: 'Accedere',
        se: 'Logga in',
    },
    continueWith: {
        us: 'Continue with',
        cz: 'Pokračovat s',
        pl: 'Konutynuuj z',
        hr: 'Nastavite s',
        si: 'Nadaljuj z',
        de: 'Weiter mit',
        sk: 'Pokračovať s',
        es: 'Continuar con',
        ru: 'Продолжение c',
        rs: 'Nastavi sa',
        ua: 'Продовжуйте з',
        no: 'Fortsett med',
        it: 'Continuare con',
        se: 'Fortsätt med',
    },
    more: {
        us: 'more',
        cz: 'další',
        pl: 'więcej',
        hr: 'više',
        si: 'več',
        de: 'weiter',
        sk: 'ďalší',
        es: 'más',
        ru: 'подробнее',
        rs: 'dalje',
        ua: 'більше',
        no: 'mer',
        it: 'successivo',
        se: 'mer',
    },
    needToLogIn: {
        us: 'Your account has been upgraded. Now you just need to log in.',
        cz: 'Váš účet byl upgradován. Už stačí se jen přihlásit.',
        pl: 'Twoje konto zostało upgradowane. Teraz wystarczy się zalogować.',
        hr: 'Vaš je račun nadograđen. Sada se samo trebate prijaviti.',
        si: 'Vaš račun je nadgrajen. Zdaj se morate samo prijaviti.',
        de: 'Ihr Konto wurde aktualisiert. Jetzt müssen Sie sich nur noch anmelden.',
        sk: 'Vaše konto bolo aktualizované. Stačí sa len prihlásiť.',
        es: 'Su cuenta se ha actualizado. Ahora puede iniciar sesión.',
        ru: 'Ваш профиль был обновлен. Теперь достаточно войти.',
        rs: 'Vaš nalog je nadograđen. Sada je samo potrebno da se prijavite.',
        ua: 'Ваш обліковий запис оновлено. Тепер вам просто потрібно увійти.',
        no: 'Kontoen din har blitt oppgradert. Du kan logge deg inn nå.',
        it: 'Il suo account è stato aggiornato. Ora è sufficiente effettuare il login.',
        se: 'Ditt konto har uppgraderats. Nu kan du logga in.',
    },
    successUpgrade: {
        us: 'Welcome to Beey! Your account was successfully activated.',
        cz: 'Vítejte v Beey! Váš účet byl úspěšně aktivován.',
        pl: 'Witamy w Beey! Twoje konto zostało pomyślnie aktywowane.',
        hr: 'Dobrodošli u Beey! Vaš je račun uspješno aktiviran.',
        si: 'Dobrodošli v Beey! Vaš račun je uspešno aktiviran.',
        de: 'Willkommen in Beey! Ihr Konto wurde erfolgreich aktiviert.',
        sk: 'Vitajte v Beey! Váš účet bol úspešne aktivovaný.',
        es: '¡Bienvenido a Beey! Su cuenta fue activada exitosamente.',
        ru: 'Добро пожаловать в Beey! Ваш профиль был успешно активирован.',
        rs: 'Dobrodošli u Beey! Vaš nalog je uspešno aktiviran.',
        ua: 'Ласкаво просимо до Beey! Ваш обліковий запис успішно активовано.',
        no: 'Velkommen til Beey! Kontoen din har blitt aktivert.',
        it: 'Benvenuto su Beey! Il suo account è stato attivato con successo.',
        se: 'Välkommen till Beey! Ditt konto har aktiverats.',
    },
    invalidUpgrade: {
        us: 'The activation link is no longer valid. If you have not upgraded your account yet, log in to request a new activation link.',
        cz: 'Aktivační odkaz už není platný. Pokud váš účet ještě nebyl upgradován, přihlašte se a zažádejte o nový aktivační e-mail.',
        pl: 'Link aktywacyjny stracił ważność. Jeśli Twoje konto nie zostało jeszcze upgradowane, zaloguj się i poproś o nowy link aktywacyjny.',
        hr: 'Poveznica za aktivaciju više nije važeća. Ako svoj račun još niste nadogradili, prijavite se i zatražite novu poveznicu za aktivaciju.',
        si: 'Povezava za aktivacijo ni več veljavna. Če svojega računa še niste nadgradili, se prijavite in zahtevajte novo aktivacijsko povezavo.',
        de: 'Der Aktivierungslink ist nicht mehr gültig. Wenn Sie Ihr Konto noch nicht aktualisiert haben, melden Sie sich an, um einen neuen Aktivierungs-E-Mail anzufordern.',
        sk: 'Aktivačný odkaz už nie je platný. Pokiaľ váš účet ešte nebol upgradovaný, prihláste sa a zažiadajte o nový aktivačný e-mail.',
        es: 'El enlace de activación ya no es válido. Si aún no ha actualizado su cuenta, inicie sesión para solicitar un nuevo enlace de activación.',
        ru: 'Ссылка активации больше не действительна. Если ваш профиль еще не был обновлен, войдите и отправьте запрос на новую ссылку активации.',
        rs: 'Aktivacioni link nije više važeći. Ako još niste nadogradili svoj nalog, prijavite se da biste zatražili novi link za aktivaciju.',
        ua: 'Посилання для активації більше не дійсне. Якщо ви ще не оновили свій обліковий запис, увійдіть, щоб надіслати запит на нове посилання для активації.',
        no: 'Aktiveringslenken er ikke lenger gyldig. Hvis du ikke har oppgradert kontoen din, logg inn for å be om en ny lenke.',
        it: 'Il link di attivazione non è più valido. Se il suo account non è ancora stato aggiornato, acceda e richieda una nuova e-mail di attivazione.',
        se: 'Aktiveringslänken är inte längre giltig. Om ditt konto inte har uppgraderats än, logga in för att begära en ny länk.',
    },
    upgradeFail: {
        us: 'Account upgrade failed. Please contact our support at support@beey.io',
        cz: 'Upgrade účtu se nezdařil. Kontaktujte prosím podporu na support@beey.io',
        pl: 'Upgrade konta nie powiódł się. Skontaktuj się z naszą pomocą techniczną na adresie: support@beey.io',
        hr: 'Nadogradnja računa nije uspjela. Molimo kontaktirajte s našom podrškom na support@beey.io',
        si: 'Posodobitev računa ni uspela. Prosimo, kontaktirajte našo podporo na support@beey.io',
        de: 'Upgrade des Kontos fehlgeschlagen. Bitte kontaktieren Sie unseren Support: support@beey.io',
        sk: 'Upgrade účtu zlyhal. Kontaktujte prosím podporu na support@beey.io',
        es: 'Error al actualizar la cuenta. Por favor póngase en contacto con nuestro soporte en support@beey.io',
        ru: 'При обновлении профиля произошла ошибка. Пожалуйста, свяжитесь со службой поддержки support@beey.io',
        rs: 'Nadogradnja naloga nije uspela. Molimo kontaktirajte našu podršku: support@beey.io',
        ua: 'Не вдалося оновити обліковий запис. Будь ласка, зв’яжіться з нашою службою підтримки за адресою support@beey.io',
        no: 'Kontoen har ikke blitt oppgradert. Vennligst kontakt oss på support@beey.io',
        it: "Aggiornamento dell'account non riuscito. Contatti l'assistenza all'indirizzo support@beey.io",
        se: 'Det gick inte att uppgradera kontot. Vänligen kontakta vår kundservice på support@beey.io',
    },
    email: {
        us: 'E-mail',
        cz: 'E-mail',
        pl: 'E-mail',
        hr: 'E-mail',
        si: 'E-mail',
        de: 'E-mail',
        sk: 'E-mail',
        es: 'E-mail',
        ru: 'E-mail',
        rs: 'E-mail',
        ua: 'E-mail',
        no: 'E-post',
        it: 'E-mail',
        se: 'E-post',
    },
    pleaseEmail: {
        us: 'Please specify valid e-mail',
        cz: 'Zadejte prosím platnou e-mailovou adresu',
        pl: 'Wprowadź poprawny adres e-mail',
        hr: 'Molimo unesite valjanu e-mail adresu',
        si: 'Prosimo, vnesite veljaven e-mail naslov',
        de: 'Bitte gültige E-mail Adresse eingeben',
        sk: 'Zadajte platnú e-mailovú adresu',
        es: 'Por favor ingrese un e-mail válido',
        ru: 'Пожалуйста, введите действительный e-mail',
        rs: 'Navedite važeću e-mail adresu',
        ua: 'Вкажіть дійсну адресу електронної пошти',
        no: 'Vennligst skriv en gyldig e-postadresse',
        it: 'Inserisca un indirizzo e-mail valido',
        se: 'Vänligen ange en giltig e-postadress',
    },
    pw: {
        us: 'Password',
        cz: 'Heslo',
        pl: 'Hasło',
        hr: 'Lozinka',
        si: 'Geslo',
        de: 'Passwort',
        sk: 'Heslo',
        es: 'Contraseña',
        ru: 'Пароль',
        rs: 'Šifra',
        ua: 'Пароль',
        no: 'Passord',
        it: 'La password',
        se: 'Lösenord',
    },
    minChars: {
        us: 'Minimum characters: ',
        cz: 'Minimální počet znaků: ',
        pl: 'Minimalna liczba znaków: ',
        hr: 'Minimalan broj znakova: ',
        si: 'Minimalno število znakov: ',
        de: 'Mindestlänge: ',
        sk: 'Minimálny počet znakov: ',
        es: 'Mínimo número de caracteres: ',
        ru: 'Минимальное количество символов: ',
        rs: 'Minimalni broj znakova: ',
        ua: 'Мінімальна кількість символів: ',
        no: 'Minimum tegn: ',
        it: 'Numero minimo di caratteri: ',
        se: 'Minsta antal tecken: ',
    },
    maxCharacters: {
        us: 'Character limit exceeded',
        cz: 'Překročení limitu počtu znaků',
        pl: 'Przekroczono limit znaków',
        hr: 'Premašeno je ograničenje broja znakova',
        si: 'Presežena omejitev števila znakov',
        de: 'Maximale Länge überschritten',
        sk: 'Prekročenie limitu počtu znakov',
        es: 'Se excedió el límite de caracteres',
        ru: 'Превышение лимита символов',
        rs: 'Ograničenje broja znakova je premašeno',
        ua: 'Перевищено обмеження кількості символів',
        no: 'Maksimal tegngrense overskredet',
        it: 'Superamento del limite di caratteri',
        se: 'Du har överskridit teckengränsen',
    },
    confirmPW: {
        us: 'Confirm password',
        cz: 'Potvrdit heslo',
        pl: 'Potwierdź hasło',
        hr: 'Potvrdi lozinku',
        si: 'Potrdi geslo',
        de: 'Passwort bestätigen',
        sk: 'Potvrdiť heslo',
        es: 'Confirmar contraseña',
        ru: 'Подтвердить пароль',
        rs: 'Potvrdite šifru',
        ua: 'Підтвердьте пароль',
        no: 'Bekreft passord',
        it: 'Confermi la password',
        se: 'Bekräfta lösenord',
    },
    agreementOnSignup: {
        us: 'Consent is necessary for sign up.',
        cz: 'Souhlas je nezbytný pro registraci',
        pl: 'Do rejestracji wymagana jest zgoda.',
        hr: 'Za prijavu je potreban pristanak.',
        si: 'Za prijavo je potrebno soglasje.',
        de: 'Für die Anmeldung ist eine Zustimmung erforderlich.',
        sk: 'Na registráciu je potrebný súhlas.',
        es: 'El consentimiento es necesario para registrarse.',
        ru: 'Для регистрации необходимо согласие.',
        rs: 'Za prijavu je potreban pristanak.',
        ua: 'Для реєстрації потрібна згода.',
        no: 'Samtykke kreves for registrering.',
        it: 'Il consenso è necessario per la registrazione.',
        se: 'Samtycke krävs för registrering.',
    },
    noMatch: {
        us: 'Passwords do not match',
        cz: 'Hesla se neshodují',
        pl: 'Wprowadzone hasła nie są takie same',
        hr: 'Lozinke se ne podudaraju',
        si: 'Gesli se ne ujemata',
        de: 'Passwörter stimmen nicht überein',
        sk: 'Heslá sa nezhodujú',
        es: 'Las contraseñas no coinciden',
        ru: 'Пароли не совпадают',
        rs: 'Šifre se ne podudaraju',
        ua: 'Паролі не співпадають',
        no: 'Passordene samsvarer ikke',
        it: 'Le password non corrispondono',
        se: 'De angivna lösenorden stämmer inte överens',
    },
    alreadyExists: {
        us: 'An account with this e-mail already exists.',
        cz: 'Účet spojený s touto adresou již existuje.',
        pl: 'Konto powiązane z tym adresem e-mail już istnieje.',
        hr: 'Račun s ovom e-mail adresom već postoji.',
        si: 'Račun s tem e-mail naslovom že obstaja.',
        de: 'Ein Konto mit dieser E-mail ist bereits vorhanden.',
        sk: 'Účet spojený s touto adresou už existuje.',
        es: 'Ya existe una cuenta con este e-mail.',
        ru: 'Профиль с таким e-mail уже существует.',
        rs: 'Nalog sa ovom e-mail adresom već postoji.',
        ua: 'Обліковий запис з цією електронною адресою вже існує.',
        no: 'En konto med denne e-postadressen allerede eksisterer.',
        it: "L'account associato a questo indirizzo già esiste.",
        se: 'Det finns redan ett konto med denna e-postadress.',
    },
    yourPassword: {
        us: 'Please input your password',
        cz: 'Vložte prosím své heslo',
        pl: 'Wprowadź swoje hasło',
        hr: 'Molimo unesite svoju lozinku',
        si: 'Prosimo, vnesite svoje geslo',
        de: 'Bitte Ihr Passwort eingeben',
        sk: 'Vložte svoje heslo',
        es: 'Ingrese su contraseña',
        ru: 'Пожалуйста, введите свой пароль',
        rs: 'Molimo unesite svoju šifru',
        ua: 'Будь ласка, введіть свій пароль',
        no: 'Vennligst oppgi passordet ditt',
        it: 'Inserisca la password',
        se: 'Vänligen ange lösenordet',
    },
    signup: {
        us: 'Sign up',
        cz: 'Registrovat',
        pl: 'Zarejestruj',
        hr: 'Registracija',
        si: 'Registracija',
        de: 'Anmelden',
        sk: 'Registrovať',
        es: 'Registrarse',
        ru: 'Зарегистрироваться',
        rs: 'Registracija',
        ua: 'Зареєструватися',
        no: 'Registrer deg',
        it: 'Registrare',
        se: 'Registrera dig',
    },
    createAccount: {
        us: 'Create account',
        cz: 'Vytvořit účet',
        pl: 'Utwórz konto',
        hr: 'Stvori račun',
        si: 'Ustvari račun',
        de: 'Benutzerkonto erstellen',
        sk: 'Vytvoriť účet',
        es: 'Crear una cuenta',
        ru: 'Создать профиль',
        rs: 'Kreiraj nalog',
        ua: 'Створити обліковий запис',
        no: 'Opprette en konto',
        it: 'Creare un account',
        se: 'Skapa ett konto',
    },
    updateNeeded: {
        us: 'Beey needs to be updated.',
        cz: 'Je potřeba aktualizovat Beey.',
        pl: 'Beey wymaga aktualizacji.',
        hr: 'Potrebno je ažurirati Beey',
        si: 'Beey je treba posodobiti.',
        de: 'Beey muss aktualisiert werden.',
        sk: 'Beey je potrebné aktualizovať.',
        es: 'Beey necesita ser actualizado.',
        ru: 'Beey нуждается в обновлении.',
        rs: 'Potrebno je update-ovati Beey.',
        ua: 'Beey потребує оновлення.',
        no: 'Beey trenger å bli oppdatert.',
        it: 'È necessario aggiornare Beey.',
        se: 'Beey behöver uppdateras.',
    },
    pageRefresh: {
        us: 'Please refresh the site.',
        cz: 'Obnovte prosím stránku.',
        pl: 'Odśwież stronę.',
        hr: 'Osvježi stranicu.',
        si: 'Osvežite stran.',
        de: 'Bitte aktualisieren Sie die Webseite.',
        sk: 'Obnovte prosím stránku.',
        es: 'Por favor recarga la página.',
        ru: 'Обновите, пожалуйста, страницу.',
        rs: 'Molimo vas da osvežite sajt.',
        ua: 'Будь ласка, оновіть сайт.',
        no: 'Vennligst oppdater nettsiden.',
        it: 'Aggiorni la pagina.',
        se: 'Vänligen uppdatera sidan.',
    },
    newVersionAvailable: {
        us: 'New Beey version available! Reload the page to use the latest features.',
        cz: 'Je k dispozici nová verze Beey! Obnovte stránku, abyste získali nejnovější funkce.',
        pl: 'Nowa wersja Beey jest już dostępna! Odśwież stronę, aby korzystać z najnowszych funkcji.',
        hr: 'Dostupna je nova verzija! Ponovno učitajte stranicu kako biste koristili najnovije značajke.',
        si: 'Nova različica je na voljo! Ponovno naložite stran, če želite uporabljati najnovejše funkcije.',
        de: 'Neue Beey-Version verfügbar! Laden Sie die Seite neu, um die neuesten Funktionen zu nutzen.',
        sk: 'K dispozícii je nová verzia Beey! Znovu načítajte stránku, aby ste mohli používať najnovšie funkcie.',
        es: '¡Nueva versión de Beey disponible! Vuelva a cargar la página para usar las funciones más recientes.',
        ru: 'Доступна новая версия Beey! Перезагрузите страницу, чтобы использовать последние возможности.',
        rs: 'Dostupna je nova verzija! Ponovno učitajte stranicu kako biste koristili najnovije opcije.',
        ua: 'Доступна нова версія Beey! Перезавантажте сторінку, щоб використовувати найновіші функції.',
        no: 'En ny versjon av Beey er tilgjengelig. Last sida på nytt for å få de nyeste funksjonene.',
        it: 'È disponibile una nuova versione di Beey! Aggiornare la pagina per ottenere le ultime funzionalità.',
        se: 'En ny version av Beey är tillgänglig. Uppdatera sidan för att få tillgång till de nyaste funktionerna.',
    },
    reload: {
        us: 'Reload',
        cz: 'Obnovit',
        pl: 'Odśwież',
        hr: 'Osvježi',
        si: 'Osveži',
        de: 'Neu laden',
        sk: 'Obnoviť',
        es: 'Recargar',
        ru: 'Перезагрузить',
        rs: 'Osveži',
        ua: 'Перезавантажити',
        no: 'Laste på nytt',
        it: 'Aggiornare',
        se: 'Ladda om',
    },
    signupFail: {
        us: 'Sign up failed.',
        cz: 'Při registraci došlo k chybě.',
        pl: 'Rejestracja nie powiodła się.',
        hr: 'Registracija nije uspjela.',
        si: 'Reistracija ni uspela.',
        de: 'Anmeldung fehlgeschlagen.',
        sk: 'Pri registrácii sa vyskytla chyba.',
        es: 'Se produjo un error durante el registro.',
        ru: 'При регистрации произошла ошибка.',
        rs: 'Registracija nije uspela.',
        ua: 'Не вдалося зареєструватися.',
        no: 'Registrering mislykket.',
        it: 'Si è verificato un errore durante la registrazione.',
        se: 'Det gick inte att registrera dig.',
    },
    newPass: {
        us: 'Forgot your password?',
        cz: 'Zapomněli jste heslo?',
        pl: 'Zapomniałeś hasła?',
        hr: 'Zaboravili ste lozinku?',
        si: 'Pozabili ste geslo?',
        de: 'Passwort vergessen?',
        sk: 'Zabudli ste heslo?',
        es: '¿Ha olvidado su contraseña?',
        ru: 'Забыли пароль?',
        rs: 'Zaboravili ste šifru?',
        ua: 'Забули свій пароль?',
        no: 'Har du glemt passordet?',
        it: 'Ha dimenticato la password?',
        se: 'Har du glömt lösenordet?',
    },
    invalidCredentials: {
        us: 'Invalid e-mail or password',
        cz: 'Neplatný e-mail nebo heslo',
        pl: 'Nieprawidłowy e-mail lub hasło',
        hr: 'Nevažeći e-mail ili lozinka',
        si: 'Neveljavna e-pošta ali geslo',
        de: 'Ungültige E-Mail oder Passwort',
        sk: 'Neplatný e-mail alebo heslo',
        es: 'E-mail o contraseña no válidos',
        ru: 'Неверный e-mail или пароль',
        rs: 'Pogrešan e-mail ili lozinka',
        ua: 'Невірна адреса електронної пошти або пароль',
        no: 'Ugyldig e-postadresse eller passord',
        it: "L'indirizzo email o la password non sono valide",
        se: 'Fel e-postadress eller lösenord',
    },
    resetPsw: {
        us: 'Reset password',
        cz: 'Obnovit heslo',
        pl: 'Zresetuj hasło',
        hr: 'Obnovi lozinku',
        si: 'Obnovite geslo',
        de: 'Passwort zurücksetzen',
        sk: 'Obnoviť heslo',
        es: 'Restablecer contraseña',
        ru: 'Обновить пароль',
        rs: 'Resetujte šifru',
        ua: 'Скинути пароль',
        no: 'Tilbakestille passordet',
        it: 'Reimpostazione della password',
        se: 'Återställ lösenord',
    },
    resetPswText: {
        us: 'Enter your account e-mail address to reset your password.',
        cz: 'Zadejte e-mailovou adresu účtu, pro který chcete obnovit heslo.',
        pl: 'Aby zresetować hasło, wprowadź adres e-mail swojego konta.',
        hr: 'Unesite e-mail adresu svog računa kako biste resetirali lozinku.',
        si: 'Vnesite e-naslov svojega računa, da bi resetirali geslo.',
        de: 'Geben Sie die E-Mail-Adresse Ihres Kontos ein, um Ihr Passwort zurückzusetzen.',
        sk: 'Zadajte e-mailovú adresu účtu, pre ktorý chcete obnoviť heslo.',
        es: 'Ingrese la dirección de e-mail de su cuenta para restablecer su contraseña.',
        ru: 'Введите ваш e-mail для обновления пароля.',
        rs: 'Unesite svoju e-mail adresu kako biste resetovali svoju šifru',
        ua: 'Введіть адресу електронної пошти облікового запису, щоб скинути пароль.',
        no: 'Skriv inn kontoens e-postadresse for å tilbakestille passordet ditt.',
        it: "Inserisca l'indirizzo e-mail dell'account per il quale si desidera reimpostare la password.",
        se: 'Ange e-postadressen till kontot för att återställa lösenordet.',
    },
    pleaseNew: {
        us: 'Please input your new password',
        cz: 'Vložte své nové heslo',
        pl: 'Wprowadź nowe hasło',
        hr: 'Molimo unesite svoju novu lozinku',
        si: 'Prosimo, vnesite svoje novo geslo',
        de: 'Bitte Ihr neues Passwort eingeben',
        sk: 'Vložte nové heslo',
        es: 'Ingrese su nueva contraseña',
        ru: 'Пожалуйста, введите новый пароль',
        rs: 'Molimo Vas da unesete svoju novu šifru',
        ua: 'Будь ласка, введіть новий пароль',
        no: 'Oppgi et nytt passord',
        it: 'Inserisca la sua password nuova',
        se: 'Vänligen ange ett nytt lösenord',
    },
    resetRequest: {
        us: 'Password reset request was sent. Please check your e-mail.',
        cz: 'Žádost o obnovu hesla byla odeslána. Zkontrolujte prosím svůj e-mail.',
        pl: 'Wysłaliśmy prośbę o reset hasła. Sprawdź swój e-mail.',
        hr: 'Poslan je zahtjev za poništavanje lozinke. Provjerite e-mail.',
        si: 'Poslana je zahteva za preklic gesla. Preverite e-pošto.',
        de: 'Anfrage zum Zurücksetzen des Passworts gesendet. Überprüfen Sie Ihre E-Mail.',
        sk: 'Žiadosť o obnovu hesla odoslaná. Skontrolujte si svoj e-mail.',
        es: 'Solicitud de restablecimiento de contraseña enviada. Consulte su bandeja de entrada.',
        ru: 'Запрос на обновление пароля был отправлен. Пожалуйста, проверьте свою электронную почту.',
        rs: 'Zahtev za resetovanje šifre je poslat. Proverite svoju e-mail adresu.',
        ua: 'Запит на оновлення пароля надіслано. Будь ласка, перевірте свою електронну пошту.',
        no: 'Forespørsler om tilbakestilling av passordet var sent. Vennligst sjekk din e-post.',
        it: 'La richiesta di reimpostazione della password è stata inviata. Controlli la posta elettronica.',
        se: 'Din begäran om lösenordsåterställning har skickats. Vänligen kontrollera din e-post.',
    },
    resetOk: {
        us: 'Password reset was successful.',
        cz: 'Obnovení hesla bylo úspěšné.',
        pl: 'Resetowanie hasła powiodło się.',
        hr: 'Uspješna promjena lozinke.',
        si: 'Uspešna sprememba gesla.',
        de: 'Zurücksetzung des Passworts erfolgreich.',
        sk: 'Úspešné obnovenie hesla.',
        es: 'Restablecimiento de contraseña fue exitoso.',
        ru: 'Обновление пароля прошло успешно.',
        rs: 'Resetovanje šifre je uspešno.',
        ua: 'Оновлення пароля пройшло успішно.',
        no: 'Tilbakestilling av passordet var vellykket.',
        it: 'La reimpostazione della password è riuscita.',
        se: 'Återställningen av lösenordet lyckades.',
    },
    notExists: {
        us: 'There is no account with this e-mail address.',
        cz: 'Neexistuje účet s danou e-mailovou adresou.',
        pl: 'Konto z tym adresem e-mail nie istnieje.',
        hr: 'Ne postoji račun sa ovom e-mail adresom.',
        si: 'Ne obstaja račun s tem e-mail naslovom.',
        de: 'Es gibt kein Konto mit dieser E-Mail-Adresse.',
        sk: 'Neexistuje účet s danou e-mailovou adresou.',
        es: 'No hay ninguna cuenta con esta dirección de e-mail.',
        ru: 'Профиль с такой электронной почтой не существует.',
        rs: 'Ne postoji nalog sa ovom e-mail adresom.',
        ua: 'Немає облікового запису з цією адресою електронної пошти.',
        no: 'Det finnes ingen konto med denne e-postadressen.',
        it: "Non esiste un account con quell'indirizzo email.",
        se: 'Det finns inget konto med denna e-postadress.',
    },
    resetTokenInvalid: {
        us: 'Password reset request is no longer valid. To reset your password, make a new request.',
        cz: 'Žádost o obnovu hesla už není platná. Pokud chcete heslo obnovit, proveďte novou žádost.',
        pl: 'Żądanie resetu hasła jest nieaktualne. Jeśli chcesz zresetować swoje hasło, prześlij nowe żądanie.',
        hr: 'Zahtjev za resetiranje lozinke više nije važeći. Da biste resetirali lozinku, pošaljite novi zahtjev.',
        si: 'Zahteva za preklic gesla ni več veljavna. Da bi resetirali geslo, pošljite novo zahtevo.',
        de: 'Anforderung zum Zurücksetzen des Passworts ist nicht mehr gültig. Stellen Sie neue Anfrage, um Ihr Passwort zurückzusetzen.',
        sk: 'Žiadosť o obnovu hesla už nie je platná. Ak chcete heslo obnoviť, urobte novú žiadosť.',
        es: 'La solicitud de restablecimiento de contraseña ya no es válida. Para restablecer su contraseña, realice una nueva solicitud.',
        ru: 'Запрос на обновление пароля больше не действителен. Если хотите обновить пароль, отправьте новый запрос.',
        rs: 'Zahtev za resetovanje šifre nije više važeći. Da biste resetovali svoju šifru, podnesite novi zahtev.',
        ua: 'Запит на оновлення пароля більше не дійсний. Щоб оновити пароль, зробіть новий запит.',
        no: 'Forespørsler om tilbakestilling av passordet er ikke lenger gyldig. Gjør en ny forespørsler for å tilbakestille passordet.',
        it: 'La richiesta di reimpostazione della password non è più valida. Per reimpostare la password, effettui una nuova richiesta.',
        se: 'Din begäran om lösenordsåterställning är inte längre giltig. Vänligen skicka en ny begäran för att återställa lösenordet.',
    },
    accountBlocked: {
        us: 'Your account has been blocked. For more information, contact support at support@beey.io',
        cz: 'Váš účet byl zablokován. Pro více informací kontaktujte podporu na support@beey.io',
        pl: 'Twoje konto zostało zablokowane. Aby uzyskać więcej informacji, skontaktuj się z naszym teamem pomocy technicznej support@beey.io',
        hr: 'Vaš je račun blokiran. Za više informacija kontaktirajte s podrškom na support@beey.io',
        si: 'Vaš račun je blokiran. Za več informacij se obrnite na podporo na support@beey.io',
        de: 'Ihr Konto wurde gesperrt. Für weitere Informationen wenden Sie sich bitte an den Support unter support@beey.io',
        sk: 'Vaše konto bolo zablokované. Pre viac informácií kontaktujte support@beey.io',
        es: 'Su cuenta ha sido bloqueada. Para obtener más información, póngase en contacto con el soporte en support@beey.io',
        ru: 'Ваш профиль был заблокирован. Для более детальной информации свяжитесь со службой поддержки support@beey.io',
        rs: 'Vaš nalog je blokiran. Za više informacija kontaktirajte podršku na support@beey.io',
        ua: 'Ваш обліковий запис заблоковано. Для отримання додаткової інформації зв’яжіться зі службою підтримки за адресою support@beey.io',
        no: 'Kontoen din har blitt sperret. Kontakt oss på support@beey.io for mer informasjon.',
        it: "Il suo account è stato bloccato. Per ulteriori informazioni, contatti l'assistenza all'indirizzo support@beey.io",
        se: 'Ditt konto har spärrats. Kontakta vår kundservice på support@beey.io för mer information.',
    },
    checkVpn: {
        us: 'Check your VPN connection or contact your administrator.',
        cz: 'Zkontrolujte připojení k VPN nebo kontaktujte administrátora.',
        pl: 'Sprawdź połączenie VPN lub skontaktuj się z administratorem.',
        hr: 'Provjerite VPN vezu ili se obratite administratoru.',
        si: 'Preverite VPN povezavo ali se obrnite na administratorja.',
        de: 'Überprüfen Sie Ihre VPN-Verbindung oder wenden Sie sich an Ihren Administrator.',
        sk: 'Skontrolujte pripojenie VPN alebo sa obráťte na administrátora.',
        es: 'Verifique su conexión VPN o contacte a su administrador.',
        ru: 'Проверьте VPN-соединение или обратитесь к администратору.',
        rs: 'Proverite vašu VPN vezu ili kontaktirajte vašeg administratora.',
        ua: "Перевірте своє VPN-з'єднання або зверніться до адміністратора.",
        no: 'Sjekk VPN-tilkobling eller ta kontakt med administratoren din.',
        it: "Controllare la connessione VPN o contattare l'amministratore.",
        se: 'Kontrollera VPN-anslutningen eller kontakta administratören.',
    },
    accessDenied: {
        us: 'Access denied',
        cz: 'Neoprávněný přístup',
        pl: 'Nieautoryzowany dostęp',
        hr: 'Pristup odbijen',
        si: 'Dostop zavrnjen',
        de: 'Zugriff verweigert',
        sk: 'Neoprávnený prístup',
        es: 'Acceso denegado',
        ru: 'Доступ запрещен',
        rs: 'Pristup odbijen',
        ua: 'У доступі відмовлено',
        no: 'Tilgang nekter',
        it: 'Accesso non autorizzato',
        se: 'Åtkomst nekas',
    },
    tenantDenied: {
        us: 'Your tenant is not authorized.',
        cz: 'Váš tenant není autorizován.',
        pl: 'Twój tenant nie jest autoryzowany.',
        hr: 'Vaš tenant nije ovlašten.',
        si: 'Vaš tenant ni pooblaščen.',
        de: 'Ihr Tenant ist nicht berechtigt.',
        sk: 'Váš tenant nie je autorizovaný.',
        es: 'Su inquilino no está autorizado.',
        ru: 'Ваш тенант не авторизован.',
        rs: 'Vaš tenant nije ovlašćen.',
        ua: 'Ваш тенант не авторизований.',
        no: 'Din tenant er ikke autorisert.',
        it: 'Il vostro tenant non è autorizzato.',
        se: 'Din tenant är inte autoriserad.',
    },
    contactAdmin: {
        us: 'Unexpected error. Please contact your administrator',
        cz: 'Neočekávaná chyba. Kontaktujte prosím administrátora',
        pl: 'Nieoczekiwany błąd. Skontaktuj się z administratorem',
        hr: 'Neočekivana pogreška. Obratite se administratoru',
        si: 'Nepričakovana napaka. Prosimo, obrnite se na svojega administratorja',
        de: 'Unerwarteter Fehler. Bitte kontaktieren Sie Ihren Administrator',
        sk: 'Neočakávaná chyba. Kontaktujte svojho administrátora',
        es: 'Error inesperado. Por favor, contacte a su administrador',
        ru: 'Неожиданная ошибка. Обратитесь к администратору',
        rs: 'Neočekivana greška. Molimo kontaktirajte vašeg administratora',
        ua: 'Неочікувана помилка. Зверніться до адміністратора',
        no: 'Uventet feil. Vennligst ta kontakt med administratoren din',
        it: "Errore imprevisto. Contattare l'amministratore",
        se: 'Ett oväntat fel har uppstått. Vänligen kontakta administratören',
    },
    unexpectedError: {
        us: 'Unexpected error',
        cz: 'Neočekávaná chyba',
        pl: 'Nieoczekiwany błąd',
        hr: 'Neočekivana greška',
        si: 'Nepričakovana napaka',
        de: 'Unerwarteter Fehler',
        sk: 'Nečakaná chyba',
        es: 'Error inesperado',
        ru: 'Непредвиденная ошибка',
        rs: 'Neočekivana greška',
        ua: 'Неочікувана помилка',
        no: 'Uventet feil',
        it: 'Errore inatteso',
        se: 'Oväntat fel',
    },
    failedToUploadTrsx: {
        us: 'Invalid format of TRSX file',
        cz: 'Neplatný formát TRSX souboru',
        pl: 'Nieprawidłowy format pliku TRSX',
        hr: 'Nevažeći format TRSX datoteke',
        si: 'Neveljavna oblika datoteke TRSX',
        de: 'Ungültiges Format der TRSX-Datei',
        sk: 'Neplatný formát TRSX súboru',
        es: 'Formato de archivo TRSX no válido',
        ru: 'Недействительный формат файла TRSX',
        rs: 'Pogrešan format TRSX datoteke',
        ua: 'Недійсний формат файлу TRSX',
        no: 'Ugyldig format av TRSX fil',
        it: 'Formato file TRSX non valido',
        se: 'Ogiltigt format på TRSX-filen',
    },
    projects: {
        us: 'Projects',
        cz: 'Projekty',
        pl: 'Projekty',
        hr: 'Projekti',
        si: 'Projekti',
        de: 'Projekte',
        sk: 'Projekty',
        es: 'Proyectos',
        ru: 'Проекты',
        rs: 'Projekti',
        ua: 'Проекти',
        no: 'Prosjekter',
        it: 'Progetti',
        se: 'Projekt',
    },
    edit: {
        us: 'Editor',
        cz: 'Editor',
        pl: 'Edytor',
        hr: 'Uredi',
        si: 'Uredi',
        de: 'Editor',
        sk: 'Editor',
        es: 'Editor',
        ru: 'Редактор',
        rs: 'Editor',
        ua: 'Редактор',
        no: 'Editor',
        it: 'Editore',
        se: 'Editor',
    },
    account: {
        us: 'Account',
        cz: 'Účet',
        pl: 'Konto',
        hr: 'Račun',
        si: 'Račun',
        de: 'Konto',
        sk: 'Účet',
        es: 'Cuenta',
        ru: 'Профиль',
        rs: 'Nalog',
        ua: 'Профіль',
        no: 'Konto',
        it: 'Account',
        se: 'Konto',
    },
    admin: {
        us: 'Administration',
        cz: 'Administrace',
        pl: 'Administracja',
        hr: 'Administracija',
        si: 'Administracija',
        de: 'Administration',
        sk: 'Administrácia',
        es: 'Administración',
        ru: 'Администрация',
        rs: 'Administracija',
        ua: 'Адміністрація',
        no: 'Administrasjon',
        it: 'Amministrazione',
        se: 'Administration',
    },
    userlex: {
        us: 'Rules',
        cz: 'Pravidla',
        pl: 'Reguły',
        hr: 'Pravila',
        si: 'Pravila',
        de: 'Regeln',
        sk: 'Pravidlá',
        es: 'Reglas',
        ru: 'Правила',
        rs: 'Pravila',
        ua: 'Правила',
        no: 'Regler',
        it: 'Regole',
        se: 'Regler',
    },
    speakers: {
        us: 'Speakers',
        cz: 'Mluvčí',
        pl: 'Mówcy',
        hr: 'Govornici',
        si: 'Govorci',
        de: 'Sprecher',
        sk: 'Rečníci',
        es: 'Hablantes',
        ru: 'Спикеры',
        rs: 'Govornici',
        ua: 'Спікери',
        no: 'Talere',
        it: 'Parlanti',
        se: 'Talare',
    },
    users: {
        us: 'Users',
        cz: 'Uživatelé',
        pl: 'Użytkownicy',
        hr: 'Korisnici',
        si: 'Uporabnik',
        de: 'Benutzer',
        sk: 'Používatelia',
        es: 'Usuarios',
        ru: 'Пользователи',
        rs: 'Korisnici',
        ua: 'Користувачі',
        no: 'Brukere',
        it: 'Utenti',
        se: 'Användare',
    },
    announcements: {
        us: 'Announcements',
        cz: 'Oznámení',
        pl: 'Powiadomienie',
        hr: 'Najave',
        si: 'Obvestilo',
        de: 'Ankündigungen',
        sk: 'Oznámenie',
        es: 'Anuncios',
        ru: 'Уведомление',
        rs: 'Najave',
        ua: 'Оголошення',
        no: 'Meldinger',
        it: 'Notifiche',
        se: 'Meddelanden',
    },
    apps: {
        us: 'Apps',
        cz: 'Aplikace',
        pl: 'Aplikacje',
        hr: 'Aplikacije',
        si: 'Aplikacije',
        de: 'Apps',
        sk: 'Aplikácie',
        es: 'Aplicaciones',
        ru: 'Приложения',
        rs: 'Aplikacije',
        ua: 'Аплікації',
        no: 'Applikasjoner',
        it: 'Applicazioni',
        se: 'Appar',
    },
    confirmLeaving: {
        us: 'Leave the editor?',
        cz: 'Opravdu odejít z editoru?',
        pl: 'Czy naprawdę chcesz opuścić edytor?',
        hr: 'Želite li napustiti uređivanje?',
        si: 'Ali želite zapustiti urejanje?',
        de: 'Editor wirklich verlassen?',
        sk: 'Naozaj odísť z editora?',
        es: '¿Salir del editor?',
        ru: 'Выйти из редактора',
        rs: 'Napustiti editor?',
        ua: 'Вийти із редактора?',
        no: 'Gå ut av Editor?',
        it: "Lasciare davvero l'editore?",
        se: 'Vill du stänga editorn?',
    },
    leave: {
        us: 'Yes, leave',
        cz: 'Ano, odejít',
        pl: 'Tak, odejdź',
        hr: 'Da, napusti',
        si: 'Ja, zapusti',
        de: 'Ja, verlassen',
        sk: 'Áno, odísť',
        es: 'Sí, salir',
        ru: 'Да, выйти',
        rs: 'Da, napusti',
        ua: 'Так, вийти',
        no: 'Ja, gå ut',
        it: 'Sì, lasciare',
        se: 'Ja, stäng editorn',
    },
    remainingCredit: {
        us: 'Remaining credit',
        cz: 'Zbývající kredit',
        pl: 'Pozostały kredyt',
        hr: 'Preostali kredit',
        si: 'Preostali kredit',
        de: 'Verbleibender Kredit',
        sk: 'Zostávajúce kredit',
        es: 'Crédito restante',
        ru: 'Остаток на балансе',
        rs: 'Preostali kredit',
        ua: 'Залишок на балансі',
        no: 'Gjenværende kreditt',
        it: 'Credito rimanente',
        se: 'Återstående kredit',
    },
    givenup: {
        us: 'Unable to reconnect, please refresh the browser',
        cz: 'Nepodařilo se připojit, obnovte prosím prohlížeč',
        pl: 'Próba połączenia nie powiodła się, odśwież stronę',
        hr: 'Ponovno povezivanje je prekinuto, osvježite preglednik',
        si: 'Ponovno povezovanje je prekinjeno, osvežite brskalnik',
        de: 'Wiederverbindung aufgegeben, aktualisieren Sie den Browser',
        sk: 'Pokus o pripojenie zlyhal, obnovte prehliadač',
        es: 'No se pudo volver a conectar, recarga el navegador',
        ru: 'Не удалось подключиться, обновите браузер',
        rs: 'Ponovo povezivanje davanja, osvežavanje pregledača',
        ua: 'Не вдалося підключитись, оновіть браузер',
        no: 'Tilkobling mislykket, vennligst oppdater nettleseren',
        it: 'Impossibile connettersi, aggiornare il browser',
        se: 'Anslutning misslyckades, vänligen uppdatera webbläsaren',
    },
    reconnecting: {
        us: 'Reconnecting',
        cz: 'Připojujeme',
        pl: 'Łączymy',
        hr: 'Ponovno spajanje',
        si: 'Ponovno povezovanje',
        de: 'Wiederverbindung',
        sk: 'Pripájame',
        es: 'Reconectando',
        ru: 'Повторное соединение',
        rs: 'Ponovno povezivanje',
        ua: "Повторне з'єднання",
        no: 'Tilkobles',
        it: 'Collegamento',
        se: 'Återansluter',
    },
    projectNum: {
        us: 'Project #',
        cz: 'Projekt č.',
        pl: 'Projekt nr',
        hr: 'Projekt br.',
        si: 'Projekt št.',
        de: 'Projekt Nr.',
        sk: 'Projekt č.',
        es: 'Proyecto núm.',
        ru: 'Проект №',
        rs: 'Projekat broj',
        ua: 'Проект №',
        no: 'Prosjekt nr.',
        it: 'Progetto nr.',
        se: 'Projekt nr',
    },
    uploading: {
        us: 'Uploading',
        cz: 'Nahrávám',
        pl: 'Wczytuję',
        hr: 'Učitavanje',
        si: 'Nalaganje',
        de: 'Hochladen',
        sk: 'Nahrávam',
        es: 'Subiendo',
        ru: 'Загружается',
        rs: 'Učitavanje',
        ua: 'Завантаження',
        no: 'Laster opp',
        it: 'Caricamento',
        se: 'Laddar upp',
    },
    uploaded: {
        us: 'Uploaded',
        cz: 'Nahráno',
        pl: 'Wczytano',
        hr: 'Učitano',
        si: 'Preneseno',
        de: 'Hochgeladen',
        sk: 'Nahrané',
        es: 'Subido',
        ru: 'Загружено',
        rs: 'Učitano',
        ua: 'Завантажено',
        no: 'Lastet opp',
        it: 'Caricato',
        se: 'Uppladdat',
    },
    uploadCompleteMsg: {
        us: 'Upload complete',
        cz: 'Nahrávání dokončeno',
        pl: 'Zakończono wczytywanie',
        hr: 'Prijenos je dovršen',
        si: 'Nalaganje je končano',
        de: 'Hochladen beendet',
        sk: 'Nahrávanie dokončené',
        es: 'Carga completa',
        ru: 'Загрузка завершена',
        rs: 'Prenos je završen',
        ua: 'Завантаження завершено',
        no: 'Opplasting fullført',
        it: 'Caricamento finito',
        se: 'Uppladdning klar',
    },
    uploadFinishedMsg: {
        us: 'Upload was successful',
        cz: 'Úspěšně nahráno',
        pl: 'Wczytywanie się powiodło',
        hr: 'Prijenos je uspješan',
        si: 'Nalaganje uspešno',
        de: 'Erfolgreicher Upload',
        sk: 'Úspešné nahranie',
        es: 'Carga exitosa',
        ru: 'Загрузка прошла успешно',
        rs: 'Otpremanje je uspešno',
        ua: 'Завантаження пройшло успішно',
        no: 'Opplasting vellykket',
        it: 'Caricato con successo',
        se: 'Uppladdningen lyckades',
    },
    uploadConnectingMsg: {
        us: 'Connecting...',
        cz: 'Připojování...',
        pl: 'Łączę...',
        hr: 'Spajanje...',
        si: 'Povezovanje...',
        de: 'Verbindung wird hergestellt...',
        sk: 'Pripájanie...',
        es: 'Conectando...',
        ru: 'Соединение...',
        rs: 'Spajanje...',
        ua: 'Підключення...',
        no: 'Kobles...',
        it: 'Collegamento in corso...',
        se: 'Ansluter...',
    },
    uploadSeveredMsg: {
        us: 'Upload was interrupted, please refresh the browser',
        cz: 'Nahrávání bylo přerušeno, obnovte prosím prohlížeč',
        pl: 'Wczytywanie zostało przerwane, odśwież przeglądarkę',
        hr: 'Prijenos je prekinut, osvježite preglednik',
        si: 'Prenos je prekinjen, osvežite brskalnik',
        de: 'Upload unterbrochen, aktualisieren Sie den Browser',
        sk: 'Nahrávanie sa prerušilo, obnovte prehliadač',
        es: 'Carga interrumpida, actualiza el navegador',
        ru: 'Загрузка была прервана. Пожалуйста обновите браузер',
        rs: 'Prenos je prekinut, osvežite stranicu',
        ua: 'Завантаження перервано, оновіть веб-переглядач',
        no: 'Opplastingen avbrutt, vennligst oppdater nettleseren',
        it: 'Il caricamento è stato interrotto, si prega di aggiornare il browser',
        se: 'Uppladdningen avbröts, vänligen uppdatera webbläsaren',
    },
    uploadCanceledMsg: {
        us: 'Upload was canceled',
        cz: 'Nahrávání bylo zrušeno',
        pl: 'Wczytywanie zostało anulowane',
        hr: 'Prijenos je otkazan',
        si: 'Prenos je razveljavljen',
        de: 'Upload wurde abgebrochen',
        sk: 'Nahranie bolo zrušené',
        es: 'Carga cancelada',
        ru: 'Загрузка отменена',
        rs: 'Otpremanje je otkazano',
        ua: 'Завантаження скасовано',
        no: 'Opplastingen annullert',
        it: 'La registrazione è stata annullata',
        se: 'Du har avbrutit uppladdningen',
    },
    uploadFailedMsg: {
        us: 'Upload failed',
        cz: 'Nahrávání selhalo',
        pl: 'Wczytywanie nie powiodło się',
        hr: 'Prijenos nije uspio',
        si: 'Prenos ni uspel',
        de: 'Upload fehlgeschlagen',
        sk: 'Nahrávanie zlyhalo',
        es: 'Error al cargar',
        ru: 'Загрузка не удалась',
        rs: 'Otpremanje nije uspelo',
        ua: 'Помилка завантаження',
        no: 'Opplastingen mislykket',
        it: 'Registrazione fallita',
        se: 'Uppladdningen misslyckades',
    },
    uploadInQueue: {
        us: 'File upload is in queue',
        cz: 'Nahrávání souboru je ve frontě',
        pl: 'Wczytywanie pliku z kolejki',
        hr: 'Prijenos datoteke na čekanju',
        si: 'Prenos datoteke na čakanju',
        de: 'Datei-Upload in Warteschlange',
        sk: 'Nahrávanie súborov je vo fronte',
        es: 'Carga del archivo está en cola',
        ru: 'Файл в очереди на загрузку',
        rs: 'Prenos datoteke na čekanju',
        ua: 'Завантаження файлів стоїть у черзі',
        no: 'Filen står i kø for å lastes opp',
        it: 'Il caricamento di file è in coda',
        se: 'Filen står i kö för att laddas upp',
    },
    activationEmail: {
        us: 'Send a new activation link',
        cz: 'Zaslat nový aktivační e-mail',
        pl: 'Wyślij nowy link aktywacyjny',
        hr: 'Pošalji novu poveznicu za aktivaciju',
        si: 'Pošljite novo povezavo za aktivacijo',
        de: 'Neuen Aktivierungslink senden',
        sk: 'Zaslať nový aktivačný e-mail',
        es: 'Enviar un nuevo enlace de activación',
        ru: 'Отправить новое письмо активации',
        rs: 'Pošalji novi aktivacioni link',
        ua: 'Надішліть нове посилання для активації',
        no: 'Sende en ny aktiveringslenke',
        it: 'Inviare una nuova e-mail di attivazione',
        se: 'Skicka en ny aktiveringslänk',
    },
    activationEmailSent: {
        us: 'New activation link has been sent. Please check your e-mail.',
        cz: 'Nový aktivační e-mail byl zaslán. Zkontrolujte prosím svůj e-mail.',
        pl: 'Nowy link aktywacyjny został wysłany. Sprawdź swój e-mail.',
        hr: 'Poslana je nova poveznica za aktivaciju. Provjerite e-mail.',
        si: 'Poslana je nova povezava za aktivacijo. Preverite e-pošto.',
        de: 'Neuer Aktivierungslink wurde gesendet. Überprüfen Sie Ihr E-Mail.',
        sk: 'Nový aktivačný e-mail bol zaslaný. Skontrolujte si svoj e-mail.',
        es: 'Se ha enviado un nuevo enlace de activación. Consulte su bandeja de entrada.',
        ru: 'Новое письмо активации было отправлено. Проверьте свой e-mail.',
        rs: 'Novi aktivacioni link je poslat. Proverite svoj e-mail.',
        ua: 'Нове посилання для активації надіслано. Будь ласка, перевірте свою електронну пошту.',
        no: 'En ny aktiveringslenke har blitt sent. Vennligst sjekk innboksen din.',
        it: 'È stata inviata una nuova e-mail di attivazione. Controlli la posta elettronica.',
        se: 'En ny aktiveringslänk har skickats. Vänligen kontrollera din e-post.',
    },
    sendingEmailFailed: {
        us: 'Request for activation link failed.',
        cz: 'Žádost o aktivační e-mail selhala.',
        pl: 'Wysłanie prośby o link aktywacyjny się nie powiodło.',
        hr: 'Zahtjev za poveznicu za aktivaciju nije uspio.',
        si: 'Zahteva za aktivacijsko povezavo ni uspela.',
        de: 'Anforderung für den Aktivierungslink ist fehlgeschlagen.',
        sk: 'Žiadosť o aktivačný e-mail zlyhala.',
        es: 'La solicitud de enlace de activación ha fallado.',
        ru: 'При запросе на ссылку активации возникла ошибка.',
        rs: 'Zahtev za aktivacioni link nije uspelo.',
        ua: 'Не вдалося надіслати запит на посилання для активації.',
        no: 'Forespørsler om en ny aktiveringslenke mislykket.',
        it: "La richiesta di un'e-mail di attivazione è fallita.",
        se: 'Begäran om en ny aktiveringslänk misslyckades.',
    },
    clearFilter: {
        us: 'Clear filter',
        cz: 'Zrušit filtr',
        pl: 'Usuń filtr',
        hr: 'Očisti filter',
        si: 'Očisti filter',
        de: 'Filter löschen',
        sk: 'Zrušiť filter',
        es: 'Restablecer filtro',
        ru: 'Очистить фильтр',
        rs: 'Obrisati filter',
        ua: 'Очистити фільтр',
        no: 'Tøm filter',
        it: 'Cancelli il filtro',
        se: 'Radera filter',
    },
    thisYear: {
        us: 'This year',
        cz: 'Tento rok',
        pl: 'Bieżący rok',
        hr: 'Tekuća godina',
        si: 'Tekoče leto',
        de: 'Dieses Jahr',
        sk: 'Tento rok',
        es: 'Este año',
        ru: 'Этот год',
        rs: 'Ova godina',
        ua: 'Цього року',
        no: 'Dette året',
        it: 'Questo anno',
        se: 'Detta år',
    },
    thisMonth: {
        us: 'This month',
        cz: 'Tento měsíc',
        pl: 'Bieżący miesiąc',
        hr: 'Tekući mjesec',
        si: 'Tekoči mesec',
        de: 'Dieser Monat',
        sk: 'Tento mesiac',
        es: 'Este mes',
        ru: 'Этот месяц',
        rs: 'Ovog meseca',
        ua: 'Цього місяця',
        no: 'Denne måneden',
        it: 'Questo mese',
        se: 'Denna månad',
    },
    thisWeek: {
        us: 'This week',
        cz: 'Tento týden',
        pl: 'Bieżący tydzień',
        hr: 'Tekući tjedan',
        si: 'Tekoči teden',
        de: 'Diese Woche',
        sk: 'Tento týždeň',
        es: 'Esta semana',
        ru: 'Эта неделя',
        rs: 'Ova sedmica',
        ua: 'Цього тижня',
        no: 'Denne uka',
        it: 'Questa settimana',
        se: 'Denna vecka',
    },
    today: {
        us: 'Today',
        cz: 'Dnes',
        pl: 'Dzisiaj',
        hr: 'Danas',
        si: 'Danes',
        de: 'Heute',
        sk: 'Dnes',
        es: 'Hoy',
        ru: 'Сегодня',
        rs: 'Danas',
        ua: 'Сьогодні',
        no: 'I dag',
        it: 'Oggi',
        se: 'I dag',
    },
    startDate: {
        us: 'Start date',
        cz: 'Datum od',
        pl: 'Data od',
        hr: 'Datum početka',
        si: 'Datum začetka',
        de: 'Anfangsdatum',
        sk: 'Dátum od',
        es: 'Desde fecha',
        ru: 'Дата начала',
        rs: 'Početni datum',
        ua: 'Дата початку',
        no: 'Dato fra',
        it: 'Data da',
        se: 'Startdatum',
    },
    endDate: {
        us: 'End date',
        cz: 'Datum do',
        pl: 'Data do',
        hr: 'Datum završetka',
        si: 'Končni datum',
        de: 'Enddatum',
        sk: 'Dátum do',
        es: 'Hasta fecha',
        ru: 'Дата окончания',
        rs: 'Završni datum',
        ua: 'Кінцева дата',
        no: 'Dato til',
        it: 'Data a',
        se: 'Slutdatum',
    },
    filter: {
        us: 'Filter',
        cz: 'Filtrovat',
        pl: 'Filtruj',
        hr: 'Filtriraj',
        si: 'Filtriraj',
        de: 'Filtern',
        sk: 'Filtrovať',
        es: 'Filtrar',
        ru: 'Фильтровать',
        rs: 'Filter',
        ua: 'Фільтр',
        no: 'Filtrere',
        it: 'Filtro',
        se: 'Filtrera',
    },
    triggerDesc: {
        us: 'Click to sort descending',
        cz: 'Klikněte pro sestupné řazení',
        pl: 'Kliknij, aby posortować malejąco',
        hr: 'Kliknite za silazno sortiranje',
        si: 'Kliknite za padajoče razvrščanje',
        de: 'Absteigend sortieren',
        sk: 'Kliknite pre zostupné zoradenie',
        es: 'Haga clic para ordenar descendente',
        ru: 'Нажмите для сортировки по убыванию',
        rs: 'Kliknite za silazno sortiranje',
        ua: 'Натисніть, щоб сортувати за спаданням',
        no: 'Klikk for å sortere synkende',
        it: "Faccia clic per l'ordine discendente",
        se: 'Klicka för att sortera i fallande ordning',
    },
    triggerAsc: {
        us: 'Click to sort ascending',
        cz: 'Klikněte pro vzestupné řazení',
        pl: 'Kliknij, aby posortować rosnąco',
        hr: 'Kliknite za uzlazno sortiranje',
        si: 'Kliknite za naraščajoče razvrščanje',
        de: 'Aufsteigend sortieren',
        sk: 'Kliknutím získate vzostupné zoradenie',
        es: 'Haga clic para ordenar ascendente',
        ru: 'Нажмите для сортировки по возрастанию',
        rs: 'Kliknite za uzlazno sortiranje',
        ua: 'Натисніть, щоб сортувати за зростанням',
        no: 'Klikk for å sortere stigende',
        it: "Faccia clic per l'ordine crescente",
        se: 'Klicka för att sortera i stigande ordning',
    },
    cancelSort: {
        us: 'Click to cancel sorting',
        cz: 'Klikněte pro zrušení řazení',
        pl: 'Kliknij, aby anulować sortowanie',
        hr: 'Kliknite za odustajanje od sortiranja',
        si: 'Kliknite za preklic razvrščanja',
        de: 'Sortierung abbrechen',
        sk: 'Kliknutím zrušíte zoradenie',
        es: 'Haga clic para cancelar clasificación',
        ru: 'Нажмите для отмены сортировки',
        rs: 'Kliknite za odustajanje od sortiranja',
        ua: 'Натисніть, щоб скасувати сортування',
        no: 'Klikk for å slette sortering',
        it: "Faccia clic per annullare l'ordinamento",
        se: 'Klicka för att sluta sortera',
    },
    missingRecording: {
        us: 'Missing recording',
        cz: 'Chybí nahrávka',
        pl: 'Brak nagrania',
        hr: 'Nedostaje snimka',
        si: 'Manjka posnetek',
        de: 'Fehlende Aufnahme',
        sk: 'Chýbajúca nahrávka',
        es: 'Grabación faltante',
        ru: 'Отсутствует запись',
        rs: 'Nedostaje snimak',
        ua: 'Відсутній запис',
        no: 'Manglende innspilling',
        it: 'Registrazione mancante',
        se: 'Inspelning saknas',
    },
    missingRecordingBody: {
        us: 'No recording was found in the project. If you are transcribing a live stream, please try refreshing the site. Otherwise, try uploading the project again. No credit will be charged.',
        cz: 'V projektu nebyla nalezena nahrávka. Pokud přepisujete živý stream, obnovte prosím stránku, nahrávka se po chvilce načte. V opačném případě zkuste projekt nahrát znovu. Žádný kredit vám nebude odečten.',
        pl: 'W projekcie nie znaleziono nagrania. Jeśli transkrybujesz stream na żywo, odśwież stronę, nagranie załaduje się po chwili. W przeciwnym razie spróbuj wgrać projekt ponownie. Żaden kredyt nie został odliczony.',
        hr: 'U projektu nije pronađena ni jedna snimka. Ako transkribirate prijenos uživo, pokušajte osvježiti stranicu. U suprotnom pokušajte ponovno učitati projekt. Kredit neće biti naplaćen.',
        si: 'V projektu ni bilo najdenih nobenih posnetkov. Če prepisujete prenos v živo, poskusite osvežiti spletno stran. V nasprotnem primeru poskusite znova naložiti projekt. Kredit ne bo zaračunan.',
        de: 'Keine Aufzeichnung wurde im Projekt gefunden. Wenn Sie einen Live-Stream transkribieren, versuchen Sie die Website zu aktualisieren. Andernfalls versuchen Sie das Projekt erneut hochzuladen. Es wird kein Kredit abgerechnet.',
        sk: 'V projekte sa nenašla žiadna nahrávka. Ak prepisujete živý stream, skúste stránku obnoviť. V opačnom prípade skúste projekt nahrať znova. Žiadny kredit nebude odpočítaný.',
        es: 'No se encontró ninguna grabación en el proyecto. Si está transcribiendo una transmisión en vivo, intente actualizar el sitio. De lo contrario, intente cargar el proyecto nuevamente. No se cargará ningún crédito.',
        ru: 'В проекте не найдено ни одной записи. Если вы транскрибируете прямую трансляцию, попробуйте обновить сайт. В противном случае попробуйте загрузить проект еще раз. Кредит не взимается.',
        rs: 'Nije pronađen nijedan snimak u projektu. Ako transkribujete strim uživo, pokušajte da osvežite sajt. U suprotnom, pokušajte ponovo da otpremite projekat. Kredit neće biti naplaćen.',
        ua: 'У проекті не знайдено жодного запису. Якщо ви транскрибуєте пряму трансляцію, спробуйте оновити сайт. В іншому випадку спробуйте завантажити проект ще раз. Кредит не стягується.',
        no: 'Ingen innspilling funnet i prosjektet. Prøv å oppdatere nettleseren hvis du transkriberer en direkte strømming. Ellers prøv å laste opp prosjektet igjen. Ingen kreditt vil belastes.',
        it: "Non è stata trovata alcuna registrazione nel progetto. Se sta trascrivendo uno streaming dal vivo, aggiorni la pagina: la registrazione si caricherà dopo un po'. In caso contrario, provi a caricare nuovamente il progetto. Non verrà detratto alcun credito.",
        se: 'Ingen inspelning hittades i projektet. Om du transkriberar en livesändning, vänligen prova att uppdatera webbsidan. Annars prova igen att ladda upp projektet. Ingen kredit kommer att debiteras.',
    },
    waitingForRecording: {
        us: 'Waiting for recording...',
        cz: 'Čekám na nahrávku...',
        pl: 'Czekam na nagranie...',
        hr: 'Čekanje na snimku...',
        si: 'Čakanje na posnetek...',
        de: 'Warten auf die Aufnahme...',
        sk: 'Čakám na nahrávku...',
        es: 'Esperando la grabación...',
        ru: 'Ожидание записи...',
        rs: 'Čekanje na snimak...',
        ua: 'Чекаємо на запис...',
        no: 'Venter på innspillingen...',
        it: 'Sto aspettando la registrazione...',
        se: 'Väntar på inspelningen...',
    },
    failedProject: {
        us: 'Processing failed',
        cz: 'Zpracování selhalo',
        pl: 'Przetwarzanie nie powiodło się',
        hr: 'Obrada nije uspjela',
        si: 'Obdelava ni uspela',
        de: 'Verarbeitung fehlgeschlagen',
        sk: 'Spracovanie zlyhalo',
        es: 'Procesamiento fallido',
        ru: 'Обработка не удалась',
        rs: 'Obrada nije uspela',
        ua: 'Помилка обробки',
        no: 'Behandling mislykket',
        it: 'Elaborazione fallita',
        se: 'Bearbetning misslyckades',
    },
    canceledProject: {
        us: 'Canceled project',
        cz: 'Zrušený projekt',
        pl: 'Anulowany projekt',
        hr: 'Otkazan projekt',
        si: 'Razveljavljen projekt',
        de: 'Abgebrochenes Projekt',
        sk: 'Zrušený projekt',
        es: 'Proyecto cancelado',
        ru: 'Отмененные проекты',
        rs: 'Otkazan projekat',
        ua: 'Скасований проект',
        no: 'Prosjekt kansellert',
        it: 'Progetto annullato',
        se: 'Avbrutet projekt',
    },
    videoProject: {
        us: 'Video project',
        cz: 'Video projekt',
        pl: 'Projekt wideo',
        hr: 'Videoprojekt',
        si: 'Video projekt',
        de: 'Video-Projekt',
        sk: 'Video projekt',
        es: 'Proyecto de video',
        ru: 'Видеопроект',
        rs: 'Video projekat',
        ua: 'Відеопроект',
        no: 'Videoprosjekt',
        it: 'Progetto video',
        se: 'Videoprojekt',
    },
    audioProject: {
        us: 'Audio project',
        cz: 'Audio projekt',
        pl: 'Projekt audio',
        hr: 'Audioprojekt',
        si: 'Avdio projekt',
        de: 'Audio-Projekt',
        sk: 'Audio projekt',
        es: 'Proyecto de audio',
        ru: 'Аудиопроект',
        rs: 'Audio projekat',
        ua: 'Аудіопроект',
        no: 'Lydprosjekt',
        it: 'Progetto audio',
        se: 'Ljudprojekt',
    },
    transcribing: {
        us: 'Transcribing',
        cz: 'Přepisuji',
        pl: 'Transkrybuję',
        hr: 'Transkripcija u tijeku',
        si: 'Prepis v poteku',
        de: 'Transkription läuft',
        sk: 'Prebieha prepis',
        es: 'Transcribiendo',
        ru: 'Транскрибируется',
        rs: 'Transkripcija u toku',
        ua: 'Транскрибування',
        no: 'Transkriberer',
        it: 'Sto trascrivendo',
        se: 'Transkriberar',
    },
    preparing: {
        us: 'Preparing...',
        cz: 'Připravuji...',
        pl: 'Przygotowuję...',
        hr: 'Priprema...',
        si: 'Priprava...',
        de: 'Vorbereitung...',
        sk: 'Príprava...',
        es: 'Preparando...',
        ru: 'Подготовка...',
        rs: 'Priprema...',
        ua: 'Підготовка...',
        no: 'Forbereder...',
        it: 'Sto preparando...',
        se: 'Förbereder...',
    },
    inQueue: {
        us: 'Waiting in queue',
        cz: 'Čeká ve frontě',
        pl: 'Czeka w kolejce',
        hr: 'Čekanje u redu',
        si: 'Čakanje v vrsti',
        de: 'Wartet in der Warteschlange',
        sk: 'Čaká vo fronte',
        es: 'Esperando en la cola',
        ru: 'Ожидает в очереди',
        rs: 'Čekanje u redu',
        ua: 'Очікування в черзі',
        no: 'Venter i kø',
        it: 'Attesa in coda',
        se: 'Väntar i kö',
    },
    processingMedia: {
        us: 'Processing media',
        cz: 'Zpracování nahrávky',
        pl: 'Przetwarzanie nagrania',
        hr: 'Obrada medija',
        si: 'Obdelava medija',
        de: 'Aufnahme wird verarbeitet',
        sk: 'Spracovanie nahrávky',
        es: 'Procesando la grabación',
        ru: 'Обработка записи',
        rs: 'Obrada medija',
        ua: 'Обробка запису',
        no: 'Behandler innspilling',
        it: 'Elaborazione dei media in corso',
        se: 'Bearbetning av inspelningen',
    },
    uknown: {
        us: 'Unknown',
        cz: 'Neznámý',
        pl: 'Nieznany',
        hr: 'Nepoznato',
        si: 'Neznano',
        de: 'Unbekannt',
        sk: 'Neznámy',
        es: 'Desconocido',
        ru: 'Неизвестный',
        rs: 'Nepoznato',
        ua: 'Невідомий',
        no: 'Ukjent',
        it: 'Sconosciuto',
        se: 'Okänt',
    },
    noTranscriptionOptions: {
        us: 'You do not have any transcription options available. Please contact our support for assistance.',
        cz: 'Nemáte žádné dostupné možnosti přepisu. Kontaktujte prosím naši podporu pro pomoc.',
        pl: 'Nie są dostępne żadne opcje transkrypcji. Skontaktuj się z naszym działem pomocy technicznej, aby uzyskać pomoc.',
        hr: 'Nemate dostupnih opcija transkripcije. Obratite se našoj podršci za pomoć.',
        si: 'Na voljo nimate nobenih možnosti transkripcije. Za pomoč se obrnite na našo podporo.',
        de: 'Sie haben keine Transkriptionsoptionen zur Verfügung. Bitte kontaktieren Sie unseren Support für Hilfe.',
        sk: 'Nemáte k dispozícii žiadne možnosti prepisu. Obráťte sa na náš tím podpory pre pomoc.',
        es: 'No tienes opciones disponibles de transcripción. Por favor, contacta a nuestro soporte para obtener ayuda.',
        ru: 'Вам не доступны никакие варианты транскрипции. Обратитесь за помощью в службу поддержки.',
        rs: 'Nemate dostupne opcije transkripcije. Molimo kontaktirajte našu podršku za pomoć.',
        ua: 'Вам не доступні жодні варіанти транскрипції. Зверніться за допомогою до нашої служби підтримки.',
        no: 'Du har ingen tilgjengelig transkripsjonsvalg. Vennligst ta kontakt med brukerstøtten for å få hjelp.',
        it: 'Non ci sono opzioni di trascrizione disponibili. Contattare il nostro team di assistenza per ricevere assistenza.',
        se: 'Du har inga tillgängliga transkriptionsmöjligheter. Vänligen kontakta vår kundservice för att få hjälp.',
    },
    transcribeMedia: {
        us: 'Transcribe Media',
        cz: 'Přepsat média',
        pl: 'Transkrybuj',
        hr: 'Transkribiraj medij',
        si: 'Prepišite medij',
        de: 'Medium transkribieren',
        sk: 'Prepísať médiá',
        es: 'Transcribir la grabación',
        ru: 'Транскрибировать медиа',
        rs: 'Transkribuj medij',
        ua: 'Транскрибувати медіа',
        no: 'Transkribere medier',
        it: 'Trascrivere i media',
        se: 'Transkribera media',
    },
    mediaFile: {
        us: 'Media Files',
        cz: 'Soubory médií',
        pl: 'Pliki medialne',
        hr: 'Medijske datoteke',
        si: 'Medijske datoteke',
        de: 'Medien-Dateien',
        sk: 'Súbory médií',
        es: 'Archivos multimedia',
        ru: 'Медиафайлы',
        rs: 'Medijske datoteke',
        ua: 'Медіафайли',
        no: 'Mediefiler',
        it: 'File multimediali',
        se: 'Mediefiler',
    },
    dragAndDrop: {
        us: 'Click or drag files here',
        cz: 'Klikněte nebo přetáhněte soubory sem',
        pl: 'Kliknij lub przeciągnij pliki tutaj',
        hr: 'Klikni ili povuci datoteke ovdje',
        si: 'Kliknite ali povlecite datoteke sem',
        de: 'Klicken oder ziehen Sie Dateien hierher',
        sk: 'Kliknite alebo pretiahnite súbory sem',
        es: 'Haga clic o arrastre archivos aquí',
        ru: 'Нажмите или перетяните файлы сюда',
        rs: 'Kliknite ili prevucite datoteke ovde',
        ua: 'Натисніть або перетягніть сюди файли',
        no: 'Trykk her eller dra filer hit',
        it: 'Fare clic o trascinare i file qui',
        se: 'Klicka eller dra filer hit',
    },
    analysing: {
        us: 'Analysing files',
        cz: 'Zpracovávám soubory',
        pl: 'Analizuję pliki',
        hr: 'Analiziranje datoteka',
        si: 'Analiza datotek',
        de: 'Dateien werden analysiert',
        sk: 'Spracovávam súbory',
        es: 'Analizando los archivos',
        ru: 'Анализ файлов',
        rs: 'Analiziranje datoteka',
        ua: 'Аналіз файлів',
        no: 'Analyserer filer',
        it: 'Elaborazione di file',
        se: 'Analyserar filer',
    },
    extraLongRecordingInfo: {
        us: 'Recording is more than 360 minutes long, transcription is not possible',
        cz: 'Nahrávka má více než 360 minut, přepis není možný',
        pl: 'Nagranie ma ponad 360 minut, transkrypcja nie jest możliwa',
        hr: 'Snimka traje više od 360 minuta, transkripcija nije moguća',
        si: 'Posnetek je dolg več kot 360 minut, prepis ni možen',
        de: 'Aufnahme ist länger als 360 Minuten, Transkription ist nicht möglich',
        sk: 'Nahrávka má viac ako 360 minút, nie je možný prepis',
        es: 'La grabación dura más de 360 minutos, la transcripción no es posible',
        ru: 'Запись длиннее 360 минут, транскрибирование невозможно',
        rs: 'Snimak traje više od 360 minuta, transkripcija nije moguća',
        ua: 'Запис довший 360 хвилин, транскрибування неможливе',
        no: 'Innspillingen er lengre enn 360 minutter, transkripsjon er umulig',
        it: 'La registrazione ha una durata di oltre 360 minuti, non è possibile effettuare la trascrizione',
        se: 'Inspelningen är längre än 360 minuter, transkription är inte möjlig',
    },
    longRecordingInfo: {
        us: 'Recording is too long, transcription might fail',
        cz: 'Příliš dlouhá nahrávka, hrozí selhání přepisu',
        pl: 'Nagranie jest za długie, transkrypcja może się nie powieść',
        hr: 'Snimka je preduga, transkripcija možda neće uspjeti',
        si: 'Posnetek je predolg, prepis morda ne bo uspel',
        de: 'Aufnahme ist zu lang, Transkription kann fehlschlagen',
        sk: 'Príliš dlhá nahrávka, riziko zlyhania prepisu',
        es: 'La grabación es demasiado larga, la transcripción puede fallar',
        ru: 'Запись слишком длинная, транскрибирование может не удасться',
        rs: 'Snimak je predugačak, transkripcija možda neće uspeti',
        ua: 'Запис занадто довгий, транскрибування може не вдасться',
        no: 'Innspillingen er for lang, transkripsjon kan mislykkes',
        it: 'Registrazione troppo lunga, rischio di mancata trascrizione',
        se: 'Inspelningen är för lång, transkription kan misslyckas',
    },
    durationUnknownInfo: {
        us: 'Unknown length of the recording',
        cz: 'Délku nahrávky nelze zjistit',
        pl: 'Nie można określić długości nagrania',
        hr: 'Nepoznata dužina snimke',
        si: 'Neznana dolžina posnetka',
        de: 'Unbekannte Länge der Aufnahme',
        sk: 'Dĺžku nahrávky nemožno zistiť',
        es: 'Duración desconocida de la grabación',
        ru: 'Длину записи невозможно определить',
        rs: 'Nepoznata dužina snimka',
        ua: 'Довжину запису неможливо визначити',
        no: 'Lengden av innspillingen ukjent',
        it: 'Non è possibile determinare la durata della registrazione',
        se: 'Längden av inspelningen okänd',
    },
    insertMediaFile: {
        us: 'Insert at least one media file for transcription',
        cz: 'Vložte alespoň jeden soubor k přepisu',
        pl: 'Włóż co najmniej jeden plik do transkrypcji',
        hr: 'Umetnite najmanje jednu medijsku datoteku za transkripciju',
        si: 'Vstavite vsaj eno medijsko datoteko za prepis',
        de: 'Fügen Sie mindestens eine Mediendatei für die Transkription ein',
        sk: 'Vložte aspoň jeden súbor na prepis',
        es: 'Inserte al menos un archivo multimedia para la transcripción',
        ru: 'Вставьте хотя бы один медиафайл для расшифровки',
        rs: 'Ubaci bar jedan media fajl za transkripciju',
        ua: 'Вставте принаймні один медіафайл для транскрипції',
        no: 'Sett inn minst én fil for transkripsjon',
        it: 'Inserisca almeno un file per la trascrizione',
        se: 'Infoga minst en mediefil till traskription',
    },
    invalidFileFormat: {
        us: 'Not a supported media format',
        cz: 'Nepodporovaný formát médií',
        pl: 'Nieobsługiwany format multimediów',
        hr: 'Medijski format nije podržan',
        si: 'Medijski format ni podprt',
        de: 'Nicht unterstütztes Medienformat',
        sk: 'Nepodporovaný formát médií',
        es: 'No es un formato de medios compatible',
        ru: 'Неподдерживаемый медиаформат',
        rs: 'Medijski format nije podržan',
        ua: 'Непідтримуючи медіаформат',
        no: 'Medieformatet ikke støttet',
        it: 'Formato multimediale non supportato',
        se: 'Mediefilens format stöds inte',
    },
    mediaFormatUnsupported: {
        us: 'Transcription is not possible: some of your media have an unsupported format or do not contain audio/video.',
        cz: 'Přepis není možný: některý z vašich souborů má nepodporovaný formát nebo neobsahuje audio/video.',
        pl: 'Transkrypcja nie jest możliwa: jeden z Twoich plików ma nieobsługiwany format lub nie zawiera audio/wideo.',
        hr: 'Transkripcija nije moguća: neki od vaših medija imaju nepodržani format ili ne sadrže audio/video.',
        si: 'Prepis ni mogoč: nekateri vaši mediji imajo nepodprto obliko ali ne vsebujejo zvoka/videa.',
        de: 'Die Transkription ist nicht möglich: Einige Ihrer Medien haben ein nicht unterstütztes Format oder enthalten kein Audio/Video.',
        sk: 'Prepis nie je možný: jeden z vašich súborov má nepodporovaný formát alebo neobsahuje audio/video.',
        es: 'La transcripción no es posible: algunos de sus medios tienen un formato no compatible o no contienen audio/video.',
        ru: 'Расшифровка невозможна: некоторые из ваших медиафайлов имеют неподдерживаемый формат или не содержат аудио/видео.',
        rs: 'Transkripcija nije moguća: Neki od vaših fajlova su u nepodržanom formatu ili ne sadrže audio/video.',
        ua: 'Транскрипція неможлива: деякі з ваших медіа мають непідтримуваний формат або не містять аудіо/відео.',
        no: 'Transkripsjon umulig: noen av filene dine er i et format som ikke støttes eller inneholder ikke lyd/video.',
        it: 'La trascrizione non è possibile: uno dei file ha un formato non supportato o non contiene audio/video.',
        se: 'Transkription är inte möjlig: antingen är några av dina filer i format som inte stöds, eller innehåller de inte något ljud/någon video.',
    },
    trsxFile: {
        us: 'Trsx File',
        cz: 'Soubor trsx',
        pl: 'Plik trsx',
        hr: 'Trsx datoteka',
        si: 'Trsx datoteka',
        de: 'Trsx-Datei',
        sk: 'Súbor trsx',
        es: 'Archivo trsx',
        ru: 'Trsx файл',
        rs: 'Trsx datoteka',
        ua: 'Trsx файл',
        no: 'Trsx fil',
        it: 'File trsx',
        se: 'Trsx-fil',
    },
    trsxForOneFile: {
        us: 'Trsx cannot be uploaded for multiple projects. To add trsx, upload one project at a time.',
        cz: 'Trsx nelze nahrát pro více projektů. Pro přidání trsx, nahrajte jednotlivé projekty postupně.',
        pl: 'Nie można wczytać trsxów dla większej liczby projektów. Aby dodać trsx, wczytuj projekty jeden po drugim.',
        hr: 'Trsx se ne može učitati za više projekata. Da biste dodali trsx, učitajte jedan po jedan projekt.',
        si: 'Trsx ni mogoče naložiti za več projektov. Da bi dodali trsx, naložite en projekt naenkrat.',
        de: 'Trsx können nicht für mehrere Projekte hochgeladen werden. Um trsx hinzuzufügen, laden Sie immer nur ein Projekt hoch.',
        sk: 'Trsx nie je možné nahrať pre viacero projektov. Ak chcete pridať trsx, nahrajte jednotlivé projekty postupne.',
        es: 'Trsx no se puede cargar para varios proyectos. Para agregar trsx, cargue un proyecto a la vez.',
        ru: 'Trsx невозможно загрузить для более чем одного проекта. Если хотите добавить Trsx, загрузить каждый проект поочередно.',
        rs: 'Trsx se ne može učitati za više projekata. Da biste dodali trsx, učitajte jedan po jedan projekat.',
        ua: 'Trsx неможливо завантажити для більш ніж одного проекту. Якщо ви хочете додати Trsx, завантажити кожен проект по черзі.',
        no: 'Trsx kan ikke lastes opp for flere prosjekter samtidig. Last opp ett prosjekt om gangen for å legge til trsx.',
        it: 'Non è possibile caricare Trsx per più progetti. Per aggiungere trsx, caricare ogni progetto a turno.',
        se: 'Trsx kan inte laddas upp för flera projekt samtidigt. För att lägga till trsx, ladda upp ett projekt åt gången.',
    },
    untitledProj: {
        us: 'Untitled Project',
        cz: 'Projekt bez názvu',
        pl: 'Projekt bez nazwy',
        hr: 'Projekt bez naziva',
        si: 'Projekt brez imena',
        de: 'Projekt ohne Titel',
        sk: 'Projekt bez názvu',
        es: 'Proyecto sin título',
        ru: 'Проект без названия',
        rs: 'Neimenovani projekat',
        ua: 'Проект без назви',
        no: 'Prosjekt uten tittel',
        it: 'Progetto senza titolo',
        se: 'Namnlöst projekt',
    },
    postprocessing: {
        us: 'Automatically format numeric data and capitalize words',
        cz: 'Automaticky formátovat číselné údaje a kapitalizovat slova',
        pl: 'Automatycznie formatuj dane numeryczne i kapitalizuj słowa',
        hr: 'Automatski oblikuj numeričke podatke i riječi ispiši velikim slovima',
        si: 'Samodejno oblikuj številske podatke in uporabi velike črke',
        de: 'Automatisches Formatieren von Zahlen und Großschreibung von Wörtern',
        sk: 'Automaticky formátovať číselné údaje a písať slová veľkými písmenami',
        es: 'Formatear automáticamente datos numéricos y capitalizar palabras',
        ru: 'Автоматическое форматирование числовых данных и написание слов заглавными буквами',
        rs: 'Automatski formatiraj numeričke podatke i koristi velika slova na početku reči',
        ua: 'Автоматичне форматування числових даних і написання слів з великої літери',
        no: 'Formatere automatisk numerisk data og bruke stor forbokstaver i ord',
        it: 'Formattazione automatica dei dati numerici e delle parole in maiuscolo',
        se: 'Automatiskt formatera numeriska data och inleda varje ord med versal',
    },
    noisyRecording: {
        us: 'The recording has background noise and other disruptive effects',
        cz: 'Nahrávka má hluk v pozadí a další rušivé efekty',
        pl: 'Nagranie zawiera szumy w tle i inne zakłócające efekty',
        hr: 'Snimka ima pozadinsku buku i druge ometajuće učinke',
        si: 'Posnetek ima hrup v ozadju in druge moteče učinke',
        de: 'Aufzeichnung enthält Hintergrundgeräusche und andere Störeffekte',
        sk: 'Nahrávka má hluk na pozadí a iné rušivé efekty',
        es: 'La grabación tiene ruido de fondo y otros efectos disruptivos',
        ru: 'В записи присутствует фоновый шум и другие мешающие эффекты',
        rs: 'Snimak sadrži pozadinsku buku i druge ometajuće efekte',
        ua: 'Запис має фоновий шум та інші заважаючі ефекти',
        no: 'Inspillingen inneholder bakgrunnsstøy og andre forstyrrende effekter',
        it: 'La registrazione presenta rumore di fondo e altri effetti di distrazione',
        se: 'Inspelningen innehåller bakgrundsbrus och andra störande effekter',
    },
    advancedSettings: {
        us: 'Advanced settings',
        cz: 'Pokročilá nastavení',
        pl: 'Zaawansowane ustawienia',
        hr: 'Napredne postavke',
        si: 'Napredne nastavitve',
        de: 'Erweiterte Einstellungen',
        sk: 'Pokročilé nastavenia',
        es: 'Configuraciónes avanzadas',
        ru: 'Расширенные настройки',
        rs: 'Napredna podešavanja',
        ua: 'Розширені налаштування',
        no: 'Avansert innstilling',
        it: 'Impostazioni avanzate',
        se: 'Avancerade inställningar',
    },
    warning: {
        us: 'Warning: ',
        cz: 'Upozornění: ',
        pl: 'Uwaga: ',
        hr: 'Upozorenje: ',
        si: 'Opozorilo: ',
        de: 'Achtung: ',
        sk: 'Upozornenie: ',
        es: 'Advertencia: ',
        ru: 'Предупреждение: ',
        rs: 'Upozorenje: ',
        ua: 'Попередження: ',
        no: 'Advarsel: ',
        it: 'Avvertimento: ',
        se: 'Varning: ',
    },
    advancedTranscriptionSettings: {
        us: 'Transcription settings',
        cz: 'Nastavení přepisu',
        pl: 'Ustawienia transkrypcji',
        hr: 'Postavke transkripcije',
        si: 'Nastavitve prepisa',
        de: 'Transkriptionseinstellungen',
        sk: 'Nastavenia prepisu',
        es: 'Configuración de transcripción',
        ru: 'Настройка транскрипции',
        rs: 'Podešavanja transkripcije',
        ua: 'Налаштування транскрипції',
        no: 'Transkripsjon innstilling',
        it: 'Impostazioni di trascrizione',
        se: 'Transkriptionsinställningar',
    },
    advancedSettingsWarning: {
        us: 'if in doubt, leave checked',
        cz: 'v případě pochybností nechte zaškrtnuté',
        pl: 'w razie wątpliwości pozostaw zaznaczone',
        hr: 'ako niste sigurni, ostavite označeno',
        si: 'če niste sigurni, pustite označeno',
        de: 'im Zweifel lassen Sie das Kontrollkästchen markiert',
        sk: 'v prípade pochybností nechajte zaškrtnuté',
        es: 'en caso de duda, déjelo marcar',
        ru: 'если сомневаетесь, оставьте поле заполненным',
        rs: 'ako ste u dilemi, ostavite označeno',
        ua: 'якщо ви сумніваєтеся, залиште поле заповненим',
        no: 'er du i tvil, la krysset',
        it: 'in caso di dubbio, lascia il segno di spunta non spuntato',
        se: 'om du är osäker, lämna rutan ikryssad',
    },
    create: {
        us: 'Create',
        cz: 'Vytvořit',
        pl: 'Utwórz',
        hr: 'Kreiraj',
        si: 'Ustvari',
        de: 'Erstellen',
        sk: 'Vytvoriť',
        es: 'Crear',
        ru: 'Создать',
        rs: 'Kreiraj',
        ua: 'Створити',
        no: 'Lage',
        it: 'Creare',
        se: 'Skapa',
    },
    ok: {
        us: 'Ok',
        cz: 'Ok',
        pl: 'Ok',
        hr: 'Ok',
        si: 'Ok',
        de: 'Ok',
        sk: 'Ok',
        es: 'Ok',
        ru: 'Хорошо',
        rs: 'Ok',
        ua: 'Добре',
        no: 'Ok',
        it: 'Ok',
        se: 'Ok',
    },
    recordingLength: {
        us: 'Length:',
        cz: 'Délka:',
        pl: 'Długość:',
        hr: 'Trajanje:',
        si: 'Trajanje:',
        de: 'Länge:',
        sk: 'Dĺžka:',
        es: 'Duración:',
        ru: 'Длина:',
        rs: 'Dužina:',
        ua: 'Довжина:',
        no: 'Lengde:',
        it: 'Lunghezza:',
        se: 'Längd:',
    },
    requiredCredit: {
        us: 'Required credit:',
        cz: 'Potřebný kredit:',
        pl: 'Wymagany kredyt:',
        hr: 'Potreban kredit:',
        si: 'Potreben kredit:',
        de: 'Erforderliche Kredite:',
        sk: 'Potrebný kredit:',
        es: 'Crédito requerido:',
        ru: 'Необходимый баланс:',
        rs: 'Potrebni kredit:',
        ua: 'Необхідний баланс:',
        no: 'Nødvendig kreditt:',
        it: 'Crediti necessari:',
        se: 'Nödvändig kredit:',
    },
    notEnoughCredit: {
        us: 'You do not have enough credit.',
        cz: 'Pro přepis nemáte dostatek kreditu.',
        pl: 'Nie masz wystarczającej liczby kredytów, aby dokonać transkrypcji.',
        hr: 'Nedovoljan kredit.',
        si: 'Nezadosten kredit.',
        de: 'Sie haben nicht genug Kredite.',
        sk: 'Pre prepis nemáte dostatok kreditu.',
        es: 'No tiene suficiente crédito.',
        ru: 'На вашем счете недостаточный баланс.',
        rs: 'Nemate dovoljno kredita.',
        ua: 'На вашому рахунку недостатній баланс.',
        no: 'Du har ikke nok kreditt.',
        it: 'Non si dispone di crediti sufficienti per una trascrizione.',
        se: 'Du har inte tillräcklig kredit.',
    },
    topUpCredit: {
        us: 'Top up credit',
        cz: 'Navýšit kredit',
        pl: 'Zwiększ kredyt',
        hr: 'Nadoplatite kredit',
        si: 'Doplatite kredit',
        de: 'Kredit aufladen',
        sk: 'Navýšiť kredit',
        es: 'Recargue su crédito',
        ru: 'Пополнить баланс',
        rs: 'Dopunite kredit',
        ua: 'Поповнити баланс',
        no: 'Fyll på kreditt',
        it: 'Aumentare il credito',
        se: 'Fyll på kredit',
    },
    longRecording: {
        us: 'Some of your recordings are too long and there is a risk of failure during transcription. In the event of a failure, no credit will be charged.',
        cz: 'Některá z vašich nahrávek je příliš dlouhá a hrozí selhání v průběhu přepisu. V případě selhání vám žádný kredit nebude odečten.',
        pl: 'Niektóre z Twoich nagrań są zbyt długie i istnieje ryzyko niepowodzenia podczas transkrypcji. W przypadku niepowodzenia Twój kredyt nie zostanie potrącony.',
        hr: 'Neke su vaše snimke preduge i postoji opasnost od neuspjeha tijekom transkripcije. U slučaju neuspjeha, kredit se neće odbiti.',
        si: 'Nekateri vaši posnetki so predolgi in obstaja nevarnost neuspeha med prepisovanjem. V primeru neuspeha se kredit ne odšteje.',
        de: 'Einige Aufnahmen sind zu lang und es besteht die Gefahr, dass die Transkription fehlschlägt. Im Falle eines Fehlers wird kein Kredit abgezogen.',
        sk: 'Niektoré vaše nahrávky sú príliš dlhé a hrozí, že sa počas prepisu pokazia. V prípade neúspechu sa kredit neodpočíta.',
        es: 'Algunas de sus grabaciones son demasiado largas y existe el riesgo de que fallen durante la transcripción. En caso de incumplimiento, no se deducirá ningún crédito.',
        ru: 'Некоторые из ваших записей слишком длинные, во время транскрибирвание есть вероятность сбоя. В случае неудачи средства с вашего баланса не списываются.',
        rs: 'Neke od vaših snimaka su predugački i postoji opasnost od neuspeha tokom transkripcije. U slučaju neuspeha, kredit se neće odbiti.',
        ua: 'Деякі з ваших записів занадто довгі, під час транскрибування є можливість збою. У разі невдачі кошти з вашого балансу не списуються.',
        no: 'Noen av innspillingene er lengre enn 360 minutter, transkripsjonen kan mislykkes. I tilfelle, ingen kreditt vil belastes.',
        it: 'Alcune registrazioni sono troppo lunghe e la trascrizione rischia di fallire. In caso di fallimento, non verrà detratto alcun credito.',
        se: 'Några av dina inspelningar är för långa och det finns risk för att transkriptionen kan misslyckas. I så fall kommer ingen kredit att debiteras.',
    },
    extraLongRecording: {
        us: 'Transcription is not possible because some of your recordings are too long. The maximum length of a recording is 360 minutes.',
        cz: 'Přepis není možný, neboť některá z vašich nahrávek je příliš dlouhá. Maximální délka nahrávky je 360 minut.',
        pl: 'Transkrypcja nie jest możliwa, ponieważ niektóre Twoje nagrania są za długie. Maksymalna długość nagrania to 360 minut.',
        hr: 'Transkripcija nije moguća jer su neke vaše snimke preduge. Maksimalno trajanje snimke je 360 minuta.',
        si: 'Prepis ni mogoč, ker so nekateri vaši posnetki predolgi. Največja dolžina posnetka je 360 minut.',
        de: 'Transkription ist nicht möglich, weil einige Aufnahmen zu lang sind. Maximale Länge einer Aufnahme beträgt 360 Minuten.',
        sk: 'Prepis nie je možný, pretože niektoré vaše nahrávky sú príliš dlhé. Maximálna dĺžka nahrávky je 360 minút.',
        es: 'La transcripción no es posible porque algunas de sus grabaciones son demasiado largas. La duración máxima de una grabación es de 360 minutos.',
        ru: 'Транскрибирование невозможно так как некоторые из ваших записей слишком длинные. Максимальная длина записи 360 минут.',
        rs: 'Transkripcija nije moguća jer su neki od vaših snimaka predugački. Maksimalno trajanje snimka je 360 minuta.',
        ua: 'Транскрибування неможливо так, як деякі з ваших записів занадто довгі. Максимальна довжина запису 360 хвилин.',
        no: 'Transkripsjon er umulig, for noen av innspillingene er for lange. Maksimumslengden er 360 minutter.',
        it: 'La trascrizione non è possibile perché una delle registrazioni è troppo lunga. La durata massima di una registrazione è di 360 minuti.',
        se: 'Transkription är inte möjlig därför att några av dina inspelningar är för långa. Den maximala inspelningslängden är 360 minuter.',
    },
    start: {
        us: 'Start',
        cz: 'Zahájit',
        pl: 'Start',
        hr: 'Početak',
        si: 'Začetek',
        de: 'Start',
        sk: 'Spustiť',
        es: 'Empezar',
        ru: 'Начать',
        rs: 'Početak',
        ua: 'Почати',
        no: 'Begynn',
        it: 'Iniziare',
        se: 'Börja',
    },
    projectName: {
        us: 'Name of the project',
        cz: 'Název projektu',
        pl: 'Nazwa projektu',
        hr: 'Naziv projekta',
        si: 'Ime projekta',
        de: 'Name des Projekts',
        sk: 'Názov projektu',
        es: 'Nombre del proyecto',
        ru: 'Название проекта',
        rs: 'Naziv projekta',
        ua: 'Назва проекту',
        no: 'Prosjektnavn',
        it: 'Nome del progetto',
        se: 'Projektnamn',
    },
    langOfTranscription: {
        us: 'Language of the recording',
        cz: 'Jazyk nahrávky',
        pl: 'Język nagrania',
        hr: 'Jezik snimke',
        si: 'Jezik posnetka',
        de: 'Sprache der Aufnahme',
        sk: 'Jazyk nahrávky',
        es: 'Idioma de la grabación',
        ru: 'Язык записи',
        rs: 'Jezik snimka',
        ua: 'Мова запис',
        no: 'Språk på innspillingen',
        it: 'Lingua della registrazione',
        se: 'Språk på inspelningen',
    },
    fileNotTrsx: {
        us: 'The file is not in the trsx format',
        cz: 'Soubor není ve formátu trsx',
        pl: 'Plik nie jest w formacie trsx',
        hr: 'Datoteka nije u trsx formatu',
        si: 'Datoteka ni v trsx formatu',
        de: 'Die Datei ist nicht im trsx-Format',
        sk: 'Súbor nie je vo formáte trsx',
        es: 'El archivo no está en formato trsx',
        ru: 'Файл не в trsx формате',
        rs: 'Datoteka nije u trsx formatu',
        ua: 'Файл не в форматі trsx',
        no: 'Filen er ikke i trsx formatet',
        it: 'Il file non è in formato trsx',
        se: 'Filen är inte i trsx-format',
    },
    contactDistributor: {
        us: 'Contact your distributor',
        cz: 'Kontaktujte svého distributora',
        pl: 'Skontaktuj się ze swoim dystrybutorem',
        hr: 'Kontaktirajte sa svojim distributerom',
        si: 'Kontaktirajte svojega distributerja',
        de: 'Wenden Sie sich an Ihren Händler',
        sk: 'Kontaktujte svojho distribútora',
        es: 'Póngase en contacto con su distribuidor',
        ru: 'Свяжитесь со своим дистрибьютором',
        rs: 'Kontaktirajte Vašeg distributera',
        ua: "Зв'яжіться зі своїм дистриб'ютором",
        no: 'Kontakt din distributør',
        it: 'Contatti il proprio distributore',
        se: 'Kontakta din distributör',
    },
    nameRequired: {
        us: 'Enter name of the project',
        cz: 'Zadejte název projektu',
        pl: 'Wprowadź nazwę projektu.',
        hr: 'Unesite naziv projekta.',
        si: 'Vnesite ime projekta.',
        de: 'Name des Projekts eingeben',
        sk: 'Zadajte názov projektu',
        es: 'Ingrese el nombre del proyecto',
        ru: 'Введите название проекта',
        rs: 'Upišite ime projekta',
        ua: 'Введіть назву проекту',
        no: 'Skriv inn prosjektnavn',
        it: 'Inserisca il nome del progetto',
        se: 'Skriv in projektnamn',
    },
    durationUnknown: {
        us: 'Unknown length of one of the recordings.',
        cz: 'Délku některé z vašich nahrávek nelze zjistit.',
        pl: 'Nieznana długość niektórych nagrań.',
        hr: 'Nepoznata dužina jedne od snimaka.',
        si: 'Neznana dožina enega od posnetkov.',
        de: 'Unbekannte Länge einer der Aufnahmen.',
        sk: 'Dĺžku niektorých vašich nahrávok nie je možné určiť.',
        es: 'No se puede determinar la duración de algunas de sus grabaciones.',
        ru: 'Длину некоторых ваших записей невозможно определит.',
        rs: 'Nepoznata dužina jednog od snimaka.',
        ua: 'Довжину деяких ваших записів неможливо визначити.',
        no: 'Ukjent lengde på en av innspillingene.',
        it: 'Non è possibile determinare la durata di alcune registrazioni.',
        se: 'Okänd längd på en av inspelningarna.',
    },
    checkCredit: {
        us: 'Please check if you have enough credit before starting transcription.',
        cz: 'Před přepisem si prosím ověřte, zda máte dostatek kreditu.',
        pl: 'Przed rozpoczęciem transkrypcji upewnij się, że Twój kredyt jest wystarczający.',
        hr: 'Provjerite imate li dovoljno kredita prije transkripcije.',
        si: 'Pred prepisom preverite, ali imate dovolj kredita.',
        de: 'Überprüfen Sie vor der Transkription, ob Sie genügend Kredit haben.',
        sk: 'Pred prepisom si overte, či máte dostatok kreditu.',
        es: 'Verifique si tiene suficiente crédito antes de la transcripción.',
        ru: 'Перед транскрибированием проверьте состояние баланса.',
        rs: 'Proverite da li imate dovoljno kredita pre transkripcije.',
        ua: 'Перед транскрибуванням перевірте стан балансу.',
        no: 'Før du begynner transkripsjon, vennligst sjekk om du har nok kreditt.',
        it: 'Si assicuri di avere abbastanza crediti prima di trascrivere.',
        se: 'Innan du påbörjar transkription, vänligen kontrollera att du har tillräcklig kredit.',
    },
    durationRecognition: {
        us: 'Determining recording length...',
        cz: 'Zjišťuji délku nahrávky...',
        pl: 'Sprawdzam długość nagrania...',
        hr: 'Određivanje duljine snimke...',
        si: 'Določanje dolžine posnetka...',
        de: 'Länge der Aufnahme wird bestimmt...',
        sk: 'Zisťuje sa dĺžka nahrávky...',
        es: 'Determinando la duración de la grabación...',
        ru: 'Определяется длина записи...',
        rs: 'Određivanje dužine snimka...',
        ua: 'Визначається довжина запису...',
        no: 'Bestemmer lengden av innspillingen...',
        it: 'Sto controllando la durata della registrazione...',
        se: 'Bestämmer längden av inspelningen...',
    },
    trialCredit: {
        us: 'Only part of the recording will be transcribed as you are using trial credit. After your first credit purchase, you may transcribe whole files without any restrictions.',
        cz: 'Přepíše se pouze část souboru, protože využíváte testovací kredit. Po prvním nákupu kreditu, můžete přepisovat celé nahrávky bez omezení.',
        pl: 'Korzystasz z bezpłatnego kredytu próbnego. Tylko część nagrania zostanie stranskrybowana. Po dokonaniu pierwszego zakupu w aplikacji będziesz mógł swobodnie korzystać z kredytu.',
        hr: 'Samo će dio snimke biti transkribiran jer koristite probni kredit. Nakon prve kupnje kredita, možete transkribirati cijele datoteke bez ikakvih ograničenja.',
        si: 'Samo del posnetka bo prepisan, saj uporabljate preizkusni kredit. Po prvem nakupu kredita lahko brez omejitev prepisujete cele datoteke.',
        de: 'Sie verwenden kostenlose Kredite. Nur ein Teil der Aufnahme wird transkribiert. Nach Ihrem ersten Einkauf können Sie Kredite frei verwenden.',
        sk: 'Využívate bezplatný testovací kredit. Prepíše sa len časť nahrávky. Po prvom nákupe môžete kredit využívať neobmedzene.',
        es: 'Está utilizando un crédito de prueba gratuito. Solo se transcribirá una parte de la grabación. Después de la primera compra, puede utilizar el crédito sin límites.',
        ru: 'Только часть вашей записи будет транскрибирована так как вы используете тестовую версию с бесплатным балансом на счете. После первого пополнения баланса вы можете транскрибировать целые файлы без ограничений.',
        rs: 'Samo će deo snimka biti transkribovan jer koristite probni kredit. Nakon prve kupovine kredita, možete transkribovati cele datoteke bez ikakvih ograničenja.',
        ua: 'Лише частина вашого запису буде транскрибована, оскільки ви використовуєте тестову версію з безкоштовним балансом на рахунку. Після першого поповнення балансу можна транскрибувати цілі файли без обмежень.',
        no: 'Kun en del av innspillingen blir transkribert, for du bruker kreditt for prøveversjon. Du kan transkribere hele filer uten noen begrensninger etter ditt første kredittkjøp.',
        it: 'Viene trascritta solo una parte del file perché si sta utilizzando un credito di prova. Dopo il primo acquisto di credito, è possibile trascrivere intere registrazioni senza limitazioni.',
        se: 'Endast en del av inspelningen kommer att transkriberas därför att du använder kredit som ingår i provversionen. Efter första kreditinköpet kan du transkribera filer i deras helhet utan begränsning.',
    },
    uploadInProgress: {
        us: 'Upload in progress',
        cz: 'Probíhá nahrávání souboru',
        pl: 'Trwa wczytywanie pliku',
        hr: 'Prijenos u tijeku',
        si: 'Nalaganje poteka',
        de: 'Upload läuft',
        sk: 'Prebieha nahrávanie súboru',
        es: 'Subida en curso',
        ru: 'Идет загрузка файла',
        rs: 'Otpremanje je u toku',
        ua: 'Триває завантаження файлу',
        no: 'Opplastingen pågår',
        it: 'Caricamento di file in corso',
        se: 'Uppladdning pågår',
    },
    onlyOneFile: {
        us: 'You can upload only one file at a time.',
        cz: 'Najednou můžete nahrát pouze jeden soubor.',
        pl: 'Możesz wczytać tylko jeden plik naraz.',
        hr: 'Istodobno možete prenijeti samo jednu datoteku.',
        si: 'Obenem lahko naložite samo eno datoteko.',
        de: 'Nur eine Datei kann gleichzeitig hochgeladen werden.',
        sk: 'Naraz môžete nahrať iba jeden súbor.',
        es: 'Puede cargar solo un archivo a la vez.',
        ru: 'Вы можете загрузить только один файл за раз.',
        rs: 'Možete otpremiti samo jednu datoteku odjednom.',
        ua: 'Ви можете завантажити лише один файл за один раз.',
        no: 'Du kan laste opp bare én fil om gangen.',
        it: 'È possibile caricare solo un file alla volta.',
        se: 'Endast en fil åt gången kan laddas upp.',
    },
    transcribe: {
        us: 'Transcribe',
        cz: 'Přepsat',
        pl: 'Transkrybuj',
        hr: 'Transkribiraj',
        si: 'Prepiši',
        de: 'Transkribieren',
        sk: 'Prepísať',
        es: 'Transcribir',
        ru: 'Транскрибировать',
        rs: 'Transkribuj',
        ua: 'Транскрибувати',
        no: 'Transkribere',
        it: 'Trascrivere',
        se: 'Transkribera',
    },
    delete: {
        us: 'Delete',
        cz: 'Smazat',
        pl: 'Usuń',
        hr: 'Izbriši',
        si: 'Izbriši',
        de: 'Löschen',
        sk: 'Zmazať',
        es: 'Borrar',
        ru: 'Удалить',
        rs: 'Izbriši',
        ua: 'Видалити',
        no: 'Slette',
        it: 'Eliminare',
        se: 'Ta bort',
    },
    deleteProjectsPermanently: {
        us: 'Permanently delete projects?',
        cz: 'Trvale smazat projekty?',
        pl: 'Trwale usunąć projekty?',
        hr: 'Trajno izbrisati projekte?',
        si: 'Trajno izbrisati projekte?',
        de: 'Projekte unwiderruflich löschen?',
        sk: 'Trvalo vymazať projekty?',
        es: '¿Eliminar proyectos permanentemente?',
        ru: 'Удалить проекты навсегда?',
        rs: 'Trajno izbrisati projekte?',
        ua: 'Видалити проекти назавжди?',
        no: 'Slette prosjektene permanent?',
        it: 'Cancellare definitivamente i progetti?',
        se: 'Ta bort projekten för alltid?',
    },
    numOfProjsToDelete: {
        us: 'Number of projects to delete',
        cz: 'Počet projektů ke smazání',
        pl: 'Liczba projektów do usunięcia',
        hr: 'Broj projekata za brisanje',
        si: 'Število projektov za izbris',
        de: 'Anzahl der zu löschenden Projekte',
        sk: 'Počet projektov, ktoré sa majú odstrániť',
        es: 'Número de proyectos para eliminar',
        ru: 'Количество проектов для удаления',
        rs: 'Broj projekata za brisanje',
        ua: 'Кількість проектів для видалення',
        no: 'Antall prosjekter til å slette',
        it: 'Numero di progetti da eliminare',
        se: 'Antal projekt som ska raderas',
    },
    deleteShared: {
        us: 'By deleting shared projects you lose access to them',
        cz: 'Smazáním sdílených projektů k nim ztratíte přístup',
        pl: 'Usuwając udostępnione projekty, tracisz do nich dostęp',
        hr: 'Brisanjem zajedničkih projekata gubite pristup tim projektima',
        si: 'Če izbrišete skupne projekte, izgubite dostop do njih',
        de: 'Durch Löschen von geteilten Projekten verlieren Sie Zugriff',
        sk: 'Odstránením zdieľaných projektov k nim stratíte prístup',
        es: 'Al eliminar proyectos compartidos, pierde el acceso a ellos',
        ru: 'Удаляя совместные проекты, вы теряете к ним доступ',
        rs: 'Brisanjem zajedničkih projekata gubite pristup tim projektima',
        ua: 'Видаляючи спільні проекти, ви втрачаєте до них доступ',
        no: 'Du mister tilgang til de delte prosjektene når du sletter dem',
        it: "Se si eliminano i progetti condivisi, si perde l'accesso ad essi",
        se: 'Du förlorar åtkomst till delade projekt när du raderar dem',
    },
    successfullyDeleted: {
        us: 'Successfully deleted',
        cz: 'Úspěšně smazáno',
        pl: 'Usunięto pomyślnie',
        hr: 'Uspješno izbrisano',
        si: 'Uspešno izbrisano',
        de: 'Erfolgreich gelöscht',
        sk: 'Úspěšne zmazané',
        es: 'Borrado con éxito',
        ru: 'Успешно удалено',
        rs: 'Uspešno izbrisano',
        ua: 'Успішно видалено',
        no: 'Vellykket slettet',
        it: 'Eliminato con successo',
        se: 'Borttagning lyckades',
    },
    failedToDelete: {
        us: 'Failed to delete',
        cz: 'Mazání selhalo',
        pl: 'Nie udało się usunąć',
        hr: 'Brisanje nije uspjelo',
        si: 'Izbris ni uspel',
        de: 'Fehler beim Löschen',
        sk: 'Mazanie zlyhalo',
        es: 'No se pudo borrar',
        ru: 'Не удалось удалить',
        rs: 'Brisanje nije uspelo',
        ua: 'Не вдалося видалити',
        no: 'Sletting mislyktes',
        it: 'Cancellazione non riuscita',
        se: 'Borttagning misslyckades',
    },
    numOfProjs: {
        us: 'Number of deleted projects',
        cz: 'Počet smazaných dokumentů',
        pl: 'Liczba usuniętych projektów',
        hr: 'Broj izbrisanih projekata',
        si: 'Število izbrisanih projektov',
        de: 'Anzahl der gelöschten Projekte',
        sk: 'Počet zmazaných dokumentov',
        es: 'Número de proyectos borrados',
        ru: 'Количество удалённых документов',
        rs: 'Broj izbrisanih projekata',
        ua: 'Кількість видалених проектів',
        no: 'Antall slettet prosjekter',
        it: 'Numero di documenti eliminati',
        se: 'Antal raderade projekt',
    },
    projDeletion: {
        us: 'Projects deletion',
        cz: 'Mazání projektů',
        pl: 'Usuwanie projektów',
        hr: 'Brisanje projekata',
        si: 'Izbris projektov',
        de: 'Löschen der Projekte',
        sk: 'Mazanie projektov',
        es: 'Borradura de proyectos',
        ru: 'Удаление проектов',
        rs: 'Brisanje projekata',
        ua: 'Видалення проектів',
        no: 'Sletting av prosjekter',
        it: 'Eliminazione di progetti',
        se: 'Borttagning av projekt',
    },
    deletingProject: {
        us: 'Deleting project...',
        cz: 'Mažu projekt...',
        pl: 'Usuwam projekt...',
        hr: 'Brisanje projekta...',
        si: 'Brišem projekt...',
        de: 'Projekt wird gelöscht...',
        sk: 'Mažem projekt...',
        es: 'Borrando el proyecto...',
        ru: 'Удаление проекта...',
        rs: 'Brišem projekat...',
        ua: 'Видалення проекту...',
        no: 'Prosjektet slettes...',
        it: 'Sto cancellando il progetto...',
        se: 'Projektet raderas...',
    },
    delProgression: {
        us: 'Deleting projects...',
        cz: 'Mažu projekty...',
        pl: 'Usuwam projekty...',
        hr: 'Brisanje projekata...',
        si: 'Brišem projekte...',
        de: 'Projekte werden gelöscht...',
        sk: 'Mažem projekty...',
        es: 'Borrando proyectos...',
        ru: 'Удаляемые проекты...',
        rs: 'Brisanje projekata...',
        ua: 'Видалення проектів...',
        no: 'Prosjektene slettes...',
        it: 'Sto cancellando i progetti...',
        se: 'Projekten raderas...',
    },
    delSuccess: {
        us: 'Number of deleted projects: ',
        cz: 'Počet smazaných projektů: ',
        pl: 'Liczba usuniętych projektów: ',
        hr: 'Broj izbrisanih projekata: ',
        si: 'Število izbrisanih projektov: ',
        de: 'Anzahl der gelöschten Projekte: ',
        sk: 'Počet zmazaných projektov: ',
        es: 'Número de proyectos borrados: ',
        ru: 'Количество удалённых проектов: ',
        rs: 'Broj izbrisanih projekata: ',
        ua: 'Кількість видалених проекті: ',
        no: 'Antall slettet prosjekter: ',
        it: 'Numero di progetti cancellati: ',
        se: 'Antal raderade projekt: ',
    },
    week: {
        us: 'This week',
        cz: 'Tento týden',
        pl: 'Bieżący tydzień',
        hr: 'Tekući tjedan',
        si: 'Tekoči teden',
        de: 'Diese Woche',
        sk: 'Tento týždeň',
        es: 'Esta semana',
        ru: 'Эта неделя',
        rs: 'Ova sedmica',
        ua: 'Цього тижня',
        no: 'Denne uka',
        it: 'Questa settimana',
        se: 'Denna vecka',
    },
    enterDate: {
        us: 'Enter the date',
        cz: 'Zadejte datum',
        pl: 'Wprowadź datę',
        hr: 'Unesite datum',
        si: 'Vnesite datum',
        de: 'Geben Sie das Datum',
        sk: 'Zadajte dátum',
        es: 'Introduzca la fecha',
        ru: 'Введите даты',
        rs: 'Unesite datum',
        ua: 'Введіть дати',
        no: 'Skriv inn dato',
        it: 'Inserisca la data',
        se: 'Ange datum',
    },
    clearAll: {
        us: 'Clear all',
        cz: 'Zrušit vše',
        pl: 'Anuluj wszystko',
        hr: 'Očisti sve',
        si: 'Počisti vse',
        de: 'Alles löschen',
        sk: 'Zrušiť všetko',
        es: 'Borrar todo',
        ru: 'Очистить все',
        rs: 'Izbriši sve',
        ua: 'Очистити все',
        no: 'Tøm alt',
        it: 'Pulisci tutto',
        se: 'Rensa allt',
    },
    activeFilter: {
        us: 'Active filter',
        cz: 'Aktivní filtr',
        pl: 'Aktywny filtr',
        hr: 'Aktivan filter',
        si: 'Aktivni filter',
        de: 'Aktiver Filter',
        sk: 'Aktívny filter',
        es: 'Filtro activo',
        ru: 'Активные фильтры',
        rs: 'Aktivan filter',
        ua: 'Активні фільтри',
        no: 'Aktivt filter',
        it: 'Filtro attivo',
        se: 'Aktivt filter',
    },
    sortBy: {
        us: 'Sort by',
        cz: 'Řadit podle',
        pl: 'Sortuj według',
        hr: 'Razvrstaj po',
        si: 'Razvrsti po',
        de: 'Sortieren nach',
        sk: 'Radiť podľa',
        es: 'Ordenar por',
        ru: 'Сортировать по',
        rs: 'Sortirati po',
        ua: 'Сортувати за',
        no: 'Sorter etter',
        it: 'Ordina per',
        se: 'Sortera efter',
    },
    updated: {
        us: 'Updated',
        cz: 'Editováno',
        pl: 'Edytowany',
        hr: 'Ažurirano',
        si: 'Posodobljeno',
        de: 'Editiert',
        sk: 'Editované',
        es: 'Actualizado',
        ru: 'Обновлено',
        rs: 'Ažuriran',
        ua: 'Оновлено',
        no: 'Oppdatert',
        it: 'È stato modificato',
        se: 'Redigerat',
    },
    activity: {
        us: 'Activity',
        cz: 'Aktivita',
        pl: 'Aktywność',
        hr: 'Aktivnost',
        si: 'Aktivnost',
        de: 'Aktivität',
        sk: 'Aktivita',
        es: 'Actividad',
        ru: 'Активность',
        rs: 'Aktivnost',
        ua: 'Активність',
        no: 'Aktivitet',
        it: 'Attività',
        se: 'Aktivitet',
    },
    userActivity: {
        us: "The date of the user's last activity, e.g. editing, deleting or sharing projects, incl. changes made by the administrator.",
        cz: 'Datum poslední aktivity uživatele, např. editace, mazání nebo sdílení projektů, vč. změn provedených administrátorem.',
        pl: 'Data ostatniej aktywności użytkownika, takiej jak edytowanie, usuwanie lub udostępnianie projektów, w tym zmian wprowadzonych przez administratora.',
        hr: 'Datum posljednje aktivnosti korisnika, npr. uređivanje, brisanje ili dijeljenje projekata, uklj. promjene koje je izvršio administrator.',
        si: 'Datum zadnje uporabnikove dejavnosti, npr. urejanje, brisanje ali skupna raba projektov, vključno s spremembami, ki jih je izvedel administrator.',
        de: 'Datum der letzten Aktivität des Benutzers, z. B. Bearbeiten, Löschen oder Teilen von Projekten, einschließlich Änderungen vom Administrator.',
        sk: 'Dátum poslednej aktivity používateľa, napr. editovanie, mazanie alebo zdieľanie projektov, vrátane zmien vykonaných správcom.',
        es: 'La fecha de la última actividad del usuario, por ejemplo, editar, borrar o compartir proyectos, incluidos los cambios realizados por el administrador.',
        ru: 'Дата последней активности пользователя, например, редактирование, удаление или совместное использование проектов, включая изменения, внесенные администратором.',
        rs: 'Datum korisnikove poslednje aktivnosti, npr. editovanja, brisanja ili deljenja projekta, uključujući i promene napravljene od strane administratora.',
        ua: 'Дата останньої активності користувача, наприклад, редагування, видалення або надання доступу до проектів, включаючи зміни, внесені адміністратором.',
        no: 'Dato for brukerens siste aktivitet, f.eks. redigering, sletting eller deling av prosjektene, inkludert endringene gjort av administrator.',
        it: "La data dell'ultima attività dell'utente, come la modifica, l'eliminazione o la condivisione di progetti, comprese le modifiche apportate dall'amministratore.",
        se: 'Datum då användaren senast var aktiv och t. ex. editerade, raderade eller delade projekt inkl. ändringar som genomfördes av administratören.',
    },
    ascending: {
        us: 'Ascending',
        cz: 'Vzestupně',
        pl: 'Rosnąco',
        hr: 'Uzlazno',
        si: 'Navzgor',
        de: 'Aufsteigend',
        sk: 'Vzostupne',
        es: 'Ascendente',
        ru: 'По возрастанию',
        rs: 'Uzlazno',
        ua: 'За зростанням',
        no: 'Stigende',
        it: 'Ascendente',
        se: 'Stigande',
    },
    descending: {
        us: 'Descending',
        cz: 'Sestupně',
        pl: 'Malejąco',
        hr: 'Silazno',
        si: 'Navzdol',
        de: 'Absteigend',
        sk: 'Zostupne',
        es: 'Descendente',
        ru: 'По убыванию',
        rs: 'Silazno',
        ua: 'За спаданням',
        no: 'Synkende',
        it: 'Discendente',
        se: 'Fallande',
    },
    name: {
        us: 'Name',
        cz: 'Název',
        pl: 'Nazwa',
        hr: 'Naziv',
        si: 'Ime',
        de: 'Name',
        sk: 'Názov',
        es: 'Nombre',
        ru: 'Имя',
        rs: 'Ime',
        ua: "Ім'я",
        no: 'Tittel',
        it: 'Nome',
        se: 'Namn',
    },
    created: {
        us: 'Created',
        cz: 'Vytvořeno',
        pl: 'Utworzony',
        hr: 'Kreirano',
        si: 'Ustvarjeno',
        de: 'Erstellt',
        sk: 'Vytvorené',
        es: 'Creado',
        ru: 'Создано',
        rs: 'Kreiran',
        ua: 'Створено',
        no: 'Skapt',
        it: 'Creato',
        se: 'Skapat',
    },
    tags: {
        us: 'Tags',
        cz: 'Štítky',
        pl: 'Tagi',
        hr: 'Oznake',
        si: 'Oznaka',
        de: 'Tags',
        sk: 'Štítky',
        es: 'Etiquetas',
        ru: 'Отметки',
        rs: 'Oznake',
        ua: 'Позначки',
        no: 'Etiketter',
        it: 'Tag',
        se: 'Taggar',
    },
    inprogress: {
        us: 'In Progress',
        cz: 'Rozdělané',
        pl: 'W toku',
        hr: 'U tijeku',
        si: 'Izvajanje',
        de: 'In Arbeit',
        sk: 'Rozrobené',
        es: 'En curso',
        ru: 'В процессе',
        rs: 'U toku',
        ua: 'В процесі',
        no: 'Pågår',
        it: 'Diviso',
        se: 'Pågående',
    },
    length: {
        us: 'Duration',
        cz: 'Délka',
        pl: 'Długość',
        hr: 'Trajanje',
        si: 'Trajanje',
        de: 'Länge',
        sk: 'Dĺžka',
        es: 'Duración',
        ru: 'Длина',
        rs: 'Trajanje',
        ua: 'Довжина',
        no: 'Lengde',
        it: 'Lunghezza',
        se: 'Längd',
    },
    shared: {
        us: 'Shared',
        cz: 'Sdíleno',
        pl: 'Udostępnione',
        hr: 'Podijeljeno',
        si: 'Podeljeno',
        de: 'Geteilt',
        sk: 'Zdieľané',
        es: 'Compartido',
        ru: 'Поделился',
        rs: 'Podeljeno',
        ua: 'Поділитися',
        no: 'Delt',
        it: 'Condiviso',
        se: 'Delat',
    },
    sharedWith: {
        us: 'Shared with: ',
        cz: 'Sdíleno s: ',
        pl: 'Udostępnione: ',
        hr: 'Podijeljeno s: ',
        si: 'Podeljeno z: ',
        de: 'Geteilt mit: ',
        sk: 'Zdieľané s: ',
        es: 'Compartido con: ',
        ru: 'Поделился с: ',
        rs: 'Podeljeno sa: ',
        ua: 'Поділитися з: ',
        no: 'Delt med: ',
        it: 'Condiviso con: ',
        se: 'Delat med: ',
    },
    noName: {
        us: 'Project with no name',
        cz: 'Projekt bez názvu',
        pl: 'Projekt bez nazwy',
        hr: 'Projekt bez naziva',
        si: 'Projekt brez imena',
        de: 'Unbenanntes Projekt',
        sk: 'Projekt bez názvu',
        es: 'Proyecto sin nombre',
        ru: 'Проект без названия',
        rs: 'Projekat bez imena',
        ua: 'Проект без назви',
        no: 'Prosjekt uten tittel',
        it: 'Progetto senza nome',
        se: 'Namnlöst projekt',
    },
    show: {
        us: 'Show',
        cz: 'Zobrazit',
        pl: 'Zobacz',
        hr: 'Prikaži',
        si: 'Prikaži',
        de: 'Anzeigen',
        sk: 'Zobraziť',
        es: 'Mostrar',
        ru: 'Показать',
        rs: 'Prikaži',
        ua: 'Показати',
        no: 'Vis',
        it: 'Mostrare',
        se: 'Visa',
    },
    shareCount: {
        us: 'Share count',
        cz: 'Počet sdílení',
        pl: 'Liczba udostępnień',
        hr: 'Broj dijeljenja',
        si: 'Število delenja',
        de: 'Anzahl der Teilungen',
        sk: 'Počet zdieľanie',
        es: 'Numero de veces compartido',
        ru: 'Статистика обмена',
        rs: 'Broj deljenja',
        ua: 'Статистика обміну',
        no: 'Antall deling',
        it: 'Numero di condividioni',
        se: 'Antal delningar',
    },
    error: {
        us: 'An error occurred!',
        cz: 'Došlo k chybě!',
        pl: 'Wystąpił błąd!',
        hr: 'Došlo je do greške!',
        si: 'Prišlo je do napake!',
        de: 'Fehler ist aufgetreten!',
        sk: 'Došlo k chybe!',
        es: '¡Ocurrió un error!',
        ru: 'Произошла ошибка!',
        rs: 'Došlo je do greške!',
        ua: 'Виникла помилка!',
        no: 'Det oppsto en feil!',
        it: 'Si è verificato un errore!',
        se: 'Ett fel har uppstått!',
    },
    expandForMore: {
        us: 'Expand row to see more',
        cz: 'Rozbalte řádek pro více info',
        pl: 'Rozwiń wiersz, aby zobaczyć więcej',
        hr: 'Proširi red za bolji prikaz',
        si: 'Razširi vrsto za boljši prikaz',
        de: 'Zeile erweitern für weitere Info',
        sk: 'Rozbaľte riadok pre viac info',
        es: 'Expanda la fila para ver más',
        ru: 'Разверните текст, чтобы узнать больше',
        rs: 'Proširite red da biste videli više',
        ua: 'Розгорніть текст, щоб дізнатися більше',
        no: 'Utvid raden for å se mer',
        it: 'Espandere la riga per maggiori informazioni',
        se: 'Expandera raden för att se mer',
    },
    editVerb: {
        us: 'Edit',
        cz: 'Upravit',
        pl: 'Edytuj',
        hr: 'Uredi',
        si: 'Uredi',
        de: 'Editieren',
        sk: 'Upraviť',
        es: 'Editar',
        ru: 'Редактировать',
        rs: 'Urediti',
        ua: 'Редагувати',
        no: 'Rediger',
        it: 'Modificare',
        se: 'Redigera',
    },
    savedTags: {
        us: 'Saved tags',
        cz: 'Uložené štítky',
        pl: 'Zapisane tagi',
        hr: 'Spremljene oznake',
        si: 'Shranjene oznake',
        de: 'Gespeicherte Tags',
        sk: 'Uložené štítky',
        es: 'Etiquetas grabadas',
        ru: 'Сохранённые отметки',
        rs: 'Sačuvane oznake',
        ua: 'Збережені позначки',
        no: 'Lagrede etiketter',
        it: 'Etichette salvate',
        se: 'Sparade taggar',
    },
    add: {
        us: 'Add',
        cz: 'Přidat',
        pl: 'Dodaj',
        hr: 'Dodaj',
        si: 'Dodaj',
        de: 'Hinzufügen',
        sk: 'Pridať',
        es: 'Añadir',
        ru: 'Добавить',
        rs: 'Dodati',
        ua: 'Додати',
        no: 'Legg til',
        it: 'Aggiungere',
        se: 'Lägg till',
    },
    transcription: {
        us: 'Transcription',
        cz: 'Přepis',
        pl: 'Transkrypcja',
        hr: 'Transkripcija',
        si: 'Prepis',
        de: 'Transkription',
        sk: 'Prepis',
        es: 'Transcripción',
        ru: 'Транскрипция',
        rs: 'Transkripcija',
        ua: 'Транскрипція',
        no: 'Transkripsjon',
        it: 'Trascrizione',
        se: 'Transkription',
    },
    openEditor: {
        us: 'Open editor',
        cz: 'Otevřít editor',
        pl: 'Otwórz edytor',
        hr: 'Otvori editor',
        si: 'Odpri urejevalnik',
        de: 'Editor öffnen',
        sk: 'Otvoriť editor',
        es: 'Abrir el editor',
        ru: 'Открыть редактор',
        rs: 'Otvori editor',
        ua: 'Відкрити редактор',
        no: 'Åpne editor',
        it: "Aprire l'editor",
        se: 'Öppna editorn',
    },
    openNotes: {
        us: 'Open notes',
        cz: 'Otevřít poznámky',
        pl: 'Otwórz notatki',
        hr: 'Otvori bilješke',
        si: 'Odpri beležke',
        de: 'Anmerkungen öffnen',
        sk: 'Otvoriť poznámky',
        es: 'Abrir las notas',
        ru: 'Открыть заметки',
        rs: 'Otvori beleške',
        ua: 'Відкрити нотатки',
        no: 'Åpne notater',
        it: 'Aprire le note',
        se: 'Öppna noter',
    },
    fulltextSearch: {
        us: 'Fulltext search',
        cz: 'Fulltextové vyhledávání',
        pl: 'Wyszukiwanie pełnotekstowe',
        hr: 'Pretraživanje cijelog teksta',
        si: 'Iskanje po celotnem besedilu',
        de: 'Volltextsuche',
        sk: 'Fulltextové vyhľadávanie',
        es: 'Búsqueda de texto completo',
        ru: 'Искать целый текст',
        rs: 'Pretraživanje celog teksta',
        ua: 'Шукати цілий текст',
        no: 'Fulltekstsøk',
        it: 'Ricerca full-text',
        se: 'Fulltextsökning',
    },
    projectname: {
        us: 'Name',
        cz: 'Název',
        pl: 'Nazwa',
        hr: 'Naziv',
        si: 'Ime',
        de: 'Name',
        sk: 'Názov',
        es: 'Nombre',
        ru: 'Имя',
        rs: 'Ime',
        ua: "Ім'я",
        no: 'Tittel',
        it: 'Nome',
        se: 'Namn',
    },
    inAnyField: {
        us: 'Includes the words',
        cz: 'Obsahuje slova',
        pl: 'Zawiera słowa',
        hr: 'Sadrži riječi',
        si: 'Vsebuje besede',
        de: 'Enthält die Wörter',
        sk: 'Obsahuje slová',
        es: 'Incluye las palabras',
        ru: 'Содержит слова',
        rs: 'Uključuje reči',
        ua: 'Містить слова',
        no: 'Inkluderer ordene',
        it: 'Contiene le parole',
        se: 'Innehåller orden',
    },
    strictSearch: {
        us: 'Exact match',
        cz: 'Přesná shoda',
        pl: 'Dokładne dopasowanie',
        hr: 'Točno poklapanje',
        si: 'Natančno ujemanje',
        de: 'Genaue Übereinstimmung',
        sk: 'Presná zhoda',
        es: 'Coincidencia exacta',
        ru: 'Точное совпадение',
        rs: 'Tačno poklapanje',
        ua: 'Точна відповідність',
        no: 'Nøyaktig treff',
        it: 'Corrispondenza esatta',
        se: 'Exakt träff',
    },
    advancedSearch: {
        us: 'Advanced search',
        cz: 'Pokročilé vyhledávání',
        pl: 'Wyszukiwanie zaawansowane',
        hr: 'Napredno pretraživanje',
        si: 'Napredno iskanje',
        de: 'Erweiterte Suche',
        sk: 'Rozšírené vyhľadávanie',
        es: 'Búsqueda avanzada',
        ru: 'Расширенный поиск',
        rs: 'Napredna pretraga',
        ua: 'Розширений пошук',
        no: 'Avansert søk',
        it: 'Ricerca avanzata',
        se: 'Avancerad sökning',
    },
    strictSearchInfo1: {
        us: 'Quotation marks can also be used',
        cz: 'Pro přesný tvar výrazu lze také použít uvozovky',
        pl: 'Możesz również użyć cudzysłowu',
        hr: 'Navodni znakovi se također mogu koristiti',
        si: 'Narekovaje je tudi mogoče uporabiti',
        de: 'Anführungszeichen können auch verwendet werden',
        sk: 'Na uvedenie presnej podoby výrazu možno použiť aj úvodzovky',
        es: 'También se pueden utilizar comillas',
        ru: 'Также можно использовать кавычки',
        rs: 'Mogu se koristiti i navodnici',
        ua: 'Також можна використовувати лапки',
        no: 'Det er mulig å bruke anførselstegn for å få et nøyaktig treff',
        it: "Le virgolette possono essere utilizzate anche per dare la forma esatta dell'espressione",
        se: 'Citattecken kan användas för att formulera ett exakt uttryck',
    },
    strictSearchInfo2: {
        us: 'e.g. "Monday meeting"',
        cz: 'např. "pondělní jednání"',
        pl: 'np. "spotkanie poniedziałkowe".',
        hr: 'npr. "Sastanak u ponedjeljak"',
        si: 'npr. "Sestanek v ponedeljek"',
        de: 'z.B. "Montags-Meeting"',
        sk: 'napr. "pondelková schôdza"',
        es: 'p.ej. "Reunión del lunes"',
        ru: 'напр. «Понедельная встреча»',
        rs: 'npr. "Sastanak u ponedeljak"',
        ua: 'напр. «Понеділкова зустріч»',
        no: 'f.eks. "mandagsmøte"',
        it: 'ad esempio, "riunione di lunedì"',
        se: 't. ex. "måndagsmöte"',
    },
    resetSearch: {
        us: 'Reset search',
        cz: 'Zrušit vyhledávání',
        pl: 'Zresetuj wyszukiwanie',
        hr: 'Vrati izvornu pretragu',
        si: 'Ponastavi iskanje',
        de: 'Suche zurücksetzen',
        sk: 'Zrušiť vyhľadávanie',
        es: 'Restablecer la búsqueda',
        ru: 'Отменить поиск',
        rs: 'Vrati izvornu pretragu',
        ua: 'Скасувати пошук',
        no: 'Tilbakestill søkefilter',
        it: 'Resettare la ricerca',
        se: 'Återställ sökning',
    },
    clearSearch: {
        us: 'Clear all',
        cz: 'Zrušit vše',
        pl: 'Anuluj wszystko',
        hr: 'Očisti sve',
        si: 'Počisti vse',
        de: 'Alles löschen',
        sk: 'Zrušiť všetko',
        es: 'Borrar todo',
        ru: 'Очистить все',
        rs: 'Izbriši sve',
        ua: 'Очистити все',
        no: 'Tilbakestill alt',
        it: 'Annullare tutto',
        se: 'Återställ allt',
    },
    trialProjectTooltip: {
        us: 'Project was created in trial mode',
        cz: 'Projekt byl vytvořen v testovacím režimu',
        pl: 'Projekt powstał w trybie testowym',
        hr: 'Projekt je napravljen u probnom načinu',
        si: 'Projekt je ustvarjen v poskusnem načinu',
        de: 'Projekt wurde im Testmodus erstellt',
        sk: 'Projekt bol vytvorený v testovacom režime',
        es: 'El proyecto se creó en modo de prueba',
        ru: 'Проект был создан в пробном режиме',
        rs: 'Projekat je napravljen u probnom režimu',
        ua: 'Проект було створено у пробному режимі',
        no: 'Prosjektet ble laget i prøvemodus',
        it: 'Il progetto è stato creato in modalità test',
        se: 'Projektet skapades i testläge',
    },
    userProfile: {
        us: 'User profile',
        cz: 'Profil uživatele',
        pl: 'Profil użytkownika',
        hr: 'Korisnički profil',
        si: 'Uporabniški profil',
        de: 'Benutzerkonto',
        sk: 'Profil používateľa',
        es: 'Perfil del usuario',
        ru: 'Профиль пользователя',
        rs: 'Profil korisnika',
        ua: 'Профіль користувача',
        no: 'Brukerprofil',
        it: "Profilo dell'utente",
        se: 'Användarprofil',
    },
    basicInfo: {
        us: 'Basic information',
        cz: 'Základní údaje',
        pl: 'Informacje podstawowe',
        hr: 'Osnovne informacije',
        si: 'Osnovne informacije',
        de: 'Grundsätzliche Informationen',
        sk: 'Základné údaje',
        es: 'Informacion básica',
        ru: 'Основная информация',
        rs: 'Osnovne informacije',
        ua: 'Основна інформація',
        no: 'Grunnleggende informasjon',
        it: 'Dati di base',
        se: 'Grundläggande information',
    },
    limitAndClaims: {
        us: 'Transcription limit and claims',
        cz: 'Limit přepisů a claimy',
        pl: 'Limit transkrypcji i claimy',
        hr: 'Ograničenje transkripcije i zahtjevi',
        si: 'Omejitev prepisa in zahtevki',
        de: 'Beschränkung der Transkription und Ansprüche',
        sk: 'Limit prepisov a claimy',
        es: 'Límite de transcripciones y reclamaciones',
        ru: 'Ограничение по транскрипции и claims',
        rs: 'Limiti za transkripciju i zahtevi',
        ua: 'Ліміт транскрипції та claims',
        no: 'Transkripsjonbegrensning og claimer',
        it: 'Limiti della trascrizione e reclami',
        se: 'Transkriptionsgräns och krav',
    },
    deleteUser: {
        us: 'Delete user',
        cz: 'Smazat uživatele',
        pl: 'Usuń użytkownika',
        hr: 'Izbriši korisnika',
        si: 'Izbriši uporabnika',
        de: 'Benutzer löschen',
        sk: 'Zmazať používateľa',
        es: 'Eliminar usuario',
        ru: 'Удалить пользователя',
        rs: 'Izbriši korisnika',
        ua: 'Видалити користувача',
        no: 'Slette bruker',
        it: 'Eliminare utente',
        se: 'Radera användare',
    },
    totalTranscribed: {
        us: 'Total transcribed: ',
        cz: 'Celkem přepsáno: ',
        pl: 'Całkiem stranskrybowano: ',
        hr: 'Ukupno transkribirano: ',
        si: 'Skupno prepisano: ',
        de: 'Insgesamt transkribiert: ',
        sk: 'Celkom prepísané: ',
        es: 'Transcribido en total: ',
        ru: 'Всего транскрибировано: ',
        rs: 'Ukupno transkribovano: ',
        ua: 'Усього транскрибовано: ',
        no: 'Transkripsjoner totalt: ',
        it: 'Totale trascritto: ',
        se: 'Sammanlagt transkriberat: ',
    },
    billingInfo: {
        us: 'Billing information',
        cz: 'Fakturační údaje',
        pl: 'Informacje o płatnościach',
        hr: 'Podaci o naplati',
        si: 'Podatki za obračunavanje',
        de: 'Abrechnungsdaten',
        sk: 'Fakturačné údaje',
        es: 'Información de facturación',
        ru: 'Платёжные данные',
        rs: 'Informacije o naplati',
        ua: 'Платіжні дані',
        no: 'Betalingsinformasjon',
        it: 'Informazioni sulla fatturazione',
        se: 'Faktureringsuppgifter',
    },
    fillBillingInfo: {
        us: 'Fill in your billing information before buying credit.',
        cz: 'Zadejte své fakturační údaje, než koupíte kredit.',
        pl: 'Przed zakupem kredytu uzupełnij dane do faktury.',
        hr: 'Ispunite podatke o plaćanju prije kupnje kredita.',
        si: 'Pred nakupom kredita izpolnite podatke o plačilu.',
        de: 'Geben Sie Ihre Zahlungsinformationen ein, bevor Sie Kredit kaufen.',
        sk: 'Zadajte svoje fakturačné údaje, než kúpite kredit.',
        es: 'Complete su información de facturación antes de comprar crédito.',
        ru: 'Укажите свои платёжные данные перед пополнением баланса.',
        rs: 'Pre kupovine kredita popunite informacije o naplati.',
        ua: 'Вкажіть свої платіжні дані перед поповненням балансу.',
        no: 'Fyll ut betalingsopplysninger før du kjøper kreditt.',
        it: 'Inserire i dati di fatturazione prima di acquistare il credito.',
        se: 'Ange dina faktureringsuppgifter innan du köper kredit.',
    },
    fixBillingInfo: {
        us: 'Please correct the information before purchasing.',
        cz: 'Opravte prosím údaje před nákupem.',
        pl: 'Przed zakupem skoryguj informacje.',
        hr: 'Ispravite podatke prije kupnje.',
        si: 'Pred nakupom popravite podatke.',
        de: 'Bitte korrigieren Sie Informationen vor dem Kauf.',
        sk: 'Pred nákupom opravte svoje údaje.',
        es: 'Por favor, corrija la información antes de comprar.',
        ru: 'Пожалуйста, исправьте информацию перед покупкой.',
        rs: 'Ispravite podatke pre kupovine.',
        ua: 'Будь ласка, виправте інформацію перед покупкою.',
        no: 'Vennligst fyll ut riktig informasjon før innkjøpet.',
        it: "Si prega di correggere i dati prima dell'acquisto.",
        se: 'Vänligen rätta till uppgifterna innan inköpet.',
    },
    lowCreditToBuy: {
        us: 'The minimum amount of credit to purchase is',
        cz: 'Minimální množství kreditu k nákupu je',
        pl: 'Minimalna wysokość kredytu do zakupu to',
        hr: 'Minimalni iznos kredita za kupnju je',
        si: 'Minimalni znesek kredita za nakup je',
        de: 'Mindestbetrag für Krediteinkauf beträgt',
        sk: 'Minimálne množstvo kreditu na nákup je',
        es: 'La cantidad mínima de crédito para comprar es',
        ru: 'Минимальная сумма пополнения баланса',
        rs: 'Minimalni iznos kredita za kupovinu je',
        ua: 'Мінімальна сума поповнення балансу',
        no: 'Minimal mengde å kjøpe kreditt er',
        it: "L'importo minimo del credito da acquistare è",
        se: 'Minsta mängd krediter att köpa är',
    },
    creditPositiveInteger: {
        us: 'Credit must be a positive whole number.',
        cz: 'Kredit musí být kladné celé číslo.',
        pl: 'Kredyt musi być dodatnią liczbą całkowitą.',
        hr: 'Kredit mora biti pozitivan cijeli broj.',
        si: 'Kredit mora biti pozitivno celo število.',
        de: 'Kredit muss eine positive ganze Zahl sein.',
        sk: 'Kredit musí byť celé kladné číslo.',
        es: 'El crédito debe ser un número entero positivo.',
        ru: 'На вашем балансе должно быть целое положительное число.',
        rs: 'Kredit mora biti pozitivan ceo broj.',
        ua: 'На вашому балансі має бути ціле позитивне число.',
        no: 'Kreditt må være et positivt, helt tall.',
        it: 'Il credito deve essere un numero intero positivo.',
        se: 'Kredit måste vara ett positivt heltal.',
    },
    unavailableVIES: {
        us: 'The TIN validity check is temporarily unavailable. To use Reverse charge, please try it again later.',
        cz: 'Kontrola platnosti DIČ je dočasně nedostupná. Zkuste to prosím znovu později.',
        pl: 'Kontrola ważności numeru NIP jest chwilowo niedostępna. Spróbuj ponownie później.',
        hr: 'Provjera valjanosti TIN-a privremeno nije dostupna. Pokušajte ponovo kasnije.',
        si: 'Preverjanje veljavnosti davčne številke trenutno ni dostopna. Poskusite ponovno kasneje.',
        de: 'Die Gültigkeitsprüfung von USt-ID ist vorübergehend nicht verfügbar. Bitte versuchen Sie es später noch einmal.',
        sk: 'Kontrola platnosti DIČ je dočasne nedostupná. Skúste to znova neskôr.',
        es: 'La verificación de validez de NIF no está disponible temporalmente. Por favor inténtelo de nuevo más tarde.',
        ru: 'Проверка ИНН временно недоступна. Пожалуйста, попробуйте еще раз позже.',
        rs: 'Provera validnosti PIB-a privremeno nije dostupna. Da biste koristili obrnutu naplatu, pokušajte ponovo kasnije.',
        ua: 'Перевірка ІПН тимчасово недоступна. Будь ласка, спробуйте ще раз пізніше.',
        no: 'Umulig å sjekke skattenummer nå. Vennligst prøv igjen senere hvis du ønsker å benytte snudd avregning (Reverse charge).',
        it: 'Il controllo della validità del codice fiscale/partita IVA non è temporaneamente disponibile. Riprovi più tardi.',
        se: 'Det är inte möjligt för tillfället att verifiera skatteregistreringsnumret. Vänligen prova igen senare om du önskar använda omvänd skattskyldighet.',
    },
    orderFailed: {
        us: 'Failed to create order.',
        cz: 'Při vytváření objednávky došlo k chybě.',
        pl: 'Nie udało się utworzyć zamówienia.',
        hr: 'Izrada narudžbe nije uspjela.',
        si: 'Naročila ni bilo mogoče ustvariti.',
        de: 'Auftrag konnte nicht erstellt werden.',
        sk: 'Pri vytváraní objednávky došlo k chybe.',
        es: 'Se produjo un error al crear el pedido.',
        ru: 'При выполнении заказа произошла ошибка.',
        rs: 'Kreiranje porudžbine nije uspelo.',
        ua: 'Під час виконання замовлення сталася помилка.',
        no: 'Det oppsto en feil ved å opprette en ny bestilling.',
        it: 'Si è verificato un errore durante la creazione di un ordine.',
        se: 'Ett fel har uppsått under beställningen.',
    },
    invalidBillingInfo: {
        us: 'Missing or invalid billing information',
        cz: 'Chybějící nebo neplatné fakturační údaje',
        pl: 'Brakujące lub nieprawidłowe informacje rozliczeniowe',
        hr: 'Podaci o naplati nedostaju ili su nevažeći',
        si: 'Manjkajoči ali neveljavni podatki za obračun',
        de: 'Fehlende oder ungültige Rechnungsdaten',
        sk: 'Chýbajúce alebo neplatné fakturačné údaje',
        es: 'Información de facturación faltante o no válida',
        ru: 'Платёжные данные отсутствуют или указаны неверно',
        rs: 'Podaci o naplati nedostaju ili su nevažeći',
        ua: 'Платіжні дані відсутні або вказані неправильно',
        no: 'Manglende eller feil betalingsopplysninger',
        it: 'Informazioni di fatturazione mancanti o non valide',
        se: 'Faktureringsuppgifter saknas eller är felaktiga',
    },
    editBillingInfo: {
        us: 'Please edit your billing information in the MY ACCOUNT section.',
        cz: 'Upravte prosím své fakturační údaje v sekci MŮJ ÚČET.',
        pl: 'Popraw swoje dane rozliczeniowe w sekcji MOJE KONTO.',
        hr: 'Uredite svoje podatke o naplati u odjeljku MOJ RAČUN.',
        si: 'Uredite svoje podatke za obračun v razdelku MOJ RAČUN.',
        de: 'Bitte bearbeiten Sie Ihre Rechnungsdaten im Menü MEIN KONTO.',
        sk: 'Upravte prosím svoje fakturačné údaje v sekcii MOJE KONTO.',
        es: 'Edite su información de facturación en la sección MI CUENTA.',
        ru: 'Пожалуйста отредактируйте информацию о ваших платежных данных в разделе МОЙ ПРОФИЛЬ.',
        rs: 'Uredite svoje podatke o naplati u odeljku MOJ RAČUN.',
        ua: 'Будь ласка, відредагуйте інформацію про ваші платіжні дані в розділі МІЙ ПРОФІЛЬ.',
        no: 'Vennligst rediger betalingsopplysningene dine under MIN KONTO.',
        it: 'Modifichi i dati di fatturazione in: IL MIO ACCOUNT.',
        se: 'Vänligen redigera faktureringsuppgifterna under MITT KONTO.',
    },
    availableCredit: {
        us: 'Available credit:',
        cz: 'Zbývající kredit:',
        pl: 'Pozostały kredyt:',
        hr: 'Preostali kredit:',
        si: 'Preostali kredit:',
        de: 'Verbleibender Kredit:',
        sk: 'Zostávajúce kredit:',
        es: 'Crédito restante:',
        ru: 'Доступный баланс:',
        rs: 'Dostupni kredit:',
        ua: 'Доступний баланс:',
        no: 'Tilgjengelig kreditt:',
        it: 'Credito rimanente:',
        se: 'Återstående kredit:',
    },
    overviewTab: {
        us: 'Overview',
        cz: 'Přehled',
        pl: 'Przegląd',
        hr: 'Pregled',
        si: 'Pregled',
        de: 'Übersicht',
        sk: 'Prehľad',
        es: 'Descripción general',
        ru: 'Просмотр',
        rs: 'Pregled',
        ua: 'Перегляд',
        no: 'Oversikt',
        it: 'Panoramica',
        se: 'Översikt',
    },
    authentication: {
        us: 'Authentication',
        cz: 'Zabezpečení',
        pl: 'Bezpieczeństwo',
        hr: 'Autentifikacija',
        si: 'Avtentifikacija',
        de: 'Authentifizierung',
        sk: 'Zabezpečenie',
        es: 'Autenticación',
        ru: 'Аутентификация',
        rs: 'Autentikacija',
        ua: 'Аутентифікація',
        no: 'Autentisering',
        it: 'Autenticazione',
        se: 'Autentisering',
    },
    credit: {
        us: 'Credit',
        cz: 'Kredit',
        pl: 'Kredyt',
        hr: 'Kredit',
        si: 'Kredit',
        de: 'Kredit',
        sk: 'Kredit',
        es: 'Crédito',
        ru: 'Баланс',
        rs: 'Kredit',
        ua: 'Баланс',
        no: 'Kreditt',
        it: 'Credito',
        se: 'Kredit',
    },
    creditPrice: {
        us: 'One minute of automatic transcription costs',
        cz: 'Jedna minuta automatického přepisu stojí',
        pl: 'Jedna minuta automatycznej transkrypcji kosztuje',
        hr: 'Cijena po minuti automatske transkripcije iznosi',
        si: 'Cena na minuto avtomatskega prepisa je',
        de: 'Eine Minute automatischer Transkription kostet',
        sk: 'Jedna minúta automatického prepisu stojí',
        es: 'Un minuto de transcripción automática cuesta',
        ru: 'Одна минута автоматической транскрипции стоит',
        rs: 'Jedan minut automatske transkripcije košta',
        ua: 'Одна хвилина автоматичної транскрипції стоїть',
        no: 'Ett minutt av transkripsjon koster',
        it: 'Un minuto di trascrizione automatica costa',
        se: 'En minut av automatisk transkription kostar',
    },
    purchaseCredit: {
        us: 'Purchase credit',
        cz: 'Koupit kredit',
        pl: 'Kup kredyt',
        hr: 'Kupite kredit',
        si: 'Kupite kredit',
        de: 'Kredit kaufen',
        sk: 'Kúpiť kredit',
        es: 'Comprar crédito',
        ru: 'Пополнить баланс',
        rs: 'Kredit za kupovinu',
        ua: 'Поповнити баланс',
        no: 'Kjøp kreditt',
        it: 'Acquistare credito',
        se: 'Köp kredit',
    },
    minutes: {
        us: 'Number of minutes',
        cz: 'Počet minut',
        pl: 'Liczba minut',
        hr: 'Broj minuta',
        si: 'Število minut',
        de: 'Anzahl der Minuten',
        sk: 'Počet minút',
        es: 'Número de minutos',
        ru: 'Количество минут',
        rs: 'Broj minuta',
        ua: 'Кількість хвилин',
        no: 'Antallet minutter',
        it: 'Numero di minuti',
        se: 'Antal minuter',
    },
    minimumMinutes: {
        us: 'Minimum',
        cz: 'Minimálně',
        pl: 'Minimalnie',
        hr: 'Minimum',
        si: 'Minimum',
        de: 'Mindestens',
        sk: 'Minimálne',
        es: 'Mínimo',
        ru: 'Минимально',
        rs: 'Minimum',
        ua: 'Mінімально',
        no: 'Minst',
        it: 'Minimo',
        se: 'Minst',
    },
    buyButton: {
        us: 'Continue to payment',
        cz: 'Pokračovat k platbě',
        pl: 'Przejdź do płatności',
        hr: 'Nastavite s plaćanjem',
        si: 'Nadaljujte s plačilom',
        de: 'Weiter zur Zahlung',
        sk: 'Pokračovať k platbe',
        es: 'Continuar con el pago',
        ru: 'Перейти к оплате',
        rs: 'Nastavite sa plaćanjem',
        ua: 'Перейти до оплати',
        no: 'Fortsett til betaling',
        it: 'Proseguire con il pagamento',
        se: 'Fortsätt till betalning',
    },
    viewPurchaseHistory: {
        us: 'View order/purchase history',
        cz: 'Zobrazit historii objednávek/nákupů',
        pl: 'Zobacz historię zamówień/zakupów',
        hr: 'Pogledajte povijest narudžbi/kupnji',
        si: 'Oglejte si zgodovino naročil/nakupov',
        de: 'Bestell-/Kaufhistorie anzeigen',
        sk: 'Zobraziť históriu objednávok/nákupov',
        es: 'Ver historial de pedidos/compras',
        ru: 'Показать историю заказов/покупок',
        rs: 'Pogledajte istoriju porudžbina/kupovina',
        ua: 'Показати історію замовлень/покупок',
        no: 'Vis bestillings/kjøpshistorikk',
        it: 'Visualizzare lo storico degli ordini/acquisti',
        se: 'Visa beställnings-/betalningshistorik',
    },
    checkingTin: {
        us: 'Checking TIN validity...',
        cz: 'Probíhá kontrola DIČ...',
        pl: 'Sprawdzanie ważności NIP...',
        hr: 'Provjera valjanosti TIN-a...',
        si: 'Preverjanje veljavosti TIN-a...',
        de: 'Überprüfung von Gültigkeit des USt-IDs...',
        sk: 'Prebieha kontrola DIČ...',
        es: 'Comprobando la validez del NIF...',
        ru: 'Проверка ИНН...',
        rs: 'Provera validnosti PIB-a...',
        ua: 'Перевірка ІПН...',
        no: 'Sjekker skattenummer...',
        it: 'Controllo del codice fiscale/partita IVA in corso...',
        se: 'Kontrollerar skatteregistreringsnummer...',
    },
    seeOverview: {
        us: 'View transcription overview',
        cz: 'Zobrazit přehled přepisů',
        pl: 'Wyświetl przegląd transkrypcji',
        hr: 'Pogledajte pregled transkripcije',
        si: 'Oglejte si pregled prepisa',
        de: 'Transkriptionsübersicht anzeigen',
        sk: 'Zobraziť prehľad prepisov',
        es: 'Ver historial de transcripciones',
        ru: 'Показать историю транскрипций',
        rs: 'Pogledajte istoriju transkripcije',
        ua: 'Показати історію транскрипцій',
        no: 'Vis oversikt over transkripsjoner',
        it: 'Visualizza le trascrizioni',
        se: 'Visa översikt av transkriptioner',
    },
    price: {
        us: 'Price',
        cz: 'Cena',
        pl: 'Cena',
        hr: 'Cijena',
        si: 'Cena',
        de: 'Preis',
        sk: 'Cena',
        es: 'Precio',
        ru: 'Цена',
        rs: 'Cena',
        ua: 'Ціна',
        no: 'Pris',
        it: 'Prezzo',
        se: 'Pris',
    },
    currency: {
        us: 'Currency',
        cz: 'Měna',
        pl: 'Waluta',
        hr: 'Valuta',
        si: 'Valuta',
        de: 'Währung',
        sk: 'Mena',
        es: 'Moneda',
        ru: 'Валюта',
        rs: 'Valuta',
        ua: 'Валюта',
        no: 'Valuta',
        it: 'Valuta',
        se: 'Valuta',
    },
    changePW: {
        us: 'Change password',
        cz: 'Změna hesla',
        pl: 'Zmiana hasła',
        hr: 'Promijeni lozinku',
        si: 'Spremeni geslo',
        de: 'Passwort ändern',
        sk: 'Zmena hesla',
        es: 'Cambiar contraseña',
        ru: 'Изменить пароль',
        rs: 'Promenite šifru',
        ua: 'Змінити пароль',
        no: 'Endre passord',
        it: 'Modifica della password',
        se: 'Byt lösenord',
    },
    newSameAsOld: {
        us: 'Your new password is the same as the old one.',
        cz: 'Vaše nové heslo je stejné jako to staré.',
        pl: 'Twoje nowe hasło jest takie samo jak stare.',
        hr: 'Vaša nova lozinka ista je kao prethodna.',
        si: 'Vaše novo geslo je isto, kot prejšnje',
        de: 'Ihr neues Passwort ist gleich wie das alte.',
        sk: 'Vaše nové heslo je rovnaké ako to staré.',
        es: 'Su nueva contraseña es la misma que la anterior.',
        ru: 'Ваш новый пароль совпадает со старым.',
        rs: 'Vaša nova šifra je ista kao i stara.',
        ua: 'Ваш новий пароль співпадає зі старим.',
        no: 'Det nye passordet ditt er det samme som det gamle passordet.',
        it: 'La nuova password è identica a quella precedente.',
        se: 'Ditt nya lösenord är detsamma som det gamla.',
    },
    oldNotValid: {
        us: 'The original password is not valid. Please enter it again.',
        cz: 'Původní heslo je nesprávné. Zadejte ho prosím znovu.',
        pl: 'Oryginalne hasło jest nieprawidłowe. Wpisz je ponownie.',
        hr: 'Originalna lozinka nije važeća. Unesite je ponovno.',
        si: 'Originalno geslo ni veljavno. Prosimo, vnesite ga še enkrat.',
        de: 'Ursprüngliches Passwort ist nicht gültig. Bitte geben Sie es erneut ein.',
        sk: 'Pôvodné heslo je nesprávne. Zadajte ho prosím znova.',
        es: 'La contraseña original no es válida. Por favor, introdúzcala de nuevo.',
        ru: 'Исходный пароль указан неверно.',
        rs: 'Originalna šifra nije važeća.',
        ua: 'Вихідний пароль вказано неправильно.',
        no: 'Det opprinnelige passordet er ikke gyldig. Vennligst prøv igjen.',
        it: 'La password originale non è corretta. Si prega di inserirlo di nuovo.',
        se: 'Det ursprungliga lösenordet är felaktigt. Vänligen prova igen.',
    },
    currPW: {
        us: 'Current password',
        cz: 'Současné heslo',
        pl: 'Aktualne hasło',
        hr: 'Trenutna lozinka',
        si: 'Sedanje geslo',
        de: 'Derzeitiges Passwort',
        sk: 'Aktuálne heslo',
        es: 'Contraseña actual',
        ru: 'Текущий пароль',
        rs: 'Trenutna šifra',
        ua: 'Поточний пароль',
        no: 'Nåværende passord',
        it: 'Password attuale',
        se: 'Nuvarande lösenord',
    },
    pleaseCurr: {
        us: 'Please input your current password',
        cz: 'Vložte prosím své současné heslo',
        pl: 'Wprowadź aktualne hasło',
        hr: 'Molimo unesite svoju trenutnu lozinku',
        si: 'Prosimo, vnesite svoje sedanje geslo',
        de: 'Bitte Ihr dezeiteiges Passwort eingeben',
        sk: 'Vložte svoje aktuálne heslo',
        es: 'Por favor ingrese su contraseña actual',
        ru: 'Пожалуйста, введите ваш текущий пароль',
        rs: 'Unesite svoju tranutnu šifru',
        ua: 'Будь ласка, введіть свій поточний пароль',
        no: 'Vennligst fyll ut din nåværende passord',
        it: 'Inserisca, per favore, la password attuale',
        se: 'Vänligen ange ditt nuvarande lösenord',
    },
    newPW: {
        us: 'New password',
        cz: 'Nové heslo',
        pl: 'Nowe hasło',
        hr: 'Nova lozinka',
        si: 'Novo geslo',
        de: 'Neues Passwort',
        sk: 'Nové heslo',
        es: 'Nueva contraseña',
        ru: 'Новый пароль',
        rs: 'Nova šifra',
        ua: 'Новий пароль',
        no: 'Nytt passord',
        it: 'Nuova password',
        se: 'Nytt lösenord',
    },
    pleaseConfirm: {
        us: 'Please confirm your new password',
        cz: 'Potvrďte prosím své nové heslo',
        pl: 'Potwierdź swoje nowe hasło',
        hr: 'Molimo potvrdite svoju novu lozinku',
        si: 'Prosimo, potrdite svoje novo geslo',
        de: 'Bitte Ihr neues Passwort bestätigen',
        sk: 'Potvrďte svoje nové heslo',
        es: 'Confirme su nueva contraseña',
        ru: 'Пожалуйста, подтвердите пароль',
        rs: 'Molimo vas da potvrdite svoju novu šifru',
        ua: 'Будь ласка, підтвердіть пароль',
        no: 'Vennligst bekreft din nye passord',
        it: 'Confermi la nuova password',
        se: 'Vänligen bekräfta ditt nya lösenord',
    },
    noBillingInfo: {
        us: 'Billing information has not been entered yet.',
        cz: 'Fakturační údaje zatím nebyly vyplněny.',
        pl: 'Dane do faktury nie zostały wprowadzone.',
        hr: 'Podaci o naplati još nisu uneseni.',
        si: 'Podatki za obračun še niso vneseni.',
        de: 'Abrechnungsdaten noch nicht eingegeben.',
        sk: 'Fakturačné údaje nie sú zatiaľ zadané.',
        es: 'Aún no se ingresó la información de facturación.',
        ru: 'Информация о платежных данных еще не была указана.',
        rs: 'Informacije o naplati još nisu unete.',
        ua: 'Інформація про платіжні дані ще не була вказана.',
        no: 'Betalingsopplysninger har ikke blitt fylt ut.',
        it: 'I dati di fatturazione non sono ancora stati inseriti.',
        se: 'Faktureringsuppgifter har inte angivits än.',
    },
    fullName: {
        us: 'Name',
        cz: 'Jméno',
        pl: 'Nazwa',
        hr: 'Ime',
        si: 'Ime',
        de: 'Name',
        sk: 'Meno',
        es: 'Nombre',
        ru: 'Имя',
        rs: 'Ime',
        ua: "Ім'я",
        no: 'Navn',
        it: 'Nome',
        se: 'Namn',
    },
    cinNumber: {
        us: 'CIN',
        cz: 'IČO',
        pl: 'REGON',
        hr: 'OIB',
        si: 'Matična številka podjetja',
        de: 'UID',
        sk: 'IČO',
        es: 'DNI',
        ru: 'ИНН',
        rs: 'CIN',
        ua: 'ИНН',
        no: 'Organisasjonsnummer',
        it: 'ID',
        se: 'Organisationsnummer',
    },
    tinNumber: {
        us: 'TIN',
        cz: 'DIČ',
        pl: 'NIP',
        hr: 'OIB',
        si: 'EMŠO',
        de: 'USt-ID',
        sk: 'DIČ',
        es: 'NIF',
        ru: 'ИНН',
        rs: 'PIB',
        ua: 'ІНП',
        no: 'Skattenummer',
        it: 'Codice fiscale/partita IVA',
        se: 'Skatteregistreringsnummer',
    },
    firstName: {
        us: 'First name',
        cz: 'Jméno',
        pl: 'Imię',
        hr: 'Ime',
        si: 'Ime',
        de: 'Vorname',
        sk: 'Meno',
        es: 'Nombre',
        ru: 'Имя',
        rs: 'Ime',
        ua: "Ім'я",
        no: 'Navn',
        it: 'Nome',
        se: 'Förnamn',
    },
    lastName: {
        us: 'Last name',
        cz: 'Příjmení',
        pl: 'Nazwisko',
        hr: 'Prezime',
        si: 'Priimek',
        de: 'Familienname',
        sk: 'Priezvisko',
        es: 'Apellido',
        ru: 'Фамилия',
        rs: 'Prezime',
        ua: 'Призвище',
        no: 'Etternavn',
        it: 'Cognome',
        se: 'Efternamn',
    },
    phone: {
        us: 'Phone number',
        cz: 'Telefonní číslo',
        pl: 'Numer telefonu',
        hr: 'Broj telefona',
        si: 'Telefonska številka',
        de: 'Telefonnummer',
        sk: 'Telefónne číslo',
        es: 'Número de teléfono',
        ru: 'Телефонный номер',
        rs: 'Broj telefona',
        ua: 'Телефонний номер',
        no: 'Telefonnummer',
        it: 'Numero di telefono',
        se: 'Telefonnummer',
    },
    phoneExample: {
        us: '+1-012-345-6789',
        cz: '+420 123 456 789',
        pl: '+48 123 456 789',
        hr: '+385 1 2345 678',
        si: '+386 1 2345 678',
        de: '+49 123 456 789',
        sk: '+421 123 456 789',
        es: '+34 123 45 67 89',
        ru: '+7 123 456-78-90',
        rs: '+381 12 345 67 89',
        ua: '+380 12 345-67-89',
        no: '+47 12 34 56 78',
        it: '+39 123 4567 890',
        se: '+46 8 123 45 67',
    },
    idNumber: {
        us: 'Company registration number',
        cz: 'Identifikační číslo (IČO)',
        pl: 'Numer REGON',
        hr: 'Matični broj tvrtke',
        si: 'Matična številka podjetja',
        de: 'Unternehmens-Identifikationsnummer (UID)',
        sk: 'Identifikačné číslo (IČO)',
        es: 'Número de registro de la compañía (DNI)',
        ru: 'Идентификационный номер налогоплательщика (ИНН)',
        rs: 'Matični broj firme',
        ua: 'Ідентифікаційний номер платника податків (ІПН)',
        no: 'Organisasjonsnummer',
        it: 'Numero di identificazione (Numero ID)',
        se: 'Organisationsnummer',
    },
    taxIdNumber: {
        us: 'Tax identification number',
        cz: 'Daňové identifikační číslo (DIČ)',
        pl: 'Numer Identyfikacji Podatkowej (NIP)',
        hr: 'Porezni identifikacijski broj',
        si: 'Davčna identifikacijska številka',
        de: 'Umsatzsteuer-Identifikationsnummer (USt-ID)',
        sk: 'Daňové identifikačné číslo (DIČ)',
        es: 'Número de identificación de impuestos (NIF)',
        ru: 'Номер плательщика НДС',
        rs: 'Poreski identifikacioni broj',
        ua: 'Номер платника ПДВ',
        no: 'Skattenummer',
        it: 'Numero di identificazione fiscale (Partita IVA)',
        se: 'Skatteregistreringsnummer',
    },
    reverseCharge: {
        us: 'VAT in Reverse charge regime',
        cz: 'DPH v režimu Reverse charge',
        pl: 'VAT w trybie Reverse charge',
        hr: 'OIB u režimu obrnute naplate',
        si: 'Davčna številka v režimu obrnjenega obračunavanja',
        de: 'MwSt. in Reverse charge',
        sk: 'DPH v režime Reverse charge',
        es: 'NIF en régimen de Reverse charge',
        ru: 'НДС в режиме Reverse charge',
        rs: 'PIB u režimu obrnute naplate',
        ua: 'ПДВ у режимі Reverse charge',
        no: 'mva. i Reverse charge regime (snudd avregning)',
        it: 'IVA in modalità reverse charge',
        se: 'moms vid omvänd skyldighet',
    },
    notReverseCharge: {
        us: 'VAT not in Reverse charge regime',
        cz: 'DPH není v režimu Reverse charge',
        pl: 'VAT nie jest w trybie Reverse charge',
        hr: 'OIB nije u režimu obrnute naplate',
        si: 'Davčna številka ni v režimu obrnjene davčne obveznosti',
        de: 'MwSt. nicht in Reverse Charge',
        sk: 'DPH nie je v režime Reverse charge',
        es: 'TIN no está sujeto al régimen de inversión pasivo',
        ru: 'НДС не в режиме реверсивного начисления',
        rs: 'PIB nije u režimu obrnute naplate',
        ua: 'ПДВ не в режимі зворотного нарахування',
        no: 'mva. ikke i Reverse charge regime (snudd avregning)',
        it: "L'IVA non è in Reverse Charge",
        se: 'Moms ej i Reverse charge regime (omvänd skattskyldighet)',
    },
    address: {
        us: 'Address',
        cz: 'Adresa',
        pl: 'Adres',
        hr: 'Adresa',
        si: 'Naslov',
        de: 'Adresse',
        sk: 'Adresa',
        es: 'Dirección',
        ru: 'Адрес',
        rs: 'Adresa',
        ua: 'Адреса',
        no: 'Adresse',
        it: 'Indirizzo',
        se: 'Adress',
    },
    street: {
        us: 'Street',
        cz: 'Ulice',
        pl: 'Ulica',
        hr: 'Ulica',
        si: 'Ulica',
        de: 'Straße',
        sk: 'Ulica',
        es: 'Calle',
        ru: 'Улица',
        rs: 'Ulica',
        ua: 'Вулиця',
        no: 'Gate',
        it: 'Via',
        se: 'Gata',
    },
    stNumber: {
        us: 'street number',
        cz: 'číslo popisné',
        pl: 'numer',
        hr: 'broj ulice',
        si: 'hišna številka',
        de: 'Hausnummer',
        sk: 'číslo popisné',
        es: 'número de calle',
        ru: 'номер дома',
        rs: 'broj ulice',
        ua: 'номер будинку',
        no: 'gatenummer',
        it: 'numero civico',
        se: 'husnummer',
    },
    addressComplement: {
        us: 'Apartment, floor',
        cz: 'Byt, patro',
        pl: 'Numer mieszkania',
        hr: 'Stan, kat',
        si: 'Stanovanje, nadstropje',
        de: 'Wohnung, Stock',
        sk: 'Byt, poschodie',
        es: 'Apartamento, piso',
        ru: 'Квартира, этаж',
        rs: 'Stan, sprat',
        ua: 'Квартира, поверх',
        no: 'Leilighet, etasje',
        it: 'Appartamento, piano',
        se: 'Lägenhet, våning',
    },
    city: {
        us: 'Town',
        cz: 'Město',
        pl: 'Miasto',
        hr: 'Grad',
        si: 'Mesto',
        de: 'Stadt',
        sk: 'Mesto',
        es: 'Ciudad',
        ru: 'Город',
        rs: 'Grad',
        ua: 'Місто',
        no: 'By',
        it: 'Città',
        se: 'Stad',
    },
    postcode: {
        us: 'ZIP Code',
        cz: 'PSČ',
        pl: 'Kod pocztowy',
        hr: 'Poštanski broj',
        si: 'Poštna številka',
        de: 'PLZ',
        sk: 'PSČ',
        es: 'Código postal',
        ru: 'Почтовый индекс',
        rs: 'Poštanski broj',
        ua: 'Поштовий індекс',
        no: 'Postnummer',
        it: 'CAP',
        se: 'Postnummer',
    },
    country: {
        us: 'Country',
        cz: 'Stát',
        pl: 'Kraj',
        hr: 'Država',
        si: 'Država',
        de: 'Staat',
        sk: 'Štát',
        es: 'País',
        ru: 'Страна',
        rs: 'Država',
        ua: 'Країна',
        no: 'Land',
        it: 'Paese',
        se: 'Land',
    },
    chooseCountry: {
        us: 'Choose country',
        cz: 'Vyberte stát',
        pl: 'Wybierz kraj',
        hr: 'Odaberi državu',
        si: 'Izberi državo',
        de: 'Staat wählen',
        sk: 'Vyberte štát',
        es: 'Elegir país',
        ru: 'Выберите страну',
        rs: 'Izaberite državu',
        ua: 'Виберіть країну',
        no: 'Velg land',
        it: 'Scelga il paese',
        se: 'Välj land',
    },
    dataAgreement: {
        us: ', as well as the storage and processing of ',
        cz: ' a se zpracováním a uchováváním ',
        pl: ' oraz przetwarzanie i przechowywanie ',
        hr: ' kao i pohranom i obradom ',
        si: ' kot tudi s shranjevanje in obdelavo ',
        de: ' sowie mit der Verarbeitung und Speicherung ',
        sk: ' a so spracovaním a uchovávaním ',
        es: ', así como con el almacenamiento y procesamiento de ',
        ru: ' с обработкой и сохранением ',
        rs: ', kao i skladištenje i obradu ',
        ua: ' з обробкою та збереженням ',
        no: ' & lagring og behandling av ',
        it: " e con l'elaborazione e l'archiviazione di ",
        se: ' och lagring samt behandling av ',
    },
    myPersonalData: {
        us: 'my personal data.',
        cz: 'osobních údajů.',
        pl: 'danych osobowych.',
        hr: 'mojih osobnih podataka.',
        si: 'mojih osebnih podatkov.',
        de: 'personenbezogener Daten.',
        sk: 'osobných údajov.',
        es: 'mis datos personales.',
        ru: 'персональных данных.',
        rs: 'moji lični podaci.',
        ua: 'персональних даних.',
        no: 'mine personopplysninger.',
        it: 'i miei dati personali.',
        se: 'mina personuppgifter.',
    },
    personalData: {
        us: 'personal data.',
        cz: 'osobních údajů.',
        pl: 'danych osobowych.',
        hr: 'osobnih podataka.',
        si: 'osebnih podatkov.',
        de: 'personenbezogener Daten.',
        sk: 'osobných údajov.',
        es: 'datos personales.',
        ru: 'персональных данных.',
        rs: 'lični podaci.',
        ua: 'персональних даних.',
        no: 'personopplysninger.',
        it: 'dati personali.',
        se: 'personuppgifter.',
    },
    dataAgreementUrl: {
        us: 'https://www.beey.io/en/privacy-policy/',
        cz: 'https://www.beey.io/cs/informace-o-zpracovani-osobnich-udaju/',
        pl: 'https://www.beey.io/pl/warunki/#polityka-prywatnosci',
        hr: 'https://www.beey.io/en/privacy-policy/',
        si: 'https://www.beey.io/en/privacy-policy/',
        de: 'https://www.beey.io/en/privacy-policy/',
        sk: 'https://www.beey.io/en/privacy-policy/',
        es: 'https://www.beey.io/en/privacy-policy/',
        ru: 'https://www.beey.io/en/privacy-policy/',
        rs: 'https://www.beey.io/en/privacy-policy/',
        ua: 'https://www.beey.io/en/privacy-policy/',
        no: 'https://www.beey.io/en/privacy-policy/',
        it: 'https://www.beey.io/en/privacy-policy/',
        se: 'https://www.beey.io/en/privacy-policy/',
    },
    conditionsAgreement: {
        us: 'I agree with the ',
        cz: 'Souhlasím s ',
        pl: 'Wyrażam zgodę na ',
        hr: 'Slažem se s ',
        si: 'Strinjam se s ',
        de: 'Ich bin einverstanden mit den ',
        sk: 'Súhlasím s ',
        es: 'Estoy de acuerdo con los ',
        ru: 'Согласен(-на) с ',
        rs: 'Slažem se sa ',
        ua: 'Згоден(-на) з ',
        no: 'Jeg godtar ',
        it: "Sono d'accordo con ",
        se: 'Jag godkänner ',
    },
    termsConditions: {
        us: 'terms and conditions',
        cz: 'obchodními podmínkami',
        pl: 'warunki handlowe',
        hr: 'uvjetima poslovanja',
        si: 'pogoji poslovanja',
        de: 'Geschäftsbedingungen',
        sk: 'obchodnými podmienkami',
        es: 'términos y condiciones',
        ru: 'с пользовательским соглашением',
        rs: 'uslovi',
        ua: 'з користувальницькою угодою',
        no: 'vilkårene og betingelsene',
        it: 'termini e condizioni',
        se: 'användarvillkoren',
    },
    termsConditionsUrl: {
        us: 'https://www.beey.io/en/terms-and-conditions/',
        cz: 'https://www.beey.io/cs/obchodni-podminky/',
        pl: 'https://www.beey.io/pl/warunki/',
        hr: 'https://www.beey.io/en/terms-and-conditions/',
        si: 'https://www.beey.io/en/terms-and-conditions/',
        de: 'https://www.beey.io/en/terms-and-conditions/',
        sk: 'https://www.beey.io/en/terms-and-conditions/',
        es: 'https://www.beey.io/en/terms-and-conditions/',
        ru: 'https://www.beey.io/en/terms-and-conditions/',
        rs: 'https://www.beey.io/en/terms-and-conditions/',
        ua: 'https://www.beey.io/en/terms-and-conditions/',
        no: 'https://www.beey.io/en/terms-and-conditions/',
        it: 'https://www.beey.io/en/terms-and-conditions/',
        se: 'https://www.beey.io/en/terms-and-conditions/',
    },
    mailingAgreement: {
        us: 'I agree with receiving commercial mail',
        cz: 'Souhlasím se zasíláním obchodních sdělení',
        pl: 'Wyrażam zgodę na przesyłanie informacji handlowych',
        hr: 'Slažem se s primanjem komercijalne pošte',
        si: 'Strinjam se s sprejemanjem komercialnih sporočil',
        de: 'Ich bin damit einverstanden, kommerzielle Mitteilungen zu erhalten',
        sk: 'Súhlasím so zasielaním obchodných oznámení',
        es: 'Estoy de acuerdo con recibir correos electrónicos comerciales',
        ru: 'Согласен(-на) на e-mail рассылку',
        rs: 'Slažem se sa prijemom komercijalne pošte',
        ua: 'Згоден на e-mail розсилку',
        no: 'Jeg samtykker i å motta kommersiell kommunikasjon',
        it: 'Accetto di ricevere comunicazioni commerciali',
        se: 'Jag samtycker till att ta emot kommersiell kommunikation',
    },
    consentRequired: {
        us: 'Data processing consent is required.',
        cz: 'Souhlas se zpracováním údajů je vyžadován.',
        pl: 'Zgoda na przetwarzanie danych jest wymagana.',
        hr: 'Potrebna je suglasnost za obradu podataka.',
        si: 'Potrebno je soglasje za obdelavo podatkov.',
        de: 'Zustimmung zur Datenverarbeitung ist erforderlich.',
        sk: 'Súhlas so spracovaním údajov je vyžadovaný.',
        es: 'Se requiere consentimiento para el procesamiento de datos.',
        ru: 'Согласен(-на) с обработкой персональных данных.',
        rs: 'Potrebna je saglasnost za obradu podataka.',
        ua: 'Згідний з обробкою персональних даних.',
        no: 'Samtykke til databehandling kreves.',
        it: 'È necessario il consenso al trattamento dei dati.',
        se: 'Samtycke till behandling av personuppgifter krävs.',
    },
    dataProtectionConsent: {
        us: 'Consent to terms of service and processing of personal data',
        cz: 'Souhlas s obchodními podmínkami a zpracováním osobních údajů',
        pl: 'Zgoda na warunki handlowe i przetwarzanie danych osobowych',
        hr: 'Pristanak na uvjete pružanja usluge i obradu osobnih podataka',
        si: 'Soglasje za pogoje storitve in obdelavo osebnih podatkov',
        de: 'Zustimmung zu den Nutzungsbedingungen und zur Verarbeitung der personenbezogenen Daten',
        sk: 'Súhlas s obchodnými podmienkami a spracovaním osobných údajov',
        es: 'Consentimiento a los términos de servicio y el procesamiento de datos personales',
        ru: 'Согласие на условия обслуживания и обработки персональных данных',
        rs: 'Saglasnost sa uslovima korišćenja i obradom ličnih podataka',
        ua: 'Згода на умови обслуговування та обробки персональних даних',
        no: 'Samtykke til vilkårene for bruk og for behandling av personlig informasjon',
        it: 'Consenso alle condizioni e al trattamento dei dati personali',
        se: 'Samtycke till användarvillkoren och behandling av personuppgifter',
    },
    dataProtectionConsentRequired: {
        us: 'Beey will be fully at your service again soon, we just need your consent with the',
        cz: 'Beey vám bude brzy zase plně k dispozici, jenom nejdříve potřebujeme váš souhlas s',
        pl: 'Beey będzie wkrótce znów w pełni do Twojej dyspozycji, najpierw jednak potrzebujemy Twojej zgody na',
        hr: 'Beey će uskoro ponovno biti dostupan, samo nam je potreban Vaš pristanak s',
        si: 'Beey bo kmalu spet dostopen, potrebujemo samo vaše soglasje z',
        de: 'Beey wird Ihnen bald wieder voll zur Verfügung stehen, wir brauchen nur Ihre Zustimmung zu den',
        sk: 'Beey bude čoskoro opäť plne k dispozícii, len najprv potrebujeme váš súhlas s',
        es: 'Beey estará completamente a su servicio nuevamente pronto, solo necesitamos su consentimiento con',
        ru: 'Beey скоро снова будет полностью к вашим услугам, нам только нужно ваше согласие с',
        rs: 'Beey će vam uskoro ponovo biti u potpunosti na usluzi, samo nam treba vaša saglasnost sa',
        ua: 'Незабаром Beey знову буде повністю до ваших послуг, нам просто потрібна ваша згода з',
        no: 'Beey blir klar til bruk igjen snart, men først trenger vi ditt samtykke til',
        it: 'Beey sarà presto di nuovo completamente disponibile, ma prima abbiamo bisogno del suo consenso con',
        se: 'Beey kommer snart igen att stå till förfogande, men först behöver vi ditt samtycke med',
    },
    anyQuestions: {
        us: 'Any questions?',
        cz: 'Chcete se na něco zeptat?',
        pl: 'Masz jakieś pytania?',
        hr: 'Ima li pitanja?',
        si: 'A ima kakšnih vprašanj?',
        de: 'Haben Sie Fragen?',
        sk: 'Chcete sa na niečo opýtať?',
        es: '¿Alguna pregunta?',
        ru: 'Какие-нибудь вопросы?',
        rs: 'Ima li pitanja?',
        ua: 'Які-небудь питання?',
        no: 'Har du noen spørsmål?',
        it: 'Ha delle domande?',
        se: 'Har du några frågor?',
    },
    contact: {
        us: 'Contact us.',
        cz: 'Ozvěte se nám.',
        pl: 'Napisz do nas.',
        hr: 'Kontaktirajte s nama.',
        si: 'Stopite v stik z nami.',
        de: 'Kontaktieren Sie uns.',
        sk: 'Ozvite sa nám.',
        es: 'Contáctenos.',
        ru: 'Свяжитесь с нами.',
        rs: 'Kontaktirajte nas.',
        ua: "Зв'яжіться з нами.",
        no: 'Kontakt oss.',
        it: 'Ci contatti.',
        se: 'Kontakta oss.',
    },
    confirmConsent: {
        us: 'Confirm',
        cz: 'Potvrdit',
        pl: 'Potwierdź',
        hr: 'Potvrdi',
        si: 'Potrdi',
        de: 'Bestätigen',
        sk: 'Potvrdiť heslo',
        es: 'Confirmar',
        ru: 'Подтвердите',
        rs: 'Potvrdi',
        ua: 'Підтвердьте',
        no: 'Bekrefte',
        it: 'Confermare',
        se: 'Bekräfta',
    },
    contactOptions: {
        us: 'https://www.beey.io/en/contact/',
        cz: 'https://www.beey.io/cs/kontakty/',
        pl: 'https://www.beey.io/pl/kontakty/',
        hr: 'https://www.beey.io/hr/kontakti/',
        si: 'https://www.beey.io/sl/kontakti/',
        de: 'https://www.beey.io/de/kontakte/',
        sk: 'https://www.beey.io/cs/kontakty/',
        es: 'https://www.beey.io/en/contact/',
        ru: 'https://www.beey.io/ru/kontakty/',
        rs: 'https://www.beey.io/en/contact/',
        ua: 'https://www.beey.io/ru/kontakty/',
        no: 'https://www.beey.io/en/contact/',
        it: 'https://www.beey.io/en/contact/',
        se: 'https://www.beey.io/en/contact/',
    },
    invalidEmail: {
        us: 'Please specify a valid e-mail.',
        cz: 'Zadejte prosím platnou e-mailovou adresu.',
        pl: 'Wprowadź poprawny adres e-mail.',
        hr: 'Molimo unesite valjanu e-mail adresu.',
        si: 'Prosimo, vnesite veljaven e-mail naslov.',
        de: 'Bitte gültige E-mail Adresse eingeben.',
        sk: 'Zadajte platnú e-mailovú adresu.',
        es: 'Por favor ingrese un e-mail válido.',
        ru: 'Введите, пожалуйста, действительный e-mail.',
        rs: 'Molimo vas da navedete važeću e-mail adresu.',
        ua: 'Введіть будь-ласка дійсний e-mail.',
        no: 'Vennligst fyll ut en gyldig e-postadresse.',
        it: 'Inserisca un indirizzo e-mail valido.',
        se: 'Vänligen ange en giltig e-postadress.',
    },
    phoneFormat: {
        us: 'Please enter a valid number in international format, e.g. +1-012-345-6789.',
        cz: 'Zadejte prosím existující číslo v mezinárodním formátu, např. +420 123 456 789.',
        pl: 'Wpisz numer w formacie międzynarodowym, np. +48 123 456 789.',
        hr: 'Unesite važeći broj u međunarodnom formatu, npr. +385 1 2345 678.',
        si: 'Vnesite veljavno številko v mednarodnem formatu, npr. +386 1 2345 678.',
        de: 'Bitte geben Sie die Nummer im internationalen Format ein, z. B. +49 123 456 789.',
        sk: 'Číslo zadajte v medzinárodnom formáte, napr. +421 123 456 789.',
        es: 'Ingrese el número en formato internacional, p. Ej. +34 123 45 67 89.',
        ru: 'Пожалуйста введите существующий номер в международном формате, например +7 123 456-78-90.',
        rs: 'Unesite važeći broj u međunarodnom formatu, npr +381 12 345 67 89.',
        ua: 'Будь ласка, введіть існуючий номер у міжнародному форматі, наприклад, +380 12 345-67-89.',
        no: 'Vennligst fyll ut et gyldig telefonnummer i det internasjonale formatet, f.eks. +47 12 34 56 78.',
        it: 'Immetta un numero esistente in formato internazionale, ad esempio +39 123 456 789.',
        se: 'Vänligen ange ett giltigt telefonnummer i internationellt format, t.ex. +46 8 123 45 67.',
    },
    invalidCountry: {
        us: 'Please enter valid country format.',
        cz: 'Zadajte platný kód země.',
        pl: 'Wprowadź prawidłowy kod kraju.',
        hr: 'Molim unesite pravilan format države.',
        si: 'Vnesite veljavno obliko države.',
        de: 'Bitte gültiges Länderformat eingeben.',
        sk: 'Zadajte platný kód krajiny.',
        es: 'Por favor, ingrese un formato de país válido.',
        ru: 'Пожалуйста, введите правильный формат страны',
        rs: 'Molimo unesite validni format države.',
        ua: 'Будь ласка, введіть правильний формат країни.',
        no: 'Vennligst skriv inn gyldig landskode.',
        it: 'Inserire un codice paese valido.',
        se: 'Ange en giltig landskod.',
    },
    agreementRequired: {
        us: 'Agreement is required for payments and billing.',
        cz: 'Souhlas je nezbytný pro platby a fakturace.',
        pl: 'Zgoda jest wymagana w przypadku płatności i fakturowania.',
        hr: 'Potrebna je suglasnost za plaćanje i naplatu.',
        si: 'Potrebno je soglasje za plačevanje in obračunavanje.',
        de: 'Die Zustimmung wird für Zahlungen und Abrechnungen benötigt.',
        sk: 'Súhlas je nutný pre platby a fakturácie.',
        es: 'Se requiere un acuerdo para los pagos y la facturación.',
        ru: 'Согласие необходимо для проведения оплат и выставления счетов-фактур.',
        rs: 'Ugovor je potreban za plaćanje i fakturisanje.',
        ua: 'Згода потрібна щодо оплати і виставлення рахунків-фактур.',
        no: 'Samtykket er nødvendig for betaling og fakturering.',
        it: 'Il consenso è necessario per il pagamento e la fatturazione.',
        se: 'Samtycke är nödvändigt för betalningar och faktureringar.',
    },
    saved: {
        us: 'Saved ✓',
        cz: 'Uloženo ✓',
        pl: 'Zapisano ✓',
        hr: 'Spremljeno ✓',
        si: 'Shranjeno ✓',
        de: 'Gespeichert ✓',
        sk: 'Uložené ✓',
        es: 'Guardado ✓',
        ru: 'Сохранено ✓',
        rs: 'Sačuvano ✓',
        ua: 'Збережено ✓',
        no: 'Lagret ✓',
        it: 'Salvato ✓',
        se: 'Sparat ✓',
    },
    changesSaved: {
        us: 'Changes saved',
        cz: 'Změny uloženy',
        pl: 'Zmiany zostały zapisane',
        hr: 'Promjene su spremljene',
        si: 'Spremembe so shranjene',
        de: 'Änderungen gespeichert',
        sk: 'Zmeny sú uložené',
        es: 'Cambios guardados',
        ru: 'Изменения сохранены',
        rs: 'Promene su sačuvane',
        ua: 'Зміни збережені',
        no: 'Endringene lagret',
        it: 'Modifiche salvate',
        se: 'Ändringar sparade',
    },
    failedToSave: {
        us: 'Failed to save changes!',
        cz: 'Při ukládání došlo k chybě!',
        pl: 'Zapisywanie się nie powiodło!',
        hr: 'Spremanje nije uspjelo!',
        si: 'Shranjevanje ni uspelo!',
        de: 'Fehler beim Speichern!',
        sk: 'Pri ukladaní sa vyskytla chyba!',
        es: '¡No se pudieron guardar los cambios!',
        ru: 'При сохранении произошла ошибка!',
        rs: 'Čuvanje promena nije uspelo!',
        ua: 'У разі збереження сталася помилка!',
        no: 'Klarte ikke å lagre endringene!',
        it: 'Si è verificato un errore durante il salvataggio!',
        se: 'Det gick inte att spara ändringarna!',
    },
    validityCheckNotAvailable: {
        us: 'Validity check of TIN is temporary unavailable. If you want to use Reverse charge, please try it later again.',
        cz: 'Kontrola platnosti DIČ je dočasně nedostupná. Pokud chcete uplatnit Reverse charge, zkuste to prosím později.',
        pl: 'Kontrola ważności numeru NIP jest chwilowo niedostępna. Jeśli chcesz zastosować Reverse charge, spróbuj ponownie później.',
        hr: 'Provjera valjanosti OIB-a privremeno nije dostupna. Ako želite koristiti obrnutu naplatu, pokušajte ponovo kasnije.',
        si: 'Preverjanje veljavnostIi davčne številke ni dostopna. Če želite uporabiti obrnjeno obračunavanje, poskusite ponovno kasneje.',
        de: 'Die Gültigkeitsprüfung von USt-ID ist vorübergehend nicht verfügbar. Wenn Sie Reverse charge verwenden möchten, versuchen Sie es bitte später.',
        sk: 'Kontrola platnosti DIČ je dočasne nedostupná. Ak chcete uplatniť Reverse charge, skúste to prosím neskôr.',
        es: 'La verificación de validez de NIF no está disponible temporalmente. Si desea utilizar el Reverse charge, por favor inténtelo de nuevo más tarde.',
        ru: 'Проверка ИНН временно недоступна. Если хотите использовать Reverse charge, пожалуйста, попробуйте еще раз позже.',
        rs: 'Provera validnosti PIB-a privremeno nije dostupna. Da biste koristili obrnutu naplatu, pokušajte ponovo kasnije.',
        ua: 'Перевірка ІПН тимчасово недоступна. Якщо хочете використовувати Reverse charge, будь ласка, спробуйте ще раз пізніше.',
        no: 'Umulig å sjekke skattenummer nå. Vennligst prøv igjen senere hvis du ønsker å benytte snudd avregning (Reverse charge).',
        it: 'Il controllo della validità del codice fiscale/partita IVA non è temporaneamente disponibile. Se si desidera applicare un addebito inverso, riprovi più tardi.',
        se: 'Det är inte möjligt för tillfället att verifiera skatteregistreringsnumret. Vänligen prova igen senare om du önskar använda omvänd skattskyldighet.',
    },
    invalidTin: {
        us: 'The combination of this TIN and country is invalid. Please edit them or remove TIN number.',
        cz: 'Kombinace zadaného DIČ a země je neplatná. Upravte údaje nebo odstraňte DIČ.',
        pl: 'Połączenie numeru NIP i kraju jest nieprawidłowe. Edytuj dane lub usuń numer NIP.',
        hr: 'Kombinacija ovog OIB-a i države nije valjana. Uredite ih ili uklonite OIB.',
        si: 'Kombinacija davčne številke in države ni veljavna. Uredite ali odstranite davčno številko.',
        de: 'Die Kombination dieser Steuernummer und des Landes ist ungültig. Bitte bearbeiten Sie sie oder entfernen Sie die Steuernummer.',
        sk: 'Kombinácia zadaného DIČ a krajiny je neplatná. Upravte údaje alebo odstráňte DIČ.',
        es: 'La combinación de este NIF y país es inválida. Por favor edítelos o elimine el número NIF.',
        ru: 'Сочетание данного ИНН и страны недействительно. Пожалуйста, отредактируйте их или удалите номер ИНН.',
        rs: 'Kombinacija ovog PIB-a i zemlje je nevažeća. Izmenite ih ili uklonite PIB.',
        ua: 'Комбінація цього ІПН та країни є недійсною. Будь ласка, відредагуйте їх або видаліть номер ІПН.',
        no: 'Kombinasjonen av dette organisasjonsnummeret og landet er ugyldig. Vennligst rediger dem eller fjern organisasjonsnummeret.',
        it: 'La combinazione di partita IVA e Paese inserita non è valida. Modificare i dati o rimuovere la partita IVA.',
        se: 'Kombinationen av det angivna skatteregistreringsnumret och landet är inte giltig. Vänligen korrigera uppgifterna eller radera skatteregistreringsnumret.',
    },
    missingTin: {
        us: 'To apply Reverse charge, enter TIN.',
        cz: 'Zadejte DIČ, pokud chcete uplatnit Reverse charge.',
        pl: 'Wprowadź swój numer NIP, jeśli chcesz zastosować Reverse charge.',
        hr: 'Da biste primijenili obrnutu naplatu, unesite OIB.',
        si: 'Da bi uporabili obrnjeno obračunavane, vnesite davčno številko.',
        de: 'Geben Sie USt-ID ein, um die Reverse charge anzuwenden.',
        sk: 'Zadajte DIČ, ak chcete uplatniť Reverse charge.',
        es: 'Para aplicar el Reverse charge, ingrese el NIF.',
        ru: 'Введите ИНН, если хотите воспользоваться Reverse charge.',
        rs: 'Da biste primenili obrnutu naplatu, unesite PIB.',
        ua: 'Введіть ІПН, якщо хочете скористатися Reverse charge.',
        no: 'Oppgi skattenummer for å benytte snudd avregning (Reverse charge).',
        it: 'Inserire il codice fiscale/partita IVA se si desidera applicare un Reverse Charge.',
        se: 'Ange skatteregistreringsnummer för att använda omvänd skattskyldighet.',
    },
    invalidForCz: {
        us: 'Reverse charge cannot be applied for this service in Czechia.',
        cz: 'Pro tuto službu nelze v Česku uplatnit Reverse charge.',
        pl: 'W Republice Czeskiej w przypadku tej usługi nie można zastosować Reverse charge.',
        hr: 'U Republici Češkoj se za ovu uslugu ne može primijeniti obrnuta naplata.',
        si: 'Na Češkem za to storitev ni mogoče uporabiti obrnjenega obračunavanja.',
        de: 'Für diesen Service in Tschechien kann Reverse charge nicht erhoben werden.',
        sk: 'Pre túto službu nemožno v Česku uplatniť Reverse charge.',
        es: 'No se puede aplicar el Reverse charge para este servicio en Chequia.',
        ru: 'В рамках этой услуги в Чехии невозможно воспользоваться Reverse charge.',
        rs: 'Obrnuta naplata se ne može primeniti za ovu uslugu u Češkoj.',
        ua: 'В рамках цієї послуги у Чехії неможливо скористатися Reverse charge.',
        no: 'Denne tjenesten kan ikke benyttes av snudd avregning (Reverse charge) i Tsjekkia.',
        it: 'Il reverse charge non può essere applicato per questo servizio nella Repubblica Ceca.',
        se: 'Denna tjänst omfattas inte av omvänd skattskyldighet i Tjeckien.',
    },
    saving: {
        us: 'Saving...',
        cz: 'Ukládám...',
        pl: 'Zapisuję...',
        hr: 'Spremanje...',
        si: 'Shranjevanje...',
        de: 'Speichern...',
        sk: 'Ukladám...',
        es: 'Guardando...',
        ru: 'Сохранение...',
        rs: 'Čuvanje...',
        ua: 'Збереження...',
        no: 'Lagrer...',
        it: 'Sto salvando...',
        se: 'Sparar...',
    },
    amount: {
        us: 'Amount',
        cz: 'Částka',
        pl: 'Kwota',
        hr: 'Iznos',
        si: 'Znesek',
        de: 'Betrag',
        sk: 'Čiastka',
        es: 'Monto',
        ru: 'Сумма',
        rs: 'Iznos',
        ua: 'Сума',
        no: 'Sum',
        it: 'Importo',
        se: 'Summa',
    },
    dateUtc: {
        us: 'Date (UTC)',
        cz: 'Datum (UTC)',
        pl: 'Data (UTC)',
        hr: 'Datum (UTC)',
        si: 'Datum (UTC)',
        de: 'Datum (UTC)',
        sk: 'Dátum (UTC)',
        es: 'Fecha (UTC)',
        ru: 'Дата (UTC)',
        rs: 'Datum (UTC)',
        ua: 'Дата (UTC)',
        no: 'Dato (UTC)',
        it: 'Data (UTC)',
        se: 'Datum (UTC)',
    },
    status: {
        us: 'Status',
        cz: 'Stav',
        pl: 'Status',
        hr: 'Status',
        si: 'Status',
        de: 'Status',
        sk: 'Stav',
        es: 'Estado',
        ru: 'Статус',
        rs: 'Status',
        ua: 'Статус',
        no: 'Status',
        it: 'Stato',
        se: 'Tillstånd',
    },
    response: {
        us: 'Response',
        cz: 'Odpověď',
        pl: 'Odpowiedź',
        hr: 'Odgovor',
        si: 'Odgovor',
        de: 'Antwort',
        sk: 'Odpoveď',
        es: 'Respuesta',
        ru: 'Ответ',
        rs: 'Odgovor',
        ua: 'Відповідь',
        no: 'Svar',
        it: 'Risposta',
        se: 'Svar',
    },
    ordersOverview: {
        us: 'Orders/purchase overview',
        cz: 'Přehled objednávek/nákupů',
        pl: 'Przegląd zamówień/zakupów',
        hr: 'Pregled narudžbi/kupnji',
        si: 'Pregled naročil/nakupov',
        de: 'Bestell-/Einkaufsübersicht',
        sk: 'Prehľad objednávok/nákupov',
        es: 'Resumen de pedidos/compras',
        ru: 'Список заказов/покупок',
        rs: 'Pregled porudžbina/kupovina',
        ua: 'Список замовлень/покупок',
        no: 'Oversikt over bestillinger/innkjøp',
        it: 'Panoramica degli ordini/acquisti',
        se: 'Översikt av beställningar/inköp',
    },
    detail: {
        us: 'Detail',
        cz: 'Detail',
        pl: 'Szczegóły',
        hr: 'Detalj',
        si: 'Podrobnost',
        de: 'Detail',
        sk: 'Detail',
        es: 'Detalle',
        ru: 'Детали',
        rs: 'Detalj',
        ua: 'Деталі',
        no: 'Detalj',
        it: 'Dettaglio',
        se: 'Detalj',
    },
    notAdded: {
        us: 'not added',
        cz: 'nepřipsáno',
        pl: 'nie dodano',
        hr: 'nije dodano',
        si: 'ni dodano',
        de: 'nicht gutgeschrieben',
        sk: 'nepripísané',
        es: 'no añadido',
        ru: 'Не добавлено',
        rs: 'nije dodato',
        ua: 'Не додано',
        no: 'ikke lagt til',
        it: 'non accreditato',
        se: 'icke-krediterat',
    },
    unpaid: {
        us: 'Unpaid.',
        cz: 'Nezaplaceno.',
        pl: 'Nie zapłacono.',
        hr: 'Neplaćeno.',
        si: 'Ni plačano.',
        de: 'Nicht bezahlt.',
        sk: 'Nezaplatené.',
        es: 'No pagado.',
        ru: 'Не оплачено.',
        rs: 'Nenaplaćeno.',
        ua: 'Не оплачено.',
        no: 'Ubetalt.',
        it: 'Non pagato.',
        se: 'Obetalt.',
    },
    errorCode: {
        us: 'error code',
        cz: 'kód chyby',
        pl: 'kod błędu',
        hr: 'pogrešan kod',
        si: 'napačen kod',
        de: 'Fehlercode',
        sk: 'kód chyby',
        es: 'código de error',
        ru: 'код ошибки',
        rs: 'kod greške',
        ua: 'код помилки',
        no: 'feilkode',
        it: 'codice di errore',
        se: 'felkod',
    },
    transcribedOverview: {
        us: 'Transcribed minutes overview',
        cz: 'Přehled přepsaných minut',
        pl: 'Przegląd stranskrybowanych minut',
        hr: 'Pregled transkribiranih minuta',
        si: 'Pregled prepisanih minut',
        de: 'Übersicht transkribierten Minuten',
        sk: 'Prehľad prepísaných minút',
        es: 'Resumen de los minutos transcritos',
        ru: 'Список транскрибированных минут',
        rs: 'Pregled transkribovanih minuta',
        ua: 'Список транскрибованих хвилин',
        no: 'Oversikt over transkriberte minutter',
        it: 'Panoramica dei minuti trascritti',
        se: 'Översikt av transkriberade minuter',
    },
    total: {
        us: 'Total: ',
        cz: 'Celkem: ',
        pl: 'Całkiem: ',
        hr: 'Ukupno: ',
        si: 'Skupno: ',
        de: 'Insgesamt: ',
        sk: 'Celkom: ',
        es: 'Total: ',
        ru: 'Всего: ',
        rs: 'Ukupno: ',
        ua: 'Усього: ',
        no: 'Til sammen: ',
        it: 'Totale: ',
        se: 'Sammanlagt: ',
    },
    month: {
        us: 'Month',
        cz: 'Měsíc',
        pl: 'Miesiąc',
        hr: 'Mjesec',
        si: 'Mesec',
        de: 'Monat',
        sk: 'Mesiac',
        es: 'Mes',
        ru: 'Месяц',
        rs: 'Mesec',
        ua: 'Місяць',
        no: 'Måned',
        it: 'Mese',
        se: 'Månad',
    },
    transcribedMins: {
        us: 'Transcribed minutes',
        cz: 'Přepsané minuty',
        pl: 'Stranskrybowane minuty',
        hr: 'Iskorištene minute',
        si: 'Prepisane minute',
        de: 'Transkribierte Minuten',
        sk: 'Prepísané minúty',
        es: 'Minutos transcritos',
        ru: 'Транскрибированные минуты',
        rs: 'Transkribovani minuti',
        ua: 'Транскрибовані хвилини',
        no: 'Minutter transkribert',
        it: 'Minuti trascritti',
        se: 'Transkriberade minuter',
    },
    addSpeaker: {
        us: 'Add speaker',
        cz: 'Přidat mluvčího',
        pl: 'Dodaj mówcę',
        hr: 'Dodaj govornika',
        si: 'Dodaj govorca',
        de: 'Sprecher hinzufügen',
        sk: 'Pridať řečníka',
        es: 'Añadir hablante',
        ru: 'Добавить спикера',
        rs: 'Dodaj govornika',
        ua: 'Додати спікера',
        no: 'Legg til en taler',
        it: 'Aggiungere uno speaker',
        se: 'Lägg till talare',
    },
    findSpeaker: {
        us: 'Find speaker',
        cz: 'Vyhledat mluvčího',
        pl: 'Wyszukaj mówcę',
        hr: 'Pronaći govornika',
        si: 'Poiščite govorca',
        de: 'Sprecher finden',
        sk: 'Vyhľadať rečníka',
        es: 'Buscar hablante',
        ru: 'Найти спикера',
        rs: 'Pronađite govornika',
        ua: 'Знайти спікера',
        no: 'Finn en taler',
        it: 'Cercare uno speaker',
        se: 'Hitta talare',
    },
    speakerSaved: {
        us: 'Speaker has been saved.',
        cz: 'Mluvčí byl uložen.',
        pl: 'Mówca został zapisany.',
        hr: 'Govornik je spremljen.',
        si: 'Govorec je shranjen.',
        de: 'Der Sprecher wurde gespeichert.',
        sk: 'Rečník bol uložený.',
        es: 'El hablante se ha guardado',
        ru: 'Спикер был сохранен.',
        rs: 'Govornik je sačuvan.',
        ua: 'Спікера було збережено.',
        no: 'Taleren ble lagret.',
        it: 'Lo speaker è stato salvato.',
        se: 'Talaren har sparats.',
    },
    failedToSaveSpeaker: {
        us: 'Failed to save speaker!',
        cz: 'Při ukládání došlo k chybě!',
        pl: 'Zapisywanie się nie powiodło!',
        hr: 'Spremanje nije uspjelo!',
        si: 'Shranjevanje ni uspelo!',
        de: 'Fehler beim Speichern!',
        sk: 'Pri ukladaní sa vyskytla chyba!',
        es: '¡No se pudo guardar el hablante!',
        ru: 'Не удалось сохранить спикера!',
        rs: 'Čuvanje govornika nije uspelo!',
        ua: 'Не вдалося зберегти спікера!',
        no: 'Klarte ikke lagre taleren!',
        it: 'Si è verificato un errore durante il salvataggio!',
        se: 'Det gick inte att spara talaren!',
    },
    speakerUpdated: {
        us: 'Speaker has been updated.',
        cz: 'Mluvčí byl změněn.',
        pl: 'Mówca został zmieniony.',
        hr: 'Govornik je ažuriran.',
        si: 'Govorec je posodobljen.',
        de: 'Der Sprecher wurde geändert.',
        sk: 'Rečník bol zmenený.',
        es: 'El hablante se ha actualizado.',
        ru: 'Спикер был изменен.',
        rs: 'Govornik je ažuriran.',
        ua: 'Спікера було змінено.',
        no: 'Taleren ble endret.',
        it: 'Lo speaker è stato cambiato.',
        se: 'Talaren har uppdaterats.',
    },
    failedToUpdate: {
        us: 'Failed to update speaker!',
        cz: 'Při ukládání mluvčího došlo k chybě!',
        pl: 'Zapisywanie się nie powiodło!',
        hr: 'Spremanje nije uspjelo!',
        si: 'Shranjevanje ni uspelo!',
        de: 'Fehler beim Speichern!',
        sk: 'Pri ukladaní sa vyskytla chyba!',
        es: '¡No se pudo actualizar el hablante!',
        ru: 'Не удалось обновить спикера!',
        rs: 'Ažuriranje govornika nije uspelo!',
        ua: 'Не вдалося поновити спікера!',
        no: 'Klarte ikke endre taleren!',
        it: 'Si è verificato un errore durante il salvataggio dello speaker!',
        se: 'Det gick inte att uppdatera talaren!',
    },
    speakerDeleted: {
        us: 'Speaker has been deleted.',
        cz: 'Mluvčí byl smazán.',
        pl: 'Mówca został usunięty.',
        hr: 'Govornik uspješno izbrisan.',
        si: 'Govorec uspešno izbrisano.',
        de: 'Sprecher erfolgreich gelöscht.',
        sk: 'Rečník bol zmazaný.',
        es: 'El hablante ha sido borrado.',
        ru: 'Спикер был удалён.',
        rs: 'Govornik je izbrisan.',
        ua: 'Спікер був вилучений.',
        no: 'Taleren ble slettet.',
        it: 'Lo speaker è stato eliminato.',
        se: 'Talaren har tagits bort.',
    },
    failedToDeleteSpeaker: {
        us: 'Failed to delete speaker.',
        cz: 'Při mazání mluvčího došlo k chybě.',
        pl: 'Usuwanie się nie powiodło.',
        hr: 'Brisanje govornika nije uspjelo.',
        si: 'Izbris uporabnika ni uspel.',
        de: 'Fehler beim Löschen.',
        sk: 'Pri mazaní sa vyskytla chyba.',
        es: 'No se pudo borrar el hablante.',
        ru: 'Не удалось удалить спикера.',
        rs: 'Brisanje govornika nije uspelo.',
        ua: 'Не вдалося вилучити спікера.',
        no: 'Klarte ikke slette taleren.',
        it: 'Si è verificato un errore durante la cancellazione dello speaker.',
        se: 'Det gick inte att ta bort talaren.',
    },
    roleSaved: {
        us: 'Role has been saved.',
        cz: 'Role byla uložena.',
        pl: 'Rola została zapisana.',
        hr: 'Uloga je spremljena.',
        si: 'Vloga je shranjena',
        de: 'Rolle wurde gespeichert.',
        sk: 'Rola bola uložená.',
        es: 'Se ha guardado el rol.',
        ru: 'Роль сохранена.',
        rs: 'Uloga je sačuvana.',
        ua: 'Роль збережено.',
        no: 'Rollen ble lagret.',
        it: 'Il ruolo è stato salvato.',
        se: 'Rollen har sparats.',
    },
    roleDeleted: {
        us: 'Role has been deleted.',
        cz: 'Role byla smazána.',
        pl: 'Rola została usunięta.',
        hr: 'Uloga je izbrisana.',
        si: 'Vloga je izbrisana.',
        de: 'Rolle wurde gelöscht.',
        sk: 'Rola bola vymazaná.',
        es: 'Se ha eliminado el rol.',
        ru: 'Роль удалена.',
        rs: 'Uloga je izbrisana.',
        ua: 'Роль видалено.',
        no: 'Rollen ble slettet.',
        it: 'Il ruolo è stato cancellato.',
        se: 'Rollen har tagits bort.',
    },
    roleUpdated: {
        us: 'Role has been updated.',
        cz: 'Role byla změněna.',
        pl: 'Rola została zmieniona.',
        hr: 'Uloga je ažurirana.',
        si: 'Vloga je posodobljena.',
        de: 'Rolle wurde aktualisiert.',
        sk: 'Rola bola zmenená.',
        es: 'Se ha actualizado el rol.',
        ru: 'Роль обновлена.',
        rs: 'Uloga je ažurirana.',
        ua: 'Роль оновлено.',
        no: 'Rollen ble oppdatert.',
        it: 'Il ruolo è stato aggiornato.',
        se: 'Rollen har uppdaterats.',
    },
    failedToDeleteRole: {
        us: 'Failed to delete role!',
        cz: 'Při mazání role došlo k chybě!',
        pl: 'Przy usuwaniu roli wystąpił błąd!',
        hr: 'Brisanje uloge nije uspjelo!',
        si: 'Brisanje vloge neuspešno!',
        de: 'Rolle konnte nicht gelöscht werden!',
        sk: 'Pri odstraňovaní role došlo k chybe!',
        es: '¡Error al eliminar el rol!',
        ru: 'Не удалось удалить роль!',
        rs: 'Neuspešno brisanje uloge!',
        ua: 'Не вдалося видалити роль!',
        no: 'Klarte ikke å slette rollen!',
        it: 'Si è verificato un errore durante la cancellazione del ruolo!',
        se: 'Det gick inte att ta bort rollen!',
    },
    failedToSaveRole: {
        us: 'Failed to save role!',
        cz: 'Při ukládání došlo k chybě!',
        pl: 'Przy zapisywaniu wystąpił błąd!',
        hr: 'Spremanje uloge nije uspjelo!',
        si: 'Shranjevanje vloge ni uspelo!',
        de: 'Speichern der Rolle fehlgeschlagen!',
        sk: 'Pri ukladaní úlohy došlo k chybe!',
        es: '¡Error al guardar el rol!',
        ru: 'Не удалось сохранить роль!',
        rs: 'Neuspešno čuvanje uloge!',
        ua: 'Не вдалося зберегти роль!',
        no: 'Klarte ikke å lagre rollen!',
        it: 'Si è verificato un errore durante il salvataggio del ruolo!',
        se: 'Det gick inte att spara rollen!',
    },
    failedToUpdateRole: {
        us: 'Failed to update role!',
        cz: 'Při ukládání role došlo k chybě!',
        pl: 'Przy zapisywaniu roli wystąpił błąd!',
        hr: 'Ažuriranje uloge nije uspjelo!',
        si: 'Posodobitev vloge ni uspela!',
        de: 'Aktualisierung der Rolle fehlgeschlagen!',
        sk: 'Pri ukladaní role došlo k chybe!',
        es: '¡Error al actualizar el rol!',
        ru: 'Не удалось обновить роль!',
        rs: 'Neuspešno ažuriranje uloge!',
        ua: 'Не вдалося оновити роль!',
        no: 'Klarte ikke å oppdatere rollen!',
        it: "Si è verificato un errore durante l'aggiornamento del ruolo!",
        se: 'Det gick inte att uppdatera rollen!',
    },
    deleteSpeaker: {
        us: 'Delete speaker?',
        cz: 'Smazat mluvčího?',
        pl: 'Usunąć mówcę?',
        hr: 'Želite li izbrisati govornika?',
        si: 'Ali želite izbrisati govorca?',
        de: 'Sprecher löschen?',
        sk: 'Zmazať rečníka?',
        es: '¿Borrar el hablante?',
        ru: 'Удалить спикера?',
        rs: 'Izbrisati govornika?',
        ua: 'Видалити спікера?',
        no: 'Slette taleren?',
        it: 'Eliminare lo speaker?',
        se: 'Ta bort talaren?',
    },
    yesDelete: {
        us: 'Yes, delete',
        cz: 'Ano, smazat',
        pl: 'Tak, usunąć',
        hr: 'Da, izbriši',
        si: 'Ja, izbriši',
        de: 'Ja, löschen',
        sk: 'Áno, zmazať',
        es: 'Sí, borrar',
        ru: 'Да, удалить',
        rs: 'Da, obrisati',
        ua: 'Так, видалити',
        no: 'Ja, slette',
        it: 'Sì, eliminare',
        se: 'Ja, ta bort',
    },
    addWord: {
        us: 'Add rule',
        cz: 'Přidat pravidlo',
        pl: 'Dodaj regułę',
        hr: 'Dodaj pravilo',
        si: 'Dodaj pravilo',
        de: 'Regel hinzufügen',
        sk: 'Pridať pravidlo',
        es: 'Agregar regla',
        ru: 'Добавить правило',
        rs: 'Dodaj pravilo',
        ua: 'Додати правило',
        no: 'Legge til en regel',
        it: 'agiungere una regola',
        se: 'Lägg till regel',
    },
    correctTranscription: {
        us: 'Replace by:',
        cz: 'Nahradit za:',
        pl: 'Zastąp:',
        hr: 'Zamijeni s:',
        si: 'Zamenjaj z:',
        de: 'Ersetzen durch:',
        sk: 'Nahradiť za:',
        es: 'Reemplazar por:',
        ru: 'Заменить на:',
        rs: 'Zameni sa:',
        ua: 'Замінити на:',
        no: 'Erstatte av:',
        it: 'Sostituire con:',
        se: 'Ersätta med:',
    },
    incorrectTranscription: {
        us: 'Incorrect transcription',
        cz: 'Nesprávný přepis',
        pl: 'Błędna transkrypcja',
        hr: 'Netočna transkripcija',
        si: 'Nepravilna transkripcija',
        de: 'Falsche Transkription',
        sk: 'Nesprávny prepis',
        es: 'Transcripción incorrecta',
        ru: 'Неправильная транскрипция',
        rs: 'Neispravna transkripcija',
        ua: 'Неправильна транскрипція',
        no: 'Feil transkripsjon',
        it: 'Trascrizione errata',
        se: 'Fel transkription',
    },
    userlexHelpBody: {
        us: 'Insert the wrong spelling of a word/phrase from the transcription. As of now, this transcription will be automatically corrected to the spelling specified in "correct transcription". You can add multiple variants of wrong transcription for one word/phrase, each in a separate row.',
        cz: 'Vložte nesprávný přepis slova/fráze. Při přepisu se tento zápis automaticky nahradí za zápis zadaný jako "správný přepis". Lze přidat více variant oprav pro jedno slovo/frázi, každou na samostatný řádek.',
        pl: 'Wprowadź „błędną transkrypcję“ frazy/słowa. Podczas transkrypcji zostanie ona zastąpiona "poprawną transkrypcją". Możesz dodać więcej „błędnych transkrypcji“ dla jednego słowa/zwrotu, każdą w oddzielnym wierszu.',
        hr: 'Umetnite pogrešano napisanu riječ/frazu iz transkripcije. Od sada, ova transkripcija će biti automatski ispravljena na riječ/frazu navedenu u "pravilnoj transkripciji". Možete dodati više varijanti pogrešne transkripcije za jednu riječ/frazu, svaku u zasebnom retku.',
        si: 'Vstavite napačno črkovanje besede/fraze iz transkripcije. Od zdaj naprej bo ta transkripcija samodejno popravljena na črkovanje, določeno v "pravilni transkripciji". Dodate lahko več različic napačnega prepisa za eno besedo/frazo, vsako v ločeni vrstici.',
        de: 'Geben Sie die falsche Schreibweise eines Wortes/Satzes aus der Transkription ein. Ab sofort wird diese Transkription automatisch auf die in "richtige Transkription" angegebene Schreibweise korrigiert. Sie können mehrere Varianten der falschen Transkription für ein Wort/einen Satz hinzufügen, jede in eine separate Zeile.',
        sk: 'Vložte nesprávny prepis slova/frázy. Pri prepise sa tento zápis automaticky nahradí zápisom zadaným ako "správny prepis". Pre jedno slovo/výraz môžete pridať viacero variantov opravy, každý na samostatnom riadku.',
        es: 'Inserte la ortografía incorrecta de una palabra/frase de la transcripción. A partir de ahora, esta transcripción se corregirá automáticamente a la ortografía especificada en "transcripción correcta". Puede agregar múltiples variantes de transcripción incorrecta para una palabra/frase, cada una en una fila separada.',
        ru: 'Вставьте неправильное написание слова/фразы из транскрипции. В настоящее время такая транскрипция будет автоматически исправляться на написание, указанное в "правильной транскрипции". Вы можете добавить несколько вариантов неправильной транскрипции для одного слова/фразы, каждый в отдельной строке.',
        rs: 'Umetnite pogrešano napisanu reč/frazu iz transkripcije. Od sada, ova transkripcija će biti automatski ispravljena na reč/frazu navedenu u "pravilnoj transkripciji". Možete dodati više varijanti pogrešne transkripcije za jednu reč/frazu, svaku u zasebnom redu.',
        ua: 'Вставте неправильне написання слова/фрази з транскрипції. Відтепер ця транскрипція буде автоматично виправлена на написання, вказане у полі "правильна транскрипція". Ви можете додати кілька варіантів неправильної транскрипції для одного слова/фрази, кожен в окремому рядку.',
        no: 'Skriv inn en feilaktig transkripsjon av et ord/en frase. Disse blir automatisk erstattet med en "korrekt transkripsjon" du oppgir. Det er mulig å skrive inn flere rettelser for et ord/en frase, hver på sin egen rad.',
        it: 'Inserire la trascrizione errata della parola/frase. Durante la trascrizione, questa voce viene sostituita automaticamente con quella inserita come "trascrizione corretta". È possibile aggiungere più opzioni di correzione per una singola parola/frase, ciascuna su una riga separata.',
        se: 'Ange en felaktig stavning av ett ord/en fras från transkriptionen. Från och med nu, transkriptionen kommer att automatiskt ersättas med den stavning som angivits under "rätt transkription". För ett ord/en fras kan du lägga till flera varianter av felaktig transkription. Skriv varje variant på en separat rad.',
    },
    addNewUserWord: {
        us: 'Add user word',
        cz: 'Přidat uživatelské slovo',
        pl: 'Dodaj słowo',
        hr: 'Dodaj korisničku riječ',
        si: 'Dodaj uporabniško besedo',
        de: 'Benutzerwort hinzufügen',
        sk: 'Pridať používateľské slovo',
        es: 'Añadir palabra de usuario',
        ru: 'Добавить слово пользователя',
        rs: 'Dodaj korisničku reč',
        ua: 'Додати слово користувача',
        no: 'Legg til et brukerord',
        it: 'Aggiunga parola utente',
        se: 'Lägg till användarord',
    },
    editUserWord: {
        us: 'Edit user word',
        cz: 'Upravit uživatelské slovo',
        pl: 'Edytuj słowo',
        hr: 'Uredi korisničku riječ',
        si: 'Uredi uporabniško besedo',
        de: 'Benutzerwort bearbeiten',
        sk: 'Upraviť používateľské slovo',
        es: 'Editar palabra de usuario',
        ru: 'Редактировать слово пользователя',
        rs: 'Uredi korisničku reč',
        ua: 'Редагувати слово користувача',
        no: 'Rediger et brukerord',
        it: 'Modifichi la parola utente',
        se: 'Redigera användarord',
    },
    missingCorrectTrancription: {
        us: 'The correct transcription must have at least 1 character.',
        cz: 'Správný přepis musí mít alespoň 1 znak.',
        pl: 'Poprawna transkrypcja musi zawierać przynajmniej jeden znak.',
        hr: 'Ispravna transkripcija mora imati najmanje 1 znak.',
        si: 'Pravilen prepis mora imeti vsaj 1 znak.',
        de: 'Die korrekte Transkription muss mindestens 1 Zeichen enthalten.',
        sk: 'Správny prepis musí mať aspoň 1 znak.',
        es: 'La transcripción correcta debe tener al menos 1 carácter.',
        ru: 'Правильная транскрипция должна содержать не менее 1 символа.',
        rs: 'Ispravna transkripcija mora imati barem jedan karakter.',
        ua: 'Правильна транскрипція повинна містити щонайменше 1 символ.',
        no: 'Den korrekte transkripsjonen må ha minst 1 tegn.',
        it: 'La trascrizione corretta deve avere almeno 1 carattere.',
        se: 'En rätt transkription ska innehålla minst ett tecken.',
    },
    missingIncorrectTrancription: {
        us: 'The incorrect transcription must have at least 5 characters.',
        cz: 'Nesprávný přepis musí mít alespoň 5 znaků.',
        pl: 'Błędna transkrypcja musi zawierać przynajmniej pięć znaków.',
        hr: 'Netočna transkripcija mora imati najmanje 5 znakova.',
        si: 'Napačen prepis mora imeti vsaj 5 znakov.',
        de: 'Die falsche Transkription muss mindestens 5 Zeichen enthalten.',
        sk: 'Nesprávny prepis musí mať aspoň 5 znakov.',
        es: 'La transcripción incorrecta debe tener al menos 5 caracteres.',
        ru: 'Неправильная транскрипция должна содержать не менее 5 символов.',
        rs: 'Neispravna transkripcija mora imati barem pet karaktera.',
        ua: 'Неправильна транскрипція повинна містити щонайменше 5 символів.',
        no: 'Den feil transkripsjonen må ha minst 5 tegn.',
        it: 'La trascrizione errata deve essere lunga almeno 5 caratteri.',
        se: 'En felaktig transkription ska innehålla minst 5 tecken.',
    },
    duplicitIncorrectTranscription: {
        us: 'This incorrect transcription is already in the list of rules.',
        cz: 'Tento nesprávný přepis už je v pravidlech.',
        pl: 'Ta błędna transkrypcja znajduje się już na liście reguł.',
        hr: 'Ova je netočna transkripcija već na popisu pravila.',
        si: 'Ta napačen prepis je že na seznamu pravil.',
        de: 'Diese falsche Transkription ist bereits in der Regeln enthalten.',
        sk: 'Tento nesprávny prepis už je v pravidlách.',
        es: 'Esta transcripción incorrecta ya está en la lista de reglas.',
        ru: 'Эта неправильная транскрипция уже есть в списке правил.',
        rs: 'Ova netačna transkripcija je već na listi pravila.',
        ua: 'Ця неправильна транскрипція вже є у списку правил.',
        no: 'Denne ukorrekte transkripsjonen finnes allerede i reglene.',
        it: 'Questa trascrizione errata è già presente nelle regole.',
        se: 'Denna felaktiga transkription finns redan bland reglerna.',
    },
    validChars: {
        us: 'Valid characters: ',
        cz: 'Povolené znaky: ',
        pl: 'Dozwolone znaki: ',
        hr: 'Podržani znakovi: ',
        si: 'Veljavni znaki: ',
        de: 'Gültige Zeichen: ',
        sk: 'Povolené znaky: ',
        es: 'Caracteres válidos: ',
        ru: 'Разрешённые символы: ',
        rs: 'Važeći karakteri: ',
        ua: 'Дозволені символи: ',
        no: 'Gyldige tegn: ',
        it: 'Caratteri ammessi: ',
        se: 'Giltiga tecken: ',
    },
    invalidChar: {
        us: 'Invalid character',
        cz: 'Zakázaný znak',
        pl: 'Zakazany znak',
        hr: 'Nepodržan znak',
        si: 'Neveljavni znaki',
        de: 'Ungültiges Zeichen',
        sk: 'Zakázaný znak',
        es: 'Carácter no válido',
        ru: 'Запрещённые символы',
        rs: 'Nevažeći karakter',
        ua: 'Заборонені символи',
        no: 'Ugyldige tegn',
        it: 'Carattere proibito',
        se: 'Ogiltigt tecken',
    },
    specialChar: {
        us: 'Special character',
        cz: 'Speciální znak',
        pl: 'Specjalny znak',
        hr: 'Poseban znak',
        si: 'Poseben znak',
        de: 'Sonderzeichen',
        sk: 'Špeciálny znak',
        es: 'Carácter especial',
        ru: 'Специальные символы',
        rs: 'Specijalni karakter',
        ua: 'Спеціальні символи',
        no: 'Spesielle tegn',
        it: 'Carattere speciale',
        se: 'Specialtecken',
    },
    help: {
        us: 'Help',
        cz: 'Nápověda',
        pl: 'Pomoc',
        hr: 'Pomoć',
        si: 'Pomoč',
        de: 'Hilfe',
        sk: 'Pomoc',
        es: 'Ayuda',
        ru: 'Помощь',
        rs: 'Pomoć',
        ua: 'Допомога',
        no: 'Hjelp',
        it: 'Aiuto',
        se: 'Hjälp',
    },
    wordSaved: {
        us: 'Word has been saved',
        cz: 'Slovo bylo uloženo',
        pl: 'Słowo zostało zapisane',
        hr: 'Riječ je spremljena',
        si: 'Beseda je shranjena',
        de: 'Wort wurde gespeichert',
        sk: 'Slovo bolo uložené',
        es: 'Se ha guardado la palabra',
        ru: 'Слово было сохранено',
        rs: 'Reč je sačuvana',
        ua: 'Слово було збережено',
        no: 'Ordet ble lagret',
        it: 'La parola è stata salvata',
        se: 'Ordet har sparats',
    },
    wordDeleted: {
        us: 'Word has been deleted',
        cz: 'Slovo bylo vymazáno',
        pl: 'Słowo zostało usunięte',
        hr: 'Riječ je obrisana',
        si: 'Beseda je izbrisana',
        de: 'Wort wurde gelöscht',
        sk: 'Slovo bolo vymazané',
        es: 'La palabra ha sido borrada',
        ru: 'Слово было удалено',
        rs: 'Reč je izbrisana',
        ua: 'Слово було видалено',
        no: 'Ordet ble slettet',
        it: 'La parola è stata salvata',
        se: 'Ordet har tagits bort',
    },
    text: {
        us: 'Written form',
        cz: 'Psaná forma',
        pl: 'Forma pisemna',
        hr: 'Pisani oblik',
        si: 'Pisna oblika',
        de: 'Geschriebene Form',
        sk: 'Písaná forma',
        es: 'Forma escrita',
        ru: 'Письменная форма',
        rs: 'Pismena forma',
        ua: 'Письмова форма',
        no: 'Skriftlig form',
        it: 'Forma scritta',
        se: 'Skriftlig form',
    },
    deleteWord: {
        us: 'Delete word?',
        cz: 'Smazat slovo?',
        pl: 'Usunąć słowo?',
        hr: 'Izbriši riječ?',
        si: 'Izbriši besedo?',
        de: 'Wort löschen?',
        sk: 'Zmazať slovo?',
        es: '¿Borrar palabra?',
        ru: 'Удалить слово?',
        rs: 'Izbrisati reč?',
        ua: 'Видалити слово?',
        no: 'Slette ordet?',
        it: 'Cancellare la parola?',
        se: 'Ta bort ordet?',
    },
    administration: {
        us: 'Administration',
        cz: 'Administrace',
        pl: 'Administracja',
        hr: 'Administracija',
        si: 'Administracija',
        de: 'Administration',
        sk: 'Administrácia',
        es: 'Administración',
        ru: 'Администрация',
        rs: 'Administracija',
        ua: 'Адміністрація',
        no: 'Administrasjon',
        it: 'Amministrazione',
        se: 'Administration',
    },
    newUser: {
        us: 'Add user',
        cz: 'Přidat uživatele',
        pl: 'Dodaj użytkownika',
        hr: 'Dodaj korisnika',
        si: 'Dodaj uporabnika',
        de: 'Benutzer hinzufügen',
        sk: 'Pridať používateľa',
        es: 'Añadir usuario',
        ru: 'Добавить пользователя',
        rs: 'Dodaj korisnika',
        ua: 'Додати користувача',
        no: 'Legg til en bruker',
        it: 'Aggiungi utente',
        se: 'Lägg till användare',
    },
    alreadyRegistered: {
        us: 'This e-mail address is already registered. Please enter a different e-mail address.',
        cz: 'Tento e-mail je již registrovaný. Zadejte prosím jiný e-mail.',
        pl: 'Wprowadzony e-mail został już zarejestrowany. Wprowadź inny e-mail.',
        hr: 'Ova e-mail adresa je već registrirana. Molimo unesite drugu e-mail adresu.',
        si: 'Ta e-mail naslov je že registriran. Prosimo, vnesite drugi e-mail naslov.',
        de: 'Diese E-mail Adresse ist schon registriert. Bitte geben Sie eine andere Adresse ein.',
        sk: 'Tento e-mail je už registrovaný. Prosím zadajte iný e-mail.',
        es: 'Esta direccion de e-mail ya está registrada. Introduzca una dirección de e-mail diferente.',
        ru: 'Данный e-mail уже зарегистрирован. Пожалуйста, введите другой e-mail.',
        rs: 'Ova e-mail adresa je već registrovana. Molimo unesite drugu e-mail adresu.',
        ua: 'Цей e-mail вже зареєстрований. Будь ласка, введіть інший e-mail.',
        no: 'Denne e-postadressen er allerede registrert. Vennligst oppgi en annen adresse.',
        it: "Questa e-mail è già registrata. Inserisca un'altra e-mail.",
        se: 'Denna e-postadress är redan registrerad. Vänligen ange en annan e-postadress.',
    },
    newUserCreated: {
        us: 'New user successfully created.',
        cz: 'Nový uživatel úspěšně vytvořen.',
        pl: 'Konto nowego użytkownika zostało pomyślnie utworzone.',
        hr: 'Novi korisnik uspješno je kreiran.',
        si: 'Novi uporabnik je uspešno ustvarjen.',
        de: 'Neuer Benutzer war erfolgreich erstellt.',
        sk: 'Nový používateľ úspešne vytvorený.',
        es: 'Nuevo usuario creado con éxito.',
        ru: 'Новый пользователь был успешно создан.',
        rs: 'Novi korisnik je uspešno kreiran.',
        ua: 'Новий користувач був успішно створений.',
        no: 'En ny bruker er opprettet.',
        it: 'Nuovo utente creato con successo.',
        se: 'Ny användare har skapats.',
    },
    minCharsPsw: {
        us: 'The minimum number of characters for password is ',
        cz: 'Minimální počet znaků pro heslo je ',
        pl: 'Minimalna liczba znaków hasła to ',
        hr: 'Minimalni broj znakova za lozinku je ',
        si: 'Minimalno število znakov za geslo je ',
        de: 'Mindestanzahl von Zeichen für Passwort ist ',
        sk: 'Minimálny počet znakov pre heslo je ',
        es: 'El número mínimo de caracteres para la contraseña es ',
        ru: 'Минимальное количество символов для пароля ',
        rs: 'Minimalni broj karaktera za šifru je ',
        ua: 'Мінімальна кількість символів для пароля ',
        no: 'Minimum antall tegn som kreves for passordet er ',
        it: 'Il numero minimo di caratteri per una password è ',
        se: 'Minsta antal tecken som krävs för ett lösenord är ',
    },
    missingCredit: {
        us: 'Missing credit. Fill in the amount of credit.',
        cz: 'Chybějící kredit. Doplňte množství kreditu.',
        pl: 'Brak kredytu. Wpisz kwotę kredytu.',
        hr: 'Nedostaje kredit. Ispunite iznos kredita.',
        si: 'Manjka kredit. Izpolnite znesek kredita.',
        de: 'Fehlender Kredit. Geben Sie Kreditanzahl ein.',
        sk: 'Chýbajúci kredit. Vyplňte sumu kreditov.',
        es: 'Falta crédito. Recargue la cantidad necesaria de crédito.',
        ru: 'Недостаточный баланс. Пополните баланс.',
        rs: 'Nedostaje kredita. Popunite iznos kredita.',
        ua: 'Недостатній баланс. Поповніть баланс.',
        no: 'Kreditt mangler. Fyll ut mengden av kreditter.',
        it: "Credito mancante. Inserisca l'importo del credito.",
        se: 'Kredit saknas. Ladda upp mängden kredit som krävs.',
    },
    failedToCreate: {
        us: 'Failed to create new user!',
        cz: 'Při vytváření uživatele došlo k chybě!',
        pl: 'Utworzenie się nie powiodło!',
        hr: 'Kreiranje novog korisnika nije uspjelo!',
        si: 'Ustvarjanje novega uporabnika ni uspelo!',
        de: 'Fehler beim Erstellen neues Benutzers!',
        sk: 'Pri vytváraní používateľa nastala chyba!',
        es: '¡No se pudo crear nuevo usuario!',
        ru: 'Не удалось создать нового пользователя!',
        rs: 'Kreiranje novog korisnika nije uspelo!',
        ua: 'Неможливо створити нового користувача!',
        no: 'Klarte ikke å opprette en ny bruker!',
        it: "Si è verificato un errore durante la creazione dell'utente!",
        se: 'Det gick inte att skapa en ny användare!',
    },
    creditMinutes: {
        us: 'Credit (minutes)',
        cz: 'Kredit (minuty)',
        pl: 'Kredyt (minuty)',
        hr: 'Kredit (minute)',
        si: 'Kredit (minute)',
        de: 'Kredit (Minuten)',
        sk: 'Kredit (minúty)',
        es: 'Crédito (minutos)',
        ru: 'Баланс (минуты)',
        rs: 'Kredit (minuta)',
        ua: 'Баланс (хвилини)',
        no: 'Kreditt (minutter)',
        it: 'Credito (minuti)',
        se: 'Kredit (minuter)',
    },
    pleasePW: {
        us: "Please specify user's password",
        cz: 'Zadejte prosím heslo nového uživatele',
        pl: 'Wprowadź hasło nowego użytkownika',
        hr: 'Molimo unesite korisničku lozinku',
        si: 'Prosimo, vnesite uporabniško geslo',
        de: 'Bitte Passwort des neuen Benutzers eingeben',
        sk: 'Zadajte heslo nového používateľa',
        es: 'Por favor especifique la contraseña del usuario.',
        ru: 'Введите, пожалуйста, пароль для нового пользователя',
        rs: 'Molimo navedite šifru korisnika',
        ua: 'Введіть, будь ласка, пароль для нового користувача',
        no: 'Vennligst oppgi brukerens passord',
        it: 'Inserisca, per favore, la password del nuovo utente',
        se: 'Vänligen ange användarens lösenord',
    },
    enterCredit: {
        us: 'Enter number of minutes. Minimum is 0 and maximum',
        cz: 'Zadejte počet minut. Minimum je 0 a maximum',
        pl: 'Wprowadź liczbę minut. Minimalna wartość to 0, maksymalna',
        hr: 'Unesite broj minuta. Minimalno je 0, a maksimalno',
        si: 'Vnesite število minut. Minimalno je 0, maksimalno pa je',
        de: 'Geben Sie die Anzahl der Minuten ein. Minimum ist 0, Maximum ist',
        sk: 'Zadajte počet minút. Minimum je 0 a maximum',
        es: 'Ingrese el número de minutos. El mínimo es 0 y el máximo',
        ru: 'Введите количество минут. Минимально 0 и максимально',
        rs: 'Unesite broj minuta. Minimum je 0, a maksimum je',
        ua: 'Введіть кількість хвилин. Мінімально 0 та максимально',
        no: 'Oppgi mengden av kreditter. Minst 0, og maksimalt',
        it: 'Inserire il numero di minuti. Il minimo è 0 e il massimo è',
        se: 'Ange antal minuter. Minimum är 0, maximum är',
    },
    infoEmail: {
        us: 'Send sign-up e-mail',
        cz: 'Zaslat registrační e-mail',
        pl: 'Wyślij e-mail rejestracyjny',
        hr: 'Pošaljite e-mail za prijavu',
        si: 'Pošljite e-pošto za prijavo',
        de: 'Registrierungsmail senden',
        sk: 'Odoslať registračný e-mail',
        es: 'Enviar e-mail de registro',
        ru: 'Отправить e-mail регистрации',
        rs: 'Pošaljite e-mail za registraciju',
        ua: 'Надіслати e-mail реєстрації',
        no: 'Sende en e-post for registrering',
        it: "Inviare l'e-mail di registrazione",
        se: 'Skicka registreringsmail',
    },
    chargedMinutes: {
        us: 'Transcribed',
        cz: 'Přepsáno',
        pl: 'Stranskrybowano',
        hr: 'Transkribirano',
        si: 'Prepisano',
        de: 'Transkribiert',
        sk: 'Prepísané',
        es: 'Transcribido',
        ru: 'Транскрибировано',
        rs: 'Transkribovano',
        ua: 'Транскрибовано',
        no: 'Transkribert',
        it: 'Trascritto',
        se: 'Transkriberat',
    },
    searchEmail: {
        us: 'Search user',
        cz: 'Vyhledat uživatele',
        pl: 'Wyszukaj użytkownika',
        hr: 'Pretraži korisnika',
        si: 'Poišči uporabnika',
        de: 'Benutzer suchen',
        sk: 'Vyhľadať užívateľa',
        es: 'Buscar usuario',
        ru: 'Искать пользователя',
        rs: 'Traži korisnika',
        ua: 'Шукати користувача',
        no: 'Søke bruker',
        it: "Trovare l'utente",
        se: 'Sök användare',
    },
    usersLanguage: {
        us: 'Language',
        cz: 'Jazyk',
        pl: 'Język',
        hr: 'Jezik',
        si: 'Jezik',
        de: 'Sprache',
        sk: 'Jazyk',
        es: 'Idioma',
        ru: 'Язык',
        rs: 'Jezik',
        ua: 'Мова',
        no: 'Språk',
        it: 'Lingua',
        se: 'Språk',
    },
    noSuchUser: {
        us: 'No such user',
        cz: 'Uživatel nenalezen',
        pl: 'Użytkownik nieodnaleziony',
        hr: 'Nepostojeći korisnik',
        si: 'Neobstoječi uporabnik',
        de: 'Benutzer nicht gefunden',
        sk: 'Používateľ nenájdený',
        es: 'Usuario no existe',
        ru: 'Пользователя не существует',
        rs: 'Ne postoji korisnik',
        ua: 'Користувача не існує',
        no: 'Bruker ikke funnet',
        it: 'Utente non trovato',
        se: 'Användaren kunde inte hittas',
    },
    userDoesNotExist: {
        us: 'User with this ID does not exist.',
        cz: 'Uživatel s tímto ID neexistuje.',
        pl: 'Użytkownik z tym ID nie istnieje.',
        hr: 'Korisnik s ovim ID ne postoji.',
        si: 'Ne obstaja uporabnik, ki ima ta ID.',
        de: 'Benutzer mit dieser ID existiert nicht.',
        sk: 'Používateľ s týmto ID neexistuje.',
        es: 'El usuario con este ID no existe.',
        ru: 'Пользователь с таким ID не существует.',
        rs: 'Korisnik sa ovim ID-jem ne postoji.',
        ua: 'Користувач із таким ID не існує.',
        no: 'Bruker med dette ID finnes ikke.',
        it: "Non c'è nessun utente con questo ID.",
        se: 'Det finns ingen användare med detta ID.',
    },
    invalidCreditFormat: {
        us: 'Invalid credit format. Credit can be set to 0 or whole minutes.',
        cz: 'Neplatný formát kreditu. Kredit může být nastaven na 0 nebo na celé minuty.',
        pl: 'Nieprawidłowy format kredytu. Kredyt można ustawić na 0 lub całe minuty.',
        hr: 'Nevažeći format kredita. Kredit se može postaviti na 0 ili cijele minute.',
        si: 'Neveljavna oblika kredita. Kredit se lahko nastavi na 0 ali cele minute.',
        de: 'Ungültiges Kreditformat. Kredit kann auf 0 oder ganze Minuten eingestellt werden.',
        sk: 'Neplatný formát kreditu. Kredit je možné nastaviť na 0 alebo na celé minúty.',
        es: 'Formato de crédito no válido. El crédito se puede establecer en 0 o minutos completos.',
        ru: 'Некорректный формат баланса. Баланс может быть выставлен на 0 или целые минуты.',
        rs: 'Nevažeći format kredita. Kredit se može podesiti na 0 ili cele minute.',
        ua: 'Неправильний формат балансу. Баланс може бути виставлений на 0 або цілі хвилини.',
        no: 'Ugyldig kredittformat. Kreditt kan være stilt på 0 eller et helt tall.',
        it: 'Formato del credito non valido. Il credito può essere impostato su 0 o su minuti interi.',
        se: 'Ogilitigt kreditformat. Kredit kan vara inställd på 0 eller heltal.',
    },
    unknownError: {
        us: 'Try again or contact our support at support@beey.io',
        cz: 'Zkuste to znovu, nebo kontaktujte zákaznickou podporu na support@beey.io',
        pl: 'Powtórz akcję lub skontaktuj się ze wsparciem technicznym na adresie: support@beey.io',
        hr: 'Pokušajte ponovno ili kontaktirajte s našom podrškom na support@beey.io',
        si: 'Poskusite znova ali se obrnite na našo podporo na support@beey.io',
        de: 'Versuchen Sie es noch einmal, oder wenden Sie sich an unseren Support: support@beey.io',
        sk: 'Skúste to znovu, alebo kontaktujte zákaznícku podporu na support@beey.io',
        es: 'Inténtelo de nuevo o póngase en contacto con nuestro soporte en support@beey.io',
        ru: 'Попробуйте снова или свяжитесь с нашей службой поддержки support@beey.io',
        rs: 'Pokušajte ponovo ili kontaktirajte našu podršku: support@beey.io',
        ua: "Спробуйте знову або зв'яжіться з нашою службою підтримки: support@beey.io",
        no: 'Vennligst prøv igjen eller ta kontakt med brukerstøtten på support@beey.io',
        it: "Riprovi o contatti l'assistenza clienti all'indirizzo support@beey.io",
        se: 'Prova igen eller kontakta vår kundservice på support@beey.io',
    },
    missingDays: {
        us: 'Please set the number of days after which projects should be deleted.',
        cz: 'Nastavte prosím počet dnů, po kterém se mají projekty smazat.',
        pl: 'Ustaw liczbę dni, po których projekty mają zostać usunięte.',
        hr: 'Odredite broj dana nakon kojih bi projekti trebali biti izbrisani.',
        si: 'Nastavite število dni, po katerih je treba projekte izbrisati.',
        de: 'Bitte Anzahl der Tage angeben, nach der sollen Projekte gelöscht werden.',
        sk: 'Nastavte prosím počet dní, po ktorom sa majú projekty vymazať.',
        es: 'Establezca el número de días transcurridos en los cuales deben eliminarse los proyectos.',
        ru: 'Задайте количество дней, по истечении которых проекты должны быть удалены.',
        rs: 'Molimo vas da podesite broj dana nakon čega bi projekat trebalo da bude izbrisan.',
        ua: 'Будь ласка, встановіть кількість днів, через яку проекти будуть видалені.',
        no: 'Vennligst tast inn antall dager hvoretter skal prosjektene bli slettet.',
        it: 'Imposti il numero di giorni dopo i quali i progetti devono essere cancellati.',
        se: 'Vänligen ange antal dagar varefter projekten ska raderas.',
    },
    roleWarningHead: {
        us: 'You are about to lose admin privileges!',
        cz: 'Přijdete o administrátorská práva!',
        pl: 'Stracisz prawa administratora!',
        hr: 'Izgubit ćete administratorska prava!',
        si: 'Izgubili boste administratorske pravice!',
        de: 'Sie verlieren Adminrechte!',
        sk: 'Prídete o administrátorské práva!',
        es: '¡Está a punto de perder los privilegios de administrador!',
        ru: 'Вы можете лишиться привилегий администратора!',
        rs: 'Uskoro ćete izgubiti administratorske privilegije!',
        ua: 'Ви можете втратити привілеї адміністратора!',
        no: 'Du skal miste administratorrettigheter!',
        it: 'Sta perdendo i diritti di amministratore!',
        se: 'Du kommer att förlora administratörsrättigheter!',
    },
    roleWarningBody: {
        us: 'You will not be able to access this page after logging in again. Consider changing your role back to Admin or Developer.',
        cz: 'Po opětovném přihlášení se na tuto stránku již nedostanete. Zvažte změnu své role zpět na Admin nebo Developer.',
        pl: 'Po ponownym zalogowaniu się, ta strona już się nie otworzy. Rozważ zmianę swojej roli z powrotem na Admin lub Developer.',
        hr: 'Nećete moći pristupiti ovoj stranici nakon ponovne prijave. Razmislite o promjeni uloge natrag na ulogu Admin ili Developer.',
        si: 'Ne boste imeli dostopa do te strani po ponovni prijavi. Razmislite o spremembi vloge nazaj na vlogo Admin ali Developer.',
        de: 'Nach nächster Anmeldung können Sie nicht auf diese Seite zurückkommen. Überlegen Sie, Ihre Benutzerrolle wieder zu Admin oder Developer zu ändern.',
        sk: 'Po opätovnom prihlásení sa na túto stránku už nedostanete. Zvážte zmenu svojej role späť na Admin alebo Developer.',
        es: 'No podrá acceder a esta página después de iniciar sesión nuevamente. Considere volver a cambiar su rol a Admin o Developer.',
        ru: 'Данная страница будет недоступна после повторного входа. Вернитесь к вашей позиции Администратора или Разработчика.',
        rs: 'Nećete moći da pristupite ovoj stranici nakon ponovnog prijavljivanja. Razmislite da svoju ulogu vratite na Admin ili Developer.',
        ua: 'Ця сторінка буде недоступна після повторного входу. Поверніться до позиції Адміністратора або Розробника.',
        no: 'Etter at du logger deg på igjen, mister du tilgang til denne sida. Vurder å endre rollen din tilbake til Admin eller Developer.',
        it: 'Non sarà più possibile accedere a questa pagina dopo aver effettuato il login. Consideri la possibilità di cambiare il suo ruolo in Admin o Developer.',
        se: 'När du har loggat in igen, förlorar du åtkomsten till denna sida. Överväg att byta roll tillbaka till Admin eller Developer.',
    },
    groupWarningBody: {
        us: 'By setting a group for your own account, you would lose your super admin priviledges. If you really want to do it, ask another admin to set it for your account.',
        cz: 'Nastavením skupiny byste přišli o svoje oprávnění superadministrátora. Pokud si to opravdu přejete, požádejte jiného administrátora, ať to nastaví pro váš účet.',
        pl: 'Ustawienie grupy dla własnego konta spowoduje utratę uprawnień superadministratora. Jeśli naprawdę chcesz to zrobić, poproś innego administratora o ustawienie grupy dla twojego konta.',
        hr: 'Postavljanjem grupe za vlastiti račun izgubili biste svoje privilegije super administratora. Ako to zaista želite učiniti, zamolite drugog administratora da to postavi za vaš račun.',
        si: 'Če nastavite skupino za svoj račun, boste izgubili svoje super skrbniške privilegije. Če to res želite, prosite drugega skrbnika, da to nastavi za vaš račun.',
        de: 'Wenn Sie eine Gruppe für Ihr eigenes Konto einrichten, verlieren Sie Ihre Super-Admin-Rechte. Wenn Sie es wirklich tun wollen, bitten Sie einen anderen Administrator, es für Ihr Konto einzurichten.',
        sk: 'Nastavením skupiny by ste prišli o svoje oprávnenie superadministrátora. Pokiaľ si to naozaj prajete, požiadajte iného administrátora, nech to nastaví pre váš účet.',
        es: 'Al establecer un grupo para su propia cuenta, perderá sus privilegios de superadministrador. Si realmente desea hacerlo, pídale a otro administrador que lo haga por su cuenta.',
        ru: 'Создав группу для своей учетной записи, вы потеряете свои привилегии супер-администратора. Если вы действительно хотите это сделать, попросите другого администратора создать группу для вашей учетной записи.',
        rs: 'Podešavanjem grupe za sopstveni nalog, izgubili biste svoje privilegije super administratora. Ako zaista želite to da uradite, zamolite drugog administratora da to podesi za vaš nalog.',
        ua: 'Якщо ви створите групу для свого акаунта, ви втратите права супер-адміністратора. Якщо ви дійсно хочете це зробити, попросіть іншого адміністратора встановити групу для вашого акаунта.',
        no: 'Hvis du oppretter en gruppe for din egen konto, mister du superadministratorrettighetene dine. Hvis du virkelig ønsker å gjøre det, kan du be en annen administrator om å angi det for kontoen din.',
        it: 'Creando un gruppo, si perderebbero i privilegi di superamministratore. Se lo si desidera veramente, chiedere a un altro amministratore di crearlo per il proprio account.',
        se: 'Om du ställer in en grupp för ditt konto förlorar du superadministörens rättigheter. Om du verkligen önskar detta, be en annan administratör ställa in det för ditt konto.',
    },
    roleSuccess: {
        us: 'Role successfully set to: ',
        cz: 'Role úspešně nastavena na: ',
        pl: 'Rola została pomyślnie zmieniona na: ',
        hr: 'Uloga uspješno postavljena na: ',
        si: 'Vloga uspešno postavljena na: ',
        de: 'Benutzerrolle erfolgreich eingestellt zu: ',
        sk: 'Rola úspešne nastavená na: ',
        es: 'Rol establecido correctamente en: ',
        ru: 'Статус успешно изменен на: ',
        rs: 'Uloga je uspešno podešena na: ',
        ua: 'Статус успішно змінено на: ',
        no: 'Rollen stilt på: ',
        it: 'Ruolo impostato con successo su: ',
        se: 'Rollen är nu inställd på: ',
    },
    invalidRole: {
        us: 'Invalid role. Try again or contact our support at support@beey.io',
        cz: 'Neplatná role. Zkuste to znovu, nebo kontaktujte zákaznickou podporu na support@beey.io',
        pl: 'Nieprawidłowa rola. Spróbuj ponownie lub skontaktuj się z naszym wsparciem technicznym na adresie: support@beey.io',
        hr: 'Nevažeća uloga. Pokušajte ponovno ili kontaktirajte s našom podrškom na support@beey.io',
        si: 'Neveljavna vloga. Poskusite ponovno ali se obrnite na našo službo za podporo uporabnikom na support@beey.io',
        de: 'Ungültige Rolle. Versuchen Sie es erneut oder kontaktieren Sie unseren Support: support@beey.io',
        sk: 'Neplatná rola. Skúste to znova, alebo kontaktujte zákaznícku podporu na support@beey.io',
        es: 'Rol no válido. Inténtelo de nuevo o póngase en contacto con nuestro soporte en support@beey.io',
        ru: 'Недействительный статус. Попробуйте еще раз или свяжитесь со службой поддержки: support@beey.io',
        rs: 'Nevažeća uloga. Pokušajte ponovo ili kontaktirajte našu podršku: support@beey.io',
        ua: "Недійсний статус. Спробуйте ще раз або зв'яжіться зі службою підтримки: support@beey.io",
        no: 'Ugyldig rolle. Vennligst prøv igjen eller ta kontakt med brukerstøtten på support@beey.io',
        it: "Ruolo non valido. Riprovi, oppure contatti l'assistenza clienti all'indirizzo support@beey.io",
        se: 'Ogiltig roll. Prova igen eller kontakta vår kundservice på support@beey.io',
    },
    creditSuccess: {
        us: 'Credit successfully changed',
        cz: 'Kredit byl úspěšně změněn',
        pl: 'Kredyt został pomyślnie zmieniony',
        hr: 'Kredit uspješno promijenjen',
        si: 'Kredit uspešno spremenjen',
        de: 'Kredit erfolgreich verändert',
        sk: 'Kredit bol úspešne zmenený',
        es: 'Crédito cambiado correctamente',
        ru: 'Балан успешно изменён',
        rs: 'Kredit je uspešno promenjen',
        ua: 'Балан успішно змінено',
        no: 'Kreditt endret',
        it: 'Il credito è stato modificato con successo',
        se: 'Kredit har ändrats',
    },
    modifyCredit: {
        us: 'Add/remove credit by',
        cz: 'Navýšit/snížit kredit o',
        pl: 'Zmniejsz/zwiększ kredyt o',
        hr: 'Dodajte/uklonite kredit do',
        si: 'Dodajte/odstranite kredit do',
        de: 'Hinzufügen/Entfernen von Kredit um',
        sk: 'Zvýšiť/znížiť kredit o',
        es: 'Añadir/eliminar crédito por',
        ru: 'Добавить/удалить кредит',
        rs: 'Dodaj/ukloni kredit za',
        ua: 'Додати/видалити кредит',
        no: 'Legge til/fjerne kreditt på',
        it: 'Aumentare/diminuire il credito di',
        se: 'Öka/minska kredit med',
    },
    enableDeletion: {
        us: 'Automatic deletion has to be first enabled to save it.',
        cz: 'Pro uložení je nutné nejprve povolit automatické mazání.',
        pl: 'Aby zapisać, należy najpierw włączyć automatyczne usuwanie.',
        hr: 'Da biste ga spremili, automatsko brisanje mora biti omogućeno.',
        si: 'Če ga želite shraniti, morate najprej omogočiti samodejno brisanje.',
        de: 'Zu speichern muss automatische Löschung zuerst aktiviert werden.',
        sk: 'Pre uloženie je nutné najprv povoliť automatické mazanie.',
        es: 'Para guardarlo, primero hay que activar el borrado automático.',
        ru: 'Для сохранения необходимо предварительно включить автоматическое удаление.',
        rs: 'Automatsko brisanje mora prvo biti omogućeno kako bi bilo sačuvano.',
        ua: 'Щоб зберегти проект, спочатку потрібно увімкнути автоматичне видалення.',
        no: 'Automatisk sletting må aktiveres først for å kunne lagres.',
        it: "Per salvare, è necessario prima abilitare l'eliminazione automatica.",
        se: 'Vänligen tillåt automatisk radering innan du sparar ändringarna.',
    },
    teamDeletionDeadline: {
        us: 'Team time limit in days',
        cz: 'Lhůta týmu ve dnech',
        pl: 'Limit przechowywania danych teamu w dniach',
        hr: 'Vremensko ograničenje tima u danima',
        si: 'Časovna omejitev ekipe v dneh',
        de: 'Team-Zeitlimit in Tagen',
        sk: 'Lehota tímu v dňoch',
        es: 'Tiempo límite del equipo en días',
        ru: 'Лимит времени команды в днях',
        rs: 'Vremensko ograničenje za tim u danima',
        ua: 'Ліміт часу команди в днях',
        no: 'Team-tidsbegrensing i dager',
        it: 'Scadenza del team in giorni',
        se: 'Teamets tidsfrist i dagar',
    },
    userDeletionDeadline: {
        us: 'User time limit in days',
        cz: 'Lhůta uživatele ve dnech',
        pl: 'Limit przechowywania danych użytkownika w dniach',
        hr: 'Korisničko vremensko ograničenje u danima',
        si: 'Časovna omejitev uporabnika v dneh',
        de: 'Benutzer-Zeitlimit in Tagen',
        sk: 'Lehota užívateľa v dňoch',
        es: 'Limite de usuario en días',
        ru: 'Ограничение времени работы пользователя в днях',
        rs: 'Vremensko ograničenje za korisnika u danima',
        ua: 'Ліміт часу користувача в днях',
        no: 'Bruker-tidsbegrensing i dager',
        it: 'Termine ultimo per gli utenti in giorni',
        se: 'Användarens tidsfrist i dagar',
    },
    storage: {
        us: 'Storage usage',
        cz: 'Využití úložiště',
        pl: 'Wielkość przechowywanych danych',
        hr: 'Korištenje pohrane',
        si: 'Uporaba prostora za shranjevanje',
        de: 'Speicherverbrauch',
        sk: 'Využitie úložiska',
        es: 'Uso de almacenamiento',
        ru: 'Использование хранилища',
        rs: 'Korišćenje skladištenja',
        ua: 'Використання сховища',
        no: 'Forbruk av lagring',
        it: 'Utilizzo dello stoccaggio',
        se: 'Lagringsanvändning',
    },
    successAutomaticalDeletion: {
        us: 'Deletion of inactive projects has been successfully set up',
        cz: 'Mazání neaktivních projektů bylo úspešně nastaveno',
        pl: 'Usuwanie nieaktywnych projektów zostało pomyślnie skonfigurowane',
        hr: 'Brisanje neaktivnih projekata uspješno je postavljeno',
        si: 'Izbris neaktivnih projektov je uspešno nastavljen',
        de: 'Löschung inaktiver Projekte wurde erfolgreich eingerichtet',
        sk: 'Mazanie neaktívnych projektov bolo úspešne nastavené',
        es: 'La eliminación de proyectos inactivos se ha configurado correctamente',
        ru: 'Удаление неактивных проектов успешно настроено',
        rs: 'Brisanje neaktivnih projekata je uspešno podešeno',
        ua: 'Видалення неактивних проектів успішно налаштовано',
        no: 'Sletting av inaktive prosjekter er riktig satt opp',
        it: "L'eliminazione dei progetti inattivi è stata impostata con successo",
        se: 'Radering av inaktiva projekt har ställts in',
    },
    cancelAutomaticalDeletion: {
        us: 'Deletion of inactive projects has been successfully canceled',
        cz: 'Mazání neaktivních projektů bylo úspešně zrušeno',
        pl: 'Usuwanie nieaktywnych projektów zostało pomyślnie anulowane',
        hr: 'Brisanje neaktivnih projekata uspješno je otkazano',
        si: 'Izbris neaktivnih projektov je uspešno preklican',
        de: 'Löschung inaktiver Projekte wurde erfolgreich abgebrochen',
        sk: 'Mazanie neaktívnych projektov bolo úspešne zrušené',
        es: 'La eliminación de proyectos inactivos se ha cancelado',
        ru: 'Удаление неактивных проектов успешно отменено',
        rs: 'Brisanje neaktivnih projekata je uspešno otkazano',
        ua: 'Видалення неактивних проектів успішно скасовано',
        no: 'Sletting av inaktive prosjekter har blitt avsluttet riktig',
        it: "L'eliminazione dei progetti inattivi è stata annullata con successo",
        se: 'Radering av inaktiva projekt har återkallats',
    },
    resetAutomaticalDeletionFail: {
        us: 'Resetting automatical deletion failed. Try it again.',
        cz: 'Mazání neaktivních projektů selhalo. Zkuste to znovu.',
        pl: 'Resetowanie automatycznego usuwania nie powiodło się. Spróbuj ponownie.',
        hr: 'Resetiranje automatskog brisanja nije uspjelo. Pokušajte ponovno.',
        si: 'Ponastavitev samodejnega brisanja ni uspela. Poskusite znova.',
        de: 'Zurücksetzen der automatischen Löschung ist fehlgeschlagen. Versuchen Sie es erneut.',
        sk: 'Vymazanie neaktívnych projektov sa nepodarilo. Skúste to znova.',
        es: 'El borrado automático ha fallado. Inténtelo de nuevo.',
        ru: 'Сброс автоматического удаления не удался. Попробуйте еще раз.',
        rs: 'Resetovanje automatskog brisanja nije uspelo. Probajte ponovo.',
        ua: 'Не вдалося скинути автоматичне видалення. Спробуйте ще раз.',
        no: 'Tilbakestilling av den automatiske slettingen mislyktes. Vennligst prøv igjen.',
        it: "L'eliminazione dei progetti inattivi non è riuscita. Riprovi.",
        se: 'Det gick inte att återställa raderingen av inaktiva projekt. Vänligen prova om.',
    },
    settingAutomaticalDeletionFail: {
        us: 'Setting automatical deletion failed. Try it again.',
        cz: 'Nastavení mazání neaktivních projektů selhalo. Zkuste to znovu.',
        pl: 'Ustawienie usuwania nieaktywnych projektów nie powiodło się. Spróbuj ponownie.',
        hr: 'Automatsko brisanje nije uspjelo. Pokušajte ponovno.',
        si: 'Nastavitev samodejnega brisanja ni uspela. Poskusite znova.',
        de: 'Einstellung der automatischen Löschung ist fehlgeschlagen. Versuchen Sie es erneut.',
        sk: 'Nastavenie mazania neaktívnych projektov zlyhalo. Skúste to znova.',
        es: 'La configuración de borrado automático ha fallado. Inténtelo de nuevo.',
        ru: 'Настройка автоматического удаления не удалась. Попробуйте еще раз.',
        rs: 'Podešavanje automatskog brisanja nije uspelo. Probajte ponovo.',
        ua: 'Не вдалося налаштувати автоматичне видалення. Спробуйте ще раз.',
        no: 'Klarte ikke stille inn den automatiske slettingen. Vennligst prøv igjen.',
        it: "L'impostazione Elimina progetti inattivi non è riuscita. Riprovi.",
        se: 'Det gick inte att ställa in raderingen av inaktiva projekt. Vänligen prova om.',
    },
    deleteUserPermanently: {
        us: 'Permanently delete user',
        cz: 'Permanentně vymazat uživatele',
        pl: 'Trwale usuń użytkownika',
        hr: 'Izbriši korisnika',
        si: 'Izbriši uporabnika',
        de: 'Benutzer permanent löschen',
        sk: 'Permanentne vymazať používateľa',
        es: 'Eliminar usuario de forma permanente',
        ru: 'Удалить пользователя навсегда',
        rs: 'Trajno obrišite korisnika',
        ua: 'Видалити користувача назавжди',
        no: 'Slette bruker permanent',
        it: "Eliminare definitivamente l'utente",
        se: 'Radera användaren för alltid',
    },
    successChange: {
        us: 'Password changed successfully.',
        cz: 'Heslo bylo úspešně změněno.',
        pl: 'Hasło zostało pomyślnie zmienione.',
        hr: 'Lozinka je uspješno promijenjena.',
        si: 'Geslo je uspešno spremenjeno.',
        de: 'Passwort erfolgreich verändert.',
        sk: 'Heslo bolo úspešne zmenené.',
        es: 'La contraseña se cambió con éxito.',
        ru: 'Пароль был успешно изменен.',
        rs: 'Vaša šifra je uspešno promenjena.',
        ua: 'Пароль успішно змінено.',
        no: 'Passordet ble endret.',
        it: 'La password è stata modificata con successo.',
        se: 'Lösenord ändrades.',
    },
    changePassword: {
        us: 'Change password',
        cz: 'Změnit heslo',
        pl: 'Zmień hasło',
        hr: 'Promijeni lozinku',
        si: 'Spremeni geslo',
        de: 'Passwort ändern',
        sk: 'Zmeniť heslo',
        es: 'Cambiar contraseña',
        ru: 'Изменить пароль',
        rs: 'Promenite šifru',
        ua: 'Змінити пароль',
        no: 'Endre passord',
        it: 'Cambiare la password',
        se: 'Ändra lösenord',
    },
    savingFailed: {
        us: 'Password reset failed.',
        cz: 'Při změně hesla došlo k chybě.',
        pl: 'Resetowanie hasła nie powiodło się.',
        hr: 'Promjena lozinke nije uspjela.',
        si: 'Ponastavitev gesla ni uspela.',
        de: 'Zurücksetzen des Passworts ist fehlgeschlagen.',
        sk: 'Pri zmene hesla došlo k chybe.',
        es: '¡No se pudo restablecer la contraseña.',
        ru: 'При смене пароля возникла ошибка.',
        rs: 'Poništavanje šifre nije uspelo.',
        ua: 'При зміні пароля виникла помилка.',
        no: 'Tilbakestilling av passordet mislyktes.',
        it: 'Si è verificato un errore durante la modifica della password.',
        se: 'Det gick inte att återställa lösenordet.',
    },
    generatePW: {
        us: 'Generate password',
        cz: 'Generovat heslo',
        pl: 'Wygeneruj hasło',
        hr: 'Kreiraj lozinku',
        si: 'Ustvari geslo',
        de: 'Passwort generieren',
        sk: 'Generovať heslo',
        es: 'Generar contraseña',
        ru: 'Генерировать пароль',
        rs: 'Generiši lozinku',
        ua: 'Генерувати пароль',
        no: 'Generer passord',
        it: 'Generare la password',
        se: 'Generera lösenord',
    },
    unlimitedTranscription: {
        us: 'Unlimited transcription:',
        cz: 'Neomezený přepis:',
        pl: 'Nieograniczona transkrypcja:',
        hr: 'Neograničena transkripcija:',
        si: 'Neomejeno prepisovanje:',
        de: 'Unbegrenzte Transkription:',
        sk: 'Neobmedzený prepis:',
        es: 'Transcripción ilimitada:',
        ru: 'Неограниченное транскрибирование:',
        rs: 'Neograničena transkripcija:',
        ua: 'Необмежене транскрибування:',
        no: 'Ubegrenset transkripsjon:',
        it: 'Trascrizioni illimitate:',
        se: 'Obegränsad transkription:',
    },
    subtitleMode: {
        us: 'Subtitle mode',
        cz: 'Titulkový režim',
        pl: 'Tryb napisów',
        hr: 'Način rada titlova',
        si: 'Način dela podnapisov',
        de: 'Untertitel-Modus',
        sk: 'Režim titulkov',
        es: 'Modo de subtítulos',
        ru: 'Режим субтитров',
        rs: 'Način rada titlova',
        ua: 'Режим субтитрів',
        no: 'Undertekstmodus',
        it: 'Modalità sottotitoli',
        se: 'Undertextläge',
    },
    sharingToAll: {
        us: 'Sharing to all:',
        cz: 'Sdílení se všemi:',
        pl: 'Udostępnianie wszystkim:',
        hr: 'Dijeljenje sa svima:',
        si: 'Deljenje z vsemi:',
        de: 'Teilen mit allen:',
        sk: 'Zdieľanie so všetkými:',
        es: 'Compartir con todos:',
        ru: 'Поделиться со всеми:',
        rs: 'Deljenje sa svima:',
        ua: 'Поділитися з усіма:',
        no: 'Deler med alle:',
        it: 'Condividere con tutti:',
        se: 'Delar med alla:',
    },
    shareAlert: {
        us: 'Sharing grants the user access to the project forever.',
        cz: 'Sdílením udělíte uživateli navždy přístup k projektu.',
        pl: 'Po udostępnieniu użytkownik na zawsze zyska dostęp do projektu.',
        hr: 'Dijeljenje korisniku pruža pristup projektu zauvijek.',
        si: 'Skupna raba uporabniku za vedno odobri dostop do projekta.',
        de: 'Durch Teilen erhält der Benutzer Zugriff zu dem Projekt für immer.',
        sk: 'Zdieľanie poskytne používateľovi prístup k projektu navždy.',
        es: 'Compartir le da al usuario acceso al proyecto para siempre.',
        ru: 'Общий доступ предоставляет пользователю доступ к проекту навсегда.',
        rs: 'Deljenje daje korisniku pristup projektu zauvek.',
        ua: 'Спільний доступ надає користувачеві доступ до проекту назавжди.',
        no: 'Deling gir brukeren tilgang til prosjektet for alltid.',
        it: "Condividendo, si concede all'utente l'accesso al progetto per sempre.",
        se: 'Genom att dela projektet får användaren åtkomst till det för alltid.',
    },
    accountActive: {
        us: 'Account active:',
        cz: 'Účet aktivní:',
        pl: 'Konto aktywne:',
        hr: 'Račun aktivan:',
        si: 'Račun aktiven:',
        de: 'Konto aktiv:',
        sk: 'Účet aktívny:',
        es: 'Cuenta activa:',
        ru: 'Активные профили:',
        rs: 'Nalog aktivan:',
        ua: 'Активні профілі:',
        no: 'Kontoen aktiv:',
        it: 'Conto attivo:',
        se: 'Kontot aktivt:',
    },
    appsAllMissing: {
        us: 'To edit app claims for this user, enable claim "all apps" for your own account.',
        cz: 'Abyste mohli editovat claimy tohoto uživatele, povolte claim "všechny aplikace" pro svůj účet.',
        pl: 'Jeśli chcesz edytować claimy tego użytkownika, aktywuj claim "wszystkie aplikacje" na swoim koncie.',
        hr: 'Za uređivanje zahtjeva aplikacije za ovog korisnika omogućite zahtjev "sve aplikacije" za svoj račun.',
        si: 'Če želite urediti zahtevke aplikacije za tega uporabnika, omogočite zahtevek »vse aplikacije« za svoj račun.',
        de: 'Um App-Ansprüche für diesen Benutzer zu bearbeiten, aktivieren Sie den Anspruch "Alle Apps" für Ihr eigenes Konto.',
        sk: 'Ak chcete upraviť claimy tohto používateľa, povoľte claim "všetky aplikácie" pre svoje konto.',
        es: 'Para editar reclamos de aplicaciones para este usuario, permite el reclamo "todas las aplicaciones" para su propia cuenta.',
        ru: 'Чтобы редактировать утверждения приложений для этого пользователя, включите утверждение "все приложения" для своей учетной записи.',
        rs: 'Za editovanje zahteva za aplikaciju za ovog korisnika, omogućite zahtevanje "svih aplikacija" za sopstveni nalog.',
        ua: 'Щоб редагувати вимоги до додатків для цього користувача, увімкніть вимогу "всі додатки" для свого облікового запису.',
        no: 'For å kunne redigere claimer av denne brukeren, tillat claim "alle apper" for kontoen din.',
        it: 'Per modificare le dichiarazioni di questo utente, abiliti la dichiarazione "tutte le app" per il proprio account.',
        se: 'För att editera denna användares krav, tillåt kravet "alla applikationer" för ditt konto.',
    },
    allApps: {
        us: 'All apps:',
        cz: 'Všechny aplikace:',
        pl: 'Wszystkie aplikacje:',
        hr: 'Sve aplikacije:',
        si: 'Vse aplikacije:',
        de: 'Alle Apps:',
        sk: 'Všetky aplikácie:',
        es: 'Todas las aplicaciones:',
        ru: 'Все приложения:',
        rs: 'Sve aplikacije:',
        ua: 'Всі програми:',
        no: 'Alle applikasjoner:',
        it: 'Tutte le applicazioni:',
        se: 'Alla appar:',
    },
    allSubtitles: {
        us: 'All subtitles formats:',
        cz: 'Všechny formáty titulků:',
        pl: 'Wszystkie formaty napisów:',
        hr: 'Svi formati titlova:',
        si: 'Vsi formati podnapisov:',
        de: 'Alle Untertitelformate:',
        sk: 'Všetky formáty titulkov:',
        es: 'Todos los formatos de subtítulos:',
        ru: 'Все форматы субтитров:',
        rs: 'Svi formati titlova:',
        ua: 'Усі формати субтитрів:',
        no: 'Alle undertekst-formater:',
        it: 'Tutti i formati dei sottotitoli:',
        se: 'Alla undertextformat:',
    },
    srtSubtitles: {
        us: 'Subtitles in .srt:',
        cz: 'Titulky v .srt:',
        pl: 'Napisy w formacie .srt:',
        hr: 'Titlovi u .srt:',
        si: 'Podnapisi v .srt:',
        de: 'Untertitel in .srt:',
        sk: 'Titulky v .srt:',
        es: 'Subtítulos en .srt:',
        ru: 'Субтитры в .srt:',
        rs: 'Titlovi u .srt:',
        ua: 'Субтитри в .srt:',
        no: 'Undertekst i .srt:',
        it: 'Sottotitoli in .srt:',
        se: 'Undertexter i .srt:',
    },
    vttSubtitles: {
        us: 'Subtitles in .vtt:',
        cz: 'Titulky v .vtt:',
        pl: 'Napisy w formacie .vtt:',
        hr: 'Titlovi u .vtt:',
        si: 'Podnapisi v .vtt:',
        de: 'Untertitel in .vtt:',
        sk: 'Titulky v .vtt:',
        es: 'Subtítulos en .vtt:',
        ru: 'Субтитры в .vtt:',
        rs: 'Titlovi u .vtt:',
        ua: 'Субтитри в .vtt:',
        no: 'Undertekst i .vtt:',
        it: 'Sottotitoli in .vtt:',
        se: 'Undertexter i .vtt:',
    },
    ssaSubtitles: {
        us: 'Subtitles in .ssa:',
        cz: 'Titulky v .ssa:',
        pl: 'Napisy w formacie .ssa:',
        hr: 'Titlovi u .ssa:',
        si: 'Podnapisi v .ssa:',
        de: 'Untertitel in .ssa:',
        sk: 'Titulky v .ssa:',
        es: 'Subtítulos en .ssa:',
        ru: 'Субтитры в .ssa:',
        rs: 'Titlovi u .ssa:',
        ua: 'Субтитри в .ssa:',
        no: 'Undertekst i .ssa:',
        it: 'Sottotitoli in .ssa:',
        se: 'Undertexter i .ssa:',
    },
    stlSubtitles: {
        us: 'Subtitles in .stl:',
        cz: 'Titulky v .stl:',
        pl: 'Napisy w formacie .stl:',
        hr: 'Titlovi u .stl:',
        si: 'Podnapisi v .stl:',
        de: 'Untertitel in .stl:',
        sk: 'Titulky v .stl:',
        es: 'Subtítulos en .stl:',
        ru: 'Субтитры в .stl:',
        rs: 'Titlovi u .stl:',
        ua: 'Субтитри в .stl:',
        no: 'Undertekst i .stl:',
        it: 'Sottotitoli in .stl:',
        se: 'Undertexter i .stl:',
    },
    ttmlSubtitles: {
        us: 'Subtitles in .ttml:',
        cz: 'Titulky v .ttml:',
        pl: 'Napisy w formacie .ttml:',
        hr: 'Titlovi u .ttml:',
        si: 'Podnapisi v .ttml:',
        de: 'Untertitel in .ttml:',
        sk: 'Titulky v .ttml:',
        es: 'Subtítulos en .ttml:',
        ru: 'Субтитры в .ttml:',
        rs: 'Titlovi u .ttml:',
        ua: 'Субтитри в .ttml:',
        no: 'Undertekst i .ttml:',
        it: 'Sottotitoli in .ttml:',
        se: 'Undertexter i .ttml:',
    },
    failedToChangeClaim: {
        us: 'Failed to change claim',
        cz: 'Nepodařilo se změnit claim uživatele',
        pl: 'Nie udało się zmienić claimu użytkownika',
        hr: 'Promjena zahtjeva nije uspjela',
        si: 'Sprememba zahtevka ni uspela',
        de: 'Benutzeranspruch kann nicht geändert werden',
        sk: 'Nepodarilo sa zmeniť claim používateľa',
        es: 'No se pudo cambiar el reclamo',
        ru: 'Не удалось изменить требование клиента',
        rs: 'Promena zahteva nije uspela',
        ua: 'Не вдалося змінити вимогу клієнта',
        no: 'Brukerclaim endring mislyktes',
        it: "Impossibile modificare la richiesta dell'utente",
        se: 'Det gick inte att ändra användarens anspråk',
    },
    claimEnabled: {
        us: 'Enabled',
        cz: 'Povoleno',
        pl: 'Włączone',
        hr: 'Omogućeno',
        si: 'Omogočeno',
        de: 'Aktiviert',
        sk: 'Povolené',
        es: 'Activado',
        ru: 'Активировано',
        rs: 'Omogućeno',
        ua: 'Активовано',
        no: 'Slått på',
        it: 'Abilitato',
        se: 'Aktiverat',
    },
    claimDefault: {
        us: 'Default',
        cz: 'Defaultní',
        pl: 'Domyślne',
        hr: 'Zadano',
        si: 'Privzeto',
        de: 'Standard',
        sk: 'Defaultné',
        es: 'Predeterminado',
        ru: 'По умолчанию',
        rs: 'Zadato',
        ua: 'За замовчуванням',
        no: 'Default',
        it: 'Predefinito',
        se: 'Default',
    },
    claimDisabled: {
        us: 'Disabled',
        cz: 'Blokováno',
        pl: 'Wyłączone',
        hr: 'Onemogućeno',
        si: 'Onemogočeno',
        de: 'Deaktiviert',
        sk: 'Blokované',
        es: 'Desactivado',
        ru: 'Заблокировано',
        rs: 'Onemogućeno',
        ua: 'Заблоковано',
        no: 'Slått av',
        it: 'Bloccato',
        se: 'Inaktiverat',
    },
    user: {
        us: 'User ',
        cz: 'Uživatel ',
        pl: 'Użytkownik ',
        hr: 'Korisnik ',
        si: 'Uporabnik ',
        de: 'Benutzer ',
        sk: 'Používateľ ',
        es: 'Usuario ',
        ru: 'Пользователь ',
        rs: 'Korisnik ',
        ua: 'Користувач ',
        no: 'Brukeren ',
        it: 'Utente ',
        se: 'Användare ',
    },
    succesfullyDeleted: {
        us: ' has been deleted.',
        cz: ' byl smazán.',
        pl: ' został usunięty.',
        hr: ' uspješno izbrisan.',
        si: ' uspešno izbrisano.',
        de: ' erfolgreich gelöscht.',
        sk: ' bol zmazaný.',
        es: ' ha sido borrado.',
        ru: ' был удалён.',
        rs: ' je izbrisan.',
        ua: ' був видалений.',
        no: ' ble slettet.',
        it: ' è stato eliminato.',
        se: ' har raderats.',
    },
    processIrreversible: {
        us: 'This process is permanent and irreversible! Together with the user, all their projects will be deleted as well. ',
        cz: 'Tento proces je konečný a nevratný! Spolu s uživatelem budou smazány i všechny jeho projekty. ',
        pl: 'Ten proces jest definitywny i nieodwracalny! Wraz z użytkownikiem zostaną usunięte wszystkie jego projekty. ',
        hr: 'Ovaj postupak je trajan i nepovratan! Zajedno s korisnikom bit će izbrisani i svi projekti. ',
        si: 'Ta postopek je trajen in nepovraten! Skupaj z uporabnikom bodo izbrisani tudi vsi projekti. ',
        de: 'Der Prozess ist permanent und irreversibel! Alle Projekte des Benutzers werden auch gelöscht. ',
        sk: 'Tento proces je konečný a nezvratný! Spolu s používateľom budú zmazané aj všetky jeho projekty. ',
        es: '¡Este proceso es permanente e irreversible! Junto con el usuario, también se eliminarán todos sus proyectos. ',
        ru: 'Это действие окончательное и необратимое! Вместе с пользователем будут удалены все его проекты. ',
        rs: 'Ovaj proces je trajan i nepovratan! Zajedno sa korisnikom, svi njegovi projekti će takođe biti izbrisani. ',
        ua: 'Це дія остаточна і необоротна! Разом з користувачем буде видалено всі його проекти. ',
        no: 'Denne prosessen er permanent og irreversibel! Sletter du brukeren, sletter du alle brukerens prosjekter også. ',
        it: "Questo processo è definitivo e irreversibile! Tutti i progetti verranno eliminati insieme all'utente. ",
        se: 'Denna process är permanent och oåterkallelig! Om du raderar användaren, raderar du också användarens samtliga projekt. ',
    },
    criticalEditorError: {
        us: 'Critical editor error. If the problem persists, please report it at https://www.beey.io/en/beey-bug-report',
        cz: 'Kritická chyba editoru. Pokud problém přetrvává, prosím popište, jak k chybě došlo, na https://www.beey.io/cs/beey-bug-report',
        pl: 'Krytyczny błąd edytora. Jeśli problem będzie się powtarzał, opisz, w jaki sposób wystąpił błąd na https://www.beey.io/pl/beey-bug-report/',
        hr: 'Kritična pogreška editora. Ako se problem nastavi pojavljivati, prijavite kako se to dogodilo na https://www.beey.io/en/beey-bug-report',
        si: 'Kritična napaka urejevalnika. Če se težava ponovi, sporočite, kako se je zgodilo na https://www.beey.io/en/beey-bug-report',
        de: 'Kritischer Editor-Fehler. Wenn das Problem weiterhin besteht, berichten Sie bitte unter https://www.beey.io/en/beey-bug-report, wie es passiert ist.',
        sk: 'Kritická chyba editora. Ak problém pretrváva, popíšte, ako k chybe došlo, na adrese https://www.beey.io/cs/beey-bug-report',
        es: 'Error crítico del editor. Si el problema persiste, informe cómo sucedió en https://www.beey.io/en/beey-bug-report',
        ru: 'Критическая ошибка редактора. Если ошибка повторяется, пожалуйста сообщите об этом перейдя по ссылке https://www.beey.io/en/beey-bug-report',
        rs: 'Kritična pogreška editora. Ako se problem nastavi pojavljivati, prijavite kako se to dogodilo na https://www.beey.io/en/beey-bug-report',
        ua: 'Критична помилка редактора. Якщо помилка повторюється, будь ласка, повідомте про це, перейшовши за посиланням https://www.beey.io/en/beey-bug-report',
        no: 'Kritisk editor feil. Hvis problemet vedvarer, vennligst send feilrapporten på https://www.beey.io/en/beey-bug-report',
        it: "Se il problema persiste, invvi una descrizione del modo in cui si è verificato l'errore a https://www.beey.io/en/beey-bug-report",
        se: 'Det har uppstått ett kritiskt fel i editorn. Om problemet kvarstår, vänligen felrapportera på https://www.beey.io/en/beey-bug-report',
    },
    pleaseTypeEmail: {
        us: "Please type in or copy the user's e-mail address ",
        cz: 'Prosím opište nebo zkopírujte e-mailovou adresu uživatele ',
        pl: 'Wprowadź adres e-mail użytkownika ',
        hr: 'Molimo unesite ili kopirajte e-mail adresu korisnika ',
        si: 'Prosimo, vnesite ali kopirajte uporabniški e-mail naslov ',
        de: 'Bitte eingeben oder kopieren Email Adresse des Benutzers ',
        sk: 'Prosím opíšte alebo skopírujte e-mailovú adresu používateľa ',
        es: 'Por favor, escriba o copie la dirección de e-mail del usuario ',
        ru: 'Введите, пожалуйста, либо скопируйте e-mail пользователя ',
        rs: 'Molimo unesite ili kopirajte e-mail adresu korisnika ',
        ua: 'Введіть будь-ласка або скопіюйте e-mail користувача ',
        no: 'Vennligst skriv inn eller kopier inn brukerens e-postadresse ',
        it: "Scriva o copi, per favore, l'indirizzo e-mail dell'utente ",
        se: 'Vänligen skriv in eller kopiera användarens e-postadress ',
    },
    verifyAction: {
        us: ' in the input box below to complete this action.',
        cz: ' do okénka níže pro dokončení této akce.',
        pl: ' do poniższego okna, aby dokończyć akcję.',
        hr: ' u polju niže za unos kako biste dovršili radnju.',
        si: ' spodaj v polju za vnos, da bi dokončali dejanje.',
        de: ' ins Eingabefeld unten, um diese Aktion fertigstellen.',
        sk: ' do okienka nižšie pre dokončenie tejto akcie.',
        es: ' en el cuadro de entrada a continuación para completar esta acción.',
        ru: ' В поле внизу для завершения этого действия.',
        rs: ' u dole naznačenom polju kako biste dovršili ovu radnju.',
        ua: ' У полі внизу для завершення цієї дії.',
        no: ' til boksen nede for å fullføre denne handlingen.',
        it: ' nella casella sottostante per completare questa azione.',
        se: ' i inmatningsfältet nedan för att slutföra denna handling.',
    },
    newAnnouncement: {
        us: 'Add announcement',
        cz: 'Přidat oznámení',
        pl: 'Dodaj powiadomienie',
        hr: 'Dodaj obavijest',
        si: 'Dodaj obvestilo',
        de: 'Ankündigung hinzufügen',
        sk: 'Pridať oznámenie',
        es: 'Añadir anuncio',
        ru: 'Добавить объявление',
        rs: 'Dodaj najavu',
        ua: 'Додати оголошення',
        no: 'Legg til en ny melding',
        it: 'Aggiungere una  notifica',
        se: 'Lägg till ett meddelande',
    },
    announcement: {
        us: 'Announcement',
        cz: 'Oznámení',
        pl: 'Powiadomienie',
        hr: 'Najave',
        si: 'Obvestilo',
        de: 'Ankündigung',
        sk: 'Oznámenie',
        es: 'Anuncio',
        ru: 'Уведомление',
        rs: 'Saopštenje',
        ua: 'Сповіщення',
        no: 'Melding',
        it: 'Notifica',
        se: 'Meddelande',
    },
    localization: {
        us: 'Localization',
        cz: 'Lokalizace',
        pl: 'Lokalizacja',
        hr: 'Lokalizacija',
        si: 'Lokalizacija',
        de: 'Lokalisierung',
        sk: 'Lokalizácia',
        es: 'Localización',
        ru: 'Местоположение',
        rs: 'Lokalizacija',
        ua: 'Розташування',
        no: 'Lokalisasjon',
        it: 'Localizzazione',
        se: 'Lokalisering',
    },
    from: {
        us: 'From',
        cz: 'Od',
        pl: 'Od',
        hr: 'Od',
        si: 'Od',
        de: 'Von',
        sk: 'Od',
        es: 'De',
        ru: 'От',
        rs: 'Od',
        ua: 'Від',
        no: 'Fra',
        it: 'Da',
        se: 'Från',
    },
    to: {
        us: 'To',
        cz: 'Do',
        pl: 'Do',
        hr: 'Do',
        si: 'Do',
        de: 'Bis',
        sk: 'Do',
        es: 'Para',
        ru: 'До',
        rs: 'Do',
        ua: 'До',
        no: 'Til',
        it: 'A',
        se: 'Till',
    },
    deleteAnnouncement: {
        us: 'Delete announcement?',
        cz: 'Smazat oznámení?',
        pl: 'Usunąć powiadomienie?',
        hr: 'Izbriši obavijest?',
        si: 'Izbriši obvestilo?',
        de: 'Ankündigung löschen?',
        sk: 'Zmazať oznámenie?',
        es: '¿Borrar anuncio?',
        ru: 'Удалить уведомление?',
        rs: 'Izbrisati saopštenje?',
        ua: 'Видалити сповіщення?',
        no: 'Slette meldingen?',
        it: 'Cancellare le notifiche?',
        se: 'Radera meddelandet?',
    },
    titleNewAnnoun: {
        us: 'Add new announcement',
        cz: 'Přidat nové oznámení',
        pl: 'Dodaj nowe powiadomienie',
        hr: 'Dodaj novu najavu',
        si: 'Dodaj novo obvestilo',
        de: 'Neue Ankündigung hinzufügen',
        sk: 'Pridať nové oznámenie',
        es: 'Añadir nuevo anuncio',
        ru: 'Добавить новое уведомление',
        rs: 'Dodajte novo saopštenje',
        ua: 'Додати нове повідомлення',
        no: 'Legg til en ny melding',
        it: 'Aggiungere una nuova notifica',
        se: 'Lägg till ett nytt meddelande',
    },
    enterDateAnnouncementRange: {
        us: 'Enter the date from and to which the announcement should be displayed.',
        cz: 'Zadejte datum, od a do kdy se má oznámení zobrazovat.',
        pl: 'Wprowadź datę, od i do kiedy ma się wyświetlać powiadomienie.',
        hr: 'Unesite datum od i do kojeg bi trebala biti prikazana najava.',
        si: 'Vnesite datum od in do katerega bo prikazano obvestilo.',
        de: 'Geben Sie das Datum von und bis ein, wann die Ankündigung angezeigt werden soll.',
        sk: 'Zadajte dátum, od a do kedy sa má oznámenia zobrazovať.',
        es: 'Introduzca la fecha desde y hasta en la que se debe mostrar el anuncio.',
        ru: 'Введите даты ОТ и ДО которых должно отображаться уведомление.',
        rs: 'Unesite datum od i do kada bi saopštenje trebalo da bude prikazano.',
        ua: 'Введіть дати ВІД та ДО яких має відображатись повідомлення.',
        no: 'Oppgi dato, fra og til, når meldingen skal vises.',
        it: 'Immetta la data da e fino a quando si desidera che la notifica venga visualizzata.',
        se: 'Ange de datum mellan vilka meddelandet ska visas.',
    },
    minTimeToDisplay: {
        us: 'Announcement can be displayed at least for 1 min.',
        cz: 'Oznámení lze zobrazit nejméně po dobu 1 min.',
        pl: 'Powiadomienie może być wyświetlane przez co najmniej 1 minutę.',
        hr: 'Najava se može prikazivati najmanje 1 min.',
        si: 'Obvestilo se lahko prikaže najmanj 1 min.',
        de: 'Die Ankündigung kann mindestens 1 Minute lang angezeigt werden.',
        sk: 'Oznámenie možno zobraziť najmenej po dobu 1 min.',
        es: 'El anuncio se puede mostrar al menos durante 1 minuto.',
        ru: 'Уведомление может отображаться минимально 1 мин.',
        rs: 'Saopštenje može biti prikazano najmanje 1 minut.',
        ua: 'Повідомлення може відображатися мінімально 1 хв.',
        no: 'Meldingen skal vises i minst 1 minutt.',
        it: 'La notifica può essere visualizzata per almeno 1 minuto.',
        se: 'Meddelandet ska visas i minst 1 minut.',
    },
    minimumOneAnnoun: {
        us: 'Enter at least one announcement.',
        cz: 'Zadejte alespoň jedno oznámení.',
        pl: 'Wprowadź co najmniej jedno powiadomienie.',
        hr: 'Unesite barem jednu najavu.',
        si: 'Vnesite vsaj eno obvestilo.',
        de: 'Geben Sie mindestens eine Ankündigung ein.',
        sk: 'Zadajte aspoň jedno oznámenia.',
        es: 'Ingrese al menos un anuncio.',
        ru: 'Введите хотя бы ОДНО уведомление.',
        rs: 'Unesite najmanje jedno saopštenje.',
        ua: 'Введіть хоча б одне повідомлення.',
        no: 'Skriv inn minst én melding.',
        it: 'Inserisca almeno una notifica.',
        se: 'Skriv in minst ett meddelande.',
    },
    pastTime: {
        us: "Date can't be set to past time. Announcement can be displayed from the current moment.",
        cz: 'Nelze nastavit na starší čas. Oznámení se zobrazí nejdříve od aktuálního času.',
        pl: 'Nie możesz wprowadzić daty z przeszłości. Powiadomienie może być wyświetlane najwcześniej od aktualnego czasu.',
        hr: 'Nije moguće postaviti prošlo vrijeme. Obavijest se prikazuje najranije od trenutnog vremena.',
        si: 'Datuma ni mogoče namestiti na pretekli čas. Obvestilo se lahko prikaže od trenutnega trenutka.',
        de: 'Datum kann nicht auf Vergangenheit eingestellt werden. Ankündigung kann ab dem aktuellen Zeitpunkt angezeigt werden.',
        sk: 'Nie je možné nastaviť na skorší čas. Oznámenie sa zobrazí najskôr od aktuálneho času.',
        es: 'La fecha no se puede establecer en una hora pasada. El anuncio se puede mostrar desde el momento actual.',
        ru: 'Невозможно указать прошедшую дату. Уведомление может отображаться от настоящего момента.',
        rs: 'Datum ne može da se podesi na prošlo vreme. Saopštenje se može prikazati od ovog trenutka.',
        ua: 'Неможливо вказати минулу дату. Повідомлення може відображатися від цього моменту.',
        no: 'Dato kan ikke være i fortid. Meldingen kan vises fra nå og utover.',
        it: "Non può essere impostato su un orario precedente. Le notifiche saranno visualizzate non prima dell'ora corrente.",
        se: 'Ett förflutet datum kan inte ställas in. Meddelandet kan visas från och med nu.',
    },
    okAnnouncement: {
        us: 'Save announcement',
        cz: 'Uložit oznámení',
        pl: 'Zapisz powiadomienie',
        hr: 'Spremi novu najavu',
        si: 'Shrani novo obvestilo',
        de: 'Ankündigung speichern',
        sk: 'Uložiť oznámenie',
        es: 'Guardar anuncio',
        ru: 'Сохранить уведомление',
        rs: 'Sačuvaj saopštenje',
        ua: 'Зберегти повідомлення',
        no: 'Lagre meldingen',
        it: 'Salva le notifiche',
        se: 'Spara meddelandet',
    },
    announcementSaved: {
        us: 'Announcement has been saved',
        cz: 'Oznámení bylo uloženo',
        pl: 'Powiadomienie zostało zapisane',
        hr: 'Najava je spremljena',
        si: 'Obvestilo je shranjeno',
        de: 'Ankündigung gespeichert',
        sk: 'Oznámenie bolo uložené',
        es: 'El anuncio se ha guardado',
        ru: 'Уведомление было сохранено',
        rs: 'Saopštenje je sačuvano',
        ua: 'Повідомлення було збережено',
        no: 'Meldingen lagret',
        it: 'La notifica è stata salvata',
        se: 'Meddelandet har sparats',
    },
    failedToReloadDocument: {
        us: 'Failed to reload document',
        cz: 'Nepodařilo se znovu načíst dokument',
        pl: 'Nie udało się ponownie załadować dokumentu',
        hr: 'Greška kod ponovnog učitavanja dokumenta',
        si: 'Dokumenta ni bilo mogoče znova naložiti',
        de: 'Dokument konnte nicht neu geladen werden',
        sk: 'Nepodarilo sa znovu načítať dokument',
        es: 'No se pudo volver a cargar el documento',
        ru: 'Не удалось перезагрузить документ',
        rs: 'Učitavanje dokumenta nije uspelo',
        ua: 'Не вдалося перезавантажити документ',
        no: 'Klarte ikke å laste dokumentet på nytt',
        it: 'Impossibile ricaricare il documento',
        se: 'Det gick inte att ladda om dokumentet',
    },
    trialProjectMessageTitle: {
        us: 'You are still in trial mode',
        cz: 'Jste stále v demo režimu',
        pl: 'Nadal jesteś w trybie demo',
        hr: 'Još uvijek ste u probnom načinu rada',
        si: 'Še vedno ste v preskusnem načinu dela',
        de: 'Sie befinden sich noch im Testmodus',
        sk: 'Stále ste v demo režime',
        es: 'Todavía está en modo de prueba',
        ru: 'Вы все еще находитесь в пробном режиме',
        rs: 'Još uvek ste u probnom režimu',
        ua: 'Ви все ще перебуваєте в пробному режимі',
        no: 'Du er fortsatt i prøvemodus',
        it: 'È ancora in modalità demo',
        se: 'Du befinner dig fortfarande i testläge',
    },
    trialProjectMessageContent: {
        us: 'Only part of your recording is transcribed in this mode. For unlimited transcriptions,',
        cz: 'V tomto režimu se přepíše jen část vaší nahrávky. Pro přepis bez omezení si',
        pl: 'W tym trybie transkrybowana jest tylko część nagrania. Aby uzyskać dostęp do nieograniczonej transkrypcji, należy',
        hr: 'Samo dio snimke se transkribira u ovom načinu. Za neograničene transkripcije,',
        si: 'V tem načinu je prepisan samo del posnetka. Za neomejene transkripcije,',
        de: 'In diesem Modus wird nur ein Teil Ihrer Aufnahme transkribiert. Für unbegrenzte Transkriptionen,',
        sk: 'V tomto režime sa prepíše len časť záznamu. Ak chcete neobmedzene prepisovať,',
        es: 'Solo una parte de su grabación se transcribe en este modo. Para transcripciones ilimitadas,',
        ru: 'В этом режиме транскрибируется только часть записи. Для неограниченного количества транскрипций,',
        rs: 'Samo deo vašeg snimka se transkribuje u ovom režimu. Za neograničene transkripcije,',
        ua: 'У цьому режимі транскрибується лише частина вашого запису. Для необмеженої кількості транскрипцій,',
        no: 'Kun en del av innspillingen blir transkribert i prøvemodus. For ubegrenset transkripsjon,',
        it: 'In questa modalità, viene sovrascritta solo una parte della registrazione. Per la sovrascrittura senza limitazioni, è necessario',
        se: 'Endast en del av inspelningen transkriberas i detta läge. För obegränsade transkriptioner,',
    },
    trialProjectMessageTextLink: {
        us: 'top up your credit.',
        cz: 'dobijte kredit.',
        pl: 'dokupić kredyt.',
        hr: 'dopunite kredit.',
        si: 'dopolnite kredit',
        de: 'Kredit erhöhen.',
        sk: 'dobite si kredit.',
        es: 'recargue su crédito',
        ru: 'пополнить свой кредит.',
        rs: 'dopunite vaš kredit.',
        ua: 'поповнити свій кредит.',
        no: 'fyll på kreditt.',
        it: 'ricaricare il credito.',
        se: 'fyll på kredit.',
    },
    saveFailed: {
        us: 'Save failed. ',
        cz: 'Uložení selhalo. ',
        pl: 'Zapisywanie nie powiodło się. ',
        hr: 'Spremanje nije uspjelo. ',
        si: 'Shranjevanje ni uspelo. ',
        de: 'Speichern fehlgeschlagen. ',
        sk: 'Uloženie zlyhalo. ',
        es: 'Error al guardar. ',
        ru: 'Не удалось сохранить. ',
        rs: 'Čuvanje nije uspelo. ',
        ua: 'Не вдалось зберегти. ',
        no: 'Lagring mislyktes. ',
        it: 'Salvataggio fallito. ',
        se: 'Ej sparat. ',
    },
    retrying: {
        us: 'Retrying...',
        cz: 'Zkouším to znovu...',
        pl: 'Ponawiam próbę...',
        hr: 'Ponavljanje pokušaja...',
        si: 'Ponovni poskus...',
        de: 'Erneuter Versuch...',
        sk: 'Skúšame to znova...',
        es: 'Reintentando...',
        ru: 'Повторение попытки...',
        rs: 'Ponavljanje pokušaja...',
        ua: 'Повторення спроби...',
        no: 'Prøver igjen...',
        it: 'Sto provando di nuovo...',
        se: 'Provar igen...',
    },
    controlsPanel: {
        us: 'Controls panel',
        cz: 'Ovládací panel',
        pl: 'Panel sterowania',
        hr: 'Upravljačka ploča',
        si: 'Nadzorna plošča',
        de: 'Systemsteuerung',
        sk: 'Ovládací panel',
        es: 'Panel de control',
        ru: 'Панель управления',
        rs: 'Kotrolna tabla',
        ua: 'Панель управління',
        no: 'Kontrollpanel',
        it: 'Pannello di controllo',
        se: 'Kontrollpanel',
    },
    noProj: {
        us: 'No project',
        cz: 'Žádný projekt',
        pl: 'Żaden projekt',
        hr: 'Nema projekta',
        si: 'Nima projekta',
        de: 'Kein Projekt',
        sk: 'Žiadny projekt',
        es: 'Sin proyecto',
        ru: 'Нет проектов',
        rs: 'Nema projekta',
        ua: 'Немає проектів',
        no: 'Ingen prosjekt',
        it: 'Nessun progetto',
        se: 'Inget projekt',
    },
    noLastProj: {
        us: 'No last project',
        cz: 'Žádný poslední projekt',
        pl: 'Żaden ostatni projekt',
        hr: 'Nema posljednjeg projekta',
        si: 'Nima poslednjega projekta',
        de: 'Kein letztes Projekt',
        sk: 'Žiadny posledný projekt',
        es: 'Sin último proyecto',
        ru: 'Нет последнего проекта',
        rs: 'Nema poslednjeg projekta',
        ua: 'Немає останнього проекту',
        no: 'Ingen sist prosjekt',
        it: 'Nessun progetto recente',
        se: 'Inget senaste projekt',
    },
    noLastProjMsg: {
        us: 'You have not opened any projects yet.',
        cz: 'Zatím jste neotevřeli žádný projekt.',
        pl: 'Nie otworzyłeś dotychczas żadnego projektu.',
        hr: 'Još niste otvorili ni jedan projekt.',
        si: 'Še niste odprli nobenega projekta.',
        de: 'Sie haben noch keine Projekte geöffnet.',
        sk: 'Zatiaľ ste neotvorili žiadny projekt.',
        es: 'Aún no ha abierto ningún proyecto.',
        ru: 'Не был открыт ни один проект.',
        rs: 'Još niste otvorili nijedan projekat.',
        ua: 'Не було відкрито жодного проекту.',
        no: 'Du har ikke åpnet noe prosjekt.',
        it: 'Non ha ancora aperto nessun progetto.',
        se: 'Du har inte öppnat några projekt än.',
    },
    notFound: {
        us: 'Not found',
        cz: 'Nenalezeno',
        pl: 'Nie odnaleziono',
        hr: 'Nije pronađeno',
        si: 'Ni najdeno',
        de: 'Nicht gefunden',
        sk: 'Nenájdené',
        es: 'No encontrado',
        ru: 'Не найдено',
        rs: 'Nije pronađeno',
        ua: 'Не знайдено',
        no: 'Ikke funnet',
        it: 'Non trovato',
        se: 'Ej funnet',
    },
    projNotFound: {
        us: 'Project not found',
        cz: 'Projekt nebyl nalezen',
        pl: 'Projekt nie został odnaleziony',
        hr: 'Projekt nije pronađen',
        si: 'Projekt ni najden',
        de: 'Projekt nicht gefunden',
        sk: 'Projekt nebol nájdený',
        es: 'Proyecto no encontrado',
        ru: 'Проект не найден',
        rs: 'Projekat nije pronađen',
        ua: 'Проект не знайдено',
        no: 'Prosjekt ikke funnet',
        it: 'Progetto non trovato',
        se: 'Projektet hittades ej',
    },
    projNotFoundMsg: {
        us: 'This project does not exist or you do not have access to it.',
        cz: 'Tento projekt neexistuje, nebo k němu nemáte přístup.',
        pl: 'Ten projekt nie istnieje albo nie masz do niego dostępu.',
        hr: 'Ovaj projekt ne postoji ili nemate pristup.',
        si: 'Ta projekt ne obstaja ali nimate dostopa.',
        de: 'Dieses Projekt existiert nicht, oder Sie haben keinen Zugang dazu.',
        sk: 'Tento projekt neexistuje, alebo k nemu nemáte prístup.',
        es: 'Este proyecto no existe o no tiene acceso a él.',
        ru: 'Этот проект не существует, или у вас нет к нему доступа.',
        rs: 'Ovaj projekat ne postoji ili mu nemate pristup.',
        ua: 'Цей проект не існує, або у вас немає доступу до нього.',
        no: 'Dette prosjektet finnes ikke, eller du mangler rettigheter for å få tilgang til det.',
        it: 'Questo progetto non esiste o non si ha accesso ad esso.',
        se: 'Detta projekt existerar inte eller du har inte åtkomst till det.',
    },
    backToProjs: {
        us: 'Go to projects',
        cz: 'Přejít na projekty',
        pl: 'Przejdź do projektu',
        hr: 'Idi na projekte',
        si: 'Pojdi na projekte',
        de: 'Zu Projekten',
        sk: 'Prejsť na projekty',
        es: 'Ir a proyectos',
        ru: 'Перейти к проектам',
        rs: 'Idite na projekte',
        ua: 'Перейти до проектів',
        no: 'Gå til prosjekter',
        it: 'Vada ai progetti',
        se: 'Gå till projekt',
    },
    backHome: {
        us: 'Back home',
        cz: 'Zpět domů',
        pl: 'Powrót na stronę główną',
        hr: 'Povratak na početnu stranicu',
        si: 'Nazaj na začetno stran',
        de: 'Zurück zur Startseite',
        sk: 'Späť domov',
        es: 'Volver a Inicio',
        ru: 'Назад домой',
        rs: 'Nazad na početni ekran',
        ua: 'Назад додому',
        no: 'Tilbake til hjemmesiden',
        it: 'Torna alla home',
        se: 'Tillbaka till hemsidan',
    },
    nameSaved: {
        us: 'Project name saved',
        cz: 'Jméno projektu uloženo',
        pl: 'Zapisano nazwę projektu',
        hr: 'Naziv projekta spremljen',
        si: 'Ime projekta je shranjeno',
        de: 'Projektname gespeichert',
        sk: 'Názov projektu uložený',
        es: 'Nombre del proyecto guardado',
        ru: 'Название проекта изменено',
        rs: 'Naziv projekta je sačuvan',
        ua: 'Назва проекту змінено',
        no: 'Prosjektnavnet lagret',
        it: 'Nome del progetto salvato',
        se: 'Projektnamnet har sparats',
    },
    failedName: {
        us: 'Failed to save project name.',
        cz: 'Uložení jména projektu selhalo.',
        pl: 'Nie udało się zapisać nazwy projektu.',
        hr: 'Nije uspjelo spremanje naziva projekta.',
        si: 'Ni uspelo shranjevanje imena projekta.',
        de: 'Der Projektname konnte nicht gespeichert werden.',
        sk: 'Uloženie názvu projektu sa nepodarilo.',
        es: 'No se pudo guardar el nombre del proyecto.',
        ru: 'При сохранении названия проекта возникла ошибка.',
        rs: 'Čuvanje naziva projekta nije uspelo.',
        ua: 'У разі збереження назви проекту виникла помилка.',
        no: 'Klarte ikke å lagre prosjektnavnet.',
        it: 'Il salvataggio del nome del progetto non è riuscito.',
        se: 'Det gick inte att spara projektnamnet.',
    },
    retry: {
        us: 'Please try again.',
        cz: 'Zkuste to prosím znovu.',
        pl: 'Proszę spróbuj ponownie.',
        hr: 'Pokušajte ponovno.',
        si: 'Poskusite ponovno.',
        de: 'Bitte versuchen Sie es erneut.',
        sk: 'Skúste to prosím znova.',
        es: 'Por favor inténtelo de nuevo.',
        ru: 'Попробуйте, пожалуйста, снова.',
        rs: 'Molimo da pokušate ponovo.',
        ua: 'Спробуйте, будь ласка, знову.',
        no: 'Vennligst prøv igjen.',
        it: 'Si prega di riprovare.',
        se: 'Vänligen prova igen.',
    },
    untitled: {
        us: 'Untitled Project',
        cz: 'Projekt beze jména',
        pl: 'Projekt bez nazwy',
        hr: 'Projekt bez naslova',
        si: 'Projekt brez naslova',
        de: 'Projekt ohne Titel',
        sk: 'Projekt bez mena',
        es: 'Proyecto sin título',
        ru: 'Проект без названия',
        rs: 'Neimenovani projekat',
        ua: 'Проект без назви',
        no: 'Prosjekt uten tittel',
        it: 'Progetto senza nome',
        se: 'Namnlöst projekt',
    },
    transcriptionStopped: {
        us: 'Transcription was stopped and deleted.',
        cz: 'Přepis byl zastaven a smazán.',
        pl: 'Transkrypcja została zatrzymana i usunięta.',
        hr: 'Transkripcija je zaustavljena i obrisana.',
        si: 'Prepis je ustavljen in izbrisan.',
        de: 'Transkription wurde gestoppt und gelöscht.',
        sk: 'Prepis bol zastavený a vymazaný.',
        es: 'La transcripción se detuvo y eliminó.',
        ru: 'Транскрибирование было остановлено и удалено.',
        rs: 'Transkripcija je uspešno zaustavljena.',
        ua: 'Транскрибування було зупинено та видалено.',
        no: 'Transkripsjonen ble stoppet og slettet.',
        it: 'La trascrizione è stata interrotta ed eliminata.',
        se: 'Transkriptionen avbröts och raderades.',
    },
    transcriptionStopFailed: {
        us: 'Failed to stop transcription.',
        cz: 'Zastavení přepisu selhalo.',
        pl: 'Zatrzymanie transkrypcji się nie powiodło.',
        hr: 'Zaustavljanje transkripcije nije uspjelo.',
        si: 'Ustavitev prepisa ni uspela.',
        de: 'Transkription konnte nicht gestoppt werden.',
        sk: 'Zastavenie prepisu zlyhalo.',
        es: 'No se pudo detener la transcripción.',
        ru: 'Не удалось остановить транскрибирование.',
        rs: 'Zaustavljanje transkripcije nije uspelo.',
        ua: 'Не вдалося зупинити транскрибування.',
        no: 'Klarte ikke å stoppe transkripsjonen.',
        it: "L'arresto della trascrizione non è riuscito.",
        se: 'Det gick inte att avbryta transkriptionen.',
    },
    continueQuestion: {
        us: 'Do you want to continue?',
        cz: 'Chcete pokračovat?',
        pl: 'Czy chcesz kontynuować?',
        hr: 'Želite li nastaviti?',
        si: 'Želite nadaljevati?',
        de: 'Wollen Sie fortsetzen?',
        sk: 'Chcete pokračovať?',
        es: '¿Quiere continuar?',
        ru: 'Вы хотите продолжить?',
        rs: 'Da li želite da nastavite?',
        ua: 'Ви хочете продовжити?',
        no: 'Vil du fortsette?',
        it: 'Vuole continuare?',
        se: 'Vill du fortsätta?',
    },
    resetTranscription: {
        us: 'Reset to original transcription?',
        cz: 'Reset na původní přepis?',
        pl: 'Zresetować do oryginalnej transkrypcji?',
        hr: 'Vratiti na izvornu transkripciju?',
        si: 'Vrniti na izvirni prepis?',
        de: 'Auf ursprüngliche Transkription zurücksetzen?',
        sk: 'Reset na pôvodný prepis?',
        es: '¿Restablecer la transcripción original?',
        ru: 'Восстановить оригинальную запись?',
        rs: 'Da li želite da vratite originalnu transkripciju?',
        ua: 'Відновити оригінальний запис?',
        no: 'Tilbakestille til den opprinnelige transkripsjonen?',
        it: 'Ripristinare la trascrizione originale?',
        se: 'Återställa till den ursprungliga transkriptionen?',
    },
    yesReset: {
        us: 'Yes, reset',
        cz: 'Ano, resetovat',
        pl: 'Tak, zresetuj',
        hr: 'Da, ponovno pokreni',
        si: 'Ja, ponastavi',
        de: 'Ja, zurücksetzen',
        sk: 'Áno, resetovať',
        es: 'Sí, restablecer',
        ru: 'Да, восстановить',
        rs: 'Da, vratite',
        ua: 'Так, відновити',
        no: 'Ja, tilbakestille',
        it: 'Sì, ripristinare',
        se: 'Ja, återställ',
    },
    uploadTrsxConfirm: {
        us: 'Upload new trsx?',
        cz: 'Nahrát nový trsx?',
        pl: 'Wczytać nowy trsx?',
        hr: 'Učitati novi trsx?',
        si: 'Naloži novi trsx?',
        de: 'Neue Trsx-Datei hochladen?',
        sk: 'Nahrať nový trsx?',
        es: '¿Subir nuevo trsx?',
        ru: 'Загрузить новый trsx?',
        rs: 'Otpremiti novi trsx?',
        ua: 'Завантажити новий trsx?',
        no: 'Laste opp en ny trsx fil?',
        it: 'Caricare un nuovo trsx?',
        se: 'Ladda upp en ny trsx-fil?',
    },
    uploadBddConfirm: {
        us: 'Upload bdd dump?',
        cz: 'Nahrát bdd dump?',
        pl: 'Wczytać bdd dump?',
        hr: 'Učitati bdd dump?',
        si: 'Naloži bdd dump?',
        de: 'Bdd dump hochladen?',
        sk: 'Nahrať bdd dump?',
        es: '¿Subir bdd dump?',
        ru: 'Загрузить bdd dump?',
        rs: 'Otpremiti bdd dump?',
        ua: 'Завантажити bdd dump?',
        no: 'Laste opp bdd dump?',
        it: 'Caricare il dump di bdd?',
        se: 'Ladda upp bdd dump?',
    },
    yesUpload: {
        us: 'Yes, upload',
        cz: 'Ano, nahrát',
        pl: 'Tak, wczytaj',
        hr: 'Da, učitaj',
        si: 'Ja, naloži',
        de: 'Ja, hochladen',
        sk: 'Áno, nahrať',
        es: 'Sí, subir',
        ru: 'Да, загрузить',
        rs: 'Da, otpremi',
        ua: 'Так, завантажити',
        no: 'Ja, laste opp',
        it: 'Sì, caricare',
        se: 'Ja, ladda upp',
    },
    stopTranscriptionQuestion: {
        us: 'Stop transciption?',
        cz: 'Zastavit přepis?',
        pl: 'Zatrzymać transkrypcję?',
        hr: 'Zaustavi transkripciju?',
        si: 'Ustavi prepis?',
        de: 'Transkription stoppen?',
        sk: 'Zastaviť prepis?',
        es: '¿Detener la transcripción?',
        ru: 'Остановить транскрибирование?',
        rs: 'Zaustaviti transkripciju?',
        ua: 'Зупинити транскрибування?',
        no: 'Stoppe transkripsjonen?',
        it: 'Interrompere la trascrizione?',
        se: 'Avbryta transkriptionen?',
    },
    changesOverwritten: {
        us: 'This action will overwrite all your changes. This cannot be undone.',
        cz: 'Touto akcí ztratíte veškeré uložené změny. Tato akce je nevratná.',
        pl: 'Akcja ta spowoduje utratę wszelkich zapisanych zmian. Skutek będzie nieodwracalny.',
        hr: 'Ova će radnja izbrisati sve vaše promjene. To se ne može poništiti.',
        si: 'To dejanje bo izbrisalo vse vaše spremembe. Tega se ne more preklicati.',
        de: 'Diese Aktion überschreibt alle Ihre Änderungen. Diese Aktion ist irreversibel.',
        sk: 'Touto akciou stratíte všetky uložené zmeny. Táto akcia je nevratná.',
        es: 'Esta acción sobrescribirá todos sus cambios. Esto no se puede deshacer.',
        ru: 'При этом действии все сохранённые изменения будут утеряны. Действие не может быть отменено.',
        rs: 'Ova radnja će prebrisati sve vaše izmene. Ovo se ne može poništiti.',
        ua: 'При цій дії всі збережені зміни будуть втрачені. Дія може бути скасована.',
        no: 'Du vil miste alle endringene med denne handlingen. Denne prosessen er irreversibel.',
        it: 'Questa azione comporta la perdita di tutte le modifiche salvate. Questa azione è irreversibile.',
        se: 'Du kommer att förlora alla dina ändringar med detta steg. Steget kan inte ångras.',
    },
    resetSuccessful: {
        us: 'Successfully reset to original transcription',
        cz: 'Úspěšně resetováno na původní přepis',
        pl: 'Pomyślnie zresetowano do oryginalnej transkrypcji',
        hr: 'Uspješno vraćeno na izvornu transkripciju',
        si: 'Uspešno nastavljeno na izvirni prepis',
        de: 'Erfolgreich auf ursprüngliche Transkription zurückgesetzt',
        sk: 'Úspešne resetované na pôvodný prepis',
        es: 'El restablecimiento a la transcripción original se realizó correctamente',
        ru: 'Оригинальная транскрипция успешно восстановлена',
        rs: 'Vraćanje na originalnu transkripciju je uspešno',
        ua: 'Оригінальна транскрипція успішно відновлена',
        no: 'Tilbakestilt til den opprinnelige transkripsjonen',
        it: 'Trascrizione originale ripristinata con successo',
        se: 'Återställt till den ursprungliga transkriptionen',
    },
    resetFailed: {
        us: 'Reset to original transcription failed',
        cz: 'Reset na původní přepis selhal',
        pl: 'Reset do oryginalnej transkrypcji nie powiódł się',
        hr: 'Vraćanje na izvornu transkripciju nije uspjelo',
        si: 'Vrnitev na izvirni prepis ni uspela',
        de: 'Zurücksetzen auf ursprüngliche Transkription fehlgeschlagen',
        sk: 'Reset na pôvodný prepis zlyhal',
        es: 'No se pudo restablecer la transcripción original',
        ru: 'Восстановление оригинальной транскрипции не удалось',
        rs: 'Vraćanje na originalnu transkripciju nije uspelo',
        ua: 'Відновлення оригінальної транскрипції не вдалося',
        no: 'Tilbakestilling til den opprinnelige transkripsjonen mislyktes',
        it: 'Ripristino della trascrizione originale non riuscita',
        se: 'Återställningen till den ursprungliga transkriptionen misslyckades',
    },
    trsxUploaded: {
        us: 'Trsx file was successfully uploaded',
        cz: 'Soubor trsx byl úspěšně nahrán',
        pl: 'Plik trsx został wczytany',
        hr: 'Trsx datoteka uspješno učitana',
        si: 'Trsx datoteka uspešno prenesena',
        de: 'Trsx-Datei erfolgreich hochgeladen',
        sk: 'Súbor trsx úspešne nahraný',
        es: 'Archivo trsx subido correctamente',
        ru: 'Trsx файл успешно загружен',
        rs: 'Trsx datoteka je uspešno učitana',
        ua: 'Trsx файл успішно завантажено',
        no: 'Trsx filen lastet opp',
        it: 'Il file trsx è stato caricato con successo',
        se: 'Trsx-filen har laddats upp',
    },
    trsxUploadFail: {
        us: 'Trsx file upload failed. Please try again.',
        cz: 'Nahrání souboru trsx selhalo. Zkuste to prosím znovu.',
        pl: 'Przesyłanie pliku trsx nie powiodło się. Spróbuj ponownie.',
        hr: 'Prijenos trsx datoteke nije uspio. Pokušajte ponovno.',
        si: 'Nalaganje datoteke trsx ni uspelo. Poskusite znova.',
        de: 'Trsx-Dateiupload fehlgeschlagen. Bitte versuchen Sie es erneut.',
        sk: 'Nahrávanie súboru trsx zlyhalo. Skúste to prosím znova.',
        es: 'Error al cargar el archivo trsx. Inténtelo de nuevo.',
        ru: 'Не удалось загрузить файл Trsx. Пожалуйста, попробуйте еще раз.',
        rs: 'Otpremanje trsx fajla nije uspelo. Probajte ponovo.',
        ua: 'Не вдалося завантажити файл Trsx. Будь ласка, спробуйте ще раз.',
        no: 'Opplasting av den trsx filen mislyktes. Vennligst prøv igjen.',
        it: 'Il caricamento del file trsx non è riuscito. Riprovare.',
        se: 'Det gick inte att ladda up trsx-filen. Vänligen prova om.',
    },
    bddUploaded: {
        us: 'Bdd file was successfully uploaded',
        cz: 'Soubor bdd byl úspěšně nahrán',
        pl: 'Plik bdd został wczytany',
        hr: 'Bdd datoteka uspješno učitana',
        si: 'Bdd datoteka uspešno prenesena',
        de: 'Bdd erfolgreich hochgeladen',
        sk: 'Súbor bdd úspešne nahraný',
        es: 'Archivo bdd subido correctamente',
        ru: 'Bdd файл успешно загружен',
        rs: 'Bdd datoteka je uspešno učitana',
        ua: 'Bdd файл успішно завантажений',
        no: 'Bdd filen lastet opp',
        it: 'Il file bdd è stato caricato con successo',
        se: 'Bdd-filen har laddats upp',
    },
    confirmStopTranscription: {
        us: 'This will stop the transcription and delete your project. This action is irreversible.',
        cz: 'Tímto zastavíte přepis a smažete projekt. Tato akce je nevratná.',
        pl: 'Spowoduje to zatrzymanie transkrypcji i usunięcie projektu. Ten proces będzie nieodwracalny.',
        hr: 'Ovo će zaustaviti transkripciju i izbrisati vaš projekt. Ova je radnja nepovratna.',
        si: 'S tem boste ustavili prepis in izbrisali svoj projekt. Ta dejavnost je nepovratna.',
        de: 'Die Transkription wird gestoppt und Ihr Projekt gelöscht. Diese Aktion ist irreversibel.',
        sk: 'Týmto zastavíte prepis a zmažete projekt. Táto akcia je nevratná.',
        es: 'Esto detendrá la transcripción y eliminará su proyecto. Esta acción es irreversible.',
        ru: 'Этим действием вы остановите транскрибирование и удалите проект. Действие не может быть отменено.',
        rs: 'Ovo će zaustaviti transkripciju i izbrisati vaš projekat. Ova akocija je nepovratna.',
        ua: 'Цією дією ви зупините транскрибування та видалите проект. Дія не може бути скасована.',
        no: 'Dette vil stoppe transkripsjonen og slette prosjektet. Denne prosessen er irreversibel.',
        it: 'In questo modo si interrompe la sovrascrittura e si elimina il progetto. Questa azione è irreversibile.',
        se: 'Detta avbryter transkriptionen and raderar projektet. Detta steg kan inte ångras.',
    },
    yesStop: {
        us: 'Yes, stop',
        cz: 'Ano, zastavit',
        pl: 'Tak, zatrzymaj',
        hr: 'Da, zaustavi',
        si: 'Ja, ustavi',
        de: 'Ja, stoppen',
        sk: 'Áno, zastaviť',
        es: 'Sí, detener',
        ru: 'Да, остановить',
        rs: 'Da, zaustaviti',
        ua: 'Так, зупинити',
        no: 'Ja, stoppe',
        it: 'Sì, fermare',
        se: 'Ja, avbryt',
    },
    yes: {
        us: 'Yes',
        cz: 'Ano',
        pl: 'Tak',
        hr: 'Da',
        si: 'Ja',
        de: 'Ja',
        sk: 'Áno',
        es: 'Sí',
        ru: 'Да',
        rs: 'Da',
        ua: 'Так',
        no: 'Ja',
        it: 'Sì',
        se: 'Ja',
    },
    no: {
        us: 'No',
        cz: 'Ne',
        pl: 'Nie',
        hr: 'Ne',
        si: 'Ne',
        de: 'Nein',
        sk: 'Nie',
        es: 'No',
        ru: 'Нет',
        rs: 'Ne',
        ua: 'Ні',
        no: 'Nei',
        it: 'No',
        se: 'Nej',
    },
    projectChanged: {
        us: 'Project was changed on server',
        cz: 'Projekt byl změnen na serveru',
        pl: 'Projekt zmieniony na serwerze',
        hr: 'Projekt je promijenjen na poslužitelju',
        si: 'Projekt je spremenjen na serverju',
        de: 'Projekt wurde auf dem Server geändert',
        sk: 'Projekt zmenený na serveri',
        es: 'Proyecto cambiado en el servidor',
        ru: 'Проект изменен на сервере',
        rs: 'Projekat je promenjen na serveru',
        ua: 'Проект змінено на сервері',
        no: 'Prosjektet ble endret på server',
        it: 'Il progetto è stato modificato sul server',
        se: 'Projektet ändrades på servern',
    },
    projectChangedOnServer: {
        us: 'Project was changed on the server. Please refresh the page to update it. Your last changes will be lost.',
        cz: 'Projekt byl změnen na serveru. Aktualizujte prosím stránku. Vaše poslední změny nebudou uloženy.',
        pl: 'Projekt został zmieniony na serwerze. Odśwież stronę. Ostatnie zmiany nie zostaną zapisane.',
        hr: 'Projekt je promijenjen na poslužitelju. Osvježite stranicu za ažuriranje. Vaše posljednje promjene bit će izgubljene.',
        si: 'Projekt je spremenjen na serverju. Osvežite stran za posodobitev. Vaše zadnje spremembe bodo izgubljene.',
        de: 'Das Projekt wurde auf dem Server geändert. Aktualisieren Sie die Seite. Ihre letzten Änderungen werden nicht gespeichert.',
        sk: 'Projekt bol zmenený na serveri. Je nutné obnoviť stránku. Vaše posledné zmeny nebudú uložené.',
        es: 'El proyecto se cambió en el servidor. Recarga la página para actualizar. Se perderán sus últimos cambios.',
        ru: 'Проект изменен на сервере. Необходимо обновить страницу. Ваши последние изменения не будут сохранены.',
        rs: 'Projekat je promenjen na serveru. Osvežite stranicu da biste je ažurirali. Vaše poslednje promene će biti izgubljene.',
        ua: 'Проект змінено на сервері. Потрібно оновити сторінку. Ваші останні зміни не будуть збережені.',
        no: 'Prosjektet ble endret på server. Vennligst oppdater nettleseren. Dine siste endringer blir slettet.',
        it: 'Il progetto è stato modificato sul server. Aggiorni la pagina. Le ultime modifiche non verranno salvate.',
        se: 'Projektet ändrades på servern. Vänligen uppdatera webbsidan. Du kommer att förlora dina senaste ändringar.',
    },
    badQualityTitle: {
        us: 'Poor transcription quality',
        cz: 'Špatná kvalita přepisu',
        pl: 'Niska jakość transkrypcji',
        hr: 'Loša kvaliteta transkripcije',
        si: 'Slaba kakovost prepisa',
        de: 'Schlechte Transkriptionsqualität',
        sk: 'Zlá kvalita prepisu',
        es: 'Mala calidad de transcripción',
        ru: 'Низкое качество записи',
        rs: 'Loš kvalitet transkripcije',
        ua: 'Низька якість запису',
        no: 'Dårlig kvalitet på transkripsjonen',
        it: 'Scarsa qualità della trascrizione',
        se: 'Låg kvalitet på transkriptionen',
    },
    badQualityText: {
        us: 'It seems that the transcription quality is very low. This is likely caused by poor quality of the recording. Find out how to improve transcription accuracy:',
        cz: 'Zdá se, že přepis má velmi nízkou kvalitu. To je pravděpodobně způsobeno špatnou kvalitou nahrávky. Zjistěte, jak přesnost přepisu zlepšit:',
        pl: 'Wygląda na to, że jakość transkrypcji jest bardzo niska. Jest to prawdopodobnie spowodowane słabą jakością nagrania. Dowiedz się, jak poprawić dokładność transkrypcji:',
        hr: 'Čini se da je kvaliteta transkripcije vrlo niska. To je vjerojatno uzrokovano lošom kvalitetom snimke. Saznajte kako poboljšati točnost transkripcije:',
        si: 'Zdi se, da je kakovost prepisa zelo nizka. To je verjetno posledica slabe kakovosti posnetka. Izvedite, kako izboljšati natančnost prepisa:',
        de: 'Es scheint, dass die Qualität der Transkription sehr niedrig ist. Dies ist wahrscheinlich auf die schlechte Qualität der Aufnahme zurückzuführen. Finden Sie heraus, wie Sie die Transkriptionsgenauigkeit verbessern können:',
        sk: 'Prepis sa zdá byť veľmi nekvalitný. Je to pravdepodobne spôsobené nízkou kvalitou nahrávky. Zistite, ako zlepšiť presnosť prepisu:',
        es: 'Parece que la calidad de la transcripción es muy baja. Es probable que esto se deba a la mala calidad de la grabación. Descubra cómo mejorar la precisión de la transcripción:',
        ru: 'Вероятно, что качество транскрипции очень низкое. Скорее всего это вызвано низким качеством аудиозаписи. Узнайте больше о том, как улучшить точность транскрипции:',
        rs: 'Čini se da je kvalitet transkripcije veoma nizak. Ovo je verovatno prouzrokovano lošim kvalitetom snimka. Saznajte kako poboljšati kvalitet transkripcije:',
        ua: 'Ймовірно, якість транскрипції дуже низька. Швидше за все, це викликано низькою якістю аудіозапису. Дізнайтеся більше про те, як покращити точність транскрипції:',
        no: 'Det ser ut som at kvaliteten på transkripsjonen er dårlig, sannsynlig på grunn av dårlig kvalitet på innspillingen. Finn ut hvordan du kan forbedre transkripsjonen:',
        it: "La trascrizione appare di pessima qualità. Ciò è probabilmente dovuto alla scarsa qualità della registrazione. Scopra come migliorare l'efficacia della trascrizione:",
        se: 'Det verkar som att kvaliteten på transkriptionen är väldigt låg, troligtvis på grund av låg kvalitet på inspelningen. Ta reda på hur du kan förbättra transkriptionen:',
    },
    badQualityLink: {
        us: 'https://www.beey.io/en/tutorials/how-to-make-a-high-quality-audio-recording/',
        cz: 'https://www.beey.io/cs/tutorialy/jak-poridit-kvalitni-zvukovou-nahravku/',
        pl: 'https://www.beey.io/pl/tutoriale/jak-sporzadzic-dobrej-jakosci-nagranie-dzwiekowe/',
        hr: 'https://www.beey.io/en/tutorials/how-to-make-a-high-quality-audio-recording/',
        si: 'https://www.beey.io/sl/vodici/kako-ustvariti-kakovosten-zvocni-posnetek/',
        de: 'https://www.beey.io/de/anleitungen/wie-sie-eine-audioaufnahme-in-guter-qualitaet-erstellen/',
        sk: 'https://www.beey.io/cs/tutorialy/jak-poridit-kvalitni-zvukovou-nahravku/',
        es: 'https://www.beey.io/en/tutorials/how-to-make-a-high-quality-audio-recording/',
        ru: 'https://www.beey.io/en/tutorials/how-to-make-a-high-quality-audio-recording/',
        rs: 'https://www.beey.io/en/tutorials/how-to-make-a-high-quality-audio-recording/',
        ua: 'https://www.beey.io/en/tutorials/how-to-make-a-high-quality-audio-recording/',
        no: 'https://www.beey.io/en/tutorials/how-to-make-a-high-quality-audio-recording/',
        it: 'https://www.beey.io/en/tutorials/how-to-make-a-high-quality-audio-recording/',
        se: 'https://www.beey.io/en/tutorials/how-to-make-a-high-quality-audio-recording/',
    },
    badQualityLinkText: {
        us: 'How to make a high-quality audio recording',
        cz: 'Jak pořídit kvalitní zvukovou nahrávku',
        pl: 'Jak sporządzić dobrej jakości nagranie dźwiękowe',
        hr: 'Kako napraviti kvalitetnu audiosnimku',
        si: 'Kako ustvariti kakovosten zvočni posnetek',
        de: 'Wie macht man eine hochwertige Audioaufnahme',
        sk: 'Ako vytvoriť kvalitný zvukový záznam',
        es: 'Cómo grabar audio de alta calidad',
        ru: 'Как сделать аудиозапись высокого качества',
        rs: 'Kako napraviti kvaliteta audio zapis',
        ua: 'Як зробити аудіозапис високої якості',
        no: 'Hvordan lage høykvalitetsopptak',
        it: 'Come realizzare una registrazione audio di qualità',
        se: 'Hur gör man en högkvalitativ inspelning',
    },
    editorWidthTitle: {
        us: 'Editor width',
        cz: 'Šířka editoru',
        pl: 'Szerokość edytora',
        hr: 'Širina editora',
        si: 'Širina urejevalnika',
        de: 'Editorbreite',
        sk: 'Šírka editora',
        es: 'Ancho del editor',
        ru: 'Ширина редактора',
        rs: 'Širina editora',
        ua: 'Ширина редактора',
        no: 'Bredde av editoren',
        it: "Larghezza dell'editore",
        se: 'Bredden på editorn',
    },
    editorWidthAuto: {
        us: 'Auto',
        cz: 'Auto',
        pl: 'Auto',
        hr: 'Auto',
        si: 'Avto',
        de: 'Auto',
        sk: 'Auto',
        es: 'Auto',
        ru: 'Автоматическая',
        rs: 'Auto',
        ua: 'Автоматична',
        no: 'Auto',
        it: 'Automatico',
        se: 'Auto',
    },
    editorWidthNarrow: {
        us: 'Narrow',
        cz: 'Úzký',
        pl: 'Wąski',
        hr: 'Uzak',
        si: 'Ozek',
        de: 'Eng',
        sk: 'Úzky',
        es: 'Estrecho',
        ru: 'Узкий',
        rs: 'Usko',
        ua: 'Вузький',
        no: 'Smal',
        it: 'Stretto',
        se: 'Smal',
    },
    editorWidthMedium: {
        us: 'Medium',
        cz: 'Střední',
        pl: 'Średni',
        hr: 'Srednji',
        si: 'Srednji',
        de: 'Mittel',
        sk: 'Stredný',
        es: 'Medio',
        ru: 'Средний',
        rs: 'Srednje',
        ua: 'Середній',
        no: 'Medium',
        it: 'Medio',
        se: 'Medium',
    },
    editorWidthWide: {
        us: 'Wide',
        cz: 'Široký',
        pl: 'Szeroki',
        hr: 'Širok',
        si: 'Širok',
        de: 'Breit',
        sk: 'Široký',
        es: 'Amplio',
        ru: 'Широкий',
        rs: 'Široko',
        ua: 'Широкий',
        no: 'Bred',
        it: 'Ampio',
        se: 'Bred',
    },
    minVid: {
        us: 'Minimize',
        cz: 'Minimalizovat',
        pl: 'Zminimalizować',
        hr: 'Smanji',
        si: 'Zmanjšaj',
        de: 'Minimieren',
        sk: 'Minimalizovať',
        es: 'Minimizar',
        ru: 'Уменьшить',
        rs: 'Minimiziraj',
        ua: 'Зменшити',
        no: 'Minimere',
        it: 'Ridurre al minimo',
        se: 'Minimera',
    },
    maxVid: {
        us: 'Maximize',
        cz: 'Maximalizovat',
        pl: 'Maksymalizować',
        hr: 'Povećaj',
        si: 'Povečaj',
        de: 'Maximieren',
        sk: 'Maximalizovať',
        es: 'Maximizar',
        ru: 'Увеличить',
        rs: 'Maksimiziraj',
        ua: 'Збільшити',
        no: 'Maksimere',
        it: 'Massimizzare',
        se: 'Maximera',
    },
    hideVid: {
        us: 'Hide video',
        cz: 'Skrýt video',
        pl: 'Ukryj wideo',
        hr: 'Sakrij video',
        si: 'Skrij video',
        de: 'Video verstecken',
        sk: 'Skryť video',
        es: 'Ocultar video',
        ru: 'Скрыть видео',
        rs: 'Sakrij video',
        ua: 'Приховати відео',
        no: 'Skjule video',
        it: 'Nascondere il video',
        se: 'Dölj video',
    },
    showVid: {
        us: 'Show video',
        cz: 'Zobrazit video',
        pl: 'Zobacz wideo',
        hr: 'Prikaži video',
        si: 'Prikaži video',
        de: 'Video anzeigen',
        sk: 'Zobraziť video',
        es: 'Mostrar video',
        ru: 'Показать видео',
        rs: 'Prikaži video',
        ua: 'Показати відео',
        no: 'Vise video',
        it: 'Mostrare il video',
        se: 'Visa video',
    },
    resetCaptionPosition: {
        us: 'Reset subtitle position',
        cz: 'Resetovat pozici titulku',
        pl: 'Zresetuj pozycję napisów',
        hr: 'Resetiraj položaj titla',
        si: 'Ponastavi položaj podnapisa',
        de: 'Untertitelposition zurücksetzen',
        sk: 'Resetovanie pozície titulku',
        es: 'Restablecer la posición de los subtítulos',
        ru: 'Сброс позиции субтитров',
        rs: 'Resetovanje pozicije titla',
        ua: 'Скинути позицію субтитрів',
        no: 'Nullstille plassering av underteksten',
        it: 'Ripristino della posizione dei sottotitoli',
        se: 'Återställ undertexternas position',
    },
    someoneEditedHead: {
        us: 'Failed to save.',
        cz: 'Uložení selhalo.',
        pl: 'Zapisywanie się nie powiodło.',
        hr: 'Spremanje nije uspjelo.',
        si: 'Shranjevanje ni uspelo.',
        de: 'Fehler beim Speichern.',
        sk: 'Uloženie zlyhalo.',
        es: 'Error al guardar.',
        ru: 'Не удалось сохранить.',
        rs: 'Čuvanje nije uspelo.',
        ua: 'Не вдалось зберегти.',
        no: 'Lagring mislyktes.',
        it: 'Salvataggio fallito.',
        se: 'Ej sparat.',
    },
    someoneEdited: {
        us: 'Someone else edited the document. The page will be reloaded with their changes.',
        cz: 'Někdo jiný editoval dokument. Stránka bude obnovena s jejich změnami.',
        pl: 'Ktoś inny edytował dokument. Strona zostanie odświeżona wraz ze zmianami, które wprowadził.',
        hr: 'Netko je drugi uredio dokument. Stranica će biti ponovno učitana s ranijim promjenama',
        si: 'Nekdo drugi je uredil dokument. Stran bo ponovno naložena s prejšnjimi spremembami.',
        de: 'Ein anderer Benutzer hat das Dokument editiert. Die Seite wird mit ihren Änderungen neu geladen.',
        sk: 'Niekto iný editoval dokument. Stránka bude obnovená s ich zmenami.',
        es: 'Alguien más editó el documento. La página se volverá a cargar con sus cambios.',
        ru: 'Другой пользователь редактировал документ. Страница будет обновлена с внесёнными изменениями.',
        rs: 'Neko drugi je uredio dokument. Stranica će se ponovo učitati sa njihovim izmenama.',
        ua: 'Інший користувач редагував документ. Сторінку буде оновлено із внесеними змінами.',
        no: 'Noen annen har redigert videoen. Siden vil bli lastet på nytt med endringene av den andre brukeren.',
        it: 'Qualcun altro ha modificato il documento. La pagina verrà ripristinata con le modifiche apportate.',
        se: 'Någon annan har gjort ändringar i dokumentet. Sidan kommer att uppdateras med ändringarna.',
    },
    someoneEditedReloaded: {
        us: 'Someone else edited the document. The page was reloaded with their changes.',
        cz: 'Někdo jiný editoval dokument. Stránka byla obnovena s jejich změnami.',
        pl: 'Ktoś inny edytował ten dokument. Strona została odnowiona z uwzględnieniem wprowadzonych zmian.',
        hr: 'Netko drugi je uredio dokument. Stranica je ponovno učitana s njihovim promjenama.',
        si: 'Nekdo drug je uredil dokument. Stran je ponovno naložena z njihovimi spremembami.',
        de: 'Jemand anderes hat das Dokument bearbeitet. Die Seite wurde mit seinen Änderungen neu geladen.',
        sk: 'Dokument upravil niekto iný. Stránka bola obnovená s ich zmenami.',
        es: 'Alguien más editó el documento. La página fue recargada con sus cambios.',
        ru: 'Кто-то другой отредактировал документ. Страница была перезагружена с их изменениями.',
        rs: 'Neko drugi je uredio dokument. Stranica je ponovno učitana s njihovim promenama.',
        ua: 'Хтось інший відредагував документ. Сторінка була перезавантажена зі своїми змінами.',
        no: 'Noen annen har gjort endringer i dokumentet. Denne siden ble oppdatert med endringene.',
        it: 'Qualcun altro ha modificato il documento. La pagina è stata ripristinata con le modifiche apportate.',
        se: 'Någon annan har gjort ändringar i dokumentet. Sidan har uppdaterats med ändringarna.',
    },
    userThatEdited: {
        us: 'User that edited the document:',
        cz: 'Uživatel, který dokument editoval:',
        pl: 'Użytkownik, który edytował dokument:',
        hr: 'Korisnik koji je uredio dokument:',
        si: 'Uporabnik, ki je uredil dokument:',
        de: 'Benutzer, der das Dokument bearbeitet hat:',
        sk: 'Používateľ, ktorý dokument editoval:',
        es: 'Usuario que editó el documento:',
        ru: 'Пользователь, редактировавший документ:',
        rs: 'Korisnik koji je uredio dokument:',
        ua: 'Користувач, який відредагував документ:',
        no: 'Brukeren som har gjort endringene i dokumentet:',
        it: "L'utente che ha modificato il documento:",
        se: 'Användaren som har gjort ändringar i dokumentet:',
    },
    saveErrorTitle: {
        us: 'Automatic save failed',
        cz: 'Automatické uložení selhalo',
        pl: 'Automatyczne zapisywanie się nie powiodło',
        hr: 'Automatsko spremanje nije uspjelo',
        si: 'Avtomatsko shranjevanje ni uspelo',
        de: 'Automatisches Speichern fehlgeschlagen',
        sk: 'Automatické uloženie zlyhalo',
        es: 'Falló el guardado automático',
        ru: 'Автоматическое сохранение не удалось',
        rs: 'Automatsko čuvanje nije uspelo',
        ua: 'Автоматичне збереження не вдалося',
        no: 'Automatisk lagring mislyktes',
        it: 'Salvataggio automatico fallito',
        se: 'Ej sparat automatiskt',
    },
    checkInternetConnection: {
        us: 'Please check your internet connection and try editing to save the project again.',
        cz: 'Prosím zkontrolujte své internetové připojení a proveďte editaci k opětovnému uložení projektu.',
        pl: 'Sprawdź połączenie internetowe i spróbuj edytować, aby ponownie zapisać projekt.',
        hr: 'Provjerite svoju internetsku vezu i pokušajte urediti kako biste ponovno spremili projekt.',
        si: 'Preverite svojo internetno povezavo in poskusite urediti, da bi znova shranili projekt.',
        de: 'Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie erneut, das Projekt zu speichern.',
        sk: 'Skontrolujte svoje internetové pripojenie a urobte editáciu pre opätovné uloženie projektu.',
        es: 'Verifique su conexión a Internet e intente editar para guardar el proyecto nuevamente.',
        ru: 'Пожалуйста проверьте свое подключение к интернету и проведите редактирование для повторного сохранения проекта.',
        rs: 'Proverite svoju vezu sa internetom i pokušajte da uredite kako biste ponovo sačuvali projekat.',
        ua: 'Будь ласка, перевірте своє підключення до інтернету та проведіть редагування для повторного збереження проекту.',
        no: 'Vennligst kontroller Internett-tilkobling og prøv å redigere prosjektet for å lagre det på nytt.',
        it: 'Controllare la connessione a Internet ed eseguire una modifica per salvare nuovamente il progetto.',
        se: 'Vänligen kontrollera din internetanslutning och prova att redigera projektet för att spara det igen.',
    },
    saveFailedRefresh: {
        us: 'The document could not be saved. If the issue persists, please refresh the page. Error info: ',
        cz: 'Nepodařilo se uložit dokument. Pokud problém přetrvává, obnovte stránku. Informace o chybě: ',
        pl: 'Nie udało się zapisać dokumentu. Jeśli problem będzie się powtarzał, odśwież stronę. Informacje o błędzie: ',
        hr: 'Dokument se nije mogao spremiti. Ako se problem nastavi, osvježite stranicu.  Podaci o pogrešci: ',
        si: 'Dokumenta ni bilo mogoče shraniti. Če se težava ponovi, osvežite stran. Podatki o napaki: ',
        de: 'Das Dokument konnte nicht gespeichert werden. Wenn das Problem weiterhin besteht, aktualisieren Sie bitte die Seite. Fehler-Info: ',
        sk: 'Nepodarilo sa uložiť dokument. Ak problém pretrváva, obnovte stránku. Informácie o chybe: ',
        es: 'No se pudo guardar el documento. Si el problema persiste, actualice la página. Información de error: ',
        ru: 'Не удалось сохранить документ. Если проблема не исчезнет, ​​обновите страницу. Информация об ошибке: ',
        rs: 'Dokument nije mogao da se sačuva. Ako se problem nastavi, osvežite stranicu. Podaci o grešci: ',
        ua: 'Не вдалося зберегти документ. Якщо проблема не зникне, оновіть сторінку. Інформація про помилку: ',
        no: 'Prosjektet kan ikke lagres. Hvis problemet vedvarer, vennligst oppdater denne sida. Feilinformasjon: ',
        it: "Impossibile salvare il documento. Se il problema persiste, aggiorni la pagina. Informazioni sull'errore: ",
        se: 'Det gick inte att spara dokumentet. Om problemet kvarstår, vänligen uppdatera webbsidan. Information om felet: ',
    },
    project: {
        us: 'Project',
        cz: 'Projekt',
        pl: 'Projekt',
        hr: 'Projekt',
        si: 'Projekt',
        de: 'Projekt',
        sk: 'Projekt',
        es: 'Proyecto',
        ru: 'Проект',
        rs: 'Projekat',
        ua: 'Проект',
        no: 'Prosjekt',
        it: 'Progetto',
        se: 'Projekt',
    },
    download: {
        us: 'Download',
        cz: 'Stahování',
        pl: 'Pobieranie',
        hr: 'Preuzimanje',
        si: 'Prenos',
        de: 'Download',
        sk: 'Sťahovanie',
        es: 'Descargar',
        ru: 'Скачать',
        rs: 'Preuzmanje',
        ua: 'Завантажити',
        no: 'Nedlasting',
        it: 'Scaricare',
        se: 'Nedladdning',
    },
    downloading: {
        us: 'Downloading',
        cz: 'Stahování',
        pl: 'Pobieranie',
        hr: 'Preuzimanje',
        si: 'Prenašanje',
        de: 'Herunterladen',
        sk: 'Sťahovanie',
        es: 'Descargando',
        ru: 'Скачивание',
        rs: 'Preuzimanje',
        ua: 'Завантаженя',
        no: 'Laster ned',
        it: 'Scaricare',
        se: 'Hämtar',
    },
    document: {
        us: 'Document',
        cz: 'Dokument',
        pl: 'Dokument',
        hr: 'Dokument',
        si: 'Dokument',
        de: 'Dokument',
        sk: 'Dokument',
        es: 'Documento',
        ru: 'Документ',
        rs: 'Dokument',
        ua: 'Документ',
        no: 'Dokument',
        it: 'Documento',
        se: 'Dokument',
    },
    advanced: {
        us: 'Advanced',
        cz: 'Pokročilé',
        pl: 'Zaawansowane',
        hr: 'Napredno',
        si: 'Napredno',
        de: 'Erweitert',
        sk: 'Pokročilé',
        es: 'Avanzado',
        ru: 'Продвинутый',
        rs: 'Napredno',
        ua: 'Просунутий',
        no: 'Avansert',
        it: 'Avanzato',
        se: 'Avanserat',
    },
    noTranscription: {
        us: 'Transcription is not available',
        cz: 'Přepis není k dispozici',
        pl: 'Transkrypcja nie jest dostępna',
        hr: 'Transkripcija nije dostupna',
        si: 'Prepis ni na voljo',
        de: 'Transkription ist nicht verfügbar',
        sk: 'Prepis nie je k dispozícii',
        es: 'La transcripción no está disponible',
        ru: 'Транскрипция недоступна',
        rs: 'Transkripcija nije dostupna',
        ua: 'Транскрипція недоступна',
        no: 'Transkripsjon ikke tilgjengelig',
        it: 'Trascrizione non disponibile',
        se: 'Transkription ej tillgänglig',
    },
    preMediaUpload: {
        us: 'Wait for the media to upload',
        cz: 'Počkejte na nahrání média',
        pl: 'Poczekaj na dokończenie wczytywania pliku multimedialnego',
        hr: 'Pričekajte da se mediji prenesu',
        si: 'Počakajte, da se mediji prenesejo',
        de: 'Warten Sie, bis die Medien hochgeladen sind',
        sk: 'Počkajte na nahranie médiá',
        es: 'Espere a que se carguen los medios',
        ru: 'Подождите загрузки медиафайла',
        rs: 'Sačekajte da se medij otpremi',
        ua: 'Почекайте завантаження медіафайлу',
        no: 'Vent til mediet er lastet opp',
        it: 'Aspetti che il file venga caricato',
        se: 'Vänta tills media har laddats upp',
    },
    preCompleted: {
        us: 'Wait for the transcription to finish',
        cz: 'Počkejte na dokončení přepisu',
        pl: 'Poczekaj na dokończenie transkrypcji',
        hr: 'Pričekajte da završi transkripcija',
        si: 'Počakajte, da se prepis zaključi',
        de: 'Warten Sie, bis die Transkription abgeschlossen ist',
        sk: 'Počkajte na dokončenie prepisu',
        es: 'Espere a que termine la transcripción',
        ru: 'Дождитесь завершения транскрибирования',
        rs: 'Sačekajte da se transkripcija završi',
        ua: 'Дочекайтеся завершення транскрибування',
        no: 'Vent til transkripsjonen er ferdig',
        it: 'Aspetti il completamento della trascrizione',
        se: 'Vänta tills transkriptionen är färdig',
    },
    projectUnavailable: {
        us: 'Unavailable, upload the project again',
        cz: 'Nedostupné, nahrajte projekt znovu',
        pl: 'Niedostępne, prześlij projekt ponownie',
        hr: 'Nije dostupno, ponovno prenesite projekt',
        si: 'Ni na voljo, znova naložite projekt',
        de: 'Nicht verfügbar, laden Sie das Projekt erneut hoch',
        sk: 'Nedostupné, nahrajte projekt znova',
        es: 'No disponible, suba el proyecto de nuevo',
        ru: 'Недоступно, загрузите проект повторно',
        rs: 'Nije dostupno, ponovno prenesite projekat',
        ua: 'Недоступно, завантажте проект повторно',
        no: 'Utilgjengelig, last opp prosjektet igjen',
        it: 'Non disponibile, carichi nuovamente il progetto',
        se: 'Otillgängligt, ladda upp projektet igen',
    },
    waitUntilSaved: {
        us: 'Wait until changes in editor are saved...',
        cz: 'Počkejte, než se změny v editoru uloží...',
        pl: 'Poczekaj, aż zmiany w edytorze zostaną zapisane...',
        hr: 'Pričekajte pohranu promjena u editoru...',
        si: 'Počakajte, da se spremembe v urejevalniku shranijo...',
        de: 'Warten Sie, bis die Änderungen im Editor gespeichert sind...',
        sk: 'Počkajte, kým sa zmeny uložia do editora...',
        es: 'Espere hasta que se guarden los cambios en el editor...',
        ru: 'Подождите, пока изменения в редакторе будут сохранены...',
        rs: 'Sačekajte čuvanje promena u editoru...',
        ua: 'Зачекайте, доки зміни в редакторі будуть збережені...',
        no: 'Vent til endringene i editoren er lagret...',
        it: "Aspetti che le modifiche vengano salvate nell'editor...",
        se: 'Vänta tills ändringarna i editorn har sparats...',
    },
    subtitlesExportForbidden: {
        us: 'Subtitle export is not allowed, contact our support at support@beey.io',
        cz: 'Export titulků není povolen, kontaktujte podporu na support@beey.io',
        pl: 'Eksport napisów jest niedozwolony, skontaktuj się z pomocą techniczną na support@beey.io',
        hr: 'Izvoz titlova nije dopušten, kontaktirajte s našom podrškom na support@beey.io',
        si: 'Izvoz podnapisov ni dovoljen, kontaktirajte našo podporo na support@beey.io',
        de: 'Export von Untertiteln ist nicht erlaubt, wenden Sie sich an unseren Support unter support@beey.io',
        sk: 'Exportovanie titulkov nie je povolené, kontaktujte podporu na support@beey.io',
        es: 'No se permite la exportación de subtítulos, póngase en contacto con nuestro soporte en support@beey.io',
        ru: 'Экспорт субтитров не разрешен, свяжитесь с нашей службой поддержки по ссылке support@beey.io',
        rs: 'Izvoz titlova nije dopušten, kontaktirajte našu podršku na support@beey.io',
        ua: "Експорт субтитрів не дозволено, зв'яжіться з нашою службою підтримки за посиланням support@beey.io",
        no: 'Underteksteksport er ikke tillatt. Ta kontakt med brukerstøtten på support@beey.io',
        it: "L'esportazione dei sottotitoli non è consentita, contatti il supporto all'indirizzo support@beey.io",
        se: 'Det är inte tillåtet att exportera undertexter. Kontakta vår kundservice på support@beey.io',
    },
    preMediaReady: {
        us: 'Wait for the media to be processed',
        cz: 'Počkejte na zpracování media',
        pl: 'Poczekaj na przetworzenie mediów',
        hr: 'Pričekajte na obradu medija',
        si: 'Počakajte, da se medij obdela',
        de: 'Warten Sie auf die Verarbeitung der Medien',
        sk: 'Počkajte na spracovanie média',
        es: 'Espere a que se procesen los medios',
        ru: 'Дождитесь завершения обработки медиа',
        rs: 'Pričekajte na obradu medija',
        ua: 'Дочекайтеся завершення обробки медіа',
        no: 'Vent til mediet er behandlet',
        it: "Aspetti l'elaborazione dei media",
        se: 'Vänta tills media har bearbetats',
    },
    on: {
        us: 'On',
        cz: 'Zapnuto',
        pl: 'Włączone',
        hr: 'Uključeno',
        si: 'Vključeno',
        de: 'Ein',
        sk: 'Zapnuté',
        es: 'Encendido',
        ru: 'Включено',
        rs: 'Uključeno',
        ua: 'Увімкнено',
        no: 'Aktivert',
        it: 'Acceso',
        se: 'Aktiverat',
    },
    off: {
        us: 'Off',
        cz: 'Vypnuto',
        pl: 'Wyłączone',
        hr: 'Isključeno',
        si: 'Izključeno',
        de: 'Aus',
        sk: 'Vypnuté',
        es: 'Apagado',
        ru: 'Выключено',
        rs: 'Isključeno',
        ua: 'Вимкнено',
        no: 'Deaktivert',
        it: 'Spento',
        se: 'Inaktiverat',
    },
    turnOffSubtitleRevision: {
        us: 'Turn off subtitle revision?',
        cz: 'Vypnout revizi titulků?',
        pl: 'Wyłącz reweizję napisów?',
        hr: 'Isključiti provjeru titlova?',
        si: 'Izklopiti preverjanje podnapisov?',
        de: 'Untertitelrevision ausschalten?',
        sk: 'Vypnúť revíziu titulkov?',
        es: '¿Desactivar la revisión de subtítulos?',
        ru: 'Выключить проверку субтитров?',
        rs: 'Isključite proveru titlova?',
        ua: 'Вимкнути перевірку субтитрів?',
        no: 'Deaktivere undertekstkorrektur?',
        it: 'Disattivare la revisione dei sottotitoli?',
        se: 'Inaktivera korrekturen av undertexter?',
    },
    subtitleRevisedHead: {
        us: 'Revised subtitles will be generated',
        cz: 'Generují se revidované titulky',
        pl: 'Zostaną wygenerowane zrewidowane napisy',
        hr: 'Provjereni titlovi će se kreirati',
        si: 'Ustvarjeni bodo preverjeni podnapisi',
        de: 'Überarbeitete Untertitel werden generiert',
        sk: 'Revidované titulky sa exportujú',
        es: 'Se generarán subtítulos revisados',
        ru: 'Проверенные субтитры будут сгенерированы',
        rs: 'Provereni titlovi će se kreirati',
        ua: 'Перевірені субтитри будуть згенеровані',
        no: 'De reviderte undertekstene vil bli generert',
        it: 'Verranno generati sottotitoli rivisti',
        se: 'Reviderade undertexter genereras',
    },
    subtitlesAndPreviewDiffer: {
        us: 'Error: Downloaded subtitles differ from preview. Download anyway?',
        cz: 'Chyba: Stažené titulky se liší od náhledu. Přesto stáhnout?',
        pl: 'Błąd: pobrane napisy różnią się od podglądu. Pobrać mimo to?',
        hr: 'Greška: Preuzeti titlovi se razlikuju od pregleda. Svejedno preuzeti?',
        si: 'Napaka: Preneseni podnapisi se razlikujejo od predogleda. Vseeno prenesti?',
        de: 'Fehler: Heruntergeladene Untertitel weichen von der Vorschau ab. Trotzdem herunterladen?',
        sk: 'Chyba: Stiahnuté titulky sa líšia od náhľadu. Stiahnuť napriek tomu?',
        es: 'Error: los subtítulos descargados difieren de la vista previa. ¿Descargar de todos modos?',
        ru: 'Ошибка: Скаченные субтитры отличаются от предварительного просмотра. Все равно скачать?',
        rs: 'Greška: Preuzeti titlovi se razlikuju od pregleda. Svejedno preuzeti?',
        ua: 'Помилка: Завантажені субтитри відрізняються від попереднього перегляду. Все одно завантажити?',
        no: 'Feil: Nedlastet undertekst er forskjellig fra fremvisningen. Laste ned likevel?',
        it: "Errore: i sottotitoli scaricati differiscono dall'anteprima. Continuare lo scaricamento?",
        se: 'Fel: De nedladdade undertexterna skiljer sig från förhandsgranskningen. Hämta ändå?',
    },
    subtitleExportMessageError: {
        us: 'Failed to generate subtitles',
        cz: 'Nepodařilo se vygenerovat titulky',
        pl: 'Nie udało się wygenerować napisów',
        hr: 'Kreiranje titlova nije uspjelo',
        si: 'Ustvarjanje podnapisov ni uspelo',
        de: 'Erzeugung von Untertiteln fehlgeschlagen',
        sk: 'Nepodarilo sa vygenerovať titulky',
        es: 'No se pudo generar subtítulos',
        ru: 'Не удалось сгенерировать субтитры',
        rs: 'Kreiranje titlova nije uspelo',
        ua: 'Не вдалося згенерувати субтитри',
        no: 'Klarte ikke å generere undertekster',
        it: 'Impossibile generare i sottotitoli',
        se: 'Det gick inte att generera undertexter',
    },
    subtitleAutomaticHead: {
        us: 'Download transcription as automatic subtitles',
        cz: 'Stáhnout přepis jako automatické titulky',
        pl: 'Pobierz transkrypcję jako napisy wygenerowane automatycznie',
        hr: 'Preuzmite transkripciju kao automatske titlove',
        si: 'Prenesite transkripcijo kot samodejne podnapise',
        de: 'Transkription als automatische Untertitel herunterladen',
        sk: 'Stiahnuť prepis ako automatické titulky',
        es: 'Descargar transcripción como subtítulos automáticos',
        ru: 'Скачать транскрибцию в формате субтитров',
        rs: 'Preuzmi transkripciju kao automatske titlove',
        ua: 'Завантажити транскрипцію як автоматичні субтитри',
        no: 'Laste ned som automatiske undertekster',
        it: 'Scaricare la trascrizione come sottotitoli automatici',
        se: 'Ladda ned transkriptionen i form av automatiska undertexter',
    },
    includeColorAndPosition: {
        us: 'Generate with formatting (color, position, font, background) as well',
        cz: 'Generovat i s formátováním (barva, pozice, font, pozadí)',
        pl: 'Generuj z formatowaniem (kolor, położenie, czcionka, tło)',
        hr: 'Generirajte i s formatiranjem (boja, položaj, font, pozadina)',
        si: 'Ustvarite tudi z oblikovanjem (barva, položaj, pisava, ozadje)',
        de: 'Generieren Sie auch mit Formatierung (Farbe, Position, Schriftart, Hintergrund)',
        sk: 'Generovanie s formátovaním (farba, pozícia, písmo, pozadie)',
        es: 'Generar también con formato (color, posición, fuente, fondo)',
        ru: 'Генерировать также с форматированием (цвет, положение, шрифт, фон)',
        rs: 'Generiši sa formatiranjem (boja, pozicija, font, pozadina)',
        ua: 'Згенеруйте також з форматуванням (колір, положення, шрифт, фон)',
        no: 'Generere formatert (med farge, posisjon, skrift, bakgrunn)',
        it: 'Generare con formattazione (colore, posizione, carattere, sfondo)',
        se: 'Generera inkl. formatering (färg, ställning, font, bakgrund)',
    },
    noFormatting: {
        us: "Facebook doesn't support formatting of srt subtitles",
        cz: 'Facebook nepodporuje formátování srt titulků',
        pl: 'Facebook nie obsługuje formatowanych napisów srt',
        hr: 'Facebook ne podržava formatiranje srt titlova',
        si: 'Facebook ne podpira oblikovanja srt podnapisov',
        de: 'Die Formatierung von srt-Untertiteln wird von Facebook nicht unterstützt',
        sk: 'Facebook nepodporuje formátovanie srt titulkov',
        es: 'Facebook no admite el formateo de subtítulos srt',
        ru: 'Facebook не поддерживает форматирование srt-субтитров',
        rs: 'Facebook ne podržava formatiranje srt titlova',
        ua: 'Facebook не підтримує форматування субтитрів srt',
        no: 'Facebook støtter ikke formaterte srt undertekster',
        it: 'Facebook non supporta la formattazione dei sottotitoli srt',
        se: 'Facebook stödjer inte formatering av srt-undertexter',
    },
    speedCriticalWarn: {
        us: 'Critical speed warning:',
        cz: 'Kritické varování rychlosti:',
        pl: 'Ostrzeżenie o krytycznej prędkości:',
        hr: 'Upozorenje na kritičnu brzinu:',
        si: 'Opozorilo o kritični hitrosti:',
        de: 'Warnung vor kritischer Geschwindigkeit:',
        sk: 'Kritické varovanie rýchlosti:',
        es: 'Advertencia de velocidad crítica:',
        ru: 'Предупреждение о критической скорости:',
        rs: 'Upozorenje na kritičnu brzinu:',
        ua: 'Попередження про критичну швидкість:',
        no: 'Kritisk fartsvarsling:',
        it: 'Avviso di velocità critica:',
        se: 'Varning för kritisk hastighet:',
    },
    speedCriticalWarnDescription: {
        us: 'Subtitles faster than this value will be displayed with yellow background.',
        cz: 'Titulky s rychlostí vyšší než tato hodnota budou zobrazeny se žlutým pozadím.',
        pl: 'Napisy z prędkością wyższą niż ta wartość będą wyświetlane z żółtym tłem.',
        hr: 'Titlovi koji su brži od ove vrijednosti prikazat će se sa žutom pozadinom.',
        si: 'Podnapisi, ki so hitrejši od te vrednosti, bodo prikazani z rumenim ozadjem.',
        de: 'Untertitel, die schneller als dieser Wert sind, werden mit gelbem Hintergrund angezeigt.',
        sk: 'Titulky s rýchlosťou vyššou ako táto hodnota sa zobrazia so žltým pozadím.',
        es: 'Los subtítulos que sean más rápidos que este valor se mostrarán con un fondo amarillo.',
        ru: 'Субтитры, которые быстрее этого значения, отображаются на желтом фоне.',
        rs: 'Titlovi koji su brži od ove vrednosti biće prikazani sa žutom pozadinom.',
        ua: 'Субтитри, які швидші за це значення, відображатимуться на жовтому фоні.',
        no: 'Undertekster som er fortere enn denne verdien vil bli markert med gul bakgrunn.',
        it: 'I sottotitoli con una velocità superiore a questo valore saranno visualizzati con uno sfondo giallo.',
        se: 'Undertexter vars hastighet överstiger detta värde markeras med gul bakgrund.',
    },
    speedWarn: {
        us: 'Speed warning:',
        cz: 'Varování rychlosti:',
        pl: 'Ostrzeżenie o prędkości:',
        hr: 'Upozorenje na brzinu:',
        si: 'Opozorilo o hitrosti:',
        de: 'Geschwindigkeitswarnung:',
        sk: 'Varovanie rýchlosti:',
        es: 'Advertencia de velocidad:',
        ru: 'Предупреждение о скорости:',
        rs: 'Upozorenje na brzinu:',
        ua: 'Попередження про швидкість:',
        no: 'Fartsvarsling:',
        it: 'Avviso di velocità:',
        se: 'Hastighetsvarning:',
    },
    speedWarnDescription: {
        us: 'Subtitles faster than this value will be displayed with light yellow background.',
        cz: 'Titulky s rychlostí vyšší než tato hodnota budou zobrazeny se světle žlutým pozadím.',
        pl: 'Napisy z prędkością wyższą niż ta wartość będą wyświetlane na jasnożółtym tle.',
        hr: 'Titlovi koji su brži od ove vrijednosti prikazat će se sa svijetložutom pozadinom.',
        si: 'Podnapisi, ki so hitrejši od te vrednosti, bodo prikazani s svetlo rumenim ozadjem.',
        de: 'Untertitel, die schneller als dieser Wert sind, werden mit hellgelbem Hintergrund angezeigt.',
        sk: 'Titulky s rýchlosťou vyššou ako táto hodnota sa zobrazia so svetložltým pozadím.',
        es: 'Los subtítulos que sean más rápidos que este valor se mostrarán con un fondo amarillo claro.',
        ru: 'Субтитры, которые работают быстрее, чем это значение, отображаются на светло-желтом фоне.',
        rs: 'Titlovi koji su brži od ove vrednosti biće prikazani sa svetlo-žutom pozadinom.',
        ua: 'Субтитри, які працюють швидше, ніж це значення, відображатимуться на світло-жовтому фоні.',
        no: 'Undertekster som er fortere enn denne verdien vil bli markert med lysegul bakgrunn.',
        it: 'I sottotitoli con una velocità superiore a questo valore saranno visualizzati con uno sfondo giallo chiaro.',
        se: 'Undertexter vars hastighet överstiger detta värde markeras med ljusgul bakgrund.',
    },
    automaticSpeed: {
        us: 'Automatic subtitle speed:',
        cz: 'Automatická rychlost titulků:',
        pl: 'Automatyczna prędkość napisów:',
        hr: 'Brzina automatskog titla:',
        si: 'Avtomatska hitrost podnapisa:',
        de: 'Automatische Untertitelgeschwindigkeit:',
        sk: 'Automatická rýchlosť titulkov:',
        es: 'Velocidad de subtítulos automática:',
        ru: 'Автоматическая скорость субтитров:',
        rs: 'Automatska brzina titla:',
        ua: 'Автоматична швидкість субтитрів:',
        no: 'Automatisk undertekstfart:',
        it: 'Velocità automatica dei sottotitoli:',
        se: 'Automatisk undertexthastighet:',
    },
    automaticSpeedDescription: {
        us: 'Subtitles will be expanded so that their speed decreases to this value.',
        cz: 'Titulky budou roztaženy tolik, aby se jejich rychlost snížila na tuto hodnotu.',
        pl: 'Napisy zostaną rozciągnięte tak, aby ich prędkość została dostosowana do podanej wartości.',
        hr: 'Titlovi će se proširiti tako da se njihova brzina smanji na tu vrijednost.',
        si: 'Podnapisi bodo razširjeni tako, da se njihova hitrost zmanjša na to vrednost.',
        de: 'Untertitel werden so erweitert, dass ihre Geschwindigkeit auf diesen Wert sinkt.',
        sk: 'Titulky sa natiahnu natoľko, aby sa ich rýchlosť znížila na túto hodnotu.',
        es: 'Los subtítulos se expandirán para que su velocidad disminuya a este valor.',
        ru: 'Субтитры будут развернуты так, чтобы их скорость снизилась до этого значения.',
        rs: 'Titlovi će biti prošireni tako da se njihova brzina smanji na ovu vrednost.',
        ua: 'Субтитри будуть розгорнуті так, щоб їх швидкість зменшилась до цього значення.',
        no: 'Undertekster skal bres ut slik at hastigheten senker til denne verdien.',
        it: 'I sottotitoli saranno allungati abbastanza da ridurre la loro velocità a questo valore.',
        se: 'Undertexterna kommer att visas längre så att deras hastighet sjunker till detta värde.',
    },
    speedWarnHigh: {
        us: 'Speed warning must be lower (less strict) than critical speed warning.',
        cz: 'Varování rychlosti musí být nižší (méně přísné) než kritické varování rychlosti.',
        pl: 'Ostrzeżenie o prędkości musi być niższe (mniej rygorystyczne) niż ostrzeżenie o prędkości krytycznej.',
        hr: 'Upozorenje o brzini mora biti niže (manje strogo) od upozorenja o kritičnoj brzini.',
        si: 'Opozorilo o hitrosti mora biti nižje (manj strogo) od kritičnega opozorila o hitrosti.',
        de: 'Die Geschwindigkeitswarnung muss niedriger sein als die Warnung vor kritischer Geschwindigkeit.',
        sk: 'Upozornenie na rýchlosť musí byť nižšie (menej prísne) ako upozornenie na kritickú rýchlosť.',
        es: 'La advertencia de velocidad debe ser más baja (menos estricta) que la advertencia de velocidad crítica.',
        ru: 'Предупреждение о скорости должно быть ниже (менее строгим), чем предупреждение о критической скорости.',
        rs: 'Upozorenje o brzini mora biti niže (manje strogo) od upozorenja o kritičnoj brzini.',
        ua: 'Попередження про швидкість має бути нижчим (менш суворим), ніж попередження про критичну швидкість.',
        no: 'Fartvarsel må være lavere (mindre streng) enn kritisk fartvarsel.',
        it: "L'avviso di velocità deve essere inferiore (meno rigido) rispetto all'avviso di velocità critica.",
        se: 'Hastighetsvarning måste ange ett lägre värde än varningen för kritisk hastighet.',
    },
    automaticSpeedHigh: {
        us: 'Automatic speed must be lower than speed warning',
        cz: 'Automatická rychlost musí být nižší než varování rychlosti',
        pl: 'Wartość prędkości automatycznej powinna być niższa niż wartość ostrzeżenia o prędkości',
        hr: 'Automatska brzina mora biti manja od upozorenja na brzinu',
        si: 'Avtomatska hitrost mora biti nižja od opozorila o hitrosti',
        de: 'Automatische Geschwindigkeit muss niedriger sein als die Geschwindigkeitswarnung',
        sk: 'Automatická rýchlosť musí byť nižšia ako varovanie rýchlosti',
        es: 'La velocidad automática debe ser más baja que la advertencia de velocidad',
        ru: 'Автоматическая скорость должна быть ниже предупреждения о скорости',
        rs: 'Automatska brzina mora biti manja od upozorenja o brzini',
        ua: 'Автоматична швидкість має бути нижчою за попередження про швидкість',
        no: 'Automatisk hastighet må være lavere enn fartvarsel',
        it: "La velocità automatica deve essere inferiore all'avviso di velocità",
        se: 'Automatisk hastighet måste vara lägre än den som nämns i hastighetsvarning',
    },
    range: {
        us: 'Range:',
        cz: 'Rozmezí:',
        pl: 'Zasięg:',
        hr: 'Raspon:',
        si: 'Razpon:',
        de: 'Intervall:',
        sk: 'Rozsah:',
        es: 'Intervalo:',
        ru: 'Интервалы:',
        rs: 'Raspon:',
        ua: 'Інтервали:',
        no: 'Intervall:',
        it: 'Fascia:',
        se: 'Intervall:',
    },
    minDuration: {
        us: 'Minimum subtitle duration:',
        cz: 'Minimální trvání titulku:',
        pl: 'Minimalny czas trwania napisów:',
        hr: 'Minimalno trajanje titla:',
        si: 'Minimalno trajanje podnapisov:',
        de: 'Minimale Untertiteldauer:',
        sk: 'Minimálne trvanie titulku:',
        es: 'Duración mínima de los subtítulos:',
        ru: 'Минимальная длительность субтитров:',
        rs: 'Minimalno trajanje titla:',
        ua: 'Мінімальна тривалість субтитрів:',
        no: 'Minimum undertekstvarighet:',
        it: 'Durata minima della didascalia:',
        se: 'Minsta visningstid av undertexten:',
    },
    maxLineLengthDescription: {
        us: 'Subtitles will be automatically created to meet this limit.',
        cz: 'Titulky budou automaticky vytvořeny tak, aby splňovaly tento limit.',
        pl: 'Napisy zostaną utworzone automatycznie tak, aby zmieściły się w tym limicie.',
        hr: 'Titlovi će se automatski kreirati kako bi se zadovoljilo ovo ograničenje.',
        si: 'Podnapisi bodo samodejno ustvarjeni za izpolnitev te omejitve.',
        de: 'Die Untertitel werden automatisch erstellt, um diese Grenze einzuhalten.',
        sk: 'Titulky sa automaticky vytvoria tak, aby spĺňali tento limit.',
        es: 'Los subtítulos se crearán automáticamente para cumplir con este límite.',
        ru: 'Субтитры будут автоматически созданы в соответствии с этим ограничением.',
        rs: 'Titlovi će se automatski kreirati da bi se ispunilo ovo ograničenje.',
        ua: 'Субтитри будуть автоматично створені відповідно до цього обмеження.',
        no: 'Undertekster vil automatisk bli laget slik at de oppfyller grensen.',
        it: 'I sottotitoli verranno creati automaticamente per rispettare questo limite.',
        se: 'Undertexter skapas automatiskt så att de uppfyller detta gränsvärde.',
    },
    minDurationDescription: {
        us: 'Short subtitles will be extended so that their duration increases to this value.',
        cz: 'Rychlé titulky budou prodlouženy tolik, aby se jejich trvání zvýšilo na tuto hodnotu.',
        pl: 'Szybkie napisy zostaną wydłużone o tyle, aby ich czas trwania wynosił tyle, ile wynosi ta wartość.',
        hr: 'Kratki titlovi će se produljiti tako da se njihovo trajanje poveća na ovu vrijednost.',
        si: 'Kratki podnapisi se bodo podaljšali tako, da se bo njihovo trajanje povečalo na to vrednost.',
        de: 'Kurze Untertitel werden so verlängert, dass sich ihre Dauer auf diesen Wert erhöht.',
        sk: 'Rýchle titulky sa predĺžia o toľko, aby sa ich trvanie zvýšilo na túto hodnotu.',
        es: 'Los subtítulos cortos se ampliarán para que su duración aumente hasta este valor.',
        ru: 'Короткие субтитры будут расширены, чтобы их продолжительность увеличилась до этого.',
        rs: 'Kratki titlovi će biti produženi tako da se njihovo trajanje povećava na ovu vrednost.',
        ua: 'Короткі субтитри будуть розширені, щоб їх тривалість збільшилася до цього значення.',
        no: 'Korte undertekster vil forlenges slik at varigheten oppfyller denne grensen.',
        it: 'I sottotitoli veloci saranno estesi per aumentare la loro durata a questo valore.',
        se: 'Korta undertexter kommer att förlängas så att deras visningstid uppnår detta värde.',
    },
    seconds: {
        us: 'seconds',
        cz: 'sekundy',
        pl: 'sekundy',
        hr: 'sekundi',
        si: 'sekund',
        de: 'Sekunden',
        sk: 'sekundy',
        es: 'segundos',
        ru: 'секунды',
        rs: 'sekundi',
        ua: 'секунди',
        no: 'sekunder',
        it: 'secondi',
        se: 'sekunder',
    },
    changeSpeakerIndicator: {
        us: 'Speaker change indicator:',
        cz: 'Indikace změny mluvčího:',
        pl: 'Wskaźnik zmiany mówcy:',
        hr: 'Indikator promjene govornika:',
        si: 'Indikator zamenjave govorca:',
        de: 'Anzeige für Sprecherwechsel:',
        sk: 'Indikácia zmeny rečníka:',
        es: 'Indicador de cambio de hablante:',
        ru: 'Обозначение смены говорящего:',
        rs: 'Indikator promene govornika:',
        ua: 'Позначення зміни того, хто говорить:',
        no: 'Indikator av endring hos talere:',
        it: 'Indicazione del cambio di speaker:',
        se: 'Indikator för talarbyte:',
    },
    hyphen: {
        us: 'Hyphen',
        cz: 'Pomlčka',
        pl: 'Myślnik',
        hr: 'Crtica',
        si: 'Vezaj',
        de: 'Bindestrich',
        sk: 'Pomlčka',
        es: 'Guión',
        ru: 'Дефис',
        rs: 'Crtica',
        ua: 'Дефіс',
        no: 'Tankestrek',
        it: 'Trattino',
        se: 'Bindestreck',
    },
    none: {
        us: 'None',
        cz: 'Žádná',
        pl: 'Żaden',
        hr: 'Ništa',
        si: 'Nič',
        de: 'Nichts',
        sk: 'Žiadna',
        es: 'Ninguno',
        ru: 'Никто',
        rs: 'Ništa',
        ua: 'Ніхто',
        no: 'Ingen',
        it: 'Nessuna',
        se: 'Inget',
    },
    noPlacement: {
        us: 'None',
        cz: 'Žádné',
        pl: 'Żaden',
        hr: 'Ništa',
        si: 'Nič',
        de: 'Nichts',
        sk: 'Žiadned',
        es: 'Ninguno',
        ru: 'Никто',
        rs: 'Ništa',
        ua: 'Ніхто',
        no: 'Ingen',
        it: 'Nessuno',
        se: 'Inget',
    },
    nameRequiresSign: {
        us: 'Speaker sign placement must be other than "none" if this is enabled.',
        cz: 'Umístění značek mluvčího musí být jiné než "žádné" pokud je toto zapnuté.',
        pl: 'Jeśli ta funkcja jest aktywna, wskaźnik zmiany mówcy powinien mieć status inny niż "Żaden".',
        hr: 'Položaj znaka govornika mora biti različit od "ništa" ako je to omogućeno.',
        si: 'Postavitev znaka govorca mora biti drugačna od "nič", če je to omogočeno.',
        de: 'Wenn diese Option aktiviert ist, muss die Platzierung des Sprecherzeichens anders als "keine" sein.',
        sk: 'Ak je táto možnosť povolená, umiestnenie značiek rečníka musí byť iné ako "žiadne".',
        es: 'La colocación de la marca del hablante debe ser distinta de "ninguno" si está activado.',
        ru: 'Если эта функция включена, размещение тегов динамиков должно быть отличным от "none".',
        rs: 'Postavljanje znaka za govornika mora biti drugačije od "nijedno" ako je to omogućeno.',
        ua: 'Розміщення тегів спікера має бути відмінним від "none", якщо цей параметр увімкнено.',
        no: 'Plassering av taler-tegnet må være annerledes enn "ingen" hvis denne er tillat.',
        it: 'Le posizioni dei tag degli altoparlanti devono essere diverse da "nessuno" se questa opzione è attivata.',
        se: 'Placering av talartecken får inte vara "ingen", om den är aktiverad.',
    },
    autofillPauseBetweenCaptions: {
        us: 'Automatically fill the gap between subtitles shorter than:',
        cz: 'Automaticky vyplnit mezeru mezi titulky kratší než:',
        pl: 'Automatycznie wypełnij lukę między napisami krótszymi niż:',
        hr: 'Automatski popunite razmak između titlova kraćih od:',
        si: 'Avtomatski dopolni razliko med podnapisi, krajšimi od:',
        de: 'Automatisches Füllen der Lücke zwischen Untertiteln kürzer als:',
        sk: 'Automaticky vyplniť priestor medzi titulkami kratšími ako:',
        es: 'Rellenar automáticamente el espacio entre los subtítulos más cortos que:',
        ru: 'Автоматически заполнять промежуток между субтитрами короче, чем:',
        rs: 'Automatski popuni razmak između titlova kraćih od:',
        ua: 'Автоматично заповнювати пробіл між субтитрами, коротшими за:',
        no: 'Automatisk fylle ut mellomrom mellom undertekster kortere enn:',
        it: 'Riempi automaticamente lo spazio tra i sottotitoli più corto di:',
        se: 'Fyll automatiskt mellanrum mellan undertexter på mindre än:',
    },
    autofillPauseBetweenCaptionsInfo: {
        us: 'If the gap between subtitles is smaller than the set value, then the gap will be automatically set to the value of the pause between subtitles.',
        cz: 'Pokud mezera mezi titulky je menší než nastavená hodnota, tak bude mezera automaticky nastavena na hodnotu pauzy mezi titulky.',
        pl: 'Jeśli odstęp między napisami jest mniejszy niż ustawiona wartość, zostanie on automatycznie ustawiony na wartość przerwy między napisami.',
        hr: 'Ako je razmak između titlova manji od postavljene vrijednosti, tada će se razmak automatski postaviti na vrijednost pauze između titlova.',
        si: 'Če je razlika med podnapisi manjša od nastavljene vrednosti, bo razlika avtomatski nastavljena na vrednost pavze med podnapisi.',
        de: 'Wenn die Lücke zwischen Untertiteln kleiner ist als der eingestellte Wert, wird die Lücke automatisch auf Wert der Pause zwischen Untertiteln gesetzt.',
        sk: 'Ak je medzera medzi titulkami menšia ako nastavená hodnota, medzera sa automaticky nastaví na hodnotu pauzy medzi titulkami.',
        es: 'Si el espacio entre los subtítulos sea menor que el valor establecido, el espacio se establecerá automáticamente en el valor de la pausa entre los subtítulos.',
        ru: 'Если промежуток между субтитрами меньше установленного значения, то промежуток будет автоматически установлен на величину паузы между субтитрами.',
        rs: 'Ako je razmak između titlova manji od podešene vrednosti, razmak će biti automatski podešen na vrednost pauze između titlova.',
        ua: 'Якщо проміжок між субтитрами менший за встановлене значення, то проміжок буде автоматично встановлено на значення паузи між субтитрами.',
        no: 'Hvis mellomrommet mellom undertekster blir mindre enn den fastsatte verdien, blir mellomrommet automatisk fastsatt til verdien av pause mellom undertekster.',
        it: 'Se lo spazio tra i sottotitoli è inferiore al valore impostato, lo spazio verrà automaticamente impostato al valore della pausa tra i sottotitoli.',
        se: 'Om mellanrummet mellan undertexterna är mindre än det inställda värdet, kommer mellanrummet automatiskt att ställas på värdet av pausen mellan undertexterna.',
    },
    pauseBetweenCaptions: {
        us: 'Pause between subtitles:',
        cz: 'Pauza mezi titulky:',
        pl: 'Przerwa między napisami:',
        hr: 'Pauza između titlova:',
        si: 'Pavza med podnapisi:',
        de: 'Pause zwischen Untertiteln:',
        sk: 'Pauza medzi titulkami:',
        es: 'Pausa entre subtítulos:',
        ru: 'Пауза между субтитрами:',
        rs: 'Pauza između titlova:',
        ua: 'Пауза між субтитрами:',
        no: 'Pause mellom undertekster:',
        it: 'Pausa tra i sottotitoli:',
        se: 'Paus mellan undertexter:',
    },
    spaceAtLineEnd: {
        us: 'Space at the end of a line:',
        cz: 'Mezera na konci řádku:',
        pl: 'Spacja na końcu wiersza:',
        hr: 'Razmak na kraju retka:',
        si: 'Presledek na koncu vrstice',
        de: 'Leerzeichen am Ende der Zeile:',
        sk: 'Medzera na konci riadku:',
        es: 'Espacio al final de una línea:',
        ru: 'Пробел в конце линии:',
        rs: 'Razmak na kraju reda:',
        ua: 'Пробіл в кінці лінії:',
        no: 'Mellomrom på slutten av linjen:',
        it: 'Spazio alla fine della riga:',
        se: 'Blanksteg i slutet av raden:',
    },
    useSpeakerName: {
        us: 'Insert speaker name into speaker sign:',
        cz: 'Vložit jméno ke značce nového mluvčího:',
        pl: 'Dodaj nazwę mówcy do znacznika nowego mówcy:',
        hr: 'Unesite ime govornika:',
        si: 'Vstavite ime govorca v znak govorca:',
        de: 'Name zum Sprechertag hinzufügen:',
        sk: 'Vložte meno na označenie nového rečníka:',
        es: 'Inserte el nombre del hablante en la marca del hablante:',
        ru: 'Вставьте имя спикера в табличку спикера:',
        rs: 'Unesite ime govornika u znak za govornika:',
        ua: "Вставте ім'я спікера в табличку спікера:",
        no: 'Sette inn et navn i taler-tegn:',
        it: 'Inserisca un nome per etichettare il nuovo diffusore:',
        se: 'Infoga talarens namn i talartecknet:',
    },
    dlTrsx: {
        us: 'Download trsx',
        cz: 'Stáhnout trsx',
        pl: 'Pobierz trsx',
        hr: 'Preuzmi trsx',
        si: 'Prenesi trsx',
        de: 'Trsx herunterladen',
        sk: 'Stiahnuť trsx',
        es: 'Descargar trsx',
        ru: 'Скачать trsx',
        rs: 'Preuzmite trsx',
        ua: 'Завантажити trsx',
        no: 'Laste ned trsx',
        it: 'Scarica trsx',
        se: 'Ladda ner trsx',
    },
    dlTxt: {
        us: 'Download txt',
        cz: 'Stáhnout txt',
        pl: 'Pobierz txt',
        hr: 'Preuzmi txt',
        si: 'Prenesi txt',
        de: 'Txt herunterladen',
        sk: 'Stahnuť txt',
        es: 'Descargar txt',
        ru: 'Скачать txt',
        rs: 'Preuzmite txt',
        ua: 'Завантажити txt',
        no: 'Laste ned txt',
        it: 'Scarica txt',
        se: 'Ladda ner txt',
    },
    dlBdd: {
        us: 'Download bdd',
        cz: 'Stáhnout bdd',
        pl: 'Pobierz bdd',
        hr: 'Preuzmi bdd',
        si: 'Prenesi bdd',
        de: 'Bdd herunterladen',
        sk: 'Stiahnuť bdd',
        es: 'Descargar bdd',
        ru: 'Скачать bdd',
        rs: 'Preuzmite bdd',
        ua: 'Завантажити bdd',
        no: 'Laste ned bdd',
        it: 'Scarica bdd',
        se: 'Ladda ner bdd',
    },
    copy: {
        us: 'Copy document to clipboard',
        cz: 'Zkopírovat dokument do schránky',
        pl: 'Kopiuj dokument do schowka',
        hr: 'Kopiraj dokument u međuspremnik',
        si: 'Kopiraj dokument v medpomnilnik',
        de: 'Dokument in Zwischenablage kopieren',
        sk: 'Skopírovať dokument do schránky',
        es: 'Copiar documento al portapapeles',
        ru: 'Скопировать документ в буфер обмена',
        rs: 'Kopirajte dokument u klipbord',
        ua: 'Копіювати документ у буфер обміну',
        no: 'Kopier dokumentet til utklippstavla',
        it: 'Copia il documento negli appunti',
        se: 'Kopiera dokumentet till urklipp',
    },
    copiedTitle: {
        us: 'Copied',
        cz: 'Zkopírováno',
        pl: 'Skopiowano',
        hr: 'Kopirano',
        si: 'Kopirano',
        de: 'Kopiert',
        sk: 'Skopírované',
        es: 'Copiado',
        ru: 'Скопировано',
        rs: 'Kopirano',
        ua: 'Скопійовано',
        no: 'Kopiert',
        it: 'Copiato',
        se: 'Kopierat',
    },
    copied: {
        us: 'Document copied to clipboard',
        cz: 'Dokument byl zkopírován do schránky',
        pl: 'Dokument został skopiowany do schowka',
        hr: 'Dokument kopiran u međuspremnik',
        si: 'Dokument kopiran v medpomnilnik',
        de: 'Dokument kopiert in Zwischenablage',
        sk: 'Dokument bol skopírovaný do schránky',
        es: 'Documento copiado al portapapeles',
        ru: 'Документ скопирован в буфер обмена',
        rs: 'Dokument kopiran u klipbord',
        ua: 'Документ скопійовано в буфер обміну',
        no: 'Dokumentet ble kopiert til utklippstavla',
        it: 'Il documento è stato copiato negli Appunti',
        se: 'Dokumentet har kopierats till urklipp',
    },
    dlMedia: {
        us: 'Download media',
        cz: 'Stáhnout média',
        pl: 'Pobierz audio/video',
        hr: 'Preuzmi audio/video',
        si: 'Prenesi avdio/video',
        de: 'Audio/Video herunterladen',
        sk: 'Stiahnuť audio/video',
        es: 'Descargar audio/video',
        ru: 'Скачать аудио/видео',
        rs: 'Preuzmite audio/video',
        ua: 'Завантажити аудіо/відео',
        no: 'Laste ned lyd/video',
        it: 'Scarica i media',
        se: 'Ladda ned media',
    },
    upTranscript: {
        us: 'Upload trsx',
        cz: 'Nahrát trsx',
        pl: 'Wczytaj trsx',
        hr: 'Učitaj trsx',
        si: 'Naloži trsx',
        de: 'Trsx hochladen',
        sk: 'Nahrať trsx',
        es: 'Subir trsx',
        ru: 'Загрузить trsx',
        rs: 'Otpremiti trsx',
        ua: 'Завантажити trsx',
        no: 'Laste opp trsx',
        it: 'Carica trsx',
        se: 'Ladda upp trsx',
    },
    importDump: {
        us: 'Upload bdd',
        cz: 'Nahrát bdd',
        pl: 'Wczytaj bdd',
        hr: 'Učitaj bdd',
        si: 'Naloži bdd',
        de: 'Bdd hochladen',
        sk: 'Nahrať bdd',
        es: 'Subir bdd',
        ru: 'Загрузить bdd',
        rs: 'Otpremiti bdd',
        ua: 'Завантажити bdd',
        no: 'Laste opp bdd',
        it: 'Carica bdd',
        se: 'Ladda upp bdd',
    },
    reset: {
        us: 'Reset to original transcription',
        cz: 'Reset na původní přepis',
        pl: 'Resetuj do oryginalnej transkrypcji',
        hr: 'Vrati na izvornu transkripciju',
        si: 'Vrnitev na izvirni prepis',
        de: 'Auf ursprüngliche Transkription zurücksetzen',
        sk: 'Reset na pôvodný prepis',
        es: 'Restablecer la transcripción original',
        ru: 'Восстановить оригинальную транскрипцию',
        rs: 'Vratiti na originalni transkripciju',
        ua: 'Відновити оригінальну транскрипцію',
        no: 'Tilbakestille til den opprinnelige transkripsjonen',
        it: 'Ripristino della trascrizione originale',
        se: 'Återställ till den ursprungliga transkriptionen',
    },
    alignAll: {
        us: 'Recalculate timestamps',
        cz: 'Přepočítat časové značky',
        pl: 'Ponownie oblicz znaczniki czasu',
        hr: 'Preračunaj vremenske oznake',
        si: 'Preračunaj časovne oznake',
        de: 'Zeitstempel neu berechnen',
        sk: 'Prepočítať časové značky',
        es: 'Recalcular las marcas de tiempo',
        ru: 'Пересчитать временные отметки',
        rs: 'Ponovo izračunati vremenske oznake',
        ua: 'Перерахувати тимчасові позначки',
        no: 'Omregne tidsstempler',
        it: 'Ricalcolo dei timestamp',
        se: 'Beräkna om tidsstämplar',
    },
    alignmentSuccess: {
        us: 'Timestamps recalculation was successful',
        cz: 'Přepočítání časových značek bylo úspěšné',
        pl: 'Ponowne obliczenie znaczników czasowych się powiodło',
        hr: 'Ponovno izračunavanje vremenskih oznaka je uspješno',
        si: 'Ponovni izračun časovnih žigov je uspešen',
        de: 'Neuberechnung der Zeitstempel war erfolgreich',
        sk: 'Prepočet časových značiek bol úspešný',
        es: 'Recalcular las marcas de tiempo fue exitoso',
        ru: 'Повторный подсчет временных меток прошел успешно',
        rs: 'Ponovo izgračunavanje vremenskih oznaka je uspelo',
        ua: 'Повторний підрахунок тимчасових міток пройшов успішно',
        no: 'Omregning av tidsstemplene vellykket',
        it: 'Il ricalcolo dei timestamp è avvenuto con successo',
        se: 'Tidsstämplarna omräknades',
    },
    alignmentFail: {
        us: 'We could not synchronize text with audio. Please check that text matches audio.',
        cz: 'Nepodařilo se synchronizovat text a audio. Zkontrolujte prosím, že text odpovídá audiu.',
        pl: 'Nie udało się zsynchronizować tekstu z dźwiękiem. Sprawdź, czy tekst i dźwięk są powiązane prawidłowo.',
        hr: 'Nismo mogli sinkronizirati tekst sa zvukom. Provjerite odgovara li tekst zvuku.',
        si: 'Besedila ni bilo mogoče sihhronizirati z zvokom. Preverite, ali se besedilo ujema z zvokom.',
        de: 'Text konnte nicht mit Audio synchronisiert werden. Bitte überprüfen Sie, ob Text mit Audio übereinstimmt.',
        sk: 'Nepodarilo sa synchronizovať text a audio. Skontrolujte, či sa text zhoduje s audiom.',
        es: 'No se pudo sincronizar el texto con el audio. Compruebe que el texto coincida con el audio.',
        ru: 'Мы не смогли синхронизировать текст с аудио. Проверьте, пожалуйста, чтобы текст соответствовал аудио.',
        rs: 'Nismo mogli da sinhronizujemo tekst za zvukom. Molimo da proverite da li tekst odgovara zvuku.',
        ua: 'Ми не змогли синхронізувати текст із аудіо. Перевірте, будь ласка, щоб текст відповідав аудіо.',
        no: 'Vi klarte ikke å synkronisere tekst med lyden. Vennligst kontroller om teksten samsvarer med lyden.',
        it: "Impossibile sincronizzare testo e audio. Controllare che il testo corrisponda all'audio.",
        se: 'Det gick inte att synkronisera texten med ljudet. Vänligen kontrollera att texten stämmer överens med ljudet.',
    },
    generateSubtitles: {
        us: 'Generate subtitles',
        cz: 'Generovat titulky',
        pl: 'Wygeneruj napisy',
        hr: 'Kreiraj titlove',
        si: 'Ustvari podnapis',
        de: 'Untertitel generieren',
        sk: 'Generovať titulky',
        es: 'Generar subtítulos',
        ru: 'Добавить субтитры',
        rs: 'Generišite titlove',
        ua: 'Додати субтитри',
        no: 'Generere undertekster',
        it: 'Generare sottotitoli',
        se: 'Generera undertexter',
    },
    subtitleRevision: {
        us: 'Subtitle revision',
        cz: 'Revize titulků',
        pl: 'Rewizja napisów',
        hr: 'Provjera titlova',
        si: 'Preverjanje podnapisov',
        de: 'Untertitelrevision',
        sk: 'Revízia titulkov',
        es: 'Revisión de subtítulos',
        ru: 'Проверка субтитров',
        rs: 'Revizija titlova',
        ua: 'Перевірка субтитрів',
        no: 'Revisjon av undertekster',
        it: 'Rivedere i sottotitoli',
        se: 'Korrektur av undertexter',
    },
    convertToSubtitles: {
        us: 'Convert to subtitles',
        cz: 'Převést na titulky',
        pl: 'Konwertuj do napisów',
        hr: 'Pretvori u titlove',
        si: 'Pretvori v podnapise',
        de: 'In Untertitel umwandeln',
        sk: 'Konvertovať na titulky',
        es: 'Convertir a subtítulos',
        ru: 'Преобразовать в субтитры',
        rs: 'Konvertuj u titlove',
        ua: 'Перетворити на субтитри',
        no: 'Omformere til undertekster',
        it: 'Convertire in sottotitoli',
        se: 'Konvertera till undertexter',
    },
    subtitleSettings: {
        us: 'Subtitle mode settings',
        cz: 'Nastavení režimu titulků',
        pl: 'Ustawienia trybu napisów',
        hr: 'Postavke načina titlova',
        si: 'Nastavitve načina podnapisa',
        de: 'Einstellungen für Untertitel-Modus',
        sk: 'Nastavenie režimu titulkov',
        es: 'Configuración del modo de subtítulos',
        ru: 'Настройки режима субтитров',
        rs: 'Podešavanje modaliteta titlova',
        ua: 'Налаштування режиму субтитрів',
        no: 'Undertekster-modus innstillinger',
        it: 'Impostare la modalità sottotitoli',
        se: 'Inställningar för undertextläge',
    },
    subtitleMessageSuccess: {
        us: 'Subtitle ends were generated',
        cz: 'Konce titulků byly úspěšně vygenerovány',
        pl: 'Końce napisów zostały pomyślnie wygenerowane',
        hr: 'Krajevi titlova su kreirani',
        si: 'Konci podnapisov so ustvarjeni',
        de: 'Untertitelenden generiert',
        sk: 'Úspešne vygenerované konce titulkov',
        es: 'Configuración del modo de subtítulos',
        ru: 'Разделения субтитров успешно сгенерированы',
        rs: 'Generisani završeci titlova',
        ua: 'Поділ субтитрів успішно згенеровані',
        no: 'Slutter av undertekstene ble generert',
        it: 'I titoli di coda sono stati generati con successo',
        se: 'Undertextslut har genererats',
    },
    subtitleUpdateSuccess: {
        us: 'Subtitle settings were updated',
        cz: 'Nastavení titulků bylo aktualizováno',
        pl: 'Ustawienia napisów zostały zaktualizowane',
        hr: 'Postavke titlova su ažurirane',
        si: 'Nastavitve podnapisov so posodobljene',
        de: 'Untertiteleinstellungen wurden aktualisiert',
        sk: 'Nastavenia titulkov boli aktualizované',
        es: 'Se actualizó la configuración de los subtítulos',
        ru: 'Настройки субтитров были обновлены',
        rs: 'Podešavanja titlova su ažurirana',
        ua: 'Налаштування субтитрів оновлено',
        no: 'Undertekster instillingene ble oppdatert',
        it: 'Le impostazioni dei sottotitoli sono state aggiornate',
        se: 'Inställningar för undertexter har uppdaterats',
    },
    subtitleMessageErrorTryAgain: {
        us: 'Failed to generate captions preview. Please try again.',
        cz: 'Nepodařilo se vygenerovat konce titulků. Zkuste to prosím znovu.',
        pl: 'Nie udało się wygenerować końców napisów. Spróbuj ponownie.',
        hr: 'Nije uspjelo kreiranje pregleda titlova. Pokušajte ponovno.',
        si: 'Predogleda podnapisov ni bilo mogoče ustvariti. Poskusite ponovno.',
        de: 'Untertitelvorschau konnte nicht generiert werden. Bitte versuchen Sie es erneut.',
        sk: 'Nepodarilo sa vygenerovať konce titulkov. Skúste to znova.',
        es: 'No se pudo generar los fines de los subtítulos. Por favor inténtelo de nuevo.',
        ru: 'Не удалось создать предпросмотр разделений субтитров. Пожалуйста, попробуйте снова.',
        rs: 'Generisanje pregleda titlova nije uspelo. Molim vas, pokušajte ponovo.',
        ua: 'Не вдалося створити перегляд розділів субтитрів. Будь ласка, спробуйте ще раз.',
        no: 'Klarte ikke å generere slutter av undertekstene. Vennligst prøv igjen.',
        it: "Impossibile generare l'anteprima dei sottotitoli. Si prega di riprovare.",
        se: 'Det gick inte att generera undertextslut. Vänligen prova igen.',
    },
    subtitleMessageError: {
        us: 'Failed to generate captions preview',
        cz: 'Nepodařilo se vygenerovat konce titulků',
        pl: 'Nie udało się wygenerować końców napisów',
        hr: 'Nije uspjelo kreiranje pregleda titlova',
        si: 'Predogleda podnapisov ni bilo mogoče ustvariti',
        de: 'Untertitelvorschau fehlgeschlagen',
        sk: 'Nepodarilo sa vygenerovať konce titulkov',
        es: 'No se pudo generar la vista previa de los subtítulos',
        ru: 'Не удалось создать предпросмотр разделений субтитров',
        rs: 'Generisanje pregleda titlova nije uspelo',
        ua: 'Не вдалося створити перегляд розділів субтитрів',
        no: 'Klarte ikke å generere slutter av undertekstene',
        it: "Impossibile generare l'anteprima dei sottotitoli",
        se: 'Det gick inte att generera undertextslut',
    },
    minSubtitleLineLength: {
        us: 'Minimal subtitle length is',
        cz: 'Minimální délka titulku je',
        pl: 'Minimalna długość napisów to',
        hr: 'Minimalna duljina titla je',
        si: 'Minimalna dožina podnapisa je',
        de: 'Minimale Untertitellänge ist',
        sk: 'Minimálna dĺžka titulku je',
        es: 'La longitud mínima de los subtítulos es',
        ru: 'Минимальная длина субтитров',
        rs: 'Minimalna dužina titla je',
        ua: 'Мінімальна довжина субтитрів',
        no: 'Minimal undertekstlengde er',
        it: 'La lunghezza minima dei sottotitoli è',
        se: 'Minimilängd på undertexten är',
    },
    maxSubtitleLineLength: {
        us: 'Maximal line length is',
        cz: 'Maximální délka řádku je',
        pl: 'Maksymalna długość linii to',
        hr: 'Maksimalna duljina je',
        si: 'Maksimalna dolžina podnapisa je',
        de: 'Maximale Zeilenlänge ist',
        sk: 'Maximálna dĺžka riadku je',
        es: 'La longitud máxima de la línea es',
        ru: 'Максимальная длина ряда',
        rs: 'Maksimalna dužina je',
        ua: 'Максимальна довжина ряду',
        no: 'Maksimal linjelengde er',
        it: 'La lunghezza massima della riga è',
        se: 'Maximilängd på raden är',
    },
    integerSubtitleLineLength: {
        us: 'Subtitle length must be a positive whole number.',
        cz: 'Délka titulku musí být kladné celé číslo.',
        pl: 'Długość napisów musi być dodatnią liczbą całkowitą.',
        hr: 'Duljina titla mora biti pozitivan cijeli broj.',
        si: 'Dolžina podnapisov mora biti pozitivno celo število.',
        de: 'Die Untertitellänge muss eine positive ganze Zahl sein.',
        sk: 'Dĺžka titulku musí byť kladné celé číslo.',
        es: 'La longitud de los subtítulos debe ser un número entero positivo.',
        ru: 'Длиной субтитров должно быть положительное целое число.',
        rs: 'Dužina titla mora biti pozitivan ceo broj.',
        ua: 'Довжиною субтитрів має бути позитивне ціле число.',
        no: 'Undertekstlengden må være et positivt, helt tall.',
        it: 'La lunghezza del sottotitolo deve essere un numero intero positivo.',
        se: 'Längden på undertexten måste vara ett positivt heltal.',
    },
    invalidExportFormat: {
        us: 'Invalid file format',
        cz: 'Neplatný formát souboru',
        pl: 'Nieprawidłowy format pliku',
        hr: 'Nevažeći format datoteke',
        si: 'Neveljaven format datoteke',
        de: 'Ungültiges Dateiformat',
        sk: 'Neplatný formát súboru',
        es: 'Formato de archivo inválido',
        ru: 'Неподдерживаемый формат файла',
        rs: 'Pogrešan format datoteke',
        ua: 'Формат файлу, що не підтримується',
        no: 'Ugyldig filformat',
        it: 'Formato file non valido',
        se: 'Ogiltigt filformat',
    },
    yesTurnOff: {
        us: 'Yes, turn off',
        cz: 'Ano, vypnout',
        pl: 'Tak, wyłącz',
        hr: 'Da, isključi',
        si: 'Ja, izklopi',
        de: 'Ja, ausschalten',
        sk: 'Áno, vypnúť',
        es: 'Sí, apaga',
        ru: 'Да, выключить',
        rs: 'Da, isključi',
        ua: 'Так, вимкнути',
        no: 'Ja, slå av',
        it: 'Sì, disattivare',
        se: 'Ja, inaktivera',
    },
    subtitleResetWarning: {
        us: 'By switching back you will lose all subtitle ends edits. Time stamps and text edits will remain saved.',
        cz: 'Převedením trvale přijdete o veškeré úpravy konců titulků. Časové značky a editace textu zůstanou zachovány.',
        pl: 'Jeśli zmieniłeś końce napisów, wprowadzone zmiany zostaną utracone w trakcie konwersji.',
        hr: 'Vraćanjem unazad izgubit ćete sva uređivanja krajeva titlova. Vremenske oznake i izmjene teksta ostat će spremljene.',
        si: 'Če preklopite nazaj, boste izgubili vse spremembe konca podnapisov. Časovni žigi in urejanje besedila bodo ostali shranjeni.',
        de: 'Wenn Sie zurückschalten, gehen alle Bearbeitungen der Untertitelenden verloren. Zeitstempel und Textbearbeitungen bleiben gespeichert.',
        sk: 'Pri konverzii sa natrvalo stratia všetky úpravy koncových titulkov. Časové značky a úpravy textu sa zachovajú.',
        es: 'Al regresar, perderá todas las ediciones de los fines de los subtítulos. Las marcas de tiempo y las ediciones de texto seguirán guardadas.',
        ru: 'При переключении назад вы потеряете все правки концов субтитров. Временные метки и изменения текста останутся сохраненными.',
        rs: 'Vraćanjem unazad izgubićete sva uređivanja krajeva titlova. Vremenske oznake i izmene teksta ostaće sačuvane.',
        ua: 'Повернувшись назад, ви втратите всі редагування кінців субтитрів. Позначки часу та редагування тексту залишаться збереженими.',
        no: 'Du kommer til å miste alle endringene i slutter av undertekstene ved å bytte tilbake. Tidsstemplene og endringene i teksten forblir lagret.',
        it: 'Il trasferimento provoca la perdita definitiva della fine dei sottotitoli. Le marche temporali e la modifica del testo saranno conservate.',
        se: 'Genom att byta tillbaka kommer du att förlora alla ändringar du har gjort på undertextsluten. Tidsstämplar och textändringar förblir sparade.',
    },
    subtitleModalEnds: {
        us: 'You will see automatically generated subtitle ends ',
        cz: 'Uvidíte automaticky generované konce titulků ',
        pl: 'Zobaczysz automatycznie wygenerowane końce napisów ',
        hr: 'Vidjet ćete automatski kreirane krajeve titlova ',
        si: 'Videli boste avtomatski ustvarjene konce podnapisov ',
        de: 'Sie werden automatisch generierte Untertitelenden sehen ',
        sk: 'Zobrazia sa automaticky generované konce titulkov ',
        es: 'Verá fines de subtítulos generados automáticamente ',
        ru: 'Вы увидите автоматически сгенерированные разделения субтитров ',
        rs: 'Videćete automatski generisane krajeve titlova ',
        ua: 'Ви побачите автоматично згенеровані розділення субтитрів ',
        no: 'Du vil se automatisk generert slutter av undertekstene ',
        it: 'Verranno visualizzati i finali dei sottotitoli generati automaticamente ',
        se: 'Du kommer att se automatiskt genererade undertextxlut ',
    },
    subtitleModalEditEnds: {
        us: 'You can edit them manually:',
        cz: 'Můžete je upravovat ručně:',
        pl: 'Możesz je edytować ręcznie:',
        hr: 'Možete ih uređivati ručno:',
        si: 'Lahko jih urejate ročno:',
        de: 'Sie können sie manuell bearbeiten:',
        sk: 'Môžete ich upraviť ručne:',
        es: 'Puede editarlos a mano:',
        ru: 'Вы можете редактировать их вручную:',
        rs: 'Možete ih ručno urediti:',
        ua: 'Ви можете редагувати їх вручну:',
        no: 'Du kan redigere dem manuelt:',
        it: 'È possibile modificarli manualmente:',
        se: 'Du kan redigera dem manuellt:',
    },
    subtitleModalUse: {
        us: 'Use',
        cz: 'Použijte',
        pl: 'Zastosuj',
        hr: 'Upotrijebite',
        si: 'Uporabite',
        de: 'Verwenden Sie',
        sk: 'Použite',
        es: 'Usar',
        ru: 'Используйте',
        rs: 'Upotreba',
        ua: 'Використовуйте',
        no: 'Bruk',
        it: 'Utilizzi',
        se: 'Använd',
    },
    subtitleModalCreateEnds: {
        us: 'to add a subtitle end and ',
        cz: 'pro přidání konce titulku a ',
        pl: 'aby dodać koniec napisu oraz ',
        hr: 'za dodavanje kraja titla i ',
        si: 'za dodajanje konca podnapisa in ',
        de: 'um ein Untertitelende hinzuzufügen und ',
        sk: 'na pridanie konca nadpisu a ',
        es: 'para agregar un subtítulo y ',
        ru: 'для добавления разделений субтитров и ',
        rs: 'za dodavanje podnaslova i ',
        ua: 'для додавання поділів субтитрів та ',
        no: 'for å legge til en slutt av undertekst og ',
        it: 'per aggiungere un sottotitolo finale e ',
        se: 'för att lägga till ett slut på undertexten och ',
    },
    subtitleModalDeleteEnds: {
        us: 'to delete it.',
        cz: 'pro jeho smazání.',
        pl: 'aby go zmazać.',
        hr: 'za brisanje.',
        si: 'za brisanje.',
        de: 'um es zu löschen.',
        sk: 'na jeho vymazanie.',
        es: 'para borrarlo.',
        ru: 'для их удаления.',
        rs: 'da ga izbrišete.',
        ua: 'для їхнього видалення.',
        no: 'for å slette den.',
        it: 'per eliminarlo.',
        se: 'för att ta bort det.',
    },
    subtitleModalSpeakers: {
        us: 'In video speakers are replaced with a dash ',
        cz: 'Ve videu jsou mluvčí nahrazeni pomlčkou ',
        pl: 'W filmie wideo mówcy będą zastąpieni myślnikiem ',
        hr: 'Govornici su u videu zamijenjeni crticom ',
        si: 'Govorci v videu so zamenjani z vezajem ',
        de: 'Im Video werden die Sprecher durch Bindestrich ersetzt ',
        sk: 'Vo videu sú rečníci nahradení pomlčkou ',
        es: 'En el video, los hablantes se reemplazan con un guión ',
        ru: 'На видео спикер заменен на тире ',
        rs: 'U videima govornici su zamenjeni crticom ',
        ua: 'На відео спікер замінено на тирі ',
        no: 'Talere er erstattet med tankestrek i videoer ',
        it: 'Nel video, gli speaker sono sostituiti da un trattino ',
        se: 'Talare ersätts med bindestreck i videon ',
    },
    lineLength: {
        us: 'Maximum subtitle length:',
        cz: 'Maximální délka titulku:',
        pl: 'Maksymalna długość napisów:',
        hr: 'Maksimalna duljina titla:',
        si: 'Maksimalna dožina podnapisa je:',
        de: 'Maximale Untertitellänge:',
        sk: 'Maximálna dĺžka titulku:',
        es: 'La longitud máxima de los subtítulos:',
        ru: 'Максимальная длина субтитров:',
        rs: 'Maksimalna dužina titla:',
        ua: 'Максимальна довжина субтитрів:',
        no: 'Maksimal undertekstlengde er:',
        it: 'Lunghezza massima dei sottotitoli:',
        se: 'Maximilängd på undertexten:',
    },
    fontStyleAndSize: {
        us: 'Font and size',
        cz: 'Font a velikost',
        pl: 'Czcionka i rozmiar',
        hr: 'Font i veličina',
        si: 'Pisava in velikost',
        de: 'Schriftart und Größe',
        sk: 'Font a veľkosť',
        es: 'Fuente y tamaño',
        ru: 'Шрифт и размер',
        rs: 'Font i veličina',
        ua: 'Шрифт і розмір',
        no: 'Skrift og størrelse',
        it: 'Carattere e dimensione',
        se: 'Font och storlek',
    },
    backgroundTransparency: {
        us: 'Background',
        cz: 'Pozadí',
        pl: 'Tło',
        hr: 'Pozadina',
        si: 'Ozadje',
        de: 'Hintergrund',
        sk: 'Pozadie',
        es: 'Fondo',
        ru: 'Фон',
        rs: 'Pozadina',
        ua: 'Фон',
        no: 'Bakgrunn',
        it: 'Sfonfo',
        se: 'Bakgrund',
    },
    defaultPosition: {
        us: 'Global setting for all captions. Position individual subtitles by dragging the subtitle directly in the video preview.',
        cz: 'Globální nastavení pro všechny titulky. Pozici individuálních titulků nastavíte tažením titulku přímo v náhledu videa.',
        pl: 'Globalne ustawienie dla wszystkich napisów. Pojedyncze napisy można pozycjonować, przeciągając je bezpośrednio w podglądzie wideo.',
        hr: 'Globalna postavka za sve titlove. Postavite pojedinačne titlove povlačenjem titla izravno u pregled video zapisa.',
        si: 'Globalna nastavitev za vse napise. Postavite posamezne podnaslove tako, da podnaslov povlečete neposredno v predogled video posnetka.',
        de: 'Globale Einstellung für alle Untertitel. Positionieren Sie einzelne Untertitel durch Ziehen des Untertitels direkt in der Videovorschau.',
        sk: 'Globálne nastavenia pre všetky titulky. Pozíciu jednotlivých titulkov nastavíte potiahnutím titulku priamo v náhľade videa.',
        es: 'Configuración global para todos los subtítulos. Posicione los subtítulos individuales arrastrando el subtítulo directamente en la vista previa del video.',
        ru: 'Глобальная настройка для всех субтитров. Позиционирование отдельных субтитров осуществляется перетаскиванием субтитров непосредственно в предварительном просмотре видео.',
        rs: 'Globalno podešavanje za sve titlove. Postavite pojedinačne titlove pomeranjem titlova direktno u pregledu videa.',
        ua: 'Глобальне налаштування для всіх підписів. Позиціонуйте окремі субтитри, перетягуючи їх безпосередньо в попередньому перегляді відео.',
        no: 'Globale innstillinger for alle undertekster. Plasser individuelle undertekster med å dra direkte i videoens forhåndsvisning.',
        it: "Impostazioni globali per tutti i sottotitoli. Posizionare i singoli sottotitoli trascinandoli direttamente nell'anteprima del video.",
        se: 'Global inställning för alla undertexter. Placera enskilda undertexter genom att dra dem direkt i videoförhandsgranskningen.',
    },
    positionLeft: {
        us: 'left',
        cz: 'vlevo',
        pl: 'w lewo',
        hr: 'lijevo',
        si: 'levo',
        de: 'links',
        sk: 'vľavo',
        es: 'izquierda',
        ru: 'слева',
        rs: 'levo',
        ua: 'ліворуч',
        no: 'til venstre',
        it: 'sinistra',
        se: 'till vänster',
    },
    positionMiddle: {
        us: 'in the middle',
        cz: 'uprostřed',
        pl: 'pośrodku',
        hr: 'u sredini',
        si: 'sredina',
        de: 'in der Mitte',
        sk: 'uprostred',
        es: 'en el centro',
        ru: 'посередине',
        rs: 'u sredini',
        ua: 'посередині',
        no: 'i midten',
        it: 'centro',
        se: 'i mitten',
    },
    positionRight: {
        us: 'right',
        cz: 'vpravo',
        pl: 'w prawo',
        hr: 'desno',
        si: 'desno',
        de: 'rechts',
        sk: 'vpravo',
        es: 'derecha',
        ru: 'справа',
        rs: 'desno',
        ua: 'праворуч',
        no: 'til høyre',
        it: 'destra',
        se: 'till höger',
    },
    positionTop: {
        us: 'top',
        cz: 'nahoře',
        pl: 'na górze',
        hr: 'gore',
        si: 'zgoraj',
        de: 'oben',
        sk: 'hore',
        es: 'arriba',
        ru: 'вверху',
        rs: 'na vrhu',
        ua: 'зверху',
        no: 'oppe',
        it: 'in alto',
        se: 'uppe',
    },
    positionCenter: {
        us: 'center',
        cz: 'střed',
        pl: 'środek',
        hr: 'centar',
        si: 'sredina',
        de: 'mitte',
        sk: 'stred',
        es: 'centro',
        ru: 'по центру',
        rs: 'u centru',
        ua: 'по центру',
        no: 'sentrum',
        it: 'al centro',
        se: 'i mitten',
    },
    positionBottom: {
        us: 'bottom',
        cz: 'dole',
        pl: 'na dole',
        hr: 'dolje',
        si: 'spodaj',
        de: 'unten',
        sk: 'dole',
        es: 'abajo',
        ru: 'внизу',
        rs: 'dole',
        ua: 'внизу',
        no: 'nede',
        it: 'in basso',
        se: 'nere',
    },
    subtitlePosition: {
        us: 'Subtitle position',
        cz: 'Pozice titulků',
        pl: 'Pozycja napisów',
        hr: 'Položaj titla',
        si: 'Položaj podnapisov',
        de: 'Position des Untertitels',
        sk: 'Pozícia titulkov',
        es: 'Posición de los subtítulos',
        ru: 'Положение субтитров',
        rs: 'Pozicija titla',
        ua: 'Позиція субтитрів',
        no: 'Plassering av undertekster',
        it: 'Posizione dei sottotitoli',
        se: 'Placering av undertexter',
    },
    opaque: {
        us: 'black',
        cz: 'černé',
        pl: 'czarne',
        hr: 'crna',
        si: 'črna',
        de: 'schwarz',
        sk: 'čierne',
        es: 'negro',
        ru: 'черный',
        rs: 'crno',
        ua: 'чорний',
        no: 'svart',
        it: 'nero',
        se: 'svarta',
    },
    semitransparent: {
        us: 'semi-transparent',
        cz: 'poloprůhledné',
        pl: 'półprzezroczyste',
        hr: 'poluprozirna',
        si: 'polprozorno',
        de: 'halbtransparent',
        sk: 'polopriehľadné',
        es: 'semi-trasparente',
        ru: 'полупрозрачный',
        rs: 'polu-providno',
        ua: 'напівпрозорий',
        no: 'halvgjennomsiktig',
        it: 'semitrasparente',
        se: 'halvgenomskinliga',
    },
    transparent: {
        us: 'transparent',
        cz: 'průhledné',
        pl: 'przezroczyste',
        hr: 'prozirna',
        si: 'prozorno',
        de: 'transparent',
        sk: 'priehľadné',
        es: 'transparente',
        ru: 'прозрачный',
        rs: 'providno',
        ua: 'прозорий',
        no: 'gjennomsiktig',
        it: 'trasparente',
        se: 'genomskinliga',
    },
    chooseLineLength: {
        us: 'Choose line length',
        cz: 'Vyberte délku řádku',
        pl: 'Wybierz długość linii',
        hr: 'Odaberite duljinu linije',
        si: 'Izberite dolžino vrstice',
        de: 'Zeilenlänge wählen',
        sk: 'Vyberte dĺžku riadku',
        es: 'Elegir la longitud de la línea',
        ru: 'Выберите длину ряда',
        rs: 'Odaberite dužinu linije',
        ua: 'Виберіть довжину ряду',
        no: 'Velg linjelengde',
        it: 'Selezionare la lunghezza della riga',
        se: 'Välj radlängden',
    },
    downloadDoc: {
        us: 'Download docx',
        cz: 'Stáhnout docx',
        pl: 'Pobierz docx',
        hr: 'Preuzmi docx',
        si: 'Prenesi docx',
        de: 'Docx herunterladen',
        sk: 'Stiahnuť docx',
        es: 'Descargar docx',
        ru: 'Скачать docx',
        rs: 'Preuzmite docx',
        ua: 'Завантажити docx',
        no: 'Laste ned docx',
        it: 'Scarica il docx',
        se: 'Ladda ned docx',
    },
    downloadPdf: {
        us: 'Download pdf',
        cz: 'Stáhnout pdf',
        pl: 'Pobierz pdf',
        hr: 'Preuzmi pdf',
        si: 'Prenesi pdf',
        de: 'Pdf herunterladen',
        sk: 'Stiahnuť pdf',
        es: 'Descargar pdf',
        ru: 'Скачать pdf',
        rs: 'Preuzmite pdf',
        ua: 'Завантажити pdf',
        no: 'Laste ned pdf',
        it: 'Scarica il pdf',
        se: 'Ladda ned pdf',
    },
    subtitleAutomatic: {
        us: 'Subtitles timing and line splitting will be created automatically. To edit it, turn on the Subtitle mode by converting the transcription to subtitles.',
        cz: 'Titulky se vygenerují s automatickým časováním a zalomením řádků. Pro jejich editaci, zapněte Titulkový režim převedením přepisu na titulky.',
        pl: 'Czasowa synchronizacja napisów i ich podział zostaną utworzone automatycznie. Aby edytować napisy, włącz tryb napisów.',
        hr: 'Automatski će se izraditi vrijeme titlova i podjela redaka. Za uređivanje uključite način titlovanja pretvaranjem transkripcije u titlove.',
        si: 'Časovne oznake in delitev vrstic bosta samodejno ustvarjeni. Če ju želite urediti, vklopite način podnapisov tako, da prepis pretvorite v podnapise.',
        de: 'Timing und Zeilentrennung der Untertitel werden automatisch erstellt. Um sie zu bearbeiten, schalten Sie den Untertitelmodus ein, indem Sie Transkription in Untertitel umwandeln.',
        sk: 'Titulky sa vytvoria s automatickým načasovaním a zalomením riadkov. Ak ich chcete upraviť, zapnite režim titulkov prevodom prepisu na titulky.',
        es: 'Los subtítulos se crearán con los tiempos y la división de líneas automaticamente. Para editarlos, activa el modo de subtítulos convirtiendo la transcripción en subtítulos.',
        ru: 'Субтитры генерируются с автоматическим синхронизацией временных знаков и разрывами строк. Чтобы их редактировать, включите режим субтитров.',
        rs: 'Titlovi će automatski biti sinhronizovani i linije će se deliti automatski. Da biste ih uredili, uključite režim titlova pretvaranjem transkripta u titlove.',
        ua: 'Хронометраж субтитрів і розбиття на рядки буде створено автоматично. Щоб відредагувати їх, увімкніть режим субтитрів, перетворивши транскрипцію на субтитри.',
        no: 'Undertekst-timing og linje-splitting blir generert automatisk. For å editere, slå på Undertekstmodus med å konvertere transkripsjon til undertekster.',
        it: 'Il tempo dei sottotitoli e la divisione delle linee verranno generati automaticamente. Per modificarle, attivi la modalità Sottotitoli convertende la trascrizinoe in sottotitoli.',
        se: 'Undertexterna kommer att genereras med automatisk tidskodning och uppdelning av rader. För att redigera dem, aktivera undertextredigeringsläget genom att konvertera transkriptionen till undertexter.',
    },
    subtitleRevised: {
        us: 'Subtitles will be generated with current subtitle ends',
        cz: 'Titulky se vygenerují s aktuálním časováním titulků',
        pl: 'Napisy zostaną wygenerowane z aktualnymi znacznikami czasowymi',
        hr: 'Titlovi će se kreirati s trenutnim krajevima titlova',
        si: 'Podnapisi bodo ustvarjeni s trenutnimi konci podnapisov',
        de: 'Untertitel werden mit den aktuellen Untertitelenden generiert',
        sk: 'Titulky sa vygenerujú s aktuálnym časovaním titulkov',
        es: 'Los subtítulos se generarán con los fines de subtítulos actuales',
        ru: 'Субтитры будут сгенерированы с учётом актуальных временных промежутков',
        rs: 'Titlovi će biti generisano sa trenutnim krajem titlova',
        ua: 'Субтитри будуть згенеровані з урахуванням актуальних часових інтервалів',
        no: 'Undertekst vil bli generert med gjeldende sluttidspunkter',
        it: 'I sottotitoli saranno generati con la temporizzazione corrente dei sottotitoli',
        se: 'Undertexter genereras med nuvarande tidskodning',
    },
    chooseSubtFormat: {
        us: 'Choose format:',
        cz: 'Vyberte formát:',
        pl: 'Wybierz format:',
        hr: 'Odaberi format:',
        si: 'Izberite format:',
        de: 'Format wählen:',
        sk: 'Vyberte formát:',
        es: 'Elegir el formato:',
        ru: 'Выберите формат:',
        rs: 'Odaberite format:',
        ua: 'Виберіть формат:',
        no: 'Velg format:',
        it: 'Selezioni il formato:',
        se: 'Välj format:',
    },
    setAllCaps: {
        us: 'Set subtitles to uppercase:',
        cz: 'Nastavit titulky na velká písmena:',
        pl: 'Ustaw napisy na wielkie litery:',
        hr: 'Podesite titlove na velika slova:',
        si: 'Nastavite podnapise na velike črke:',
        de: 'Untertitel in Großbuchstaben setzen:',
        sk: 'Nastavenie titulkov na veľké písmená:',
        es: 'Establecer subtítulos en mayúsculas:',
        ru: 'Установите субтитры в верхний регистр:',
        rs: 'Postavite titlove na velika slova:',
        ua: 'Встановити для субтитрів верхній регістр:',
        no: 'Stille inn undertekster til store bokstaver:',
        it: 'Impostare i sottotitoli in maiuscolo:',
        se: 'Ställ in undertexter till versaler:',
    },
    format: {
        us: 'Format',
        cz: 'Formát',
        pl: 'Format',
        hr: 'Format',
        si: 'Format',
        de: 'Format',
        sk: 'Formát',
        es: 'El formato',
        ru: 'Формат',
        rs: 'Format',
        ua: 'Формат',
        no: 'Format',
        it: 'Il formato',
        se: 'Format',
    },
    unsupportedForColoredCaptions: {
        us: 'does not support colored subtitles.',
        cz: 'nepodporuje barevné titulky.',
        pl: 'nie obsługuje kolorowych napisów.',
        hr: 'ne podržava titlove u boji.',
        si: 'ne podpira barvnih podnapisov.',
        de: 'unterstützt keine Farbuntertitel.',
        sk: 'nepodporuje farebné titulky.',
        es: 'no admite subtitulos con color.',
        ru: 'не поддерживает цветные субтитры.',
        rs: 'ne podržava titlove u boji.',
        ua: 'не підтримує кольорові субтитри.',
        no: 'støtter ikke fargerike undertekster.',
        it: 'non supporta i sottotitoli a colori.',
        se: 'stödjer inte undertexter i färg.',
    },
    subtitlePositionNotSupported: {
        us: 'The ttml format supports only centered subtitles. Other positions will be automatically reset to the center.',
        cz: 'Formát ttml podporuje pouze vycentrované titulky. Jiné pozice budou automaticky resetovány na prostředek.',
        pl: 'Format ttml obsługuje wyłącznie napisy wyśrodkowane. Pozycje tekstu w innych miejscach zostaną automatycznie przesunięte do środka.',
        hr: 'Ttml format podržava samo centrirane titlove. Ostali položaji automatski će se resetirati na središte.',
        si: 'Oblika zapisa ttml podpira samo centrirane podnapise.  Drugi položaji bodo samodejno ponastavljeni na sredino.',
        de: 'Das ttml-Format unterstützt nur zentrierte Untertitel. Andere Positionen werden automatisch auf die Mitte zurückgesetzt.',
        sk: 'Formát ttml podporuje iba vycentrované titulky. Ostatné pozície sa automaticky nastavia na stred.',
        es: 'El formato ttml solo admite subtítulos centrados. Otras posiciones se restablecerán automáticamente al centro.',
        ru: 'Формат ttml поддерживает только центрированные субтитры. Другие позиции будут автоматически сброшены к центру.',
        rs: 'Ttml format podržava samo centrirane titlove. Ostale pozicije će automatski biti centrirane.',
        ua: 'Формат txt підтримує лише вирівнювання субтитрів по центру. Інші позиції будуть автоматично вирівняні по центру.',
        no: 'ttml formatet støtter kun midtstilte undertekster. Andre posisjoner blir automatisk sentrert.',
        it: 'Il formato ttml supporta solo sottotitoli centrati. Le altre posizioni verranno automaticamente riportate al centro.',
        se: 'Ttml-formatet stödjer endast centrerade undertexter. Andra ställningar kommer automatiskt att återställas till centrum.',
    },
    subtitleFormatting: {
        us: 'Some services (e.g. social networks) do not support formatting and an error may occur. In that case, we recommend generating the subtitles without formatting, or burning the subtitles into the video.',
        cz: 'Určité služby (např. sociální sítě) formátování nepodporují a může dojít k chybě. V tom případě doporučujeme vygenerovat titulky bez formátování, nebo titulky vypálit do videa.',
        pl: 'Niektóre usługi (np. sieci społecznościowe) nie obsługują formatowania i może pojawić się błąd. W takim przypadku zalecamy wygenerowanie napisów bez formatowania lub wypalenie napisów w filmie.',
        hr: 'Neke usluge (npr. društvene mreže) ne podržavaju formatiranje i može doći do pogreške. U tom slučaju preporučujemo kreiranje titlova bez formatiranja ili ugrađivanje titlova u videozapis.',
        si: 'Nekatere storitve (npr. družabna omrežja) ne podpirajo oblikovanja in lahko pride do napake. V tem primeru priporočamo ustvarjanje podnapisov brez oblikovanja ali vgradnjo podnapisov v videoposnetek.',
        de: 'Einige Dienste (z. B. soziale Netzwerke) unterstützen keine Formatierung und es kann Fehler auftreten. In diesem Fall empfehlen wir, Untertitel ohne Formatierung zu erstellen oder Untertitel in Video einzubrennen.',
        sk: 'Niektoré služby (napr. sociálne siete) nepodporujú formátovanie a môže dôjsť k chybe. V takom prípade odporúčame vygenerovať titulky bez formátovania alebo ich vypáliť do videa.',
        es: 'Ciertos servicios (por ejemplo, redes sociales) no admiten formato y pueden producir errores. En ese caso, recomendamos generar subtítulos sin formato o incrustar los subtítulos en el video.',
        ru: 'Некоторые сервисы (например, социальные сети) не поддерживают форматирование, и может возникнуть ошибка. В этом случае мы рекомендуем генерировать субтитры без форматирования или записать их в видео.',
        rs: 'Neke usluge (npr. društvene mreže) ne podržavaju formatiranje i može doći do greške. U tom slučaju, preporučujemo generisanje titlova bez formatiranja, ili umetanje titlova u video.',
        ua: 'Деякі сервіси (наприклад, соціальні мережі) не підтримують форматування, і може виникнути помилка. У такому випадку ми рекомендуємо генерувати субтитри без форматування або записати субтитри у відео.',
        no: 'Noen tjenester (f.eks. sosiale medier) støtter ikke formatering og en feil kan oppstå. I så fall anbefaler vi å generere undertekster uten formatering eller brenne undertekster inn til videoen.',
        it: 'Alcuni servizi (ad esempio i social network) non supportano la formattazione e potrebbe verificarsi un errore. In questo caso, si consiglia di generare i sottotitoli senza formattazione o di masterizzare i sottotitoli nel video.',
        se: 'Somliga tjänster (såsom sociala medier) stödjer inte formatering och ett fel kan uppstå. I så fall rekommenderar vi att du genererar undertexter utan formatering och bäddar in undertexterna i videon.',
    },
    selectedSubtitleLength: {
        us: 'Selected length',
        cz: 'Vybraná délka',
        pl: 'Wybrana długość',
        hr: 'Odabrana duljina',
        si: 'Izbrana dolžina',
        de: 'Ausgewählte Länge',
        sk: 'Vybraná dĺžka',
        es: 'Longitud seleccionada',
        ru: 'Выбранная длина',
        rs: 'Odabrana dužina',
        ua: 'Вибрана довжина',
        no: 'Utvalgt lengde',
        it: 'Lunghezza selezionata',
        se: 'Vald längd',
    },
    charactersPerLine: {
        us: 'characters/line',
        cz: 'znaků/řádek',
        pl: 'znaków/wiersz',
        hr: 'znakova/redaka',
        si: 'znakov/vrstic',
        de: 'Zeichen/Zeile',
        sk: 'znakov/riadok',
        es: 'caracteres/línea',
        ru: 'символы/ряд',
        rs: 'karaktera/red',
        ua: 'символи/ряд',
        no: 'tegn/linje',
        it: 'caratteri/riga',
        se: 'tecken/rad',
    },
    charactersPerSecond: {
        us: 'characters/second',
        cz: 'znaků/sekundu',
        pl: 'znaki/sekundę',
        hr: 'znakova/sekundi',
        si: 'znakov/sekund',
        de: 'Zeichen/Sekunde',
        sk: 'znakov/sekundu',
        es: 'caracteres/segundo',
        ru: 'знак/секунда',
        rs: 'znakova/sekundi',
        ua: 'знак/секунда',
        no: 'tegn/sekund',
        it: 'caratteri/secondo',
        se: 'tecken/sekund',
    },
    exportSrt50: {
        us: 'srt (50 characters/line)',
        cz: 'srt (50 znaků/řádek)',
        pl: 'srt (50 znaków/wiersz)',
        hr: 'srt (50 znakova/redaka)',
        si: 'srt (50 znakov/vrstic)',
        de: 'srt (50 Zeichen/Zeile)',
        sk: 'srt (50 znakov/riadok)',
        es: 'srt (50 caracteres/línea)',
        ru: 'srt (50 символов/ряд)',
        rs: 'srt (50 karaktera/red)',
        ua: 'srt (50 символів/ряд)',
        no: 'srt (50 tegn/linje)',
        it: 'srt (50 caratteri/riga)',
        se: 'srt (50 tecken/rad)',
    },
    exportSrt35: {
        us: 'srt (35 characters/line)',
        cz: 'srt (35 znaků/řádek)',
        pl: 'srt (35 znaków/wiersz)',
        hr: 'srt (35 znakova/redaka)',
        si: 'srt (35 znakov/vrstic)',
        de: 'srt (35 Zeichen/Zeile)',
        sk: 'srt (35 znakov/riadok)',
        es: 'srt (35 caracteres/línea)',
        ru: 'srt (35 символов/ряд)',
        rs: 'srt (35 karaktera/red)',
        ua: 'srt (35 символів/ряд)',
        no: 'srt (35 tegn/linje)',
        it: 'srt (35 caratteri/riga)',
        se: 'srt (35 tecken/rad)',
    },
    exportVtt50: {
        us: 'vtt (50 characters/line)',
        cz: 'vtt (50 znaků/řádek)',
        pl: 'vtt (50 znaków/wiersz)',
        hr: 'vtt (50 znakova/redaka)',
        si: 'vtt (50 znakov/vrstic)',
        de: 'vtt (50 Zeichen/Zeile)',
        sk: 'vtt (50 znakov/riadok)',
        es: 'vtt (50 caracteres/línea)',
        ru: 'vet (50 символов/ряд)',
        rs: 'vtt (50 karaktera/red)',
        ua: 'vet (50 символів/ряд)',
        no: 'vtt (50 tegn/linje)',
        it: 'vtt (50 caratteri/riga)',
        se: 'vtt (50 tecken/rad)',
    },
    exportVtt35: {
        us: 'vtt (35 characters/line)',
        cz: 'vtt (35 znaků/řádek)',
        pl: 'vtt (35 znaków/wiersz)',
        hr: 'vtt (35 znakova/redaka)',
        si: 'vtt (35 znakov/vrstic)',
        de: 'vtt (35 Zeichen/Zeile)',
        sk: 'vtt (35 znakov/riadok)',
        es: 'vtt (35 caracteres/línea)',
        ru: 'vtt (35 символов/ряд)',
        rs: 'vtt (35 karaktera/red)',
        ua: 'vtt (35 символів/ряд)',
        no: 'vtt (35 tegn/linje)',
        it: 'vtt (35 caratteri/riga)',
        se: 'vtt (35 tecken/rad)',
    },
    exportSrtFB: {
        us: 'srt (Facebook format)',
        cz: 'srt (Facebook formát)',
        pl: 'srt (Facebook format)',
        hr: 'srt (Facebook format)',
        si: 'srt (Facebook format)',
        de: 'srt (Facebook Format)',
        sk: 'srt (Facebook formát)',
        es: 'srt (formato para Facebook)',
        ru: 'srt (Формат Facebook)',
        rs: 'srt (Fejsbuk format)',
        ua: 'srt (Формат Facebook)',
        no: 'srt (Facebook formatet)',
        it: 'srt (Formato di Facebook)',
        se: 'srt (format för Facebook)',
    },
    convertToDash: {
        us: 'Convert to DASH (advanced feature)',
        cz: 'Konvertovat do DASH formátu (pokročilá funkce)',
        pl: 'Konwertuj do formatu DASH (opcja zaawansowana)',
        hr: 'Pretvori u DASH (napredna značajka)',
        si: 'Pretvori v DASH (napredna značilnost)',
        de: 'Ins DASH-Format konvertieren (erweiterte Funktion)',
        sk: 'Konvertovať do DASH formátu (pokročilá funkcia)',
        es: 'Convertir a DASH (función avanzada)',
        ru: 'Конвертировать в DASH (расширенные функции)',
        rs: 'Pretvori u DASH (napredna funkcija)',
        ua: 'Конвертувати в DASH (розширені функції)',
        no: 'Konvertere til DASH formatet (avansert funksjon)',
        it: 'Convertire in formato DASH (funzione avanzata)',
        se: 'Konvertera till DASH-formatet (avanserad funktion)',
    },
    shareProj: {
        us: 'Share project',
        cz: 'Sdílení projektu',
        pl: 'Udostępnianie projektów',
        hr: 'Podijeli projekt',
        si: 'Podeli projekt',
        de: 'Teilen der Projekte',
        sk: 'Zdieľanie projektu',
        es: 'Compartir proyecto',
        ru: 'Поделиться проектом',
        rs: 'Podeliti projekat',
        ua: 'Поділитись проектом',
        no: 'Dele prosjektet',
        it: 'Condivisione del progetto',
        se: 'Dela projekt',
    },
    addNotes: {
        us: 'Add notes',
        cz: 'Přidat poznámky',
        pl: 'Dodaj notatki',
        hr: 'Dodaj bilješke',
        si: 'Dodaj beležke',
        de: 'Anmerkungen hinzufügen',
        sk: 'Pridať poznámky',
        es: 'Añadir notas',
        ru: 'Добавить заметку',
        rs: 'Dodajte beleške',
        ua: 'Додати замітку',
        no: 'Legge til notater',
        it: 'Aggiungi note',
        se: 'Lägg till noter',
    },
    settingsLabel: {
        us: 'Settings',
        cz: 'Nastavení',
        pl: 'Ustawienia',
        hr: 'Postavke',
        si: 'Nastavitve',
        de: 'Einstellungen',
        sk: 'Nastavenia',
        es: 'Configuraciónes',
        ru: 'Настройки',
        rs: 'Podešavanja',
        ua: 'Налаштування',
        no: 'Innstillinger',
        it: 'Impostazioni',
        se: 'Inställningar',
    },
    spellcheck: {
        us: 'Enable browser spell check',
        cz: 'Povolit kontrolu pravopisu prohlížeče',
        pl: 'Zezwalaj na sprawdzanie pisowni',
        hr: 'Omogući provjeru pravopisa preglednika',
        si: 'Omogoči preverjanje črkovanja brskalnika',
        de: 'Rechtschreibprüfung des Browsers einschalten',
        sk: 'Povoliť kontrolu pravopisu prehliadače',
        es: 'Habilitar el corrector ortográfico del navegador',
        ru: 'Включить проверку орфографии браузером',
        rs: 'Omogući proveru pravopisa',
        ua: 'Увімкнути перевірку орфографії браузером',
        no: 'Aktivere stavekontroll i nettleseren',
        it: 'Abilitare il controllo ortografico del browser',
        se: 'Aktivera stavningskontroll i webbläsaren',
    },
    infoSpellcheck: {
        us: 'To use browser spell check, enable it in the browser settings.',
        cz: 'Pro použití kontroly pravopisu prohlížeče funkci nejdříve zapněte v nastavení prohlížeče.',
        pl: 'Aby korzystać ze sprawdzania pisowni w przeglądarce, włącz ją w ustawieniach przeglądarki.',
        hr: 'Da biste koristili provjeru pravopisa preglednika, omogućite je u postavkama preglednika.',
        si: 'Da bi uporabljali preverjanje črkovanja brskalnika, ga omogočite v nastavitvah brskalnika.',
        de: 'Um die Rechtschreibprüfung des Browsers zu verwenden, aktivieren Sie sie in den Browsereinstellungen.',
        sk: 'Ak chcete používať kontrolu pravopisu v prehliadači, najprv povoľte túto funkciu v nastaveniach prehliadača.',
        es: 'Para utilizar el corrector ortográfico del navegador, habilítelo en la configuración del navegador.',
        ru: 'Для использования проверки орфографии браузером включите ее в настройках браузера.',
        rs: 'Da biste koristili proveru pravopisa, omogućite je u podešavanjima brauzera.',
        ua: 'Щоб перевірити орфографію браузером, увімкніть її в налаштуваннях браузера.',
        no: 'Stavekontroll i nettleseren aktiveres i nettleserinnstillingene.',
        it: 'Per utilizzare il controllo ortografico del browser, occorre innanzitutto attivare la funzione nelle impostazioni del browser.',
        se: 'Stavningskontroll i webbläsaren kan aktiveras i webbläsarinställningar.',
    },
    caretSync: {
        us: 'Move caret with audio',
        cz: 'Pohyb kurzoru s audiem',
        pl: 'Przesuwanie kursora wraz z dźwiękiem',
        hr: 'Pomicanje pokazivača sa reprodukcijom audiozapisa',
        si: 'Premikanje kazalnika z reprodukcijo avdio zapisa',
        de: 'Cursor mit Audio bewegen',
        sk: 'Pohyb kurzora s audiom',
        es: 'Mover el cursor con audio',
        ru: 'Перемещение курсора с аудио',
        rs: 'Pomerajte znak sa zvukom',
        ua: 'Переміщення курсору з аудіо',
        no: 'Bevege markør med lyd',
        it: 'Movimento del cursore con audio',
        se: 'Flytta markör med ljud',
    },
    caretDelay: {
        us: 'Caret delay',
        cz: 'Zpoždění kurzoru',
        pl: 'Opóźnienie kursora',
        hr: 'Kašnjenje pokazivača',
        si: 'Zamujanje kazalnika',
        de: 'Cursorverzögerung',
        sk: 'Oneskorenie kurzora',
        es: 'Retraso del cursor',
        ru: 'Задержка курсора',
        rs: 'Odlaganje znaka',
        ua: 'Затримка курсору',
        no: 'Markørforsinkelse',
        it: 'Ritardo del cursore',
        se: 'Markörfördröjning',
    },
    enableTimestampLabels: {
        us: 'Show paragraph timestamps',
        cz: 'Zobrazit časové značky odstavců',
        pl: 'Pokaż znaczniki czasu akapitów',
        hr: 'Prikaži vremenske oznake odlomka',
        si: 'Pokaži časovne žige odstavkov',
        de: 'Absatz-Zeitstempel anzeigen',
        sk: 'Zobraziť časové značky odsekov',
        es: 'Mostrar marcas de tiempo de párrafo',
        ru: 'Показать временные метки абзаца',
        rs: 'Prikaži vremenske oznake pasusa',
        ua: 'Показати часові позначки абзацу',
        no: 'Vise tidsstempler for avsnitt',
        it: 'Mostra i timestamp dei paragrafi',
        se: 'Visa tidsstämplar för textstycken',
    },
    jumpInPlayback: {
        us: 'Jump in playback forward/backward',
        cz: 'Skok v přehrávání vpřed/vzad',
        pl: 'Skocz do przodu/do tyłu podczas odtwarzania',
        hr: 'Skok u reprodukciji naprijed/natrag',
        si: 'Skok v predvajanju naprej/nazaj',
        de: 'In der Wiedergabe vorwärts/rückwärts springen',
        sk: 'Skok dopredu/dozadu v prehrávaní',
        es: 'Saltar hacia adelante/atras en la reproducción',
        ru: 'Переход при воспроизведении вперед/назад',
        rs: 'Premotaj unapred/unazad',
        ua: 'Перехід при відтворенні вперед/назад',
        no: 'Hopp i avspillingsposisjonen fram/tilbake',
        it: 'Salta avanti/indietro nella riproduzione',
        se: 'Hoppa fram/tillbaka i uppspelningen',
    },
    shortcutsLabel: {
        us: 'Keyboard shortcuts',
        cz: 'Klávesové zkratky',
        pl: 'Skróty klawiszowe',
        hr: 'Prečaci na tipkovnici',
        si: 'Bližnjice na tipkovnici',
        de: 'Tastaturkürzel',
        sk: 'Klávesové skratky',
        es: 'Atajos de teclado',
        ru: 'Клавишные сокращения',
        rs: 'Prečice na tastaturi',
        ua: 'Клавішні скорочення',
        no: 'Snarveistaster',
        it: 'Scorciatoie da tastiera',
        se: 'Kortkommandon',
    },
    tab: {
        us: 'Play or pause playback',
        cz: 'Spustí nebo pozastaví přehrávání',
        pl: 'Odtwórz lub zatrzymaj audio',
        hr: 'Pokreni ili pauziraj snimku',
        si: 'Začni ali pavziraj posnetek',
        de: 'Wiedergabe starten oder stoppen',
        sk: 'Spustí alebo pozastaví prehrávanie',
        es: 'Reproducir o pausar la reproducción',
        ru: 'Проиграть или поставить на паузу воспроизведение',
        rs: 'Reprodukujte ili pauzirajte reprodukciju',
        ua: 'Програти чи поставити на паузу відтворення',
        no: 'Starte eller pause avspillingen',
        it: 'Avviare o mettere in pausa la riproduzione',
        se: 'Starta eller pausa uppspelningen',
    },
    shiftTab: {
        us: 'Start playback from text cursor',
        cz: 'Spustí přehrávání z textového kurzoru',
        pl: 'Odtwórz dźwięk od pozycji kursora',
        hr: 'Započni izvođenje od pokazivača',
        si: 'Začni predvajanje od kazalnika',
        de: 'Weidergabe vom Cursor starten',
        sk: 'Spustí prehrávanie z textového kurzora',
        es: 'Iniciar la reproducción desde el cursor de texto',
        ru: 'Начать воспроизведение с текстового курсора',
        rs: 'Pokrenite reporodukciju sa kursora za tekst',
        ua: 'Почати відтворення з текстового курсору',
        no: 'Starte avspillingen fra tekstmarkøren',
        it: 'Avviare la riproduzione dal cursore del testo',
        se: 'Starta uppspelningen från textmarkören',
    },
    ctrlLeft: {
        us: 'Jump one word to the left',
        cz: 'Skočí o jedno slovo doleva',
        pl: 'Skocz o jedno słowo w lewo',
        hr: 'Skočite jednu riječ ulijevo',
        si: 'Skočite eno besedo v levo',
        de: 'Ein Wort nach links',
        sk: 'Skočí o jedno slovo doľava',
        es: 'Salta una palabra a la izquierda',
        ru: 'Пропустить одно слово влево',
        rs: 'Preskočite jednu reč ulevo',
        ua: 'Пропустити одне слово вліво',
        no: 'Hoppe ett ord til venstre',
        it: 'Salta una parola a sinistra',
        se: 'Hoppa ett ord åt vänster',
    },
    ctrlRight: {
        us: 'Jump one word to the right',
        cz: 'Skočí o jedno slovo doprava',
        pl: 'Skocz o jedno słowo w prawo',
        hr: 'Skočite jednu riječ udesno',
        si: 'Skočite eno besedo v desno',
        de: 'Ein Wort nach rechts',
        sk: 'Skočí o jedno slovo doprava',
        es: 'Salta una palabra a la derecha',
        ru: 'Пропустить одно слово вправо',
        rs: 'Preskočit jednu reč udesno',
        ua: 'Пропустити одне слово праворуч',
        no: 'Hoppe ett ord til høyre',
        it: 'Salta una parola a destra',
        se: 'Hoppa ett ord åt höger',
    },
    ctrlZ: {
        us: 'Undo',
        cz: 'Vrátit změny',
        pl: 'Cofnij',
        hr: 'Poništi',
        si: 'Prekliči',
        de: 'Rückgängigmachen',
        sk: 'Vrátiť zmeny',
        es: 'Deshacer',
        ru: 'Отменить изменения',
        rs: 'Poništite',
        ua: 'відмінити зміни',
        no: 'Angre endringer',
        it: 'Annullare le modifiche',
        se: 'Ångra',
    },
    ctrlY: {
        us: 'Redo',
        cz: 'Zopakovat změny',
        pl: 'Powtórz',
        hr: 'Ponovi',
        si: 'Ponovi',
        de: 'Wiederherstellen',
        sk: 'Zopakovať zmeny',
        es: 'Rehacer',
        ru: 'Повторить изменения',
        rs: 'Uradite ponovo',
        ua: 'Повторити зміни',
        no: 'Gjør om endringene',
        it: 'Ripetere le modifiche',
        se: 'Gör om',
    },
    ctrlH: {
        us: 'Find and replace',
        cz: 'Najít a nahradit',
        pl: 'Znajdź i zamień',
        hr: 'Nađi i zamijeni',
        si: 'Najdi in zamenjaj',
        de: 'Suchen und Ersetzen',
        sk: 'Nájsť a nahradiť',
        es: 'Buscar y reemplazar',
        ru: 'Найти и заменить',
        rs: 'Nađi i zameni',
        ua: 'Знайти та замінити',
        no: 'Finne og erstatte',
        it: 'Trovare e sostituire',
        se: 'Sök och ersätt',
    },
    altJ: {
        us: 'Move backward in playback',
        cz: 'Posune přehrávání dozadu',
        pl: 'Przesuń odtwarzacz do tyłu',
        hr: 'Kretanje prema natrag u izvođenju',
        si: 'Premikanje nazaj v predvajanju',
        de: 'Wiedergabe zurück bewegen',
        sk: 'Posunie prehrávanie dozadu',
        es: 'Retroceder en la reproducción',
        ru: 'Вернуться назад в воспроизведении',
        rs: 'Premotajte unazad pri reprodukciji',
        ua: 'Повернутись назад у відтворенні',
        no: 'Bevege tilbake i avspillingen',
        it: 'Sposta la riproduzione indietro',
        se: 'Flytta bakåt i uppspelningen',
    },
    altL: {
        us: 'Move forward in playback',
        cz: 'Posune přehrávání dopředu',
        pl: 'Przesuń odtwarzacz do przodu',
        hr: 'Kretanje prema naprijed u izvođenju',
        si: 'Premikanje naprej v predvajanju',
        de: 'Wiedergabe vorwärts bewegen',
        sk: 'Posunie prehrávanie dopredu',
        es: 'Avanzar en la reproducción',
        ru: 'Продвинуться вперед в воспроизведении',
        rs: 'Premotajte unapred pri reprodukciji',
        ua: 'Просунутися вперед у відтворенні',
        no: 'Bevege fram i avspillingen',
        it: 'Sposta la riproduzione avanti',
        se: 'Flytta framåt i uppspelningen',
    },
    altComma: {
        us: 'Move backward in playback by one frame',
        cz: 'Posune přehrávání dozadu o jeden frame',
        pl: 'Przesuwa odtwarzanie o jedną klatkę do tyłu',
        hr: 'Pomicanje unatrag u reprodukciji za jedan kadar',
        si: 'Premikanje nazaj v predvajanju za en okvir',
        de: 'In der Wiedergabe um ein Frame rückwärts gehen',
        sk: 'Posunie prehrávanie o jeden frame späť',
        es: 'Retroceder en la reproducción un cuadro',
        ru: 'Переместиться назад при воспроизведении на один кадр',
        rs: 'Pomeri unazad pomoću plejbeka frejm po frejm',
        ua: 'Перейти назад у відтворенні на один кадр',
        no: 'Bevege tilbake en ramme i avspillingen',
        it: 'Sposta la riproduzione indietro di un fotogramma',
        se: 'Flytta bakåt en ram i uppspelningen',
    },
    altPeriod: {
        us: 'Move forward in playback by one frame',
        cz: 'Posune přehrávání dopředu o jeden frame',
        pl: 'Przesuwa odtwarzanie o jedną klatkę do przodu',
        hr: 'Pomicanje unaprijed u reprodukciji za jedan kadar',
        si: 'Premikanje naprej v predvajanju za en okvir',
        de: 'In der Wiedergabe um ein Frame vorwärts gehen',
        sk: 'Posunie prehrávanie o jeden frame dopredu',
        es: 'Avanzar en la reproducción un cuadro',
        ru: 'Переместиться вперед при воспроизведении на один кадр',
        rs: 'Pomeri unapred pomoću plejbeka frejm po frejm',
        ua: 'Перехід у відтворенні на один кадр вперед',
        no: 'Bevege fram en ramme i avspillingen',
        it: 'Sposta la riproduzione avanti di un fotogramma',
        se: 'Flytta framåt en ram i uppspelningen',
    },
    altN: {
        us: 'Decrease playback speed',
        cz: 'Sníží rychlost přehrávání',
        pl: 'Zmniejsz prędkość odtwarzania',
        hr: 'Smanjite brzinu izvođenja',
        si: 'Zmanjšajte hitrost predvajanja',
        de: 'Wiedergabegeschwindigkeit verringern',
        sk: 'Zníži rýchlosť prehrávania',
        es: 'Disminuir la velocidad de reproducción',
        ru: 'Уменьшить скорость воспроизведения',
        rs: 'Smanjite brzinu reprodukcije',
        ua: 'Зменшити швидкість відтворення',
        no: 'Senke avspillingshastighet',
        it: 'Ridurre la velocità di riproduzione',
        se: 'Sänka uppspelningshastigheten',
    },
    altM: {
        us: 'Increase playback speed',
        cz: 'Zvýší rychlost přehrávání',
        pl: 'Zwiększ prędkość odtwarzania',
        hr: 'Povećajte brzinu izvođenja',
        si: 'Povečajte hitrost predvajanja',
        de: 'Wiedergabegeschwindigkeit erhöhen',
        sk: 'Zvýši rýchlosť prehrávania',
        es: 'Aumentar la velocidad de reproducción',
        ru: 'Увеличить скорость воспроизведения',
        rs: 'Povećajte brzinu reprodukcije',
        ua: 'Збільшити швидкість відтворення',
        no: 'Øke avspillingshastighet',
        it: 'Aumentare la velocità di riproduzione',
        se: 'Öka uppspelningshastigheten',
    },
    shiftEnter: {
        us: 'Assign speaker',
        cz: 'Přidělí mluvčího',
        pl: 'Przypisz mówcę',
        hr: 'Dodijeli govornika',
        si: 'Dodeli govorca',
        de: 'Sprecher zuweisen',
        sk: 'Priraďiť rečníka',
        es: 'Asignar hablante',
        ru: 'Назначить спикера',
        rs: 'Dodelite govornika',
        ua: 'Призначити спікера',
        no: 'Tildele en taler',
        it: 'Assegnazione dello speaker',
        se: 'Tilldela talare',
    },
    insertUnknownSpeaker: {
        us: 'Insert unknown speaker',
        cz: 'Vložit neznámého mluvčího',
        pl: 'Włóż nieznanego mówcę',
        hr: 'Unesite nepoznatog govornika',
        si: 'Vstavite neznanega govorca',
        de: 'Unbekannten Sprecher einfügen',
        sk: 'Vložiť neznámeho rečníka',
        es: 'Insertar un hablante desconocido',
        ru: 'Вставить неизвестного спикера',
        rs: 'Unesi nepoznatog govornika',
        ua: 'Вставити невідомого спікера',
        no: 'Sette inn en ukjent taler',
        it: 'Inserire speaker sconosciuto',
        se: 'Infoga okänd talare',
    },
    ctrlClickLabel: {
        us: 'Click',
        cz: 'Klik',
        pl: 'Klik',
        hr: 'Klik',
        si: 'Klik',
        de: 'Klick',
        sk: 'Klik',
        es: 'Clic',
        ru: 'Нажмите',
        rs: 'Klik',
        ua: 'Натисніть',
        no: 'Klikk',
        it: 'Cliccare',
        se: 'Klick',
    },
    rightClick: {
        us: 'Right-click',
        cz: 'Pravé tlačítko myši',
        pl: 'Prawy przycisk myszy',
        hr: 'Desni klik',
        si: 'Desni klik',
        de: 'Rechtsklick',
        sk: 'Pravé tlačidlo myši',
        es: 'Botón derecho del mouse',
        ru: 'Правая кнопка мыши',
        rs: 'Desni klik',
        ua: 'Права кнопка миші',
        no: 'Høyreklikk',
        it: 'Tasto destro del mouse',
        se: 'Höger musknapp',
    },
    ctrlClick: {
        us: 'Change all instances of the speaker',
        cz: 'Změní všechen výskyt daného mluvčího',
        pl: 'Zmieni wszystkie wystąpienia danego mówcy',
        hr: 'Promijenite sve govore odabranog govornika',
        si: 'Spremenite vse govore izbranega govorca',
        de: 'Alle Instanzen des Sprechers ändern',
        sk: 'Zmení všetky výskyty daného rečníka',
        es: 'Cambiar todas las instancias del hablante',
        ru: 'Изменить все появления данного спикера',
        rs: 'Promenite sve instance govornika',
        ua: 'Змінити всі появи цього спікера',
        no: 'Endre alle forekomster av taleren',
        it: 'Modifica tutte le occorrenze di un determinato speaker',
        se: 'Ändra alla förekomster av talaren',
    },
    ctrlOneTwo: {
        us: 'Heading 1/2',
        cz: 'Nadpis 1/2',
        pl: 'Nagłówek 1/2',
        hr: 'Naslov 1/2',
        si: 'Naslov 1/2',
        de: 'Überschrift 1/2',
        sk: 'Nadpis 1/2',
        es: 'Título 1/2',
        ru: 'Заголовок 1/2',
        rs: 'Naslov 1/2',
        ua: 'Заголовок 1/2',
        no: 'Overskrift 1/2',
        it: 'Titolo 1/2',
        se: 'Rubrik 1/2',
    },
    ctrlShiftOneTwo: {
        us: 'Copy selected text to a new heading',
        cz: 'Vloží vybraný text do nového nadpisu',
        pl: 'Skopiuje oznaczony tekst do nowego nagłówka',
        hr: 'Kopirajte odabrani tekst u novi naslov',
        si: 'Kopirajte izbrano besedilo v novi naslov',
        de: 'Kopiert ausgewählten Text in neue Überschrift',
        sk: 'Vloží vybratý text do nového nadpisu',
        es: 'Copiar el texto seleccionado a un nuevo título',
        ru: 'Скопировать выбранный текст в новый заголовок',
        rs: 'Kopirajte izabrani tekst u novi naslov',
        ua: 'Копіювати вибраний текст у новий заголовок',
        no: 'Kopier den valgte teksten til en ny overskrift',
        it: 'Inserisce il testo selezionato in una nuova intestazione',
        se: 'Kopierar den valda texten till en ny rubrik',
    },
    ctrlG: {
        us: '(De)capitalize letters in this order: lower case ⟶ Title case ⟶ UPPER CASE',
        cz: 'Zvětší nebo zmenší písmena v pořadí: malá písmena ⟶ Velké písmeno na začátku ⟶ VELKÁ PÍSMENA',
        pl: 'Zwiększanie lub zmniejszanie liter w kolejności: małe litery ⟶ Wielka litera na początku ⟶ WIELKIE LITERY',
        hr: 'Povećajte ili smanjite slova sljedećim redoslijedom: mala slova ⟶ Velika slova na početku ⟶ VELIKA SLOVA',
        si: 'Povečaj ali zmanjšaj črke v vrstnem redu: male črke ⟶ Velike črke na začetku ⟶ VELIKE ČRKE',
        de: '(Groß-)Schreiben in dieser Reihenfolge: kleinbuchstaben ⟶ Großbuchstabe am Anfang ⟶ GROSSBUCHSTABEN',
        sk: 'Zväčší alebo zmenší písmená v poradí: malé písmená ⟶ Veľké písmeno na začiatku ⟶ VEĽKÉ PÍSMENÁ',
        es: '(Des)capitalizar letras en este orden: minúsculas ⟶ Caso de título ⟶ MAYÚSCULAS',
        ru: 'Увеличить или уменьшить буквы по порядку: строчные ⟶ Большая буква в начале ⟶ ВЕРХНИЙ РЕГИСТР',
        rs: 'Stavljanje velikih i malih slova ovim redosledom: mala slova ⟶ Početno veliko slovo ⟶ VELIKA SLOVA',
        ua: 'Збільшіть або зменшіть літери в такому порядку: малі літери ⟶ Заголовні ⟶ ВЕЛИКI ЛIТЕРИ',
        no: 'Bruk av små og store bokstaver i denne rekkefølgen: små bokstaver ⟶ Store forbokstaver ⟶ STORE BOKSTAVER',
        it: "Aumenta o diminuisce le lettere in ordine: minuscole ⟶ Maiuscole all'inizio ⟶ MAIUSCOLE",
        se: 'Byter bokstäverna mot stora eller små i följande ordning: gemener ⟶ Versal i början ⟶ VERSALER',
    },
    ctrlI: {
        us: 'Inserts sign: (???)',
        cz: 'Vloží znak: (???)',
        pl: 'Włoży (???)',
        hr: 'Umetni znak: (???)',
        si: 'Vstavi znak (???)',
        de: '(???) Zeichen einsetzen',
        sk: 'Vloží znak (???)',
        es: 'Insertar el signo: (???)',
        ru: 'Вставить (???)',
        rs: 'Umetnite (???)',
        ua: 'Вставити (???)',
        no: 'Setter inn tegn: (???)',
        it: 'Inserisce il carattere: (???)',
        se: 'Infogar tecken: (???)',
    },
    altEnter: {
        us: 'Insert/change speaker before paragraph',
        cz: 'Vloží nebo změní mluvčího před odstavcem',
        pl: 'Włoży lub zmieni mówcę przed akapitem',
        hr: 'Umetni/promijeni govornika prije odlomka',
        si: 'Vstavi/spremeni govorca prej odlomka',
        de: 'Sprecher vor Absatz einsetzen/ändern',
        sk: 'Vloží alebo zmení rečníka pred odsekom',
        es: 'Insertar/cambiar el hablante antes del párrafo',
        ru: 'Вставить или изменить спикера перед абзацем',
        rs: 'Umetnite/promenite govornika pre pasusa',
        ua: 'Вставити або змінити спікера перед абзацом',
        no: 'Sette inn/endre taler før avsnitt',
        it: 'Inserisce o modifica lo speaker prima del paragrafo',
        se: 'Infoga/ändra talare före textstycke',
    },
    ctrlAltEnter: {
        us: 'Change speaker before paragraph',
        cz: 'Změní mluvčího před odstavcem',
        pl: 'Zmieni mówcę przed akapitem',
        hr: 'Promijeni govornika prije odlomka',
        si: 'Spremeni govorca prej odlomka',
        de: 'Sprecher vor Absatz ändern',
        sk: 'Zmení rečníka pred odsekom',
        es: 'Cambiar el hablante antes del párrafo',
        ru: 'Изменить спикера перед абзацем',
        rs: 'Promenite govornika pre pasusa',
        ua: 'Змінити спікера перед абзацом',
        no: 'Endrer taler før avsnitt',
        it: 'Cambia lo speaker prima di un paragrafo',
        se: 'Ändra talare före textstycke',
    },
    ctrlK: {
        us: '',
        cz: 'Zamění i/y na konci slova',
        pl: '',
        hr: '',
        si: '',
        de: '',
        sk: 'Zamení i/y na konci slova',
        es: '',
        ru: '',
        rs: '',
        ua: '',
        no: '',
        it: '',
        se: '',
    },
    zKey: {
        us: 'Z',
        cz: 'Z',
        pl: 'Z',
        hr: 'Z',
        si: 'Z',
        de: 'Z',
        sk: 'Z',
        es: 'Z',
        ru: 'Я',
        rs: 'Z',
        ua: 'Я',
        no: 'Z',
        it: 'Z',
        se: 'Z',
    },
    yKey: {
        us: 'Y',
        cz: 'Y',
        pl: 'Y',
        hr: 'Y',
        si: 'Y',
        de: 'Y',
        sk: 'Y',
        es: 'Y',
        ru: 'Н',
        rs: 'Y',
        ua: 'Н',
        no: 'Y',
        it: 'Y',
        se: 'Y',
    },
    hKey: {
        us: 'H',
        cz: 'H',
        pl: 'H',
        hr: 'H',
        si: 'H',
        de: 'H',
        sk: 'H',
        es: 'H',
        ru: 'Р',
        rs: 'H',
        ua: 'Р',
        no: 'H',
        it: 'H',
        se: 'H',
    },
    lKey: {
        us: 'L',
        cz: 'L',
        pl: 'L',
        hr: 'L',
        si: 'L',
        de: 'L',
        sk: 'L',
        es: 'L',
        ru: 'Д',
        rs: 'L',
        ua: 'Д',
        no: 'L',
        it: 'L',
        se: 'L',
    },
    jKey: {
        us: 'J',
        cz: 'J',
        pl: 'J',
        hr: 'J',
        si: 'J',
        de: 'J',
        sk: 'J',
        es: 'J',
        ru: 'О',
        rs: 'J',
        ua: 'О',
        no: 'J',
        it: 'J',
        se: 'J',
    },
    mKey: {
        us: 'M',
        cz: 'M',
        pl: 'M',
        hr: 'M',
        si: 'M',
        de: 'M',
        sk: 'M',
        es: 'M',
        ru: 'Ь',
        rs: 'M',
        ua: 'Ь',
        no: 'M',
        it: 'M',
        se: 'M',
    },
    nKey: {
        us: 'N',
        cz: 'N',
        pl: 'N',
        hr: 'N',
        si: 'N',
        de: 'N',
        sk: 'N',
        es: 'N',
        ru: 'Т',
        rs: 'N',
        ua: 'Т',
        no: 'N',
        it: 'N',
        se: 'N',
    },
    gKey: {
        us: 'G',
        cz: 'G',
        pl: 'G',
        hr: 'G',
        si: 'G',
        de: 'G',
        sk: 'G',
        es: 'G',
        ru: 'П',
        rs: 'G',
        ua: 'П',
        no: 'G',
        it: 'G',
        se: 'G',
    },
    iKey: {
        us: 'I',
        cz: 'I',
        pl: 'I',
        hr: 'I',
        si: 'I',
        de: 'I',
        sk: 'I',
        es: 'I',
        ru: 'Ш',
        rs: 'I',
        ua: 'Ш',
        no: 'I',
        it: 'I',
        se: 'I',
    },
    bKey: {
        us: 'B',
        cz: 'B',
        pl: 'B',
        hr: 'B',
        si: 'B',
        de: 'B',
        sk: 'B',
        es: 'B',
        ru: 'И',
        rs: 'B',
        ua: 'И',
        no: 'B',
        it: 'B',
        se: 'B',
    },
    qKey: {
        us: 'Q',
        cz: 'Q',
        pl: 'Q',
        hr: 'Q',
        si: 'Q',
        de: 'Q',
        sk: 'Q',
        es: 'Q',
        ru: 'Й',
        rs: 'Q',
        ua: 'Й',
        no: 'Q',
        it: 'Q',
        se: 'Q',
    },
    mouseWheel: {
        us: 'Mouse wheel',
        cz: 'Kolečko myši',
        pl: 'Kółko myszki',
        hr: 'Kotačić miša',
        si: 'Miškin kolešček',
        de: 'Mausrad',
        sk: 'Koliesko myši',
        es: 'Rueda del ratón',
        ru: 'Колесико мыши',
        rs: 'Točkić na mišu',
        ua: 'Колесо миші',
        no: 'Musehjul',
        it: 'Rotella del mouse',
        se: 'Mushjul',
    },
    movePlayback: {
        us: 'Move playback on sound wave',
        cz: 'Posun přehrávání na zvukové vlně',
        pl: 'Przesunięcie odtwarzania na fali dźwiękowej',
        hr: 'Pomicanje reprodukcije na zvučnom valu',
        si: 'Premikanje predvajanja na zvočnem valu',
        de: 'Wiedergabeverschiebung auf der Schallwelle',
        sk: 'Posunutie prehrávania na zvukovej vlne',
        es: 'Mover reproducción en onda de sonido',
        ru: 'Перемещение воспроизведения по звуковой волне',
        rs: 'Pomeri plejbek na jačinu zvuka',
        ua: 'Переміщення відтворення на звукову хвилю',
        no: 'Flytte avspillingen på lydbølgen',
        it: "Spostamento della riproduzione dell'onda sonora",
        se: 'Flytta uppspelningen på ljudvågen',
    },
    replayCaption: {
        us: 'Replay subtitle',
        cz: 'Opakovaně přehrát titulek',
        pl: 'Odtwórz napis',
        hr: 'Ponovno reproduciraj titl',
        si: 'Ponovno predvajaj podnaslov',
        de: 'Untertitel wiederholen',
        sk: 'Opätovné prehratie titulku',
        es: 'Reproducir subtítulo de nuevo',
        ru: 'Воспроизведение субтитров',
        rs: 'Ponovo reprodukuj titl',
        ua: 'Відтворити субтитри',
        no: 'Spille underteksten igjen',
        it: 'Titolo del replay',
        se: 'Spela upp undertexten igen',
    },
    loadingSoundWave: {
        us: 'Loading sound wave',
        cz: 'Načítám zvukovou vlnu',
        pl: 'Wczytuję falę dźwiękową',
        hr: 'Učitavanje zvučnog vala',
        si: 'Nalaganje zvočnega vala',
        de: 'Laden der Schallwelle',
        sk: 'Načítam zvukovú vlnu',
        es: 'Cargando onda de sonido',
        ru: 'Загрузка звуковой волны',
        rs: 'Učitavanje zvučnog talasa',
        ua: 'Завантаження звукової хвилі',
        no: 'Lydbølgen lastes opp',
        it: 'Onda sonora di carico',
        se: 'Ljudvågen laddas',
    },
    failedLoadingSoundWave: {
        us: 'Failed to generate sound wave',
        cz: 'Nepodařilo se vytvořit zvukovou vlnu',
        pl: 'Nie udało się wygenerować fali dźwiękowej',
        hr: 'Generiranje zvučnog vala nije uspjelo',
        si: 'Ustvarjanje zvočnega vala ni uspelo',
        de: 'Schallwelle konnte nicht generiert werden',
        sk: 'Nepodarilo sa vytvoriť zvukovú vlnu',
        es: 'No se pudo generar la onda de sonido',
        ru: 'Не удалось сгенерировать звуковую волну',
        rs: 'Generisanje zvučnog talasa nije uspelo',
        ua: 'Не вдалося згенерувати звукову хвилю',
        no: 'Opplasting av lydbølgen mislyktes',
        it: "Impossibile creare un'onda sonora",
        se: 'Det gick inte att skapa ljudvågen',
    },
    moveWave: {
        us: 'Move sound wave',
        cz: 'Posun zvukové vlny',
        pl: 'Przesunięcie fali dźwiękowej',
        hr: 'Pomicanje zvučnog vala',
        si: 'Premakni zvočni val',
        de: 'Schallwelle verschieben',
        sk: 'Posun zvukovej vlny',
        es: 'Mover onda de sonido',
        ru: 'Переместить звуковую волну',
        rs: 'Pomeri jačinu zvuka',
        ua: 'Перемістити звукову хвилю',
        no: 'Flytte lydbølgen',
        it: "Spostamento dell'onda sonora",
        se: 'Flytta ljudvågen',
    },
    space: {
        us: 'Space',
        cz: 'Mezerník',
        pl: 'Spacja',
        hr: 'Razmak',
        si: 'Razdalja',
        de: 'Leerzeichen',
        sk: 'Medzerník',
        es: 'Espacio',
        ru: 'Пробел',
        rs: 'Razmak',
        ua: 'Пробел',
        no: 'Mellomromstast',
        it: 'Barra spaziatrice',
        se: 'Mellanslagstangent',
    },
    openBracketKey: {
        us: '[',
        cz: 'ú',
        pl: 'ż',
        hr: 'š',
        si: 'š',
        de: 'ü',
        sk: 'ú',
        es: '`',
        ru: 'х',
        rs: 'š',
        ua: 'Х',
        no: 'å',
        it: 'è',
        se: 'å',
    },
    closeBracketKey: {
        us: ']',
        cz: ')',
        pl: 'ś',
        hr: 'đ',
        si: 'đ',
        de: '+',
        sk: 'ä',
        es: '+',
        ru: 'ъ',
        rs: 'đ',
        ua: 'Ї',
        no: '¨',
        it: '+',
        se: '¨',
    },
    periodKey: {
        us: 'Period',
        cz: 'Tečka',
        pl: 'Kropka',
        hr: 'Točka',
        si: 'Pika',
        de: 'Punkt',
        sk: 'Bodka',
        es: 'Punto',
        ru: 'Точка',
        rs: 'Tačka',
        ua: 'Крапка',
        no: 'Punktum',
        it: 'Punto',
        se: 'Punkt',
    },
    commaKey: {
        us: 'Comma',
        cz: 'Čárka',
        pl: 'Przecinek',
        hr: 'Zarez',
        si: 'Vejica',
        de: 'Komma',
        sk: 'Čiarka',
        es: 'Coma',
        ru: 'Запятая',
        rs: 'Zarez',
        ua: 'Кома',
        no: 'Komma',
        it: 'Virgola',
        se: 'Komma',
    },
    nbsp: {
        us: 'Non-breaking space',
        cz: 'Nedělitelná mezera',
        pl: 'Spacja nierozdzielająca',
        hr: 'Neprekidajući razmak',
        si: 'Neprekinjeni presledek',
        de: 'Geschütztes Leerzeichen',
        sk: 'Nezalomiteľná medzera',
        es: 'Espacio duro',
        ru: 'Неразрывный пробел',
        rs: 'Neprekidajući razmak',
        ua: 'Нерозривний пробіл',
        no: 'Hardt mellomrom',
        it: 'Divario indivisibile',
        se: 'Icke-brytande mellanslag',
    },
    bindWordToPlayback: {
        us: 'Bind selected word to current playback time',
        cz: 'Navázat vybrané slovo na současný čas přehrávání',
        pl: 'Powiąż wybrane słowo z bieżącym czasem odtwarzania',
        hr: 'Vežite odabranu riječ s trenutnim vremenom reprodukcije',
        si: 'Povežite izbrano besedo s trenutnim časom predvajanja',
        de: 'Ausgewähltes Wort an die aktuelle Wiedergabezeit binden',
        sk: 'Naviazať vybrané slovo na aktuálny čas prehrávania',
        es: 'Vincula la palabra seleccionada al tiempo de reproducción actual',
        ru: 'Связать выбранное слово с данным временем воспроизведения',
        rs: 'Povežite izabranu reč sa trenutnim vremenom reprodukcije',
        ua: "Зв'язати вибране слово з цим часом відтворення",
        no: 'Binde det valgte ordet sammen med den gjeldende avspillingstiden',
        it: 'Collegare la parola selezionata al tempo di riproduzione attuale',
        se: 'Bind det valda ordet till aktuell uppspelningstid',
    },
    startWordSooner: {
        us: 'Move selected word 100\xa0ms sooner in time',
        cz: 'Posunout vybrané slovo v čase o\xa0100\xa0ms dříve',
        pl: 'Przenieś wybrane słowo o\xa0100\xa0ms wcześniej',
        hr: 'Pomaknite odabranu riječ 100\xa0ms ranije',
        si: 'Premaknite izbrano besedo za\xa0100\xa0ms prej',
        de: 'Ausgewähltes Wort um\xa0100\xa0ms nach vorne verschieben',
        sk: 'Posunúť vybrané slovo o\xa0100\xa0ms skôr v čase',
        es: 'Mueva la palabra seleccionada 100\xa0ms antes en el tiempo',
        ru: 'переместить выбранное слово во времени на 100\xa0мс назад',
        rs: 'Premestite izabranu reč 100\xa0ms skorije u vremenu',
        ua: 'перемістити вибране слово в часі на 100\xa0мс тому',
        no: 'Flytte de valgte ordene 100\xa0ms tidligere i avspillingstiden',
        it: 'Sposta la parola selezionata 100\xa0ms prima nel tempo',
        se: 'Flytta det valda ordet 100\xa0ms bakåt',
    },
    startWordLater: {
        us: 'Move selected word 100\xa0ms later in time',
        cz: 'Posunout vybrané slovo v čase o\xa0100\xa0ms později',
        pl: 'Przenieś wybrane słowo o\xa0100\xa0ms później',
        hr: 'Pomaknite odabranu riječ 100\xa0ms kasnije',
        si: 'Premaknite izbrano besedo za\xa0100\xa0ms pozneje',
        de: 'Ausgewähltes Wort um\xa0100\xa0ms nach hinten verschieben',
        sk: 'Posunúť vybrané slovo o\xa0100\xa0ms neskôr v čase',
        es: 'Mueva la palabra seleccionada 100\xa0ms más tarde en el tiempo',
        ru: 'переместить выбранное слово во времени на 100\xa0мс вперёд',
        rs: 'Premestite izabranu reč 100\xa0ms kasnije u vremenu',
        ua: 'перемістити вибране слово в часі на 100\xa0мс вперед',
        no: 'Flytte de valgte ordene 100\xa0ms senere i avspillingstiden',
        it: 'Sposta la parola selezionata nel tempo 100\xa0ms dopo',
        se: 'Flytta det valda ordet 100\xa0ms framåt',
    },
    orWord: {
        us: ' or ',
        cz: ' nebo ',
        pl: ' lub ',
        hr: ' ili ',
        si: ' ali ',
        de: ' oder ',
        sk: ' alebo ',
        es: ' o ',
        ru: ' или ',
        rs: ' ili ',
        ua: ' або ',
        no: ' eller ',
        it: ' oppure ',
        se: ' eller ',
    },
    convertingMedia: {
        us: 'Converting medium...',
        cz: 'Zpracovávám nahrávku...',
        pl: 'Przetwarzam nagranie...',
        hr: 'Pretvaranje medija...',
        si: 'Pretvorba medija...',
        de: 'Medium wird konvertiert...',
        sk: 'Zpracovávám nahrávku...',
        es: 'Conversión de medio ...',
        ru: 'Конвертирую запись...',
        rs: 'Konvertovanje medijuma...',
        ua: 'Конвертую запис...',
        no: 'Konverterer mediet...',
        it: 'Sto elaborando la registrazione...',
        se: 'Konverterar inspelningen...',
    },
    loading: {
        us: 'Loading...',
        cz: 'Načítám...',
        pl: 'Wczytuję...',
        hr: 'Učitavanje...',
        si: 'Nalaganje...',
        de: 'Laden...',
        sk: 'Načítavam...',
        es: 'Cargando...',
        ru: 'Загрузка...',
        rs: 'Učitavanje...',
        ua: 'Завантаження...',
        no: 'Lastes opp...',
        it: 'Caricamento in corso...',
        se: 'Laddar...',
    },
    recordingNotPlayable: {
        us: 'Recording unavailable',
        cz: 'Nahrávka není dostupná',
        pl: 'Nagranie nie jest dostępne',
        hr: 'Snimka nije dostupna',
        si: 'Posnetek ni na voljo',
        de: 'Aufnahme nicht verfügbar',
        sk: 'Nahrávka nedostupná',
        es: 'Grabación no disponible',
        ru: 'Запись недоступна',
        rs: 'Snimak nije dostupan',
        ua: 'Запис недоступний',
        no: 'Innspillingen ikke tilgjengelig',
        it: 'La registrazione non è disponibile',
        se: 'Inspelningen är inte tillgänglig',
    },
    play: {
        us: 'Play/Pause (Tab)',
        cz: 'Spustit/Zastavit (Tab)',
        pl: 'Odtwórz/Zatrzymaj (Tab)',
        hr: 'Pokreni/Pauza (Tab)',
        si: 'Začni/Pavza (Tab)',
        de: 'Starten/Stoppen (Tab)',
        sk: 'Spustiť/Zastaviť (Tab)',
        es: 'Reproducir/Pausar (Tab)',
        ru: 'Проиграть/Поставить на паузу (Tab)',
        rs: 'Pusti/pauziraj (Tab)',
        ua: 'Програти/Поставити на паузу (Tab)',
        no: 'Starte/Pause (Tab)',
        it: 'Avvio/Arresto (Tab)',
        se: 'Spela/Pausa (Tab)',
    },
    playCursor: {
        us: 'Play from cursor (Shift+Tab)',
        cz: 'Spustit z kurzoru (Shift+Tab)',
        pl: 'Odtwórz od pozycji kursora (Shift+Tab)',
        hr: 'Izvođenje od pokazivača (Shift+Tab)',
        si: 'Predvajanje od kurzorja (Shift+Tab)',
        de: 'Wiedergabe von Cursorposition starten (Shift+Tab)',
        sk: 'Spustiť z kurzora (Shift+Tab)',
        es: 'Reproducir desde el cursor (Shift+Tab)',
        ru: 'Воспроизвести от курсора (Shift+Tab)',
        rs: 'Pusti sa kursora (Shift+Tab)',
        ua: 'Відтворити від курсору (Shift+Tab)',
        no: 'Starte fra markøren (Shift+Tab)',
        it: 'Esegui dal cursore (Shift+Tab)',
        se: 'Spela från markören (Shift+Tab)',
    },
    noMediaDate: {
        us: 'Missing media date',
        cz: 'Chybí datum nahrávky',
        pl: 'Brak daty nagrania',
        hr: 'Nedostaje datum medijske datoteke',
        si: 'Manjka datum posnetka',
        de: 'Fehlendes Aufnahmedatum',
        sk: 'Chýba dátum nahrávky',
        es: 'Falta la fecha de grabación',
        ru: 'Отсутствует дата записи',
        rs: 'Nedostaje datum medija',
        ua: 'Відсутня дата запису',
        no: 'Innspillingsdato mangler',
        it: 'Manca la data di registrazione',
        se: 'Inspelningens datum saknas',
    },
    speed: {
        us: 'Speed ',
        cz: 'Rychlost ',
        pl: 'Prędkość ',
        hr: 'Brzina ',
        si: 'Hitrost ',
        de: 'Geschwindigkeit ',
        sk: 'Rýchlosť ',
        es: 'Velocidad ',
        ru: 'Скорость ',
        rs: 'Brzina ',
        ua: 'Швидкість ',
        no: 'Hastighet ',
        it: 'Velocità ',
        se: 'Hastighet ',
    },
    hideWaveCanvas: {
        us: 'Hide sound wave',
        cz: 'Skrýt zvukovou vlnu',
        pl: 'Ukryj falę dźwiękową',
        hr: 'Sakrij zvučni val',
        si: 'Skrij zvočni val',
        de: 'Schallwelle verstecken',
        sk: 'Skryť zvukovú vlnu',
        es: 'Ocultar onda sonora',
        ru: 'Скрыть звуковую волну',
        rs: 'Sakrij zvučni talas',
        ua: 'Приховати звукову хвилю',
        no: 'Gjemme lydbølgen',
        it: "Nascondi l'onda sonora",
        se: 'Dölj ljudvågen',
    },
    displayWaveCanvas: {
        us: 'Display sound wave',
        cz: 'Zobrazit zvukovou vlnu',
        pl: 'Pokaż falę dźwiękową',
        hr: 'Prikaži zvučni val',
        si: 'Pokaži zvočni val',
        de: 'Schallwelle anzeigen',
        sk: 'Zobraziť zvukovú vlnu',
        es: 'Mostrar onda sonora',
        ru: 'Показать звуковую волну',
        rs: 'Pokaži zvučni talas',
        ua: 'Показати звукову хвилю',
        no: 'Vise lydbølgen',
        it: "Visualizza l'onda sonora",
        se: 'Visa ljudvågen',
    },
    zoomChange: {
        us: 'Zoom change',
        cz: 'Změnit zoom',
        pl: 'Zmień zoom',
        hr: 'Promijeni zoom',
        si: 'Sprememba zooma',
        de: 'Zoom ändern',
        sk: 'Zmena zoomu',
        es: 'Cambio del acercamiento',
        ru: 'Изменение масштаба',
        rs: 'Promeni zoom',
        ua: 'Зміна масштабу',
        no: 'Endre zoom',
        it: 'Modifica dello zoom',
        se: 'Byta zoom',
    },
    verticalZoom: {
        us: 'Vertical zoom',
        cz: 'Vertikální zoom',
        pl: 'Zoom wertykalny',
        hr: 'Okomiti zoom',
        si: 'Navpičen zoom',
        de: 'Vertikaler Zoom',
        sk: 'Vertikálny zoom',
        es: 'Acercamiento vertical',
        ru: 'Вертикальный зум',
        rs: 'Vertikalni zoom',
        ua: 'Вертикальне масштабування',
        no: 'Vertikal zoom',
        it: 'Zoom verticale',
        se: 'Vertikal zoom',
    },
    horizontalZoom: {
        us: 'Horizontal zoom',
        cz: 'Horizontální zoom',
        pl: 'Zoom horyzontalny',
        hr: 'Vodoravni zoom',
        si: 'Vodoraven zoom',
        de: 'Horizontaler Zoom',
        sk: 'Horizontálny zoom',
        es: 'Acercamiento horizontal',
        ru: 'Горизонтальное масштабирование',
        rs: 'Vodoravni zoom',
        ua: 'Горизонтальне масштабування',
        no: 'Horisontal zoom',
        it: 'Zoom orizzontale',
        se: 'Horisontal zoom',
    },
    zoomIn: {
        us: 'Zoom in',
        cz: 'Přiblížit',
        pl: 'Powiększ',
        hr: 'Povećaj',
        si: 'Povečaj',
        de: 'Heranzoomen',
        sk: 'Priblížiť',
        es: 'Acercar',
        ru: 'Приближать',
        rs: 'Uveličati',
        ua: 'Приближувати',
        no: 'Zoome inn',
        it: 'Ingrandimento',
        se: 'Zooma in',
    },
    zoomOut: {
        us: 'Zoom out',
        cz: 'Oddálit',
        pl: 'Oddal',
        hr: 'Smanji',
        si: 'Oddalji',
        de: 'Herauszoomen',
        sk: 'Oddialiť',
        es: 'Disminuir el zoom',
        ru: 'Уменьшить',
        rs: 'Odzumiraj',
        ua: 'Зменшити',
        no: 'Zoome ut',
        it: 'Rimpicciolire',
        se: 'Zooma ut',
    },
    zoomOutCanvas: {
        us: 'Zoom out sound wave',
        cz: 'Oddálit zvukovou vlnu',
        pl: 'Oddal falę dźwiękową',
        hr: 'Smanji zvučni val',
        si: 'Oddalji zvočni val',
        de: 'Schallwelle herauszoomen',
        sk: 'Oddialiť zvukovú vlnu',
        es: 'Alejar la onda de sonido',
        ru: 'Уменьшить звуковую волну',
        rs: 'Odzumiraj jačinu zvuka',
        ua: 'Зменшити звукову хвилю',
        no: 'Zoome ut lydbølgen',
        it: "Rimpicciolire l'onda sonora",
        se: 'Zooma ut ljudvågen',
    },
    zoomHorizontally: {
        us: 'horizontally',
        cz: 'horizontálně',
        pl: 'poziomo',
        hr: 'vodoravno',
        si: 'vodoravno',
        de: 'horizontal',
        sk: 'horizontálne',
        es: 'horizontalmente',
        ru: 'горизонтально',
        rs: 'horizontalno',
        ua: 'горизонтально',
        no: 'horisontalt',
        it: 'in orizzontale',
        se: 'horisontalt',
    },
    zoomVertically: {
        us: 'vertically',
        cz: 'vertikálně',
        pl: 'pionowo',
        hr: 'okomito',
        si: 'navpično',
        de: 'vertikal',
        sk: 'vertikálne',
        es: 'verticalmente',
        ru: 'вертикально',
        rs: 'vertikalno',
        ua: 'вертикально',
        no: 'vertikalt',
        it: 'in verticale',
        se: 'vertikalt',
    },
    notGeneratedSoundWave: {
        us: 'Sound wave cannot be generated.',
        cz: 'Zvukovou vlnu nelze vygenerovat.',
        pl: 'Nie można wytworzyć fali dźwiękowej.',
        hr: 'Zvučni val se ne može generirati.',
        si: 'Zvočnega vala ni mogoče ustvariti.',
        de: 'Die Schallwelle kann nicht erzeugt werden.',
        sk: 'Zvuková vlna sa nedá vygenerovať.',
        es: 'La onda sonora no se puede generar.',
        ru: 'Звуковая волна не может быть создана.',
        rs: 'Zvučni talas se ne može generisati.',
        ua: 'Звукова хвиля не може бути створена.',
        no: 'Lydbølgen kan ikke genereres.',
        it: "Non è possibile generare l'onda sonora.",
        se: 'Det är inte möjligt att generera ljudvågen.',
    },
    stopTranscription: {
        us: 'Stop transcription',
        cz: 'Zastavit přepis',
        pl: 'Zatrzymaj transkrypcję',
        hr: 'Zaustavi transkripciju',
        si: 'Ustavi prepis',
        de: 'Transkription stoppen',
        sk: 'Zastaviť prepis',
        es: 'Detener la transcripción',
        ru: 'Остановить транскрибирование',
        rs: 'Zaustavi transkripciju',
        ua: 'Зупинити транскрибування',
        no: 'Stoppe transkripsjonen',
        it: 'Interrompere la trascrizione',
        se: 'Avbryta transkriptionen',
    },
    captionReviewDisabled: {
        us: 'Subtitle revision is not enabled for you. To enable it, contact support at support@beey.io',
        cz: 'Režim revize titulků nemáte povolen. Pro jeho zapnutí kontaktujte podporu na support@beey.io',
        pl: 'Tryb napisów nie jest dostępny. Aby go aktywować, skontaktuj się z naszym teamem pomocy technicznej support@beey.io',
        hr: 'Provjera titlova nije omogućena za vas. Da biste je omogućili, kontaktirajte s podrškom na support@beey.io',
        si: 'Preverjanje podnapisov za vas ni omogočeno. Da bi ga omogočili, se obrnite na podporo na support@beey.io',
        de: 'Untertitel-Revision ist für Sie nicht aktiviert. Um sie zu aktivieren, wenden Sie sich an Support unter support@beey.io',
        sk: 'Revízia titulkov nie je povolená. Ak ju chcete povoliť, kontaktujte podporu na adrese support@beey.io',
        es: 'La revisión de subtítulos no está habilitada para usted. Para habilitarlo, póngase en contacto con el soporte en support@beey.io',
        ru: 'Проверка субтитров не активирована для вас, для активации свяжитесь со службой проверки support@beey.io',
        rs: 'Provera titlova nije omogućena za vas. Da biste je omogućili, kontaktirajte podršku na support@beey.io',
        ua: "Перевірка субтитрів не активована для вас, щоб активувати зв'яжіться зі службою перевірки support@beey.io",
        no: 'Du har ikke aktivert undertekstredigering. Ta kontakt med brukerstøtte på support@beey.io for å få den aktivert.',
        it: "Non è stata attivata la modalità di revisione dei sottotitoli. Per attivarla, contatti l'assistenza all'indirizzo support@beey.io",
        se: 'Undertextredigeringsläget har inte aktiverats för dig. För att få det aktiverat, kontakta vår kundservice på support@beey.io',
    },
    charactersPerSec: {
        us: 'chars/s',
        cz: 'zn/s',
        pl: 'zn/s',
        hr: 'zn/s',
        si: 'zn/s',
        de: 'Zeichen/S',
        sk: 'zn/s',
        es: 'Cps',
        ru: 'зн/с',
        rs: 'zn/s',
        ua: 'зн/с',
        no: 'tegn/s',
        it: 'caratteri/s',
        se: 'tecken/s',
    },
    trialCreditWarning: {
        us: 'Only part of the recording will be transcribed as you are using trial credit. After your first credit purchase, you may transcribe whole files without any restrictions.',
        cz: 'Využíváte testovací kredit zdarma. V tomto režimu se přepisuje pouze část nahrávky. Po prvním nákupu můžete zbylý kredit využít neomezeně.',
        pl: 'Korzystasz z bezpłatnego kredytu testowego. Stranskrybowana zostanie część nagrania. Po pierwszym zakupie w aplikacji będziesz mógł wykorzystać pozostały kredyt bez ograniczeń.',
        hr: 'Samo će dio snimke biti transkribiran jer koristite probni kredit. Nakon prve kupnje kredita možete transkribirati cijele datoteke bez ikakvih ograničenja.',
        si: 'Samo del posnetka bo prepisan, saj uporabljate preizkusni kredit. Po prvem nakupu kredita lahko brez omejitev prepisujete cele datoteke.',
        de: 'Sie verwenden kostenlose Kredite. Nur ein Teil der Aufnahme wird transkribiert. Nach Ihrem ersten Einkauf können Sie Kredite frei verwenden.',
        sk: 'Využívate bezplatný testovací kredit. Prepíše sa len časť nahrávky. Po prvom nákupe môžete kredit využívať neobmedzene.',
        es: 'Está utilizando un crédito de prueba gratuito. Solo se transcribirá una parte de la grabación. Después de la primera compra, puede utilizar el crédito sin límites.',
        ru: 'Только часть вашей записи будет транскрибирована так как вы используете тестовую версию с бесплатным балансом на счете. После первого пополнения баланса вы можете транскрибировать целые файлы без ограничений.',
        rs: 'Samo će deo snimka biti transkribovann jer koristite probni nalog. Nakon prve kupovine kredita možete transkribovati cele datoteke bez ikakvih ograničenja.',
        ua: 'Лише частина вашого запису буде транскрибована, оскільки ви використовуєте тестову версію з безкоштовним балансом на рахунку. Після першого поповнення балансу можна транскрибувати цілі файли без обмежень.',
        no: 'Kun en del av innspillingen blir transkribert, for du bruker kreditt for prøveversjon. Du kan transkribere hele filer uten noen begrensninger etter ditt første kredittkjøp.',
        it: 'Sta utilizzando il credito di prova gratuito. In questa modalità, viene trascritta solo una parte della registrazione. Dopo il primo acquisto, il credito residuo può essere utilizzato illimitatamente.',
        se: 'Endast en del av inspelningen kommer att transkriberas därför att du använder kredit som ingår i provversionen. Efter första kreditinköpet kan du transkribera filer i deras helhet utan begränsning.',
    },
    captionErrorMode: {
        us: 'Cannot insert caption end when not in caption review mode.',
        cz: 'Nelze vložit konec titulku, když neprobíhá revize titulků.',
        pl: 'Nie możesz włożyć końca napisu, jeśli tryb napisów nie jest włączony.',
        hr: 'Nije moguće umetnuti kraj titla kada nije u načinu pregleda titlova.',
        si: 'Konca podnapisov ni mogoče vstaviti, ko ni v načinu pregleda podnapisov.',
        de: 'Es kann kein Untertitelende eingefügt werden, wenn der Untertitel-Revisionsmodus nicht aktiviert ist.',
        sk: 'Nie je možné vložiť koniec titulku, keď nie je spustená revízia titulkov.',
        es: 'No se puede insertar el final del subtítulo cuando no está en el modo de revisión de subtítulos.',
        ru: 'Невозможно вставить конец субтитров, когда не активирована проверка субтитров.',
        rs: 'Nije moguće umetnuti kraj titla kada nije u načinu pregleda titlova.',
        ua: 'Неможливо вставити кінець субтитрів, коли не активовано перевірку субтитрів.',
        no: 'Umulig sette inn undertekstslutt hvis du ikke er i revisjon-modus.',
        it: 'Non è possibile inserire la fine di un sottotitolo quando non è in modalità revisione dei sottotitoli.',
        se: 'Det är inte möjligt att infoga undertextslut utanför undertextredigeringsläget.',
    },
    captionErrorSamePlace: {
        us: 'Only one caption end can be inserted between two words.',
        cz: 'Mezi dvě slova lze vložit pouze jeden konec titulku.',
        pl: 'Między dwa słowa możesz wstawić tylko jeden koniec napisów.',
        hr: 'Između dvije riječi može se umetnuti samo jedan kraj titla.',
        si: 'Med dvema besedama je mogoče vstaviti samo en konec podnapisa.',
        de: 'Zwischen zwei Wörter kann nur ein Untertitelende eingefügt werden.',
        sk: 'Medzi dve slová možno vložiť iba jeden koniec titulku.',
        es: 'Solo se puede insertar un final de subtítulo entre dos palabras.',
        ru: 'Между двумя словами можно вставить только один конец субтитров.',
        rs: 'Između dve reči može da se umetne samo jedan kraj titla.',
        ua: 'Між двома словами можна вставити лише один кінець субтитрів.',
        no: 'Det er mulig å sette inn bare én undertekstslutt mellom to ord.',
        it: "È possibile inserire solo un'estremità del sottotitolo tra due parole.",
        se: 'Endast ett undertextslut kan infogas mellan två ord.',
    },
    videoNotAvailable: {
        us: 'Video unavailable',
        cz: 'Nedostupné video',
        pl: 'Niedostępne wideo',
        hr: 'Videozapis nije dostupan',
        si: 'Videoposnetek ni na voljo',
        de: 'Video nicht verfügbar',
        sk: 'Nedostupné video',
        es: 'Vídeo no disponible',
        ru: 'Видео недоступно',
        rs: 'Video zapis nije dostupan',
        ua: 'Відео недоступне',
        no: 'Video utilgjengelig',
        it: 'Video non disponibile',
        se: 'Video ej tillgänglig',
    },
    convertToH264: {
        us: 'The recording either does not contain a video or it is in an unsupported format. To display video, convert the file to H.264 format first and then upload the project again. We recommend stopping the current transcription.',
        cz: 'Nahrávka buď neobsahuje video nebo není v podporovaném formátu. Pro zobrazení videa soubor nejprve zkonvertujte do formátu H.264 a poté nahrajte projekt znovu. Aktuální přepis doporučujeme zastavit.',
        pl: 'Nagranie nie zawiera filmu wideo lub ma nieobsługiwany format. Aby wyświetlić wideo, przekonwertuj najpierw plik do formatu H.264, a następnie prześlij projekt ponownie. Zalecamy zatrzymanie bieżącej transkrypcji.',
        hr: 'Snimka ne sadrži videozapis ili je u nepodržanom formatu. Za prikaz videozapisa prvo pretvorite datoteku u format H.264, a zatim ponovno učitajte projekt. Preporučujemo da zaustavite trenutnu transkripciju.',
        si: 'Posnetek ne vsebuje videoposnetka ali pa je v nepodprti obliki. Za prikaz videoposnetka najprej spremenite datoteko v format H.264 in nato znova naložite projekt. Priporočamo, da ustavite trenutno prepisovanje.',
        de: 'Die Aufnahme enthält entweder kein Video oder hat ein nicht unterstütztes Format. Um das Video anzuzeigen, konvertieren Sie die Datei zunächst in das H.264-Format und laden Sie das Projekt dann erneut hoch. Wir empfehlen die aktuelle Transkription zu stoppen.',
        sk: 'Záznam buď neobsahuje video, alebo nie je v podporovanom formáte. Ak chcete zobraziť video, najprv prekonvertujte súbor do formátu H.264 a potom projekt znova nahrajte. Odporúčame zastaviť aktuálny prepis.',
        es: 'La grabación no contiene un video o está en un formato no compatible. Para mostrar el video, primero convierta el archivo al formato H.264 y luego vuelva a cargar el proyecto. Le recomendamos que detenga la transcripción actual.',
        ru: 'Запись либо не содержит видео, либо имеет неподдерживаемый формат. Для отображения видео сначала конвертируйте файл в формат H.264, а затем снова загрузите проект. Мы рекомендуем остановить текущую транскрипцию.',
        rs: 'Snimak ili ne sadrži video ili je u nepodržanom formatu. Da biste prikazali video, prvo konvertujte datoteku u H.264 format, a zatim ponovo otpremite projekat. Preporučujemo da zaustavite trenutnu transkripciju.',
        ua: 'Запис або не містить відео, або він у непідтримуваному форматі. Щоб відобразити відео, спочатку конвертуйте файл у формат H.264, а потім знову завантажте проект. Ми рекомендуємо зупинити поточну транскрипцію.',
        no: 'Innspillingen enten ikke inneholder video eller er videoen i et ugyldig format. For å vise videoen, først konverter filen til H.264 format, så last opp prosjektet på nytt. Vi anbefaler å stanse den pågående transkripsjonen.',
        it: 'La registrazione non contiene video o non è in un formato supportato. Per visualizzare il video, convertire prima il file in formato H.264 e poi caricare nuovamente il progetto. Si consiglia di interrompere la trascrizione in corso.',
        se: 'Antingen innehåller inspelningen ingen video, eller är den i format som inte stöds. För att visa videon, konvertera först filen till H.264-formatet och sedan ladda upp projektet igen. Vi rekommenderar att du avbryter den aktuella transkriptionen.',
    },
    loadingProject: {
        us: 'Loading the project...',
        cz: 'Nahrávám projekt...',
        pl: 'Wczytuję projekt...',
        hr: 'Projekt se učitava...',
        si: 'Nalaganje projekta...',
        de: 'Das Projekt wird geladen...',
        sk: 'Nahrávam projekt...',
        es: 'Cargando el proyecto ...',
        ru: 'Проект загружается...',
        rs: 'Učitavanje projekta...',
        ua: 'Проект завантажується...',
        no: 'Prosjektet lastes...',
        it: 'Sto caricando un progetto...',
        se: 'Projektet laddas...',
    },
    projectInQueue: {
        us: 'The project is queued for transcription',
        cz: 'Projekt je zařazen do fronty na přepis',
        pl: 'Projekt czeka w kolejce do transkrypcji',
        hr: 'Projek je na čekanju za transkripciju',
        si: 'Projekt je na čakanju za prepis',
        de: 'Projekt steht in Warteschlange für Transkription',
        sk: 'Projekt je zaradený do fronty na prepis',
        es: 'El proyecto está en cola para su transcripción',
        ru: 'Проект ожидает очереди на транскрибирвание',
        rs: 'Projekat je na čekanju za transkripciju',
        ua: 'Проект чекає черги на транскрибування',
        no: 'Prosjektet står i kø for transkripsjonen',
        it: 'Il progetto è in coda per la trascrizione',
        se: 'Projektet står i kö för transkriptionen',
    },
    orderInQueue: {
        us: 'Order in queue',
        cz: 'Pořadí ve frontě',
        pl: 'Kolejność projektów',
        hr: 'Narudžba na čekanju',
        si: 'Naročilo na čakanju',
        de: 'Reihenfolge in Warteschlange',
        sk: 'Poradie vo fronte',
        es: 'Posición en la cola',
        ru: 'Место в очереди',
        rs: 'Narudžbina na čekanju',
        ua: 'Місце у черзі',
        no: 'Plassering i kø',
        it: 'Ordine in coda',
        se: 'Placering i kö',
    },
    waitingForConversion: {
        us: 'Due to the recording format, transcription will start after the upload is finished.',
        cz: 'Pro nahrávky tohoto formátu začne přepis po dokončení uploadu.',
        pl: 'W przypadku nagrań w tym formacie transkrypcja rozpocznie się po zakończeniu wczytywania.',
        hr: 'Radi formata snimke će transkripcija započeti nakon završetka prijenosa.',
        si: 'Zaradi formata posnetka se bo prepis začel po končanem nalaganju.',
        de: 'Aufgrund Aufnahmeformats wird Transkription erst nach Hochladen beginnen.',
        sk: 'Pre nahrávky v tomto formáte sa prepis spustí po dokončení nahrávania.',
        es: 'Debido al formato de la grabación, la transcripción comenzará una vez finalizada la subida.',
        ru: 'Транскрибирование для данного формата записи начнётся после окончания загрузки.',
        rs: 'Zbog formata snimanja, transkripcija će početi nakon što se učitavanje završi.',
        ua: 'Транскрибування для цього формату запису почнеться після закінчення завантаження.',
        no: 'Transkripsjon begynner når opplastingen er fullført, takket være formatet av innspillingen.',
        it: 'Per i caricamenti di questo formato, la trascrizione inizierà al termine del caricamento.',
        se: 'På grund av inspelningsformatet börjar transkriptionen när uppladdningen är färdig.',
    },
    converting: {
        us: 'Converting the recording...',
        cz: 'Konvertuji nahrávku...',
        pl: 'Konwertuję nagranie...',
        hr: 'Pretvaranje snimke...',
        si: 'Pretvorba posnetka...',
        de: 'Konvertierung der Aufnahme...',
        sk: 'Konverzia nahrávky...',
        es: 'Conversión de la grabación ...',
        ru: 'Запись конвертируется...',
        rs: 'Konvertovanje snimka...',
        ua: 'Запис конвертується...',
        no: 'Konverterer innspillingen...',
        it: 'Sto convertendo la registrazione...',
        se: 'Konverterar inspelningen...',
    },
    uploadErrorTitle: {
        us: 'Upload failed',
        cz: 'Upload nahrávky selhal',
        pl: 'Wczytanie nagrania nie powiodło się',
        hr: 'Prijenos nije uspio',
        si: 'Prenos ni uspel',
        de: 'Upload fehlgeschlagen',
        sk: 'Upload zlyhal',
        es: 'Subida fallida',
        ru: 'Загрузка не удалась',
        rs: 'Prenos nije uspeo',
        ua: 'Завантаження не вдалося',
        no: 'Opplastingen mislyktes',
        it: 'Caricamento della registrazione non riuscito',
        se: 'Uppladdning av inspelningen misslyckades',
    },
    uploadError: {
        us: "Upload of the recording failed. Don't worry, no credit was charged. Check your internet connection and upload the project again.",
        cz: 'Upload vaší nahrávky selhal. Nebojte, žádný kredit vám nebyl odečten. Zkontrolujte své internetové připojení a nahrajte projekt znovu.',
        pl: 'Wczytanie Twojego nagrania nie powiodło się. Nie martw się, żaden kredyt nie został odliczony. Skontroluj swoje łącze internetowe i spróbuj przesłać projekt ponownie.',
        hr: 'Prijenos snimke nije uspio. Ne brinite, kredit nije naplaćen.  Provjerite svoju internetsku vezu i ponovno prenesite projekt.',
        si: 'Prenos posnetka ni uspel. Ne skrbite, kredit ni zaračunan.  Preverite internetno povezavo in znova naložite projekt.',
        de: 'Upload der Aufnahme ist fehlgeschlagen. Kein Kredit wurde abgerechnet. Überprüfen Sie Ihre Internetverbindung und laden Sie das Projekt erneut hoch.',
        sk: 'Upload nahrávky sa nepodaril. Nemajte obavy, nebol vám strhnutý žiadny kredit. Skontrolujte svoje internetové pripojenie a nahrajte projekt znova.',
        es: 'Error al subir la grabación. No se preocupe, no se cargó ningún crédito. Verifique su conexión a Internet y cargue el proyecto de nuevo.',
        ru: 'Загрузка записи не удалась. Не беспокойтесь, средства с вашего баланса не были списаны. Проверьте свое подключение к интернету и загрузите проект снова.',
        rs: 'Prenos snimka nije uspeo. Ne brinite, kredit nije naplaćen.  Proverite svoju internet vezu i ponovo prenesite projekat.',
        ua: 'Завантаження запису не вдалося. Не турбуйтеся, кошти з вашого балансу не були списані. Перевірте своє підключення до Інтернету та завантажте проект знову.',
        no: 'Opplasting av innspillingen mislyktes. Ikke bekymre deg, ingen kreditt ble belastet. Kontroller internett-tilkoblingen og last opp prosjektet igjen.',
        it: 'Il caricamento non è riuscito. Non si preoccupi, non è stato detratto alcun credito. Controllare la connessione a Internet e caricare nuovamente il progetto.',
        se: 'Uppladdning av inspelningen misslyckades. Oroa dig inte, ingen kredit har debiterats. Kontrollera din internetanslutning och ladda upp projektet igen.',
    },
    transcriptionErrorTitle: {
        us: 'Transcription failed',
        cz: 'Přepis nahrávky selhal',
        pl: 'Transkrypcja nagrania nie powiodła się',
        hr: 'Transkripcija nije uspjela',
        si: 'Prepis ni uspel',
        de: 'Transkription fehlgeschlagen',
        sk: 'Prepis nahrávky zlyhal',
        es: 'Transcripción fallida',
        ru: 'Транскрибирование не удалось',
        rs: 'Transkripcija nije uspela',
        ua: 'Транскрибування не вдалося',
        no: 'Transkripsjonen mislyktes',
        it: 'La trascrizione della registrazione non è riuscita',
        se: 'Transkriptionen misslyckades',
    },
    transcriptionError: {
        us: "There was an unexpected error while transcribing the recording. Don't worry, you were not charged anything. Please try creating the project again.",
        cz: 'Během přepisu nahrávky došlo k nečekané chybě. Nebojte, žádný kredit vám nebyl odečten. Nahrajte prosím projekt znovu.',
        pl: 'Podczas transkrypcji nagrania wystąpił nieoczekiwany błąd. Nie martw się, żaden kredyt nie został odliczony. Spróbuj przesłać projekt ponownie.',
        hr: 'Došlo je do neočekivane pogreške tijekom transkripcije snimke. Ne brinite, ništa nije naplaćeno. Molimo pokušajte ponovno stvoriti projekt.',
        si: 'Med prepisovanjem posnetka je prišlo do nepričakovane napake. Ne skrbite, nič vam ni zaračunano. Poskusite znova ustvariti projekt.',
        de: 'Beim Transkribieren der Aufnahme ist ein unerwarteter Fehler aufgetreten. Kein Kredit wurde abgerechnet. Bitte versuchen Sie das Projekt erneut zu erstellen.',
        sk: 'Pri prepise nahrávky došlo k neočakávanej chybe. Nemajte obavy, nebol vám strhnutý žiadny kredit. Skúste projekt nahrať znova.',
        es: 'Se produjo un error inesperado al transcribir la grabación. No se preocupe, no se cargó ningún crédito. Por favor, intente crear el proyecto de nuevo.',
        ru: 'Во время транскрибирования возникла неожиданная ошибка. Не беспокойтесь, средства с вашего баланса не были списаны. Пожалуйста, попробуйте создать проект снова.',
        rs: 'Došlo je do neočekivane greške tokom transkripcije snimka. Ne brinite, ništa nije naplaćeno. Molimo pokušajte ponovo da kreirate projekt.',
        ua: 'Під час транскрибування виникла несподівана помилка. Не турбуйтеся, кошти з вашого балансу не були списані. Будь ласка, спробуйте створити проект ще раз.',
        no: 'Det oppsto en uventet feil under transkribering av innspillingen. Ikke bekymre deg, ingen kreditt ble belastet. Vennligst prøv å lage prosjektet på nytt.',
        it: 'Durante la trascrizione della registrazione si è verificato un errore imprevisto. Non si preoccupi, non è stato detratto alcun credito. Si prega di registrare nuovamente il progetto.',
        se: 'Ett oväntat fel uppstod under transkriptionen av inspelningen. Oroa dig inte, ingen kredit har debiterats. Vänligen prova igen att skapa projektet.',
    },
    transcodingErrorTitle: {
        us: 'Unsupported media format',
        cz: 'Nepodporovaný formát nahrávky',
        pl: 'Nieobsługiwany format nagrania',
        hr: 'Nepodržani medijski format',
        si: 'Nepodprt medijski format',
        de: 'Nicht unterstütztes Medienformat',
        sk: 'Nepodporovaný formát nahrávky',
        es: 'Formato de medios no compatible',
        ru: 'Неподдерживаемый формат медиа',
        rs: 'Nepodržani medijski format',
        ua: 'Непідтримуваний формат медіа',
        no: 'Formatet støttes ikke',
        it: 'Formato di registrazione non supportato',
        se: 'Inspelningsformatet stöds inte',
    },
    transcodingError: {
        us: 'The media file you uploaded is not supported. Please convert it to a supported format first – for video use mp4 format (H.264 video codec), and for audio use mp3, wav, FLAC or opus.',
        cz: 'Nahraný soubor není v podporovaném formátu. Nejdříve jej prosím zkonvertujte do podporovaného formátu: pro video použijte mp4 (H.264 video kodek) a pro audio formát mp3, wav, FLAC nebo opus.',
        pl: 'Twój plik jest w nieobsługiwanym formacie. Skonwertuj go do obsługiwanego formatu: użyj mp4 (kodek wideo H.264) dla wideo i mp3, wav, FLAC lub opus dla audio.',
        hr: 'Medijska datoteka koju ste prenijeli nije podržana. Molimo da je prvo pretvorite u podržani format - za video koristite mp4 format (H.264 video kodek), a za audio koristite mp3, wav, FLAC ili opus.',
        si: 'Medijska datoteka, ki ste jo naložili, ni podprta. Prosimo, da jo najprej pretvorite v podprt format - za video uporabite format mp4 (video kodek H.264), za zvok pa uporabite mp3, wav, FLAC ali opus.',
        de: 'Die von Ihnen hochgeladene Mediendatei ist nicht unterstützt. Bitte konvertieren Sie sie zunächst in ein unterstütztes Format - für Video verwenden Sie mp4-Format (H.264-Video-Codec) und für Audio mp3, wav, FLAC oder opus.',
        sk: 'Nahraný súbor, nie je v podporovanom formáte. Najprv ho prekonvertujte do podporovaného formátu - pre video použite mp4 (H.264 video kodek) a pre zvuk mp3, wav, FLAC alebo opus.',
        es: 'El archivo multimedia que cargó no es compatible. Primero conviértalo a un formato compatible: para video, use el formato mp4 (códec de video H.264) y para audio, use mp3, wav, FLAC u opus.',
        ru: 'Загруженный медиафайл не поддерживается. Пожалуйста, конвертируйте файл в поддерживаемый формат-для видео используйте формат mp4 (H.264 видео кодек), а для аудио используйте формат mp3, wav, FLAC или opus.',
        rs: 'Medijska datoteka koju ste preneli nije podržana. Molimo da je prvo pretvorite u podržani format - za video koristite mp4 format (H.264 video kodek), a za audio koristite mp3, wav, FLAC ili opus.',
        ua: 'Завантажений медіафайл не підтримується. Будь ласка, конвертуйте файл у підтримуваний формат-для відео використовуйте формат mp4 (H.264 відео кодек), а для аудіо використовуйте формат mp3, wav, FLAC або opus.',
        no: 'Formatet av mediefilen du lastet opp støttes ikke. Vennligst konverter fila til et støttet format: mp4 for videoer (H.264 codec), mp3, wav, FLAC eller opus for lyd.',
        it: 'Il file caricato non è in un formato supportato. Prima convertirlo in un formato supportato: per i video utilizzare mp4 (codec video H.264) e per il formato audio mp3, wav, FLAC o opus.',
        se: 'Den uppladdade filen är i format som inte stöds. Vänligen konvertera den till ett format som stöds – mp4-format för videofiler (H.264 video codec), och mp3, wav, FLAC eller opus för ljudfiler.',
    },
    notEnoughCreditTitle: {
        us: 'Insufficient credit for transcription',
        cz: 'Nedostatek kreditu pro přepis',
        pl: 'Niewystarczający kredyt do transkrypcji',
        hr: 'Nedovoljno kredita za transkripciju',
        si: 'Nezadosten kredit za prepis',
        de: 'Nicht genügend Kredit für Transkription',
        sk: 'Nedostatočný kredit pre prepis',
        es: 'Crédito insuficiente para la transcripción',
        ru: 'Недостаточный баланс для транскрибирования',
        rs: 'Nedovoljno kredita za transkripciju',
        ua: 'Недостатній баланс для транскрибування',
        no: 'Ikke tilstrekkelig kreditt for transkripsjonen',
        it: 'Crediti per la trascrizione insufficienti',
        se: 'Otillräcklig kredit för transkription',
    },
    notEnoughCreditError: {
        us: 'You do not have enough credit for transcription. Top up your credit in the Account section and create the project again.',
        cz: 'Pro přepis nemáte dostatek kreditu. Dobijte si kredit v sekci Účet a vytvořte projekt znovu.',
        pl: 'Nie masz wystarczającego kredytu, aby dokonać transkrypcji. Doładuj swój kredyt w sekcji Konto i ponownie utwórz projekt.',
        hr: 'Nemate dovoljno kredita za transkripciju. Nadopunite svoj kredit u dijelu Račun te ponovno kreirajte projekt.',
        si: 'Nimate dovolj kredita za prepis. Napolnite svoj kredit v razdelku Račun in znova ustvarite projekt.',
        de: 'Sie haben nicht genügend Kredit für Transkription. Erhöhen Sie Ihr Kredit in der Konto-Sektion und erstellen Sie Projekt erneut.',
        sk: 'Nemáte dostatok kreditu na prepis. Doplňte si kredit v časti Účet a vytvorte projekt znova.',
        es: 'No tiene suficiente crédito para la transcripción. Recargue su crédito en la sección Cuenta y vuelva a crear el proyecto.',
        ru: 'На вашем балансе недостаточно средств для транскрибирования. Пополните баланс в отделе своего Профиля и создайте проект снова.',
        rs: 'Nemate dovoljno kredita za transkripciju. Dopunite svoj kredit u delu Nalog i ponovo kreirajte projekat.',
        ua: 'На вашому балансі недостатньо засобів для транскрибування. Поповніть баланс у відділі свого профілю та створіть проект знову.',
        no: 'Du har ikke tilstrekkelig kreditt for transkripsjonen. Fyll på kreditt under Min konto og lag prosjektet på nytt.',
        it: 'Non si dispone di crediti sufficienti per la trascrizione. Ricaricate il credito nella sezione Account e create nuovamente il progetto.',
        se: 'Du har inte tillräcklig kredit för transkriptionen. Fyll på kredit under rubriken Konto och skapa projektet igen.',
    },
    trsxLoadErrorTitle: {
        us: 'Loading of transcription failed',
        cz: 'Načítání přepisu selhalo',
        pl: 'Nie udało się wczytać transkrypcji',
        hr: 'Učitavanje transkripcije nije uspjelo',
        si: 'Nalaganje prepisa ni uspelo',
        de: 'Laden der Transkription fehlgeschlagen',
        sk: 'Načítanie prepisu zlyhalo',
        es: 'Error al cargar la transcripción',
        ru: 'Загрузка транскрибирования не удалась',
        rs: 'Učitavanje transkripcije nije uspelo',
        ua: 'Завантаження транскрибування не вдалося',
        no: 'Klarte ikke å laste transkripsjonen',
        it: 'Caricamento della trascrizione non riuscito',
        se: 'Det gick inte att ladda transkriptionen',
    },
    trsxLoadError: {
        us: "Transcription couldn't be loaded. Please try refreshing the page.",
        cz: 'Přepis se nepodařilo načíst. Zkuste prosím obnovit stránku.',
        pl: 'Nie udało się wczytać transkrypcji. Spróbuj odświeżyć stronę.',
        hr: 'Transkripcija se nije mogla učitati. Pokušajte osvježiti stranicu.',
        si: 'Prepisa ni bilo mogoče naložiti. Poskusite osvežiti stran.',
        de: 'Transkription konnte nicht geladen werden. Bitte versuchen Sie, die Seite zu aktualisieren.',
        sk: 'Prepis sa nepodarilo získať. Skúste stránku obnoviť.',
        es: 'No se pudo cargar la transcripción. Intente actualizar la página.',
        ru: 'Транскрипцию не удалось загрузить. Пожалуйста обновите страницу.',
        rs: 'Transkripcija nije mogla da se učita. Pokušajte da osvežite stranicu.',
        ua: 'Транскрипцію не вдалося завантажити. Будь ласка, оновіть сторінку.',
        no: 'Transkripsjonen kan ikke lastes. Vennligst prøv å oppdatere siden.',
        it: 'La trascrizione non è stata caricata. Provare ad aggiornare la pagina.',
        se: 'Det gick inte att ladda transkriptionen. Vänligen prova att uppdatera sidan.',
    },
    serverShutdownTitle: {
        us: 'Server shutdown',
        cz: 'Vypnutí serveru',
        pl: 'Awaria serwera',
        hr: 'Pad poslužitelja',
        si: 'Zrušitev strežnika',
        de: 'Serverabschaltung',
        sk: 'Vypnutie servera',
        es: 'Fallo del servidor',
        ru: 'Падение сервера',
        rs: 'Pad servera',
        ua: 'Падіння сервера',
        no: 'Avslutning av server',
        it: 'Spegnimento del server',
        se: 'Serveravstängning',
    },
    serverShutdown: {
        us: "There was a server shutdown while processing the recording. Don't worry, you were not charged anything. Please try creating the project again.",
        cz: 'Během zpracování nahrávky došlo k vypnutí serveru. Nebojte, žádný kredit vám nebyl odečten. Nahrajte prosím projekt znovu.',
        pl: 'Podczas przetwarzania nagrania wystąpiła nieoczekiwana awaria serwera. Nie martw się, żaden kredyt nie został odliczony.  Spróbuj przesłać projekt ponownie.',
        hr: 'Došlo je do neočekivanog pada poslužitelja tijekom obrade snimke. Ne brinite, ništa vam nije naplaćeno. Molimo pokušajte ponovno stvoriti projekt.',
        si: 'Med obdelavo posnetka je prišlo do nepričakovane zrušitve strežnika. Ne skrbite, nič vam ni zaračunano. Prosimo, poskusite znova ustvariti projekt.',
        de: 'Während Bearbeitung der Aufzeichnung wurde der Server abgeschaltet. Kein Kredit wurde abgerechnet. Bitte versuchen Sie das Projekt erneut zu erstellen.',
        sk: 'Počas spracovania nahrávky došlo k vypnutie servera. Nemajte obavy, nebol vám strhnutý žiadny kredit. Skúste projekt nahrať znova.',
        es: 'Se produjo un error inesperado del servidor al procesar la grabación. No se preocupe, no se cargó ningún crédito. Por favor, intente crear el proyecto de nuevo.',
        ru: 'Произошло непредвиденное падение сервера во время обработки записи. Не беспокойтесь, средства с вашего баланса не были списаны. Пожалуйста, попробуйте создать проект снова.',
        rs: 'Došlo je do neočekivanog pada servera tokom obrade snimka. Ne brinite, ništa vam nije naplaćeno. Molimo pokušajte ponovno da kreirate projekat.',
        ua: 'Відбулося непередбачене падіння сервера під час обробки запису. Не турбуйтеся, кошти з вашого балансу не були списані. Будь ласка, спробуйте створити проект ще раз.',
        no: 'Server ble avsluttet under behandlingen av innspillingen. Ikke bekymre deg, ingen kreditt ble belastet. Vennligst prøv å lage prosjektet på nytt.',
        it: "Si è verificato un arresto del server durante l'elaborazione della registrazione. Non preoccupatevi, non è stato detratto alcun credito. Caricare nuovamente il progetto.",
        se: 'Server stängdes av under bearbetningen av inspelningen. Oroa dig inte, ingen kredit har debiterats. Vänligen prova igen att skapa projektet.',
    },
    unknownProjectErrorTitle: {
        us: 'Recording processing failed',
        cz: 'Zpracování nahrávky selhalo',
        pl: 'Przetworzenie nagrania nie powiodło się',
        hr: 'Obrada snimke nije uspjela',
        si: 'Obdelava posnetka ni uspela',
        de: 'Verarbeitung der Aufnahme fehlgeschlagen',
        sk: 'Spracovanie nahrávky zlyhalo',
        es: 'Falló el procesamiento de la grabación',
        ru: 'Обработка записи не удалась',
        rs: 'Obrada snimka nije uspela',
        ua: 'Обробка запису не вдалася',
        no: 'Behandling av innspillingen mislyktes',
        it: 'Elaborazione della registrazione non riuscita',
        se: 'Bearbetning av inspelningen misslyckades',
    },
    unknownProjectError: {
        us: "There was an unexpected error while processing the recording. Don't worry you were not charged anything. Please try creating the project again.",
        cz: 'Při zpracování nahrávky došlo k chybě. Nebojte, žádný kredit vám nebyl odečten. Nahrajte prosím projekt znovu.',
        pl: 'Wystąpił błąd podczas przetwarzania nagrania. Nie martw się, żaden kredyt nie został odliczony. Spróbuj przesłać projekt ponownie.',
        hr: 'Došlo je do neočekivane pogreške tijekom obrade snimke. Ne brinite, ništa vam nije naplaćeno. Molimo pokušajte ponovno stvoriti projekt.',
        si: 'Pri obdelavi posnetka je prišlo do nepričakovane napake. Ne skribte, nič vam ni zaračunano. Prosimo, poskusite znova ustvariti projekt.',
        de: 'Bei Verarbeitung der Aufzeichnung ist ein unerwarteter Fehler aufgetreten. Kein Kredit wurde abgerechnet. Bitte versuchen Sie das Projekt erneut zu erstellen.',
        sk: 'Počas spracovania nahrávky došlo k neočakávanej chybe. Nemajte obavy, nebol vám strhnutý žiadny kredit. Skúste prosím nahrať projekt znova.',
        es: 'Se produjo un error inesperado mientras se procesaba la grabación.No se preocupe, no se cargó ningún crédito. Por favor, intente crear el proyecto de nuevo.',
        ru: 'Произошла непредвиденная ошибка во время обработки записи. Не беспокойтесь, средства с вашего баланса не были списаны. Пожалуйста, попробуйте создать проект снова.',
        rs: 'Došlo je do neočekivane greške tokom obrade snimka. Ne brinite, ništa vam nije naplaćeno. Molimo pokušajte ponovo da kreirate projekat.',
        ua: 'Виникла непередбачена помилка під час обробки запису. Не турбуйтеся, кошти з вашого балансу не були списані. Будь ласка, спробуйте створити проект ще раз.',
        no: 'Det oppsto en uventet feil under transkribering av innspillingen. Ikke bekymre deg, ingen kreditt ble belastet. Vennligst prøv å lage prosjektet på nytt.',
        it: "Si è verificato un errore durante l'elaborazione della registrazione. Non preoccupatevi, non è stato detratto alcun credito. Caricare nuovamente il progetto.",
        se: 'Ett oväntat fel uppstod under bearbetningen av inspelningen. Oroa dig inte, ingen kredit har debiterats. Vänligen prova igen att skapa projektet.',
    },
    canceledProjectError: {
        us: 'The processing of the project was canceled. Please create the project again.',
        cz: 'Zpracování projektu bylo zrušeno. Nahrajte prosím projekt znovu.',
        pl: 'Przetwarzanie projektu zostało przerwane. Prześlij projekt ponownie.',
        hr: 'Obrada projekta je otkazana. Ponovno kreirajte projekt.',
        si: 'Obdelava projekta je bila preklicana. Ponovno ustvarite projekt.',
        de: 'Bearbeitung des Projekts wurde abgebrochen. Bitte erstellen Sie das Projekt erneut.',
        sk: 'Spracovanie projektu bolo zrušené. Nahrajte projekt znova.',
        es: 'Se canceló el procesamiento del proyecto. Por favor, cree el proyecto de nuevo.',
        ru: 'Обработка проекта отменена. Пожалуйста, создайте проект снова.',
        rs: 'Obrada projekta je otkazana. Molimo da ponovo kreirate projekat.',
        ua: 'Обробку проекту скасовано. Будь ласка, створіть проект ще раз.',
        no: 'Behandling av prosjektet har blitt avbrutt. Vennligst lag prosjektet på nytt.',
        it: 'Il progetto è stato cancellato. Caricare nuovamente il progetto.',
        se: 'Bearbetning av projektet avbröts. Vänligen skapa projektet igen.',
    },
    currentTrsxAvailable: {
        us: 'Your work in progress will not be lost though. You can download the saved part of your transcription as a docx from the Controls panel.',
        cz: 'O rozdělanou práci však nepřijdete. Uloženou část přepisu si můžete stáhnout jako docx v Ovládacím panelu.',
        pl: 'Nie stracisz jednak już wykonanej pracy. Zapisaną część transkrypcji możesz pobrać jako Docx z Panelu sterowania.',
        hr: 'Vaš rad neće biti izgubljen. Spremljeni dio transkripcije možete preuzeti kao docx s Upravljačke ploče.',
        si: 'Vaše nedokončano delo pa ne bo izgubljeno. Shranjeni del prepisa lahko prenesete kot docx s Nadzorne plošče.',
        de: 'Ihre bisherige Arbeit wird jedoch nicht verloren gehen. Sie können gespeicherten Teil Ihrer Transkription als docx-Datei aus dem Bedienfeld Systemsteuerung herunterladen.',
        sk: 'Vykonanú prácu však nestratíte. Uloženú časť prepisu si môžete stiahnuť vo formáte docx v Ovládacom paneli.',
        es: 'Sin embargo, su trabajo en progreso no se perderá. Puede descargar la parte guardada de su transcripción como un docx desde el panel de Controles.',
        ru: 'Ваша проделанная работа не будет утеряна. Вы можете скачать сохраненный фрагмент вашей транскрипции в формате docx Панели управления.',
        rs: 'Vaš rad neće biti izgubljen. Sačuvani deo transkripcije možete preuzeti kao docx sa ubravljačkog panela.',
        ua: 'Вашу виконану роботу не буде втрачено. Ви можете завантажити збережений фрагмент транскрипції у форматі docx Панелі управління.',
        no: 'Du vil ikke miste jobben som pågår. Du kan laste ned den delen av transkripsjonen du lagret som et docx dokument i Kontrollpanel.',
        it: 'Tuttavia, non perderete il vostro lavoro. È possibile scaricare la parte salvata della trascrizione in formato docx nel Pannello di controllo.',
        se: 'Ditt pågående arbete kommer dock inte att gå till spillo. Du kan ladda ner den del av transkriptionen som sparades som ett docx-dokument i Kontrollpanel.',
    },
    contactSupport: {
        us: 'If the problem persists or you need help ',
        cz: 'Pokud problém přetrvává nebo potřebujete poradit, ',
        pl: 'Jeśli problem nie ustąpi lub potrzebujesz poradzić, ',
        hr: 'Ako problem i dalje postoji ili vam je potrebna pomoć, ',
        si: 'Če se težava nadaljuje ali potrebujete pomoč, ',
        de: 'Wenn das Problem weiterhin besteht oder Sie Hilfe benötigen ',
        sk: 'Ak problém pretrváva alebo potrebujete radu, ',
        es: 'Si el problema persiste o necesita ayuda ',
        ru: 'Если проблема возникает снова или вам нужна помощь ',
        rs: 'Ako problem i dalje postoji ili vam je potrebna pomoć, ',
        ua: 'Якщо проблема виникає знову або вам потрібна допомога ',
        no: 'Hvis problemet vedvarer eller hvis du trenger hjelp, ',
        it: 'Se il problema persiste o avete bisogno di aiuto, ',
        se: 'Om problemet kvarstår eller om du behöver hjälp, ',
    },
    problemPersists: {
        us: 'If the problem persists, ',
        cz: 'Pokud problémy přetrvávají, ',
        pl: 'Jeśli problemy nie ustąpią, ',
        hr: 'Ako probljem i dalje postoji, ',
        si: 'Če se težava nadaljuje, ',
        de: 'Wenn das Problem weiterhin besteht, ',
        sk: 'Ak problémy pretrvávajú, ',
        es: 'Si el problema persiste, ',
        ru: 'Если проблема возникает снова, ',
        rs: 'Ako probijem i dalje postoji, ',
        ua: 'Якщо проблема виникає знову, ',
        no: 'Hvis problemet vedvarer, ',
        it: 'Se i problemi persistono, ',
        se: 'Om problemet kvarstår, ',
    },
    contactUs: {
        us: 'let us know',
        cz: 'dejte nám vědět',
        pl: 'daj nam znać',
        hr: 'dajte nam do znanja',
        si: 'sporočite nam',
        de: 'lassen Sie es uns wissen',
        sk: 'dajte nám vedieť',
        es: 'háganos saber',
        ru: 'дайте нам знать',
        rs: 'dajte nam do znanja',
        ua: 'Дайте нам знати',
        no: 'gi oss beskjed',
        it: 'fateci sapere',
        se: 'meddela oss',
    },
    contactLink: {
        us: 'https://www.beey.io/en/beey-bug-report',
        cz: 'https://www.beey.io/cs/beey-bug-report',
        pl: 'https://www.beey.io/pl/beey-bug-report/',
        hr: 'https://www.beey.io/en/beey-bug-report',
        si: 'https://www.beey.io/en/beey-bug-report',
        de: 'https://www.beey.io/en/beey-bug-report',
        sk: 'https://www.beey.io/cs/beey-bug-report',
        es: 'https://www.beey.io/en/beey-bug-report',
        ru: 'https://www.beey.io/en/beey-bug-report',
        rs: 'https://www.beey.io/en/beey-bug-report',
        ua: 'https://www.beey.io/en/beey-bug-report',
        no: 'https://www.beey.io/en/beey-bug-report',
        it: 'https://www.beey.io/en/beey-bug-report',
        se: 'https://www.beey.io/en/beey-bug-report',
    },
    missingTrsxHead: {
        us: 'Transcription not found!',
        cz: 'Přepis nebyl nalezen!',
        pl: 'Transkrypcja nie została odnaleziona!',
        hr: 'Transkripcija nije pronađena!',
        si: 'Prepis ni najden!',
        de: 'Transkription nicht gefunden!',
        sk: 'Prepis nebol nájdený!',
        es: '¡Transcripción no encontrada!',
        ru: 'Транскрипция не была найдена!',
        rs: 'Transkripcija nije pronađena!',
        ua: 'Транскрипцію не було знайдено!',
        no: 'Fant ikke transkripsjonen!',
        it: 'Nessuna trascrizione trovata!',
        se: 'Transkriptionen hittades ej!',
    },
    missingTrsxBody: {
        us: 'No transcription associated with this project could be found.',
        cz: 'Nebyl nalezen žádný přepis spojený s tímto projektem.',
        pl: 'Transkrypcja powiązana z tym projektem nie została odnaleziona.',
        hr: 'Ne postoji transkripcija za ovaj projekt.',
        si: 'Ne obstaja prepis za ta projekt.',
        de: 'Zu diesem Projekt war keine Transkription gefunden.',
        sk: 'Nebol nájdený žiadny prepis spojený s týmto projektom.',
        es: 'No se pudo encontrar ninguna transcripción asociada con este proyecto.',
        ru: 'Ни одна транскрипция, связанная с данным проектом, не была найдена.',
        rs: 'Nije moguće pronaći transkripciju povezanu sa ovim projektom.',
        ua: "Жодної транскрипції, пов'язаної з цим проектом, не було знайдено.",
        no: 'Ingen transkripsjon knyttet til dette prosjektet ble funnet.',
        it: 'Non è stata trovata alcuna trascrizione associata a questo progetto.',
        se: 'Ingen transkription har hittats i samband med detta projekt.',
    },
    uploadStoppedHead: {
        us: 'Upload interrupted',
        cz: 'Nahrávání přerušeno',
        pl: 'Wczytywanie przerwane',
        hr: 'Prijenos je prekinut',
        si: 'Nalaganje je prekinjeno',
        de: 'Upload unterbrochen',
        sk: 'Nahrávanie prerušené',
        es: 'Se interrumpió la subida',
        ru: 'Загрузка прервана',
        rs: 'Otpremanje je prekinuto',
        ua: 'Завантаження перервано',
        no: 'Opplastingen avbrutt',
        it: 'Registrazione interrotta',
        se: 'Uppladningen avbröts',
    },
    uploadStoppedBody: {
        us: 'Upload of the recording failed. Check your internet connection and create the project again. ',
        cz: 'Nahrávání souboru selhalo. Zkontrolujte své internetové připojení a vytvořte projekt znovu. ',
        pl: 'Nie udało się wczytać nagrania. Sprawdź swoje połączenie internetowe i ponownie utwórz projekt. ',
        hr: 'Prijenos snimke nije uspio. Provjerite svoju internetsku vezu i ponovno stvorite projekt. ',
        si: 'Nalaganje posnetka ni uspelo. Preverite internetno povezavo in znova ustvarite projekt. ',
        de: 'Upload der Aufzeichnung ist fehlgeschlagen. Überprüfen Sie Ihre Internetverbindung und erstellen Sie das Projekt erneut. ',
        sk: 'Nahrávanie súboru zlyhalo. Skontrolujte internetové pripojenie a znovu vytvorte projekt. ',
        es: 'No se pudo subir la grabación y no se cargó ningún crédito. Compruebe su conexión a Internet y vuelva a crear el proyecto. ',
        ru: 'Загрузка записи не удалась и средства не были списаны. Проверьте ваше интернет соединение и создайте проект заново. ',
        rs: 'Otpremanje snimka nije uspelo I kredit nije naplaćen. Proverite internet vezu i ponovo kreirajte projekat. ',
        ua: "Завантаження запису не вдалося і кошти не були списані. Перевірте ваше інтернет з'єднання та створіть проект заново. ",
        no: 'Opplastingen av denne innspillingen ble avbrutt. Kontroller internett-tilkoblingen og lag prosjektet på nytt. ',
        it: 'Caricamento del file non riuscito. Controllare la connessione a Internet e creare nuovamente il progetto. ',
        se: 'Det gick inte att ladda upp inspelningen. Kontrollera din internetanslutning och skapa projektet igen. ',
    },
    mediaProcessingFailedHead: {
        us: 'Recording processing failed!',
        cz: 'Zpracování nahrávky selhalo!',
        pl: 'Przetworzenie nagrania nie powiodło się!',
        hr: 'Obrada snimke nije uspjela!',
        si: 'Obdelava posnetka ni uspela!',
        de: 'Verarbeitung der Aufzeichnung fehlgeschlagen!',
        sk: 'Spracovanie nahrávky zlyhalo!',
        es: '¡Falló el procesamiento de la grabación!',
        ru: 'Обработка записи не удалась!',
        rs: 'Obrada snimka nije uspela!',
        ua: 'Обробка запису не вдалася!',
        no: 'Behandling av innspillingen mislyktes!',
        it: 'Elaborazione della registrazione fallita!',
        se: 'Bearbetning av inspelningen misslyckades!',
    },
    mediaProcessingFailedBody: {
        us: 'There was an error while processing the recording. Create the project again or fill a bug report at https://www.beey.io/en/beey-bug-report',
        cz: 'Při zpracování nahrávky došlo k chybě. Nahrajte projekt znovu, nebo nahlašte chybu na https://www.beey.io/cs/beey-bug-report',
        pl: 'Wystąpił błąd podczas przetwarzania nagrania. Utwórz projekt ponownie lub wypełnij raport o błędzie na https://www.beey.io/pl/beey-bug-report',
        hr: 'Došlo je do pogreške prilikom obrade snimke. Ponovno stvorite projekt ili ispunite izvješće o grešci na https://www.beey.io/en/beey-bug-report',
        si: 'Pri obdelavi posnetka je prišlo do napake. Znova ustvarite projekt ali izpolnite poročilo o napakah na https://www.beey.io/en/beey-bug-report',
        de: 'Es ist ein Fehler bei Verarbeitung der Aufzeichnung aufgetreten. Erstellen Sie das Projekt erneut oder füllen Sie einen Fehlerbericht unter https://www.beey.io/en/beey-bug-report aus',
        sk: 'Pri spracovaní nahrávky došlo k chybe. Nahrajte projekt znova alebo nahláste chybu na https://www.beey.io/cz/beey-bug-report',
        es: 'Hubo un error al procesar la grabación. Cree el proyecto de nuevo o complete un informe de error en https://www.beey.io/en/beey-bug-report',
        ru: 'При обработке записи возникла ошибка. Загрузите проект снова, или оповестите об ошибке по адресу https://www.beey.io/en/beey-bug-report',
        rs: 'Došlo je do greške pri obradi snimka. Kredit nije naplaćen. Ponovo kreirajte projekat ili popunite izveštaj o grešci na https://www.beey.io/en/beey-bug-report',
        ua: 'Під час обробки запису виникла помилка. Завантажте проект знову, або сповістіть про помилку за адресою https://www.beey.io/en/beey-bug-report',
        no: 'Det oppsto en feil under behandling av innspillingen! Lag prosjektet på nytt og rapporter feilen på https://www.beey.io/en/beey-bug-report',
        it: "Si è verificato un errore nell'elaborazione della registrazione. Caricare nuovamente il progetto o segnalare l'errore a https://www.beey.io/en/beey-bug-report",
        se: 'Ett fel uppstod under bearbetning av inspelningen. Skapa projektet igen eller rapportera felet på https://www.beey.io/en/beey-bug-report',
    },
    noWordsYet: {
        us: 'Transcribing...',
        cz: 'Přepisuji...',
        pl: 'Transkrybuję...',
        hr: 'Transkripcija u tijeku...',
        si: 'Prepis v poteku...',
        de: 'Transkription läuft...',
        sk: 'Prebieha prepis...',
        es: 'Transcribiendo...',
        ru: 'Транскрибируется...',
        rs: 'Transkribuje se...',
        ua: 'Транскрибується...',
        no: 'Transkriberer...',
        it: 'Trascrizione...',
        se: 'Transkriberar...',
    },
    changeEverywhere: {
        us: 'Change everywhere in the project',
        cz: 'Změnit všude v projektu',
        pl: 'Zmień w całym projekcie',
        hr: 'Promijeni svugdje u projektu',
        si: 'Zamenjaj povsod v projektu',
        de: 'Überall im Projekt ändern',
        sk: 'Zmeniť všade v projekte',
        es: 'Cambiar en todas partes en el proyecto',
        ru: 'Изменить везде в проекте',
        rs: 'Promeni svuda u projektu',
        ua: 'Змінити скрізь у проекті',
        no: 'Endre i hele prosjektet',
        it: 'Cambia ovunque nel progetto',
        se: 'Ändra överallt inom projektet',
    },
    activeGrammarlyTitle: {
        us: 'Active Grammarly plugin',
        cz: 'Aktivní Grammarly plugin',
        pl: 'Aktywna wtyczka Grammarly',
        hr: 'Aktivan Grammarly dodatak',
        si: 'Aktiven Grammarly vtičnik',
        de: 'Aktives Grammarly-Plugin',
        sk: 'Aktívny Grammarly plugin',
        es: 'El complemento Grammarly está activo',
        ru: 'Активный плагин Грамматики',
        rs: 'Aktivan Grammarly dodatak',
        ua: 'Активний плагін Граматики',
        no: 'Grammarly programtillegget aktivert',
        it: 'Plugin Grammarly attivo',
        se: 'Grammarly-tillägg aktiverat',
    },
    activeDeeplTitle: {
        us: 'Active DeepL plugin',
        cz: 'Aktivní DeepL plugin',
        pl: 'Aktywna wtyczka DeepL',
        hr: 'Aktivan DeepL dodatak',
        si: 'Aktiven DeepL vtičnik',
        de: 'Aktives DeepL-Plugin',
        sk: 'Aktívny DeepL plugin',
        es: 'El complemento DeepL está activo',
        ru: 'Активный плагин DeepL',
        rs: 'Aktivan DeepL dodatak',
        ua: 'Активний плагін DeepL',
        no: 'DeepL programtillegget aktivert',
        it: 'Plugin DeepL attivo',
        se: 'DeepL-tillägg aktiverat',
    },
    activePluginDescription: {
        us: "This plugin disrupts the editor's functionality. We highly recommend deactivating it for this site.",
        cz: 'Tento plugin narušuje funkčnost editoru. Doporučujeme ho pro tuto stránku deaktivovat.',
        pl: 'Ta wtyczka zakłóca funkcjonalność edytora. Zalecamy wyłączenie je dla tej strony.',
        hr: 'Ovaj dodatak ometa funkcionalnost editora. Preporučujemo da ga za ovu stranicu isključite.',
        si: 'Ta vtičnik moti delovanje urejevalnika. Priporočamo, da ga deaktivirate za to spletno stran.',
        de: 'Dieses Plugin stört die Funktionalität des Editors. Wir empfehlen es für diese Website zu deaktivieren.',
        sk: 'Tento plugin narúša funkčnosť editora. Odporúčame ho pre túto stránku deaktivovať.',
        es: 'Este complemento interrumpe la funcionalidad del editor. Recomendamos encarecidamente desactivarlo para este sitio.',
        ru: 'Этот плагин нарушает функционирование редактора. Мы рекомендуем деактивировать его для этого сайта.',
        rs: 'Ovaj dodatak ometa funkcionalnost editora. Preporučujemo da ga za ovu stranicu isključite.',
        ua: 'Цей плагін порушує функціонування редактора. Ми рекомендуємо його деактивувати для цього сайту.',
        no: 'Dette programtillegget forstyrrer funksjoner av editoren. Vi sterkt anbefaler å deaktivere det for dette nettstedet.',
        it: "Questo plugin interferisce con la funzionalità dell'editor. Si consiglia di disattivarlo per questa pagina.",
        se: 'Detta tillägg stör editorns funktionalitet. Vi rekommenderar starkt att du inaktiverar det för denna sida.',
    },
    forgetSpeaker: {
        us: 'Forget speaker',
        cz: 'Zapomenout mluvčího',
        pl: 'Zapomnij mówcę',
        hr: 'Zaboravi govornika',
        si: 'Pozabi govorca',
        de: 'Sprecher vergessen',
        sk: 'Zabudnúť rečníka',
        es: 'Olvidar hablante',
        ru: 'Забыть говорящего',
        rs: 'Zaboravi govornika',
        ua: 'Забути того, хто говорить',
        no: 'Glemme taleren',
        it: 'Dimenticare lo speaker',
        se: 'Glöm talare',
    },
    confirmDelete: {
        us: 'Delete from your speaker database?',
        cz: 'Odstranit z vaší databáze mluvčích?',
        pl: 'Usunąć z bazy danych mówców?',
        hr: 'Izbrisati iz baze podataka govornika?',
        si: 'Izbrisati iz podatkovne baze govorcev?',
        de: 'Aus Ihrer Sprecher-Datenbank löschen?',
        sk: 'Odstrániť z databázy rečníkov?',
        es: '¿Eliminar de la base de datos de sus hablantes?',
        ru: 'Удалить из базы данных динамиков?',
        rs: 'Izbriši iz baze govornika?',
        ua: 'Видалити з бази даних спікерів?',
        no: 'Slette fra talerdatabasen din?',
        it: 'Rimuovere dal database degli speaker?',
        se: 'Radera från din talardatabas?',
    },
    confirmDeleteContent: {
        us: "will remain used in projects but won't be offered among select options anymore.",
        cz: 'zůstane použitý v projektech, ale už nebude v nabídce mluvčích.',
        pl: 'będzie nadal używany w projektach, ale nie będzie już dostępny w bazie danych mówców.',
        hr: 'ostat će korišteni u projektima, ali više neće biti ponuđeni među odabranim opcijama.',
        si: 'se bodo še naprej uporabljali v projektih, vendar ne bodo več na voljo med izbranimi možnostmi.',
        de: 'wird weiterhin in Projekten verwendet, wird aber nicht mehr unter den Auswahloptionen angeboten.',
        sk: 'sa bude naďalej používať v projektoch, ale už nebude v ponuke rečníkov.',
        es: 'Permanecerá disponible en proyectos pero ya no se ofrecerá entre las opciones seleccionadas.',
        ru: 'будет по-прежнему использоваться в проектах, но больше не будет предлагаться среди вариантов выбора.',
        rs: 'biće i dalje korišćeno u projektima, ali više neće biti ponuđeno među opcijama za izbor.',
        ua: 'залишиться у проєкті, але більше не буде пропонуватися серед вибраних варіантів.',
        no: 'skal bevare brukt i prosjektene, men blir ikke tilbudt blant talere.',
        it: 'rimarrà utilizzato nei progetti, ma non sarà più presente nelle opzioni.',
        se: 'förblir använd i tidigare projekt men kommer inte längre att erbjudas från talardatabasen.',
    },
    findAndReplace: {
        us: 'Find and replace',
        cz: 'Najít a nahradit',
        pl: 'Znajdź i zamień',
        hr: 'Nađi i zamijeni',
        si: 'Najdi in zamenjaj',
        de: 'Suchen und Ersetzen',
        sk: 'Nájsť a nahradiť',
        es: 'Buscar y reemplazar',
        ru: 'Найти и заменить',
        rs: 'Završi tutorial',
        ua: 'Знайти та замінити',
        no: 'Finne og erstatte',
        it: 'Trova e sostituisci',
        se: 'Hitta och ersätt',
    },
    findAndReplaceWord: {
        us: 'Find and replace word',
        cz: 'Najít a nahradit slovo',
        pl: 'Znajdź i zamień słowo',
        hr: 'Pronađi i zamijeni riječ',
        si: 'Najdi in zamenjaj besedo',
        de: 'Wort suchen und ersetzen',
        sk: 'Nájsť a nahradiť slovo',
        es: 'Buscar y reemplazar palabra',
        ru: 'Найти и заменить слово',
        rs: 'Naži i zameni reč',
        ua: 'Знайти та замінити слово',
        no: 'Finne og erstatte ord',
        it: 'Trovare e sostituire la parola',
        se: 'Hitta och ersätt ord',
    },
    search: {
        us: 'Find',
        cz: 'Najít',
        pl: 'Znajdź',
        hr: 'Pretraži',
        si: 'Preišči',
        de: 'Suchen',
        sk: 'Nájsť',
        es: 'Buscar',
        ru: 'Найти',
        rs: 'Pretraži',
        ua: 'Знайти',
        no: 'Finne',
        it: 'Trova',
        se: 'Sök',
    },
    enterSearchedWord: {
        us: 'Please enter search text',
        cz: 'Zadejte prosím hledaný text',
        pl: 'Proszę wpisać wyszukiwany tekst',
        hr: 'Unesite tekst za pretraživanje',
        si: 'Vnesite besedilo za preiskovanje',
        de: 'Bitte Suchtext eingeben',
        sk: 'Zadajte prosím hľadaný text',
        es: 'Ingrese el texto de búsqueda',
        ru: 'Пожалуйста введи текст для поиска',
        rs: 'Unesite tekst za pretraživanje',
        ua: 'Будь ласка, введи текст для пошуку',
        no: 'Vennligst oppgi søketeksten',
        it: 'Inserire il testo che si sta cercando',
        se: 'Vänligen ange söktext',
    },
    replace: {
        us: 'Replace',
        cz: 'Nahradit',
        pl: 'Zamień',
        hr: 'Zamijeni',
        si: 'Zamenjaj',
        de: 'Ersetzen',
        sk: 'Nahradiť',
        es: 'Reemplazar',
        ru: 'Заменить',
        rs: 'Zameni',
        ua: 'Замінити',
        no: 'Erstatte',
        it: 'Sostituire',
        se: 'Ersätt',
    },
    replaceWith: {
        us: 'Replace with',
        cz: 'Nahradit čím',
        pl: 'Zamień na',
        hr: 'Zamijeni s',
        si: 'Zamenjaj z',
        de: 'Ersetzen durch',
        sk: 'Nahradiť čím',
        es: 'Reemplazar con',
        ru: 'Заменить на',
        rs: 'Zameni sa',
        ua: 'Замінити на',
        no: 'Erstatte med',
        it: 'Sostituire con ciò che',
        se: 'Ersätt med',
    },
    matchCase: {
        us: 'Match case',
        cz: 'Rozlišovat velká a malá písmena',
        pl: 'Wielkość liter ma znaczenie',
        hr: 'Uskladi veličinu slova',
        si: 'Ujemaj velikost črk',
        de: 'Groß-/Kleinschreibung berücksichtigen',
        sk: 'Rozlišovať veľké a malé písmená',
        es: 'Distingue mayúsculas y minúsculas',
        ru: 'Различать заглавную и строчную буквы',
        rs: 'Uskladi veličinu slova',
        ua: 'Розрізняти великі та малі літери',
        no: 'Skjelne store/små bokstaver',
        it: 'Distinguere tra lettere maiuscole e minuscole',
        se: 'Skilj på versaler och gemener',
    },
    noResults: {
        us: 'No results',
        cz: 'Žádné výsledky',
        pl: 'Brak wyników',
        hr: 'Nema rezultata',
        si: 'Ni rezultata',
        de: 'Keine Ergebnisse',
        sk: 'Žiadne výsledky',
        es: 'No hay resultados',
        ru: 'Нет результатов',
        rs: 'Nema rezultata',
        ua: 'Немає результатів',
        no: 'Ingen resultater',
        it: 'Nessun risultato',
        se: 'Inga resultat',
    },
    updateSearching: {
        us: 'Click on Find to update results.',
        cz: 'Výsledky aktualizujete kliknutím na Najít.',
        pl: 'Kliknij Znajdź, aby zaktualizować wyniki.',
        hr: 'Kliknite na Pretraži za ažuriranje rezultata.',
        si: 'Kliknite na Poišči, da bi posodobili rezultate.',
        de: 'Klicken Sie auf Suchen, um die Ergebnisse zu aktualisieren.',
        sk: 'Kliknutím na tlačidlo Nájsť aktualizujete výsledky.',
        es: 'Haga clic en Buscar para actualizar los resultados.',
        ru: 'Нажмите на Найти для обновления результатов.',
        rs: 'Kliknite na Pretraži za ažuriranje rezultata.',
        ua: 'Натисніть на Знайти для оновлення результатів.',
        no: 'Klikk på Finne for å få oppdaterte resultater.',
        it: 'Per aggiornare i risultati, fare clic su Trova.',
        se: 'Klicka på Hitta för att uppdatera resultaten.',
    },
    firstSearch: {
        us: 'First click on Find to get results.',
        cz: 'Nejprve vyhledejte text kliknutím na Najít.',
        pl: 'Aby uzyskać wyniki, najpierw kliknij Znajdź.',
        hr: 'Prvo kliknite na Pretraži da biste dobili rezultate.',
        si: 'Naprej kliknite na Poišči, da bi dobili rezultate.',
        de: 'Klicken Sie zuerst auf Suchen, um Ergebnisse zu erhalten.',
        sk: 'Najprv vyhľadajte text kliknutím na Nájsť.',
        es: 'Haga clic en Buscar para obtener los resultados.',
        ru: 'Сначала нажмите на найти для получения результатов.',
        rs: 'Prvo kliknite na Pretraži da biste dobili rezultate.',
        ua: 'Спочатку натисніть на знайти для отримання результатів.',
        no: 'Først klikk på Finne for å få resultater.',
        it: 'Per prima cosa, cercate il testo facendo clic su Trova.',
        se: 'Först klicka på Hitta för att hitta resultat.',
    },
    numberOfOccurrence: {
        us: 'Number of occurrences replaced',
        cz: 'Počet nahrazených výskytů',
        pl: 'Liczba zastąpionych wystąpień',
        hr: 'Broj zamijenjenih pojava',
        si: 'Število zamenjanih pojavov',
        de: 'Anzahl der ersetzten Vorkommen',
        sk: 'Počet nahradených výskytov',
        es: 'Número de ocurrencias reemplazadas',
        ru: 'Количество замененных позиций',
        rs: 'Broj zamenjenih pojava',
        ua: 'Кількість замінених позицій',
        no: 'Antall erstattet forekomster',
        it: 'Numero di occorrenze sostituite',
        se: 'Antal ersatta förekomster',
    },
    replaceAll: {
        us: 'Replace all',
        cz: 'Nahradit vše',
        pl: 'Zamień wszystko',
        hr: 'Zamijeni sve',
        si: 'Zamenjaj vse',
        de: 'Alle ersetzen',
        sk: 'Nahradiť všetko',
        es: 'Reemplazar todo',
        ru: 'Заменить все',
        rs: 'Zamijeni sve',
        ua: 'Замінити все',
        no: 'Erstatte alt',
        it: 'Sostituire tutti',
        se: 'Ersätt allt',
    },
    addReplacementRule: {
        us: 'Add replacement rule',
        cz: 'Přidat nahrazovací pravidlo',
        pl: 'Dodaj regułę zamiany',
        hr: 'Dodaj pravilo zamjene',
        si: 'Dodaj pravilo zamenjave',
        de: 'Ersetzungsregel hinzufügen',
        sk: 'Pridať nahradzovacie pravidlo',
        es: 'Agregar regla de reemplazo',
        ru: 'Добавить правило замены',
        rs: 'Dodaj pravilo za zamenu',
        ua: 'Додати правило заміни',
        no: 'Legge til en erstatningsregel',
        it: 'Aggiungere una regola di sostituzione',
        se: 'Lägga till ersättningsregel',
    },
    replacementRuleAdded: {
        us: 'Replacement rule added',
        cz: 'Pravidlo bylo přidáno',
        pl: 'Reguła została dodana',
        hr: 'Pravilo zamjene je dodano',
        si: 'Pravilo zamenjave je dodano',
        de: 'Ersetzungsregel hinzugefügt',
        sk: 'Nahradzovacie pravidlo pridané',
        es: 'Se agregó una regla de reemplazo',
        ru: 'Правило замены добавлено',
        rs: 'Dodato pravilo za zamenu',
        ua: 'Правило заміни додано',
        no: 'Regelen ble lagt',
        it: 'La regola è stata aggiunta',
        se: 'Regeln har lagts till',
    },
    toList: {
        us: 'to list',
        cz: 'do seznamu',
        pl: 'do listy',
        hr: 'na popis',
        si: 'na seznam',
        de: 'zur Liste',
        sk: 'do zoznamu',
        es: 'a la lista',
        ru: 'в список',
        rs: 'u listu',
        ua: 'до списку',
        no: 'til listen',
        it: 'nella lista',
        se: 'i listan',
    },
    word: {
        us: 'The word',
        cz: 'Slovo',
        pl: 'Słowo',
        hr: 'Riječ',
        si: 'Beseda',
        de: 'Das Wort',
        sk: 'Slovo',
        es: 'La palabra',
        ru: 'Слово',
        rs: 'Reč',
        ua: 'Слово',
        no: 'Ordet',
        it: 'Parola',
        se: 'Ordet',
    },
    hasReplacementRule: {
        us: 'is already in the replacement rules',
        cz: 'už je v pravidlech nahrazování',
        pl: 'znajduje się już w regułach zamiany',
        hr: 'je već u pravilima zamjene',
        si: 'je že v pravilih zamenjave',
        de: 'ist bereits in den Ersetzungsregeln enthalten',
        sk: 'sa už nachádza v nahradzovacích pravidlách',
        es: 'ya está en las reglas de reemplazo',
        ru: 'уже есть в правилах замены',
        rs: 'je već u pravilima za zamenu',
        ua: 'вже є в правилах заміни',
        no: 'allerede finnes i erstatningsreglene',
        it: 'è già presente nelle regole di sostituzione',
        se: 'finns redan i ersättningsreglerna',
    },
    failedAddReplacementRule: {
        us: 'Failed to add replacement rule',
        cz: 'Selhalo přidání nahrazovacího pravidla',
        pl: 'Dodanie nowej reguły zamiany nie powiodło się',
        hr: 'Dodavanje pravila zamjene nije uspjelo',
        si: 'Dodajanje pravila zamenjave ni uspelo',
        de: 'Ersetzungsregel konnte nicht hinzugefügt werden',
        sk: 'Nepodarilo sa pridať nahradzovacie pravidlo',
        es: 'Error al agregar regla de reemplazo',
        ru: 'Не удалось добавить правило замены',
        rs: 'Neuspešno dodavanje pravila za zamenu',
        ua: 'Не вдалося додати правило заміни',
        no: 'Klarte ikke legge til erstatningsregelen',
        it: 'Impossibile aggiungere una regola sostitutiva',
        se: 'Det gick inte att lägga till ersättningsregeln',
    },
    selectSpeaker: {
        us: "Start typing speaker's name...",
        cz: 'Začněte psát jméno mluvčího...',
        pl: 'Zacznij wpisywać imię i nazwisko mówcy...',
        hr: 'Počnite upisivati ime govornika...',
        si: 'Začnite vnašati ime govorca...',
        de: 'Geben Sie den Namen des Sprechers ein...',
        sk: 'Začnite písať meno rečníka...',
        es: 'Comience a escribir el nombre del hablante...',
        ru: 'Начать вводить имя докладчика...',
        rs: 'Počnite kucati ime govornika...',
        ua: "Почніть вводити ім'я спікера...",
        no: 'Begynn å skrive talerens navn...',
        it: 'Iniziare a digitare il nome dello speaker...',
        se: 'Börja skriva talarens namn...',
    },
    editSpeaker: {
        us: 'Edit speaker',
        cz: 'Upravit mluvčího',
        pl: 'Edytuj mówcę',
        hr: 'Uredi govornika',
        si: 'Uredi govorca',
        de: 'Sprecher bearbeiten',
        sk: 'Upraviť rečníka',
        es: 'Editar hablante',
        ru: 'Изменить динамик',
        rs: 'Edituj govornika',
        ua: 'Редагувати спікера',
        no: 'Redigere taleren',
        it: 'Modifica speaker',
        se: 'Redigera talare',
    },
    addRole: {
        us: 'Add role',
        cz: 'Přidat roli',
        pl: 'Dodaj rolę',
        hr: 'Dodaj ulogu',
        si: 'Dodaj vlogo',
        de: 'Rolle hinzufügen',
        sk: 'Pridať rolu',
        es: 'Añadir rol',
        ru: 'Добавить роль',
        rs: 'Dodaj ulogu',
        ua: 'Додати роль',
        no: 'Legge til en rolle',
        it: 'Aggiungere un ruolo',
        se: 'Lägga till roll',
    },
    editRole: {
        us: 'Edit role',
        cz: 'Upravit roli',
        pl: 'Edytuj rolę',
        hr: 'Uredi ulogu',
        si: 'Uredi vlogo',
        de: 'Rolle bearbeiten',
        sk: 'Upraviť rolu',
        es: 'Editar rol',
        ru: 'Изменить роль',
        rs: 'Edituj ulogu',
        ua: 'Редагувати роль',
        no: 'Redigere rollen',
        it: 'Modifica ruolo',
        se: 'Redigera rollen',
    },
    deleteRole: {
        us: 'Delete role?',
        cz: 'Smazat roli?',
        pl: 'Usunąć rolę?',
        hr: 'Izbriši ulogu?',
        si: 'Izbriši vlogo?',
        de: 'Rolle löschen?',
        sk: 'Vymazať rolu?',
        es: '¿Eliminar rol?',
        ru: 'Удалить роль?',
        rs: 'Izbriši ulogu?',
        ua: 'Видалити роль?',
        no: 'Slette rollen?',
        it: 'Cancellare un ruolo?',
        se: 'Radera rollen?',
    },
    failedToFetchSpeaker: {
        us: 'Failed to load speakers.',
        cz: 'Selhalo načtení mluvčích.',
        pl: 'Wczytanie mówców się nie powiodło.',
        hr: 'Učitavanje govornika nije uspjelo.',
        si: 'Nalaganje govorcev ni uspelo.',
        de: 'Sprecher konnten nicht geladen werden.',
        sk: 'Nepodarilo sa načítať rečníky.',
        es: 'Error al cargar los hablantes.',
        ru: 'Не удалось загрузить динамики.',
        rs: 'Neuspešno učitavanje govornika.',
        ua: 'Не вдалося завантажити колонки.',
        no: 'Klarte ikke å laste talerene.',
        it: 'Impossibile caricare gli speaker.',
        se: 'Det gick inte att ladda talarna.',
    },
    failedToFetchRole: {
        us: 'Failed to load roles.',
        cz: 'Selhalo načtení rolí.',
        pl: 'Wczytanie roli się nie powiodło.',
        hr: 'Učitavanje uloga nije uspjelo.',
        si: 'Nalaganje vlog ni uspelo.',
        de: 'Rollen konnten nicht geladen werden.',
        sk: 'Nepodarilo sa načítať roly.',
        es: 'Error al cargar los roles.',
        ru: 'Не удалось загрузить роли.',
        rs: 'Neuspešno učitavanje uloga.',
        ua: 'Не вдалося завантажити ролі.',
        no: 'Klarte ikke å laste rollene.',
        it: 'Caricamento ruoli non riuscito.',
        se: 'Det gick inte att ladda rollerna.',
    },
    chooseRole: {
        us: 'Choose a role from the selection.',
        cz: 'Vyberte roli z výběru.',
        pl: 'Wybierz rolę z listy wyboru.',
        hr: 'Odaberi ulogu iz odabira.',
        si: 'Izberite vlogo iz izbora.',
        de: 'Wählen Sie eine Rolle aus der Auswahl.',
        sk: 'Vyberte rolu z ponuky.',
        es: 'Elija un rol de la selección.',
        ru: 'Выберите роль из списка.',
        rs: 'Izaberi ulogu od ponuđenih.',
        ua: 'Виберіть роль із добірки.',
        no: 'Velg en rolle fra utvalget.',
        it: 'Selezionare un ruolo dalla selezione.',
        se: 'Välj en roll från urvalet.',
    },
    chooseOrWriteRole: {
        us: 'Select or write a role.',
        cz: 'Vyberte nebo napište roli.',
        pl: 'Wybierz lub wpisz rolę.',
        hr: 'Odaberi ili upiši ulogu.',
        si: 'Izberite ali vpišite vlogo.',
        de: 'Wählen oder schreiben Sie eine Rolle.',
        sk: 'Vyberte alebo zadajte rolu.',
        es: 'Seleccione o escriba un rol.',
        ru: 'Выберите или напишите роль.',
        rs: 'Selektuj ili upiši ulogu.',
        ua: 'Виберіть або напишіть роль.',
        no: 'Velg eller skriv en rolle.',
        it: 'Selezionare o digitare un ruolo.',
        se: 'Välj eller skriv en roll.',
    },
    lostConnection: {
        us: 'Lost connection with speaker database, reconnecting...',
        cz: 'Ztraceno spojení s databází mluvčích, obnovuji spojení...',
        pl: 'Utracono połączenie z bazą danych mówców. Łączę ponownie...',
        hr: 'Izgubljena veza s bazom podataka govornika, ponovno povezivanje...',
        si: 'Izgubljena povezava z bazo podatkov govorcev, ponovno povezovanje...',
        de: 'Verbindung zur Sprecherdatenbank verloren, Verbindung wird wiederhergestellt...',
        sk: 'Stratené spojenie s databázou hovorcov, obnovujeme spojenie...',
        es: 'Se perdió la conexión con la base de datos de los hablantes, reconectando...',
        ru: 'Соединение с базой данных спикера утеряно, соединение обновляется...',
        rs: 'Veza sa bazom govornika je prekinuta, ponovo se povezuje...',
        ua: "З'єднання з базою даних спікера втрачено, з'єднання оновлюється...",
        no: 'Forbindelsen med talerdatabasen ble avbrutt, forbindelsen gjenopprettes...',
        it: 'Connessione persa al database degli speaker, ripristino della connessione...',
        se: 'Anslutningen till talardatabasen har gått förlorad, anslutningen återupprättas...',
    },
    role: {
        us: 'Role',
        cz: 'Role',
        pl: 'Rola',
        hr: 'Uloga',
        si: 'Vloga',
        de: 'Rolle',
        sk: 'Rola',
        es: 'Rol',
        ru: 'Статус',
        rs: 'Uloga',
        ua: 'Статус',
        no: 'Rolle',
        it: 'Ruolo',
        se: 'Roll',
    },
    saveSpeaker: {
        us: 'Add to the database of speakers',
        cz: 'Přidat do databáze mluvčích',
        pl: 'Dodaj do bazy danych mówców',
        hr: 'Dodaj u bazu podataka govornika',
        si: 'Dodaj v podatkovno bazo govorcev',
        de: 'Hinzufügen zur Sprecher-Datenbank',
        sk: 'Pridanie do databázy rečníkov',
        es: 'Agregar a la base de datos de los hablantes',
        ru: 'Добавить в базу данных дикторов',
        rs: 'Dodajte u bazu podataka govornika',
        ua: 'Додати до бази даних дикторів',
        no: 'Legge til talerdatabasen',
        it: 'Aggiungi al database degli speaker',
        se: 'Lägga till i talardatabasen',
    },
    saveSpeakerTooltip: {
        us: 'You can use saved speakers in other projects',
        cz: 'Uložené mluvčí můžete použít v dalších projektech',
        pl: 'Zapisanych mówców możesz użyć w innych projektach',
        hr: 'Spremljene govornike možete koristiti u drugim projektima',
        si: 'Shranjene govorce lahko uporabite v drugih projektih',
        de: 'Sie können gespeicherte Sprecher in anderen Projekten verwenden',
        sk: 'Uložené rečníky môžete použiť v iných projektoch',
        es: 'Puede usar los hablantes guardados en otros proyectos',
        ru: 'Сохраненные спикеры можно использовать в других проектах',
        rs: 'Možete koristiti sačuvane govornike u drugim projektima',
        ua: 'Ви можете використовувати збережених спікерів в інших проектах',
        no: 'Du kan bruke de lagrede talerne i andre prosjekter',
        it: 'È possibile utilizzare gli speaker salvati in altri progetti',
        se: 'Du kan använda sparade talare i andra projekt',
    },
    speakerColor: {
        us: 'Color',
        cz: 'Barva',
        pl: 'Kolor',
        hr: 'Boja',
        si: 'Barva',
        de: 'Farbe',
        sk: 'Farba',
        es: 'Color',
        ru: 'Цвет',
        rs: 'Boja',
        ua: 'Колір',
        no: 'Farge',
        it: 'Colore',
        se: 'Färg',
    },
    speakerColorRed: {
        us: 'red',
        cz: 'červená',
        pl: 'czerwony',
        hr: 'crvena',
        si: 'rdeča',
        de: 'rot',
        sk: 'červená',
        es: 'rojo',
        ru: 'красный',
        rs: 'crvena',
        ua: 'червоний',
        no: 'rød',
        it: 'rosso',
        se: 'röd',
    },
    speakerColorBlue: {
        us: 'blue',
        cz: 'modrá',
        pl: 'niebieski',
        hr: 'plava',
        si: 'modra',
        de: 'blau',
        sk: 'modrá',
        es: 'azul',
        ru: 'синий',
        rs: 'plava',
        ua: 'синій',
        no: 'blå',
        it: 'blu',
        se: 'blå',
    },
    speakerColorLime: {
        us: 'lime',
        cz: 'limetková',
        pl: 'limonkowy',
        hr: 'limeta',
        si: 'limetna',
        de: 'lindgrün',
        sk: 'limetková',
        es: 'verde lima',
        ru: 'лаймовый',
        rs: 'limun',
        ua: 'лаймовий',
        no: 'limefarge',
        it: 'calce',
        se: 'limefärg',
    },
    speakerColorYellow: {
        us: 'yellow',
        cz: 'žlutá',
        pl: 'żółty',
        hr: 'žuta',
        si: 'rumena',
        de: 'gelb',
        sk: 'žltá',
        es: 'amarillo',
        ru: 'жёлтый',
        rs: 'žuta',
        ua: 'жовтий',
        no: 'gul',
        it: 'giallo',
        se: 'gul',
    },
    speakerColorMagenta: {
        us: 'magenta',
        cz: 'purpurová',
        pl: 'magenta',
        hr: 'magenta',
        si: 'škrlatna',
        de: 'purpurrot',
        sk: 'purpurová',
        es: 'morado',
        ru: 'пурпурный',
        rs: 'ružičasta',
        ua: 'пурпурний',
        no: 'lille',
        it: 'viola',
        se: 'purpur',
    },
    speakerColorCyan: {
        us: 'cyan',
        cz: 'tyrkysová',
        pl: 'turkusowy',
        hr: 'cijan',
        si: 'cianova',
        de: 'türkis',
        sk: 'tyrkysová',
        es: 'cian',
        ru: 'голубой',
        rs: 'tirkizna',
        ua: 'блакитний',
        no: 'turkis',
        it: 'turchese',
        se: 'turkos',
    },
    speakerColorBlack: {
        us: 'black',
        cz: 'černá',
        pl: 'czarny',
        hr: 'crna',
        si: 'črna',
        de: 'schwarz',
        sk: 'čierna',
        es: 'negro',
        ru: 'чёрный',
        rs: 'crna',
        ua: 'чорний',
        no: 'svart',
        it: 'nero',
        se: 'svart',
    },
    speakerColorWhite: {
        us: 'white',
        cz: 'bílá',
        pl: 'biały',
        hr: 'bijela',
        si: 'bela',
        de: 'weiß',
        sk: 'biela',
        es: 'blanco',
        ru: 'белый',
        rs: 'bela',
        ua: 'білий',
        no: 'hvit',
        it: 'bianco',
        se: 'vit',
    },
    speakerColorDefault: {
        us: 'default',
        cz: 'výchozí',
        pl: 'domyślny',
        hr: 'zadano',
        si: 'privzeto',
        de: 'default',
        sk: 'predvolená',
        es: 'por defecto',
        ru: 'по умолчанию',
        rs: 'uobičajeno',
        ua: 'за замовчуванням',
        no: 'automatisk',
        it: 'predefinito',
        se: 'default',
    },
    unaligned: {
        us: 'Text does not match audio',
        cz: 'Text neodpovídá audiu',
        pl: 'Tekst nie odpowiada audiu',
        hr: 'Tekst ne odgovara zvuku',
        si: 'Besedilo se ne ujema z zvokom',
        de: 'Text passt nicht zum Audio',
        sk: 'Text sa nezhoduje s audiom',
        es: 'El texto no coincide con el audio',
        ru: 'Текст не сооответствует аудио',
        rs: 'Tekst se ne podudara sa zvukom',
        ua: 'Текст не відповідає аудіо',
        no: 'Teksten samsvarer ikke med lyden',
        it: "Il testo non corrisponde all'audio",
        se: 'Texten stämmer inte överens med ljudet',
    },
    subtitleLong: {
        us: 'Subtitle is too long',
        cz: 'Titulek je příliš dlouhý',
        pl: 'Napis jest zbyt długi',
        hr: 'Titl je predug',
        si: 'Podnapis je predolg',
        de: 'Untertitel ist zu lang',
        sk: 'Titulok je príliš dlhý',
        es: 'El subtítulo es demasiado largo',
        ru: 'Субтитры слишком длинные',
        rs: 'Titlovi su predugi',
        ua: 'Субтитри надто довгі',
        no: 'Undertekst er for lang',
        it: 'Il sottotitolo è troppo lungo',
        se: 'Undertexten är för lång',
    },
    subtitleFastCritical: {
        us: 'Subtitle is too fast (CRITICAL):',
        cz: 'Titulek je příliš rychlý (KRITICKÉ):',
        pl: 'Napisy są zbyt szybkie (BŁĄD KRYTYCZNY):',
        hr: 'Titl je prebrz (KRITIČNO):',
        si: 'Podnapis je prehiter (KRITIČNO):',
        de: 'Untertitel ist zu schnell (KRITISCH):',
        sk: 'Titulok je príliš rýchly (KRITICKÉ):',
        es: 'El subtítulo es demasiado rápido (CRÍTICO):',
        ru: 'Субтитры слишком быстрые (Критически):',
        rs: 'Titlovi su previše brzi (KRITIČNO):',
        ua: 'Субтитри занадто швидкі (Критично):',
        no: 'Undertekst er for rask (KRITISK):',
        it: 'I sottotitoli sono troppo veloci (CRITICA):',
        se: 'Undertexten är för snabb (KRITISKT):',
    },
    subtitleTooFast: {
        us: 'Subtitle duration is shorter than',
        cz: 'Titulek trvá méně než',
        pl: 'Czas trwania napisu jest krótszy niż',
        hr: 'Trajanje titla je kraće od',
        si: 'Trajanje podnapisa je krajše od',
        de: 'Dauer des Untertitels ist kürzer als',
        sk: 'Trvanie titulku je kratšie ako',
        es: 'La duración del subtítulo es menos que',
        ru: 'Длина субтитра короче чем',
        rs: 'Trajanje titla je kraće od',
        ua: 'Довжина субтитру коротша за',
        no: 'Undertekst varer kortere enn',
        it: 'Il sottotitolo richiede meno di',
        se: 'Undertextens visningstid är kortare än',
    },
    subtitleTooSlow: {
        us: 'Subtitle is displayed longer than',
        cz: 'Titulek se zobrazuje déle než',
        pl: 'Czas trwania napisu jest dłuższy niż',
        hr: 'Titl je prikazan dulje od',
        si: 'Podnaslov je prikazan dlje kot',
        de: 'Untertitel wird länger angezeigt als',
        sk: 'Titulok sa zobrazuje dlhšie ako',
        es: 'El subtítulo se muestra más de',
        ru: 'Субтитры отображаются дольше, чем',
        rs: 'Titl je prikazan duže od',
        ua: 'Субтитри відображаються довше, ніж',
        no: 'Undertekst er vist lengre enn',
        it: 'Il titolo viene visualizzato più lungo di',
        se: 'Undertexten visas i mer än',
    },
    subtitleFasterThanOptimum: {
        us: 'Subtitle is fast:',
        cz: 'Titulek je rychlý:',
        pl: 'Napis jest szybki:',
        hr: 'Titl je brz:',
        si: 'Podnapis je hiter:',
        de: 'Untertitel ist schnell:',
        sk: 'Titulok je rýchly:',
        es: 'El subtítulo es rápido:',
        ru: 'Субтитр быстрый:',
        rs: 'Titl je brz:',
        ua: 'Субтитр швидкий:',
        no: 'Undertekst er rask:',
        it: 'Il sottotitolo è veloce:',
        se: 'Undertexten är snabb:',
    },
    timestampUnderZero: {
        us: 'Timestamp cannot be lower than 0:00:00',
        cz: 'Časová značka nemůže být nižší než 0:00:00',
        pl: 'Znacznik czasu nie może być mniejszy niż 0:00:00',
        hr: 'Vremenska oznaka ne može biti niža od 0:00:00',
        si: 'Časovni žig ne sme biti nižji od 0:00:00',
        de: 'Der Zeitstempel kann nicht kleiner als 0:00:00 sein.',
        sk: 'Časová značka nemôže byť nižšia ako 0:00:00',
        es: 'La marca de tiempo no puede ser inferior a 0:00:00',
        ru: 'Отметка времени не может быть ниже 0:00:00',
        rs: 'Vremenska oznaka ne može biti niža od 0:00:00',
        ua: 'Позначка часу не може бути нижчою за 0:00:00',
        no: 'Tidsstempel kan ikke være lavere enn 0:00:00',
        it: "L'orario non può essere inferiore a 0:00:00",
        se: 'Tidsstämpel kan inte vara lägre än 0:00:00',
    },
    timestampTooClose: {
        us: 'Timestamp is too close to adjacent timestamps.',
        cz: 'Časová značka je příliš blízko sousedním časovým značkám.',
        pl: 'Znacznik czasowy jest zbyt blisko sąsiednich znaczników czasowych.',
        hr: 'Vremenska oznaka je preblizu susjednim vremenskim oznakama.',
        si: 'Časovni žig je preblizu sosednjih časovnih žigov.',
        de: 'Zeitstempel liegt zu nah an benachbarten Zeitstempeln.',
        sk: 'Časová značka je príliš blízko susedných časových značiek.',
        es: 'La marca de tiempo está demasiado cerca de las marcas de tiempo adyacentes.',
        ru: 'Временная отметка слишком близко расположена к соседним временным отметкам.',
        rs: 'Vremenska oznaka je preblizu susednim vremenskim oznakama.',
        ua: 'Мітка часу розташована надто близько до сусідніх міток часу.',
        no: 'Tidsstemplet er for nær et tilgrensende tidsstempel.',
        it: 'Il timestamp è troppo vicino ai timestamp vicini.',
        se: 'Tidsstämpeln ligger för nära angränsande tidsstämplar.',
    },
    conflictingNextTimestamp: {
        us: 'Cannot insert timestamp, because it is inconsistent with the next timestamp.',
        cz: 'Nelze vložit časovou značku, protože je nekonzistentní s následující časovou značkou.',
        pl: 'Nie można wstawić znacznika czasu, ponieważ jest on niespójny z następnym znacznikiem czasu.',
        hr: 'Nije moguće umetnuti vremensku oznaku jer nije u skladu sa sljedećom vremenskom oznakom.',
        si: 'Ni mogoče vstaviti časovnega žiga, ker ni v skladu z naslednjim časovnim žigom.',
        de: 'Zeitstempel kann nicht eingefügt werden, da er nicht mit dem nächsten Zeitstempel übereinstimmt.',
        sk: 'Nemožno vložiť časovú značku, pretože je nekonzistentná s nasledujúcou časovou značkou.',
        es: 'No se puede insertar la marca de tiempo porque no es coherente con la siguiente marca de tiempo.',
        ru: 'Невозможно вставить метку времени, так как она несовместима со следующей меткой времени.',
        rs: 'Nije moguće umetnuti vremensku oznaku jer nije u skladu sa sledećom vremenskom oznakom.',
        ua: 'Не вдається вставити позначку часу, оскільки вона не відповідає наступній позначці часу.',
        no: 'Kan ikke sette inn et tidsstempel. Tidsstemplet er inkonsekvent med det følgende tidsstemplet.',
        it: 'Impossibile inserire un timestamp perché non è coerente con il timestamp seguente.',
        se: 'Det går inte att infoga en tidsstämpel, därför att den inte är förenlig med följande tidsstämpel.',
    },
    conflictingPreviousTimestamp: {
        us: 'Cannot insert timestamp, because it is inconsistent with the previous timestamp.',
        cz: 'Nelze vložit časovou značku, protože je nekonzistentní s předchozí časovou značkou.',
        pl: 'Nie można wstawić znacznika czasu, ponieważ jest on niespójny z poprzednim znacznikiem czasu.',
        hr: 'Nije moguće umetnuti vremensku oznaku jer nije u skladu s prethodnom vremenskom oznakom.',
        si: 'Ni mogoče vstaviti časovnega žiga, ker ni v skladu s prejšnjim časovnim žigom.',
        de: 'Zeitstempel kann nicht eingefügt werden, da er nicht mit dem vorherigen Zeitstempel übereinstimmt.',
        sk: 'Nemožno vložiť časovú značku, pretože je nekonzistentná s predchádzajúcou časovou značkou.',
        es: 'No se puede insertar la marca de tiempo porque no es coherente con la marca de tiempo anterior.',
        ru: 'Невозможно вставить временную метку, так как она не соответствует предыдущей временной метке.',
        rs: 'Nije moguće umetnuti vremensku oznaku jer nije u skladu s prethodnom vremenskom oznakom.',
        ua: 'Неможливо вставити позначку часу, оскільки вона не відповідає попередній позначці часу.',
        no: 'Kan ikke sette inn et tidsstempel. Tidsstemplet er inkonsekvent med det forrige tidsstemplet.',
        it: 'Non è possibile inserire un timestamp perché non è coerente con il timestamp precedente.',
        se: 'Det går inte att infoga en tidsstämpel, därför att den inte är förenlig med föregående tidsstämpel.',
    },
    confirmTimestamp: {
        us: 'Do you really want to set this timestamp for the selected word?',
        cz: 'Opravdu chcete k vybranému slovu nastavit tuto časovou značku?',
        pl: 'Czy na pewno chcesz dla wybranego słowa nastawić ten znacznik czasowy?',
        hr: 'Želite li doista postaviti ovu vremensku oznaku za odabranu riječ?',
        si: 'Ali res želite nastaviti ta časovni žig za izbrano besedo?',
        de: 'Wollen Sie diesen Zeitstempel wirklich für das ausgewählte Wort setzen?',
        sk: 'Naozaj chcete nastaviť túto časovú značku pre vybrané slovo?',
        es: '¿Realmente desea establecer esta marca de tiempo para la palabra seleccionada?',
        ru: 'Вы действительно хотите применить эту часовую отметку для выбранного слова?',
        rs: 'Da li zaista želite da postavite ovu vremensku oznaku za odabranu reč?',
        ua: 'Попередня тимчасова позначка',
        no: 'Er du sikker på at du vil stille dette tidsstemplet på det velvalgte ordet?',
        it: 'Si vuole davvero impostare questo timestamp sulla parola selezionata?',
        se: 'Är du säker på att du vill sätta denna tidstämpel på det valda ordet?',
    },
    previousTimestamp: {
        us: 'Previous timestamp',
        cz: 'Minulá časová značka',
        pl: 'Poprzedni znacznik czasowy',
        hr: 'Prethodna vremenska oznaka',
        si: 'Prejšnji časovni žig',
        de: 'Vorheriger Zeitstempel',
        sk: 'Predchádzajúca časová značka',
        es: 'Marca de tiempo anterior',
        ru: 'Предыдущая временная отметка',
        rs: 'Prethodna vremenska oznaka',
        ua: 'Нова тимчасова позначка',
        no: 'Forrige tidsstempel',
        it: 'Timestamp precedente',
        se: 'Föregående tidsstämpel',
    },
    newTimestamp: {
        us: 'New timestamp',
        cz: 'Nová časová značka',
        pl: 'Nowy znacznik czasowy',
        hr: 'Nova vremenska oznaka',
        si: 'Nov časovni žig',
        de: 'Neuer Zeitstempel',
        sk: 'Nová časová značka',
        es: 'Nueva marca de tiempo',
        ru: 'Новая временная отметка',
        rs: 'Nova vremenska oznaka',
        ua: 'Нова тимчасова позначка',
        no: 'Nytt tidsstempel',
        it: 'Nuovo timestamp',
        se: 'Ny tidsstämpel',
    },
    timestampSuccess: {
        us: 'Timestamp was fixed to',
        cz: 'Časová značka byla nastavena na',
        pl: 'Znacznik czasu był ustawiony na',
        hr: 'Vremenska oznaka je postavljena na',
        si: 'Časovni žig je določen na',
        de: 'Zeitstempel wurde festgelegt auf',
        sk: 'Časová značka bola nastavená na',
        es: 'La marca de tiempo se fijó en',
        ru: 'Отметка времени была исправлена ​​на',
        rs: 'Nova vremenska oznaka',
        ua: 'Позначку часу встановлено на',
        no: 'Tidsstemplet ble festet til',
        it: 'Il timestamp è stato impostato su',
        se: 'Tidsstämpeln sattes till',
    },
    headingOne: {
        us: 'Heading 1',
        cz: 'Nadpis 1',
        pl: 'Nagłówek 1',
        hr: 'Naslov 1',
        si: 'Naslov 1',
        de: 'Überschrift 1',
        sk: 'Nadpis 1',
        es: 'Título 1',
        ru: 'Заголовок 1',
        rs: 'Naslov 1',
        ua: 'Заголовок 1',
        no: 'Overskrift 1',
        it: 'Titolo 1',
        se: 'Rubrik 1',
    },
    headingTwo: {
        us: 'Heading 2',
        cz: 'Nadpis 2',
        pl: 'Nagłówek 2',
        hr: 'Naslov 2',
        si: 'Naslov 2',
        de: 'Überschrift 2',
        sk: 'Nadpis 2',
        es: 'Título 2',
        ru: 'Заголовок 2',
        rs: 'Naslov 2',
        ua: 'Заголовок 2',
        no: 'Overskrift 2',
        it: 'Titolo 2',
        se: 'Rubrik 2',
    },
    capitals: {
        us: 'Capital letters',
        cz: 'Velká písmena',
        pl: 'Wielkie litery',
        hr: 'Velika slova',
        si: 'Velike črke',
        de: 'Großbuchstaben',
        sk: 'Veľké písmená',
        es: 'Letras mayúsculas',
        ru: 'Заглавные буквы',
        rs: 'Velika slova',
        ua: 'Великі літери',
        no: 'Store bokstaver',
        it: 'Lettere maiuscole',
        se: 'Versaler',
    },
    lowerCaseText: {
        us: 'lower case',
        cz: 'malá písmena',
        pl: 'małe litery',
        hr: 'mala slova',
        si: 'male črke',
        de: 'kleinbuchstaben',
        sk: 'malé písmená',
        es: 'minúsculas',
        ru: 'маленькие буквы',
        rs: 'mala slova',
        ua: 'малі літери',
        no: 'små bokstaver',
        it: 'lettere minuscole',
        se: 'gemener',
    },
    upperCaseText: {
        us: 'UPPER CASE',
        cz: 'VELKÁ PÍSMENA',
        pl: 'WIELKIE LITERY',
        hr: 'VELIKA SLOVA',
        si: 'VELIKE ČRKE',
        de: 'GROSSBUCHSTABEN',
        sk: 'VEĽKÉ PÍSMENÁ',
        es: 'MAYÚSCULAS',
        ru: 'ЗАГЛАВНЫЕ БУКВЫ',
        rs: 'VELIKA SLOVA',
        ua: 'ВЕЛИКІ ЛІТЕРИ',
        no: 'STORE BOKSTAVER',
        it: 'LETTERE MAIUSCOLE',
        se: 'VERSALER',
    },
    titleCaseText: {
        us: 'Title case',
        cz: 'Velké písmeno na začátku',
        pl: 'Wielka litera na początku',
        hr: 'Prvo veliko početno slovo',
        si: 'Velike črke na začetku',
        de: 'Großbuchstabe am Anfang',
        sk: 'Veľké písmeno na začiatku',
        es: 'Letra mayúscula al principio',
        ru: 'Заглавная буква в начале',
        rs: 'Veliko početno slovo',
        ua: 'Великі літери на початку',
        no: 'Stor forbokstav',
        it: "Lettera maiuscola all'inizio",
        se: 'Versal i början',
    },
    clearFormat: {
        us: 'Clear formatting',
        cz: 'Zrušit formátování',
        pl: 'Usuń formatowanie',
        hr: 'Izbriši oblikovanje',
        si: 'Izbriši oblikovanje',
        de: 'Formatierung löschen',
        sk: 'Zrušiť formátovanie',
        es: 'Formato claro',
        ru: 'Отменить форматирование',
        rs: 'Poništite formatiranje',
        ua: 'Скасувати форматування',
        no: 'Fjern all formatering',
        it: 'Annullamento della formattazione',
        se: 'Ta bort formatering',
    },
    assignSpeaker: {
        us: 'Assign speaker',
        cz: 'Přiřadit mluvčího',
        pl: 'Przypisz mówcę',
        hr: 'Dodijeli govornika',
        si: 'Dodeli govorca',
        de: 'Sprecher zuweisen',
        sk: 'Priradiť rečníka',
        es: 'Asignar hablante',
        ru: 'Добавить спикера',
        rs: 'Dodeli govornika',
        ua: 'Додати спікера',
        no: 'Tildele taler',
        it: 'Assegnare speaker',
        se: 'Tilldela talare',
    },
    changeSpeaker: {
        us: 'Change speaker',
        cz: 'Změnit mluvčího',
        pl: 'Zmień mówcę',
        hr: 'Promijeni govornika',
        si: 'Spremeni govorca',
        de: 'Sprecher wechseln',
        sk: 'Zmena rečníka',
        es: 'Cambiar hablante',
        ru: 'Изменить динамик',
        rs: 'Promeni govornika',
        ua: 'Змінити динаміку',
        no: 'Endre taler',
        it: 'Cambiare speaker',
        se: 'Byta talare',
    },
    unavailable: {
        us: 'Unavailable',
        cz: 'Nedostupné',
        pl: 'Niedostępne',
        hr: 'Nedostupno',
        si: 'Ni na voljo',
        de: 'Nicht verfügbar',
        sk: 'Nedostupné',
        es: 'Indisponible',
        ru: 'Недоступно',
        rs: 'Nedostupno',
        ua: 'Недоступно',
        no: 'Utilgjengelig',
        it: 'Non disponibile',
        se: 'Ej tillgängligt',
    },
    loadingApps: {
        us: 'Loading apps...',
        cz: 'Načítám aplikace...',
        pl: 'Wczytuję aplikacje...',
        hr: 'Učitavam aplikacije...',
        si: 'Nalagam aplikacije...',
        de: 'Apps werden geladen...',
        sk: 'Načítavam aplikácie...',
        es: 'Cargando las aplicaciones...',
        ru: 'Приложение загружается...',
        rs: 'Učitavanje aplikacija...',
        ua: 'Програма завантажується...',
        no: 'Laster applikasjoner...',
        it: 'Caricamento delle applicazioni...',
        se: 'Laddar appar...',
    },
    noApps: {
        us: 'No apps available for your account',
        cz: 'Pro váš účet nejsou dostupné žádné aplikace',
        pl: 'Brak aplikacji dostępnych dla Twojego konta',
        hr: 'Za vaš račun nije dostupna nijedna aplikacija',
        si: 'Za vaš račun ni dostopna nobena aplikacija',
        de: 'Für Ihr Konto sind keine Apps verfügbar',
        sk: 'Pre váš účet nie sú dostupné žiadne aplikácie',
        es: 'No hay aplicaciones disponibles para su cuenta',
        ru: 'Для вашего пользователя нет доступных приложений',
        rs: 'Nema dostupnih aplikacija za vaš nalog',
        ua: 'Для вашого користувача немає доступних програм',
        no: 'Din konto har ingen tilgjengelige applikasjoner',
        it: 'Nessuna app disponibile per il tuo account',
        se: 'Ditt konto har inga tillgängliga appar',
    },
    splitterDescription: {
        us: 'Split and transcribe even very long files in Beey.',
        cz: 'Rozdělte a zpracujte v Beey i velmi dlouhé nahrávky.',
        pl: 'Dziel i transkrybuj w Beey bardzo długie nagrania.',
        hr: 'Razdvojite i transkribirajte vrlo duge datoteke u Beeyju.',
        si: 'Razdelite in prepišite celo zelo dolge datoteke v Beeyju.',
        de: 'Aufteilung und Transkription von sehr langen Dateien.',
        sk: 'Rozdeľte a spracujte v Beey aj veľmi dlhé nahrávky.',
        es: 'Divida y transcriba incluso archivos muy largos en Beey.',
        ru: 'Разделяйте и транскрибируйте даже очень длинные файлы в Beey.',
        rs: 'Podeli i transkribuj i veoma dugačke fajlove u Beey-u.',
        ua: 'Розділяйте та транскрибуйте навіть дуже довгі файли за допомогою Beey.',
        no: 'Del opp og transkriber også veldig lange innspillinger med Beey.',
        it: 'Dividere ed elaborare anche registrazioni molto lunghe in Beey.',
        se: 'Dela upp och bearbeta även väldigt långa filer i Beey.',
    },
    linkDescription: {
        us: 'Use Beey to transcribe video and audio from Youtube and other servers.',
        cz: 'Přepisujte v Beey videa a nahrávky z Youtube a dalších služeb.',
        pl: 'Transkrybuj w Beey videa i nagrania z Youtube oraz innych serwerów.',
        hr: 'Upotrebljavajte Beey za transkripciju video i audiozapisa s YouTubea i drugih poslužitelja.',
        si: 'Uporabite Beey za prepisovanje videa in zvoka iz Youtuba in drugih strežnikov.',
        de: 'Mit Beey können Sie Video- und Audiodateien von Youtube und anderen Apps transkribieren.',
        sk: 'Prepisujte v Beey videá a nahrávky z YouTube a iných služieb.',
        es: 'Use Beey para transcribir videos y audios de YouTube y otros servidores.',
        ru: 'Используйте Beey для транскрибирования видео и аудио с Youtube и других серверов.',
        rs: 'Koristi Beey za transkribovanje videa i audija sa Youtube-a i drugih servera.',
        ua: 'Використовуйте Beey для транскрибування відео та аудіо з Youtube та інших серверів.',
        no: 'Bruk Beey for å transkribere videoer og lydinnspillinger fra YouTube og andre nettsteder.',
        it: 'Trascrivere video e registrazioni da YouTube e altri servizi in Beey.',
        se: 'Transkribera videor och inspelningar från Youtube och andra tjänster i Beey.',
    },
    streamDescription: {
        us: 'Transcribe and caption internet live streams using Beey.',
        cz: 'Přepisujte a titulkujte v Beey živé vysílání z internetu.',
        pl: 'Transkrybuj w Beey streamy internetowe i twórz do nich napisy.',
        hr: 'Transkribirajte i titlujte internetske prijenose uživo koristeći se Beeyjem.',
        si: 'Prepišite in podnaslovite prenose v živo z Interneta s pomočjo Beeyja.',
        de: 'Transkription und Untertitelung von Internet-Livestreams.',
        sk: 'Prepisujte a titulkujte v Beey živé vysielanie z internetu.',
        es: 'Transcriba y subtitule transmisiones en vivo de Internet usando Beey.',
        ru: 'Транскрибируйте и делайте субтитры к прямым интернет-трансляциям с помощью Beey.',
        rs: 'Transkribuj internet prenos uživo koristeći Beey.',
        ua: 'Транскрибуйте та додавайте субтитри до інтернет-трансляцій за допомогою Beey.',
        no: 'Transkriber og lag undertekster for direktesending som strømmes over internett med Beey.',
        it: 'Trascrivere e sottotitolare i webcast in diretta in Beey.',
        se: 'Transkribera och skapa undertexter till livesändningar från internet i Beey.',
    },
    streamtvrDescription: {
        us: 'Transcribe and caption TV and broadcast live streams using Beey.',
        cz: 'Přepisujte a titulkujte v Beey živé vysílání z televize a rozhlasu.',
        pl: 'Transkrybuj programy telewizyjne i radiowe nadawane na żywo i i dodawaj do nich napisy.',
        hr: 'Transkribirajte i titlujte TV i ostale prijenose uživo koristeći se Beeyjem.',
        si: 'Prepišite in podnaslovite TV in oddajanje v živo s pomočjo Beeyja.',
        de: 'Transkription und Untertitelung von TV- und Rundfunk-Livestreams.',
        sk: 'Prepisujte a titulkujte v Beey živé vysielanie z televízie a rádia.',
        es: 'Transcriba y subtitule la televisión y transmita transmisiones en vivo usando Beey.',
        ru: 'Транскрибируйте и делайте субтитры к телевизионным и радиовещательным прямым трансляциям с помощью Beey.',
        rs: 'Transkribuj TV prenos uživo koristeći Beey.',
        ua: 'Транскрибуйте і додавайте субтитри до телевізійних і прямих трансляцій за допомогою Beey.',
        no: 'Transkriber og lag undertekster for direktestrømmet sendinger på TV og radio med Beey.',
        it: 'Trascrivere e sottotitolare trasmissioni in diretta da TV e radio in Beey.',
        se: 'Transkribera och skapa undertexter till livesändningar från tv och radio i Beey.',
    },
    filesDescription: {
        us: 'Upload several files to Beey at once.',
        cz: 'Nahrávejte do Beey více souborů najednou.',
        pl: 'Wczytaj do Beey wiele plików jednocześnie.',
        hr: 'Prenesite nekoliko datoteka odjednom na Beey.',
        si: 'Naložite več datotek na Beey naenkrat.',
        de: 'Hochladen von mehreren Dateien auf einmal in Beey.',
        sk: 'Nahrávajte do Beey viacero súborov naraz.',
        es: 'Cargue varios archivos en Beey a la vez.',
        ru: 'Загружать в Beey несколько файлов одновременно.',
        rs: 'Unesi nekoliko fajlova u Beey odjednom.',
        ua: 'Завантажуйте до Beey кілька файлів одночасно.',
        no: 'Last opp flere filer samtidig med Beey.',
        it: 'Carichi più file contemporaneamente su Beey.',
        se: 'Ladda upp flera filer samtidigt till Beey.',
    },
    dssDescription: {
        us: 'Upload DSS and DS2 (Digital Speech Standard) files from your dictaphone to Beey.',
        cz: 'Nahrávejte do Beey soubory DSS a DS2 (Digital Speech Standard) z diktafonů.',
        pl: 'Transkrybuj w Beey pliki z dyktafonu w formacie DSS i DS2 (Digital Speech Standard).',
        hr: 'Prenesite DSS i DS2 (Digital Speech Standard) datoteke s vašeg diktafona na Beey.',
        si: 'Prenesite datoteke DSS in DS2 (Digital Speech Standard) iz svojega diktafona v Beey.',
        de: 'Laden Sie DSS- und DS2-Dateien (Digital Speech Standard) von Ihrem Diktiergerät in Beey hoch.',
        sk: 'Nahrávajte do Beey súbory DSS a DS2 (Digital Speech Standard) z diktafónových zariadení.',
        es: 'Cargue archivos DSS y DS2 (Digital Speech Standard) desde su dictáfono a Beey.',
        ru: 'Загружайте файлы DSS и DS2 (Digital Speech Standard) с вашего диктофона в Beey.',
        rs: 'Unesi DSS i DS2 (Digital Speech Standard) fajlove iz svog diktafona u Beey.',
        ua: 'Завантажуйте файли DSS і DS2 (Digital Speech Standard) з вашого диктофона на Beey.',
        no: 'Last opp DSS og DS2 (Digital Speech Standard) filer fra diktafoner til Beey.',
        it: 'Caricare file DSS e DS2 (Digital Speech Standard) da dispositivi di dettatura a Beey.',
        se: 'Ladda upp DSS- och DS2-filer (Digital Speech Standard) från diktafon till Beey.',
    },
    liveDescription: {
        us: 'Transcribe speech and publish live subtitles.',
        cz: 'Přepisujte mluvenou řeč a publikujte živé titulky.',
        pl: 'Transkrybuj mowę i publikuj napisy na żywo.',
        hr: 'Transkribirajte govor i objavite titlove uživo.',
        si: 'Prepišite govor in objavite podnapise v živo.',
        de: 'Sprache transkribieren und Live-Untertitel veröffentlichen.',
        sk: 'Prepisujte hovorenú reč a publikujte živé titulky.',
        es: 'Transcribir discurso y publicar subtítulos en vivo.',
        ru: 'Транскрибируйте речь и публикуйте субтитры.',
        rs: 'Transkribujte govor i objavite titlove uživo.',
        ua: 'Транскрибуйте мову та публікуйте живі субтитри.',
        no: 'Transkriber tale og publiser direkte undertekster.',
        it: 'Trascrivere il parlato e pubblicare sottotitoli in tempo reale.',
        se: 'Transkribera talat språk och publicera liveundertexter.',
    },
    translateDescription: {
        us: 'Automatically translates your transcript for editing and subtitles in other languages.',
        cz: 'Automatický překlad vašeho přepisu pro editaci a titulkování v dalších jazycích.',
        pl: 'Automatycznie tłumacz swoje transkrypcje, edytuj i twórz napisy w językach obcych.',
        hr: 'Automatski prijevod transkripata za uređivanje i titlova na drugim jezicima.',
        si: 'Avtomatski prevede prepis za urejanje in podnapise v drugih jezikih.',
        de: 'Übersetzt automatisch Ihr Transkript für Bearbeitung und Untertitel in anderen Sprachen.',
        sk: 'Automatický preklad vášho prepisu pre úpravy a titulkovanie v ďalších jazykoch.',
        es: 'Traduce automáticamente su transcripción para editarla y subtitularla en otros idiomas.',
        ru: 'Автоматический перевод вашей расшифровки для редактирования и субтитров на других языках.',
        rs: 'Automatski prevodi vaš transkript i titlove u druge jezike.',
        ua: 'Автоматичний переклад транскрипту для редагування та субтитрів іншими мовами.',
        no: 'En automatisk oversettelse av transkripsjonen din for redigering og undertekster i andre språk.',
        it: 'Traduzione automatica della trascrizione per la modifica e la sottotitolazione in altre lingue.',
        se: 'Översätter automatiskt transkriptionen för att kunna editera den och skapa undertexter i andra språk.',
    },
    voiceDescription: {
        us: 'Transcribe and caption live speech in Beey.',
        cz: 'Přepisujte a titulkujte v Beey mluvenou řeč.',
        pl: 'Transkrybuj i twórz napisy w Beey.',
        hr: 'Transkribirajte i titlujte govor u realnom vremenu koristeći se Beeyjem.',
        si: 'Prepišite in podnaslovite govor v živo v Beeyju.',
        de: 'Transkribieren und untertiteln Sie Audio-Aufzeichnungen in Echtzeit.',
        sk: 'Prepisujte a titulkujte reč v Beey.',
        es: 'Transcriba y subtitule el discurso en vivo en Beey.',
        ru: 'Расшифровка и субтитры живой речи в Beey.',
        rs: 'Transkribuj govor uživo u Beey-u.',
        ua: 'Транскрибуйте та субтитруйте живу мову в Beey.',
        no: 'Transkriber og lag undertekster for direktesendt tale med Beey.',
        it: 'Trascrizione e sottotitolazione del parlato in Beey.',
        se: 'Transkribera tal och skapa undertexter till det.',
    },
    captionsRemoved: {
        us: 'Subtitle review successfully reset',
        cz: 'Revize titulků byla úspěšně resetována',
        pl: 'Rewizja napisów została pomyślnie zresetowana',
        hr: 'Pregled titlova uspješno je resetiran',
        si: 'Pregled podnapisov je uspešno ponastavljen',
        de: 'Untertitel-Revision erfolgreich zurückgesetzt',
        sk: 'Revízia titulkov bola úspešne resetovaná',
        es: 'Revisión de subtítulos restablecida correctamente',
        ru: 'Проверка субтитров успешно сброшена',
        rs: 'Pregled titlova uspešno je poništen',
        ua: 'Перевірка субтитрів успішно скинута',
        no: 'Reviderte undertekster ble tilbakestilt',
        it: 'La revisione dei sottotitoli è stata ripristinata con successo',
        se: 'Korrektur av undertexter återställdes',
    },
    removeCaptionsFailed: {
        us: 'Failed to reset subtitle review',
        cz: 'Resetování revize titulků selhalo',
        pl: 'Nie udało się zresetować rewizji napisów',
        hr: 'Resetiranje pregleda titlova nije uspjelo',
        si: 'Pregleda podnapisov ni bilo mogoče ponastaviti',
        de: 'Zurücksetzen der Untertitel-Revision fehlgeschlagen',
        sk: 'Resetovanie revízie titulkov zlyhalo',
        es: 'No se pudo restablecer la revisión de subtítulos',
        ru: 'Не удалось сбросить проверку субтитров',
        rs: 'Poništavanje pregleda titlova nije uspelo',
        ua: 'Не вдалося скинути перевірку субтитрів',
        no: 'Tilbakestilling av reviderte undertekster mislyktes',
        it: 'Reimpostazione della revisione dei sottotitoli fallita',
        se: 'Det gick inte att återställa korrektur av undertexter',
    },
    myTranscriptions: {
        us: 'My transcriptions',
        cz: 'Moje přepisy',
        pl: 'Moje transkrypcje',
        hr: 'Moje transkripcije',
        si: 'Moji prepisi',
        de: 'Meine Transkriptionen',
        sk: 'Moje prepisy',
        es: 'Mis transcripciones',
        ru: 'Мои транскрипции',
        rs: 'Moje transkripcije',
        ua: 'Мої транскрипції',
        no: 'Mine transkripsjoner',
        it: 'Le mie trascrizioni',
        se: 'Mina transkriptioner',
    },
    myTeam: {
        us: 'My team',
        cz: 'Můj tým',
        pl: 'Mój team',
        hr: 'Moj tim',
        si: 'Moj tim',
        de: 'Mein Team',
        sk: 'Môj tím',
        es: 'Mi equipo',
        ru: 'Моя команда',
        rs: 'Moj tim',
        ua: 'Моя команда',
        no: 'Mitt team',
        it: 'Il mio team',
        se: 'Mitt team',
    },
    myAccount: {
        us: 'My account',
        cz: 'Můj účet',
        pl: 'Moje konto',
        hr: 'Moj račun',
        si: 'Moj račun',
        de: 'Mein Konto',
        sk: 'Môj účet',
        es: 'Mi cuenta',
        ru: 'Мой профиль',
        rs: 'Moj nalog',
        ua: 'Мій профіль',
        no: 'Min konto',
        it: 'Il mio account',
        se: 'Mitt konto',
    },
    manageAccount: {
        us: 'Manage account',
        cz: 'Správa účtu',
        pl: 'Zarządzanie kontem',
        hr: 'Upravljajte računom',
        si: 'Upravljajte račun',
        de: 'Konto verwalten',
        sk: 'Správa účtu',
        es: 'Gestionar cuenta',
        ru: 'Управление счетом',
        rs: 'Upravljajte nalogom',
        ua: 'Керування обліковим записом',
        no: 'Kontohåndtering',
        it: 'Gestione degli account',
        se: 'Kontohantering',
    },
    monthlyOverview: {
        us: 'Monthly overview',
        cz: 'Měsíční přehled',
        pl: 'Przegląd miesięczny',
        hr: 'Mjesečni pregled',
        si: 'Mesečni pregled',
        de: 'Monatliche Übersicht',
        sk: 'Mesačný prehľad',
        es: 'Resumen mensual',
        ru: 'Месячный обзор',
        rs: 'Mesečni pregled',
        ua: 'Місячний огляд',
        no: 'Månedsoversikt',
        it: 'Panoramica mensile',
        se: 'Månadsöversikt',
    },
    projectOverview: {
        us: 'Projects overview for ',
        cz: 'Přehled projektů za ',
        pl: 'Przegląd projektów za ',
        hr: 'Pregled projekata za ',
        si: 'Pregled projektov za ',
        de: 'Projektübersicht für ',
        sk: 'Prehľad projektov pre ',
        es: 'Resumen de proyectos para ',
        ru: 'Обзор проектов для ',
        rs: 'Pregled projekata za ',
        ua: 'Огляд проектів для ',
        no: 'Oversikt over prosjekter i ',
        it: 'Panoramica del progetto per ',
        se: 'Översikt av projekt i ',
    },
    myTranscriptionTotal: {
        us: 'My transcriptions in total:',
        cz: 'Moje přepisy celkem:',
        pl: 'Moje wszystkie transkrypcje:',
        hr: 'Moje ukupne transkripcije:',
        si: 'Moji prepisi skupaj:',
        de: 'Meine Transkriptionen insgesamt:',
        sk: 'Celkovo moje prepisy:',
        es: 'Mis transcripciones en total:',
        ru: 'Всего моих транскрипций:',
        rs: 'Moje ukupne transkripcije:',
        ua: 'Усього моїх транскрипцій:',
        no: 'Alle mine transkripsjoner:',
        it: 'Il totale delle mie trascrizioni:',
        se: 'Mina samtliga transkriptioner:',
    },
    myTeamTotal: {
        us: "My team's transcriptions in total:",
        cz: 'Přepisy mého týmu celkem:',
        pl: 'Wszystkie transkrypcje mojego teamu:',
        hr: 'Ukupne transkripcije mog tima:',
        si: 'Prepisi moje ekipe skupaj:',
        de: 'Transkriptionen meines Teams insgesamt:',
        sk: 'Celkové prepisy môjho tímu:',
        es: 'Las transcripciones de mi equipo en total:',
        ru: 'Всего транскрипций моей команды:',
        rs: 'Ukupne transkripcije mog tima:',
        ua: 'Усього транскрипцій моєї команди:',
        no: 'Alle transkripsjonene av teamet mitt:',
        it: 'Le trascrizioni totali del mio team:',
        se: 'Mitt teams samtliga transkriptioner:',
    },
    teamOwner: {
        us: 'Team owner',
        cz: 'Správce týmu',
        pl: 'Administrator teamu',
        hr: 'Vlasnik tima',
        si: 'Lastnik tima',
        de: 'Teamverwalter',
        sk: 'Správca tímu',
        es: 'Administrador del equipo',
        ru: 'Администратор команды',
        rs: 'Vlasnik tima',
        ua: 'Адміністратор команди',
        no: 'Teameier',
        it: 'Responsabile del team',
        se: 'Teamägare',
    },
    teamsOwners: {
        us: 'Teams owners',
        cz: 'Správci týmů',
        pl: 'Administratorzy teamu',
        hr: 'Vlasnici tima',
        si: 'Lastniki tima',
        de: 'Teamsverwalter',
        sk: 'Správcovia tímov',
        es: 'Administradores de equipos',
        ru: 'Администраторы команд',
        rs: 'Vlasnici tima',
        ua: 'Адміністратори команд',
        no: 'Teameiere',
        it: 'Amministratori del team',
        se: 'Teamägare',
    },
    team: {
        us: 'Team',
        cz: 'Tým',
        pl: 'Team',
        hr: 'Tim',
        si: 'Tim',
        de: 'Team',
        sk: 'Tím',
        es: 'Equipo',
        ru: 'Команда',
        rs: 'Tim',
        ua: 'Команда',
        no: 'Team',
        it: 'Team',
        se: 'Team',
    },
    teamMembers: {
        us: 'Team members',
        cz: 'Členové týmu',
        pl: 'Członkowie teamu',
        hr: 'Članovi tima',
        si: 'Člani tima',
        de: 'Teammitglieder',
        sk: 'Členovia tímu',
        es: 'Miembros del equipo',
        ru: 'Участники команды',
        rs: 'Članovi tima',
        ua: 'Учасники команди',
        no: 'Team medlemmer',
        it: 'Membri del team',
        se: 'Teammedlemmar',
    },
    teamMember: {
        us: 'Team member',
        cz: 'Člen týmu',
        pl: 'Członek teamu',
        hr: 'Članovi tima',
        si: 'Člani tima',
        de: 'Teammitglied',
        sk: 'Člen tímu',
        es: 'Miembro del equipo',
        ru: 'Участники команды',
        rs: 'Član tima',
        ua: 'Учасники команди',
        no: 'Team medlem',
        it: 'Membro del team',
        se: 'Teammedlem',
    },
    automaticProjectDeletion: {
        us: 'Automatically delete inactive projects',
        cz: 'Automatické mazání neaktivních projektů',
        pl: 'Automatyczne usuwanie nieaktywnych projektów',
        hr: 'Automatski izbriši neaktivne projekte',
        si: 'Samodejno izbriši neaktivne projekte',
        de: 'Automatisches Löschen inaktiver Projekte',
        sk: 'Automatické mazanie neaktívnych projektov',
        es: 'Eliminar automáticamente los projectos inactivos',
        ru: 'Автоматическое удаление неактивных проектов',
        rs: 'Automatski brišite neaktivne projekte',
        ua: 'Автоматично видаляти неактивні проекти',
        no: 'Slette automatisk inaktive prosjekter',
        it: 'Eliminazione automatica dei progetti inattivi',
        se: 'Radera inaktiva projekt automatiskt',
    },
    teamSettings: {
        us: 'Team preferences',
        cz: 'Předvolby týmu',
        pl: 'Preferencje teamu',
        hr: 'Postavke tima',
        si: 'Nastavitve ekipe',
        de: 'Team-Einstellungen',
        sk: 'Predvoľby tímu',
        es: 'Preferencias del equipo',
        ru: 'Параметры команды',
        rs: 'Postavke tima',
        ua: 'Налаштування команди',
        no: 'Teamets preferanser',
        it: 'Preferenze del team',
        se: 'Teamets preferenser',
    },
    queueProjectsDeletion: {
        us: 'Projects that have not been edited or shared for a certain period of time will be automatically deleted.',
        cz: 'Projekty needitované nebo nesdílené určitou dobu budou automaticky smazány.',
        pl: 'Projekty, które nie były edytowane lub udostępniane przez określony czas, zostaną automatycznie usunięte.',
        hr: 'Projekti koji nisu uređivani ili dijeljeni određeno vrijeme automatski će se izbrisati.',
        si: 'Projekti, ki v določenem obdobju niso bili urejeni ali v skupni rabi, bodo samodejno izbrisani.',
        de: 'Projekte, die eine bestimmte Zeit lang nicht bearbeitet oder geteilt wurden, werden automatisch gelöscht.',
        sk: 'Projekty, ktoré neboli upravované alebo zdieľané po určitú dobu, sa automaticky vymažú.',
        es: 'Los proyectos que no se hayan editado o compartido durante un determinado periodo de tiempo se eliminarán automáticamente.',
        ru: 'Проекты, которые в течение определенного времени не редактировались и не предоставлялись в общий доступ, будут автоматически удалены.',
        rs: 'Projekti koji nisu uređivani ili deljeni u određenom periodu vremena će biti automatski obrisani.',
        ua: 'Проекти, які не були відредаговані або опубліковані протягом певного періоду часу, будуть автоматично видалені.',
        no: 'Prosjekter som ikke har blitt redigert eller delt i en viss periode automatisk blir slettet.',
        it: 'I progetti non modificati o condivisi per un certo periodo di tempo verranno automaticamente eliminati.',
        se: 'Projekt som inte har editerats eller delats under en viss tidsperiod kommer att raderas automatiskt.',
    },
    permanentlydeleteProjects: {
        us: 'The deletion is irreversible.',
        cz: 'Smazání je nevratné.',
        pl: 'Usunięcie jest nieodwracalne.',
        hr: 'Brisanje je nepovratno.',
        si: 'Izbris je nepovraten.',
        de: 'Die Löschung ist unwiderruflich.',
        sk: 'Zmazanie je nezvratné.',
        es: 'La eliminación es irreversible',
        ru: 'Удаление является необратимым.',
        rs: 'Brisanje je nepovratno.',
        ua: 'Видалення є незворотнім.',
        no: 'Sletteprosessen er irreversibel.',
        it: "L'eliminazione è irreversibile.",
        se: 'Det går inte att återkalla denna radering.',
    },
    deleteProjectsAfter: {
        us: 'Automatically delete projects after',
        cz: 'Automaticky smazat projekty po',
        pl: 'Automatycznie usuwaj projekty po',
        hr: 'Automatski izbriši projekte nakon',
        si: 'Samodejno izbriši projekte po',
        de: 'Projekte automatisch löschen nach',
        sk: 'Automaticky zmazať projekty po',
        es: 'Eliminar automáticamente despues los proyectos',
        ru: 'Автоматическое удаление проектов по истечении',
        rs: 'Automatski obriši projekte nakon dana',
        ua: 'Автоматично видаляти проекти через',
        no: 'Slette prosjekter automatisk etter',
        it: 'Elimina automaticamente i progetti dopo',
        se: 'Radera projekt automatiskt efter',
    },
    afterDays: {
        us: 'days',
        cz: 'dnech',
        pl: 'dniach',
        hr: 'dana',
        si: 'dneh',
        de: 'Tagen',
        sk: 'dňoch',
        es: 'días',
        ru: 'дней',
        rs: 'dani',
        ua: 'днів',
        no: 'dager',
        it: 'giorni',
        se: 'dagar',
    },
    projectID: {
        us: 'Project ID',
        cz: 'ID projektu',
        pl: 'ID projektu',
        hr: 'ID projekta',
        si: 'ID projekta',
        de: 'Projekt-ID',
        sk: 'ID projektu',
        es: 'ID del proyecto',
        ru: 'ID проекта',
        rs: 'ID projekta',
        ua: 'ID проекту',
        no: 'Prosjekt ID',
        it: 'ID progetto',
        se: 'Projekt-ID',
    },
    numberOfMinutes: {
        us: 'Number of minutes',
        cz: 'Počet minut',
        pl: 'Liczba minut',
        hr: 'Broj minuta',
        si: 'Število minut',
        de: 'Anzahl der Minuten',
        sk: 'Počet minút',
        es: 'Número de minutos',
        ru: 'Количество минут',
        rs: 'Broj minuta',
        ua: 'Кількість хвилин',
        no: 'Antall av minutter',
        it: 'Numero di minuti',
        se: 'Antal minuter',
    },
    creationDate: {
        us: 'Creation date',
        cz: 'Datum vytvoření',
        pl: 'Data utworzenia',
        hr: 'Datum izrade',
        si: 'Datum izdelave',
        de: 'Datum der Erstellung',
        sk: 'Dátum vytvorenia',
        es: 'Fecha de creación',
        ru: 'Дата создания',
        rs: 'Datum izrade',
        ua: 'дата створення',
        no: 'Opprettelsesdato',
        it: 'Data di creazione',
        se: 'Skapelsedatum',
    },
    addToTeam: {
        us: 'Add to a team',
        cz: 'Přidat do týmu',
        pl: 'Dodaj do teamu',
        hr: 'Dodajte u tim',
        si: 'Dodajte v tim',
        de: 'Mitglied hinzufügen',
        sk: 'Pridať do tímu',
        es: 'Agregar a un equipo',
        ru: 'Добавить в команду',
        rs: 'Dodajte u tim',
        ua: 'Додати до команди',
        no: 'Legge til i et team',
        it: 'Aggiungi al team',
        se: 'Lägg till i team',
    },
    chooseTeam: {
        us: 'Choose a team',
        cz: 'Vyberte tým',
        pl: 'Wybierz team',
        hr: 'Izaberite tim',
        si: 'Izberite tim',
        de: 'Team auswählen',
        sk: 'Vyberte tím',
        es: 'Elegir un equipo',
        ru: 'Выбрать команду',
        rs: 'Izaberite tim',
        ua: 'Вибрати команду',
        no: 'Velg et team',
        it: 'Seleziona il team',
        se: 'Välj team',
    },
    showLastProjects: {
        us: 'Showing 10 last projects (out of ',
        cz: 'Zobrazeno 10 posledních projektů (z celkového počtu ',
        pl: 'Pokazuję 10 ostatnich projektów (z całkiem ',
        hr: 'Prikazivanje zadnjih 10 projekata (od ',
        si: 'Prikazovanje zadnjih 10 projektov (od ',
        de: 'Die letzten 10 Projekte werden angezeigt (von ',
        sk: 'Zobrazenie 10 posledných projektov (z celkového počtu ',
        es: 'Mostrando 10 últimos proyectos (de ',
        ru: 'Отображено 10 последних проектов (из ',
        rs: 'Prikazivane poslednjih 10 projekata (od ',
        ua: 'Відображено 10 останніх проектів (із ',
        no: 'Viser de 10 siste prosjektene (av ',
        it: 'Mostra gli ultimi 10 progetti (sul totale ',
        se: '10 senaste projekt visas (utav ',
    },
    showing: {
        us: 'Showing ',
        cz: 'Zobrazeno ',
        pl: 'Pokazuję ',
        hr: 'Prikazivanje ',
        si: 'Prikazovanje ',
        de: 'Es werden ',
        sk: 'Zobrazenie ',
        es: 'Mostrando ',
        ru: 'Отображено ',
        rs: 'Prikazivanje ',
        ua: 'Відображено ',
        no: 'Viser ',
        it: 'Visualizzazione ',
        se: 'Visas ',
    },
    outOf: {
        us: 'out of ',
        cz: 'z celkového počtu ',
        pl: 'z całkiem ',
        hr: 'od ',
        si: 'od ',
        de: 'von ',
        sk: 'z celkového počtu ',
        es: 'de ',
        ru: 'от общей суммы',
        rs: 'od ',
        ua: 'від загальної кількості',
        no: 'av ',
        it: 'del totale ',
        se: 'utav ',
    },
    projectsOutOf: {
        us: 'projects',
        cz: 'projektů',
        pl: 'projektów',
        hr: 'projekata',
        si: 'projektov',
        de: 'Projekte angezeigt',
        sk: 'projektov',
        es: 'proyectos',
        ru: 'проекты',
        rs: 'projekti',
        ua: 'проекти',
        no: 'prosjekter',
        it: 'progetti',
        se: 'projekt',
    },
    projectOutOf: {
        us: 'project',
        cz: 'projektu',
        pl: 'projektu',
        hr: 'projekt',
        si: 'projekt',
        de: 'Projekt angezeigt',
        sk: 'projektu',
        es: 'proyecto',
        ru: 'проект',
        rs: 'projekat',
        ua: 'проект',
        no: 'prosjekt',
        it: 'progetto',
        se: 'projekt',
    },
    downloadCSV: {
        us: 'Download all projects in .csv',
        cz: 'Stáhnout všechny projekty v .csv',
        pl: 'Pobierz wszystkie projekty w .csv',
        hr: 'Preuzmite sve projekte u .csv',
        si: 'Prenesite vse projekte v .csv',
        de: 'Alle Projekte im .csv-Format herunterladen',
        sk: 'Stiahnutie všetkých projektov vo formáte .csv',
        es: 'Descarga todos los proyectos en .csv',
        ru: 'Скачать все проекты в .csv',
        rs: 'Preuzmite sve projekte u .csv',
        ua: 'Завантажити всі проекти в .csv',
        no: 'Laste ned alle .csv prosjektene',
        it: 'Scaricare tutti i progetti in .csv',
        se: 'Ladda ned alla projekt i .csv',
    },
    forPeriod: {
        us: 'For a period',
        cz: 'Za období',
        pl: 'Za okres',
        hr: 'Za razdoblje',
        si: 'Za obdobje',
        de: 'Für Zeitraum',
        sk: 'Za obdobie',
        es: 'Por un periodo',
        ru: 'За период',
        rs: 'Za razdoblje',
        ua: 'За період',
        no: 'I en periode',
        it: 'Per il periodo',
        se: 'Under en period',
    },
    noProjects: {
        us: 'No transcribed projects',
        cz: 'Žádné přepsané projekty',
        pl: 'Brak stranskrybowanych projektów',
        hr: 'Netranskribirani projekti',
        si: 'Neprepisani projekti',
        de: 'Keine transkribierten Projekte',
        sk: 'Žiadne prepísané projekty',
        es: 'No hay proyectos transcritos',
        ru: 'Отсутствуют транскрибированные проекты',
        rs: 'Netranskribovani projekti',
        ua: 'Відсутні транскрибовані проекти',
        no: 'Ingen transkriberte prosjekter',
        it: 'Nessun progetto trascritto',
        se: 'Inga transkriberade projekt',
    },
    noDeletion: {
        us: 'You do not have permission to delete items. Contact your team owner.',
        cz: 'Nemáte oprávnění mazat položky. Kontaktujte správce týmu.',
        pl: 'Nie masz uprawnień do usuwania elementów. Skontaktuj się z właścicielem teamu.',
        hr: 'Nemate dopuštenje za brisanje stavki. Kontaktirajte s vlasnikom tima.',
        si: 'Nimate dovoljenja za brisanje elementov. Obrnite se na lastnika tima.',
        de: 'Sie haben keine Berechtigung Objekte zu Löschen. Kontaktieren Sie Ihren Teamverwater.',
        sk: 'Nemáte oprávnenie odstraňovať položky. Kontaktujte správcu tímu.',
        es: 'No tiene permiso para eliminar elementos. Póngase en contacto con el administrador de su equipo.',
        ru: 'У вас нет доступа к удалению файлов. Свяжитесь с администратором вашей команды.',
        rs: 'Nemate dozvolu za brisanje stavki. Kontaktirajte vlasnika tima.',
        ua: "Ви не маєте доступу до видалення файлів. Зв'яжіться з адміністратором команди.",
        no: 'Du har ikke rettighet til å slette elementer. Ta kontakt med teameieren din.',
        it: "Non si ha il permesso di eliminare gli elementi. Contattare l'amministratore del team.",
        se: 'Du har inte rättigheter till att ta bort objekt. Kontakta din teamägare.',
    },
    teams: {
        us: 'Teams',
        cz: 'Týmy',
        pl: 'Teamy',
        hr: 'Timovi',
        si: 'Timi',
        de: 'Teams',
        sk: 'Tímy',
        es: 'Equipos',
        ru: 'Команды',
        rs: 'Timovi',
        ua: 'Команди',
        no: 'Team',
        it: 'Team',
        se: 'Team',
    },
    contactTeamOwner: {
        us: 'Contact your team owner.',
        cz: 'Kontaktujte správce svého týmu.',
        pl: 'Skontaktuj się z administratorem twojego teamu.',
        hr: 'Kontaktirajte s vlasnikom tima.',
        si: 'Obrnite se na lastnika tima.',
        de: 'Kontaktieren Sie Ihren Teamverwalter.',
        sk: 'Kontaktujte správcu svojho tímu.',
        es: 'Póngase en contacto con el administrador de su equipo.',
        ru: 'Свяжитесь с вашим администратором команды.',
        rs: 'Kontaktirajte vlasnika tima.',
        ua: "Зв'яжіться з адміністратором команди.",
        no: 'Ta kontakt med teameieren din.',
        it: "Contattare l'amministratore del team.",
        se: 'Kontakta din teamägare.',
    },
    teamProfile: {
        us: 'Team profile',
        cz: 'Profil týmu',
        pl: 'Profil teamu',
        hr: 'Profil tima',
        si: 'Profil tima',
        de: 'Team-Profil',
        sk: 'Profil tímu',
        es: 'Perfil del equipo',
        ru: 'Профиль команды',
        rs: 'Profil tima',
        ua: 'Профіль команди',
        no: 'Teamprofil',
        it: 'Profilo del team',
        se: 'Teamprofil',
    },
    noSuchTeam: {
        us: 'No such team',
        cz: 'Tým nenalezen',
        pl: 'Nie znaleziono teamu',
        hr: 'Taj tim ne postoji',
        si: 'Takšnega tima ni',
        de: 'Team nicht gefunden',
        sk: 'Tím nebol nájdený',
        es: 'Equipo no encontrado',
        ru: 'Команда не была найдена',
        rs: 'Taj tim ne postoji',
        ua: 'Команда не була знайдена',
        no: 'Teamet ikke funnet',
        it: 'Team non trovato',
        se: 'Team hittades ej',
    },
    teamDoesNotExist: {
        us: 'Team with this ID does not exist.',
        cz: 'Tým s tímto ID neexistuje.',
        pl: 'Team o tym identyfikatorze nie istnieje.',
        hr: 'Tim s ovim ID-om ne postoji.',
        si: 'Tim s tem ID-jem ne obstaja.',
        de: 'Team mit dieser ID existiert nicht.',
        sk: 'Tím s týmto ID neexistuje.',
        es: 'El equipo con este ID no existe.',
        ru: 'Команда с таким ID  не существует.',
        rs: 'Tim sa ovim ID-jem ne postoji.',
        ua: 'Команда з таким ID не існує.',
        no: 'Teamet med denne ID finnes ikke.',
        it: 'Non esiste un team con questo ID.',
        se: 'Team med detta ID finns ej.',
    },
    hisHerTeam: {
        us: 'and his/her team',
        cz: 'a jeho/její tým',
        pl: 'i jego/jej team',
        hr: 'i njegov/njezin tim',
        si: 'in njegova/njena ekipa',
        de: 'und sein/ihr Team',
        sk: 'a jeho/jej tím',
        es: 'y su equipo',
        ru: 'и его/ее команда',
        rs: 'I njegov/njen tim',
        ua: 'та його/її команда',
        no: 'og hans/hennes team',
        it: 'e il suo team',
        se: 'och hans/hennes team',
    },
    cannotDeleteOwner: {
        us: 'It is not possible to delete a team owner user. You have to delete the whole team in the team settings.',
        cz: 'Nelze smazat uživatele, který je správcem týmu. Musíte smazat celý tým v týmovém nastavení.',
        pl: 'Nie można usunąć użytkownika, który jest administratorem teamu. Musisz usunąć cały team w ustawieniach teamu.',
        hr: 'Nije moguće izbrisati korisnika koji je vlasnik tima. Morate izbrisati cijeli tim u postavkama tima.',
        si: 'Ni mogoče izbrisati uporabnika, ki je lastnik ekipe. V nastavitvah ekipe morate izbrisati celotno ekipo.',
        de: 'Es ist nicht möglich, einen Teameigentümer zu löschen. Sie müssen das gesamte Team in den Teameinstellungen löschen.',
        sk: 'Používateľa, ktorý je správcom tímu, nemôžete vymazať. Musíte odstrániť celý tím v nastaveniach tímu.',
        es: 'No es posible eliminar a un usuario que es administrador del equipo. Debe eliminar todo el equipo en la configuración del equipo.',
        ru: 'Невозможно удалить пользователя-владельца команды. Необходимо удалить всю команду в настройках команды.',
        rs: 'Nije moguće obrisati vlasnika tima. Morate obrisati ceo tim u postavkama tima.',
        ua: 'Видалити користувача-власника команди неможливо. Ви повинні видалити всю команду в налаштуваннях команди.',
        no: 'Du kan ikke slette brukeren som er teameier. Du må slette hele teamet i teaminnstillinger.',
        it: "Non è possibile eliminare un utente che è amministratore di un team. È necessario eliminare l'intero team nelle impostazioni del team.",
        se: 'Det är inte möjligt att radera användaren som är teamägaren. Du får radera hela teamet i teamets inställningar.',
    },
    cannotDeleteSelf: {
        us: 'You cannot delete yourself. Ask another administrator.',
        cz: 'Nemůžete smazat sám sebe. Požádajte jiného administrátora.',
        pl: 'Nie możesz wymazać siebie. O wymazanie poproś innego administratora.',
        hr: 'Ne možete izbrisati sebe. Pitajte drugog administratora.',
        si: 'Ne morete izbrisati sebe. Vprašajte drugega administratorja.',
        de: 'Sie können sich selbst nicht löschen. Fragen Sie einen Administrator.',
        sk: 'Nemôžete vymazať sám seba. Požiadajte iného správcu.',
        es: 'No puedes eliminarte a ti mismo. Debes solicitar a otro administrador.',
        ru: 'Удалить самого себя невозможно. Обратитесь к другому администратору.',
        rs: 'Ne možete obrisati sebe. Pitajte drugog administratora.',
        ua: 'Ви не можете видалити себе. Зверніться до іншого адміністратора.',
        no: 'Du kan ikke slette deg selv. Be en annen administrator.',
        it: 'Non è possibile eliminare sé stessi. Chiedete a un altro amministratore.',
        se: 'Du kan inte radera dig själv. Fråga en annan administratör.',
    },
    deleteTeam: {
        us: 'Delete team',
        cz: 'Smazat tým',
        pl: 'Usuń team',
        hr: 'Izbriši tim',
        si: 'Izbriši ekipo',
        de: 'Team löschen',
        sk: 'Zmazať tím',
        es: 'Eliminar equipo',
        ru: 'Удалить команду',
        rs: 'Obriši tim',
        ua: 'Видалити команду',
        no: 'Slette teamet',
        it: 'Eliminazione di un team',
        se: 'Radera teamet',
    },
    deleteTeamPermanently: {
        us: 'Permanently delete team',
        cz: 'Permanentně vymazat tým',
        pl: 'Trwale usuń team',
        hr: 'Trajno brisanje tima',
        si: 'Trajno izbriši tim',
        de: 'Team permanent löschen',
        sk: 'Trvalo vymazať tím',
        es: 'Eliminar permanentemente el equipo',
        ru: 'Удалить команду навсегда',
        rs: 'Trajno brisanje tima',
        ua: 'Видалити команду назавжди',
        no: 'Slette teamet permanent',
        it: 'Eliminare definitivamente un team',
        se: 'Radera teamet för alltid',
    },
    processIrreversibleTeamPartOne: {
        us: 'This process is permanent and irreversible!',
        cz: 'Tento proces je konečný a nevratný!',
        pl: 'Ten proces jest ostateczny i nieodwracalny!',
        hr: 'Ovaj proces je trajan i nepovratan!',
        si: 'Ta proces je trajen in nepovraten!',
        de: 'Dieser Prozess ist dauerhaft und unumkehrbar!',
        sk: 'Tento proces je konečný a nezvratný!',
        es: '¡Este proceso es permanente e irreversible!',
        ru: 'Этот процесс постоянный и необратимый!',
        rs: 'Ovaj proces je trajan i nepovratan!',
        ua: 'Цей процес постійний та необоротний!',
        no: 'Denne prosessen er permanent og irreversibel!',
        it: 'Questo processo è definitivo e irreversibile!',
        se: 'Denna process är permanent och oåterkallelig!',
    },
    processIrreversibleTeamPartTwo: {
        us: 'The whole team - its owner, all its members and all their projects - will be deleted.',
        cz: 'Bude smazán celý tým, jeho správce, všichni jeho členové i všechny jejich projekty.',
        pl: 'Cały team, jego administrator, wszyscy jego członkowie i wszystkie projekty zostaną usunięte.',
        hr: 'Cijeli tim - njegov vlasnik, svi njegovi članovi i svi njihovi projekti - bit će obrisani.',
        si: 'Celoten tim - njen lastnik, vsi njeni člani in vsi njihovi projekti - bo izbrisan.',
        de: 'Das gesamte Team - sein Verwalter, alle Mitglieder und alle Projekte - wird gelöscht.',
        sk: 'Celý tím, jeho správca, všetci jeho členovia a všetky ich projekty budú vymazané.',
        es: 'Se eliminará todo el equipo - su administrador, todos sus miembros y todos sus proyectos.',
        ru: 'Вся команда, ее администратор, все ее участники и проекты будут удалены.',
        rs: 'Ceo tim - njegov vlasnik, svi njegovi članovi i svi njihovi projekti - biće obrisani.',
        ua: 'Вся команда, її адміністратор, усі її учасники та проекти будуть видалені.',
        no: 'Hele teamet - eieren, medlemmene, prosjektene - blir slettet.',
        it: "L'intero team, il suo amministratore, tutti i suoi membri e tutti i loro progetti saranno eliminati.",
        se: 'Hela teamet, inkl. ägaren, alla medlemmar och alla deras projekt, kommer att raderas.',
    },
    deletedMembers: {
        us: 'Deleted members',
        cz: 'Smazaní členové',
        pl: 'Usunięci członkowie',
        hr: 'Izbrisani članovi',
        si: 'Izbrisani člani',
        de: 'Gelöschte Mitglieder',
        sk: 'Vymazaní členovia',
        es: 'Miembros eliminados',
        ru: 'Удаленные участники',
        rs: 'Izbrisani članovi',
        ua: 'Видалені учасники',
        no: 'Slettede medlemmer',
        it: 'Membri cancellati',
        se: 'Raderade medlemmar',
    },
    teamTranscribedOverview: {
        us: "Team's transcribed minutes overview",
        cz: 'Přehled přepsaných minut týmu',
        pl: 'Przegląd stranskrybowanych przez team minut',
        hr: 'Pregled transkribiranih minuta tima',
        si: 'Pregled prepisanih minut tima',
        de: 'Übersicht über transkribierten Minute des Teams',
        sk: 'Přehled přepsaných minut týmu',
        es: 'Descripción general de los minutos transcritos del equipo',
        ru: 'Обзор транскрибированных минут команды',
        rs: 'Pregled transkribovanih minuta tima',
        ua: 'Огляд транскрибованих хвилин команди',
        no: 'Oversikt over transkriberte minutter av teamet',
        it: 'Panoramica delle trascrizioni del team',
        se: 'Översikt av teamets transkriberade minuter',
    },
    totalPerPeriod: {
        us: 'Total for a period:',
        cz: 'Celkem za období:',
        pl: 'Całkiem za okres:',
        hr: 'Ukupno za razdoblje:',
        si: 'Skupaj za obdobje:',
        de: 'Gesamtbetrag für eine Periode:',
        sk: 'Celkovo za obdobie:',
        es: 'Total del período:',
        ru: 'Всего за период:',
        rs: 'Ukupno za razdoblje:',
        ua: 'Усього за період:',
        no: 'Til sammen i en periode:',
        it: 'Totale del periodo:',
        se: 'Sammanlagt under en period:',
    },
    speakerId: {
        us: 'Speaker identification',
        cz: 'Identifikace mluvčích',
        pl: 'Identyfikacja mówców',
        hr: 'Identifikacija govornika',
        si: 'Identifikacija govorca',
        de: 'Sprecheridentifikation',
        sk: 'Identifikácia rečníkov',
        es: 'Identificación del hablante',
        ru: 'Идентификация спикера',
        rs: 'Identifikacija govornika',
        ua: 'Ідентифікація диктора',
        no: 'Taleridentifikasjon',
        it: 'Identificazione dello speaker',
        se: 'Talaridentifikation',
    },
    speakerIdModel: {
        us: 'Speaker identification model',
        cz: 'Model identifikace mluvčích',
        pl: 'Model identyfikacji mówcy',
        hr: 'Model identifikacije govornika',
        si: 'Model identifikacije govorca',
        de: 'Modell der Sprecheridentifikation',
        sk: 'Model identifikácie rečníka',
        es: 'Modelo de identificación del hablante',
        ru: 'Модель идентификации диктора',
        rs: 'Model identifikacije govornika',
        ua: 'Модель ідентифікації диктора',
        no: 'Taleridentifikasjon modell',
        it: 'Modello di identificazione dello speaker',
        se: 'Modell för talaridentifikation',
    },
    clearSorting: {
        us: 'Clear sorting',
        cz: 'Zrušit řazení',
        pl: 'Anuluj sortowanie',
        hr: 'Očisti sortiranje',
        si: 'Očisti razvrščanje',
        de: 'Sortierung löschen',
        sk: 'Zrušiť radenie',
        es: 'Borrar clasificación',
        ru: 'Очистить сортировку',
        rs: 'Očisti sortiranje',
        ua: 'Очистити сортування',
        no: 'Tømme sortering',
        it: 'Elimina ordinamento',
        se: 'Rensa sortering',
    },
    downloadInCSV: {
        us: 'Download in .csv',
        cz: 'Stáhnout v .csv',
        pl: 'Pobierz w .csv',
        hr: 'Preuzmite u .csv',
        si: 'Prenesite v .csv',
        de: 'Herunterladen im csv-Format',
        sk: 'Stiahnutie vo formáte .csv',
        es: 'Descarga en .csv',
        ru: 'Скачать в .csv',
        rs: 'Preuzmite u .csv',
        ua: 'Завантажити в .csv',
        no: 'Last ned i .csv',
        it: 'Scarica in .csv',
        se: 'Ladda ner i .csv',
    },
    deleteTag: {
        us: 'Delete tag',
        cz: 'Smazat štítek',
        pl: 'Usuń tag',
        hr: 'Izbriši oznaku',
        si: 'Izbriši oznako',
        de: 'Tag löschen',
        sk: 'Odstrániť štítok',
        es: 'Eliminar etiqueta',
        ru: 'Удалить метку',
        rs: 'Izbriši oznaku',
        ua: 'Видалити мітку',
        no: 'Slette etiketten',
        it: "Cancellare l'etichetta",
        se: 'Ta bort tagg',
    },
    deleteTagPermanently: {
        us: 'You will remove this tag from the project for everyone it is shared with.',
        cz: 'Smazáním štítek odstraníte z projektu i pro všechny, se kterými je projekt sdílen.',
        pl: 'Usunięcie tagu usunie go z projektu i będzie on niewidoczny dla wszystkich, którym projekt jest udostępniany.',
        hr: 'Uklonit ćete ovu oznaku za sve s kojima ste podijelili projekt.',
        si: 'To oznako boste odstranili iz projekta za vse, s katerimi je v skupni rabi.',
        de: 'Sie werden diesen Tag für alle, mit denen er geteilt ist, aus dem Projekt entfernen.',
        sk: 'Zmazaním štítok odstránite z projektu aj pre všetkých, s ktorými je projekt zdieľaný.',
        es: 'Eliminarás esta etiqueta del proyecto y para todos con los que se comparta.',
        ru: 'Вы удалите этот тег из проекта для всех, с кем он используется.',
        rs: 'Uklonićete ovu oznaku iz projekta za sve sa kojima se deli.',
        ua: 'Ви видалите цей тег з проекту для всіх, кому він буде доступний.',
        no: 'Du fjerner denne etiketten fra prosjektet for alle det deles med.',
        it: "L'eliminazione di un tag lo rimuove dal progetto e da tutti coloro con cui il progetto è condiviso.",
        se: 'Om du raderar taggen kommer den att raderas för alla som har tillgång till projektet.',
    },
    tagsManagement: {
        us: 'Tags management',
        cz: 'Správa štítků',
        pl: 'Zarządzanie tagami',
        hr: 'Upravljanje oznakama',
        si: 'Upravljanje oznak',
        de: 'Tags-Verwaltung',
        sk: 'Správa štítkov',
        es: 'Administración de etiquetas',
        ru: 'Управление метками',
        rs: 'Upravljanje oznakama',
        ua: 'Управління мітками',
        no: 'Håndtering av etiketter',
        it: 'Gestione delle etichette',
        se: 'Hantering av taggar',
    },
    standard: {
        us: 'Standard',
        cz: 'Standard',
        pl: 'Standard',
        hr: 'Standard',
        si: 'Standard',
        de: 'Standard',
        sk: 'Standard',
        es: 'Estándar',
        ru: 'Стандартный',
        rs: 'Standard',
        ua: 'Стандартний',
        no: 'Standard',
        it: 'Standard',
        se: 'Standard',
    },
    end2end: {
        us: 'EndToEnd',
        cz: 'EndToEnd',
        pl: 'EndToEnd',
        hr: 'EndToEnd',
        si: 'EndToEnd',
        de: 'EndToEnd',
        sk: 'EndToEnd',
        es: 'EndToEnd',
        ru: 'EndToEnd',
        rs: 'EndToEnd',
        ua: 'EndToEnd',
        no: 'EndtoEnd',
        it: 'EndToEnd',
        se: 'EndToEnd',
    },
    justice: {
        us: 'Legal',
        cz: 'Justice',
        pl: 'Prawo',
        hr: 'Pravo',
        si: 'Pravo',
        de: 'Justiz',
        sk: 'Justícia',
        es: 'Judicial',
        ru: 'Юридический',
        rs: 'Pravo',
        ua: 'Юридичний',
        no: 'Justis',
        it: 'Note legali',
        se: 'Rättslig',
    },
    transcriber: {
        us: 'Transcriber',
        cz: 'Přepisovač',
        pl: 'Model',
        hr: 'Transkriptor',
        si: 'Prepisovalec',
        de: 'Transcriber',
        sk: 'Prepisovač',
        es: 'Transcriptor',
        ru: 'Переписчик',
        rs: 'Transkriptor',
        ua: 'Переписувач',
        no: 'Transkribent',
        it: 'Trascrittore',
        se: 'Transkriberare',
    },
    allTranscribers: {
        us: 'All transcribers',
        cz: 'Všechny přepisovače',
        pl: 'Wszystkie modele',
        hr: 'Svi transkriptori',
        si: 'Vsi prepisovalci',
        de: 'Alle Transcribers',
        sk: 'Všetky prepisovače',
        es: 'Todos transcriptores',
        ru: 'Все писцы',
        rs: 'Svi transkriptori',
        ua: 'Усі переписувачі',
        no: 'Alle trankribenter',
        it: 'Tutti i trascrittori',
        se: 'Alla transkriberare',
    },
    transcriberType: {
        us: 'Type of transcriber',
        cz: 'Typ přepisovače',
        pl: 'Rodzaj modelu',
        hr: 'Vrsta transkriptora',
        si: 'Vrsta prepisovalca',
        de: 'Typ des Transcribers',
        sk: 'Typ prepisovača',
        es: 'Tipo de transcriptor',
        ru: 'Тип транскрибатора',
        rs: 'Vrsta transkriptora',
        ua: 'Тип транскрибатора',
        no: 'Type av transkribenter',
        it: 'Tipo di trascrittore',
        se: 'Typ av transkriberare',
    },
    subtitles: {
        us: 'Subtitles',
        cz: 'Titulky',
        pl: 'Napisy',
        hr: 'Titlovi',
        si: 'Podnapisi',
        de: 'Untertitel',
        sk: 'Titulky',
        es: 'Subtítulos',
        ru: 'Субтитры',
        rs: 'Titlovi',
        ua: 'Субтитри',
        no: 'Undertekster',
        it: 'Sottotitoli',
        se: 'Undertexter',
    },
    transcribers: {
        us: 'Transcribers',
        cz: 'Přepisovače',
        pl: 'Modele',
        hr: 'Transkriptori',
        si: 'Prepisovalci',
        de: 'Transcribers',
        sk: 'Prepisovače',
        es: 'Transcriptores',
        ru: 'Переписчики',
        rs: 'Transkriptori',
        ua: 'Переписувачі',
        no: 'Transkribenter',
        it: 'Trascrittori',
        se: 'Transkriberare',
    },
    cookiesTitle: {
        us: 'Beey runs on cookies too',
        cz: 'I Beey jede na cookies',
        pl: 'I Beey potrzebuje ciasteczek',
        hr: 'I Beey koristi kolačiće',
        si: 'Tudi Beey uporablja piškotke',
        de: 'Auch Beey arbeitet mit Cookies',
        sk: 'I Beey používa súbory cookie',
        es: 'Beey también necesita cookies',
        ru: 'Beey также работает с cookies',
        rs: 'I Beey koristi kolačiće',
        ua: 'Beey також працює з cookies',
        no: 'Beey også bruker informasjonskapsler (cookies)',
        it: 'Anche Beey utilizza cookies',
        se: 'Beey gillar kakor också',
    },
    cookiesText: {
        us: 'We use cookies to collect and analyze information on site performance and usage, to provide social media features and to enhance and customize content and advertisements. You can edit this consent at any time in your browser settings.',
        cz: 'Soubory cookies používáme ke shromažďování a analýze informací o výkonu a používání webu, zajištění fungování funkcí ze sociálních médií a ke zlepšení a přizpůsobení obsahu a reklam. Tenhle souhlas můžete kdykoli upravit v nastavení prohlížeče.',
        pl: 'Używamy plików cookies do zbierania i analizowania informacji o wydajności witryny i sposobie jej użytkowania, do udostępniania funkcji mediów społecznościowych oraz do ulepszania i dostosowywania treści i reklam. Swoją zgodę możesz w każdej chwili edytować w ustawieniach przeglądarki.',
        hr: 'Kolačiće koristimo za prikupljanje i analizu informacija o performansama i korištenju web-lokacije, za pružanje značajki društvenih mreža te za poboljšanje i prilagodbu sadržaja i oglasa. Ovu suglasnost možete u bilo kojem trenutku uređivati u postavkama preglednika.',
        si: 'Piškotke uporabljamo za zbiranje in analizo informacij o uspešnosti in uporabi spletnega mesta, za zagotavljanje funkcij družbenih medijev ter za izboljšanje in prilagajanje vsebin in oglasov. To soglasje lahko kadarkoli uredite v nastavitvah brskalnika.',
        de: 'Wir verwenden Cookies, um Informationen über die Leistung und Nutzung der Website zu sammeln und zu analysieren, um Funktionen für soziale Medien bereitzustellen und um Inhalte und Werbung zu verbessern und anzupassen. Sie können diese Zustimmung jederzeit in Ihren Browsereinstellungen ändern.',
        sk: 'Súbory cookies používame na zhromažďovanie a analýzu informácií o výkone a používaní stránky, na zabezpečenie funkcií zo sociálnych médií a na zlepšenie a prispôsobenie obsahu a reklám. Tento súhlas môžete kedykoľvek zmeniť v nastaveniach prehliadača.',
        es: 'Utilizamos cookies para recopilar y analizar información sobre el rendimiento y el uso del sitio, para proporcionar funciones de redes sociales y para mejorar y personalizar el contenido y los anuncios. Puede editar este consentimiento en cualquier momento en la configuración de su navegador.',
        ru: 'Мы используем файлы cookies для сбора и анализа информации о производительности и использовании сайта, для предоставления функций социальных сетей, а также для улучшения и настройки содержимого и рекламы. Вы можете в любое время изменить это согласие в настройках вашего браузера.',
        rs: 'Kolačiće koristimo za prikupljanje i analizu informacija o performansama i korišćenju web-lokacije, za pružanjefunkcija društvenih mreža i za poboljšanje i prilagođavanje sadržaja i oglasa. Ovu saglasnost možete u bilo kojem trenutku uređivati u postavkama pregledača.',
        ua: 'Ми використовуємо файли cookies для збору та аналізу інформації про продуктивність і використання сайту, для надання функцій соціальних мереж, а також для покращення та налаштування вмісту та реклами. Ви можете будь-коли змінити цю згоду в налаштуваннях свого браузера.',
        no: 'Vi bruker informasjonskapsler for å samle og analysere data om nettstedets ytelse og bruk, for å sørge for sosiale medier, og for å forbedre og tilpasse innhold og reklame. Du kan tilpasse samtykket når som helst i nettleserinnstillingene.',
        it: "Utilizziamo i cookie per raccogliere e analizzare informazioni sulle prestazioni e sull'utilizzo del sito, per far funzionare le funzioni dei social media e per migliorare e personalizzare i contenuti e gli annunci. È possibile modificare questo consenso in qualsiasi momento nelle impostazioni del browser.",
        se: 'Vi använder cookies för att samla och analysera information om webbplatsens prestanda och användning, för att tillhandahålla funktioner för sociala medier och anpassa samt förbättra innehåll och annonser. Du kan alltid redigera ditt samtycke i webbläsarinställningarna.',
    },
    cookiesMoreInfo: {
        us: 'Find out more about cookies.',
        cz: 'Zjistit víc o cookies.',
        pl: 'Dowiedz się więcej',
        hr: 'Saznajte više o kolačićima.',
        si: 'Izvedite več o piškotkih',
        de: 'Erfahren Sie mehr über Cookies.',
        sk: 'Ďalšie informácie o cookies.',
        es: 'Obtener más información sobre las cookies.',
        ru: 'Узнайте больше о файлах cookies.',
        rs: 'Saznajte više o kolačićima.',
        ua: 'Дізнайтеся більше про файли cookies.',
        no: 'Finn ut mer om informasjonskapsler.',
        it: 'Scoprire di più sui cookies.',
        se: 'Läs mer om cookies.',
    },
    cookiesMoreInfoUrl: {
        us: 'https://www.cookiehub.com/about-cookies',
        cz: 'https://www.cookiehub.com/about-cookies',
        pl: 'https://www.cookiehub.com/about-cookies',
        hr: 'https://www.cookiehub.com/about-cookies',
        si: 'https://www.cookiehub.com/about-cookies',
        de: 'https://www.cookiehub.com/about-cookies',
        sk: 'https://www.cookiehub.com/about-cookies',
        es: 'https://www.cookiehub.com/about-cookies',
        ru: 'https://www.cookiehub.com/about-cookies',
        rs: 'https://www.cookiehub.com/about-cookies',
        ua: 'https://www.cookiehub.com/about-cookies',
        no: 'https://www.cookiehub.com/about-cookies',
        it: 'https://www.cookiehub.com/about-cookies',
        se: 'https://www.cookiehub.com/about-cookies',
    },
    cookiesSettings: {
        us: 'Cookies settings',
        cz: 'Nastavení cookies',
        pl: 'Ustawienia plików cookies',
        hr: 'Postavke kolačića',
        si: 'Nastavitve piškotkov',
        de: 'Cookies-Einstellungen',
        sk: 'Nastavenia cookies',
        es: 'Configuración de cookies',
        ru: 'Настройка файлов cookies',
        rs: 'Konfiguracija kolačića',
        ua: 'Налаштування файлів cookies',
        no: 'Innstillinger av informasjonskapsler',
        it: 'Impostazioni dei cookies',
        se: 'Cookie-inställningar',
    },
    allowAll: {
        us: 'Allow all',
        cz: 'Přijmout vše',
        pl: 'Przyjmij wszystkie',
        hr: 'Dopusti sve',
        si: 'Dovoli vse',
        de: 'Alle zulassen',
        sk: 'Prijať všetky',
        es: 'Permitir todo',
        ru: 'Разрешить все',
        rs: 'Dopusti sve',
        ua: 'Дозволити все',
        no: 'Tillate alt',
        it: 'Accettare tutti',
        se: 'Tillåt alla',
    },
    cookiesSettingsContinue: {
        us: 'Continue with these settings',
        cz: 'Pokračovat s tímto nastavením',
        pl: 'Kontynuuj z tymi ustawieniami',
        hr: 'Nastavi s ovim postavkama',
        si: 'Nadaljuj s temi nastavitvami',
        de: 'Mit diesen Einstellungen fortfahren',
        sk: 'Pokračujte s týmto nastavením',
        es: 'Continuar con esta configuración',
        ru: 'Продолжайте выполнять эти настройки',
        rs: 'Nastavi sa ovim konfiguracijama',
        ua: 'Продовжуйте виконувати ці налаштування',
        no: 'Fortsette med disse innstillingene',
        it: 'Continuare con questa impostazione',
        se: 'Fortsätt med dessa inställningar',
    },
    cookiesSettingsText: {
        us: 'We care about your privacy and preferences. You can change these settings at any time in your browser settings.',
        cz: 'Záleží nám na vašem soukromí a vašich preferencích. Toto nastavení si můžete kdykoli změnit v nastavení prohlížeče.',
        pl: 'Dbamy o Twoją prywatność i preferencje. Ustawienia te możesz w każdej chwili zmienić w ustawieniach przeglądarki internetowej.',
        hr: 'Stalo nam je do vaše privatnosti i preferencija. Ove postavke možete promijeniti u bilo kojem trenutku u postavkama preglednika.',
        si: 'Skrbimo za vašo zasebnost in preference. Te nastavitve lahko kadar koli spremenite v nastavitvah brskalnika.',
        de: 'Ihre Privatsphäre und Ihre Präferenzen sind für uns wichtig. Sie können diese Einstellungen jederzeit in Ihren Browsereinstellungen ändern.',
        sk: 'Záleží nám na vašom súkromí a vašich preferenciách. Toto nastavenie môžete kedykoľvek zmeniť v nastaveniach prehliadača.',
        es: 'Nos preocupamos por su privacidad y preferencias. Puede cambiar esta configuración en cualquier momento en la configuración de su navegador.',
        ru: 'Мы заботимся о вашей конфиденциальности и предпочтениях. Вы можете изменить эти настройки в любое время в настройках вашего браузера.',
        rs: 'Stalo nam je do vaše privatnosti i preferencija. Ova podešavanja možete promeniti u bilo kom trenutku u konfiguracijama pregledača.',
        ua: 'Ми дбаємо про вашу конфіденційність і вподобання. Ви можете будь-коли змінити ці налаштування в налаштуваннях браузера.',
        no: 'Vi bryr oss om ditt personvern og preferanser. Du kan tilpasse samtykket når som helst i nettleserinnstillingene.',
        it: 'Abbiamo a cuore la vostra privacy e le vostre preferenze. È possibile modificare questa impostazione in qualsiasi momento nelle impostazioni del browser.',
        se: 'Vi värnar om din integritet och preferenser. Du kan alltid ändra dessa inställningar i webbläsarinställningarna.',
    },
    necessaryCookies: {
        us: 'Necessary cookies',
        cz: 'Nezbytně nutné cookies',
        pl: 'Niezbędne pliki cookie',
        hr: 'Nužni kolačići',
        si: 'Potrebni piškotki',
        de: 'Erforderliche Cookies',
        sk: 'Nevyhnutne potrebné cookies',
        es: 'Cookies necesarias',
        ru: 'Необходимые cookies',
        rs: 'Neophodni kolačići',
        ua: 'Необхідні cookies',
        no: 'Nødvendige informasjonskapsler',
        it: 'Cookies strettamente necessari',
        se: 'Nödvändiga cookies',
    },
    necessaryCookiesText: {
        us: "Some cookies are required to provide core functionality. The website won't function properly without these cookies so they are enabled by default and cannot be disabled.",
        cz: 'Některé soubory cookies jsou vyžadovány, aby byla zajištěna základní funkčnost. Bez těchto cookies nebude web fungovat správně. Ve výchozím nastavení jsou povoleny a nelze je zakázat.',
        pl: 'Niektóre pliki cookies są wymagane do zapewnienia podstawowej funkcjonalności. Witryna bez nich nie będzie działać prawidłowo, dlatego są one domyślnie włączone i nie można ich wyłączyć.',
        hr: 'Neki su kolačići potrebni za pružanje osnovne funkcionalnosti. Web stranica neće ispravno funkcionirati bez ovih kolačića, stoga su oni standardno omogućeni i ne mogu se onemogućiti.',
        si: 'Nekateri piškotki so potrebni za zagotavljanje osnovne funkcionalnosti. Spletna stran ne bo delovala pravilno brez teh piškotkov, zato so privzeto omogočeni in jih ni mogoče onemogočiti.',
        de: 'Einige Cookies sind für die Bereitstellung von Kernfunktionen erforderlich. Die Website kann ohne diese Cookies nicht richtig funktionieren. Sie sind daher standardmäßig aktiviert und können nicht deaktiviert werden.',
        sk: 'Niektoré súbory cookies sú potrebné na zabezpečenie základných funkcií. Bez týchto súborov cookie nebude web správne fungovať. V predvolenom nastavení sú povolené a nemožno ich vypnúť.',
        es: 'Algunas cookies son necesarias para proporcionar la funcionalidad principal. El sitio web no funcionará correctamente sin estas cookies, por lo que están activadas de forma predeterminada y no se pueden desactivar.',
        ru: 'Некоторые файлы cookie необходимы для обеспечения основной функциональности. Веб-сайт не будет работать должным образом без этих файлов cookies, поэтому они включены по умолчанию и не могут быть отключены.',
        rs: 'Neki su kolačići potrebni za pružanje osnovne funkcionalnosti. Web stranica neće ispravno funkcionisati bez ovih kolačića, zbog čega su oni standardno omogućeni i ne mogu se onemogućiti.',
        ua: 'Деякі файли cookies необхідні для забезпечення основної функціональності. Веб-сайт не працюватиме належним чином без цих файлів cookies, тому вони ввімкнені за замовчуванням і не можуть бути вимкнені.',
        no: 'Noen informasjonskapsler forlanges for å opprettholde kjernefunksjonene. Denne siden kan ikke fungere uten disse informasjonskapslene, derfor er de automatisk aktivert og ikke kan deaktiveres.',
        it: 'Alcuni cookie sono necessari per garantire la funzionalità di base. Senza questi cookie, il sito web non funziona correttamente. Sono abilitati per impostazione predefinita e non possono essere disabilitati.',
        se: 'Vissa cookies är avgörande för grundfunktionaliteten. Webbsidan kommer inte att fungera korrekt utan dessa cookies. Därför är de aktiverade som standard och kan inte inaktiveras.',
    },
    analyticalCookies: {
        us: 'Analytical and marketing cookies',
        cz: 'Analytické a marketingové cookies',
        pl: 'Analityczne i marketingowe pliki cookies',
        hr: 'Analitički i marketinški kolačići',
        si: 'Analitični in marketinški piškotki',
        de: 'Analytische und Marketing-Cookies',
        sk: 'Analytické a marketingové cookies',
        es: 'Cookies analíticas y de marketing',
        ru: 'Аналитические и маркетинговые файлы cookie',
        rs: 'Analitički i marketinški kolačići',
        ua: 'Аналітичні та маркетингові файли cookies',
        no: 'Analytiske og markedsføringsmessige informasjonskapsler',
        it: 'Cookie analitici e di marketing',
        se: 'Analytiska och marknadsföringscookies',
    },
    analyticalCookiesText: {
        us: 'Analytical and marketing cookies help us improve our website by collecting and reporting information on its usage.',
        cz: 'Analytické a marketingové soubory cookies nám pomáhají vylepšovat webové stránky shromažďováním informací a podáváním zpráv o jejich používání.',
        pl: 'Analityczne i marketingowe pliki cookies pomagają nam udoskonalać naszą witrynę internetową poprzez gromadzenie i raportowanie informacji o jej użytkowaniu.',
        hr: 'Analitički i marketinški kolačići pomažu nam poboljšati našu web stranicu prikupljanjem i izvješćivanjem informacija o njezinoj upotrebi.',
        si: 'Analitični in marketinški piškotki nam pomagajo izboljšati našo spletno stran z zbiranjem in poročanjem informacij o njeni uporabi.',
        de: 'Analytische und Marketing-Cookies helfen uns die Website zu verbessern, indem sie Informationen über ihre Nutzung sammeln und auswerten.',
        sk: 'Analytické a marketingové súbory cookies nám pomáhajú zlepšovať webové stránky zhromažďovaním informácií a podávaním správ o používaní.',
        es: 'Las cookies analíticas y de marketing nos ayudan a mejorar nuestro sitio web recopilando y reportando información sobre su uso.',
        ru: 'Аналитические и маркетинговые файлы cookie помогают нам улучшить наш сайт, собирая и сообщая информацию о его использовании.',
        rs: 'Analitički i marketinški kolačići pomažu nam da poboljšamo našu web stranicu prikupljanjem i izveštavanjem informacija o njenoj upotrebi.',
        ua: 'Аналітичні та маркетингові файли cookies допомагають нам покращувати наш веб-сайт, збираючи та повідомляючи інформацію про його використання.',
        no: 'Analytiske og markedsføringsmessige informasjonskapsler hjelper oss forbedre denne siden med innsamlede data og rapportene om sidens bruk.',
        it: "I cookie analitici e di marketing ci aiutano a migliorare il sito web, raccogliendo informazioni e facendo rapporto sull'utilizzo.",
        se: 'Analytiska och marknadsföringscookies hjälper oss att förbättra webbplatsen genom att samla och rapportera information om hur webbsidan används.',
    },
    checkingVersion: {
        us: 'Checking version...',
        cz: 'Kontrola verze...',
        pl: 'Kontrola wersji',
        hr: 'Provjera verzije...',
        si: 'Preverjanje različice...',
        de: 'Version wird überprüft...',
        sk: 'Kontrola verzie...',
        es: 'Comprobando versión...',
        ru: 'Проверка версии...',
        rs: 'Provera verzije...',
        ua: 'Перевірка версії...',
        no: 'Kontrollerer versjon...',
        it: 'Controllo della versione...',
        se: 'Kontrollerar version...',
    },
    serverError: {
        us: 'Server error',
        cz: 'Chyba serveru',
        pl: 'Błąd serwera',
        hr: 'Pogreška poslužitelja',
        si: 'Napaka strežnika',
        de: 'Server-Fehler',
        sk: 'Chyba servera',
        es: 'Error del servidor',
        ru: 'Ошибка сервера',
        rs: 'Greška servera',
        ua: 'Помилка серверу',
        no: 'Tjenerfeil',
        it: 'Errore del server',
        se: 'Serverfel',
    },
    projectTags: {
        us: 'Project tags',
        cz: 'Štítky projektů',
        pl: 'Tagi projektów',
        hr: 'Oznake projekta',
        si: 'Oznake projekta',
        de: 'Projekt-Tags',
        sk: 'Štítky projektu',
        es: 'Etiquetas del proyecto',
        ru: 'Теги проекта',
        rs: 'Oznake projekta',
        ua: 'Теги проекту',
        no: 'Prosjektetiketter',
        it: 'Tag del progetto',
        se: 'Projekttaggar',
    },
    searchForTags: {
        us: 'Enter project tags',
        cz: 'Zadejte štítky projektů',
        pl: 'Wprwadź tag',
        hr: 'Unesi oznake projekta',
        si: 'Vnesi oznake projekta',
        de: 'Projekt-Tags eingeben',
        sk: 'Zadajte štítky projektu',
        es: 'Ingrese las etiquetas del proyecto',
        ru: 'Введите теги проекта',
        rs: 'Unesi oznake projekta',
        ua: 'Введіть теги проекту',
        no: 'Oppgi prosjektetiketter',
        it: 'Inserisca le etichette del progetto',
        se: 'Ange projekttaggar',
    },
    saveTag: {
        us: 'Save tag',
        cz: 'Uložit štítek',
        pl: 'Zapisz tag',
        hr: 'Spremi oznaku',
        si: 'Shrani oznako',
        de: 'Tag speichern',
        sk: 'Uložiť štítok',
        es: 'Guardar etiqueta',
        ru: 'Сохранить тег',
        rs: 'Sačuvaj oznaku',
        ua: 'Зберегти тег',
        no: 'Lagre etiketter',
        it: "Salva l'etichetta",
        se: 'Spara tagg',
    },
    insertSubtitleEnd: {
        us: 'Inserts subtitle end ◆',
        cz: 'Vloží konec titulku ◆',
        pl: 'Wstawi koniec napisu ◆',
        hr: 'Umetanje završetka titla ◆',
        si: 'Vstavljanje konca podnaslova ◆',
        de: 'Untertitelende einfügen ◆',
        sk: 'Vloží koniec titulku ◆',
        es: 'Insertar el final del subtítulo',
        ru: 'Вставляет конец субтитров ◆',
        rs: 'Umetanje završetka titla ◆',
        ua: 'Вставляє кінець субтитрів ◆',
        no: 'Oppgir undertekstslutt ◆',
        it: 'Inserisce la fine del sottotitoli ◆',
        se: 'Infogar undertextslut ◆',
    },
    addTags: {
        us: 'Add tags',
        cz: 'Přidat štítky',
        pl: 'Dodaj tagi',
        hr: 'Dodaj oznake',
        si: 'Dodaj oznake',
        de: 'Tags hinzufügen',
        sk: 'Pridať štítky',
        es: 'Añadir etiquetas',
        ru: 'Добавить теги',
        rs: 'Dodaj tagove',
        ua: 'Додайте теги',
        no: 'Legg til etiketter',
        it: 'Aggiungere le etichette',
        se: 'Lägg till taggar',
    },
    noTagForEmptyHeading: {
        us: 'Fill heading text before adding tag',
        cz: 'Vyplňte text nadpisu předtím, než přidáte štítek',
        pl: 'Wypełnij tekst nagłówka przed dodaniem tagu',
        hr: 'Ispunite tekst naslova prije dodavanja oznake',
        si: 'Pred dodajanjem oznake izpolnite besedilo naslova',
        de: 'Überschriftstext ausfüllen vor dem Hinzufügen eines Tags',
        sk: 'Pred pridaním štítku vyplňte text nadpisu',
        es: 'Llena el texto del encabezado antes de agregar una etiqueta',
        ru: 'Заполнить текст заголовка перед добавлением тега',
        rs: 'Popunite naslovni tekst pre dodavanja oznake',
        ua: 'Заповніть текст заголовка перед додаванням тегу',
        no: 'Fyll ut overskriftteksten før du legger til en etikett',
        it: "Compilare il testo della didascalia prima di aggiungere l'etichetta",
        se: 'Fyll i rubriken innan du lägger till tagg',
    },
    fulltextSearchInfo1: {
        us: 'The result contains each completed field',
        cz: 'Výsledek obsahuje každé vyplněné pole',
        pl: 'Wynik zawiera każde wypełnione pole',
        hr: 'Rezultat sadrži svako popunjeno polje',
        si: 'Rezultat vsebuje vsako dokončano polje',
        de: 'Das Ergebnis enthält jedes ausgefüllte Feld',
        sk: 'Výsledok obsahuje každé vyplnené pole',
        es: 'El resultado contiene cada campo completado',
        ru: 'Результат содержит каждое заполненное поле',
        rs: 'Rezultat sadrži svako popunjeno polje',
        ua: 'Результат містить кожне заповнене поле',
        no: 'Resultatet inneholder hvert fylt ut felt',
        it: 'Il risultato contiene ogni campo completato',
        se: 'Resultatet innehåller samtilga ifyllda fält',
    },
    fulltextSearchInfo2: {
        us: 'e.g. Name and Speakers AT THE SAME TIME',
        cz: 'např. Název A ZÁROVEŇ Mluvčí',
        pl: 'np. Nazwę I JEDNOCZEŚNIE Mówcę',
        hr: 'npr. Ime i govornici ISTOVREMENO',
        si: 'npr. Ime in govorci HKRATI',
        de: 'z.B. Name und Sprecher GLEICHZEITIG',
        sk: 'napr. Názov A TIEŽ Rečník',
        es: 'p.ej. Nombre y Hablantes A LA VEZ',
        ru: 'напр. Имя и спикеры ОДНО ВРЕМЯ',
        rs: 'npr. Ime i Govornici U ISTO VREME',
        ua: 'напр. Ім’я та спікери ОДНОЧАСНО',
        no: 'f.eks. Tittel OG Taler SAMTIDIG',
        it: "ad esempio, titolo e titolo dell'oratore ALLO STESSO TEMPO",
        se: 't.ex. Namn och Talare SAMTIDIGT',
    },
    smartTranslation: {
        us: 'Smart translation',
        cz: 'Inteligentní překlad',
        pl: 'Inteligentne tłumaczenie',
        hr: 'Pametan prijevod',
        si: 'Pametno prevajanje',
        de: 'Intelligente Übersetzung',
        sk: 'Inteligentný preklad',
        es: 'Traducción inteligente',
        ru: 'Умный перевод',
        rs: 'Pametan prevod',
        ua: 'Розумний переклад',
        no: 'Smart oversettelse',
        it: 'Traduzione intelligente',
        se: 'Smart översättning',
    },
    timeStampsRemain: {
        us: 'Project time stamps will remain in the transcription as well as in subtitles.',
        cz: 'Časové značky projektu zůstanou zachovány v přepisu i titulcích.',
        pl: 'Znaczniki czasowe projektu zostaną zachowane w transkrypcji i napisach.',
        hr: 'Vremenske oznake projekta ostat će u transkripciji i u titlovima.',
        si: 'Časovni žigi projekta bodo ostali v prepisu, pa tudi v podnapisih.',
        de: 'Die Zeitstempel des Projekts bleiben sowohl in der Transkription, als auch in den Untertiteln erhalten.',
        sk: 'Časové značky projektu sa zachovajú v prepise aj v titulkoch.',
        es: 'Las marcas de tiempo del proyecto permanecerán en la transcripción, así como en los subtítulos.',
        ru: 'Отметки времени проекта останутся как в транскрипции, так и в субтитрах.',
        rs: 'Vremenske oznake projekta će ostati u transkripciji kao i u titlovima.',
        ua: 'Позначки часу проекту залишаться як у транскрипції, так і в субтитрах.',
        no: 'Prosjektets tidsstempler forblir i oversettelsen og i undertekstene.',
        it: 'I timestamp del progetto saranno conservati nella trascrizione e nei sottotitoli.',
        se: 'Projektets tidsstämplar förblir såväl i översättningen som i undertexterna.',
    },
    targetLanguage: {
        us: 'Target language of translation',
        cz: 'Cílový jazyk překladu',
        pl: 'Język docelowy tłumaczenia',
        hr: 'Ciljni jezik prijevoda',
        si: 'Ciljni jezik prevajanja',
        de: 'Zielsprache der Übersetzung',
        sk: 'Cieľový jazyk prekladu',
        es: 'Idioma de destino de la traducción',
        ru: 'Целевой язык перевода',
        rs: 'Ciljni jezik prevoda',
        ua: 'Цільова мова перекладу',
        no: 'Målspråk av oversettelse',
        it: 'Lingua di destinazione della traduzione',
        se: 'Översättningens målspråk',
    },
    credits: {
        us: 'credits',
        cz: 'kreditů',
        pl: 'kredytów',
        hr: 'krediti',
        si: 'krediti',
        de: 'Kredite',
        sk: 'kreditov',
        es: 'creditos',
        ru: 'кредиты',
        rs: 'krediti',
        ua: 'кредити',
        no: 'kreditter',
        it: 'crediti',
        se: 'krediter',
    },
    translate: {
        us: 'Translate',
        cz: 'Přeložit',
        pl: 'Przetłumacz',
        hr: 'Prevedi',
        si: 'Prevedi',
        de: 'Übersetzen',
        sk: 'Preložiť',
        es: 'Traducir',
        ru: 'Перевести',
        rs: 'Prevedi',
        ua: 'Перекладіть',
        no: 'Oversette',
        it: 'Tradurre',
        se: 'Översätt',
    },
    notEnoughCreditForTranslation: {
        us: 'You do not have enough credit for translation.',
        cz: 'Pro překlad nemáte dostatek kreditu.',
        pl: 'Nie masz wystarczającego kredytu aby skorzystać z tłumaczenia.',
        hr: 'Nemate dovoljno kredita za prijevod.',
        si: 'Nimate dovolj kredita za prevajanje.',
        de: 'Für Übersetzung haben Sie nicht genug Kredit.',
        sk: 'Na preklad nemáte dostatočný kredit.',
        es: 'No tiene suficiente crédito para la traducción.',
        ru: 'У вас недостаточно кредитов для перевода.',
        rs: 'Nemate dovoljno kredita za prevod.',
        ua: 'У вас недостатньо кредитів для перекладу.',
        no: 'Du har ikke nok kreditt for oversettelsen.',
        it: 'Non ha abbastanza credito per la traduzione.',
        se: 'Du har inte tillräcklig kredit för översättningen.',
    },
    someoneIsEditingHead: {
        us: 'Someone else is editing the document',
        cz: 'Někdo jiný edituje dokument',
        pl: 'Ktoś inny edytuje dokument',
        hr: 'Netko drugi uređuje dokument',
        si: 'Nekdo drug ureja dokument',
        de: 'Das Dokument wird von einer anderen Person bearbeitet',
        sk: 'Dokument upravuje niekto iný',
        es: 'Alguien más está editando el documento.',
        ru: 'Кто-то другой редактирует документ',
        rs: 'Neko drugi uređuje dokument',
        ua: 'Хтось інший редагує документ',
        no: 'Noen annen redigerer dokumentet nå',
        it: 'Qualcun altro sta modificando il documento',
        se: 'Någon annan redigerar dokumentet nu',
    },
    someoneIsEditingDescription: {
        us: 'It is not possible to edit one document from two places simultaneously.',
        cz: 'Není možné editovat jeden dokument ze dvou míst najednou.',
        pl: 'Nie jest możliwe jednoczesne edytowanie jednego dokumentu z dwóch kont.',
        hr: 'Nije moguće istovremeno uređivati jedan dokument s dva mjesta.',
        si: 'Hkratno urejanje enega dokumenta z dveh mest ni mogoče.',
        de: 'Es ist nicht möglich, ein Dokument an zwei Stellen gleichzeitig zu bearbeiten.',
        sk: 'Nie je možné upravovať jeden dokument z dvoch miest súčasne.',
        es: 'No es posible editar un documento desde dos lugares al mismo tiempo.',
        ru: 'Невозможно редактировать один документ из двух мест одновременно.',
        rs: 'Nije moguće uređivati jedan dokument sa dva mesta istovremeno.',
        ua: 'Неможливо редагувати один документ з двох місць одночасно.',
        no: 'Det er umulig å redigere ett dokument fra to steder samtidig.',
        it: 'Non è possibile modificare un documento da due postazioni contemporaneamente.',
        se: 'Det är inte möjligt att redigera ett dokument från två ställen samtidigt.',
    },
    editedSecondsAgo: {
        us: 'Edited <timestamp> seconds ago by <user>',
        cz: 'Editováno před <timestamp> sekundami uživatelem <user>',
        pl: 'Ostatnio edytowane <timestamp> sekundy temu przez <user>',
        hr: 'Uređivano prije <timestamp> sekundi od strane <user>',
        si: 'Urejeno pred <timestamp> sekundami s strani <user>',
        de: 'Bearbeitet vor <timestamp> Sekunden von <user>',
        sk: 'Pred <timestamp> sekundami upravil užívateľ <user>',
        es: 'Editado hace <timestamp> segundos por <user>',
        ru: 'Отредактировано <timestamp> секунд назад <user>',
        rs: 'Uređeno pre <timestamp> sekundi od strane <user>',
        ua: 'Відредаговано <timestamp> секунд тому <user>',
        no: 'Redigert for <timestamp> sekunder siden av <user>',
        it: 'Modificato <timestamp> secondi fa da <user>',
        se: 'Editerat för <timestamp> sekunder sedan av <user>',
    },
    succesfullyChangedTranscriptionLimit: {
        us: 'Limit of parallel transcriptions was succesfully changed to:',
        cz: 'Limit paralelních přepisů byl úspěšně změněn na:',
        pl: 'Limit równoległych transkrypcji został pomyślnie zmieniony na:',
        hr: 'Ograničenje paralelnih transkripcija uspješno je promijenjeno u:',
        si: 'Omejitev vzporednih transkripcij je bila uspešno spremenjena v:',
        de: 'Limit der parallelen Transkriptionen wurde erfolgreich geändert auf:',
        sk: 'Limit paralelných prepisov bol úspešne zmenený na:',
        es: 'Se ha cambiado el límite de transcripciones paralelas a:',
        ru: 'Лимит параллельных транскрипций был успешно изменен на:',
        rs: 'Limit paralelnih transkripcija je uspešno promenjen na:',
        ua: 'Ліміт паралельних транскрипцій успішно змінено на:',
        no: 'Grense for parallelle transkripsjoner ble endret til:',
        it: 'Il limite delle sovrascritture parallele è stato modificato con successo in:',
        se: 'Gränsen för parallella transkriptioner har ändrats till:',
    },
    currentTranscriptionLimit: {
        us: 'Limit of parallel transcriptions',
        cz: 'Limit paralelních přepisů',
        pl: 'Limit równoległych transkrypcji',
        hr: 'Ograničenje paralelnih transkripcija',
        si: 'Omejitev vzporednih transkripcij',
        de: 'Limit der parallelen Transkriptionen',
        sk: 'Limit paralelných prepisov',
        es: 'Límite de trasncripciones paralelas',
        ru: 'Лимит параллельных транскрипций',
        rs: 'Limit paralelnih transkripcija',
        ua: 'Ліміт паралельних транскрипцій',
        no: 'Grense for parallelle transkripsjoner',
        it: 'Limite di sovrascritture parallele',
        se: 'Gränsen för parallella transkriptioner',
    },
    sameLanguagesAlert: {
        us: 'Target language must be different from project language.',
        cz: 'Cílový jazyk musí být odlišný od jazyka projektu.',
        pl: 'Język docelowy musi być inny niż język projektu.',
        hr: 'Ciljni jezik mora se razlikovati od jezika projekta.',
        si: 'Ciljni jezik mora biti drugačen od jezika projekta.',
        de: 'Zielsprache muss sich von der Projektsprache unterscheiden.',
        sk: 'Cieľový jazyk sa musí líšiť od jazyka projektu.',
        es: 'El idioma de destino debe ser diferente del idioma del proyecto.',
        ru: 'Целевой язык должен отличаться от языка проекта.',
        rs: 'Ciljni jezik mora da se razlikuje od jezika projekta.',
        ua: 'Цільова мова має відрізнятися від мови проекту.',
        no: 'Målspråket må være forskjellig fra prosjektspråket.',
        it: 'La lingua di destinazione deve essere diversa da quella del progetto.',
        se: 'Målspråket måste skilja sig från projektspråket.',
    },
    translationInProgress: {
        us: 'Translation in progress...',
        cz: 'Probíhá překlad...',
        pl: 'Tłumaczenie w toku...',
        hr: 'Prijevod u tijeku...',
        si: 'Prevajanje v teku...',
        de: 'Übersetzung läuft...',
        sk: 'Preklad prebieha...',
        es: 'Traducción en proceso...',
        ru: 'Выполняется перевод...',
        rs: 'Prevod u toku...',
        ua: 'Виконується переклад...',
        no: 'Oversettelsen pågår...',
        it: 'La traduzione è in corso...',
        se: 'Översättning pågår...',
    },
    translation: {
        us: 'Translation',
        cz: 'Překlad',
        pl: 'Tłumaczenie',
        hr: 'Prijevod',
        si: 'Prevod',
        de: 'Übersetzung',
        sk: 'Preklad',
        es: 'Traducción',
        ru: 'Перевод',
        rs: 'Prevod',
        ua: 'Переклад',
        no: 'Oversettelse',
        it: 'Traduzione',
        se: 'Översättning',
    },
    translationSuccess: {
        us: 'Translation finished successfully!',
        cz: 'Projekt byl úspěšně přeložen!',
        pl: 'Projekt został przetłumaczony!',
        hr: 'Prijevod uspješno završen!',
        si: 'Prevajanje uspešno končano!',
        de: 'Übersetzung erfolgreich abgeschlossen!',
        sk: 'Projekt bol úspešne preložený!',
        es: '¡La traducción se completó con éxito!',
        ru: 'Перевод успешно завершен!',
        rs: 'Prevod uspešno završen!',
        ua: 'Переклад успішно завершено!',
        no: 'Oversettelsen vellykket!',
        it: 'Il progetto è stato tradotto con successo!',
        se: 'Översättningen är klar!',
    },
    openTranslation: {
        us: 'Open translated project',
        cz: 'Otevřít přeložený projekt',
        pl: 'Otwórz przetłumaczony projekt',
        hr: 'Otvori prevedeni projekt',
        si: 'Odpri prevedeni projekt',
        de: 'Übersetztes Projekt öffnen',
        sk: 'Otvoriť preložený projekt',
        es: 'Abrir proyecto traducido',
        ru: 'Открытый переведенный проект',
        rs: 'Otvori prevedeni projekat',
        ua: 'Відкритий перекладений проект',
        no: 'Åpne oversatt prosjekt',
        it: 'Aprire un progetto tradotto',
        se: 'Öppna ett översatt projekt',
    },
    newTranslation: {
        us: 'New translation',
        cz: 'Nový překlad',
        pl: 'Nowe tłumaczenie',
        hr: 'Novi prijevod',
        si: 'Nov prevod',
        de: 'Neue Übersetzung',
        sk: 'Nový preklad',
        es: 'Nueva traducción',
        ru: 'Новый перевод',
        rs: 'Novi prevod',
        ua: 'Новий переклад',
        no: 'Ny oversettelse',
        it: 'Nuova traduzione',
        se: 'Ny översättning',
    },
    translationError: {
        us: 'Project translation failed. Please try it again.',
        cz: 'Během překladu došlo k chybě. Zkuste to prosím znovu.',
        pl: 'Podczas tłumaczenia wystąpił błąd. Spróbuj ponownie.',
        hr: 'Prijevod projekta nije uspio. Molimo, pokušajte ponovno.',
        si: 'Prevajanje projekta ni uspelo. Prosimo, poskusite znova.',
        de: 'Projektübersetzung fehlgeschlagen. Bitte versuchen Sie es erneut.',
        sk: 'Pri preklade došlo k chybe. Skúste to prosím znova.',
        es: 'La traducción del proyecto falló. Por favor, inténtelo de nuevo.',
        ru: 'Ошибка перевода проекта. Пожалуйста, повторите попытку.',
        rs: 'Prevod projekta nije uspeo. Molimo pokušajte ponovo.',
        ua: 'Помилка перекладу проекту. Будь ласка, спробуйте ще раз.',
        no: 'Prosjektoversettelsen mislyktes. Vennligst prøv igjen.',
        it: 'Si è verificato un errore durante la traduzione. Si prega di riprovare.',
        se: 'Det gick inte att översätta projektet. Vänligen prova igen.',
    },
    noLangError: {
        us: 'Unknown project language',
        cz: 'Neznámý jazyk projektu',
        pl: 'Nieznany język projektu',
        hr: 'Nepoznat jezik projekta',
        si: 'Neznan jezik projekta',
        de: 'Unbekannte Projektsprache',
        sk: 'Neznámy jazyk projektu',
        es: 'Idioma del proyecto desconocido',
        ru: 'Неизвестный язык проекта',
        rs: 'Nepoznat jezik projekta',
        ua: 'Невідома мова проєкту',
        no: 'Ukjent prosjektspråk',
        it: 'Lingua del progetto sconosciuta',
        se: 'Okänt projektspråk',
    },
    noTagName: {
        us: 'Please enter a tag name.',
        cz: 'Prosím zadejte název štítku.',
        pl: 'Wprowadź nazwę tagu.',
        hr: 'Molimo uneseite ime oznake.',
        si: 'Prosimo, vnesite ime oznake.',
        de: 'Bitte geben Sie einen Tagnamen ein.',
        sk: 'Zadajte názov štítku.',
        es: 'Ingrese un nombre de etiqueta.',
        ru: 'Пожалуйста, введите название тега.',
        rs: 'Molim vas da unesete naziv taga.',
        ua: 'Введіть назву тегу.',
        no: 'Vennligst skriv inn etikettnavn.',
        it: "Inserisca il nome dell'etichetta.",
        se: 'Vänligen ange taggnamn.',
    },
    noRoleName: {
        us: 'Please enter a role name.',
        cz: 'Prosím zadejte název role.',
        pl: 'Wprowadź nazwę roli.',
        hr: 'Molimo unesite ime uloge.',
        si: 'Prosimo, vnesite ime vloge.',
        de: 'Bitte geben Sie einen Rollennamen ein.',
        sk: 'Zadajte názov role.',
        es: 'Ingrese un nombre de rol.',
        ru: 'Пожалуйста, введите имя роли.',
        rs: 'Molim vas da unesete naziv uloge.',
        ua: 'Введіть назву ролі.',
        no: 'Vennligst skriv inn rollenavn.',
        it: 'Inserire il nome del ruolo.',
        se: 'Vänligen ange rollnamn.',
    },
    duplicitTag: {
        us: 'This tag name already exists.',
        cz: 'Štítek s tímto názvem již existuje.',
        pl: 'Tag o tej nazwie już istnieje.',
        hr: 'Ovaj naziv oznake već postoji.',
        si: 'To ime oznake že obstaja.',
        de: 'Dieser Tagname existiert bereits.',
        sk: 'Štítok s týmto názvom už existuje.',
        es: 'Este nombre de etiqueta ya existe.',
        ru: 'Это имя тега уже существует.',
        rs: 'Ovaj naziv taga već postoji.',
        ua: 'Ця назва тегу вже існує.',
        no: 'Dette etikettnavnet allerede finnes.',
        it: "Un'etichetta con questo nome esiste già.",
        se: 'Detta taggnamn finns redan.',
    },
    startsWithHash: {
        us: 'Tag names cannot start with the hash sign (#).',
        cz: 'Názvy štítků nemohou začínat hash znakem (#).',
        pl: 'Nazwy tagów nie mogą zaczynać się od znaku hashtag (#).',
        hr: 'Nazivi oznaka ne mogu započeti znakom ljestve (#).',
        si: 'Imena oznak se ne morejo začeti z znakom lestev #.',
        de: 'Tagnamen dürfen nicht mit dem Hashzeichen (#) beginnen.',
        sk: 'Názvy štítkov nemôžu začínať znakom hash (#).',
        es: 'Los nombres de las etiquetas no pueden comenzar con el símbolo almohadilla (#).',
        ru: 'Имена тегов не могут начинаться со знака решетки (#).',
        rs: 'Naziv taga ne može da započne sa hashtagom (#).',
        ua: 'Імена тегів не можуть починатися зі знака решітки (#).',
        no: 'Etikettnavn ikke kan begynne med tegnet #.',
        it: 'I nomi delle etichette non possono iniziare con un carattere hash (#).',
        se: 'Taggnamn kan inte börja med hashtagg (#).',
    },
    duplicitRole: {
        us: 'This role name already exists.',
        cz: 'Role s tímto názvem již existuje.',
        pl: 'Rola z tą nazwą już istnieje.',
        hr: 'Ovaj naziv uloge već postoji.',
        si: 'To ime vloge že obstaja.',
        de: 'Dieser Rollenname existiert bereits.',
        sk: 'Rola s týmto názvom už existuje.',
        es: 'Este nombre de rol ya existe.',
        ru: 'Это имя роли уже существует.',
        rs: 'Ovaj naziv uloge već postoji.',
        ua: 'Ця назва ролі вже існує.',
        no: 'Dette rollenavnet allerede finnes.',
        it: 'Un ruolo con questo nome esiste già.',
        se: 'Detta rollnamn finns redan.',
    },
    addName: {
        us: 'Please also enter first or last name.',
        cz: 'Prosím zadejte ještě jméno nebo příjmení.',
        pl: 'Wprowadź jeszcze imię lub nazwisko.',
        hr: 'Molimo unesite i ime ili prezime.',
        si: 'Prosimo, vnesite tudi ime ali priimek.',
        de: 'Bitte geben Sie auch Vor- oder Nachnamen ein.',
        sk: 'Zadajte meno alebo priezvisko.',
        es: 'Ingrese también el nombre o apellido.',
        ru: 'Также введите имя или фамилию.',
        rs: 'Molim vas da unesete ime ili prezime.',
        ua: "Також введіть ім'я або прізвище.",
        no: 'Vennligst skriv inn navnet eller etternavnet også.',
        it: 'Inserire anche un nome o un cognome.',
        se: 'Vänligen ange även förnamn eller efternamn.',
    },
    addAtLeastOne: {
        us: 'Please fill in at least one input.',
        cz: 'Prosím zadejte aspoň jednu položku.',
        pl: 'Wypełnij przynajmniej jeden element.',
        hr: 'Molimo ispunite barem jedan unos.',
        si: 'Prosimo, izpolnite vsaj en vnos.',
        de: 'Bitte füllen Sie mindestens eine Eingabe aus.',
        sk: 'Zadajte aspoň jeden záznam.',
        es: 'Por favor complete al menos una entrada.',
        ru: 'Пожалуйста, заполните хотя бы один вход.',
        rs: 'Molim vas da unesete bar jedan unos.',
        ua: 'Будь ласка, заповніть принаймні один вхід.',
        no: 'Vennligst fyll inn minst én input.',
        it: 'Si prega di inserire almeno una voce.',
        se: 'Vänligen infoga minst en input.',
    },
    preview: {
        us: 'Preview',
        cz: 'Náhled',
        pl: 'Podgląd',
        hr: 'Pregled',
        si: 'Predogled',
        de: 'Vorschau',
        sk: 'Náhľad',
        es: 'Vista previa',
        ru: 'Предварительный просмотр',
        rs: 'Pregled',
        ua: 'Попередній перегляд',
        no: 'Forhåndsvisning',
        it: 'Anteprima',
        se: 'Förhandsgranskning',
    },
    runBeeyTutorial: {
        us: 'Run Beey tutorial',
        cz: 'Spustit Beey tutoriál',
        pl: 'Uruchom tutorial Beey',
        hr: 'Pokreni Beey tutorial',
        si: 'Zaženi vadnico za Beey',
        de: 'Tutorial ausführen',
        sk: 'Spustiť Beey tutoriál',
        es: 'Iniciar tutorial de Beey',
        ru: 'Руководство по запуску Beey',
        rs: 'Pusti Beey tutorial',
        ua: 'Запустіть підручник Beey',
        no: 'Kjøre Beey opplæringsprogrammet',
        it: 'Esecuzione del tutorial Beey',
        se: 'Starta Beey-handledning',
    },
    addNewTag: {
        us: 'Add tag',
        cz: 'Přidat štítek',
        pl: 'Dodaj tag',
        hr: 'Dodaj oznaku',
        si: 'Dodaj oznako',
        de: 'Tag hinzufügen',
        sk: 'Pridať štítok',
        es: 'Añadir etiqueta',
        ru: 'Добавить метку',
        rs: 'Dodaj tag',
        ua: 'Додати тег',
        no: 'Legg til etikett',
        it: "Aggiungere un'etichetta",
        se: 'Lägg till tagg',
    },
    editTag: {
        us: 'Edit tag',
        cz: 'Upravit štítek',
        pl: 'Edytuj tag',
        hr: 'Izmijeni oznaku',
        si: 'Uredi oznako',
        de: 'Tag bearbeiten',
        sk: 'Upraviť štítok',
        es: 'Editar etiqueta',
        ru: 'Изменить тег',
        rs: 'Izmeni tag',
        ua: 'Редагувати тег',
        no: 'Redigere etiketten',
        it: "modificare un'etichetta",
        se: 'Redigera tagg',
    },
    deletePermanently: {
        us: 'Delete permanently?',
        cz: 'Trvale smazat?',
        pl: 'Trwale usunąć?',
        hr: 'Trajno izbrisati?',
        si: 'Trajno izbrisati?',
        de: 'Dauerhaft löschen?',
        sk: 'Vymazať natrvalo?',
        es: '¿Eliminar permanentemente?',
        ru: 'Удалить навсегда?',
        rs: 'Izbirsati trajno?',
        ua: 'Видалити остаточно?',
        no: 'Slette permanent?',
        it: 'Cancellare definitivamente?',
        se: 'Ta bort för alltid?',
    },
    sectionTags: {
        us: 'Section tags',
        cz: 'Štítky sekcí',
        pl: 'Tagi sekcji',
        hr: 'Oznake sekcija',
        si: 'Oznake razdelkov',
        de: 'Abschnitt Tags',
        sk: 'Štítky sekcií',
        es: 'Etiquetas de sección',
        ru: 'Теги раздела',
        rs: 'Oznaka sekcije',
        ua: 'Теги розділів',
        no: 'Seksjonsetiketter',
        it: 'Tag di sezione',
        se: 'Sektionstaggar',
    },
    lexiconLanguage: {
        us: 'Rules for language',
        cz: 'Pravidla pro jazyk',
        pl: 'Reguły dla języka',
        hr: 'Pravila za jezik',
        si: 'Pravila za jezik',
        de: 'Regeln für Sprache',
        sk: 'Pravidlá pre jazyk',
        es: 'Reglas para el idioma',
        ru: 'Правила для языка',
        rs: 'Pravila za jezik',
        ua: 'Правила для мови',
        no: 'Regler for språk',
        it: 'Regole per la lingua',
        se: 'Språkregler',
    },
    subtitleFormat: {
        us: 'Subtitle format',
        cz: 'Formát titulků',
        pl: 'Format napisów',
        hr: 'Format titlova',
        si: 'Format podnapisov',
        de: 'Untertitel-Format',
        sk: 'Formát titulkov',
        es: 'Formato de subtítulos',
        ru: 'Формат субтитров',
        rs: 'Format titla',
        ua: 'Формат субтитрів',
        no: 'Undertekst-format',
        it: 'Formato dei sottotitoli',
        se: 'Undertextformat',
    },
    changeDisabled: {
        us: 'To change certain parameters, subtitles must be generated again. Switch back to transcription and convert to subtitles with new format.',
        cz: 'Pro změnu parametru je nutné titulky znovu vygenerovat. Vraťte na přepis a převeďte na titulky s novým formátem.',
        pl: 'Aby zmienić parametr, należy ponownie wygenerować napisy. Przejdź z powrotem do transkrypcji, a następnie skonwertuj do napisów z nowym formatem.',
        hr: 'Da biste promijenili određene parametre, titlovi se moraju ponovno kreirati. Vratite se na transkripciju i pretvorite u titlove s novim formatom.',
        si: 'Če želite spremeniti določene parametre, je treba podnapise ponovno ustvariti. Preklopite nazaj na prepis in pretvorite v podnapise z novim formatom.',
        de: 'Um bestimmte Parameter zu ändern, müssen die Untertitel neu erstellt werden. Wechseln Sie zurück zur Transkription und konvertieren Sie in Untertitel mit neuem Format.',
        sk: 'Ak chcete zmeniť tento parameter, titulky sa musia vygenerovať znova. Vráťte sa k prepisu a preveďte ho na titulky v novom formáte.',
        es: 'Para cambiar ciertos parámetros, los subtítulos deben generarse nuevamente. Vuelva a la transcripción y conviértala a subtítulos con el nuevo formato.',
        ru: 'Чтобы изменить определенные параметры, субтитры должны быть сгенерированы заново. Вернитесь к транскрипции и конвертируйте субтитры в новый формат.',
        rs: 'Za promenu određenih parametara, titlovi se moraju ponovno generisati. Vratite se na transkripciju i pretvorite u titlove s novim formatom.',
        ua: 'Щоб змінити певні параметри, субтитри потрібно згенерувати повторно. Повернутися до транскрипції та перетворити на субтитри в новому форматі.',
        no: 'Undertekster må være generert på nytt for å endre visse parametre. Bytt tilbake til transkripsjonen og konverter til undertekst med et nytt format.',
        it: 'Per modificare questo parametro, i sottotitoli devono essere rigenerati. Torni alla trascrizione e converta in sottotitoli con il nuovo formato.',
        se: 'Undertexter måste genereras igen om du vill ändra parametern. Byt tillbaka till transkriptionen och konvertera den till undertexter i nytt format.',
    },
    subtitleModeDescription: {
        us: 'You can display and edit subtitles in this mode.',
        cz: 'Tento režim umožňuje zobrazení a úpravu rozložení titulků.',
        pl: 'W tym trybie możesz wyświetlać i edytować napisy.',
        hr: 'U  ovom načinu rada titlove možete prikazati i uređivati.',
        si: 'V tem načinu lahko prikazujete in urejate podnapise.',
        de: 'In diesem Modus können Sie Untertitel anzeigen und bearbeiten.',
        sk: 'Tento režim umožňuje zobraziť a upraviť rozloženie titulkov.',
        es: 'Puede visualizar y editar subtítulos en este modo.',
        ru: 'В этом режиме вы можете отображать и редактировать субтитры.',
        rs: 'Možete da prikažete i izmenite titlove u ovom modu.',
        ua: 'У цьому режимі можна відображати та редагувати субтитри.',
        no: 'Du kan vise og redigere undertekster i denne modusen.',
        it: 'Questa modalità consente di visualizzare e modificare il layout dei sottotitoli.',
        se: 'Undertexter kan visas och redigeras i detta läge.',
    },
    warningSettings: {
        us: 'Warning settings',
        cz: 'Nastavení varování',
        pl: 'Ustawienia ostrzeżeń',
        hr: 'Postavke upozorenja',
        si: 'Nastavitve opozorila',
        de: 'Warneinstellungen',
        sk: 'Nastavenia varovania',
        es: 'Configuración de advertencia',
        ru: 'Настройки предупреждений',
        rs: 'Podešavanja upozorenja',
        ua: 'Налаштування попереджень',
        no: 'Advarsel-innstillinger',
        it: 'Impostazioni di avviso',
        se: 'Varningsinställningar',
    },
    switchToTranscription: {
        us: 'Switch back to transcription',
        cz: 'Vrátit zpět na přepis',
        pl: 'Wróć do transkrypcji',
        hr: 'Povratak na transkripciju',
        si: 'Preklopi nazaj na transkripcijo',
        de: 'Zurück zur Transkription',
        sk: 'Vrátiť sa späť na prepis',
        es: 'Volver a la transcripción',
        ru: 'Вернуться к транскрипции',
        rs: 'Vratite na transkripciju',
        ua: 'Повернутися до транскрипції',
        no: 'Gå tilbake til transkripsjon',
        it: 'Torna alla trascrizione',
        se: 'Gå tillbaka till transkription',
    },
    tutorialSkip: {
        us: 'Skip tutorial',
        cz: 'Přeskočit tutoriál',
        pl: 'Pomiń tutorial',
        hr: 'Preskoči vodič',
        si: 'Preskoči vadnico',
        de: 'Tutorial überspringen',
        sk: 'Preskočiť tutoriál',
        es: 'Saltar el tutorial',
        ru: 'Пропустить обучение',
        rs: 'Preskočite tutorial',
        ua: 'Пропустити урок',
        no: 'Hopp over opplæringen',
        it: 'Saltare il tutorial',
        se: 'Hoppa över handledningen',
    },
    tutorialFinish: {
        us: 'Finish tutorial',
        cz: 'Ukončit tutorial',
        pl: 'Zamknij tutorial',
        hr: 'Dovrši vodič',
        si: 'Dokončaj vadnico',
        de: 'Tutorial beenden',
        sk: 'Ukončiť tutoriál',
        es: 'Terminar tutorial',
        ru: 'Закончить обучение',
        rs: 'Završi tutorial',
        ua: 'Закінчити урок',
        no: 'Avslutte veiledningen',
        it: 'Finire il tutorial',
        se: 'Avsluta handledningen',
    },
    beeyTutorialStartHeading: {
        us: 'Welcome to Beey',
        cz: 'Vítejte v Beey',
        pl: 'Witaj w Beey',
        hr: 'Dobrodošli u Beey',
        si: 'Dobrodošli v Beey',
        de: 'Willkommen bei Beey',
        sk: 'Vitajte v Beey',
        es: 'Bienvenido a Beey',
        ru: 'Добро пожаловать в Beey',
        rs: 'Dobrodošli u Beey',
        ua: 'Ласкаво просимо до Beey',
        no: 'Velkommen til Beey',
        it: 'Benvenuti su Beey',
        se: 'Välkommen till Beey',
    },
    beeyTutorialStartText: {
        us: "We are glad you're with us. During the next couple of minutes we are going to show you how to transcribe your recording or create subtitles.",
        cz: 'Jsme rádi, že jste s námi. Během následujících pár minut vám ukážeme, jak si přepsat vaši nahrávku nebo vytvořit titulky.',
        pl: 'Cieszymy się, że jesteś z nami! W ciągu najbliższych kilku minut pokażemy Ci, jak stranskrybować nagranie lub stworzyć napisy.',
        hr: 'Drago nam je što ste s nama. Tijekom sljedećih nekoliko minuta ćemo Vam pokazati kako transkribirati snimku ili kreirati titlove.',
        si: 'Veseli smo, da ste z nami. V naslednjih nekaj minutah vam bomo pokazali, kako prepisati vaš posnetek ali ustvariti podnapise.',
        de: 'Wir freuen uns, dass Sie bei uns sind. In den nächsten Minuten werden wir Ihnen zeigen, wie Sie Ihre Aufnahme transkribieren oder Untertitel erstellen können.',
        sk: 'Sme radi, že ste s nami. V nasledujúcich minútach vám ukážeme, ako prepisovať nahrávku alebo vytvárať titulky.',
        es: 'Nos alegra que esté con nosotros. Durante los próximos minutos, le mostraremos cómo transcribir su grabación o crear subtítulos.',
        ru: 'Мы рады, что вы с нами. В течение следующих нескольких минут мы покажем вам, как транскрибировать вашу запись или создавать субтитры.',
        rs: 'Drago nam je da ste s nama. Tokom sledećih nekoliko minuta pokazaćemo vam kako transkribujete svoj snimak ili da napravite titlove.',
        ua: 'Ми раді, що ти з нами. Протягом наступних кількох хвилин ми покажемо вам, як транскрибувати ваш запис або створити субтитри.',
        no: 'Takk for at du er her med oss. I løpet av de neste minuttene viser vi deg hvordan du kan transkribere din innspilling og lage undertekster.',
        it: 'Siamo felici che sia con noi. Nei prossimi minuti illustreremo come trascrivere la registrazione o creare i sottotitoli.',
        se: 'Det gläder oss att du är här. Under de närmaste minuterna visar vi dig hur du kan transkribera inspelningar eller skapa undertexter.',
    },
    tutorialStart: {
        us: 'Start the tour',
        cz: 'Zahájit tutoriál',
        pl: 'Uruchom tutorial',
        hr: 'Započnite vodič',
        si: 'Začnite z vadnico',
        de: 'Starten Sie die Tour',
        sk: 'Spustiť tutoriál',
        es: 'Iniciar el recorrido',
        ru: 'Начать тур',
        rs: 'Započnite obilazak',
        ua: 'Почніть екскурсію',
        no: 'Begynne opplæringsprogrammet',
        it: 'Avvio del tutorial',
        se: 'Starta handledning',
    },
    beeyTutorialStepOpen: {
        us: 'Open the demo project.',
        cz: 'Otevřete si demo projekt.',
        pl: 'Otwórz projekt demo.',
        hr: 'Otvorite demo projekt.',
        si: 'Odprite demo projekt.',
        de: 'Öffnen Sie das Demoprojekt.',
        sk: 'Otvorte svoj demo projekt.',
        es: 'Abrir el proyecto de demostración.',
        ru: 'Откройте демонстрационный проект.',
        rs: 'Otvorite demo projekat.',
        ua: 'Відкрийте демонстраційний проект.',
        no: 'Åpn et demo-prosjekt.',
        it: 'Apra il progetto demo.',
        se: 'Öppna demoprojektet.',
    },
    beeyTutorialStepEdit: {
        us: 'Here is your first transcription ready to be edited. Change any word and insert a new line using the key',
        cz: 'Zde je váš první přepis připravený k editaci. Upravte jakékoli slovo a vložte nový řádek klávesou',
        pl: 'Oto pierwsza transkrypcja gotowa do edycji. Edytuj dowolne słowo i wstaw nowy wiersz za pomocą klawisza',
        hr: 'Ovo je vaš prvi transkript spreman za uređivanje. Promijenite bilo koju riječ i umetnite novi redak pomoću ključa',
        si: 'Tukaj je vaš prvi prepis, ki je pripravljen za urejanje. Spremenite katero koli besedo in vstavite novo vrstico s ključem',
        de: 'Hier ist Ihre erste Transkription, die Sie bearbeiten können. Ändern Sie ein beliebiges Wort und fügen Sie eine neue Zeile ein, mit der Taste',
        sk: 'Tu je váš prvý prepis pripravený k editácii. Upravte ľubovoľné slovo a vložte nový riadok pomocou',
        es: 'Aquí tienes tu primera transcripción lista para ser editada. Cambie cualquier palabra e inserte una nueva línea con la clave',
        ru: 'Вот ваша первая транскрипция, готовая к редактированию. Измените любое слово и вставьте новую строку с помощью клавиши',
        rs: 'Evo vaše prve transkripcije spremne za uređivanje. Promenite bilo koju reč i umetnite novi red koristeći taster',
        ua: 'Ось ваш перший транскрипт, готовий до редагування. Змініть будь-яке слово і вставте новий рядок за допомогою клавіші',
        no: 'Her er din første transkripsjon klar for editering. Endre et ord og sett inn en ny linje ved å bruke tasten',
        it: 'Ecco la prima trascrizione pronta per la modifica. Modificare una parola qualsiasi e inserire una nuova riga con il simbolo',
        se: 'Här är din första transkription som är redo att editeras. Ändra ett valfritt ord och infoga en ny rad med hjälp av tangenten',
    },
    beeyTutorialTip: {
        us: 'The text can be edited already during transcription!',
        cz: 'Text lze editovat už v průběhu přepisu!',
        pl: 'Tekst możesz edytować już w trakcie transkrypcji!',
        hr: 'Tekst se može uređivati već tijekom transkripcije!',
        si: 'Besedilo lahko urejate že med prepisovanjem!',
        de: 'Text kann bereits während der Transkription bearbeitet werden!',
        sk: 'Text môžete upravovať aj počas prepisu!',
        es: '¡El texto ya se puede editar durante la transcripción!',
        ru: 'Текст можно редактировать уже во время транскрипции!',
        rs: 'Tekst može biti promenjen već tokom transkripcije!',
        ua: 'Текст можна редагувати вже під час транскрипції!',
        no: 'Teksten kan redigeres allerede i løpet av transkripsjonen!',
        it: 'Il testo può essere modificato durante la trascrizione!',
        se: 'Texten kan redigeras redan under transkriptionen!',
    },
    beeyTutorialStepPlay: {
        us: 'Play audio from cursor (or press Shift + Tab) to listen to the part you edited last.',
        cz: 'Přehrajte audio od kurzoru (nebo stiskněte Shift + Tab) a poslechněte si část, kterou jste naposledy editovali.',
        pl: 'Odtwórz dźwięk od kursora (lub naciśnij Shift + Tab) i posłuchaj fragmentu, który ostatnio edytowałeś.',
        hr: 'Reproducirajte zvuk od pokazivača (ili pritisnite Shift + Tab) za slušanje dijela koji ste zadnji uredili.',
        si: 'Predvajajte zvok s kazalca (ali pritisnite Shift + Tab), da poslušate del, ki ste ga nazadnje uredili.',
        de: 'Spielen Sie das Audio vom Cursor ab (oder drücken Sie Shift + Tab), um den Teil anzuhören, den Sie zuletzt bearbeitet haben.',
        sk: 'Prehrajte audio od kurzora (alebo stlačte Shift + Tab) a vypočujte si časť, ktorú ste naposledy upravovali.',
        es: 'Reproducir audio desde el cursor (o presiona Shift + Tab) para escuchar la parte que editaste por última vez.',
        ru: 'Воспроизведите звук от курсора (или нажмите Shift + Tab) и прослушайте ту часть, которую вы редактировали в последний раз.',
        rs: 'Reprodukujte zvuk od kursora (ili pritisnite Shift + Tab) da biste poslušali deo koji ste poslednji put izmenili.',
        ua: 'Відтворіть аудіо з курсору (або натисніть клавіші Shift + Tab), щоб прослухати частину, яку ви редагували останньою.',
        no: 'Spill lyd fra markøren (eller trykk Shift + Tab) og hør på den delen du editerte sist.',
        it: "Avviare l'audio dal cursore (o premere Shift + Tab) per ascoltare l'ultima parte lavorata.",
        se: 'Spela upp ljudet från markören (eller tryck på Shift + Tab) och lyssna på den del som senast redigerats.',
    },
    beeyTutorialStepSpeakerName: {
        us: "Click on a speaker you want to change, write the new speaker's name and then press Replace.",
        cz: 'Klikněte na mluvčího, kterého chcete editovat, napište jméno nového mluvčího a pak stiskněte Nahradit.',
        pl: 'Kliknij na mówcę, którego chcesz edytować, wpisz nazwę nowego mówcy, a następnie naciśnij przycisk Zamień.',
        hr: 'Kliknite na govornika kojeg želite promijeniti, napišite ime novog govornika i zatim pritisnite Zamijeni.',
        si: 'Kliknite na govorca, ki ga želite spremeniti, napišite ime novega govorca in nato pritisnite Zamenjaj.',
        de: 'Klicken Sie auf den Sprecher, den Sie ändern möchten, schreiben Sie den Namen des neuen Sprechers und drücken Sie dann auf Ersetzen.',
        sk: 'Kliknite na rečníka, ktorého časť chcete upraviť, zadajte názov nového rečníka a potom stlačte Nahradiť.',
        es: 'Haz clic en el orador que deseas cambiar, escribe el nombre del nuevo orador y luego presiona Reemplazar.',
        ru: 'Нажмите на спикера, которого хотите исправить, напишите имя нового спикера, а потом нажмите Заменить',
        rs: 'Kliknite na govornika kojeg želite promeniti, napišite novo ime govornika i zatim pritisnite Zameni.',
        ua: "Натисніть на диктора, якого ви хочете замінити, введіть ім'я нового диктора, а потім натисніть Замінити.",
        no: 'Klikk på taleren du vil editere, skriv navnet på den nye taleren og trykk Erstatte.',
        it: 'Cliccare sullo speaker da modificare, scrivere il nuovo nome e poi premere Replace.',
        se: 'Tryck på talaren som du vill redigera, skriv namnet på den nya talaren och sedan tryck på Ersätta.',
    },
    beeyTutorialStepSubtitles: {
        us: 'You are nearly done. Now just try downloading the transcription as subtitles.',
        cz: 'Jste skoro u konce. Nyní si už jen zkuste přepis stáhnout jako titulky.',
        pl: 'Prawie gotowe. Wystarczy teraz pobrać transkrypcję w formie napisów.',
        hr: 'Uskoro ste gotovi. Sada samo pokušajte preuzeti transkripciju kao titlove.',
        si: 'Skoraj ste končali. Zdaj samo poskusite prenesti prepis kot podnapise.',
        de: 'Sie sind fast fertig. Versuchen Sie nun einfach, die Transkription als Untertitel herunterzuladen.',
        sk: 'Ste takmer hotoví. Teraz už len vyskúšajte stiahnuť prepis ako titulky.',
        es: 'Ya casi has terminado. Ahora intenta descargar la transcripción como subtítulos.',
        ru: 'Вы почти закончили. Осталось скачать субтитры.',
        rs: 'Sada ste skoro gotovi. Probajte preuzeti transkripciju kao titlove.',
        ua: 'Ви майже закінчили. Тепер просто спробуйте завантажити транскрипцію як субтитри.',
        no: 'Du er nesten ferdig. Prøv nå å laste ned transkripsjonen som undertekst.',
        it: 'Avete quasi finito. Ora provate a scaricare la trascrizione come sottotitoli.',
        se: 'Du är nästan färdig. Nu är det bara att ladda ned transkriptionen som undertexter.',
    },
    beeyTutorialStepChooseSubs: {
        us: 'Select subtitles from the transcription download formats.',
        cz: 'Mezi možnostmi formátu staženého přepisu vyberte titulky.',
        pl: 'Spośród formatów transkrypcji do pobrania wybierz format napisów.',
        hr: 'Odaberite titlove iz formata za preuzimanje transkripcije.',
        si: 'Izberite podnapise iz formatov prenosa prepisa.',
        de: 'Wählen Sie Untertitel aus den Transkriptions-Downloadformaten aus.',
        sk: 'V možnostiach formátu stiahnutého prepisu vyberte titulky.',
        es: 'Selecciona subtítulos de los formatos de descarga de la transcripción.',
        ru: 'Выберите субтитры из загруженных параметров формата расшифровки.',
        rs: 'Izaberite titlove iz formata za preuzimanje transkripcije.',
        ua: 'Виберіть субтитри з переліку форматів для завантаження транскрипції.',
        no: 'Velg undertekster blant de mulige nedlastbare formatene.',
        it: 'Selezionare sottotitoli dai formati di scaricamento della trascrizione.',
        se: 'Välj undertexter bland den nedladdade transkriptionens formatalternativ.',
    },
    beeyTutorialStepChooseFormat: {
        us: 'Simply choose your preferred format and press Download.',
        cz: 'Zvolte váš preferovaný formát a stiskněte Stáhnout.',
        pl: 'Wybierz preferowany format i naciśnij Pobierz.',
        hr: 'Jednostavno odaberite željeni format i pritisnite Preuzmi.',
        si: 'Preprosto izberite želeno obliko in pritisnite Prenesi.',
        de: 'Wählen Sie einfach Ihr bevorzugtes Format und drücken Sie auf Herunterladen.',
        sk: 'Vyberte preferovaný formát a stlačte Stiahnuť.',
        es: 'Simplemente elige tu formato preferido y presiona Descargar.',
        ru: 'Выберите предпочитаемый формат и нажмите «Загрузить».',
        rs: 'Jednostavno izaberite željeni format i pritisnite Preuzmi.',
        ua: 'Просто виберіть потрібний формат і натисніть Завантажити.',
        no: 'Velg ditt foretrukne format og trykk Laste ned.',
        it: 'Scegliete il formato preferito e premete Download.',
        se: 'Välj önskat format och tryck på Ladda ned.',
    },
    beeyTutorialFinalHeading: {
        us: 'You have finished!',
        cz: 'Máte hotovo!',
        pl: 'I gotowe!',
        hr: 'Završili ste!',
        si: 'Končali ste!',
        de: 'Sie haben es geschafft!',
        sk: 'Máte hotovo!',
        es: '¡Ha terminado!',
        ru: 'Вы закончили!',
        rs: 'Završili ste!',
        ua: 'Ви закінчили!',
        no: 'Du er ferdig!',
        it: 'Ha finito!',
        se: 'Klart och färdigt!',
    },
    beeyTutorialFinalStep: {
        us: 'Now you can start transcribing on your own.',
        cz: 'Teď se můžete pustit do vlastního přepisování.',
        pl: 'Teraz możesz zacząć transkrybować na własną rękę.',
        hr: 'Sada možete početi transkribirati sami.',
        si: 'Zdaj lahko začnete sami prepisovati.',
        de: 'Jetzt können Sie selbst mit Transkribieren beginnen.',
        sk: 'Teraz môžete začať prepisovať sami.',
        es: 'Ahora puede comenzar a transcribir por su cuenta.',
        ru: 'Теперь вы можете начать транскрипцию самостоятельно.',
        rs: 'Sada možete početi sami da transkribujete.',
        ua: 'Тепер ви можете почати транскрибувати самостійно.',
        no: 'Nå kan du begynne å transkribere på egenhånd.',
        it: 'Ora può iniziare la sua trascrizione.',
        se: 'Nu kan du börja transkribera på egen hand.',
    },
    beeyTutorialFinishButton: {
        us: 'Transcribe my own project',
        cz: 'Přepsat vlastní projekt',
        pl: 'Transkrybuj własny projekt',
        hr: 'Transkribiraj vlastiti projekt',
        si: 'Prepiši lastni projekt',
        de: 'Mein eigenes Projekt transkribieren',
        sk: 'Prepísať vlastný projekt',
        es: 'Transcribir mi propio proyecto',
        ru: 'Расшифровать мой собственный проект',
        rs: 'Transkribuj moj projekat',
        ua: 'Транскрибувати мій власний проект',
        no: 'Transkribere mitt eget prosjekt',
        it: 'Trascrivere il proprio progetto',
        se: 'Transkribera mitt eget projekt',
    },
    beeyTutorialTranscribeLater: {
        us: 'Transcribe later',
        cz: 'Přepsat později',
        pl: 'Transkrybuj później',
        hr: 'Transkribiraj kasnije',
        si: 'Prepiši pozneje',
        de: 'Später transkribieren',
        sk: 'Prepísať neskôr',
        es: 'Transcribir más tarde',
        ru: 'Расшифровать позже',
        rs: 'Transkribujte kasnije',
        ua: 'Транскрибувати пізніше',
        no: 'Transkribere senere',
        it: 'Trascrivere in seguito',
        se: 'Transkribera senare',
    },
    addBeeyTutorial: {
        us: 'Add user tutorial',
        cz: 'Přidat uživatelský tutoriál',
        pl: 'Dodaj tutorial użytkownika',
        hr: 'Dodaj korisničkog vodiča',
        si: 'Dodaj navodila za uporabnika',
        de: 'Benutzer-Tutorial hinzufügen',
        sk: 'Pridanie používateľského tutoriálu',
        es: 'Añadir tutorial de usuario',
        ru: 'Добавить руководство пользователя',
        rs: 'Dodajte tutorial za korisnike',
        ua: 'Додати посібник користувача',
        no: 'Legg til et opplæringsprogram',
        it: "Aggiungere un tutorial per l'utente",
        se: 'Lägg till användarhandledning',
    },
    bugReport: {
        us: 'Bug report',
        cz: 'Nahlásit problém',
        pl: 'Zgłoś problem',
        hr: 'Prijava programske greške',
        si: 'Poročilo o napaki',
        de: 'Fehlerbericht',
        sk: 'Nahlásiť problém',
        es: 'Informe de error',
        ru: 'Отчет об ошибке',
        rs: 'Prijava greške',
        ua: 'Повідомлення про помилку',
        no: 'Feilrapport',
        it: 'Segnalazione di un problema',
        se: 'Felrapport',
    },
    tutorials: {
        us: 'Tutorials',
        cz: 'Tutoriály',
        pl: 'Tutoriale',
        hr: 'Vodiči',
        si: 'Vadnice',
        de: 'Tutorials',
        sk: 'Tutoriály',
        es: 'Tutoriales',
        ru: 'Учебники',
        rs: 'Tutoriali',
        ua: 'Підручники',
        no: 'Opplæringsprogrammer',
        it: 'Tutorial',
        se: 'Handledningar',
    },
    tutorialsLink: {
        us: 'https://www.beey.io/en/tutorials/',
        cz: 'https://www.beey.io/cs/tutorialy/',
        pl: 'https://www.beey.io/pl/tutoriale/',
        hr: 'https://www.beey.io/hr/tutorijali/',
        si: 'https://www.beey.io/en/tutorials/',
        de: 'https://www.beey.io/de/anleitungen/',
        sk: 'https://www.beey.io/cs/tutorialy/',
        es: 'https://www.beey.io/en/tutorials/',
        ru: 'https://www.beey.io/ru/instrukczii/',
        rs: 'https://www.beey.io/en/tutorials/',
        ua: 'https://www.beey.io/en/tutorials/',
        no: 'https://www.beey.io/en/tutorials/',
        it: 'https://www.beey.io/en/tutorials/',
        se: 'https://www.beey.io/en/tutorials/',
    },
    faqsLink: {
        us: 'https://www.beey.io/en/faqs/',
        cz: 'https://www.beey.io/cs/faq/',
        pl: 'https://www.beey.io/en/faqs/',
        hr: 'https://www.beey.io/en/faqs/',
        si: 'https://www.beey.io/en/faqs/',
        de: 'https://www.beey.io/de/faq/',
        sk: 'https://www.beey.io/cs/faq/',
        es: 'https://www.beey.io/en/faqs/',
        ru: 'https://www.beey.io/en/faqs/',
        rs: 'https://www.beey.io/en/faqs/',
        ua: 'https://www.beey.io/en/faqs/',
        no: 'https://www.beey.io/en/faqs/',
        it: 'https://www.beey.io/en/faqs/',
        se: 'https://www.beey.io/en/faqs/',
    },
    transcriptionProgress: {
        us: 'Transcription progress:',
        cz: 'Stav přepisu:',
        pl: 'Status transkrypcji:',
        hr: 'Napredak transkripcije:',
        si: 'Napredek prepisa:',
        de: 'Fortschritt der Transkription:',
        sk: 'Priebeh prepisu:',
        es: 'Progreso de la transcripción:',
        ru: 'Прогресс транскрипции:',
        rs: 'Progres transkripcije:',
        ua: 'Хід перекладу:',
        no: 'Transkripsjon forløp:',
        it: 'Stato della trascrizione:',
        se: 'Transkriptionens tillstånd:',
    },
    downloadSubtitles: {
        us: 'Download as subtitles',
        cz: 'Stáhnout jako titulky',
        pl: 'Pobierz jako napisy',
        hr: 'Preuzmi kao titlove',
        si: 'Prenesi kot podnapise',
        de: 'Als Untertitel herunterladen',
        sk: 'Stiahnuť ako titulky',
        es: 'Descargar como subtítulos',
        ru: 'Скачать субтитры',
        rs: 'Preuzmi kao titlove',
        ua: 'Завантажити як субтитри',
        no: 'Laste ned som undertekst',
        it: 'Scaricare come sottotitoli',
        se: 'Ladda ned som undertexter',
    },
    subtitlerMaxLineCount: {
        us: 'Maximum number of lines:',
        cz: 'Maximální počet řádků:',
        pl: 'Maksymalna liczba wierszy:',
        hr: 'Maksimalna duljina retka:',
        si: 'Maksimalna dolžina vrstice:',
        de: 'Maximale Zeilenlänge:',
        sk: 'Maximálna dĺžka riadku:',
        es: 'La longitud máxima de la línea:',
        ru: 'Максимальная длина строки:',
        rs: 'Maksimalna dužina linije:',
        ua: 'Максимальна довжина рядка:',
        no: 'Maksimal linjelengden:',
        it: 'Numero massimo di righe:',
        se: 'Maximalt antal rader:',
    },
    line: {
        us: 'line',
        cz: 'řádek',
        pl: 'wiersz',
        hr: 'redak',
        si: 'vrstica',
        de: 'Zeile',
        sk: 'riadok',
        es: 'línea',
        ru: 'строка',
        rs: 'linija',
        ua: 'рядок',
        no: 'linje',
        it: 'riga',
        se: 'rad',
    },
    twoLines: {
        us: 'lines',
        cz: 'řádky',
        pl: 'wiersze',
        hr: 'redci',
        si: 'vrstice',
        de: 'Zeilen',
        sk: 'riadky',
        es: 'líneas',
        ru: 'строки',
        rs: 'linije',
        ua: 'рядки',
        no: 'linjer',
        it: 'righe',
        se: 'rader',
    },
    showToolbar: {
        us: 'Show toolbar',
        cz: 'Zobrazit lištu',
        pl: 'Pokaż pasek narzędzi',
        hr: 'Pokaži alatnu traku',
        si: 'Pokaži orodno vrstico',
        de: 'Toolbar anzeigen',
        sk: 'Zobraziť panel nástrojov',
        es: 'Mostrar barra de herramientas',
        ru: 'Показать панель инструментов',
        rs: 'Pokaži alatke',
        ua: 'Показати панель інструментів',
        no: 'Vise verktøylinjen',
        it: 'Mostra la barra degli strumenti',
        se: 'Visa verktygsfält',
    },
    hideToolbar: {
        us: 'Hide toolbar',
        cz: 'Skrýt lištu',
        pl: 'Ukryj pasek narzędzi',
        hr: 'Sakrij alatnu traku',
        si: 'Skrij orodno vrstico',
        de: 'Toolbar ausblenden',
        sk: 'Skryť panel nástrojov',
        es: 'Esconder barra de herramientas',
        ru: 'Скрыть панель инструментов',
        rs: 'Sakrij alatke',
        ua: 'Приховати панель інструментів',
        no: 'Gjemme verktøylinjen',
        it: 'Nascondi barra degli strumenti',
        se: 'Dölj verktygsfält',
    },
    editing: {
        us: 'editing',
        cz: 'editace',
        pl: 'edytowanie',
        hr: 'uređivanje',
        si: 'urejanje',
        de: 'Bearbeitung',
        sk: 'editácia',
        es: 'edición',
        ru: 'редактирование',
        rs: 'uređivanje',
        ua: 'редагування',
        no: 'redigering',
        it: 'modifiche',
        se: 'redigering',
    },
    basicShortcuts: {
        us: 'basic shortcuts',
        cz: 'základní zkratky',
        pl: 'podstawowe skróty klawiszowe',
        hr: 'osnovni prečaci',
        si: 'osnovne bližnjice',
        de: 'Grundkürzel',
        sk: 'základné skratky',
        es: 'atajos básicos',
        ru: 'основные ярлыки',
        rs: 'osnovne prečice',
        ua: 'основні комбінації клавіш',
        no: 'grunnleggende snarveier',
        it: 'scorciatoie di base',
        se: 'grundläggande kortkommandon',
    },
    mediaControls: {
        us: 'media controls',
        cz: 'ovládání přehrávače',
        pl: 'sterowanie odtwarzaczem',
        hr: 'kontrola medija',
        si: 'kontrola medijev',
        de: 'Mediensteuerung',
        sk: 'ovládacie prvky médií',
        es: 'controles del reproductor multimedia',
        ru: 'элементы управления мультимедиа',
        rs: 'kontrole medija',
        ua: 'елементи керування мультимедіа',
        no: 'media kontroll',
        it: 'controllo dei media',
        se: 'mediekontroller',
    },
    and: {
        us: 'and',
        cz: 'a',
        pl: 'i',
        hr: 'i',
        si: 'in',
        de: 'und',
        sk: 'a',
        es: 'y',
        ru: 'и',
        rs: 'i',
        ua: 'і',
        no: 'og',
        it: 'e',
        se: 'och',
    },
    soundWave: {
        us: 'sound wave',
        cz: 'zvuková vlna',
        pl: 'fala dźwiękowa',
        hr: 'zvučni val',
        si: 'zvočni val',
        de: 'Schallwelle',
        sk: 'zvuková vlna',
        es: 'onda sonora',
        ru: 'звуковая волна',
        rs: 'jačina zvuka',
        ua: 'звукова хвиля',
        no: 'lydbølge',
        it: 'onda sonora',
        se: 'ljudvåg',
    },
    timeStamps: {
        us: 'time stamps',
        cz: 'časové značky',
        pl: 'znaczniki czasowe',
        hr: 'vremenske oznake',
        si: 'časovni žigi',
        de: 'Zeitstempel',
        sk: 'časové značky',
        es: 'marcas de tiempo',
        ru: 'временные метки',
        rs: 'vremenske oznake',
        ua: 'мітки часу',
        no: 'tidsstempel',
        it: 'timbri temporali',
        se: 'tidsstämplar',
    },
    enablePresegmentation: {
        us: 'Split subtitles for manual editing:',
        cz: 'Dělení titulků k ruční editaci:',
        pl: 'Podziel napisy w celu ich ręcznej edycji:',
        hr: 'Razdvoji titlove za ručno uređivanje:',
        si: 'Razdeli podnapise za ročno urejanje:',
        de: 'Teilen der Untertitel für manuelle Bearbeitung:',
        sk: 'Delenie titulkov pre ručnú editáciu:',
        es: 'Subtítulos divididos para edición manual:',
        ru: 'Разделение субтитров для ручного редактирования:',
        rs: 'Razdvoj titlove za ručno uređivanje:',
        ua: 'Розбиття субтитрів для ручного редагування:',
        no: 'Deler undertekstene for manuell redigering',
        it: 'Dividere i sottotitoli per la modifica manuale:',
        se: 'Dela upp undertexterna för manuell redigering:',
    },
    enablePresegmentationTooltip: {
        us: 'Stylistically and semantically more exact splitting but can exceed max. subtitle length.',
        cz: 'Stylisticky a významově přesnější dělení, které může přesáhnout max. délku titulku.',
        pl: 'Podział dokładniejszy stylistycznie i semantycznie, ale maksymalna długość napisów może zostać przekroczona.',
        hr: 'Stilistički i semantički preciznije razdjeljivanje, ali može premašiti maks. duljinu titla.',
        si: 'Stilsko in semantično natančnejša delitev, vendar lahko presega maks. dolžino podnapisa.',
        de: 'Stilistisch und semantisch genauere Aufteilung, kann aber die max. Untertitellänge überschreiten.',
        sk: 'Štylisticky a sémanticky presnejšie delenie, ktoré môže prekročiť max. dĺžku titulku.',
        es: 'División estilística y semánticamente más exacta, pero puede exceder la longitud máxima de los subtítulos.',
        ru: 'Стилистически и семантически более точное разделение, но может превышать максимальную длину субтитров.',
        rs: 'Stilski i semantički preciznije razdeljivanje, ali može premašiti maksimalnu dužinu titla.',
        ua: 'Стилістично та семантично точніше розділення, але може перевищувати максимальну довжину субтитрів.',
        no: 'Stilistisk og semantisk mer presis deling som kan overstige maksimumslengden av undertekstlengden.',
        it: 'Suddivisione stilisticamente e semanticamente più precisa, che può superare la lunghezza massima del sottotitolo.',
        se: 'Stilistiskt och semantiskt mer precis uppdelning som kan överstiga den maximala längden på undertexten.',
    },
    speakerSignPlacement: {
        us: 'Speaker sign placement:',
        cz: 'Umístění značek mluvčího:',
        pl: 'Oznaczenie zmiany mówcy w napisach:',
        hr: 'Položaj znaka govornika:',
        si: 'Postavitev znaka govorca:',
        de: 'Platzierung von Sprecherzeichen:',
        sk: 'Umiestnenie značiek rečníkov:',
        es: 'Colocación de la marca de hablante:',
        ru: 'Размещение знаков диктора:',
        rs: 'Položaj znaka govornika:',
        ua: 'Розміщення знаків диктора:',
        no: 'Plasering av taler-tegnet:',
        it: "Posizionamento dell'etichetta dello speaker:",
        se: 'Placering av talartecken:',
    },
    utteranceStartOnly: {
        us: 'On every utterance start',
        cz: 'Na začátku každé promluvy',
        pl: 'Na początku każdego wystąpienia',
        hr: 'Na početku svakog iskaza',
        si: 'Na vsakem začetku izgovarjanja',
        de: 'Bei jedem Äußerungsbeginn',
        sk: 'Na začiatku každého vypovedania',
        es: 'En cada inicio de enunciado',
        ru: 'В начале каждого высказывания.',
        rs: 'Na početku svakog iskaza',
        ua: 'На початку кожного висловлювання',
        no: 'I begynnelsen av hver replikk',
        it: "All'inizio di ogni discorso",
        se: 'I början av varje replik',
    },
    multiSpeakerCaptionsOnly: {
        us: 'Only in captions containing more than one speaker',
        cz: 'Pouze v titulcích obsahujících více než jednoho mluvčího',
        pl: 'Tylko w napisach zawierających więcej niż jednego mówcę',
        hr: 'Samo u titlovima koji sadrže više od jednog govornika',
        si: 'Samo v podnapisih, ki vsebujejo več kot enega govorca',
        de: 'Nur in Untertiteln mit mehr als einem Sprecher',
        sk: 'Len v titulkoch obsahujúcich viac ako jedného rečníka',
        es: 'Solo en subtítulos que contengan más de un hablante',
        ru: 'Только в субтитрах, содержащих более одного диктора',
        rs: 'Samo u titlovima koji sadrže više od jednog govornika',
        ua: 'Тільки в титрах, що містять більше одного диктора',
        no: 'Kun i undertekstene som inneholder flere enn én taler',
        it: 'Solo nei sottotitoli che contengono più di uno speaker',
        se: 'Endast i undertexter som innefattar flera talare',
    },
    notesDeleted: {
        us: 'Notes were deleted.',
        cz: 'Poznámky byly smazány.',
        pl: 'Notatki zostały usunięte.',
        hr: 'Bilješke su izbrisane.',
        si: 'Opombe so izbrisane.',
        de: 'Anmerkungen wurden gelöscht.',
        sk: 'Poznámky boli vymazané.',
        es: 'Las notas fueron eliminadas.',
        ru: 'Примечания были удалены.',
        rs: 'Beleške su izbrisane.',
        ua: 'Примітки було видалено.',
        no: 'Notater ble slettet.',
        it: 'Le note sono state eliminate.',
        se: 'Noterna har tagits bort.',
    },
    subModeTutorialStartHeading: {
        us: 'You have activated subtitle mode!',
        cz: 'Máte aktivovaný režim editace titulků!',
        pl: 'Aktywowałeś tryb napisów!',
        hr: 'Aktivirali ste način titlovanja!',
        si: 'Aktivirali ste način podnapisov!',
        de: 'Sie haben den Untertitel-Modus aktiviert!',
        sk: 'Aktivovali ste režim editácie titulkov!',
        es: '¡Ha activado el modo de subtítulos!',
        ru: 'Вы активировали режим субтитров!',
        rs: 'Aktivirali ste način titlovanja!',
        ua: 'Ви активували режим субтитрів!',
        no: 'Du har aktivert undertekstmodus!',
        it: 'È stata attivata la modalità di modifica dei sottotitoli!',
        se: 'Du har aktiverat undertextredigeringsläget!',
    },
    subModeTutorialStartText: {
        us: 'In 6 short steps we will show you how to master it.',
        cz: 'V šesti krátkých krocích vám ukážeme jak na něj.',
        pl: 'W 6 krótkich krokach pokażemy Ci, jak z nim pracować.',
        hr: 'U 6 kratkih koraka pokazat ćemo vam kako to svladati.',
        si: 'V 6 kratkih korakih vam bomo pokazali, kako to obvladati.',
        de: 'Wir zeigen Ihnen in 6 kurzen Schritten, wie Sie es meistern können.',
        sk: 'V šiestich krátkych krokoch vám ukážeme ako naňho.',
        es: 'En 6 pasos cortos, le mostraremos cómo dominarlo.',
        ru: 'За 6 коротких шагов мы покажем вам, как им овладеть.',
        rs: 'U 6 kratkih koraka pokazaćemo vam kako to da savladate.',
        ua: 'За 6 коротких кроків ми покажемо вам, як його освоїти.',
        no: 'Vi viser deg hvordan å mestre det i seks korte trinn.',
        it: 'Vi mostriamo come in sei brevi passi.',
        se: 'Vi visar dig hur du ska använda det i sex korta steg.',
    },
    subModeTutorialSplitStep: {
        us: 'Split any subtitle using the subtitle end button ◆ (Ctrl+B).',
        cz: 'Tlačítkem konce titulků ◆ (Ctrl+B) rozdělte jakýkoli titulek na dva.',
        pl: 'Użyj przycisku Koniec napisu ◆ (Ctrl+B), aby podzielić dowolny napis na dwa.',
        hr: 'Razdijelite bilo koji titl pomoću gumba za završetak titla ◆ (Ctrl+B).',
        si: 'Podnaslov razdelite z gumbom za konec podnapisa ◆ (Ctrl+B).',
        de: 'Teilen Sie einen beliebigen Untertitel mit der Untertitel-Endetaste ◆ (Ctrl+B).',
        sk: 'Tlačidlom koniec titulkov ◆ (Ctrl+B) rozdelíte akýkoľvek titulok na dva.',
        es: 'Dividir cualquier subtítulo usando el botón de fin de subtítulos ◆ (Ctrl+B).',
        ru: 'Разделите любой субтитр с помощью кнопки окончания субтитров ◆ (Ctrl+И).',
        rs: 'Razdelite bilo koji titl pomoću dugmeta za završetak titla ◆ (Ctrl+B).',
        ua: 'Розділіть будь-який субтитр за допомогою кнопки закінчення субтитрів ◆ (Ctrl+И).',
        no: 'Del opp en undertekst ved å bruke sluttknappen ◆ (Ctrl+B).',
        it: 'Utilizzare il pulsante di fine sottotitolo ◆ (Ctrl+B) per dividere qualsiasi sottotitolo in due.',
        se: 'Dela upp undertexten med knappen Avsluta undertexten ◆ (Ctrl+B).',
    },
    subModeTutorialSubEnd: {
        us: '◆ sign marks end of a subtitle.',
        cz: 'Symbol ◆ značí konec titulku.',
        pl: 'Symbol ◆ oznacza koniec napisu.',
        hr: 'znak ◆ označava kraj titla.',
        si: 'znak ◆ označuje konec podnaslova.',
        de: 'Das Symbol ◆ markiert das Untertitel-Ende.',
        sk: 'Symbol ◆ označuje koniec titulku.',
        es: 'El símbolo ◆ indica el final del subtítulo.',
        ru: 'Знак ◆ обозначает конец субтитров.',
        rs: '◆ znak označava kraj titla.',
        ua: '◆ знак позначає кінець субтитрів.',
        no: 'Symbolet ◆ setter merke på slutten av en undertekst.',
        it: 'Il simbolo ◆ indica la fine del sottotitolo.',
        se: 'Symbolen ◆ markerar slutet på undertexten.',
    },
    subModeTutorialRedWarning: {
        us: 'Subtitles longer than the max. length have red background.',
        cz: 'Titulky přesahující max. délku jsou značeny červeně.',
        pl: 'Napisy dłuższe niż maksymalna długość mają czerwone tło.',
        hr: 'Titlovi dulji od maks. duljine imaju crvenu pozadinu.',
        si: 'Podnapisi, daljši od maks. dolžine, imajo rdeče ozadje.',
        de: 'Untertitel länger als die max. Länge haben roten Hintergrund.',
        sk: 'Titulky dlhšie ako max. dĺžka majú červené pozadie.',
        es: 'Los subtítulos más largos que la longitud máxima tienen un fondo rojo.',
        ru: 'Субтитры длиннее максимальной длины имеют красный фон.',
        rs: 'Titlovi duži od maksimalne dužine imaju crvenu pozadinu.',
        ua: 'Субтитри довші за максимальну довжину мають червоний фон.',
        no: 'Undertekster som overskrider maksimallengden vises med rød bakgrunn.',
        it: 'I sottotitoli che superano la lunghezza massima sono contrassegnati in rosso.',
        se: 'Undertexter som överstiger den maximala längden visas med röd bakgrund.',
    },
    subModeTutorialYellowWarning: {
        us: 'Subtitles faster than the max. speed have yellow background.',
        cz: 'Titulky s vyšší rychlostí jsou značeny žlutě.',
        pl: 'Napisy szybsze niż maksymalna prędkość mają żółte tło.',
        hr: 'Titlovi brži od maks. brzine imaju žutu pozadinu.',
        si: 'Podnapisi, ki so hitrejši od maks. hitrosti, imajo rumeno ozadje.',
        de: 'Untertitel schneller als die max. Geschwindigkeit haben gelben Hintergrund.',
        sk: 'Titulky rýchlejšie ako max. rýchlosť majú žlté pozadie.',
        es: 'Los subtítulos más rápidos que la velocidad máxima tienen un fondo amarillo.',
        ru: 'Субтитры, скорость которых превышает максимальную, имеют желтый фон.',
        rs: 'Titlovi brži od maksimalne brzine imaju žutu pozadinu.',
        ua: 'Субтитри, швидкість яких перевищує максимальну, мають жовтий фон.',
        no: 'Undertekster som overskrider maksimalfarten vises med gul bakgrunn.',
        it: 'I sottotitoli con velocità eccessiva sono contrassegnati in giallo.',
        se: 'Undertexter som överstiger den maximala hastigheten visas med gul bakgrund.',
    },
    subModeTutorialLightYellowWarning: {
        us: 'Subtitles slightly faster than the max. speed have light yellow background.',
        cz: 'Titulky s mírně vyšší rychlostí jsou značeny světle žlutě.',
        pl: 'Napisy nieznacznie szybsze niż maksymalna prędkość mają jasnożółte tło.',
        hr: 'Titlovi nešto brži od maks. brzine imaju svijetložutu pozadinu.',
        si: 'Podnapisi, ki so nekoliko hitrejši od maks. hitrosti, imajo svetlo rumeno ozadje.',
        de: 'Untertitel etwas schneller als die max. Geschwindigkeit haben hellgelben Hintergrund.',
        sk: 'Titulky mierne rýchlejšie ako max. rýchlosť majú svetložlté pozadie.',
        es: 'Los subtítulos un poco más rápidos que la velocidad máxima tienen un fondo amarillo claro.',
        ru: 'Субтитры, скорость которых немного выше максимальной, имеют светло-желтый фон.',
        rs: 'Titlovi nešto brži od maksimalne brzine imaju svetlo žutu pozadinu.',
        ua: 'Субтитри, швидкість яких трохи перевищує максимальну, мають світло-жовтий фон.',
        no: 'Undertekster som er litt raskere enn maksimalfarten vises med lysegul bakgrunn.',
        it: 'I sottotitoli con velocità leggermente superiore sono contrassegnati in giallo chiaro.',
        se: 'Undertexter som lätt överstiger den maximala hastigheten visas med ljusgul bakgrund.',
    },
    subModeTutorialWaveStep: {
        us: 'Scroll to any subtitle on the sound wave and by dragging move it forward.',
        cz: 'Scrollováním se posuňte k jakémukoliv titulku na zvukové vlně a tažením ho posuňte dopředu.',
        pl: 'Przewiń do dowolnego napisu na fali dźwiękowej i przeciągnij go do przodu.',
        hr: 'Pomaknite se do bilo kojeg titla na zvučnom valu i povlačenjem ga pomaknite naprijed.',
        si: 'Pomaknite se do katerega koli podnapisa na zvočnem valu in ga povlecite naprej.',
        de: 'Scrollen Sie zu einem beliebigen Untertitel auf der Schallwelle und bewegen Sie ihn durch Vorwärtsziehen.',
        sk: 'Prejdite scrollovaním na ľubovoľný titulok na zvukovej vlne a ťahaním ho posuňte dopredu.',
        es: 'Desplácese a cualquier subtítulo en la onda de sonido y, arrastrándolo, muévalo hacia adelante.',
        ru: 'Перейдите к любому субтитру на звуковой волне и перетащите его вперед.',
        rs: 'Pomerite se do bilo kog titla na zvučnom talasu i povlačenjem ga pomerite napred.',
        ua: 'Прокрутіть до будь-якого субтитру на звуковій хвилі і перетягуванням перемістіть його вперед.',
        no: 'Rull til en undertekst på lydbølgen og flytt den fremover med å dra.',
        it: "Scorrere fino a qualsiasi sottotitolo sull'onda sonora e trascinarlo in avanti.",
        se: 'Skrolla igenom ljudvågen till en valfri undertext och flytta den framåt genom att dra.',
    },
    subModeTutorialWaveTip: {
        us: 'To move the sound wave only without playback, hold Shift.',
        cz: 'Pro pohyb na vlně bez audia držte Shift',
        pl: 'Aby przesunąć falę dźwiękową bez odtwarzania dźwięku, przytrzymaj Shift.',
        hr: 'Za pomicanje zvučnog vala bez reprodukcije, zadržite Shift.',
        si: 'Če želite zvočni val premakniti brez predvajanja, pridržite Shift.',
        de: 'Um nur die Schallwelle zu verschieben, ohne Wiedergabe, drücken Sie Shift.',
        sk: 'Pre pohyb iba zvukovej vlny bez prehrávania podržte Shift.',
        es: 'Para mover la onda de sonido solo sin reproducción, mantenga presionada la tecla Shift.',
        ru: 'Чтобы перемещать только звуковую волну без воспроизведения, удерживайте Shift.',
        rs: 'Za pomeranje zvučnog talasa bez reprodukcije, zadržite Shift.',
        ua: 'Щоб переміщати тільки звукову хвилю без відтворення, утримуйте клавішу Shift.',
        no: 'For å flytte lydbølgen uten å spille lyden hold Shift.',
        it: "Per spostarsi su un'onda senza audio, tenere premuto Shift.",
        se: 'För att förflytta dig i ljudvågen utan att spela upp ljudet, håll ned Skift.',
    },
    subModeTutorialZoomStep: {
        us: 'Increase the sound wave size using the Zoom plus button.',
        cz: 'Zvětšte si velikost zvukové stopy tlačítkem Zoom plus.',
        pl: 'Zwiększ rozmiar fali dźwiękowej za pomocą przycisku Zoom plus.',
        hr: 'Povećajte veličinu zvučnog vala pomoću gumba Zoom plus.',
        si: 'Povečajte velikost zvočnega vala z gumbom Zoom plus.',
        de: 'Vergrößern Sie die Schallwelle mit der Zoom Plus Taste.',
        sk: 'Zväčšite veľkosť zvukovej vlny pomocou tlačidla Zoom plus.',
        es: 'Aumente el tamaño de la onda de sonido con el botón Zoom plus.',
        ru: 'Увеличьте размер звуковой волны с помощью кнопки Zoom plus.',
        rs: 'Povećajte veličinu zvučnog talasa pomoću dugmeta Zoom plus.',
        ua: 'Збільшуйте розмір звукової хвилі за допомогою кнопки Zoom plus.',
        no: 'Øk størrelsen av lydbølgen ved å bruke Zoom pluss knappen.',
        it: 'Ingrandire la traccia audio con il pulsante Zoom più.',
        se: 'Ök storleken på ljudvågen med knappen Zoom plus.',
    },
    subModeTutorialDeleteStep: {
        us: 'Delete the stopwatch ⏱ or diamant ◆ sign to undo changes in subtitle timing or splitting.',
        cz: 'Odstraňte symbol stopek ⏱ nebo diamantu ◆ pro zrušení změn v časování a dělení titulku.',
        pl: 'Usuń znak stopera ⏱ lub diamantu ◆, aby cofnąć zmiany w czasowaniu lub podziale napisów.',
        hr: 'Obrišite štopericu ⏱ ili znak dijamanta ◆ kako biste poništili promjene u vremenu titlova ili razdjeljivanju.',
        si: 'Izbrišite štoparico ali znak diamanta ◆, če želite razveljaviti spremembe časovnega razporeda podnapisov ali razdelitve.',
        de: 'Löschen Sie die Stoppuhr ⏱ oder das Diamantzeichen ◆, um Änderungen an Zeitsteuerung oder Aufteilung von Untertiteln zurückzunehmen.',
        sk: 'Odstránením znaku stopiek ⏱ alebo diamantu ◆ zrušíte zmeny v časovaní alebo rozdelení titulkov.',
        es: 'Elimine el cronómetro ⏱ o el signo de diamante ◆ para deshacer los cambios en el tiempo o la división de los subtítulos.',
        ru: 'Удалите секундомер ⏱ или знак диаманта ◆, чтобы отменить изменения во времени субтитров или их разбивку.',
        rs: 'Obrišite štopericu ⏱ ili znak dijamanta ◆ kako biste poništili promene u vremenu titlova ili razdeljivanju.',
        ua: 'Видаліть знак секундоміра ⏱ або діаманта ◆, щоб скасувати зміни в синхронізації або розбитті субтитрів.',
        no: 'Slett stoppeklokkesymbolen ⏱ eller diamantesymbolen ◆ for å angre endringer i tidskoding eller deling av undertekstene.',
        it: 'Rimuovere il simbolo del cronometro ⏱ o del diamante ◆ per annullare le modifiche alla temporizzazione e alla suddivisione dei sottotitoli.',
        se: 'Radera stoppurssymbolen ⏱ eller diamantsymbolen ◆ för att ångra ändringar i tidskodningen eller uppdelningen av undertexterna.',
    },
    subModeTutorialSettingsStep: {
        us: 'You can edit warnings and other parameters anytime in the Subtitle mode settings.',
        cz: 'Varování a další parametry si můžete kdykoli upravit v Nastavení titulkového režimu.',
        pl: 'W każdej chwili możesz dostosować ostrzeżenia i inne parametry w ustawieniach Trybu Napisów.',
        hr: 'Upozorenja i druge parametre možete uređivati u bilo kojem trenutku u postavkama načina titlova.',
        si: 'Opozorila in druge parametre lahko kadar koli uredite v nastavitvah načina podnapisa.',
        de: 'Sie können Warnungen und andere Parameter jederzeit in den Einstellungen des Untertitel-Modus bearbeiten.',
        sk: 'Upozornenia a ďalšie parametre môžete kedykoľvek upraviť v Nastavení režimu titulkov.',
        es: 'Puede editar advertencias y otros parámetros en cualquier momento en la configuración del modo de subtítulos.',
        ru: 'Вы можете редактировать предупреждения и другие параметры в любое время в настройках режима субтитров.',
        rs: 'Upozorenja i druge parametre možete uređivati u bilo kom trenutku u podešavanjima načina titlova.',
        ua: 'Ви можете будь-коли відредагувати попередження та інші параметри в налаштуваннях режиму субтитрів.',
        no: 'Du kan editere varslinger og andre parametrer i Undertekstmodus innstillinger når som helst.',
        it: 'È possibile regolare le avvertenze e altri parametri in qualsiasi momento nelle Impostazioni della modalità sottotitoli.',
        se: 'Du kan redigera varningar och andra parametrar i inställningar av undertextläge när du vill.',
    },
    subModeTutorialChooseSettingsStep: {
        us: 'Select settings and ...',
        cz: 'Vyberte nastavení a ...',
        pl: 'Wybierz ustawienia i ...',
        hr: 'Odaberite postavke a ...',
        si: 'Izberi nastavitve a ...',
        de: 'Einstellungen auswählen und ...',
        sk: 'Vyberte nastavenie a ...',
        es: 'Seleccionar configuraciones a ...',
        ru: 'Выберите настройки а ...',
        rs: 'Izaberite podešavanja...',
        ua: 'Виберіть налаштування a ...',
        no: 'Velg innstillinger og ...',
        it: 'Selezionare Impostazioni e ...',
        se: 'Välj inställningar och ...',
    },
    subModeTutorialSaveSettingsStep: {
        us: '...try editing or switching off some settings and save the changes.',
        cz: '...zkuste si vypnout nebo pozměnit některá nastavení a uložit změny.',
        pl: '...spróbuj zmienić lub wyłączyć niektóre ustawienia i zapisz zmiany.',
        hr: '...pokušajte urediti ili isključiti neke postavke i spremite promjene.',
        si: '...poskusite urediti ali izklopiti nekatere nastavitve in shranite spremembe.',
        de: '...versuchen Sie, einige Einstellungen zu bearbeiten oder auszuschalten, und speichern Sie die Änderungen.',
        sk: '...skúste upraviť alebo vypnúť niektoré nastavenia a uložte zmeny.',
        es: '...intente editar o desactivar algunas configuraciones y guarde los cambios.',
        ru: '...Попробуйте отредактировать или отключить некоторые параметры и сохраните изменения.',
        rs: '...pokušajte da uredite ili isključite neke postavke i sačuvajte promene.',
        ua: '...cпробуйте відредагувати або вимкнути деякі параметри і збережіть зміни.',
        no: '...prøv å editere eller slå av noen innstillinger og lagre endringer.',
        it: '...provate a disattivare o modificare alcune impostazioni e a salvare le modifiche.',
        se: '...prova att redigera eller stänga av vissa inställningar och spara ändringarna.',
    },
    subModeTutorialSaveSettingsTip: {
        us: 'Certain changes require for subtitles to be generated again outside of the tutorial.',
        cz: 'Pro určité změny je třeba přegenerovat titulky mimo tutoriál.',
        pl: 'Niektóre zmiany wymagają ponownego wygenerowania napisów poza tutorialem.',
        hr: 'Određene promjene zahtijevaju da se titlovi ponovno generiraju izvan vodiča.',
        si: 'Nekatere spremembe zahtevajo, da se podnapisi znova ustvarijo zunaj vodiča.',
        de: 'Bei bestimmten Änderungen müssen die Untertitel außerhalb des Tutorials neu erstellt werden.',
        sk: 'Niektoré zmeny vyžadujú opätovné vygenerovanie titulkov mimo tutoriál.',
        es: 'Ciertos cambios requieren que los subtítulos se generen nuevamente fuera del tutorial.',
        ru: 'Некоторые изменения требуют повторной генерации субтитров вне учебника.',
        rs: 'Određene promene zahtevaju da se titlovi ponovno generišu izvan vodiča.',
        ua: 'Деякі зміни потребують повторного створення субтитрів поза межами підручника.',
        no: 'Noen endringer krever at undertekstene er lagret igjen utenfor veiledningen.',
        it: "Per alcune modifiche, potrebbe essere necessario generare i sottotitoli al di fuori dell'esercitazione.",
        se: 'Vissa ändringar kräver att undertexterna genereras igen utanför handledningen.',
    },
    subModeTutorialFinalStep: {
        us: 'Now you can create your own professional subtitles. When you are done editing, download them from the side panel.',
        cz: 'Nyní si můžete vytvořit svoje vlastní profesionální titulky. Až budete hotoví, jednoduše je stáhněte z bočního panelu.',
        pl: 'Teraz możesz stworzyć swoje własne, profesjonalne napisy. Gdy skończysz edycję, pobierz je z panelu bocznego.',
        hr: 'Sada možete izraditi vlastite profesionalne titlove. Kad završite s uređivanjem, preuzmite ih s bočne trake.',
        si: 'Zdaj lahko ustvarite svoje profesionalne podnapise. Ko končate z urejanjem, jih prenesite s stranske vrstice.',
        de: 'Jetzt können Sie Ihre eigenen professionellen Untertitel erstellen. Wenn Sie mit der Bearbeitung fertig sind, können Sie sie über die Seitenleiste herunterladen.',
        sk: 'Teraz si môžete vytvoriť vlastné profesionálne titulky. Po dokončení si ich jednoducho stiahnite z bočného panela.',
        es: 'Ahora usted puede crear sus propios subtítulos profesionales. Cuando haya terminado de editar, descárguelos desde el panel lateral.',
        ru: 'Теперь вы можете создавать собственные профессиональные субтитры. Когда вы закончите редактирование, загрузите их с боковой панели.',
        rs: 'Sada možete da izradite sopstvene profesionalne titlove. Kad završite sa uređivanjem, preuzmite ih s bočne trake.',
        ua: 'Тепер ви можете створювати власні професійні субтитри. Закінчивши редагування, завантажте їх з бічної панелі.',
        no: 'Nå kan du lage dine profesjonelle undertekster. Du kan laste dem ned fra sidepanelet når du er ferdig med redigering.',
        it: 'Ora può creare i suoi sottotitoli professionali. Una volta terminato, è sufficiente scaricarli dalla barra laterale.',
        se: 'Nu kan du skapa dina egna professionella undertexter. När du är klar med editeringen, ladda ned dem från sidofältet.',
    },
    subModeTutorialFinalTip: {
        us: 'You can also play the tutorial again anytime from the side panel.',
        cz: 'Z bočního panelu si také můžete tutoriál kdykoliv pustit znovu.',
        pl: 'Możesz też w każdej chwili odtworzyć tutorial z panelu bocznego.',
        hr: 'Također možete ponovno pokrenuti tutorial u bilo kojem trenutku s bočne trake.',
        si: 'Prav tako lahko kadar koli znova predvajate vadnico s stranske vrstice.',
        de: 'Sie können das Tutorial auch jederzeit über die Seitenleiste erneut abspielen.',
        sk: 'Z bočného panela si tiež môžete tutoriál kedykoľvek prehrať znova.',
        es: 'También puede volver a reproducir el tutorial en cualquier momento desde el panel lateral.',
        ru: 'Вы также можете в любое время воспроизвести учебник с боковой панели.',
        rs: 'Takođe možete ponovno da pokrenete tutorial u bilo kom trenutku s bočne trake.',
        ua: 'Ви також можете будь-коли знову відтворити підручник з бічної панелі.',
        no: 'Du kan spille gjennom veiledningen igjen fra sidepanelet.',
        it: "È inoltre possibile rivedere l'esercitazione in qualsiasi momento dalla barra laterale.",
        se: 'Du kan också spela handledningen igen från sidofältet när du vill.',
    },
    runTutorial: {
        us: 'Run tutorial',
        cz: 'Spustit tutoriál',
        pl: 'Uruchom tutorial',
        hr: 'Pokreni tutorial',
        si: 'Zaženi vadnico',
        de: 'Tutorial ausführen',
        sk: 'Spustiť tutoriál',
        es: 'Iniciar tutorial',
        ru: 'Руководство по запуску',
        rs: 'Pusti tutorial',
        ua: 'Запустіть підручник',
        no: 'Kjøre opplæringsprogrammet',
        it: 'Esecuzione del tutorial',
        se: 'Starta handledning',
    },
    disabledDuringTutorial: {
        us: 'Disabled during tutorial',
        cz: 'Nelze během tutoriálu',
        pl: 'Wyłączone przy uruchomionym tutorialu',
        hr: 'Onemogućeno tijekom vodiča',
        si: 'Onemogočeno med vodičem',
        de: 'Deaktiviert während des Tutorials',
        sk: 'Nie je možné počas tutoriálu',
        es: 'Deshabilitado durante el tutorial',
        ru: 'Инвалид во время обучения',
        rs: 'Onemogućeno tokom vodiča',
        ua: 'Інвалідність під час навчання',
        no: 'Umulig under veiledningen',
        it: "Non è possibile durante l'esercitazione",
        se: 'Inaktiverat under handledningen',
    },
    usersFieldQuestion: {
        us: 'What area of business are you coming from?',
        cz: 'Z jakého jste oboru?',
        pl: 'Z jakiego obszaru działalności pochodzisz?',
        hr: 'Iz kojeg ste područja poslovanja?',
        si: 'S katerega področja poslovanja prihajate?',
        de: 'Aus welchem Business-Bereich kommen Sie?',
        sk: 'V akej oblasti pôsobíte?',
        es: '¿De qué área de negocio viene?',
        ru: 'Из какой сферы бизнеса вы пришли?',
        rs: 'Iz kojeg ste područja poslovanja?',
        ua: 'З якої сфери бізнесу Ви прийшли?',
        no: 'Fra hvilket område av virksomheten er du fra?',
        it: 'In quale settore lavora?',
        se: 'Från vilket verksamhetsfält kommer du?',
    },
    usersOriginQuestion: {
        us: 'Where did you hear about us?',
        cz: 'Jak jste se o nás dozvěděli?',
        pl: 'Gdzie się o nas dowiedziałeś?',
        hr: 'Gdje ste čuli za nas?',
        si: 'Kje ste slišali za nas?',
        de: 'Wo haben Sie von uns gehört?',
        sk: 'Odkiaľ ste sa o nás dozvedeli?',
        es: '¿Dónde se enteró de nosotros?',
        ru: 'Где вы узнали о нас?',
        rs: 'Gde ste čuli za nas?',
        ua: 'Звідки Ви дізналися про нас?',
        no: 'Hvordan fikk du vite om oss?',
        it: 'Come ha saputo di noi?',
        se: 'Hur fick du reda på oss?',
    },
    loginDetails: {
        us: 'Login details',
        cz: 'Přihlašovací údaje',
        pl: 'Dane logowania',
        hr: 'Pristupni podaci',
        si: 'Podrobnosti prijave',
        de: 'Details zum Login',
        sk: 'Prihlasovacie údaje',
        es: 'Detalles de acceso',
        ru: 'Данные для входа в систему',
        rs: 'Pristupni podaci',
        ua: 'Дані для входу',
        no: 'Innloggingsdetaljene',
        it: 'Dettagli di accesso',
        se: 'Inloggningsuppgifter',
    },
    additionalDetails: {
        us: 'Additional info',
        cz: 'Další údaje',
        pl: 'Dodatkowe informacje',
        hr: 'Dodatne informacije',
        si: 'Dodatne informacije',
        de: 'Zusätzliche Informationen',
        sk: 'Ďalšie informácie',
        es: 'Información adicional',
        ru: 'Дополнительная информация',
        rs: 'Dodatne informacije',
        ua: 'Додаткова інформація',
        no: 'Tilleggsinformasjon',
        it: 'Altri dettagli',
        se: 'Ytterligare uppgifter',
    },
    pleaseChooseAnswer: {
        us: 'Please choose an answer',
        cz: 'Vyberte prosím odpověď',
        pl: 'Wybierz odpowiedź',
        hr: 'Molimo odaberite odgovor',
        si: 'Prosimo izberite odgovor.',
        de: 'Bitte wählen Sie eine Antwort',
        sk: 'Vyberte odpoveď',
        es: 'Por favor, elija una respuesta',
        ru: 'Пожалуйста, выберите ответ',
        rs: 'Molimo odaberite odgovor',
        ua: 'Будь ласка, оберіть відповідь',
        no: 'Vennligst velg et svar',
        it: 'Selezionare una risposta',
        se: 'Vänligen välj ett svar',
    },
    backLabel: {
        us: 'Back',
        cz: 'Zpět',
        pl: 'Wstecz',
        hr: 'Natrag',
        si: 'Nazaj',
        de: 'Zurück',
        sk: 'Späť',
        es: 'Atrás',
        ru: 'Назад',
        rs: 'Nazad',
        ua: 'Назад',
        no: 'Tilbake',
        it: 'Indietro',
        se: 'Tillbaka',
    },
    alreadyHaveAccount: {
        us: 'Already have an account?',
        cz: 'Už máte účet?',
        pl: 'Czy masz już konto?',
        hr: 'Već imate račun?',
        si: 'Že imate račun?',
        de: 'Haben Sie bereits ein Konto?',
        sk: 'Už máte účet?',
        es: '¿Ya tiene una cuenta?',
        ru: 'У вас уже есть аккаунт?',
        rs: 'Već imate nalog?',
        ua: 'Вже маєте обліковий запис?',
        no: 'Har du allerede en konto?',
        it: 'Ha già un account?',
        se: 'Har du redan ett konto?',
    },
    enterEmailPassword: {
        us: 'To register, enter your e-mail address and password.',
        cz: 'Pro registraci zadejte svou e-mailovou adresu a heslo.',
        pl: 'Aby się zarejestrować, podaj swój adres e-mail i hasło.',
        hr: 'Za registraciju unesite svoju email adresu i lozinku.',
        si: 'Če se želite registrirati, vnesite svoj e-poštni naslov in geslo.',
        de: 'Um sich zu registrieren, geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein.',
        sk: 'Ak sa chcete zaregistrovať, zadajte svoju e-mailovú adresu a heslo.',
        es: 'Para registrarse, ingrese su dirección de correo electrónico y contraseña.',
        ru: 'Чтобы зарегистрироваться, введите свой адрес электронной почты и пароль.',
        rs: 'Za registraciju unesite svoju email adresu i lozinku.',
        ua: 'Для реєстрації введіть адресу електронної пошти та пароль.',
        no: 'For å lage en konto, fyll ut din e-mail adresse og et passord.',
        it: "Inserisca l'indirizzo e-mail e la password per registrarsi.",
        se: 'För att skapa konto, ange din e-postadress och lösenord.',
    },
    coachingField: {
        us: 'Training / coaching',
        cz: 'Tréning / koučing',
        pl: 'Szkolenie / couching',
        hr: 'Trening / couching',
        si: 'Trening / couching',
        de: 'Training / Couching',
        sk: 'Školenie / koučing',
        es: 'Entrenamiento / coaching',
        ru: 'Обучение / коучинг',
        rs: 'Trening /couching',
        ua: 'Навчання / коучинг',
        no: 'Trening / coaching',
        it: 'Formazione / coaching',
        se: 'Träning / vägledning',
    },
    conferencesField: {
        us: 'Organization of conferences',
        cz: 'Pořádání konferencí',
        pl: 'Organizacja konferencji',
        hr: 'Organizacija konferencija',
        si: 'Organizacija konferenc',
        de: 'Organisation von Konferenzen',
        sk: 'Organizácia konferencií',
        es: 'Organización de conferencias',
        ru: 'Организация конференций',
        rs: 'Organizacija konferencija',
        ua: 'Організація конференцій',
        no: 'Organisasjon av konferenser',
        it: 'Organizzazione delle conferenze',
        se: 'Organisation av konferenser',
    },
    researchField: {
        us: 'Research / education',
        cz: 'Výzkum / vzdělávání',
        pl: 'Badania naukowe / edukacja',
        hr: 'Istraživanje / obrazovanje',
        si: 'Raziskave / izobraževanje',
        de: 'Forschung / Bildung',
        sk: 'Výskum / vzdelávanie',
        es: 'Investigación / educación',
        ru: 'Исследования / образование',
        rs: 'Istraživanje / obrazovanje',
        ua: 'Дослідження / освіта',
        no: 'Forskning / utdanning',
        it: 'Ricerca / istruzione',
        se: 'Forskning / utbildning',
    },
    financesField: {
        us: 'Finance',
        cz: 'Finanční služby',
        pl: 'Finanse',
        hr: 'Financije',
        si: 'Finance',
        de: 'Finanzen',
        sk: 'Financie',
        es: 'Finanzas',
        ru: 'Финансы',
        rs: 'Finansije',
        ua: 'Фінанси',
        no: 'Finanstjenester',
        it: 'Servizi finanziari',
        se: 'Finanstjänster',
    },
    publicAdministrationField: {
        us: 'Public administration',
        cz: 'Státní správa',
        pl: 'Administracja publiczna',
        hr: 'Javna uprava',
        si: 'Javna uprava',
        de: 'Öffentliche Verwaltung',
        sk: 'Verejná správa',
        es: 'Administración Pública',
        ru: 'Государственное управление',
        rs: 'Javna uprava',
        ua: 'Державне управління',
        no: 'Offentlig administrasjon',
        it: 'Pubblica Amministrazione',
        se: 'Offentlig förvaltning',
    },
    mediaField: {
        us: 'Media',
        cz: 'Média',
        pl: 'Media',
        hr: 'Mediji',
        si: 'Mediji',
        de: 'Medien',
        sk: 'Médiá',
        es: 'Medios de comunicación',
        ru: 'СМИ',
        rs: 'Mediji',
        ua: 'Медіа',
        no: 'Media',
        it: 'Media',
        se: 'Media',
    },
    lawField: {
        us: 'Law',
        cz: 'Právo a advokacie',
        pl: 'Prawo',
        hr: 'Pravosuđe',
        si: 'Pravosodje',
        de: 'Rechtswesen',
        sk: 'Právo',
        es: 'Ley',
        ru: 'Право',
        rs: 'Pravosuđe',
        ua: 'Право',
        no: 'Rett',
        it: 'Settore legale',
        se: 'Lagstiftning',
    },
    marketingField: {
        us: 'Marketing and PR',
        cz: 'Marketing a PR',
        pl: 'Marketing i PR',
        hr: 'Marketing i PR',
        si: 'Marketing in PR',
        de: 'Marketing und PR',
        sk: 'Marketing a PR',
        es: 'Marketing y relaciones públicas',
        ru: 'Маркетинг и PR',
        rs: 'Marketing i PR',
        ua: 'Маркетинг та PR',
        no: 'Markedsføring og PR',
        it: 'Marketing e PR',
        se: 'Marknadsföring och PR',
    },
    healthField: {
        us: 'Healthcare',
        cz: 'Zdravotnictví',
        pl: 'Służba zdrowia',
        hr: 'Zdravstvo',
        si: 'Zdravstvo',
        de: 'Gesundheitswesen',
        sk: 'Zdravotníctvo',
        es: 'Ciencias de la salud',
        ru: 'Здравоохранение',
        rs: 'Zdravstvo',
        ua: "Охорона здоров'я",
        no: 'Helse',
        it: 'Assistenza sanitaria',
        se: 'Hälso- och sjukvård',
    },
    nonprofitField: {
        us: 'Non-profit sector',
        cz: 'Neziskový sektor',
        pl: 'Sektor non-profit',
        hr: 'Neprofitni sektor',
        si: 'Nepridobitni sektor',
        de: 'Gemeinnütziger Sektor',
        sk: 'Neziskový sektor',
        es: 'Sector sin ánimo de lucro',
        ru: 'Некоммерческий сектор',
        rs: 'Neprofitni sektor',
        ua: 'Некомерційний сектор',
        no: 'Ideell sektor',
        it: 'Settore non profit',
        se: 'Ideell sektor',
    },
    contentCreationField: {
        us: 'Online content creation',
        cz: 'Tvorba online obsahu',
        pl: 'Tworzenie treści on-line',
        hr: 'Izrada online sadržaja',
        si: 'Ustvarjanje spletnih vsebin',
        de: 'Erstellung von Online-Content',
        sk: 'Tvorba online obsahu',
        es: 'Creación de contenido en línea',
        ru: 'Создание онлайн-контента',
        rs: 'Izrada online sadržaja',
        ua: 'Створення онлайн-контенту',
        no: 'Produksjon av onlineinnhold',
        it: 'Creazione di contenuti online',
        se: 'Produktion av onlineinnehåll',
    },
    humanResourcesField: {
        us: 'Human resources',
        cz: 'Lidské zdroje',
        pl: 'Zasoby ludzkie',
        hr: 'Ljudski potencijali',
        si: 'Človeški viri',
        de: 'Humanressourcen',
        sk: 'Ľudské zdroje',
        es: 'Recursos humanos',
        ru: 'Человеческие ресурсы',
        rs: 'Ljudski resursi',
        ua: 'Управління персоналом',
        no: 'Personalressurser',
        it: 'Risorse umane',
        se: 'Personaladministration',
    },
    studentField: {
        us: 'Student',
        cz: 'Student',
        pl: 'Student',
        hr: 'Student',
        si: 'Študent',
        de: 'Student',
        sk: 'Študenti',
        es: 'Estudiante',
        ru: 'Студенчество',
        rs: 'Student',
        ua: 'Студентський',
        no: 'Student',
        it: 'Studente',
        se: 'Student',
    },
    videoProdField: {
        us: 'TV and video production',
        cz: 'TV a video produkce',
        pl: 'Produkcja TV i wideo',
        hr: 'TV i video produkcija',
        si: 'TV in video produkcija',
        de: 'TV- und Videoproduktion',
        sk: 'Televízna a video produkcia',
        es: 'Producción de televisión y video',
        ru: 'Телевидение и видеопроизводство',
        rs: 'TV i video produkcija',
        ua: 'Теле- та відеовиробництво',
        no: 'TV- og videoproduksjon',
        it: 'Produzione televisiva e video',
        se: 'TV- och videoproduktion',
    },
    other: {
        us: 'Other',
        cz: 'Jiné',
        pl: 'Inne',
        hr: 'Drugo',
        si: 'Drugo',
        de: 'Sonstiges',
        sk: 'Iné',
        es: 'Otro',
        ru: 'Другие',
        rs: 'Drugo',
        ua: 'Інше',
        no: 'Andre',
        it: 'Altro',
        se: 'Övrigt',
    },
    searchEngineOrigin: {
        us: 'From a search engine (Google, Bing etc.)',
        cz: 'Ve vyhledávači (Google, Seznam atp.)',
        pl: 'Z wyszukiwarki (Google, Bing itp.)',
        hr: 'Iz pretraživača (Google, Bing, itd.)',
        si: 'Iz iskalnika (Google, Bing itd.)',
        de: 'Von einer Suchmaschine (Google, Bing usw.)',
        sk: 'Z vyhľadávača (Google, Bing atď.)',
        es: 'De un buscador (Google, Bing etc.)',
        ru: 'Из поисковой системы (Google, Bing и т.д.)',
        rs: 'Iz pretraživača (Google, Bing, itd.)',
        ua: 'З пошукової системи (Google, Bing тощо)',
        no: 'Fra en søkemotor (Google, Bing, osv.)',
        it: 'In un motore di ricerca (Google, Bing, ecc.)',
        se: 'Från en sökmotor (Google, Bing m. fl.)',
    },
    articleOrigin: {
        us: 'From an internet article',
        cz: 'V článku na internetu',
        pl: 'Z artykułu w Internecie',
        hr: 'Internetski članak',
        si: 'Iz spletnega članka',
        de: 'Aus einem Internetartikel',
        sk: 'Z internetového článku',
        es: 'De un artículo de internet',
        ru: 'Из статьи в Интернете',
        rs: 'Članak na internetu',
        ua: 'Зі статті в інтернеті',
        no: 'Fra en nettartikkel',
        it: 'In un articolo su Internet',
        se: 'Från en artikel på internet',
    },
    adOrigin: {
        us: 'From an ad',
        cz: 'Z reklamy',
        pl: 'Z reklamy',
        hr: 'Iz oglasa',
        si: 'Iz spletnega članka',
        de: 'Aus einer Anzeige',
        sk: 'Z reklamy',
        es: 'De un anuncio',
        ru: 'Из рекламы',
        rs: 'Iz oglasa',
        ua: 'З рекламного оголошення',
        no: 'Fra en reklame',
        it: 'Dalla pubblicità',
        se: 'Från en reklam',
    },
    socialNetworkOrigin: {
        us: 'From a social network',
        cz: 'Na sociálních sítích',
        pl: 'Z sieci społecznościowej',
        hr: 'S društvene mreže',
        si: 'Iz družabnega omrežja',
        de: 'Aus sozialen Netzwerke',
        sk: 'Zo sociálnej siete',
        es: 'De una red social',
        ru: 'Из социальной сети',
        rs: 'S društvene mreže',
        ua: 'З соціальної мережі',
        no: 'Fra sosiale medier',
        it: 'Sui social network',
        se: 'Från sociala medier',
    },
    acquaintanceOrigin: {
        us: 'From an acquaintance / friend',
        cz: 'Doporučení od známých',
        pl: 'Rekomendacja od znajomych',
        hr: 'Od poznanika / prijatelja',
        si: 'Od znanca / prijatelja',
        de: 'Von einem Bekannten / Freund',
        sk: 'Od známeho/priateľa',
        es: 'De un conocido / amigo',
        ru: 'От знакомого/друга',
        rs: 'Od poznanika / prijatelja',
        ua: 'Від знайомого/друга',
        no: 'Fra en bekjent / venn',
        it: 'Segnalazione da parte di un amico',
        se: 'Från en bekant / vän',
    },
    conferenceOrigin: {
        us: 'From a conference / presentation',
        cz: 'Na konferenci / prezentaci',
        pl: 'Z konferencji / prezentacji',
        hr: 'S konferencije / prezentacije',
        si: 'S konference / prezentacije',
        de: 'Von einer Konferenz/Präsentation',
        sk: 'Z konferencie/prezentácie',
        es: 'De una conferencia / presentación',
        ru: 'С конференции / презентации',
        rs: 'S konferencije / prezentacije',
        ua: 'З конференції / презентації',
        no: 'Fra en konferanse / presentasjon',
        it: 'In occasione di una conferenza / presentazione',
        se: 'Från en konferens / presentation',
    },
    otherOrigin: {
        us: 'From elsewhere',
        cz: 'Odjinud',
        pl: 'Z innego źródła',
        hr: 'Od drugdje',
        si: 'Od drugod',
        de: 'Aus anderen Quelle',
        sk: 'Odinakiaľ',
        es: 'De otro lugar',
        ru: 'Из другого места',
        rs: 'Od negde drugde',
        ua: 'З іншого місця',
        no: 'Fra et annet sted',
        it: 'Altrove',
        se: 'Från andra håll',
    },
    expandSidebar: {
        us: 'Expand sidebar',
        cz: 'Rozbalit panel',
        pl: 'Rozwiń boczny panel',
        hr: 'Proširi bočnu traku',
        si: 'Razširi stransko vrstico',
        de: 'Seitenleiste ausklappen',
        sk: 'Rozbaliť panel',
        es: 'Expandir barra lateral',
        ru: 'Развернуть боковую панель',
        rs: 'Proširi bočnu traku',
        ua: 'Розгорнути бічну панель',
        no: 'Utvide sidepanelet',
        it: 'Espandi la barra laterale',
        se: 'Expandera sidofältet',
    },
    collapseSidebar: {
        us: 'Collapse sidebar',
        cz: 'Sbalit panel',
        pl: 'Zwiń boczny panel',
        hr: 'Smanji bočnu traku',
        si: 'Zmanjšaj stransko vrstico',
        de: 'Seitenleiste einklappen',
        sk: 'Zbaliť panel',
        es: 'Contraer barra lateral',
        ru: 'Свернуть боковую панель',
        rs: 'Smanji bočnu traku',
        ua: 'Згорнути бічну панель',
        no: 'Skjule sidepanelet',
        it: 'Chiudi la barra laterale',
        se: 'Komprimera sidofältet',
    },
    playFromCursor: {
        us: 'Play from cursor',
        cz: 'Přehrát od kurzoru',
        pl: 'Odtwórz od pozycji kursora',
        hr: 'Izvođenje od pokazivača',
        si: 'Izvajanje od kazalca',
        de: 'Vom Cursor abspielen',
        sk: 'Prehrať od kurzora',
        es: 'Reproducir desde el cursor',
        ru: 'Воспроизведение от курсора',
        rs: 'Izvođenje od kursora',
        ua: 'Відтворення з курсору',
        no: 'Spille av fra pekeren',
        it: 'Riproduzione dal cursore',
        se: 'Spela från markören',
    },
    copyLabel: {
        us: 'Copy',
        cz: 'Kopírovat',
        pl: 'Kopiuj',
        hr: 'Kopiraj',
        si: 'Kopiraj',
        de: 'Kopieren',
        sk: 'Kopírovať',
        es: 'Copiar',
        ru: 'Копировать',
        rs: 'Kopiraj',
        ua: 'Копіювати',
        no: 'Kopiere',
        it: 'Copia',
        se: 'Kopiera',
    },
    pasteLabel: {
        us: 'Paste',
        cz: 'Vložit',
        pl: 'Wklej',
        hr: 'Zalijepi',
        si: 'Prilepi',
        de: 'Einfügen',
        sk: 'Vložiť',
        es: 'Pegar',
        ru: 'Вставить',
        rs: 'Zalepi',
        ua: 'Вставити',
        no: 'Lime',
        it: 'Incolla',
        se: 'Klistra in',
    },
    cutLabel: {
        us: 'Cut',
        cz: 'Vyjmout',
        pl: 'Wytnij',
        hr: 'Izreži',
        si: 'Izreži',
        de: 'Ausschneiden',
        sk: 'Vystrihnúť',
        es: 'Cortar',
        ru: 'Вырезать',
        rs: 'Izreži',
        ua: 'Вирізати',
        no: 'Klippe',
        it: 'Taglia',
        se: 'Klippa ut',
    },
    cutSuccess: {
        us: 'Cut',
        cz: 'Vyjmuto',
        pl: 'Wycięto',
        hr: 'Izreži',
        si: 'Izreži',
        de: 'Ausgeschnitten',
        sk: 'Vystrihnuté',
        es: 'Cortado',
        ru: 'Вырезано',
        rs: 'Izreži',
        ua: 'Вирізано',
        no: 'Klippet',
        it: 'Taglia da',
        se: 'Klippt',
    },
    editFormat: {
        us: 'Edit format',
        cz: 'Upravit formát',
        pl: 'Zmień format',
        hr: 'Uredi format',
        si: 'Uredi obliko',
        de: 'Format bearbeiten',
        sk: 'Upraviť formát',
        es: 'Editar formato',
        ru: 'Редактирование формата',
        rs: 'Uredi format',
        ua: 'Редагування формату',
        no: 'Endre format',
        it: 'Modifica il formato',
        se: 'Editera format',
    },
    cutCopyPaste: {
        us: 'Paste, copy, or cut?',
        cz: 'Vložit, kopírovat, nebo vyjmout?',
        pl: 'Wkleić, skopiować, czy wyciąć?',
        hr: 'Zalijepi, kopiraj ili izreži?',
        si: 'Prilepiti, kopirati ali izrezati?',
        de: 'Einfügen, Kopieren oder Ausschneiden?',
        sk: 'Vložiť, kopírovať alebo vystrihnúť?',
        es: '¿Pegar, copiar o cortar?',
        ru: 'Вставить, скопировать или вырезать?',
        rs: 'Zalepi, kopiraj ili izreži?',
        ua: 'Вставити, скопіювати або вирізати?',
        no: 'Lime, kopiere eller klippe?',
        it: 'Incollare, copiare o rimuovere?',
        se: 'Klistra in, kopiera eller klippa ut?',
    },
    useShortcuts: {
        us: 'Use the following shortcuts',
        cz: 'Použijte klávesové zkratky',
        pl: 'Użyj następujących skrótów klawiszowych',
        hr: 'Upotrijebi sljedeće prečace',
        si: 'Uporabite naslednje bližnjice',
        de: 'Verwenden Sie die folgenden Tastenkombinationen',
        sk: 'Použite klávesové skratky',
        es: 'Utilice los siguientes accesos directos',
        ru: 'Используйте следующие сочетания клавиш',
        rs: 'Upotrebi sledeće prečice',
        ua: 'Скористайтеся наведеними нижче комбінаціями клавіш',
        no: 'Bruk følgende hurtigtaster',
        it: 'Utilizzare le scorciatoie da tastiera',
        se: 'Använd följande kortkommandon',
    },
    group: {
        us: 'Group',
        cz: 'Skupina',
        pl: 'Grupa',
        hr: 'Grupa',
        si: 'Skupina',
        de: 'Gruppe',
        sk: 'Skupina',
        es: 'Grupo',
        ru: 'Группа',
        rs: 'Grupa',
        ua: 'Група',
        no: 'Gruppe',
        it: 'Gruppo',
        se: 'Grupp',
    },
    groups: {
        us: 'Groups',
        cz: 'Skupiny',
        pl: 'Grupy',
        hr: 'Grupe',
        si: 'Skupine',
        de: 'Gruppen',
        sk: 'Skupiny',
        es: 'Grupos',
        ru: 'Группы',
        rs: 'Grupe',
        ua: 'Групи',
        no: 'Grupper',
        it: 'Gruppi',
        se: 'Grupper',
    },
    invalidGroup: {
        us: 'Invalid group. Try again or contact our support at support@beey.io',
        cz: 'Neplatná skupina. Zkuste to znovu, nebo kontaktujte zákaznickou podporu na support@beey.io',
        pl: 'Nieprawidłowa grupa. Spróbuj ponownie lub skontaktuj się z naszą pomocą techniczną pod adresem support@beey.io',
        hr: 'Grupa nije valjana. Pokušajte ponovno ili se obratite našoj podršci na support@beey.io',
        si: 'Neveljavna skupina. Poskusite znova ali se obrnite na našo podporo na support@beey.io',
        de: 'Ungültige Gruppe. Versuchen Sie es erneut oder kontaktieren Sie unseren Support unter support@beey.io',
        sk: 'Neplatná skupina. Skúste to znova, alebo kontaktujte zákaznícku podporu na adrese support@beey.io',
        es: 'Grupo no válido. Inténtelo de nuevo o póngase en contacto con nuestro soporte en support@beey.io',
        ru: 'Неверная группа. Попробуйте еще раз или свяжитесь с нашей службой поддержки по адресу support@beey.io',
        rs: 'Grupa nije ispravna. Pokušajte ponovo ili se obratite našoj podršci na support@beey.io',
        ua: 'Неправильна група. Спробуйте ще раз або зверніться до служби підтримки на support@beey.io',
        no: 'Ugyldig gruppe. Prøv igjen eller ta kontakt med brukerstøtten på support@beey.io',
        it: "Gruppo non valido. Riprovi o contatti l'assistenza clienti all'indirizzo support@beey.io",
        se: 'Ogiltig grupp. Prova igen eller kontakta vår kundservice på support@beey.io',
    },
    groupSuccess: {
        us: 'Group successfully set to: ',
        cz: 'Skupina úspešně nastavena na: ',
        pl: 'Grupa ustawiona pomyślnie na: ',
        hr: 'Grupa uspješno podešena na: ',
        si: 'Skupina je uspešno nastavljena na: ',
        de: 'Gruppe erfolgreich gesetzt auf: ',
        sk: 'Skupina úspešne nastavená na: ',
        es: 'Grupo establecido correctamente en: ',
        ru: 'Группа успешно установлена: ',
        rs: 'Grupa uspešno podešena na: ',
        ua: 'Групу успішно створено: ',
        no: 'Gruppen ble stilt på: ',
        it: 'Gruppo impostato con successo su: ',
        se: 'Gruppen är nu inställd på: ',
    },
    groupTitle: {
        us: 'Group title',
        cz: 'Název skupiny',
        pl: 'Nazwa grupy',
        hr: 'Naziv grupe',
        si: 'Ime skupine',
        de: 'Gruppentitel',
        sk: 'Názov skupiny',
        es: 'Título del grupo',
        ru: 'Название группы',
        rs: 'Naziv grupe',
        ua: 'Назва групи',
        no: 'Gruppenavn',
        it: 'Nome del gruppo',
        se: 'Gruppnamn',
    },
    groupDescription: {
        us: 'Group description',
        cz: 'Popis skupiny',
        pl: 'Opis grupy',
        hr: 'Opis grupe',
        si: 'Opis skupine',
        de: 'Beschreibung der Gruppe',
        sk: 'Popis skupiny',
        es: 'Descripción del grupo',
        ru: 'Описание группы',
        rs: 'Opis grupe',
        ua: 'Опис групи',
        no: 'Gruppebeskrivelse',
        it: 'Descrizione del gruppo',
        se: 'Gruppbeskrivning',
    },
    groupProfile: {
        us: 'Group profile',
        cz: 'Profil skupiny',
        pl: 'Profil grupy',
        hr: 'Profil grupe',
        si: 'Profil skupine',
        de: 'Profil der Gruppe',
        sk: 'Profil skupiny',
        es: 'Perfil del grupo',
        ru: 'Профиль группы',
        rs: 'Profil grupe',
        ua: 'Профіль групи',
        no: 'Gruppeprofil',
        it: 'Profilo del gruppo',
        se: 'Grupprofil',
    },
    groupMembers: {
        us: 'Group members',
        cz: 'Členové skupiny',
        pl: 'Członkowie grupy',
        hr: 'Članovi grupe',
        si: 'Člani skupine',
        de: 'Mitglieder der Gruppe',
        sk: 'Členovia skupiny',
        es: 'Miembros del grupo',
        ru: 'Члены группы',
        rs: 'Članovi grupe',
        ua: 'Учасники групи',
        no: 'Gruppemedlemmer',
        it: 'Membri del gruppo',
        se: 'Gruppmedlemmar',
    },
    addGroup: {
        us: 'Create new group',
        cz: 'Vytvořit novou skupinu',
        pl: 'Utwórz nową grupę',
        hr: 'Kreiraj novu grupu',
        si: 'Ustvari novo skupino',
        de: 'Neue Gruppe erstellen',
        sk: 'Vytvoriť novú skupinu',
        es: 'Crear nuevo grupo',
        ru: 'Создать новую группу',
        rs: 'Kreiraj novu grupu',
        ua: 'Створити нову групу',
        no: 'Lage en ny gruppe',
        it: 'Creare un nuovo gruppo',
        se: 'Skapa en ny grupp',
    },
    deleteGroup: {
        us: 'Delete group',
        cz: 'Smazat skupinu',
        pl: 'Usuń grupę',
        hr: 'Izbriši grupu',
        si: 'Izbriši skupino',
        de: 'Gruppe löschen',
        sk: 'Odstrániť skupinu',
        es: 'Eliminar grupo',
        ru: 'Удалить группу',
        rs: 'Izbriši grupu',
        ua: 'Видалити групу',
        no: 'Slette gruppen',
        it: 'Cancellare un gruppo',
        se: 'Radera gruppen',
    },
    editGroup: {
        us: 'Edit group',
        cz: 'Upravit skupinu',
        pl: 'Edytuj grupę',
        hr: 'Uredi grupu',
        si: 'Uredi skupino',
        de: 'Gruppe bearbeiten',
        sk: 'Upraviť skupinu',
        es: 'Editar grupo',
        ru: 'Редактировать группу',
        rs: 'Uredi grupu',
        ua: 'Редагувати групу',
        no: 'Redigere gruppen',
        it: 'Modifica gruppo',
        se: 'Editera gruppen',
    },
    submodeFinishButton: {
        us: 'Continue editing subtitles',
        cz: 'Pokračovat v editaci titulků',
        pl: 'Kontynuuj edytowanie napisów',
        hr: 'Nastavi uređivati titlove',
        si: 'Nadaljuj z urejanjem podnapisov',
        de: 'Untertitel weiter editieren',
        sk: 'Pokračovať v úprave titulkov',
        es: 'Seguir editando subtítulos',
        ru: 'Продолжить редактирование субтитров',
        rs: 'Nastavi sa uređivanjem titlova',
        ua: 'Продовжити редагування субтитрів',
        no: 'Fortsette editering av undertekstene',
        it: 'Continua a modificare i sottotitoli',
        se: 'Fortsätta att editera undertexter',
    },
    burnSubtitlesInVideo: {
        us: 'Burn subtitles in video',
        cz: 'Vypálit titulky do videa',
        pl: 'Wypal napisy do wideo',
        hr: 'Ugradite titlove na videozapis',
        si: 'Vgradite podnapise v video',
        de: 'Brennen von Untertiteln ins Video',
        sk: 'Vypáliť titulky do videa',
        es: 'Incrustar subtítulos en un video',
        ru: 'Запись субтитров в видео',
        rs: 'Ugradite titlove u video',
        ua: 'Записати субтитри у відео',
        no: 'Brenne underteksten i videoen',
        it: 'Masterizzare i sottotitoli nel video',
        se: 'Bränna in undertexter i video',
    },
    burnSubs: {
        us: 'Burn subtitles',
        cz: 'Vypálit titulky',
        pl: 'Wypal napisy',
        hr: 'Ugradi titlove',
        si: 'Vgradi podnapise',
        de: 'Untertitel einbrennen',
        sk: 'Vypáliť titulky',
        es: 'Incrustar subtítulos en el video',
        ru: 'Записать субтитры',
        rs: 'Ugradite titlove',
        ua: 'Записування субтитрів',
        no: 'Brenne inn undertekster',
        it: 'Masterizzazione dei sottotitoli',
        se: 'Bädda in undertexter',
    },
    burntVideos: {
        us: 'Videos with burnt subtitles to download',
        cz: 'Videa s vypálenými titulky ke stažení',
        pl: 'Filmy z wypalonymi napisami do pobrania',
        hr: 'Videozapisi s ugrađenim titlovima za preuzimanje',
        si: 'Video posnetki z ugrajenimi podnapisi za prenos',
        de: 'Videos mit eingebrannten Untertiteln zum Herunterladen',
        sk: 'Stiahnuť videá s vypálenými titulkami',
        es: 'Vídeos con subtítulos incrustados para descargar',
        ru: 'Видео с сожженными субтитрами для загрузки',
        rs: 'Video sa ugrađenim titlovima za preuzimanje',
        ua: 'Відео зі спаленими субтитрами завантажити',
        no: 'Videoer med innbrente undertekster å laste ned',
        it: 'Video con sottotitoli masterizzati da scaricare',
        se: 'Videor med inbrända undertexter som är redo för nedladdning',
    },
    burnTimeInfo: {
        us: 'Burning can take up to several tens of minutes depending on the video length and the service load.',
        cz: 'Vypálení může trvat až několik desítek minut v závilosti na délce videa a vytížení služby.',
        pl: 'Wypalanie może trwać do kilkudziesięciu minut w zależności od długości wideo i stopnia przeciążenia usługi.',
        hr: 'Ugrađivanje može potrajati do nekoliko desetaka minuta, ovisno o duljini videozapisa i opterećenju usluge.',
        si: 'Vgradnja lahko traja do nekaj deset minut, odvisno od dolžine video posnetka in obremenitve storitve.',
        de: 'Das Einbrennen kann bis zu mehreren Dutzend Minuten dauern, je nach Länge des Videos und der Auslastung des Dienstes.',
        sk: 'Vypaľovanie môže trvať až niekoľko desiatok minút v závislosti od dĺžky videa a zaťaženia služby.',
        es: 'La incrustación puede llevar varios minutos, dependiendo de la duración del video y la carga del servicio.',
        ru: 'Запись может занимать до нескольких десятков минут в зависимости от длины видео и загрузки сервиса.',
        rs: 'Ugradnja može trajati do nekoliko desetina minuta, zavisno o dužini videa i opterećenju servisa.',
        ua: 'Запис може тривати до кількох десятків хвилин, залежно від тривалості відео та навантаження на сервіс.',
        no: 'Brenning kan ta opptil noen titalls av minutter avhengig av videolengden og utnyttelse av tjenesten.',
        it: 'La masterizzazione può richiedere fino a diverse decine di minuti, a seconda della lunghezza del video e del grado di occupazione del servizio.',
        se: 'Det kan ta upp till flera tiotal minuter att bädda in undertexterna beroende på längden av videon och belastningen på tjänsten.',
    },
    fullLengthSelected: {
        us: 'The whole recording is ready to be downloaded.',
        cz: 'Celá nahrávka je připravena ke stažení.',
        pl: 'Całe nagranie jest gotowe do pobrania.',
        hr: 'Cijela snimka je spremna za preuzimanje.',
        si: 'Celoten posnetek je pripravljen za prenos.',
        de: 'Die gesamte Aufnahme ist zum Download bereit.',
        sk: 'Celá nahrávka je pripravená na stiahnutie.',
        es: 'Todal la grabación está lista para ser descargada.',
        ru: 'Вся запись готова к скачиванию.',
        rs: 'Ceo snimak je spreman za preuzimanje.',
        ua: 'Весь запис готовий до завантаження.',
        no: 'Hele innspillingen er klar til nedlasting.',
        it: 'La registrazione completa è pronta per il download.',
        se: 'Hela transkriptionen är redo för nedladdning.',
    },
    fullLength: {
        us: 'Full length',
        cz: 'Celá délka',
        pl: 'Całe nagranie',
        hr: 'Cijela duljina',
        si: 'Cela dolžina',
        de: 'Ganze Länge',
        sk: 'Plná dĺžka',
        es: 'Duración completa',
        ru: 'Полная длина',
        rs: 'Puna dužina',
        ua: 'Повна версія',
        no: 'Hele lengden',
        it: 'Tutta la lunghezza',
        se: 'Full längd',
    },
    cutTimeInfo: {
        us: 'File cutting can take up to several tens of minutes. For quick download, download the full recording.',
        cz: 'Střih nahrávky může trvat až několik desítek minut. Pro rychlé stažení stáhněte nahrávku celou.',
        pl: 'Cięcie nagrania może zająć nawet kilkadziesiąt minut. Aby szybko pobrać nagranie, pobierz je w całości.',
        hr: 'Izrezivanje datoteka može potrajati do nekoliko desetaka minuta. Za brzo preuzimanje preuzmite cijelu snimku.',
        si: 'Rezanje datotek lahko traja do nekaj deset minut. Za hiter prenos prenesite celoten posnetek.',
        de: 'Das Schneiden der Datei kann bis zu mehreren Dutzend Minuten dauern. Für einen schnellen Download laden Sie die vollständige Aufzeichnung herunter.',
        sk: 'Zostrihanie nahrávky môže trvať až niekoľko desiatok minút. Ak chcete rýchlo stiahnuť nahrávku, stiahnite si ju celú.',
        es: 'La edicón de la grabación puede tomar varios minutos. Para una descarga rápida, descargue la grabación completa.',
        ru: 'Нарезка файлов может занимать до нескольких десятков минут. Для быстрого скачивания загрузите полную запись.',
        rs: 'Sečenje fajla može trajati do nekoliko desetina minuta. Za brzo preuzimanje, preuzmite celu snimku.',
        ua: 'Обрізання файлів може зайняти до декількох десятків хвилин. Для швидкого завантаження завантажте повний запис.',
        no: 'Det kan ta opptil flere titalls minutter å klippe innspillingen. For en rask nedlasting, last ned hele innspillingen.',
        it: "La modifica di una registrazione può richiedere fino a diverse decine di minuti. Per un download rapido, scaricare l'intera registrazione.",
        se: 'Det kan ta upp till flera tiotal minuter att klippa inspelningen. För en snabb nedladdning ladda ned inspelningen i dess helhet.',
    },
    selectedTimeRange: {
        us: 'Length of burnt section',
        cz: 'Délka vypáleného úseku',
        pl: 'Długość wypalonego fragmentu',
        hr: 'Dužina ugrađene sekcije',
        si: 'Dolžina vgrajenega odseka',
        de: 'Länge des eingebrannten Abschnitts',
        sk: 'Dĺžka vypáleného segmentu',
        es: 'Longitud de la sección incrustada',
        ru: 'Длина записываемого участка',
        rs: 'Dužina ugrađenog dela',
        ua: 'Довжина спаленого фрагмента',
        no: 'Lengden av innbrente avsnitt',
        it: 'Lunghezza del segmento masterizzato',
        se: 'Längden på det brända stycket',
    },
    canSelectText: {
        us: 'Select a part of transcription with mouse or insert the time of section here:',
        cz: 'Označte část přepisu myší v editoru nebo zadejte čas úseku zde:',
        pl: 'Wybierz sekcję transkrypcji za pomocą myszy w edytorze lub wprowadź czas sekcji tutaj:',
        hr: 'Odaberite dio transkripcije mišem ili ovdje umetnite vrijeme odjeljka:',
        si: 'Izberite del prepisa z miško ali tukaj vstavite čas razdelka:',
        de: 'Wählen Sie einen Teil der Transkription mit der Maus aus, oder geben Sie hier Zeit des Abschnitts ein:',
        sk: 'Pomocou myši v editore vyberte časť prepisu alebo tu zadajte čas danej časti:',
        es: 'Selecciona una parte de la transcripción con el ratón en el editor o ingresa el tiempo del segmento aquí:',
        ru: 'Выделите фрагмент транскрипции мышью или вставьте сюда время фрагмента:',
        rs: 'Izaberite deo transkripcije mišem ili unesite vreme odeljka ovde:',
        ua: 'Виберіть частину транскрипції за допомогою миші або вставте час фрагмента тут:',
        no: 'Sett merke på en del av transkripsjonen med mus eller sett inn tid av avsnittet her:',
        it: "Selezionare una sezione della trascrizione con il mouse nell'editor o inserire l'ora della sezione qui:",
        se: 'Markera en del av transkriptionen i editorn med musen eller ange tiden av avsnittet här:',
    },
    insertUrl: {
        us: 'Insert the file url address from YouTube, Facebook etc.',
        cz: 'Zadejte url nahrávky z YouTube, Facebooku atp.',
        pl: 'Wprowadź adres url YouTube, Facebooka itp.',
        hr: 'Unesite URL adresu datoteke s YouTubea, Facebooka itd.',
        si: 'Vstavite naslov URL datoteke iz YouTuba, Facebooka itd.',
        de: 'Einfügen der Datei-URL-Adresse von YouTube, Facebook usw.',
        sk: 'Zadajte URL nahrávky zo služby YouTube, Facebook atď.',
        es: 'Inserte la dirección URL del archivo de YouTube, Facebook, etc.',
        ru: 'Вставить url-адрес файла с YouTube, Facebook и т.д.',
        rs: 'Unesite URL adresu fajla sa YouTube-a, Facebook-a itd.',
        ua: 'Вставте URL-адресу файлу з YouTube, Facebook тощо.',
        no: 'Skriv inn URL-adressen fra YouTube, Facebook o.l.',
        it: "Inserisca l'url della registrazione da YouTube, Facebook, ecc.",
        se: 'Ange URL-adressen av inspelningen från YouTube, Facebook m.fl.',
    },
    orLabel: {
        us: 'Or',
        cz: 'Nebo',
        pl: 'Lub',
        hr: 'Ili',
        si: 'Ali',
        de: 'Oder',
        sk: 'Alebo',
        es: 'O',
        ru: 'Или',
        rs: 'Ili',
        ua: 'Або',
        no: 'Eller',
        it: 'Oppure',
        se: 'Eller',
    },
    invalidUrl: {
        us: 'Please insert valid url address',
        cz: 'Zadejte prosím platnou url adresu',
        pl: 'Wprowadź prawidłowy adres url',
        hr: 'Unesite valjanu URL adresu',
        si: 'Vnesite veljaven URL naslov',
        de: 'Bitte gültige URL-Adresse eingeben',
        sk: 'Zadajte prosím platnú URL adresu',
        es: 'Por favor, introduzca una dirección URL válida',
        ru: 'Пожалуйста, вставьте правильный url-адрес',
        rs: 'Molimo unesite validnu URL adresu',
        ua: 'Будь ласка, вставте коректну URL-адресу',
        no: 'Vennligst sett inn en gyldig URL-adresse',
        it: 'Inserisca un url valido',
        se: 'Vänligen ange en giltig URL-adress',
    },
    invalidShortUrl: {
        us: 'Shortened url including "fb.watch" is not valid. Please insert the full video url from the address bar',
        cz: 'Zkrácené url typu "fb.watch" není validní. Zkopírujte prosím celou adresu videa z adresního řádku',
        pl: 'Skrócony adres URL, taki jak "fb.watch", jest nieprawidłowy. Skopiuj pełny adres wideo z paska adresu',
        hr: 'Skraćeni URL koji sadrži "fb.watch" nije valjan. Unesite cijeli URL video zapisa iz adresne trake',
        si: 'Skrajšan URL, vključno z "fb.watch", ni veljaven. Vstavite celoten URL videoposnetka iz naslovne vrstice',
        de: 'Verkürzte URLs wie z.B. "fb.watch" sind nicht gültig. Bitte geben Sie die vollständige Video-URL von der Adressleiste ein',
        sk: 'Skrátené url ako "fb.watch" nie je platné. Skopírujte celú adresu videa z adresného riadka',
        es: 'La URL acortada que incluye "fb.watch" no es válida. Por favor, inserta la URL completa del video desde la barra de direcciones',
        ru: 'Сокращенный url, включающий "fb.watch", недействителен. Пожалуйста, вставьте полный адрес видео из адресной строки',
        rs: 'Skraćeni URL uključujući "fb.watch" nije validan. Molimo umetnite punu URL adresu videa iz polja za adresu',
        ua: 'Скорочена адреса, включаючи "fb.watch", не є дійсною. Будь ласка, вставте повну адресу відео з адресного рядка',
        no: 'Forkortet nettadresse, blant annet "fb.watch", er ugyldig. Vennligst kopier hele nettadressen av videoen fra adressefeltet',
        it: "Un URL abbreviato come \"fb.watch\" non è valido. Copiare l'URL completo del video dalla barra degli indirizzi",
        se: 'Förkortade webbadresser såsom "fb.watch" är inte giltiga. Vänligen kopiera hela adressen till videon från adressfältet',
    },
    noGroupTitle: {
        us: 'Enter group title',
        cz: 'Zadejte název skupiny',
        pl: 'Wprowadź nazwę grupy',
        hr: 'Unesite naziv grupe',
        si: 'Vnesi ime skupine',
        de: 'Geben Sie den Gruppentitel ein',
        sk: 'Zadajte názov skupiny',
        es: 'Ingrese el título del grupo',
        ru: 'Введите название группы',
        rs: 'Unesi naziv grupe',
        ua: 'Введіть назву групи',
        no: 'Skriv inn gruppenavn',
        it: 'Inserire il nome di un gruppo',
        se: 'Ange gruppnamn',
    },
    groupExists: {
        us: 'Such group already exists',
        cz: 'Tato skupina již existuje',
        pl: 'Grupa o tej nazwie już istnieje',
        hr: 'Takva grupa već postoji',
        si: 'Takšna skupina že obstaja',
        de: 'Diese Gruppe existiert bereits',
        sk: 'Táto skupina už existuje',
        es: 'Tal grupo ya existe',
        ru: 'Такая группа уже существует',
        rs: 'Takva grupa već postoji',
        ua: 'Така група вже існує',
        no: 'Denne gruppen finnes allerede',
        it: 'Questo gruppo esiste già',
        se: 'Denna grupp finns redan',
    },
    waitForGroups: {
        us: 'Wait for the group list to be loaded',
        cz: 'Počkejte, až se načte seznam skupin',
        pl: 'Poczekaj na wczytanie listy grup',
        hr: 'Pričekajte da se popis grupa učita',
        si: 'Počakajte, da se seznam skupin naloži',
        de: 'Warten Sie, bis die Gruppenliste geladen ist',
        sk: 'Počkajte, kým sa načíta zoznam skupín',
        es: 'Espere a que se cargue la lista de grupos',
        ru: 'Подождите, пока загрузится список групп',
        rs: 'Sačekajte da se lista grupa učita',
        ua: 'Зачекайте, поки завантажиться список груп',
        no: 'Vent på at gruppelista lastes',
        it: "Attenda il caricamento dell'elenco dei gruppi",
        se: 'Vänta tills grupplistan har laddats upp',
    },
    externalId: {
        us: 'External id',
        cz: 'Externí id',
        pl: 'ID zewnętrzne',
        hr: 'Vanjski id',
        si: 'Znanji id',
        de: 'Externe ID',
        sk: 'Externé id',
        es: 'ID externo',
        ru: 'Внешний идентификатор',
        rs: 'Eksterni ID',
        ua: 'Зовнішній ідентифікатор',
        no: 'Ekstern id',
        it: 'ID esterno',
        se: 'Externt ID',
    },
    findRole: {
        us: 'Find role',
        cz: 'Vyhledat roli',
        pl: 'Znajdź rolę',
        hr: 'Pronađite ulogu',
        si: 'Najdi vlogo',
        de: 'Rolle finden',
        sk: 'Vyhľadajte rolu',
        es: 'Buscar rol',
        ru: 'Найти роль',
        rs: 'Pronađi ulogu',
        ua: 'Знайти роль',
        no: 'Finn rolle',
        it: 'Cerca un ruolo',
        se: 'Hitta roll',
    },
    emailRegistered: {
        us: 'This email address is already registered',
        cz: 'Tato emailová adresa je již registrovaná',
        pl: 'Ten adres e-mail jest już zarejestrowany',
        hr: 'Ova email adresa je već registrirana',
        si: 'Ta e-poštni naslov je že registriran',
        de: 'Diese E-Mail Adresse ist bereits registriert',
        sk: 'Táto e-mailová adresa je už zaregistrovaná',
        es: 'Esta dirección de correo electrónico ya está registrada',
        ru: 'Этот адрес электронной почты уже зарегистрирован',
        rs: 'Ova email adresa je već registrovana',
        ua: 'Ця електронна адреса вже зареєстрована',
        no: 'Denne e-postadressen er allerede registrert',
        it: 'Questo indirizzo e-mail è già registrato',
        se: 'Denna e-postadress -ar redan registrerad',
    },
    fillInSubtitlesStart: {
        us: 'Fill in the start time of subtitles',
        cz: 'Vyplňte začátek titulků',
        pl: 'Wprowadź czas początku napisów',
        hr: 'Unesite vrijeme početka titlova',
        si: 'Izpolnite začetni čas podnapisov',
        de: 'Geben Sie die Startzeit der Untertitel ein',
        sk: 'Vyplňte začiatok titulkov',
        es: 'Ingrese la hora de inicio de los subtítulos',
        ru: 'Заполните время начала субтитров',
        rs: 'Unesite vreme početka titlova',
        ua: 'Заповніть час початку субтитрів',
        no: 'Fyll ut starttidspunktet av undertekstene',
        it: "Compilare l'inizio dei sottotitoli",
        se: 'Ange början av undertexter',
    },
    fillInSubtitlesEnd: {
        us: 'Fill in the end time of subtitles',
        cz: 'Vyplňte konec titulků',
        pl: 'Wprowadź czas końca napisów',
        hr: 'Unesite vrijeme završetka titlova',
        si: 'Izpolnite končni čas podnapisov',
        de: 'Geben Sie die Endzeit der Untertitel ein',
        sk: 'Vyplňte koniec titulkov',
        es: 'Ingrese la hora de finalización de los subtítulos',
        ru: 'Введите время окончания субтитров',
        rs: 'Unesite vreme kraja titlova',
        ua: 'Заповніть час закінчення субтитрів',
        no: 'Fyll ut sluttidspunktet av undertekstene',
        it: 'Compili i titoli di coda',
        se: 'Ange slutet av undertexter',
    },
    timeRangeFormat: {
        us: 'Both time entries must be in the format 00:00:00.000',
        cz: 'Oba časové údaje musí být ve formátu 00:00:00.000',
        pl: 'Obie pozycje czasowe muszą być w formacie 00:00:00.000',
        hr: 'Oba vremenska unosa moraju biti u formatu 00:00:00.000',
        si: 'Oba časovna vnosa morata biti v obliki 00:00:00.000',
        de: 'Beide Zeitangaben müssen im Format 00:00:00.000 sein',
        sk: 'Oba časové údaje musia byť vo formáte 00:00:00.000',
        es: 'Ambas entradas de tiempo deben tener el formato 00:00:00.000',
        ru: 'Оба значения времени должны быть в формате 00:00:00.000',
        rs: 'Oba unosa vremena moraju biti u formatu 00:00:00.000',
        ua: 'Обидва записи часу повинні бути у форматі 00:00:00.000',
        no: 'Begge tidspunkter må være i formatet 00:00:00.000',
        it: 'Entrambi i tempi devono essere nel formato 00:00:00.000',
        se: 'Båda tidsuppgifter måste motsvara formatet 00:00:00.000',
    },
    beginningBeforeEnd: {
        us: 'The beginning must come before the end',
        cz: 'Začátek musí být dříve než konec',
        pl: 'Czas początku musi być wcześniejszy niż czas końca',
        hr: 'Početak mora doći prije kraja',
        si: 'Začetek mora priti pred koncem',
        de: 'Der Startzeit muss vor dem Endzeit liegen',
        sk: 'Začiatok musí byť skôr ako koniec',
        es: 'El tiempo del inicio debe venir antes del final',
        ru: 'Начало должно быть раньше конца',
        rs: 'Početak mora biti pre kraja',
        ua: 'Початок має бути перед закінченням',
        no: 'Starttidspunktet må komme før slutten',
        it: "L'inizio deve essere precedente alla fine",
        se: 'Början måste komma före slutet',
    },
    notGreaterThanRecording: {
        us: 'Time entires cannot be greater than the duration of the recording',
        cz: 'Časové údaje nemohou být větší než je délka nahrávky',
        pl: 'Wprowadzone wartości nie mogą być większe niż długość nagrania',
        hr: 'Vrijeme ne može biti duže od trajanja snimanja',
        si: 'Časovno obdobje ne sme biti daljše od trajanja snemanja',
        de: 'Die Zeitangaben dürfen nicht länger sein als die Dauer der Aufnahme',
        sk: 'Časové údaje nemôžu byť dlhšie ako dĺžka nahrávky',
        es: 'El tiempo total no puede ser mayor que la duración de la grabación',
        ru: 'Ввод времени не может быть больше, чем продолжительность записи',
        rs: 'Vremenski unosi ne smeju biti duži od trajanja snimka',
        ua: 'Введений час не може бути більшим за тривалість запису',
        no: 'Tidspunkter kan ikke overstige varigheten av innspillingen',
        it: "L'indicazione del tempo non può essere superiore alla durata della registrazione",
        se: 'Tidsuppgifter får inte överstiga längden på inspelningen',
    },
    mediaOriginError: {
        us: "Transcription is not possible: Some of your recordings can't be processed. Please try again or contact our support.",
        cz: 'Přepis není možný: Některou z vašich nahrávek nelze zpracovat. Zkuste to prosím znovu, nebo kontaktujte zákaznickou podporu.',
        pl: 'Transkrypcja nie jest możliwa: Niektóre z Twoich nagrań nie mogą zostać przetworzone. Spróbuj ponownie lub skontaktuj się z naszym działem wsparcia.',
        hr: 'Transkripcija nije moguća: neke od vaših snimaka nije moguće obraditi. Pokušajte ponovo ili kontaktirajte s našom podrškom.',
        si: 'Transkripcija ni mogoča: Nekaterih vaših posnetkov ni mogoče obdelati. Poskusite znova ali se obrnite na našo podporo.',
        de: 'Transkription ist nicht möglich: Einige Ihrer Aufnahmen können nicht verarbeitet werden. Bitte versuchen Sie es erneut oder kontaktieren Sie unseren Support.',
        sk: 'Prepis nie je možný: Niektoré z vašich nahrávok nie je možné spracovať. Skúste to znova alebo kontaktujte zákaznícku podporu.',
        es: 'La transcripción no es posible: algunas de sus grabaciones no se pueden procesar. Vuelva a intentarlo o póngase en contacto con nuestro soporte.',
        ru: 'Транскрипция невозможна: Некоторые из ваших записей не могут быть обработаны. Пожалуйста, попробуйте еще раз или свяжитесь с нашей службой поддержки.',
        rs: 'Transkripcija nije moguća: Neki od vaših snimaka ne mogu biti obrađeni. Molimo pokušajte ponovo ili kontaktirajte našu podršku.',
        ua: 'Транскрипція неможлива: Деякі з ваших записів не можуть бути оброблені. Будь ласка, спробуйте ще раз або зверніться до нашої служби підтримки.',
        no: 'Transkripsjon umulig: noen av innspillingene dine ikke kan bearbeides. Vennligst prøv igjen eller kontakt brukerstøtten.',
        it: "La trascrizione non è possibile: Alcune registrazioni non possono essere elaborate. Riprovare o contattare l'assistenza clienti.",
        se: 'Transkription är inte möjlig. Det gick inte att bearbeta någon av dina inspelnigar. Vänligen prova igen, eller kontakta vår kundservice.',
    },
    linkDownload: {
        us: 'Preparing transcription from url',
        cz: 'Připravuji přepis z url',
        pl: 'Przygotowuję transkrypcję z adresu url',
        hr: 'Priprema transkripcije s URL-a',
        si: 'Priprava prepisa iz URL-ja',
        de: 'Transkription vom URL vorbereiten',
        sk: 'Príprava prepisu z URL',
        es: 'Preparando la transcripción desde la URL',
        ru: 'Подготовка транскрипции из url',
        rs: 'Priprema transkripcije sa URL adrese',
        ua: 'Підготовка транскрипції з url',
        no: 'Forbereder transkripsjonen fra URL',
        it: 'Preparazione della trascrizione da una url',
        se: 'Förbereder transpription från URL',
    },
    dataThemeApa: {
        us: 'APA color scheme',
        cz: 'APA barevné schéma',
        pl: 'Schemat kolorystyczny APA',
        hr: 'APA shema boja',
        si: 'APA barvna shema',
        de: 'APA-Farbschema',
        sk: 'APA farebná schéma',
        es: 'Esquema de color APA',
        ru: 'Цветовая схема APA',
        rs: 'APA šema boja',
        ua: 'Кольорова схема APA',
        no: 'APA fargeoppsett',
        it: 'Schema di colori APA',
        se: 'APA-färgschema',
    },
    trsxNotInLink: {
        us: 'Trsx cannot be uploaded for a project from url',
        cz: 'Trsx nemůže být nahraný pro projekt z url',
        pl: 'W przypadku projektu z url upload trsx nie jest możliwy',
        hr: 'Trsx se ne može prenijeti za projekt s URL-a',
        si: 'Trsx ni mogoče naložiti za projekt iz URL-ja',
        de: 'Trsx kann nicht für ein Projekt vom URL hochgeladen werden',
        sk: 'Trsx nie je možné nahrať pre projekt z URL',
        es: 'Trsx no se puede cargar para un proyecto desde la URL',
        ru: 'Транскрипция не может быть загружена для проекта из url',
        rs: 'Trsx se ne može učitati za projekat sa URL adrese',
        ua: 'Trsx не може бути завантажений для проекту з url',
        no: 'Trsx kan ikke lastes opp for et prosjekt fra URL',
        it: 'Non è possibile caricare il trsx per il progetto da url',
        se: 'Det går inte att ladda upp Trsx för projekt från URL',
    },
    transcode: {
        us: 'Burnt-in subtitles',
        cz: 'Vypálené titulky',
        pl: 'Wypalone napisy',
        hr: 'Ugrađeni titlovi',
        si: 'Vgrajeni podnapisi',
        de: 'Eingebrannte Untertitel',
        sk: 'Vypálené titulky',
        es: 'Subtítulos incrustados',
        ru: 'Сгоревшие субтитры',
        rs: 'Ugrađeni titlovi',
        ua: 'Випалені субтитри',
        no: 'Innbrente undertekster',
        it: 'Sottotitoli masterizzati',
        se: 'Inbrända undertexter',
    },
    contextMenu: {
        us: 'Editor context menu',
        cz: 'Kontextové menu editoru',
        pl: 'Menu kontekstowe edytora',
        hr: 'Kontekstni izbornik editora',
        si: 'Kontekstni meni urejevalnika',
        de: 'Editor-Kontextmenü',
        sk: 'Kontextové menu editora',
        es: 'Menú contextual del editor',
        ru: 'Контекстное меню редактора',
        rs: 'Editor menija konteksta',
        ua: 'Контекстне меню редактора',
        no: 'Redigerings kontekstmeny',
        it: "Menu contestuale dell'editor",
        se: 'Redigeringsmeny',
    },
    linkApp: {
        us: 'Link',
        cz: 'Link',
        pl: 'Link',
        hr: 'Poveznica',
        si: 'Povezava',
        de: 'Link',
        sk: 'Link',
        es: 'Enlace',
        ru: 'Ссылка',
        rs: 'Link',
        ua: 'Посилання',
        no: 'Lenke',
        it: 'Collegamento',
        se: 'Länk',
    },
    useTemplate: {
        us: 'Use a template',
        cz: 'Použít šablonu',
        pl: 'Zastosuj szablon',
        hr: 'Upotrijebi predložak',
        si: 'Uporabi predlogo',
        de: 'Vorlage verwenden',
        sk: 'Použiť šablónu',
        es: 'Usar una plantilla',
        ru: 'Использовать шаблон',
        rs: 'Koristite templejt',
        ua: 'Використовуйте шаблон',
        no: 'Bruk en mal',
        it: 'Usa modello',
        se: 'Använda en mall',
    },
    addTemplate: {
        us: 'New template',
        cz: 'Vytvořit šablonu',
        pl: 'Utwórz szablon',
        hr: 'Novi predložak',
        si: 'Nova predloga',
        de: 'Neue Vorlage',
        sk: 'Vytvoriť šablónu',
        es: 'Nueva plantilla',
        ru: 'Новый шаблон',
        rs: 'Novi templejt',
        ua: 'Новий шаблон',
        no: 'Lage en ny mal',
        it: 'Crea modello',
        se: 'Skapa en mall',
    },
    deleteTemplateTeam: {
        us: 'Delete template for the whole team?',
        cz: 'Smazat šablonu pro celý tým?',
        pl: 'Usuń szablon dla całego teamu?',
        hr: 'Želite li izbrisati predložak za cijeli tim?',
        si: 'Želite izbrisati predlogo za celotno ekipo?',
        de: 'Vorlage für das ganze Team löschen?',
        sk: 'Odstrániť šablónu pre celý tím?',
        es: '¿Eliminar plantilla para todo el equipo?',
        ru: 'Удалить шаблон для всей команды?',
        rs: 'Obriši templejt za ceo tim?',
        ua: 'Видалити шаблон для всієї команди?',
        no: 'Slette malen for hele teamet?',
        it: 'Cancellare un modello per tutto il team?',
        se: 'Ta bort mallen för hela teamet?',
    },
    deleteTemplate: {
        us: 'Delete template',
        cz: 'Smazat šablonu',
        pl: 'Usuń szablon',
        hr: 'Izbriši predložak',
        si: 'Izbriši predlogo',
        de: 'Vorlage löschen',
        sk: 'Odstrániť šablónu',
        es: 'Eliminar plantilla',
        ru: 'Удалить шаблон',
        rs: 'Obriši templejt',
        ua: 'Видалити шаблон',
        no: 'Slette malen',
        it: 'Cancellare un modello',
        se: 'Ta bort mallen',
    },
    updateTemplateTeam: {
        us: 'Overwrite template for the whole team?',
        cz: 'Přepsat šablonu pro celý tým?',
        pl: 'Nadpisać szablon dla całego teamu?',
        hr: 'Želite li prepisati predložak za cijeli tim?',
        si: 'Želite prepisati predlogo za celotno ekipo?',
        de: 'Vorlage für das ganze Team überschreiben?',
        sk: 'Prepísať šablónu pre celý tím?',
        es: '¿Sobrescribir plantilla para todo el equipo?',
        ru: 'Переписать шаблон для всей команды?',
        rs: 'Upiši preko templejta za ceo tim?',
        ua: 'Перезаписати шаблон для всієї команди?',
        no: 'Overskrive malen for hele teamet?',
        it: "Riscrivere il modello per l'intero team?",
        se: 'Skriva över mallen för hela teamet?',
    },
    yesUpdate: {
        us: 'Yes, update',
        cz: 'Ano, aktualizovat',
        pl: 'Tak, zaktualizuj',
        hr: 'Da, ažuriraj',
        si: 'Ja, posodobi',
        de: 'Ja, aktualisieren',
        sk: 'Áno, aktualizovať',
        es: 'Si, actualizar',
        ru: 'Да, обновить',
        rs: 'Da, ažuriraj',
        ua: 'Так, оновити',
        no: 'Ja, oppdatere',
        it: 'Sì, aggiornare',
        se: 'Ja, uppdatera',
    },
    updateTemplate: {
        us: 'Update template',
        cz: 'Aktualizovat šablonu',
        pl: 'Zaktualizuj szablon',
        hr: 'Ažuriraj predložak',
        si: 'Posodobi predlogo',
        de: 'Vorlage aktualisieren',
        sk: 'Aktualizovať šablónu',
        es: 'Actualizar plantilla',
        ru: 'Обновить шаблон',
        rs: 'Ažuriraj templejt',
        ua: 'Оновити шаблон',
        no: 'Oppdatere malen',
        it: 'Aggiorni il modello',
        se: 'Uppdatera mallen',
    },
    saveTemplate: {
        us: 'Save template',
        cz: 'Uložit šablonu',
        pl: 'Zapisz szablon',
        hr: 'Spremi predložak',
        si: 'Shrani predlogo',
        de: 'Vorlage speichern',
        sk: 'Uložiť šablónu',
        es: 'Guardar plantilla',
        ru: 'Сохранить шаблон',
        rs: 'Sačuvaj templejt',
        ua: 'Зберегти шаблон',
        no: 'Lagre malen',
        it: 'Salvi il modello',
        se: 'Spara mallen',
    },
    unsavedTemplate: {
        us: 'Current project settings differ from the originally used template. Update the template or create a new one.',
        cz: 'Aktuální nastavení projektu neodpovídají původní šabloně. Aktualizujte šablonu nebo vytvořte novou.',
        pl: 'Bieżące ustawienia projektu różnią się od pierwotnie używanego szablonu. Zaktualizuj szablon lub utwórz nowy.',
        hr: 'Trenutne postavke projekta razlikuju se od izvorno korištenog predloška. Ažurirajte predložak ili izradite novi.',
        si: 'Trenutne nastavitve projekta se razlikujejo od prvotno uporabljene predloge. Posodobite predlogo ali ustvarite novo.',
        de: 'Aktuelle Projekteinstellungen weichen von der ursprünglich verwendeten Vorlage ab. Aktualisieren Sie die Vorlage oder erstellen Sie eine neue.',
        sk: 'Aktuálne nastavenia projektu sa nezhodujú s pôvodnou šablónou. Aktualizujte šablónu alebo vytvorte novú.',
        es: 'La configuración actual del proyecto difiere con la plantilla originalmente utilizada. Actualice la plantilla o cree una nueva.',
        ru: 'Текущие настройки проекта отличаются от первоначально использованного шаблона. Обновите шаблон или создайте новый.',
        rs: 'Trenutne postavke projekta se razlikuju od prvobitno korišćenog templejta. Ažurirajte templejt ili napravite novi.',
        ua: 'Поточні налаштування проекту відрізняються від початкового шаблону. Оновіть шаблон або створіть новий.',
        no: 'Aktuelle innstillinger av prosjektet samsvarer ikke med den opprinnelige malen. Oppdater malen eller lag en ny.',
        it: 'Le impostazioni attuali del progetto non corrispondono al modello originale. Aggiornare il modello o crearne uno nuovo.',
        se: 'De aktuella projektinställningarna motsvarar inte den ursprungliga mallen. Uppdatera mallen eller skapa en ny mall.',
    },
    fillTemplateName: {
        us: 'To save as template, fill in template name',
        cz: 'Pro uložení šablony, vyplňte její název',
        pl: 'Aby zapisać szablon, wpisz jego nazwę',
        hr: 'Da biste spremili kao predložak, unesite naziv predloška',
        si: 'Če želite shraniti kot predlogo, izpolnite ime predloge',
        de: 'Um als Vorlage zu speichern, geben Sie den Vorlagennamen ein',
        sk: 'Ak chcete šablónu uložiť, vyplňte jej názov',
        es: 'Para guardar como plantilla, complete el nombre de la plantilla',
        ru: 'Чтобы сохранить как шаблон, заполните имя шаблона',
        rs: 'Da biste sačuvali kao templejt, popunite ime templejta',
        ua: 'Щоб зберегти як шаблон, введіть назву шаблону',
        no: 'Fyll ut malnavn for å lagre malen',
        it: 'Per salvare un modello, inserisca il suo nome',
        se: 'För att spara som mall, ange ett mallnamn',
    },
    defaultSettings: {
        us: 'Default settings',
        cz: 'Výchozí nastavení',
        pl: 'Ustawienia domyślne',
        hr: 'Zadane postavke',
        si: 'Privzete nastavitve',
        de: 'Standardeinstellungen',
        sk: 'Východiskové nastavenia',
        es: 'Configuración predeterminada',
        ru: 'Настройки по умолчанию',
        rs: 'Podrazumevane postavke',
        ua: 'Налаштування за замовчуванням',
        no: 'Standardinnstillinger',
        it: 'Impostazioni predefinite',
        se: 'Standardinställningar',
    },
    readOnly: {
        us: 'Read only',
        cz: 'Pouze pro čtení',
        pl: 'Tylko do odczytu',
        hr: 'Samo za čitane',
        si: 'Samo za branje',
        de: 'Schreibschutz',
        sk: 'Len na čítanie',
        es: 'Solo lectura',
        ru: 'Только чтение',
        rs: 'Samo za čitanje',
        ua: 'Тільки для читання',
        no: 'Skrivebeskyttet',
        it: 'Solo lettura',
        se: 'Skrivskyddat',
    },
    exportLabel: {
        us: 'Export as .csv',
        cz: 'Stáhnout v .csv',
        pl: 'Pobierz w .csv',
        hr: 'Izvezi kao .csv',
        si: 'Izvezi kot .csv',
        de: 'Exportieren als .csv',
        sk: 'Stiahnuť vo formáte .csv',
        es: 'Exportar como .csv',
        ru: 'Экспортировать в формат .csv',
        rs: 'Izvoz kao .csv',
        ua: 'Експортувати як .csv',
        no: 'Eksporter som .csv',
        it: 'Scarica in .csv',
        se: 'Exportera som .csv',
    },
    exportProgress: {
        us: 'Exporting',
        cz: 'Stahuji',
        pl: 'Pobieram',
        hr: 'Izvezi',
        si: 'Izvezi',
        de: 'Exportieren',
        sk: 'Sťahujem',
        es: 'Exportando',
        ru: 'Экспорт',
        rs: 'Izvoz',
        ua: 'Експортувати',
        no: 'Eksporterer',
        it: 'Scaricando',
        se: 'Exporterar',
    },
    deleteModel: {
        us: 'Delete model',
        cz: 'Smazat model',
        pl: 'Usuń model',
        hr: 'Izbriši model',
        si: 'Izbriši model',
        de: 'Modell löschen',
        sk: 'Zmazať model',
        es: 'Borrar modelo',
        ru: 'Удалить модель',
        rs: 'Obriši model',
        ua: 'Видалити модель',
        no: 'Slette modell',
        it: 'Eliminare il modello',
        se: 'Ta bort modellen',
    },
    installedModels: {
        us: 'Installed models',
        cz: 'Nainstalované modely',
        pl: 'Zainstalowane modele',
        hr: 'Instalirani modeli',
        si: 'Nameščeni modeli',
        de: 'Installierte Modelle',
        sk: 'Nainštalované modely',
        es: 'Modelos instalados',
        ru: 'Установленные модели',
        rs: 'Instalirani modeli',
        ua: 'Встановлені моделі',
        no: 'Installerte modeller',
        it: 'Modelli installati',
        se: 'Installerade modeller',
    },
    addOrUpdateModel: {
        us: 'Add or update model',
        cz: 'Vytvořit nebo aktualizovat model',
        pl: 'Utwórz lub aktualizuj model',
        hr: 'Dodaj ili ažuriraj model',
        si: 'Dodaj ali posodobi model',
        de: 'Modell hinzufügen oder aktualisieren',
        sk: 'Pridať alebo aktualizovať model',
        es: 'Agregar o actualizar modelo',
        ru: 'Добавить или обновить модель',
        rs: 'Dodaj ili ažuriraj model',
        ua: 'Додати або оновити модель',
        no: 'Legge til eller oppdatere modell',
        it: 'Creare o aggiornare un modello',
        se: 'Skapa eller uppdatera modellen',
    },
    editedTemplate: {
        us: 'Edited template',
        cz: 'Editovaná šablona',
        pl: 'Edytowany szablon',
        hr: 'Uređeni predložak',
        si: 'Urejena predloga',
        de: 'Bearbeitete Vorlage',
        sk: 'Editovaná šablóna',
        es: 'Plantilla editada',
        ru: 'Отредактированный шаблон',
        rs: 'Uređeni templejt',
        ua: 'Відредагований шаблон',
        no: 'Redigert mal',
        it: 'Modello modificato',
        se: 'Redigerad mall',
    },
    exportInfo: {
        us: 'Download current projects view. Use filters or fulltext search to narrow down the view.',
        cz: 'Stáhnout aktuální náhled projektů. Použijte filtry nebo fulltextové vyhledávání pro zúžení náhledu.',
        pl: 'Pobierz aktualny podgląd projektów. Użyj filtrów lub wyszukiwania pełnotekstowego, aby zawęzić podgląd.',
        hr: 'Preuzmi prikaz trenutnih projekata. Za sužavanje prikaza koristite filtre ili pretraživanje cijelog teksta.',
        si: 'Prenesite pogled trenutnih projektov. Če želite omejiti pogled, uporabite filtre ali iskanje po celotnem besedilu.',
        de: 'Laden Sie die aktuelle Projektvorschau herunter. Verwenden Sie Filter oder Volltextsuche, um die Vorschau einzugrenzen.',
        sk: 'Stiahnuť náhľad aktuálnych projektov. Na zúženie náhľadu použite filtre alebo fulltextové vyhľadávanie.',
        es: 'Descargue la vista de proyectos actuales. Utilice filtros o la búsqueda de texto completo para reducir la vista.',
        ru: 'Загрузите просмотр текущих проектов. Используйте фильтры или полнотекстовый поиск для сужения просмотра.',
        rs: 'Preuzmite trenutni pregled projekata. Koristite filtere ili pretragu sa punim tekstom da biste suzili pregled.',
        ua: 'Завантажити перегляд поточних проектів. Використовуйте фільтри або повнотекстовий пошук, щоб звузити перегляд.',
        no: 'Laste ned den aktuelle prosjektoversikten. Bruk filtrer eller fulltekstsøk for å innsnevre visningen.',
        it: "Scarica l'anteprima del progetto corrente. Utilizzate i filtri o la ricerca full-text per restringere l'anteprima.",
        se: 'Ladda ned den aktuella förhandsgranskningen av projekten. Använda filter eller fulltextsökning för att begränsa förhandsgranskningen.',
    },
    colorSpeakers: {
        us: 'Color speakers',
        cz: 'Obarvit mluvčí',
        pl: 'Pokoloruj mówców',
        hr: 'Obojite govornike',
        si: 'Pobarvajte govorce',
        de: 'Sprecher einfärben',
        sk: 'Zafarbiť rečníka',
        es: 'Colorear oradores',
        ru: 'Цвет динамиков',
        rs: 'Oboj govornike',
        ua: 'Кольор динаміків',
        no: 'Farge talere',
        it: 'Assegna colore allo speaker',
        se: 'Färga talare',
    },
    setSpeakerColors: {
        us: 'Set speaker colors in the project',
        cz: 'Nastavit barvy mluvčích v projektu',
        pl: 'Ustaw kolory mówców w projekcie',
        hr: 'Postavite boje govornika u projektu',
        si: 'Nastavite barve govorcev v projektu',
        de: 'Sprecherfarben im Projekt festlegen',
        sk: 'Nastaviť farby rečníkov v projekte',
        es: 'Configurar colores de oradores en el proyecto',
        ru: 'Установка цветов динамиков в проекте',
        rs: 'Postavite boje govornika u projektu',
        ua: 'Встановлення кольорів динаміків у проєкті',
        no: 'Sette farger for talere i prosjektet',
        it: 'Impostare i colori degli speaker nel progetto',
        se: 'Sätt in färg för talare i projektet',
    },
    manual: {
        us: 'Manual',
        cz: 'Manuální',
        pl: 'Ręcznie',
        hr: 'Ručno',
        si: 'Ročno',
        de: 'Manuell',
        sk: 'Manuálny',
        es: 'Manual',
        ru: 'Вручную',
        rs: 'Ručno',
        ua: 'Вручну',
        no: 'Manuell',
        it: 'Manuale',
        se: 'Manuell',
    },
    automatic: {
        us: 'Automatic',
        cz: 'Automatický',
        pl: 'Automatycznie',
        hr: 'Automatski',
        si: 'Avtomatski',
        de: 'Automatisch',
        sk: 'Automatický',
        es: 'Automático',
        ru: 'Автоматически',
        rs: 'Automatski',
        ua: 'Автоматично',
        no: 'Automatisk',
        it: 'Automatico',
        se: 'Automatisk',
    },
    coloringMode: {
        us: 'Coloring mode',
        cz: 'Režim obarvování',
        pl: 'Tryb kolorowania',
        hr: 'Način bojanja',
        si: 'Način barvanja',
        de: 'Modus der Farbgebung',
        sk: 'Režim vyfarbovania',
        es: 'Modo a color',
        ru: 'Режим раскраски',
        rs: 'Način bojenja',
        ua: 'Режим забарвлення',
        no: 'Fargemodus',
        it: 'Modalità di colorazione',
        se: 'Färgläggningsläge',
    },
    primaryColors: {
        us: 'Primary colors',
        cz: 'Základní barvy',
    },
    defaultColor: {
        us: 'Default/most frequent color',
        cz: 'Výchozí/nejčastější barva',
        pl: 'Domyślny/najczęstszy kolor',
        hr: 'Zadana/najčešća boja',
        si: 'Privzeta/najpogostejša barva',
        de: 'Standard/häufigste Farbe',
        sk: 'Predvolená/najčastejšia farba',
        es: 'Color predeterminado/más frecuente',
        ru: 'Цвет по умолчанию/наиболее часто используемый цвет',
        rs: 'Podrazumevana/najčešća boja',
        ua: 'Колір за замовчуванням/найпоширеніший колір',
        no: 'Standard/oftest brukt farge',
        it: 'Colore predefinito/più comune',
        se: 'Default/oftast förekommande färg',
    },
    chooseDefaultColor: {
        us: 'Choose default/most frequent color',
        cz: 'Zvolit výchozí/nejčastější barvu',
    },
    autoColor: {
        us: 'Assign alternating colors automatically',
        cz: 'Automaticky přiřadit střídající se barvy',
        pl: 'Automatycznie przypisz naprzemienne kolory',
        hr: 'Automatski dodijeli naizmjenične boje',
        si: 'Samodejno dodeli izmenične barve',
        de: 'Alternierende Farben automatisch zuweisen',
        sk: 'Automaticky priradiť striedajúce sa farby',
        es: 'Asignar automáticamente colores alternos',
        ru: 'Назначить чередующиеся цвета автоматически',
        rs: 'Automatski dodeli naizmenične boje',
        ua: 'Автоматичне призначення кольорів, що чергуються',
        no: 'Tildele vekslende farger automatisk',
        it: 'Assegnazione automatica di colori alternati',
        se: 'Automatiskt tilldela växlande färger',
    },
    assignColors: {
        us: 'Assign colors',
        cz: 'Přiřadit barvy',
        pl: 'Przypisz kolory',
        hr: 'Dodijeli boje',
        si: 'Dodeli barve',
        de: 'Farben zuweisen',
        sk: 'Priradiť farby',
        es: 'Asignar colores',
        ru: 'Назначить цвета',
        rs: 'Dodeli boje',
        ua: 'Призначити кольори',
        no: 'Tildele farger',
        it: 'Assegnazione dei colori',
        se: 'Tilldela färger',
    },
    colorSpeakerHint: {
        us: 'Change speaker color by clicking on the circle.',
        cz: 'Barvu mluvčího změníte kliknutím na kolečko.',
        pl: 'Aby zmienić kolor mówcy, kliknij w kółko.',
        hr: 'Klikom na krug promijenite boju govornika.',
        si: 'Spremenite barvo govorca s klikom na krog.',
        de: 'Ändern Sie die Farbe des Sprechers durch Klicken auf den Kreis.',
        sk: 'Zmeňte farbu rečníka kliknutím na koliesko.',
        es: 'Puedes cambiar el color del hablante haciendo clic en la círculo.',
        ru: 'Измените цвет диктора, щелкнув по кругу.',
        rs: 'Promenite boju govornika klikom na krug.',
        ua: 'Змінити колір спікера, натиснувши на коло.',
        no: 'Fargen på taleren endres ved å klikke på sirkelen.',
        it: 'Cambiare il colore dello speaker facendo clic sulla ruota.',
        se: 'Du ändrar färgen av talaren genom att klicka på cirkeln.',
    },
    showConflicts: {
        us: 'Show warning of neighbor speakers of the same color',
        cz: 'Zobrazit varování sousedních mluvčí stejné barvy',
        pl: 'Pokazuj ostrzeżenia o sąsiednich mówcach w tym samym kolorze',
        hr: 'Prikaži upozorenje susjednih govornika iste boje',
        si: 'Prikaži opozorilo sosednjih govorcev iste barve',
        de: 'Warnung vor benachbarten Sprechern der gleichen Farbe anzeigen',
        sk: 'Zobraziť varovanie susedných rečníkov rovnakej farby',
        es: 'Mostrar advertencias de hablantes adyacentes del mismo color',
        ru: 'Показать предупреждение о соседних колонках того же цвета',
        rs: 'Prikazivanje upozorenja o susednim govornicima iste boje',
        ua: 'Показувати попередження про сусідні колонки того ж кольору',
        no: 'Vise varsling om talere i samme farge ved siden av hverandre',
        it: 'Mostra gli avvisi di speaker adiacenti dello stesso colore',
        se: 'Visa varningen för angränsande talare med samma färg',
    },
    colorConflictError: {
        us: 'This speaker has the same color',
        cz: 'Tento mluvčí má stejnou barvu',
        pl: 'Ten mówca ma taki sam kolor',
        hr: 'Ovaj govornik ima istu boju',
        si: 'Ta govorec je enake barve',
        de: 'Dieser Sprecher hat dieselbe Farbe',
        sk: 'Tento rečník má rovnakú farbu',
        es: 'Este hablante tiene el mismo color',
        ru: 'Этот диктор имеет такой же цвет',
        rs: 'Ovaj govornik ima istu boju',
        ua: 'Цей динамік має такий самий колір',
        no: 'Denne taleren har den samme fargen',
        it: 'Questo speaker ha lo stesso colore',
        se: 'Denna talare har samma färg',
    },
    defaultSubsColor: {
        us: 'Default subtitles color',
        cz: 'Výchozí barva titulků',
        pl: 'Domyślny kolor napisów',
        hr: 'Zadana boja titlova',
        si: 'Privzeta barva podnapisov',
        de: 'Standardfarbe für Untertitel',
        sk: 'Predvolená farba titulkov',
        es: 'Color predeterminado de los subtítulos',
        ru: 'Цвет субтитров по умолчанию',
        rs: 'Podrazumevana boja titlova',
        ua: 'Колір субтитрів за замовчуванням',
        no: 'Standard farge av underteksten',
        it: 'Colore predefinito dei sottotitoli',
        se: 'Defaultfärgen på undertexterna',
    },
    downloadVideo: {
        us: 'Download video',
        cz: 'Stáhnout video',
        pl: 'Pobierz wideo',
        hr: 'Preuzmi video',
        si: 'Prenesi videoposnetek',
        de: 'Video herunterladen',
        sk: 'Stiahnuť video',
        es: 'Descargar video',
        ru: 'Скачать видео',
        rs: 'Preuzmi video',
        ua: 'Завантажити відео',
        no: 'Laste ned video',
        it: 'Scarica il video',
        se: 'Ladda ned videon',
    },
    downloadStarted: {
        us: 'Download started',
        cz: 'Stahování zahájeno',
        pl: 'Rozpoczęto pobieranie',
        hr: 'Preuzimanje započeto',
        si: 'Prenos se je začel',
        de: 'Herunterladen gestartet',
        sk: 'Sťahovanie zahájené',
        es: 'Descarga iniciada',
        ru: 'Начало загрузки',
        rs: 'Započeto preuzimanje',
        ua: 'Завантаження розпочато',
        no: 'Nedlasting startet',
        it: 'Scaricamento iniziato',
        se: 'Nedladdningen har påbörjats',
    },
    downloadedVideoLength: {
        us: 'Length of downloaded video',
        cz: 'Délka staženého videa',
        pl: 'Długość pobranego wideo',
        hr: 'Duljina preuzetog videozapisa',
        si: 'Dolžina prenesenega videoposnetka',
        de: 'Heruntergeladene Videolänge',
        sk: 'Dĺžka stiahnutého videa',
        es: 'Duración del video descargado',
        ru: 'Длина загруженного видео',
        rs: 'Dužina preuzetog videa',
        ua: 'Довжина завантаженого відео',
        no: 'Lengde av den nedlastede videoen',
        it: 'Lunghezza del video scaricato',
        se: 'Längden på den nedladdade videon',
    },
    subtitleLineLength: {
        us: 'Subtitle line length',
        cz: 'Délka řádku titulku',
        pl: 'Długość wiersza napisów',
        hr: 'Duljina retka titla',
        si: 'Dolžina vrstice podnapisa',
        de: 'Länge der Untertitelzeile',
        sk: 'Dĺžka riadku titulku',
        es: 'Longitud de línea de subtítulo',
        ru: 'Длина строки субтитров',
        rs: 'Dužina reda titla',
        ua: 'Довжина рядка субтитрів',
        no: 'Undertekstens linjelengde',
        it: 'Lunghezza della riga della didascalia',
        se: 'Längden på undertextraden',
    },
    cutVideoHint: {
        us: 'When cutting the video, download can take several minutes',
        cz: 'Při střihu videa může stahování trvat několik minut',
        pl: 'Pobranie przyciętego wideo może trwać kilka minut',
        hr: 'Prilikom rezanja videozapisa, preuzimanje može potrajati nekoliko minuta',
        si: 'Pri rezanju videoposnetka lahko prenos traja nekaj minut',
        de: 'Wenn Sie ein Video bearbeiten, kann der Download mehrere Minuten dauern',
        sk: 'Pri strihaní videa môže sťahovanie trvať niekoľko minút',
        es: 'Al cortar el video, la descarga puede tardar varios minutos',
        ru: 'При обрезке видео загрузка может занять несколько минут',
        rs: 'Prilikom sečenja videa, preuzimanje može potrajati nekoliko minuta',
        ua: 'При обрізанні відео завантаження може затягнутися на кілька хвилин',
        no: 'Ved videoklippingen kan nedlasting ta flere minutter',
        it: "Durante l'editing di un video, il download può richiedere diversi minuti.",
        se: 'Vid videoklippning kan nedladdningen ta flera minuter',
    },
    notRecognitionLanguage: {
        us: "The project language is not among transcription languages and can't have replacement rules.",
        cz: 'Jazyk projektu není mezi jazyky přepisu a nemůže mít pravidla nahrazování.',
        pl: 'Język projektu nie należy do języków transkrypcji, nie można więc zastosować reguł zamiany.',
        hr: 'Jezik projekta nije među transkripcijskim jezicima i ne može imati pravila zamjene.',
        si: 'Jezik projekta ni med transkripcijskimi jeziki in ne more imeti nadomestnih pravil.',
        de: 'Projektsprache gehört nicht zu den Transkriptionssprachen und kann keine Ersetzungsregeln haben.',
        sk: 'Jazyk projektu nepatrí medzi transkripčné jazyky a nemôže mať substitučné pravidlá.',
        es: 'El idioma del proyecto no se encuentra entre los idomas de transcripción y no puede tener reglas de sustitución.',
        ru: 'Язык проекта не входит в число языков транскрипции и не может иметь правила замены.',
        rs: 'Jezik projekta nije među jezicima za transkripciju i ne može imati pravila zamene.',
        ua: 'Мова проекту не належить до мов транскрипції і не може мати правил заміни.',
        no: 'Prosjektspråket ikke finnes blant transkripssjonspråket og ikke kan ha erstatningsregler.',
        it: 'La lingua del progetto non è tra le lingue di trascrizione e non può avere regole di sostituzione.',
        se: 'Projektspråket finns inte bland transkriberingsspråken och kan inte få ersättningsregler.',
    },
    replacementRuleInfo: {
        us: 'The incorrect transcription must have at least 5 characters and be replaced by at least 1 character.',
        cz: 'Nesprávný přepis musí mít alespoň 5 znaků a být nahrazen alespoň 1 znakem.',
        pl: 'Błędna transkrypcja musi zawierać co najmniej 5 znaków i musi zostać zastąpiona co najmniej 1 znakiem.',
        hr: 'Netočna transkripcija mora imati najmanje 5 znakova i mora biti zamijenjena s najmanje 1 znakom.',
        si: 'Napačen prepis mora imeti vsaj 5 znakov in mora biti nadomeščen z vsaj 1 znakom.',
        de: 'Falsche Transkription muss mindestens 5 Zeichen lang sein und durch mindestens 1 Zeichen ersetzt werden.',
        sk: 'Nesprávny prepis musí mať aspoň 5 znakov a musí byť nahradený aspoň 1 znakom.',
        es: 'La transcripción incorrecta debe tener al menos 5 caracteres y ser reemplazada por al menos 1 carácter.',
        ru: 'Неправильная транскрипция должна содержать не менее 5 символов и быть заменена не менее чем на 1 символ.',
        rs: 'Netačna transkripcija mora imati barem 5 karaktera i mora biti zamenjena sa barem 1 karakterom.',
        ua: 'Неправильна транскрипція повинна містити щонайменше 5 символів і бути замінена щонайменше на 1 символ.',
        no: 'Den feil transkripsjonen må ha minst 5 tegn og bli erstattet med minst 1 tegn.',
        it: 'La trascrizione errata deve avere almeno 5 caratteri e deve essere sostituita da almeno 1 carattere.',
        se: 'En felaktig transkription ska innehålla minst 5 tecken och ska ersättas med minst ett tecken.',
    },
    replacementRuleError: {
        us: 'The incorrect transcription must have at least 5 characters.',
        cz: 'Nesprávný přepis musí mít alespoň 5 znaků.',
        pl: 'Błędna transkrypcja musi zawierać co najmniej 5 znaków.',
        hr: 'Netočna transkripcija mora imati najmanje 5 znakova.',
        si: 'Napačen prepis mora imeti vsaj 5 znakov.',
        de: 'Falsche Transkription muss mindestens 5 Zeichen lang sein.',
        sk: 'Nesprávny prepis musí mať aspoň 5 znakov.',
        es: 'La transcripción incorrecta debe tener al menos 5 caracteres.',
        ru: 'Неправильная транскрипция должна содержать не менее 5 символов.',
        rs: 'Netačna transkripcija mora imati barem 5 karaktera.',
        ua: 'Неправильна транскрипція повинна містити щонайменше 5 символів.',
        no: 'Den feil transkripsjonen må ha minst 5 tegn.',
        it: 'Una trascrizione errata deve essere lunga almeno 5 caratteri.',
        se: 'En felaktig transkription ska innehålla minst 5 tecken.',
    },
    keycloakLoginFailed: {
        us: 'Login with Keycloak failed',
        cz: 'Přihlášení přes Keycloak selhalo',
        pl: 'Logowanie za pomocą Keycloak nie powiodło się',
        hr: 'Prijava Keycloakom nije uspjela',
        si: 'Prijava s Keycloakom ni uspela',
        de: 'Login mit Keycloak fehlgeschlagen',
        sk: 'Prihlásenie pomocou Keycloak zlyhalo',
        es: 'El inicio de sesión por Keycloak fallo',
        ru: 'Вход в систему с помощью Keycloak не удался',
        rs: 'Prijavljivanje sa Keycloak-om nije uspelo',
        ua: 'Не вдалося увійти за допомогою Keycloak',
        no: 'Innlogging med Keycloak mislyktes',
        it: 'Accesso tramite Keycloak non riuscito',
        se: 'Inloggning med Keycloak misslyckades',
    },
    loginWithKeycloak: {
        us: 'Login with Keycloak',
        cz: 'Přihlášení pomocí Keycloak',
        pl: 'Zaloguj się za pomocą Keycloak',
        hr: 'Prijavi se Keycloakom',
        si: 'Prijava s Keycloakom',
        de: 'Mit Keycloak einloggen',
        sk: 'Prihlásenie pomocou Keycloak',
        es: 'Iniciar sesión con Keycloak',
        ru: 'Войдите с помощью Keycloak',
        rs: 'Prijavljivanje sa Keycloak-om',
        ua: 'Увійдіть за допомогою Keycloak',
        no: 'Innlogging med Keycloak',
        it: 'Accesso con Keycloak',
        se: 'Inloggning med Keycloak',
    },
    continueWithApa: {
        us: 'Continue with PR-Desk',
        cz: 'Pokračovat s PR-Desk',
        pl: 'Kontynuuj z PR-Desk',
        hr: 'Nastavite s PR-Desk',
        si: 'Nadaljujte s PR-Desk',
        de: 'Fortfahren mit PR-Desk',
        sk: 'Pokračovať s PR-Desk',
        es: 'Continuar con PR-Desk',
        ru: 'Продолжить работу с PR-Desk',
        rs: 'Nastavite sa PR-Desk-om',
        ua: 'Продовжити з PR-Desk',
        no: 'Fortsette med PR-Desk',
        it: 'Continua con PR-Desk',
        se: 'Fortsätta med PR-Desk',
    },
    speakerFreq: {
        us: 'Number of speech utterances in the project',
        cz: 'Množství promluv v projektu',
        pl: 'Liczba wypowiedzi w projekcie',
        hr: 'Broj iskaza u projektu',
        si: 'Število govornih izrazov v projektu',
        de: 'Anzahl der Sprachäußerungen im Projekt',
        sk: 'Počet rečových prejavov v projekte',
        es: 'Número de expresiones orales en el proyecto',
        ru: 'Количество речевых высказываний в проекте',
        rs: 'Broj govornih izjava u projektu',
        ua: 'Кількість мовних висловлювань у проекті',
        no: 'Mengden av taler i prosjektet',
        it: 'Numero di interventi nel progetto',
        se: 'Antal yttranden i projektet',
    },
    cutVideos: {
        us: 'Cut videos',
        cz: 'Video střihy',
        pl: 'Przycinanie plików wideo',
        hr: 'Izreži videozapise',
        si: 'Izreži video posnetke',
        de: 'Geschnittene Videos',
        sk: 'Strihy videí',
        es: 'Cortar videos',
        ru: 'Вырезать видео',
        rs: 'Sečenje videa',
        ua: 'Обрізати відео',
        no: 'Klippe videoer',
        it: 'Tagli video',
        se: 'Klippa videor',
    },
    cutVideo: {
        us: 'Cut video',
        cz: 'Střih videa',
        pl: 'Przycinanie pliku wideo',
        hr: 'Izreži videozapis',
        si: 'Izreži video posnetek',
        de: 'Video schneiden',
        sk: 'Strih videa',
        es: 'Cortar video',
        ru: 'Нарезанное видео',
        rs: 'Seci video',
        ua: 'Вирізати відео',
        no: 'Klippe video',
        it: 'Montaggio video',
        se: 'Klippa video',
    },
    signInApa: {
        us: 'Login with APA',
        cz: 'Přihlášení přes APA',
        pl: 'Zaloguj się przez APA',
        hr: 'Prijavi se APA',
        si: 'Prijava s APA',
        de: 'Login mit APA',
        sk: 'Prihlásenie pomocou APA',
        es: 'Iniciar sesión con APA',
        ru: 'Войти с помощью APA',
        rs: 'Prijavljivanje sa APA',
        ua: 'Увійдіть за допомогою APA',
        no: 'Innlogging med APA',
        it: 'Accesso tramite APA',
        se: 'Inloggning med APA',
    },
    keywords: {
        us: 'Keywords',
        cz: 'Klíčová slova',
        pl: 'Słowa kluczowe',
        hr: 'Ključne riječi',
        si: 'Ključne besede',
        de: 'Schlüsselwörter',
        sk: 'Kľúčové slová',
        es: 'Palabras claves',
        ru: 'Ключевые слова',
        rs: 'Ključne reči',
        ua: 'Ключові слова',
        no: 'Nøkkelord',
        it: 'Parole chiave',
        se: 'Nyckelord',
    },
    storageStatistics: {
        us: 'Storage used',
        cz: 'Využité úložiště',
        pl: 'Wykorzystane miejsce',
        hr: 'Iskorištena pohrana',
        si: 'Porabljen prostor',
        de: 'Verwendeter Speicherplatz',
        sk: 'Využité úložisko',
        es: 'Almacenamiento utilizado',
        ru: 'Используемое хранилище',
        rs: 'Iskorišćen prostor',
        ua: 'Використовуване сховище',
        no: 'Lagring brukt',
        it: 'Spazio di archiviazione utilizzato',
        se: 'Använt lagringsutrymme',
    },
    sharedWithTeamCount: {
        us: 'Shared with (number of teams):',
        cz: 'Sdíleno s (počet teamů):',
        pl: 'Udostępnione (liczba teamów):',
        hr: 'Podijeljeno s (broj timova):',
        si: 'V skupni rabi z (število ekip):',
        de: 'Geteilt mit (Anzahl der Teams):',
        sk: 'Zdieľané s (počet tímov):',
        es: 'Compartido con (número de equipos):',
        ru: 'Совместно с (количество команд):',
        rs: 'Podeljeno sa (broj timova):',
        ua: 'Поділитися з (кількість команд):',
        no: 'Delt med (antall team): ',
        it: 'Condiviso con (numero di team):',
        se: 'Delat med (antal team):',
    },
    displayStorageStatistics: {
        us: 'Display storage statistics',
        cz: 'Zobrazit údaje o využitém uložišti',
        pl: 'Wyświetl dane o wykorzystanym miejscu',
        hr: 'Prikaži statistiku pohrane',
        si: 'Prikaz statistike shranjevanja',
        de: 'Speicherstatistik anzeigen',
        sk: 'Zobraziť údaje o využitom úložisku',
        es: 'Mostrar estadìsticas de almacenamiento',
        ru: 'Отображение статистики по хранилищу',
        rs: 'Prikazivanje statistike skladišta',
        ua: 'Показати статистику сховища',
        no: 'Vis lagringsstatistikk',
        it: 'Visualizza i dettagli dello spazio di archiviazione utilizzato',
        se: 'Visa uppgifter om använt lagringsutrymme',
    },
    longLoading: {
        us: 'Loading can take several minutes, do not close window',
        cz: 'Načítání může trvat několik minut, nezavírejte okno prohlížeče',
        pl: 'Ładowanie może potrwać kilka minut, nie zamykaj okna',
        hr: 'Učitavanje može potrajati nekoliko minuta, ne zatvarajte prozor',
        si: 'Nalaganje lahko traja nekaj minut, ne zapirajte okna',
        de: 'Laden kann mehrere Minuten dauern, Fenster nicht schließen',
        sk: 'Načítanie môže trvať niekoľko minút, nezatvárajte okno prehliadača',
        es: 'La carga puede tardar varios minutos, no cierre la ventana',
        ru: 'Загрузка может занять несколько минут, не закрывайте окно',
        rs: 'Učitavanje može potrajati nekoliko minuta, ne zatvarajte prozor',
        ua: 'Завантаження може зайняти кілька хвилин, не закривайте вікно',
        no: 'Opplasting kan ta flere minutter, ikke lukk vinduet',
        it: 'Il caricamento può richiedere alcuni minuti, non chiudere la finestra del browser.',
        se: 'Det kan ta några minuter att ladda upp uppgifterna, stäng inte fliken',
    },
    showSectionTags: {
        us: 'Show tags next to the section name (Heading 2) in the editor.',
        cz: 'Zobrazit štítky u názvu sekce (Nadpis 2) v editoru.',
        pl: 'Pokaż tagi obok nazwy sekcji (Nagłówek 2) w edytorze.',
        hr: 'Prikaži oznake pored naziva odjeljka (naslov 2) u editoru.',
        si: 'Prikaži oznake poleg imena razdelka (Naslov 2) v urejevalniku.',
        de: 'Tags neben dem Abschnittsnamen (Überschrift 2) im Editor anzeigen.',
        sk: 'Zobraziť štítky pri názve sekcie (Nadpis 2) v editore.',
        es: 'Mostrar etiquetas junto al nombre de la sección (Encabezado 2) en el editor.',
        ru: 'Показывать в редакторе теги рядом с названием раздела (Заголовок 2).',
        rs: 'Prikazivanje oznaka pored naziva sekcije (Naslov 2) u uređivaču.',
        ua: 'Показувати теги поруч з назвою розділу (заголовок 2) в редакторі.',
        no: 'Vis etiketter ved siden av tittelen (Overskrift 2) i editoren.',
        it: "Mostra le etichette accanto al titolo della sezione (Titolo 2) nell'editor.",
        se: 'Visa taggar vid sidan av avsnittstiteln (Rubrik 2) i editorn',
    },
    summaries: {
        us: 'Transcription summaries',
        cz: 'Shrnutí přepisu',
        pl: 'Streszczenia transkrypcji',
        hr: 'Sažeci transkripcije',
        si: 'Povzetki prepisov',
        de: 'Transkriptionszusammenfassungen',
        sk: 'Zhrnutie prepisu',
        es: 'Resúmenes de la transcripción',
        ru: 'Резюме транскрипции',
        rs: 'Sažeci transkripcije',
        ua: 'Транскрипційні зведення',
        no: 'Oppsummering av transkripsjon',
        it: 'Riepilogo della trascrizione',
        se: 'Sammanfattning av transkriptionen',
    },
    missingPermission: {
        us: "You don't have permission to use this feature",
        cz: 'Nemáte oprávnění pro tuto funkci',
        pl: 'Nie posiadasz uprawnień do tej funkcji',
        hr: 'Nemate dozvolu za upotrebu ove značajke',
        si: 'Nimate dovoljenja za uporabo te funkcije',
        de: 'Sie haben nicht Berechtigung, diese Funktion zu nutzen',
        sk: 'Nemáte oprávnenie pre túto funkciu',
        es: 'No tienes permiso para usar esta función',
        ru: 'У вас нет разрешения на использование этой функции',
        rs: 'Nemate dozvolu da koristite ovu funkciju',
        ua: 'У вас немає дозволу на використання цієї функції',
        no: 'Du har ikke rettigheter til å bruke denne funksjonen',
        it: "Non hai l'autorizzazione per questa funzione",
        se: 'Du har inte rättigheter till denna funktion',
    },
    showSummaries: {
        us: 'Display option to generate AI summary of a transcription section.',
        cz: 'Zobrazit možnost vytvoření AI shrnutí sekce přepisu.',
        pl: 'Pokaż opcję tworzenia streszczeń AI sekcji transkrypcji.',
        hr: 'Prikaz opcije za generiranje AI sažetka odjeljka transkripcije.',
        si: 'Možnost prikaza za ustvarjanje AI povzetka razdelka prepisa.',
        de: 'Anzeigeoption für Erstellung der AI-Zusammenfassung eines Transkriptionsabschnitts.',
        sk: 'Zobrazenie možnosti vytvorenia súhrnu AI časti prepisu.',
        es: 'Opción de visualización para generar el resumen AI de una sección de transcripción.',
        ru: 'Отображение возможности создания AI-резюме раздела транскрипции.',
        rs: 'Prikaz opcije za generisanje AI sažetka transkripcijskog odeljka.',
        ua: 'Відображати опцію для створення AI-резюме розділу транскрипції.',
        no: 'Vis muligheten til å generere en AI oppsummering av en del av transkripsjonen.',
        it: "Mostra l'opzione per creare un sommario AI di una parte della trascrizione.",
        se: 'Visa möjligheten att skapa en AI-sammanfattning av ett avsnitt ur transkriptionen.',
    },
    hideKeywords: {
        us: 'Hide keywords',
        cz: 'Skrýt klíčová slova',
        pl: 'Ukryj słowa kluczowe',
        hr: 'Sakrij ključne riječi',
        si: 'Skrij ključne besede',
        de: 'Schlüsselwörter ausblenden',
        sk: 'Skryť kľúčové slová',
        es: 'Ocultar palabras claves',
        ru: 'Скрыть ключевые слова',
        rs: 'Sakrij ključne reči',
        ua: 'Приховати ключові слова',
        no: 'Skjule nøkkelord',
        it: 'Nascondi le parole chiave',
        se: 'Dölja nyckelord',
    },
    sectionName: {
        us: 'Section name',
        cz: 'Název sekce',
        pl: 'Nazwa sekcji',
        hr: 'Naziv odjeljka',
        si: 'Ime razdelka',
        de: 'Sektionsname',
        sk: 'Názov sekcie',
        es: 'Nombre de la sección',
        ru: 'Название раздела',
        rs: 'Naziv sekcije',
        ua: 'Назва розділу',
        no: 'Navn på seksjonen',
        it: 'Nome della sezione',
        se: 'Avsnittsnamn',
    },
    sectionNames: {
        us: 'Section names',
        cz: 'Názvy sekcí',
        pl: 'Nazwy sekcji',
        hr: 'Nazivi odjeljaka',
        si: 'Imena razdelkov',
        de: 'Sektionsnamen',
        sk: 'Názvy sekcií',
        es: 'Nombres de las secciones',
        ru: 'Названия разделов',
        rs: 'Nazivi sekcija',
        ua: 'Назви розділів',
        no: 'Navn på seksjonene',
        it: 'Nomi delle sezioni',
        se: 'Avsnittsnamn',
    },
    selectSectionName: {
        us: 'Select section name',
        cz: 'Zvolte název sekce',
        pl: 'Wybierz nazwę sekcji',
        hr: 'Odaberi naziv odjeljka',
        si: 'Izberite ime razdelka',
        de: 'Sektionsname auswählen',
        sk: 'Vyberte názov sekcie',
        es: 'Seleccionar nombre de sección',
        ru: 'Выберите название раздела',
        rs: 'Izaberite naziv sekcije',
        ua: 'Виберіть назву розділу',
        no: 'Velg navn på seksjonen',
        it: 'Selezionare un nome di sezione',
        se: 'Välj avsnittsnamn',
    },
    sectionNameValidation: {
        us: 'Section name format is invalid',
        cz: 'Jméno sekce je v neplatném formátu',
        pl: 'Nazwa sekcji ma nieprawidłowy format',
        hr: 'Format naziva odjeljka nije valjan',
        si: 'Oblika imena razdelka ni veljavna',
        de: 'Das Format des Sektionsnamens ist ungültig',
        sk: 'Názov sekcie je v neplatnom formáte',
        es: 'El formato del nombre de la sección es invalido',
        ru: 'Неверный формат названия раздела',
        rs: 'Format naziva sekcije nije validan',
        ua: 'Неправильний формат назви розділу',
        no: 'Navnet på seksjonen er ikke i gyldig format',
        it: 'Il nome della sezione è in un formato non valido',
        se: 'Avsnittsnamnet är inte i giltigt format',
    },
    sectionNameChanged: {
        us: 'Section name is missing in the list',
        cz: 'Jméno sekce není v seznamu',
        pl: 'Na liście nie ma nazwy sekcji',
        hr: 'Na popisu nedostaje naziv odjeljka',
        si: 'Na seznamu manjka ime razdelka',
        de: 'Der Sektionsname fehlt in der Liste',
        sk: 'Názov sekcie nie je uvedený v zozname',
        es: 'El nombre de la sección no está en la lista',
        ru: 'Название раздела отсутствует в списке',
        rs: 'Nedostaje naziv sekcije u listi',
        ua: 'Назва розділу відсутня у списку',
        no: 'Navnet på seksjonen finnes ikke på lista',
        it: "Il nome della sezione non è presente nell'elenco",
        se: 'Avsnittsnamnet finns inte på listan',
    },
    captionPlacementError: {
        us: 'Caption ends cannot be inserted in headings or summaries.',
        cz: 'Konce titulku nelze vložit do nadpisů nebo shrnutí',
        pl: 'Koniec napisu nie może znajdować się w nagłówku ani streszczeniu.',
        hr: 'Krajevi titlova ne mogu se umetnuti u naslove ili sažetke.',
        si: 'Koncev napisov ni mogoče vstaviti v naslove ali povzetke.',
        de: 'Untertitelenden können nicht in Überschriften oder Zusammenfassungen eingefügt werden.',
        sk: 'Koncové titulky nie je možné vložiť do nadpisov alebo súhrnov',
        es: 'Los extremos de los subtítulos no pueden insertarse en los títulos ni en los resúmenes.',
        ru: 'В заголовки и резюме нельзя вставлять концы надписей.',
        rs: 'Krajevi titlova ne mogu biti umetnuti u naslove ili sažetke.',
        ua: 'Кінцівки підписів не можна вставляти в заголовки або анотації.',
        no: 'Undertekstslutter kan ikke settes inn i overskrifter og oppsummeringer.',
        it: 'I sottotitoli finali non possono essere inseriti nelle intestazioni o nei sommari.',
        se: 'Det går inte att infogas undertextslut i rubriker eller sammanfattningar.',
    },
    timestampPlacementError: {
        us: 'Timestamps cannot be inserted in headings or summaries.',
        cz: 'Časové značky nelze vložit do nadpisů nebo shrnutí.',
        pl: 'Znacznik czasowy nie może znajdować się w nagłówku ani streszczeniu.',
        hr: 'Vremenske oznake ne mogu se umetnuti u naslove ili sažetke.',
        si: 'Časovnih žigov ni mogoče vstaviti v naslove ali povzetke.',
        de: 'Zeitstempel können nicht in Überschriften oder Zusammenfassungen eingefügt werden.',
        sk: 'Do nadpisov alebo súhrnov nemožno vkladať časové značky.',
        es: 'Las marcas de tiempo no pueden insertarse en los títulos ni en los resúmenes.',
        ru: 'В заголовки и резюме нельзя вставлять временные метки.',
        rs: 'Vremenski žigovi ne mogu biti umetnuti u naslove ili sažetke.',
        ua: 'Мітки часу не можна вставляти в заголовки або анотації.',
        no: 'Tidsstempler kan ikke settes inn i overskrifter og oppsummeringer.',
        it: 'I timestamp non possono essere inseriti nelle intestazioni o nei sommari.',
        se: 'Det går inte att infogas tidsstämplar i rubriker eller sammanfattningar.',
    },
    headingPlacementError: {
        us: 'Heading format cannot be used in summaries.',
        cz: 'Formát nadpisů nelze použít v shrnutích.',
        pl: 'W streszczeniu nie jest możliwe wstawienie formatu nagłówka.',
        hr: 'Format naslova ne može se koristiti u sažecima.',
        si: 'Format naslova ne more biti uporabljen v povzetkih.',
        de: 'Überschriftenformat kann in Zusammenfassungen nicht verwendet werden.',
        sk: 'Formát nadpisov nemožno použiť v súhrnoch.',
        es: 'El formato de encabezado no puede utilizarse en los resúmenes',
        ru: 'Формат заголовков не может быть использован в резюме.',
        rs: 'Format naslova ne može biti korišćen u sažecima.',
        ua: 'Формат заголовка не можна використовувати в анотаціях.',
        no: 'Overskriftsformat kan ikke brukes i oppsummeringer.',
        it: 'Il formato dei titoli non può essere usato nei sommari.',
        se: 'Det går inte att använda rubrikformat i sammanfattningar.',
    },
    speakerSummaryError: {
        us: 'Speakers cannot be assigned in summaries.',
        cz: 'V shrnutí nelze přiřadit mluvčí.',
        pl: 'W streszczeniu nie jest możliwe przypisanie mówcy.',
        hr: 'Govornici se ne mogu dodijeliti u sažecima.',
        si: 'Govornikov ni mogoče dodeliti v povzetkih.',
        de: 'Sprecher können in Zusammenfassungen nicht zugewiesen werden.',
        sk: 'V súhrne nie je možné priradiť rečníkov.',
        es: 'No se pueden asignar ponentes en los resúmenes.',
        ru: 'В резюме нельзя назначать докладчиков.',
        rs: 'Govornici se ne mogu dodeliti u sažecima.',
        ua: 'У резюме не можна призначати доповідачів.',
        no: 'Brukere kan ikke bli tillagt i oppsummeringer.',
        it: 'I relatori non possono essere assegnati in un sommario.',
        se: 'Det går inte att tilldela talare i sammanfattningar.',
    },
    summaryLanguage: {
        us: 'Language of summary',
        cz: 'Jazyk shrnutí',
        pl: 'Język streszczenia',
        hr: 'Jezik sažetka',
        si: 'Jezik povzetka',
        de: 'Sprache der Zusammenfassung',
        sk: 'Jazyk súhrnu',
        es: 'Lenguaje del resumen',
        ru: 'Язык резюме',
        rs: 'Jezik sažetka',
        ua: 'Мова резюме',
        no: 'Språket på oppsummering',
        it: 'Lingua del riassunto',
        se: 'Språket på sammanfattningen',
    },
    generateSummary: {
        us: 'Generate AI summary',
        cz: 'Vytvořit AI shrnutí',
        pl: 'Wygeneruj streszczenie AI',
        hr: 'Generiranje AI sažetka',
        si: 'Ustvari AI povzetek',
        de: 'AI-Zusammenfassung generieren',
        sk: 'Vytvorenie súhrnu AI',
        es: 'Generar resumen AI',
        ru: 'Создать резюме AI',
        rs: 'Generiši AI sažetak',
        ua: 'Створити резюме зі штучним інтелектом',
        no: 'Generere AI oppsummering',
        it: 'Creare il sommario AI',
        se: 'Generera en AI-sammanfattning',
    },
    summaryError: {
        us: 'Summarization failed. Please try it again.',
        cz: 'Při vytváření shrnutí došlo k chybě. Zkuste to prosím znovu.',
        pl: 'Generowanie streszczenia nie powiodło się. Spróbuj ponownie.',
        hr: 'Sažimanje nije uspjelo. Pokušajte ponovno.',
        si: 'Povzemanje ni uspelo. Poskusite znova.',
        de: 'Zusammenfassung fehlgeschlagen. Bitte versuchen Sie es erneut.',
        sk: 'Pri vytváraní súhrnu došlo k chybe. Skúste to prosím znova.',
        es: 'Error de resumen. Por favor, inténtelo de nuevo.',
        ru: 'Не удалось создать резюме. Попробуйте сделать это еще раз.',
        rs: 'Generisanje sažetka nije uspelo. Molimo pokušajte ponovo.',
        ua: 'Не вдалося створити анотацію. Будь ласка, спробуйте ще раз.',
        no: 'Oppsummering mislyktes. Vennligst prøv igjen.',
        it: 'Si è verificato un errore durante la creazione del sommario. Riprovi.',
        se: 'Det gick inte att skapa en sammanfattning. Vänligen prova om.',
    },
    generate: {
        us: 'Generate',
        cz: 'Vytvořit',
        pl: 'Wygeneruj',
        hr: 'Generiraj',
        si: 'Ustvari',
        de: 'Erzeugen',
        sk: 'Vytvoriť',
        es: 'Generar',
        ru: 'Генерировать',
        rs: 'Generiši',
        ua: 'Створити',
        no: 'Generere',
        it: 'Creare',
        se: 'Generera',
    },
    generatingSummary: {
        us: 'Generating summary...',
        cz: 'Generuji shrnutí...',
        pl: 'Generuję streszczenie...',
        hr: 'Generiranje sažetka...',
        si: 'Ustvarjanje povzetka...',
        de: 'Zusammenfassung wird generiert...',
        sk: 'Generovanie súhrnu...',
        es: 'Generarando resumen...',
        ru: 'Генерирование резюме...',
        rs: 'Generisanje sažetka...',
        ua: 'Створення резюме...',
        no: 'Genererer oppsummering ...',
        it: 'Generazione del sommario...',
        se: 'Sammanfattningen genereras...',
    },
    showKeywordsAvailable: {
        us: 'Show keywords if available',
        cz: 'Zobrazit dostupná klíčová slova',
        pl: 'Wyświetl słowa kluczowe, jeśli są dostępne',
        hr: 'Prikaži ključne riječi ako su dostupne',
        si: 'Prikaži ključne besede, če so na voljo',
        de: 'Schlüsselwörter anzeigen, falls vorhanden',
        sk: 'Zobraziť dostupné kľúčové slová',
        es: 'Mostrar palabras claves si están disponibles',
        ru: 'Показать ключевые слова, если они доступны',
        rs: 'Prikazi ključne reči ako su dostupne',
        ua: 'Показати ключові слова, якщо вони доступні',
        no: 'Vise søkeord hvis tilgjengelig',
        it: 'Mostra le parole chiave disponibili',
        se: 'Visa tillgängliga nyckelord',
    },
    errorDetail: {
        us: 'Error detail',
        cz: 'Detail chyby',
        pl: 'Szczegóły błędu',
        hr: 'Pojedinosti o pogrešci',
        si: 'Podrobnosti o napaki',
        de: 'Fehler-Detail',
        sk: 'Detail chyby',
        es: 'Detalle de error',
        ru: 'Детализация ошибок',
        rs: 'Detalji greške',
        ua: 'Детальна інформація про помилку',
        no: 'Feildetalj',
        it: 'Dettaglio errori',
        se: 'Felinformation',
    },
    translateProject: {
        us: 'Translate project',
        cz: 'Přeložit projekt',
        pl: 'Przetłumacz projekt',
        hr: 'Prevedi projekt',
        si: 'Prevedi projekt',
        de: 'Projekt übersetzen',
        sk: 'Preložiť projekt',
        es: 'Traducir proyecto',
        ru: 'Перевести проект',
        rs: 'Prevedi projekat',
        ua: 'Перекласти проект',
        no: 'Oversette prosjektet',
        it: 'Tradurre il progetto',
        se: 'Översätt projekt',
    },
    downloadAudio: {
        us: 'Download audio',
        cz: 'Stáhnout audio',
        pl: 'Pobierz audio',
        hr: 'Preuzmi audiozapis',
        si: 'Prenesi avdio posnetek',
        de: 'Audio herunterladen',
        sk: 'Stiahnuť audio',
        es: 'Descargar audio',
        ru: 'Скачать аудио',
        rs: 'Preuzmi audio',
        ua: 'Завантажити аудіо',
        no: 'Laste ned lyd',
        it: "Scaricare l'audio",
        se: 'Ladda ned ljud',
    },
    cutAudio: {
        us: 'Cut audio',
        cz: 'Střih audia',
        pl: 'Cięcie audio',
        hr: 'Izreži audiozapis',
        si: 'Izreži avdio posnetek',
        de: 'Audio schneiden',
        sk: 'Strih audia',
        es: 'Cortar audio',
        ru: 'Вырезать аудио',
        rs: 'Seci audio',
        ua: 'Обрізати аудіо',
        no: 'Klippe lyd',
        it: 'Editing audio',
        se: 'Klippa ljud',
    },
    cutAudios: {
        us: 'Audio cuts',
        cz: 'Audio střihy',
        pl: 'Cięcie audio',
        hr: 'Izreži audiozapise',
        si: 'Izreži avdio posnetke',
        de: 'Audios schneiden',
        sk: 'Strih audií',
        es: 'Cortar audios',
        ru: 'Вырезать аудиозаписи',
        rs: 'Audio isečci',
        ua: 'Обрізати аудіо',
        no: 'Klippe lyder',
        it: "Modifica dell'audio",
        se: 'Ljudklipp',
    },
    downloadedAudioLength: {
        us: 'Length of downloaded audio',
        cz: 'Délka staženého audia',
        pl: 'Długość pobranego audio',
        hr: 'Duljina preuzetog audiozapisa',
        si: 'Dolžina prenesenega avdio posnetka',
        de: 'Länge des heruntergeladenen Audios',
        sk: 'Dĺžka stiahnutého audia',
        es: 'Longitud del audio descargado',
        ru: 'Длина загруженного аудио',
        rs: 'Dužina preuzetog audio zapisa',
        ua: 'Довжина завантаженого аудіо',
        no: 'Lengden av den nedlastede lyden',
        it: "Lunghezza dell'audio scaricato",
        se: 'Längden på det nedladdade ljudet',
    },
    noFilesToDownload: {
        us: 'You have currently no files to download.',
        cz: 'Aktuálně nemáte žádné soubory ke stažení.',
        pl: 'Aktualnie nie masz żadnych plików do pobrania.',
        hr: 'Trenutačno nemate datoteka za preuzimanje.',
        si: 'Trenutno nimate datotek za prenos.',
        de: 'Sie haben derzeit keine Dateien zum Herunterladen.',
        sk: 'Aktuálne nemáte žiadne súbory na stiahnutie.',
        es: 'Actualmente no tienes archivos para descargar.',
        ru: 'В настоящее время у вас нет файлов для загрузки.',
        rs: 'Trenutno nemate fajlova za preuzimanje.',
        ua: 'Наразі у вас немає файлів для завантаження.',
        no: 'Du har for øyeblikket ingen fil som kan lastes ned.',
        it: 'Al momento non si dispone di alcun download.',
        se: 'Du finns inga filer att laddas ned för närvarande.',
    },
    sharedCredit: {
        us: 'Team member shares the team credit',
        cz: 'Člen týmu sdílí kredit s týmem',
        pl: 'Członek teamu dzieli się kredytem z teamem',
        hr: 'Član tima dijeli kredit s timom',
        si: 'Član ekipe deli kredit z ekipo',
        de: 'Teammitglied teilt den Kredit des Teams',
        sk: 'Člen tímu zdieľa kredit s tímom',
        es: 'El miembro del equipo comparte el mérito del equipo',
        ru: 'Член команды разделяет командные заслуги',
        rs: 'Član tima deli kredit tima',
        ua: 'Учасник команди ділиться командним кредитом',
        no: 'Teammedlem deler kreditt med teamet',
        it: 'Il membro del team condivide il credito con il team',
        se: 'Teammedlem delar sin kredit med teamet',
    },
    roleExample: {
        us: 'e.g. interviewer',
        cz: 'např. moderátor',
        pl: 'np. moderator',
        hr: 'npr. moderator',
        si: 'npr. moderator',
        de: 'z.B. Interviewer',
        sk: 'napr. moderátor',
        es: 'p. ej. entrevistador',
        ru: 'например, интервьюер',
        rs: 'npr. intervjuer',
        ua: "наприклад, інтерв'юер",
        no: 'f.eks. ordstyrer',
        it: 'ad esempio, moderatore',
        se: 't. ex. programledare',
    },
    createSpeaker: {
        us: '+ Create speaker',
        cz: '+ Vytvořit mluvčího',
        pl: '+ Utwórz mówcę',
        hr: '+ Kreiraj govornika',
        si: '+ Ustvari govorca',
        de: '+ Sprecher erstellen',
        sk: '+ Vytvoriť rečníka',
        es: '+ Crear hablante',
        ru: '+ Создать диктора',
        rs: '+ Kreiraj govornika',
        ua: '+ Створити спікера',
        no: '+ opprette en taler',
        it: '+ Creare uno speaker',
        se: '+ Skapa talare',
    },
    replaceAllInfo: {
        us: 'All speaker occurrences in the project will be replaced',
        cz: 'Nahradí všechny výskyty mluvčího v projektu',
        pl: 'Zastąpi wszystkie wystąpienia mówcy w projekcie',
        hr: 'Sva pojavljivanja govornika u projektu bit će zamijenjena',
        si: 'Vsi pojavi govorca v projektu bodo zamenjani',
        de: 'Alle Vorkommen von Sprecher im Projekt werden ersetzt',
        sk: 'Všetky výskyty rečníka v projekte budú nahradené',
        es: 'Se reemplazará el hablante en todas partes del proyecto',
        ru: 'Все вхождения диктора в проект будут заменены',
        rs: 'Sve pojave govornika u projektu biće zamenjene',
        ua: 'Всі входження спікера в проекті будуть замінені',
        no: 'Erstatter alle forekomstene av taleren i prosjektet',
        it: 'Sostituisce tutte le occorrenze degli speaker nel progetto',
        se: 'Alla förekomster av talaren i projektet kommer att ersättas',
    },
    replaceWithSpeaker: {
        us: 'Replace with speaker:',
        cz: 'Nahradit mluvčím:',
        pl: 'Zastąp mówcą:',
        hr: 'Zamijenite govornikom:',
        si: 'Zamenjaj z govorcem:',
        de: 'Ersetzen durch Sprecher:',
        sk: 'Nahradiť rečníkom:',
        es: 'Reemplazar con el hablante:',
        ru: 'Заменить на диктора:',
        rs: 'Zameni sa govornikom:',
        ua: 'Замінити спікером:',
        no: 'Erstatte med:',
        it: 'Sostituire con uno speaker:',
        se: 'Ersätta med talare:',
    },
    replaceAllWithSpeaker: {
        us: 'Replace everywhere with speaker:',
        cz: 'Nahradit všude mluvčím:',
        pl: 'Zastąp wszędzie mówcą:',
        hr: 'Svugdje zamijenite govornikom:',
        si: 'Povsod  zamenjaj z govorcem:',
        de: 'Überall durch Sprecher ersetzen:',
        sk: 'Nahradiť všade rečníkom:',
        es: 'Reemplazar en todas partes con el hablante:',
        ru: 'Заменить везде на speaker:',
        rs: 'Zameni svuda sa govornikom:',
        ua: 'Замінити скрізь на спікера:',
        no: 'Erstatte overalt med:',
        it: 'Sostituisce ovunque con uno speaker:',
        se: 'Ersätta överallt med talare:',
    },
    projectDeletion: {
        us: 'Project deletion',
        cz: 'Mazání projektů',
        pl: 'Usuwanie projektów',
        hr: 'Brisanje projekta',
        si: 'Brisanje projekta',
        de: 'Projekt-Löschung',
        sk: 'Mazanie projektov',
        es: 'Eliminación de proyectos',
        ru: 'Удаление проекта',
        rs: 'Brisanje projekta',
        ua: 'Видалення проєкту',
        no: 'Sletting av prosjekter',
        it: 'Elimina i progetti',
        se: 'Radering av projekt',
    },
    transcriptions: {
        us: 'Transcriptions',
        cz: 'Přepisy',
        pl: 'Transkrypcje',
        hr: 'Transkripti',
        si: 'Prepisi',
        de: 'Transkriptionen',
        sk: 'Prepisy',
        es: 'Transcripciones',
        ru: 'Транскрипции',
        rs: 'Transkripcije',
        ua: 'Транскрипції',
        no: 'Transkripsjoner',
        it: 'Trascrizioni',
        se: 'Transkriptioner',
    },
    teamSettingsInfo: {
        us: 'These settings apply to the whole team.',
        cz: 'Tato nastavení se týkají celého týmu.',
        pl: 'Ustawienia te dotyczą całego teamu.',
        hr: 'Ove se postavke odnose na cijeli tim.',
        si: 'Te nastavitve veljajo za celotno ekipo.',
        de: 'Diese Einstellungen gelten für das gesamte Team.',
        sk: 'Tieto nastavenia sa týkajú celého tímu.',
        es: 'Estos ajustes se aplican a todo el equipo.',
        ru: 'Эти настройки применяются ко всей команде.',
        rs: 'Ove postavke važe za ceo tim.',
        ua: 'Ці налаштування застосовуються до всієї команди.',
        no: 'Disse innstillingene omfatter hele teamet.',
        it: "Queste impostazioni si applicano all'intero team.",
        se: 'Dessa inställningar gäller för hela teamet.',
    },
    multiTeamOwner: {
        us: 'Multi-member team owner',
        cz: 'Správce vícečl. týmu',
        pl: 'Administrator wieloosobowego zespołu',
        hr: 'Vlasnik višečlanog tima',
        si: 'Lastnik veččlanske ekipe',
        de: 'Eigentümer eines mehrköpfigen Teams',
        sk: 'Správca viacčlenného tímu',
        es: 'Proprietario del equipo con varios miembros',
        ru: 'Владелец команды из нескольких человек',
        rs: 'Vlasnik tima sa više članova',
        ua: 'Власник команди з кількох учасників',
        no: 'Eier av et team med flere medlemmer',
        it: 'Amministratore di più team',
        se: 'Ägare av team med flera medlemmar',
    },
    creditAndPayments: {
        us: 'Credit and payments',
        cz: 'Kredit a platby',
        pl: 'Kredyt i płatności',
        hr: 'Krediti i plaćanja',
        si: 'Kredit in plačila',
        de: 'Kredit und Zahlungen',
        sk: 'Kredit a platby',
        es: 'Crédito y pagos',
        ru: 'Кредиты и платежи',
        rs: 'Kredit i plaćanja',
        ua: 'Кредити та платежі',
        no: 'Kreditt og betaling',
        it: 'Crediti e pagamenti',
        se: 'Kredit och betalningar',
    },
    members: {
        us: 'Members',
        cz: 'Členové',
        pl: 'Członkowie',
        hr: 'Članovi',
        si: 'Člani',
        de: 'Mitglieder',
        sk: 'Členovia',
        es: 'Miembros',
        ru: 'Участники',
        rs: 'Članovi',
        ua: 'Учасники',
        no: 'Medlemmer',
        it: 'Membri',
        se: 'Medlemmar',
    },
    preferences: {
        us: 'Preferences',
        cz: 'Předvolby',
        pl: 'Preferencje',
        hr: 'Postavke',
        si: 'Nastavitve',
        de: 'Einstellungen',
        sk: 'Predvoľby',
        es: 'Preferencias',
        ru: 'Предпочтения',
        rs: 'Postavke',
        ua: 'Налаштування',
        no: 'Preferanser',
        it: 'Preferenze',
        se: 'Preferenser',
    },
    myProfile: {
        us: 'My profile',
        cz: 'Můj profil',
        pl: 'Mój profil',
        hr: 'Moj profil',
        si: 'Moj profil',
        de: 'Mein Profil',
        sk: 'Môj profil',
        es: 'Mi perfil',
        ru: 'Мой профиль',
        rs: 'Moj profil',
        ua: 'Мій профіль',
        no: 'Min profil',
        it: 'Il mio profilo',
        se: 'Min profil',
    },
    chargedCredit: {
        us: 'Credit used',
        cz: 'Využitý kredit',
        pl: 'Wykorzystany kredyt',
        hr: 'Iskorišteni kredit',
        si: 'Uporabljen kredit',
        de: 'Verwendeter Kredit',
        sk: 'Použitý kredit',
        es: 'Crédito utilizado',
        ru: 'Используемые кредиты',
        rs: 'Iskorišćeni kredit',
        ua: 'Використаний кредит',
        no: 'Brukt kreditt',
        it: 'Crediti utilizzati',
        se: 'Använd kredit',
    },
    joinTeam: {
        us: 'To invite members to your team and collaborate, ',
        cz: 'Pokud chcete pozvat do svého týmu další spolupracovníky, ',
        pl: 'Jeśli chcesz zaprosić innych współpracowników do swojego teamu, ',
        hr: 'Ako želite pozvati članove u svoj tim i surađivati s nama, ',
        si: 'Če želite povabiti člane v svojo ekipo in sodelovati, ',
        de: 'Um Mitglieder in Ihr Team einzuladen und zusammenzuarbeiten, ',
        sk: 'Ak chcete do svojho tímu pozvať ďalších spolupracovníkov, ',
        es: 'Para invitar miembros a tu equipo y colaborar, ',
        ru: 'Для приглашения участников в свою команду и совместной работы, ',
        rs: 'Da biste pozvali članove u svoj tim i sarađivali, ',
        ua: 'Щоб запросити учасників до своєї команди та співпрацювати, ',
        no: 'Hvis du vil invitere nye medlemmer til teamet for å samarbeide, ',
        it: 'Se desiderate invitare altri collaboratori nel vostro team, ',
        se: 'Om du vill bjuda in flera medlemmar till teamet, ',
    },
    contactOurSupport: {
        us: 'contact us',
        cz: 'kontaktujte nás',
        pl: 'skontaktuj się z nami',
        hr: 'kontaktirajte s nama',
        si: 'stopite u stik z nami',
        de: 'kontaktieren Sie uns',
        sk: 'kontaktujte nás',
        es: 'contacte con nosotros',
        ru: 'свяжитесь с нами',
        rs: 'kontaktirajte nas',
        ua: "зв'яжіться з нами",
        no: 'kontakt oss',
        it: 'contattateci',
        se: 'kontakta oss',
    },
    ownerCanEdit: {
        us: 'Only team owner can edit these settings',
        cz: 'Toto nastavení může měnit jen správce týmu',
        pl: 'Ustawienie to może zostać zmienione wyłącznie przez administratora teamu',
        hr: 'Samo vlasnik tima može uređivati ove postavke',
        si: 'Te nastavitve lahko ureja samo lastnik ekipe',
        de: 'Nur der Teameigentümer kann diese Einstellungen bearbeiten',
        sk: 'Tieto nastavenia môže meniť iba správca tímu',
        es: 'Solo el propietario del equipo puede editar estas configuraciones',
        ru: 'Редактировать эти настройки может только владелец команды',
        rs: 'Samo vlasnik tima može uređivati ove postavke',
        ua: 'Тільки власник команди може редагувати ці налаштування',
        no: 'Kun teamets eier kan endre disse innstillingene',
        it: "Questa impostazione può essere modificata solo dall'amministratore del team.",
        se: 'Endast teamägaren kan ändra denna inställning',
    },
    wrongHive: {
        us: 'Oops, you seem to have wandered into a wrong hive.',
        cz: 'Ups, asi jste zabloudili do špatného úlu.',
        pl: 'Ups, chyba trafiłeś do niewłaściwego ula.',
        hr: 'Ups, čini se da ste zalutali u pogrešnu košnicu.',
        si: 'Ups, zdi se, da ste zašli v napačen panj.',
        de: 'Ups, Sie scheinen sich in einem falschen Bienenstock verirrt zu haben.',
        sk: 'Ups, asi ste zablúdili do nesprávneho úľa.',
        es: 'Vaya, parece que has entrado en la colmena equivocada.',
        ru: 'Упс, похоже, вы забрели не в тот улей.',
        rs: 'Ups, čini se da ste zalutali na pogrešno mesto.',
        ua: 'Ой, здається, ви потрапили не в той вулик.',
        no: 'Ops, du har visst vandret inn i en feil bikube.',
        it: "Ops, devi essere entrato nell'alveare sbagliato.",
        se: 'Hoppsan! Du har hamnat i en fel bikupa.',
    },
    pageNotFound: {
        us: 'The page you were looking for does not exist.',
        cz: 'Stránka, na kterou se snažíte dostat, neexistuje.',
        pl: 'Strona, którą próbujesz otworzyć, nie istnieje.',
        hr: 'Stranica koju tražite ne postoji.',
        si: 'Stran, ki ste jo iskali, ne obstaja.',
        de: 'Die Seite, nach der Sie gesucht haben, existiert nicht.',
        sk: 'Stránka, na ktorú sa snažíte dostať, neexistuje.',
        es: 'La página que estabas buscando no existe.',
        ru: 'Страница, которую вы искали, не существует.',
        rs: 'Stranica koju ste tražili ne postoji.',
        ua: 'Сторінка, яку ви шукали, не існує.',
        no: 'Siden du prøvde å besøke finnes ikke.',
        it: 'La pagina a cui stai cercando di accedere non esiste.',
        se: 'Sidan du letar efter finns inte.',
    },
    returnToBeey: {
        us: 'Return to Beey',
        cz: 'Vrátit se do Beey',
        pl: 'Powrót do Beey',
        hr: 'Povratak u Beey',
        si: 'Vrnite se na Beey',
        de: 'Zurück zu Beey',
        sk: 'Vrátiť sa do Beey',
        es: 'Regresar a Beey',
        ru: 'Вернуться в Beey',
        rs: 'Vratite se na Beey',
        ua: 'Повернутися до Beey',
        no: 'Tilbake til Beey',
        it: 'Torna a Beey',
        se: 'Tillbaka till Beey',
    },
    availableTeamCredit: {
        us: 'Available team credit',
        cz: 'Zbývající kredit týmu',
        pl: 'Pozostały kredyt teamu',
        hr: 'Raspoloživ timski kredit',
        si: 'Razpoložljiv kredit ekipe',
        de: 'Verfügbare Team-Kredite',
        sk: 'Zostávajúci kredit tímu',
        es: 'Crédito disponible del equipo',
        ru: 'Доступный командный кредит',
        rs: 'Dostupni timski kredit',
        ua: 'Доступний командний кредит',
        no: 'Teamkreditt tilgjengelig',
        it: 'Credito residuo del team',
        se: 'Återstående teamkredit',
    },
    creditDescription: {
        us: 'Credit allows you and your team members to transcribe recordings. ',
        cz: 'Kredit umožňuje vám a členům vašeho týmu přepisovat nahrávky. ',
        pl: 'Kredyt umożliwia tobie i członkom twojego teamu transkrypcję nagrań. ',
        hr: 'Kredit omogućuje Vama i članovima Vašeg tima transkripciju snimaka. ',
        si: 'Kredit vam in članom vaše ekipe omogoča prepis posnetkov. ',
        de: 'Der Kredit erlaubt Ihnen und Ihren Teammitgliedern, Aufnahmen zu transkribieren. ',
        sk: 'Kredit umožňuje vám a členom vášho tímu prepisovať nahrávky. ',
        es: 'El crédito le permite a usted y a los miembros de su equipo transcribir grabaciones. ',
        ru: 'Кредит позволяет вам и членам вашей команды транскрибировать записи. ',
        rs: 'Kredit vam omogućava i vašim članovima tima transkribovanje snimaka. ',
        ua: 'Кредит дозволяє вам і членам вашої команди транскрибувати записи. ',
        no: 'Kreditt muliggjør at du og medlemmer av teamet ditt kan transkribere innspillinger. ',
        it: 'Il credito consente a voi e ai membri del vostro team di sovrascrivere le registrazioni. ',
        se: 'Med kredit kan du och andra teammedlemmar transkribera inspelingar. ',
    },
    speakerInDatabase: {
        us: 'Saved in your speakers database',
        cz: 'Uložený ve vaší databázi mluvčích',
        pl: 'Zapisany w twojej bazie danych mówców',
        hr: 'Spremljeno u bazu podataka govornika',
        si: 'Shranjeno v podatkovno bazo govorcev',
        de: 'Gespeichert in Ihrer Sprecher-Datenbank',
        sk: 'Uložený vo vašej databáze rečníkov',
        es: 'Guardado en la base de datos de los hablantes',
        ru: 'Сохраняется в вашей базе данных дикторов',
        rs: 'Sačuvano u bazi podataka govornika',
        ua: 'Зберігається у вашій базі даних спікерів',
        no: 'Lagret i talerdatabasen',
        it: 'Salvate nel database degli speaker',
        se: 'Sparad i talardatabasen',
    },
    useOnlyInProject: {
        us: 'Use only in the current project',
        cz: 'Použít pouze v aktuálním projektu',
        pl: 'Zastosuj wyłącznie w bieżącym projekcie',
        hr: 'Koristi samo u trenutnom projektu',
        si: 'Uporabi samo v trenutnem projektu',
        de: 'Nur im aktuellen Projekt verwenden',
        sk: 'Použiť len v aktuálnom projekte',
        es: 'Usar solo en el proyecto actual',
        ru: 'Использовать только в текущем проекте',
        rs: 'Koristi samo u trenutnom projektu',
        ua: 'Використовуйте тільки в поточному проекті',
        no: 'Bruke kun i det aktuelle prosjektet',
        it: 'Da utilizzare solo nel progetto corrente',
        se: 'Använda endast i det nuvarande projektet',
    },
    editOnlyInProject: {
        us: 'Edit only in the current project',
        cz: 'Editovat pouze v aktuálním projektu',
        pl: 'Edytuj tylko w bieżącym projekcie',
        hr: 'Uredi samo u trenutnom projektu',
        si: 'Uredi samo v trenutnem projektu',
        de: 'Nur im aktuellen Projekt bearbeiten',
        sk: 'Editovať len v aktuálnom projekte',
        es: 'Editar solo en el proyecto actual',
        ru: 'Редактирование только в текущем проекте',
        rs: 'Uredite samo u trenutnom projektu',
        ua: 'Редагувати лише у поточному проекті',
        no: 'Redigere kun i det aktuelle prosjektet',
        it: 'Modifica solo nel progetto corrente',
        se: 'Redigera endast i det nuvarande projektet',
    },
    updateSpeakerInDatabase: {
        us: 'Update also in the speaker database',
        cz: 'Aktualizovat také v databázi mluvčích',
        pl: 'Aktualizuj również w bazie danych mówców',
        hr: 'Ažurirajte i u bazi podataka govornika',
        si: 'Posodobi tudi u podatkovni bazi govorcev',
        de: 'Auch in der Sprecher-Datenbank aktualisieren',
        sk: 'Aktualizovať aj v databáze rečníkov',
        es: 'Actualizar también en la base de datos de los hablantes',
        ru: 'Обновлять также в базе данных докладчиков',
        rs: 'Ažurirajte i u bazi podataka govornika',
        ua: 'Оновлюйте також у базі даних спікерів',
        no: 'Oppdatere også i talerdatabasen',
        it: 'Aggiornare anche il database degli speaker',
        se: 'Uppdatera också i talardatabasen',
    },
    saveAsNewSpeaker: {
        us: 'Save as a new speaker to the speaker database',
        cz: 'Uložit jako nového mluvčího v databázi mluvčích',
        pl: 'Zapisz jako nowego mówcę w bazie danych mówców',
        hr: 'Spremi kao novog govornika u bazu podataka govornika',
        si: 'Shrani kot novega govorca v podatkovno bazo govorcev',
        de: 'Als neuer Sprecher in der Sprecher-Datenbank speichern',
        sk: 'Uložiť ako nového rečníka v databáze rečníkov',
        es: 'Guardar como un nuevo hablante en la base de datos de los hablantes',
        ru: 'Сохранить как нового докладчика в базе данных докладчиков',
        rs: 'Sačuvaj kao novog govornika u bazi podataka govornika',
        ua: 'Зберегти як нового спікера в базі даних спікерів',
        no: 'Lagre som en ny taler i talerdatabasen',
        it: 'Salva come nuovo speaker nel database degli speaker',
        se: 'Spara som en ny talare i talardatabasen',
    },
    editLabel: {
        us: 'Edit',
        cz: 'Editovat',
        pl: 'Edytuj',
        hr: 'Uredi',
        si: 'Uredi',
        de: 'Bearbeiten',
        sk: 'Editovať',
        es: 'Editar',
        ru: 'Редактировать',
        rs: 'Uredi',
        ua: 'Редагувати',
        no: 'Redigere',
        it: 'Modifica',
        se: 'Editera',
    },
    deleteFromDb: {
        us: 'Delete from database',
        cz: 'Smazat z databáze',
        pl: 'Usuń z bazy danych',
        hr: 'Izbriši iz baze podataka',
        si: 'Izbriši iz podatkovne baze',
        de: 'Aus der Datenbank löschen',
        sk: 'Vymazať z databázy',
        es: 'Eliminar de la base de datos',
        ru: 'Удалить из базы данных',
        rs: 'Izbriši iz baze podataka',
        ua: 'Видалити з бази даних',
        no: 'Slette fra databasen',
        it: 'Eliminazione dal database',
        se: 'Ta bort från databasen',
    },
    keywordPreview: {
        us: 'Keyword preview',
        cz: 'Náhled klíčových slov',
        pl: 'Podgląd słów kluczowych',
        hr: 'Prikazivanje ključne riječi',
        si: 'Predogled ključne besede',
        de: 'Schlüsselwort-Vorschau',
        sk: 'Náhľad kľúčových slov',
        es: 'Vista previa de palabras claves',
        ru: 'Предварительный просмотр ключевых слов',
        rs: 'Pregled ključnih reči',
        ua: 'Попередній перегляд за ключовим словом',
        no: 'Forhåndsvisning av nøkkelord ',
        it: 'Anteprima delle parole chiave',
        se: 'Förhandsgransking av nyckelord',
    },
    noKeywords: {
        us: 'There are no keywords in the project.',
        cz: 'V projektu nejsou žádná klíčová slova.',
        pl: 'W projekcie nie ma słów kluczowych.',
        hr: 'U projektu nema ključnih riječi.',
        si: 'V projektu ni ključnih besed.',
        de: 'Es gibt keine Schlüsselwörter im Projekt.',
        sk: 'V projekte nie sú žiadne kľúčové slová.',
        es: 'No hay palabras clave en el proyecto.',
        ru: 'В проекте нет ключевых слов.',
        rs: 'Nema ključnih reči u projektu.',
        ua: 'У проекті немає ключових слів.',
        no: 'Det finnes ingen nøkkelord i prosjektet.',
        it: 'Non ci sono parole chiave nel progetto.',
        se: 'Det finns inga nyckelord i projektet.',
    },
    noCategory: {
        us: 'No category',
        cz: 'Bez kategorie',
        pl: 'Bez kategorii',
        hr: 'Bez kategorije',
        si: 'Brez kategorije',
        de: 'Keine Kategorie',
        sk: 'Žiadna kategória',
        es: 'Sin categoría',
        ru: 'Нет категории',
        rs: 'Bez kategorije',
        ua: 'Немає категорії',
        no: 'Uten kategori',
        it: 'Nessuna categoria',
        se: 'Utan kategori',
    },
    languageRequired: {
        us: 'Please select the language',
        cz: 'Vyberte prosím jazyk',
        pl: 'Wybierz język',
        hr: 'Odaberite jezik',
        si: 'Izberite jezik',
        de: 'Bitte Sprache wählen',
        sk: 'Vyberte prosím jazyk',
        es: 'Por favor seleccione el idioma',
        ru: 'Пожалуйста, выберите язык',
        rs: 'Molimo odaberite jezik',
        ua: 'Будь ласка, виберіть мову',
        no: 'Vennligst velg språket',
        it: 'Selezionare la lingua',
        se: 'Vänligen välj ett språk',
    },
    'in-bg': {
        us: 'in Bulgarian',
        cz: 'bulharsky',
        pl: 'w języku bułgarskim',
        hr: 'bugarski',
        si: 'bolgarščina',
        de: 'auf Bulgarisch',
        sk: 'v bulharčine',
        es: 'en búlgaro',
        ru: 'на болгарском',
        rs: 'bugarski',
        ua: 'болгарською',
        no: 'bulgarsk',
        it: 'in bulgaro',
        se: 'på bulgariska',
    },
    'in-bs': {
        us: 'in Bosnian',
        cz: 'bosensky',
        pl: 'w języku bośniackim',
        hr: 'bosanski',
        si: 'bosanščina',
        de: 'auf Bosnisch',
        sk: 'v bosenčine',
        es: 'en bosnio',
        ru: 'на боснийском',
        rs: 'bošnjački',
        ua: 'боснійською',
        no: 'bosnisk',
        it: 'in bosniaco',
        se: 'på bosniska',
    },
    'in-cs': {
        us: 'in Czech',
        cz: 'česky',
        pl: 'w języku czeskim',
        hr: 'češki',
        si: 'češčina',
        de: 'auf Tschechisch',
        sk: 'v češtine',
        es: 'en checo',
        ru: 'на чешском',
        rs: 'češki',
        ua: 'чеською',
        no: 'tsjekkisk',
        it: 'in ceco',
        se: 'på tjeckiska',
    },
    'in-da': {
        us: 'in Danish',
        cz: 'dánsky',
        pl: 'w języku duńskim',
        hr: 'danski',
        si: 'danščina',
        de: 'auf Dänisch',
        sk: 'v dánčine',
        es: 'en danés',
        ru: 'на датском',
        rs: 'danski',
        ua: 'данською',
        no: 'dansk',
        it: 'in danese',
        se: 'på danska',
    },
    'in-de': {
        us: 'in German',
        cz: 'německy',
        pl: 'w języku niemieckim',
        hr: 'njemački',
        si: 'nemščina',
        de: 'auf Deutsch',
        sk: 'v nemčine',
        es: 'en alemán',
        ru: 'на немецком',
        rs: 'nemački',
        ua: 'німецькою',
        no: 'tysk',
        it: 'in tedesco',
        se: 'på tyska',
    },
    'in-en': {
        us: 'in English',
        cz: 'anglicky',
        pl: 'w języku angielskim',
        hr: 'engleski',
        si: 'angleščina',
        de: 'auf Englisch',
        sk: 'v angličtine',
        es: 'en inglés',
        ru: 'на английском языке',
        rs: 'engleski',
        ua: 'англійською',
        no: 'engelsk',
        it: 'in inglese',
        se: 'på engelska',
    },
    'in-es': {
        us: 'in Spanish',
        cz: 'španělsky',
        pl: 'w języku hiszpańskim',
        hr: 'španjolski',
        si: 'španščina',
        de: 'auf Spanisch',
        sk: 'v španielčine',
        es: 'en español',
        ru: 'на испанском языке',
        rs: 'španski',
        ua: 'іспанською',
        no: 'spansk',
        it: 'in spagnolo',
        se: 'på spanska',
    },
    'in-fr': {
        us: 'in French',
        cz: 'francouzsky',
        pl: 'w języku francuskim',
        hr: 'francuski',
        si: 'francoščina',
        de: 'auf Französisch',
        sk: 'vo francúzštine',
        es: 'en francés',
        ru: 'на французском языке',
        rs: 'francuski',
        ua: 'французькою',
        no: 'fransk',
        it: 'in francese',
        se: 'på franska',
    },
    'in-hr': {
        us: 'in Croatian',
        cz: 'chorvatsky',
        pl: 'w języku chorwackim',
        hr: 'hrvatski',
        si: 'hrvaščina',
        de: 'auf Kroatisch',
        sk: 'v chorvátčine',
        es: 'en croata',
        ru: 'на хорватском языке',
        rs: 'hrvatski',
        ua: 'хорватською',
        no: 'kroatisk',
        it: 'in croato',
        se: 'på kroatiska',
    },
    'in-hu': {
        us: 'in Hungarian',
        cz: 'maďarsky',
        pl: 'w języku węgierskim',
        hr: 'mađarski',
        si: 'madžarščina',
        de: 'auf Ungarisch',
        sk: 'v maďarčine',
        es: 'en húngaro',
        ru: 'на венгерском языке',
        rs: 'mađarski',
        ua: 'угорською',
        no: 'ungarsk',
        it: 'in ungherese',
        se: 'på ungerska',
    },
    'in-it': {
        us: 'in Italian',
        cz: 'italsky',
        pl: 'w języku włoskim',
        hr: 'talijanski',
        si: 'italijanščina',
        de: 'auf Italienisch',
        sk: 'v taliančine',
        es: 'en italiano',
        ru: 'на итальянском языке',
        rs: 'italijanski',
        ua: 'італійською ',
        no: 'italiensk',
        it: 'in italiano',
        se: 'på italienska',
    },
    'in-cn': {
        us: 'in Montenegrin',
        cz: 'černohorsky',
        pl: 'w języku czarnogórskim',
        hr: 'crnogorski',
        si: 'črnogorščina',
        de: 'auf Montenegrinisch',
        sk: 'v čiernohorčine',
        es: 'en montenegrino',
        ru: 'на черногорском языке',
        rs: 'crnogorski',
        ua: 'чорногорською',
        no: 'montenegrinsk',
        it: 'in montenegrino',
        se: 'på montenegrinska',
    },
    'in-mk': {
        us: 'in Macedonian',
        cz: 'makedonsky',
        pl: 'w języku macedońskim',
        hr: 'makedonski',
        si: 'makedonščina',
        de: 'auf Mazedonisch',
        sk: 'v macedónčine',
        es: 'en macedonio',
        ru: 'на македонском языке',
        rs: 'makedonski',
        ua: 'македонською',
        no: 'makedonsk',
        it: 'in macedone',
        se: 'på makedoniska',
    },
    'in-nb': {
        us: 'in Norwegian',
        cz: 'norsky',
        pl: 'w języku norweskim',
        hr: 'norveški',
        si: 'norveščina',
        de: 'auf Norwegisch',
        sk: 'v nórčine',
        es: 'en noruego',
        ru: 'на норвежском языке',
        rs: 'norveški',
        ua: 'норвезькою',
        no: 'norsk',
        it: 'in norvegese',
        se: 'på norska',
    },
    'in-nl': {
        us: 'in Dutch',
        cz: 'holandsky',
        pl: 'w języku holenderskim',
        hr: 'nizozemski',
        si: 'nizozemščina',
        de: 'auf Niederländisch',
        sk: 'v holandčine',
        es: 'en neerlandés',
        ru: 'на голландском языке',
        rs: 'holandski',
        ua: 'голландською',
        no: 'nederlandsk',
        it: 'in olandese',
        se: 'på nederländska',
    },
    'in-pl': {
        us: 'in Polish',
        cz: 'polsky',
        pl: 'w języku polskim',
        hr: 'poljski',
        si: 'poljščina',
        de: 'auf Polnisch',
        sk: 'v poľštine',
        es: 'en polaco',
        ru: 'на польском языке',
        rs: 'poljski',
        ua: 'польською',
        no: 'polsk',
        it: 'in polacco',
        se: 'på polska',
    },
    'in-ro': {
        us: 'in Romanian',
        cz: 'rumunsky',
        pl: 'w języku rumuńskim',
        hr: 'rumunjski',
        si: 'romunščina',
        de: 'auf Rumänisch',
        sk: 'v rumunčine',
        es: 'en rumano',
        ru: 'на румынском языке',
        rs: 'rumunski',
        ua: 'румунською',
        no: 'rumensk',
        it: 'in rumeno',
        se: 'på rumänska',
    },
    'in-sr': {
        us: 'in Serbian',
        cz: 'srbsky',
        pl: 'w języku serbskim',
        hr: 'srpski',
        si: 'srbščina',
        de: 'auf Serbisch',
        sk: 'v srbčine',
        es: 'en serbio',
        ru: 'на сербском языке',
        rs: 'srpski',
        ua: 'сербською',
        no: 'serbisk',
        it: 'in serbo',
        se: 'på serbiska',
    },
    'in-ru': {
        us: 'in Russian',
        cz: 'rusky',
        pl: 'w języku rosyjskim',
        hr: 'ruski',
        si: 'ruščina',
        de: 'auf Russisch',
        sk: 'v ruštine',
        es: 'en ruso',
        ru: 'на русском языке',
        rs: 'ruski',
        ua: 'російською',
        no: 'russisk',
        it: 'in russo',
        se: 'på ryska',
    },
    'in-sv': {
        us: 'in Swedish',
        cz: 'švédsky',
        pl: 'w języku szwedzkim',
        hr: 'švedski',
        si: 'švedščina',
        de: 'auf Schwedisch',
        sk: 'vo švédčine',
        es: 'en sueco',
        ru: 'на шведском языке',
        rs: 'švedski',
        ua: 'шведською',
        no: 'svensk',
        it: 'in svedese',
        se: 'på svenska',
    },
    'in-sl': {
        us: 'in Slovenian',
        cz: 'slovinsky',
        pl: 'w języku słoweńskim',
        hr: 'slovenski',
        si: 'slovenščina',
        de: 'auf Slowenisch',
        sk: 'v slovinčine',
        es: 'en esloveno',
        ru: 'на словенском языке',
        rs: 'slovenački',
        ua: 'словенською',
        no: 'slovensk',
        it: 'in sloveno',
        se: 'på slovenska',
    },
    'in-sk': {
        us: 'in Slovak',
        cz: 'slovensky',
        pl: 'w języku słowackim',
        hr: 'slovački',
        si: 'slovaščina',
        de: 'auf Slowakisch',
        sk: 'v slovenčine',
        es: 'en eslovaco',
        ru: 'на словацком языке',
        rs: 'slovački',
        ua: 'словацькою',
        no: 'slovakisk',
        it: 'in slovacco',
        se: 'på slovakiska',
    },
    'in-uk': {
        us: 'in Ukrainian',
        cz: 'ukrajinsky',
        pl: 'w języku ukraińskim',
        hr: 'ukrajinski',
        si: 'ukrajinščina',
        de: 'auf Ukrainisch',
        sk: 'v ukrajinčine',
        es: 'en ucraniano',
        ru: 'на украинском языке',
        rs: 'ukrajinski',
        ua: 'українською',
        no: 'ukrainsk',
        it: 'in ucraino',
        se: 'på ukrainska',
    },
    'in-ca': {
        us: 'in Catalan',
        cz: 'katalánsky',
        pl: 'w języku katalońskim',
        hr: 'katalonski',
        si: 'katalonščina',
        de: 'auf Katalanisch',
        sk: 'v katalánčine',
        es: 'en catalán',
        ru: 'на каталанском языке',
        rs: 'na katalonskom jeziku',
        ua: 'каталонською',
        no: 'katalansk',
        it: 'in catalano',
        se: 'på katalanska',
    },
    splitBySpeakers: {
        us: 'Mark individual speakers',
        cz: 'Rozlišit jednotlivé mluvčí',
        pl: 'Rozdziel na poszczególnych mówców',
        hr: 'Označite govornike',
        si: 'Označi posamezne govorce',
        de: 'Einzelne Sprecher markieren',
        sk: 'Rozlíšiť jednotlivé hovorca',
        es: 'Marcar hablantes individuales',
        ru: 'Отметить отдельных выступающих',
        rs: 'Označite pojedinačne govornike',
        ua: 'Позначити окремих спікерів',
        no: 'Merke enkelte talere',
        it: 'Distinguere i singoli altoparlanti',
        se: 'Markera enskilda talare',
    },
    oneSpeaker: {
        us: 'speaker',
        cz: 'mluvčí',
        pl: 'mówca',
        hr: 'govornik',
        si: 'govorec',
        de: 'Sprecher',
        sk: 'rečník',
        es: 'orador',
        ru: 'cпикер',
        rs: 'govornik',
        ua: 'cпікер',
        no: 'taleren',
        it: 'altoparlante',
        se: 'talaren',
    },
    moreSpeakers: {
        us: '2 or more speakers',
        cz: '2 a více mluvčích',
        pl: '2 i więcej mówców',
        hr: '2 ili više govornika',
        si: '2 ali več govorcev',
        de: '2 oder mehr Sprecher',
        sk: '2 alebo viac rečníkov',
        es: '2 o más hablantes',
        ru: '2 или более дикторов',
        rs: '2 ili više govornika',
        ua: '2 або більше спікерів',
        no: '2 og flere talere',
        it: '2 o più oratori',
        se: '2 eller flera talare',
    },
    deleteProjectPermanently: {
        us: 'Permanently delete project?',
        cz: 'Trvale smazat projekt?',
        pl: 'Trwale usunąć projekt?',
        hr: 'Trajno izbrisati projekt?',
        si: 'Trajno izbrisati projekt?',
        de: 'Projekt unwiderruflich löschen?',
        sk: 'Trvalo vymazať projekt?',
        es: '¿Eliminar proyecto permanentemente?',
        ru: 'Удалить проект навсегда?',
        rs: 'Da li želite trajno da obrišete projekat?',
        ua: 'Видалити проект назавжди?',
        no: 'Slette prosjektet permanent?',
        it: 'Cancellare questo progetto in modo permanente?',
        se: 'Radera projekt permanent?',
    },
    deleteInfo: {
        us: 'The project and your edits will be permanently deleted. The shared project will be deleted only in your account.',
        cz: 'Projekt i vaše editace budou nenávratně smazány. Sdílený projekt bude smazán pouze ve vašem účtu.',
        pl: 'Projekt wraz z wprowadzonymi zmianami zostanie trwale usunięty. Udostępniony projekt zostanie usunięty tylko z Twojego konta.',
        hr: 'Projekt i Vaše izmjene bit će trajno izbrisani. Dijeljeni projekt bit će izbrisan samo na Vašem računu.',
        si: 'Projekt in vaše spremembe bodo trajno izbrisani. Projekt v skupni rabi bo izbrisan samo v vašem računu.',
        de: 'Das Projekt und Ihre Bearbeitungen werden dauerhaft gelöscht. Das geteilte Projekt wird nur in Ihrem Konto gelöscht.',
        sk: 'Projekt a vaše úpravy budú natrvalo vymazané. Zdieľaný projekt sa odstráni len vo vašom konte.',
        es: 'El proyecto y tus ediciones serán eliminados permanentemente. El proyecto compartido solo será eliminado de tu cuenta.',
        ru: 'Проект и ваши изменения будут удалены без возможности восстановления. Общий проект будет удален только в вашем аккаунте.',
        rs: 'Projekat i vaše izmene će biti trajno obrisani. Deljeni projekat će biti obrisan samo sa vašeg naloga.',
        ua: 'Проєкт і ваші правки будуть видалені назавжди. Спільний проект буде видалено лише у вашому акаунті.',
        no: 'Prosjektet og redigeringen blir slettet permanent. Det delte prosjektet blir slettet bare fra kontoen din.',
        it: 'I progetti e le tue modifiche saranno cancellati in modo permanente. I progetti condivisi saranno cancellati solo per il tuo account.',
        se: 'Projektet och dina ändringar kommer att raderas permanent. Det delade projektet kommer att raderas endast i ditt konto.',
    },
    abbreviationDescription: {
        us: 'Insert a frequently repeated phrase into your projects using a text shortcut and press Space.',
        cz: 'Vložte často opakovanou frázi do svých projektů pomocí textové zkratky a stisknutí mezerníku.',
        pl: 'Wstaw często powtarzające się w twoich projektach sformułowanie, używając skrótu tekstowego i naciskając spację.',
        hr: 'Umetnite često ponavljanu frazu u svoje projekte pomoću tekstualnog prečaca i pritisnite Razmak.',
        si: 'V svoje projekte vstavite pogosto ponavljajočo se besedno zvezo z besedilno bližnjico in pritisnite Preslednico.',
        de: 'Fügen Sie eine häufig wiederholte Phrase in Ihre Projekte ein, indem Sie ein Textkürzel verwenden und die Leertaste drücken.',
        sk: 'Často opakovanú frázu vložíte do svojich projektov pomocou textovej skratky a stlačením medzerníka.',
        es: 'Insertar una frase repetida con frecuencia en tus proyectos usando un atajo de texto y presiona Espacio.',
        ru: 'Вставьте часто повторяющуюся фразу в проекты с помощью текстового ярлыка и нажмите Пробел.',
        rs: 'Umetnite često ponavljani izraz u vašim projektima koristeći skraćeni tekst i pritisnite razmak.',
        ua: 'Вставте фразу, що часто повторюється у ваших проектах, за допомогою текстового скорочення і натисніть клавішу Пробіл.',
        no: 'Sett inn en ofte gjentatt frase i dine prosjekter med å bruke en tekst snarvei og trykk mellomromstasten.',
        it: 'Inserite una frase ripetuta frequentemente nei vostri progetti utilizzando una scorciatoia di testo e premendo la barra spaziatrice.',
        se: 'Infoga en ofta upprepad fras i dina projekt genom att använda ett kortkommando och att trycka på mellanslagstangenten.',
    },
    abbreviationImported: {
        us: 'Abbreviations were succesfully imported.',
        cz: 'Zkratky byly úspěšně importované.',
        pl: 'Skróty zostały pomyślnie zaimportowane.',
        hr: 'Kratice su uspješno uvezene.',
        si: 'Uspešen uvoz okrajšav.',
        de: 'Die Textkürzel wurden erfolgreich importiert.',
        sk: 'Skratky boli úspešne importované.',
        es: 'Las abreviaturas se importaron exitosamente.',
        ru: 'Сокращения были успешно импортированы.',
        rs: 'Skraćenice su uspešno uvezene.',
        ua: 'Скорочення успішно імпортовано.',
        no: 'Importering av forkortelsene vellykket.',
        it: 'I collegamenti sono stati importati con successo.',
        se: 'Förkortningarna har importerats.',
    },
    abbreviationImportFail: {
        us: 'Failed to import abbreviations.',
        cz: 'Selhal import zkratek.',
        pl: 'Import skrótów nie powiódł się.',
        hr: 'Uvoz kratica nije uspio.',
        si: 'Uvoz okrajšav ni uspel.',
        de: 'Der Import von Textkürzeln ist fehlgeschlagen.',
        sk: 'Import skratiek sa nepodaril.',
        es: 'No se pudieron importar las abreviaturas.',
        ru: 'Не удалось импортировать аббревиатуры.',
        rs: 'Neuspešno uvoženje skraćenica.',
        ua: 'Не вдалося імпортувати скорочення.',
        no: 'Importering av forkortelsene mislyktes.',
        it: "L'importazione dei collegamenti non è riuscita.",
        se: 'Det gick inte att importera förkortningar.',
    },
    abbreviations: {
        us: 'Abbreviations',
        cz: 'Zkratky',
        pl: 'Skróty',
        hr: 'Kratice',
        si: 'Okrajšave',
        de: 'Textkürzel',
        sk: 'Skratky',
        es: 'Abreviaturas',
        ru: 'Аббревиатуры',
        rs: 'Skraćenice',
        ua: 'Скорочення',
        no: 'Forkortelser',
        it: 'Scorciatoia',
        se: 'Förkortningar',
    },
    addAbbreviation: {
        us: 'Add abbreviation',
        cz: 'Přidat zkratku',
        pl: 'Dodaj skrót',
        hr: 'Dodaj kraticu',
        si: 'Dodaj okrajšavo',
        de: 'Textkürzel hinzufügen',
        sk: 'Pridať skratku',
        es: 'Agregar abreviatura',
        ru: 'Добавить аббревиатуру',
        rs: 'Dodajte skraćenicu',
        ua: 'Додати скорочення',
        no: 'Legg til en forkortelse',
        it: 'Aggiungi scorciatoia',
        se: 'Lägg till förkortning',
    },
    editAbbreviation: {
        us: 'Edit abbreviation',
        cz: 'Upravit zkratku',
        pl: 'Edytuj skrót',
        hr: 'Uredi kraticu',
        si: 'Uredi okrajšavo',
        de: 'Textkürzel bearbeiten',
        sk: 'Upraviť skratku',
        es: 'Editar abreviatura',
        ru: 'Редактировать аббревиатуру',
        rs: 'Uredi skraćenicu',
        ua: 'Редагувати скорочення',
        no: 'Rediger forkortelsen',
        it: 'Modifica scorciatoia',
        se: 'Redigera förkortning',
    },
    textAbbreviation: {
        us: 'Text abbreviation',
        cz: 'Text zkratky',
        pl: 'Tekst skrótu',
        hr: 'Skraćenica teksta',
        si: 'Besedilo okrajšave',
        de: 'Textkürzel',
        sk: 'Text skratky',
        es: 'Abreviatura de texto',
        ru: 'Текстовая аббревиатура',
        rs: 'Tekst skraćenice',
        ua: 'Скорочення тексту',
        no: 'Tekstforkortelse',
        it: 'Testo della scelta rapida',
        se: 'Förkortningens lydelse',
    },
    replacedWith: {
        us: 'Replaced with',
        cz: 'Nahrazeno',
        pl: 'Zastąpiony przez',
        hr: 'Zamijenjeno s',
        si: 'Zamenjano z',
        de: 'Ersetzt durch',
        sk: 'Nahradené',
        es: 'Reemplazado por',
        ru: 'Заменено на',
        rs: 'Zamenjeno sa',
        ua: 'Замінено на',
        no: 'Erstattet',
        it: 'Sostituito da',
        se: 'Ersatt med',
    },
    importFromCsv: {
        us: 'Import from .csv',
        cz: 'Import z .csv',
        pl: 'Import z pliku .csv',
        hr: 'Uvoz iz .csv',
        si: 'Uvoz iz .csv',
        de: 'Import aus .csv',
        sk: 'Import z .csv',
        es: 'Importar desde .csv',
        ru: 'Импорт из .csv',
        rs: 'Uvezi iz .csv',
        ua: 'Імпорт з .csv',
        no: 'Importer fra .csv',
        it: 'Importazione da .csv',
        se: 'Import från .csv',
    },
    importFromCsvDetail: {
        us: 'Import from .csv with separators. The word separator is a semicolon ( ; ).',
        cz: 'Import z .csv s oddělovači. Oddělovačem slov je středník ( ; ).',
        pl: 'Import z pliku .csv z separatorami. Separatorem słów jest średnik ( ; ).',
        hr: 'Uvezite iz .csv s razdjelnicima. Razdjelnik riječi je točka sa zarezom ( ; ).',
        si: 'Uvozi iz .csv z ločili. Ločilo besed je podpičje ( ; ).',
        de: 'Import aus .csv mit Trennzeichen. Das Worttrennzeichen ist ein Semikolon ( ; ).',
        sk: 'Import z .csv s oddeľovačmi. Oddeľovač slov je stredník ( ; ).',
        es: 'Importar desde .csv con separadores. El separador de palabras es un punto y coma ( ; ).',
        ru: 'Импорт из .csv с разделителями. Разделителем слов является точка с запятой ( ; ).',
        rs: 'Uvoz iz .csv sa graničnicima. Graničnik reči je tačka sa zapetom ( ; ).',
        ua: 'Імпорт з .csv з розділювачами. Розділювачем слів є крапка з комою ( ; ).',
        no: 'Import fra .csv med separatorer. Ordseparator er semikolon ( ; ).',
        it: 'Importazione da .csv con separatori. Il separatore di parole è un punto e virgola ( ; ).',
        se: 'Import från .csv med ordavgränsare i form av semikolon ( ; ).',
    },
    noAbbreviation: {
        us: 'Please enter text abbreviation.',
        cz: 'Prosím zadejte text zkratky.',
        pl: 'Wprowadź tekst skrótu.',
        hr: 'Unesite skraćenicu teksta.',
        si: 'Vnesite okrajšavo.',
        de: 'Bitte Textkürzel eingeben.',
        sk: 'Prosím zadajte text skratky.',
        es: 'Por favor, ingresa la abreviatura de texto.',
        ru: 'Введите текстовую аббревиатуру.',
        rs: 'Molimo unesite tekst skraćenice.',
        ua: 'Будь ласка, введіть текстове скорочення.',
        no: 'Vennligst oppgi tekstforkortelsen.',
        it: "Inserire il testo dell'abbreviazione.",
        se: 'Vänligen ange förkortningens lydelse.',
    },
    noReplacement: {
        us: 'Please enter text that will replace the abbreviation.',
        cz: 'Prosím zadejte text, který nahradí zkratku.',
        pl: 'Wprowadź tekst, który zastąpi skrót.',
        hr: 'Unesite tekst koji će zamijeniti kraticu.',
        si: 'Vnesite besedilo, ki bo nadomestilo okrajšavo.',
        de: 'Bitte geben Sie den Text ein, der die Textkürzel ersetzen soll.',
        sk: 'Prosím zadajte text, ktorý nahradí skratku.',
        es: 'Por favor, ingresa el texto que reemplazará la abreviatura.',
        ru: 'Введите текст, который заменит эту аббревиатуру.',
        rs: 'Molimo unesite tekst koji će zameniti skraćenicu.',
        ua: 'Введіть текст, який замінить абревіатуру.',
        no: 'Vennligst oppgi tekst som erstatter forkortelsen.',
        it: "Inserire il testo per sostituire l'abbreviazione.",
        se: 'Vänligen ange texten som ska ersätta förkortningen.',
    },
    duplicitAbbreviation: {
        us: 'This abbreviation already exists.',
        cz: 'Zkratka s tímto názvem již existuje.',
        pl: 'Skrót o tej nazwie już istnieje.',
        hr: 'Ova kratica već postoji.',
        si: 'Ta okrajšava že obstaja.',
        de: 'Diese Textkürzel existiert bereits.',
        sk: 'Skratka s týmto názvom už existuje.',
        es: 'Esta abreviatura ya existe.',
        ru: 'Эта аббревиатура уже существует.',
        rs: 'Ova skraćenica već postoji.',
        ua: 'Ця абревіатура вже існує.',
        no: 'Forkortelsen med dette navnet finnes allerede',
        it: "Esiste già un'abbreviazione con questo nome.",
        se: 'Denna förkortning finns redan.',
    },
    onlyOneWordAbbreviation: {
        us: 'The text abbreviation can only be one-word.',
        cz: 'Text zkratky může být jen jednoslovný.',
        pl: 'Tekst skrótu może składać się tylko z jednego słowa.',
        hr: 'Skraćenica teksta može biti samo jedna riječ.',
        si: 'Okrajšava besedila je lahko samo ena beseda.',
        de: 'Die Textkürzel darf nur aus einem Wort bestehen.',
        sk: 'Text skratky môže byť len jedno slovo.',
        es: 'La abreviatura de texto solo puede ser de una palabra.',
        ru: 'Сокращение текста может состоять только из одного слова.',
        rs: 'Skraćenica teksta može biti samo jedna jedina reč.',
        ua: 'Текстова абревіатура може бути тільки однослівною.',
        no: 'Tekstforkortelse kan være ett ord.',
        it: "Il testo dell'abbreviazione può essere costituito da una sola parola.",
        se: 'Förkortningen kan endast vara i form av ett ord.',
    },
    fontSize: {
        us: 'Font size',
        cz: 'Velikost písma',
        pl: 'Wielkość czcionki',
        hr: 'Veličina fonta',
        si: 'Velikost pisave',
        de: 'Schriftgröße',
        sk: 'Veľkosť písma',
        es: 'Tamaño de fuente',
        ru: 'Размер шрифта',
        rs: 'Veličina fonta',
        ua: 'Розмір шрифту',
        no: 'Skriftstørrelse',
        it: 'Dimensioni del font',
        se: 'Fontstorlek',
    },
    showNonBreakingSpace: {
        us: 'Show non-breaking space',
        cz: 'Zobrazit nedělitelné mezery',
        pl: 'Pokaż spacje niełamiące',
        hr: 'Prikaži nedjeljivi razmak',
        si: 'Prikaži neprekinjen presledek',
        de: 'Geschützte Leerzeichen anzeigen',
        sk: 'Zobraziť nedeliteľné medzery',
        es: 'Mostrar espacio no rompible',
        ru: 'Показать неразрывные пробелы',
        rs: 'Prikaži nedeljivi razmak',
        ua: 'Показувати нерозривні пробіли\n',
        no: 'Vise hardt mellomrom',
        it: 'Mostra spazi indivisibili',
        se: 'Visa hårda mellanslag',
    },
    pauseOnPedalRelease: {
        us: 'Pause audio after foot pedal is released',
        cz: 'Zastavit audio po puštění nožního pedálu',
        pl: 'Zatrzymaj dźwięk po zwolnieniu przełącznika nożnego',
        hr: 'Pauziraj zvuk nakon otpuštanja nožne pedale',
        si: 'Ustavite zvok po sprostitvi nožnega pedala',
        de: 'Pause Audio nach Loslassen des Fußpedals',
        sk: 'Zastaviť audio po uvoľnení nožného pedála',
        es: 'Pausar audio después de soltar el pedal del pie',
        ru: 'Приостановка звука после отпускания педали',
        rs: 'Pauzirajte zvuk nakon otpuštanja pedale',
        ua: 'Призупиняти звук після відпускання педалі',
        no: 'Sette lyden på pause etter at fotpedalen slippes opp',
        it: "Interruzione dell'audio al rilascio del pedale",
        se: 'Pausa ljudet när du släpper fotpedalen',
    },
    showMediaDate: {
        us: 'Show recording date',
        cz: 'Zobrazovat datum nahrávky',
        pl: 'Wyświetl datę nagrania',
        hr: 'Pokaži datum snimanja',
        si: 'Prikaži datum posnetka',
        de: 'Aufnahmedatum anzeigen',
        sk: 'Zobrazovať dátum nahrávky',
        es: 'Mostrar fecha de grabación',
        ru: 'Показать дату записи',
        rs: 'Prikažite datum snimanja',
        ua: 'Показати дату запису',
        no: 'Vise innspillingsdato',
        it: 'Visualizzazione della data di registrazione',
        se: 'Visa inspelningens datum',
    },
    channelmixermonoDescription: {
        us: 'Merge audio recordings from multiple mono channels.',
        cz: 'Spojte audio nahrávky z několika mono audio kanálů.',
        pl: 'Połącz nagrania audio z wielu kanałów mono.',
        hr: 'Spojite audio zapise s više mono kanala.',
        si: 'Združite zvočne posnetke iz več mono kanalov.',
        de: 'Audioaufnahmen aus mehreren Monokanälen zusammenführen.',
        sk: 'Kombinujte audio nahrávky z viacerých mono zvukových kanálov.',
        es: 'Unir grabaciones de audio de múltiples canales mono.',
        ru: 'Объедините аудиозаписи с нескольких моно аудиоканалов.',
        rs: 'Spojite audio snimke sa više mono kanala.',
        ua: "Об'єднати аудіозаписи з декількох моноканалів.",
        no: 'Slå sammen innspillinger fra flere monolyd kanaler.',
        it: 'Unire le registrazioni audio da più di un canale mono.',
        se: 'Sammanfoga ljudinspelningar från flera monokanaler.',
    },
    channelmixerstereoDescription: {
        us: 'Merge audio from stereo channels.',
        cz: 'Spojte audio ze stereo kanálů.',
        pl: 'Połącz audio z kanałów stereo.',
        hr: 'Spojite zvuk sa stereo kanala.',
        si: 'Združite zvok iz stereo kanalov.',
        de: 'Audio aus Stereokanälen zusammenführen.',
        sk: 'Spojte audio nahrávky zo stereo kanálov.',
        es: 'Unir audio de canales estéreo.',
        ru: 'Объедините звук из стереоканалов.',
        rs: 'Spojite zvuk sa stereo kanala.',
        ua: "Об'єднати аудіо зі стерео каналів.",
        no: 'Slå sammen lyd fra stereokanaler.',
        it: "Unire l'audio da canali stereo.",
        se: 'Sammanfoga ljud från stereokanaler.',
    },
    dssconverterDescription: {
        us: 'Transcribe recordings in dss format.',
        cz: 'Přepište nahrávky ve formátu dss.',
        pl: 'Transkrypcja nagrań w formacie dss.',
        hr: 'Transkribiraj snimke u dss formatu.',
        si: 'Prepišite posnetke v formatu dss.',
        de: 'Transkribieren von Aufnahmen im dss-Format.',
        sk: 'Prepíšte nahrávky vo formáte dss.',
        es: 'Transcribir grabaciones en formato dss.',
        ru: 'Транскрибируйте в формате DSS',
        rs: 'Transkripcija snimaka u dss formatu.',
        ua: 'Транскрибувати записи у форматі dss.',
        no: 'Transkriber innspillinger i dss formatet.',
        it: 'Trascrivere registrazioni in formato dss.',
        se: 'Transkribera inspelningar i dss-format.',
    },
    failedToFetchAbbreviations: {
        us: 'Failed to load abbreviations.',
        cz: 'Selhalo načtení zkratek.',
        pl: 'Załadowanie skrótów nie powiodło się.',
        hr: 'Učitavanje kratica nije uspjelo.',
        si: 'Okrajšav ni bilo mogoče naložiti.',
        de: 'Textkürzel konnten nicht geladen werden.',
        sk: 'Zlyhalo načítaní skratiek.',
        es: 'Error al cargar las abreviaturas.',
        ru: 'Не удалось загрузить аббревиатуру.',
        rs: 'Neuspelo učitavanje skraćenica.',
        ua: 'Не вдалося завантажити скорочення.',
        no: 'Opplasting av forkortelsene mislyktes.',
        it: 'Impossibile caricare le abbreviazioni.',
        se: 'Det gick inte att ladda förkortningar.',
    },
    abbreviationDeleted: {
        us: 'Abbreviation has been deleted.',
        cz: 'Zkratka byla smazána.',
        pl: 'Skrót został usunięty.',
        hr: 'Kratica je izbrisana.',
        si: 'Okrajšava je izbrisana.',
        de: 'Die Textkürzel wurden gelöscht.',
        sk: 'Skratka bola odstránená.',
        es: 'La abreviatura ha sido eliminada.',
        ru: 'Аббревиатура была удалена.',
        rs: 'Skraćenica je obrisana.',
        ua: 'Скорочення було видалено.',
        no: 'Forkortelsen ble slettet.',
        it: "L'abbreviazione è stata eliminata.",
        se: 'Förkortningen har raderats.',
    },
    failedToDeleteAbbreviation: {
        us: 'Failed to delete abbreviation.',
        cz: 'Při mazání zkratky došlo k chybě.',
        pl: 'Podczas usuwania skrótu wystąpił błąd.',
        hr: 'Brisanje kratice nije uspjelo.',
        si: 'Brisanje okrajšave ni uspelo.',
        de: 'Textkürzel konnte nicht gelöscht werden.',
        sk: 'Pri odstraňovaní skratky došlo k chybe.',
        es: 'Error al eliminar la abreviatura.',
        ru: 'Не удалось удалить аббревиатуру.',
        rs: 'Neuspelo brisanje skraćenice.',
        ua: 'Не вдалося видалити абревіатуру.',
        no: 'Sletting av forkortelsen mislyktes.',
        it: "Si è verificato un errore durante l'eliminazione dell'abrevizione.",
        se: 'Det gick inte att radera förkortningen.',
    },
    abbreviationSaved: {
        us: 'Abbreviation has been saved.',
        cz: 'Zkratka byla uložena.',
        pl: 'Skrót został zapisany.',
        hr: 'Kratica je spremljena.',
        si: 'Okrajšava je shranjena.',
        de: 'Textkürzel wurde gespeichert.',
        sk: 'Skratka bola uložená.',
        es: 'La abreviatura ha sido guardada.',
        ru: 'Аббревиатура была сохранена.',
        rs: 'Skraćenica je sačuvana.',
        ua: 'Абревіатуру збережено.',
        no: 'Forkortelsen ble lagret.',
        it: "L'abrevizione è stata salvata.",
        se: 'Förkortningen har sparats.',
    },
    failedToSaveAbbreviation: {
        us: 'Failed to save abbreviation.',
        cz: 'Při ukládání zkratky došlo k chybě.',
        pl: 'Podczas zapisywania skrótu wystąpił błąd.',
        hr: 'Spremanje kratice nije uspjelo.',
        si: 'Shranjevanje okrajšave ni uspelo.',
        de: 'Speichern der Textkürzel fehlgeschlagen.',
        sk: 'Pri ukladaní skratky došlo k chybe.',
        es: 'Error al guardar la abreviatura.',
        ru: 'Не удалось сохранить аббревиатуру.',
        rs: 'Neuspelo čuvanje skraćenice.',
        ua: 'Не вдалося зберегти абревіатуру.',
        no: 'Lagring av forkortelsen mislyktes.',
        it: 'Si è verificato un errore durante il salvataggio della scelta rapida.',
        se: 'Det gick inte att spara förkortningen.',
    },
    abbreviationUpdated: {
        us: 'Abbreviation has been updated.',
        cz: 'Zkratka byla změněna.',
        pl: 'Skrót został zmieniony.',
        hr: 'Kratica je ažurirana.',
        si: 'Okrajšava je posodobljena.',
        de: 'Textkürzel wurde aktualisiert.',
        sk: 'Skratka bola zmenená.',
        es: 'La abreviatura ha sido actualizada.',
        ru: 'Аббревиатура была обновлена.',
        rs: 'Skraćenica je ažurirana.',
        ua: 'Скорочення оновлено.',
        no: 'Forkortelsen ble endret.',
        it: 'La scelta rapida è stata modificata.',
        se: 'Förkortningen har uppdaterats.',
    },
    failedToUpdateAbbreviation: {
        us: 'Failed to update abrreviation.',
        cz: 'Při ukládání zkratky došlo k chybě.',
        pl: 'Podczas zmieniania skrótu wystąpił błąd.',
        hr: 'Ažuriranje kratice nije uspjelo.',
        si: 'Posodobitev okrajšave ni uspela.',
        de: 'Textkürzel konnte nicht aktualisiert werden.',
        sk: 'Pri ukladaní skratky došlo k chybe.',
        es: 'Error al actualizar la abreviatura.',
        ru: 'Не удалось обновить аббревиатуру.',
        rs: 'Neuspelo ažuriranje skraćenice.',
        ua: 'Не вдалося оновити абревіатуру.',
        no: 'Oppdatering av forkortelsen mislyktes.',
        it: 'Si è verificato un errore durante il salvataggio della scelta rapida.',
        se: 'Det gick inte att uppdatera förkortningen.',
    },
    sectionHeadingsTesting: {
        us: 'Section headings test',
        cz: 'Testování nadpisů sekcí',
        pl: 'Test nagłówków sekcji',
        hr: 'Testiranje naslova odjeljaka',
        si: 'Preizkušanje naslovov razdelkov',
        de: 'Test der Sektionsüberschriften',
        sk: 'Testovanie nadpisov sekcií',
        es: 'Prueba de encabezados de sección',
        ru: 'Тест заголовков разделов',
        rs: 'Testiranje naslova sekcija',
        ua: 'Перевірка заголовків розділів',
        no: 'Testing av seksjonsoverskrifter',
        it: 'Titoli delle sezioni di test',
        se: 'Test på avsnittstitlar',
    },
    bulkTags: {
        us: 'Bulk editing of tags',
        cz: 'Hromadná editace štítků',
        pl: 'Zbiorcza edycja tagów',
        hr: 'Skupno uređivanje oznaka',
        si: 'Množično urejanje oznak',
        de: 'Bulk-Bearbeitung von Tags',
        sk: 'Hromadná editácia štítkov',
        es: 'Edicíón masiva de etiquetas',
        ru: 'Массовое редактирование тегов',
        rs: 'Masovno uređivanje oznaka',
        ua: 'Масове редагування тегів',
        no: 'Masseredigering av etiketter',
        it: "Modifica massiva dell'etichetta",
        se: 'Massredigering av taggar',
    },
    deleteTagFromAllProjectsPart1: {
        us: 'The tag will be deleted from',
        cz: 'Štítek bude smazán ze',
        pl: 'Tag zostanie usunięty ze',
        hr: 'Oznaka će biti izbrisana iz',
        si: 'Oznaka bo izbrisana iz',
        de: 'Das Tag wird aus',
        sk: 'Štítok bude vymazaný z',
        es: 'La etiqueta se eliminará de',
        ru: 'Метка будет удалена из',
        rs: 'Oznaka će biti uklonjena iz',
        ua: 'Тег буде видалено з',
        no: 'Etiketten blir slettet fra',
        it: "L'etichetta verrà eliminata da",
        se: 'Taggen kommer att raderas från',
    },
    deleteTagFromAllProjectsPart2: {
        us: 'all your projects.',
        cz: 'všech vašich projektů.',
        pl: 'wszystkich twoich projektów.',
        hr: 'svih vaših projekata.',
        si: 'vseh vaših projektih.',
        de: 'allen Ihren Projekten gelöscht.',
        sk: 'všetkých vašich projektov.',
        es: 'todos tus proyectos.',
        ru: 'всех ваших проектов.',
        rs: 'svih vaših projekata.',
        ua: 'з усіх ваших проектів.',
        no: 'alle dine prosjekter.',
        it: 'di tutti i vostri progetti.',
        se: 'alla dina projekt.',
    },
    apply: {
        us: 'Apply',
        cz: 'Použít',
        pl: 'Zastosuj',
        hr: 'Primijeni',
        si: 'Potrdi',
        de: 'Anwenden',
        sk: 'Použiť',
        es: 'Aplicar',
        ru: 'Применить',
        rs: 'Primeni',
        ua: 'Застосувати',
        no: 'Bruke',
        it: 'Utilizzo',
        se: 'Använda',
    },
    checkAll: {
        us: 'Check all',
        cz: 'Označit vše',
        pl: 'Zaznacz wszystko',
        hr: 'Označi sve',
        si: 'Označi vse',
        de: 'Alle überprüfen',
        sk: 'Označiť všetko',
        es: 'Marcar todos',
        ru: 'Проверить все',
        rs: 'Proveri sve',
        ua: 'Перевірити все',
        no: 'Krysse av alle',
        it: 'Contrassegnare tutto',
        se: 'Markera allt',
    },
    monthlyOverviewOfTeam: {
        us: "Monthly overview of your team's transcriptions",
        cz: 'Měsíční přehled přepisů vašeho týmu',
        pl: 'Miesięczne podsumowanie transkrypcji twojego teamu',
        hr: 'Mjesečni pregled transkripata Vašeg tima',
        si: 'Mesečni pregled prepisov vaše ekipe',
        de: 'Monatliche Übersicht der Transkriptionen Ihres Teams',
        sk: 'Mesačný prehľad prepisov vášho tímu',
        es: 'Resumen mensual de las transcripciones de tu equipo',
        ru: 'Ежемесячный обзор транскрипций вашей команды',
        rs: 'Mesečni pregled transkripcija vašeg tima',
        ua: 'Щомісячний огляд транскрипцій вашої команди',
        no: 'Månedlig oversikt over transkripsjoner av teamet ditt',
        it: 'Panoramica mensile delle trascrizioni del vostro team',
        se: 'Månadsöversikt över ditt teams transkriptioner',
    },
    teamCredit: {
        us: 'Team credit',
        cz: 'Kredit týmu',
        pl: 'Kredyt teamu',
        hr: 'Timski kredit',
        si: 'Kredit ekipe',
        de: 'Team-Kredit',
        sk: 'Kredit tímu',
        es: 'Crédito del equipo',
        ru: 'Кредит команды',
        rs: 'Kredit tima',
        ua: 'Командний кредит',
        no: 'Teamets kreditt',
        it: 'Credito del team',
        se: 'Teamets kredit',
    },
    moreCredit: {
        us: 'If you need more credit, contact your distributor',
        cz: 'Pokud potřebujete více kreditu, kontaktujte svého distributora',
        pl: 'Jeśli potrzebujesz więcej kredytu, skontaktuj się ze swoim dystrybutorem',
        hr: 'Ako Vam je potrebno više kredita, obratite se svom distributeru',
        si: 'Če potrebujete več kredita, se obrnite na vašeg distributerja',
        de: 'Wenn Sie mehr Kredit benötigen, wenden Sie sich an Ihren Vertriebspartner',
        sk: 'Ak potrebujete viac kreditu, obráťte sa na svojho distribútora',
        es: 'Si necesitas más crédito, contacta a tu distribuidor',
        ru: 'Если вам нужно больше кредитов, свяжитесь с вашим дистрибьютором.',
        rs: 'Ako vam je potrebno više kredita, kontaktirajte vašeg distributera',
        ua: "Якщо вам потрібно більше кредитів, зверніться до вашого дистриб'ютора",
        no: 'Hvis du trenger mer kreditt, ta kontakt med din distributør',
        it: 'Se avete bisogno di più crediti, contattate il vostro distributore',
        se: 'Om du behöver mer kredit, kontakta din distributör',
    },
    removeSpeaker: {
        us: "Remove current speaker's instance",
        cz: 'Smazat aktuální výskyt mluvčího',
        pl: 'Usuń instancję bieżącego mówcy',
        hr: 'Ukloni trenutnu instancu govornika',
        si: 'Odstrani trenutni primerek govorca',
        de: 'Instanz des aktuellen Sprechers entfernen',
        sk: 'Odstránenie aktuálneho vystúpenia rečníka',
        es: 'Eliminar instancia actual del hablante',
        ru: 'Удалить экземпляр текущего докладчика',
        rs: 'Ukloni trenutnog govornika',
        ua: 'Видалити поточний екземпляр доповідача',
        no: 'Slette den aktuelle talerens forekomst',
        it: "Cancellare l'apparizione dell'oratore corrente",
        se: 'Radera denna förekomst av talaren',
    },
    remove: {
        us: 'Remove',
        cz: 'Smazat',
        pl: 'Usuń',
        hr: 'Ukloni',
        si: 'Odstrani',
        de: 'Entfernen',
        sk: 'Zmazať',
        es: 'Eliminar',
        ru: 'Удалить',
        rs: 'Ukloni',
        ua: 'Видалити',
        no: 'Slette',
        it: 'Eliminare',
        se: 'Radera',
    },
    creditValidity: {
        us: 'Credit validity',
        cz: 'Platnost kreditu',
        pl: 'Ważność kredytu',
        hr: 'Valjanost kredita',
        si: 'Veljavnost kredita',
        de: 'Gültigkeit des Kredits',
        sk: 'Platnosť kreditu',
        es: 'Validez del crédito',
        ru: 'Срок действия кредитов',
        rs: 'Valjanost kredita',
        ua: 'Доступність кредиту',
        no: 'Kredittgyldighet',
        it: 'Validità del credito',
        se: 'Kreditens giltighet',
    },
    expiration: {
        us: 'Expiration',
        cz: 'Vypršení platnosti',
        pl: 'Termin ważności',
        hr: 'Istek',
        si: 'Iztek',
        de: 'Verfall',
        sk: 'Koniec platnosti',
        es: 'Vencimiento',
        ru: 'Истечение срока действия',
        rs: 'Istek',
        ua: 'Строк дії',
        no: 'Utløp',
        it: 'Scadenza',
        se: 'Utgångsdatum',
    },
    plansAndPricing: {
        us: 'Plans and pricing',
        cz: 'Předplatné a ceník',
        pl: 'Subskrypcje i ceny',
        hr: 'Pretplata i cijene',
        si: 'Naročnine in cene',
        de: 'Pläne und Preise',
        sk: 'Predplatné a cenník',
        es: 'Planes y precios',
        ru: 'Тарифы и цены',
        rs: 'Pretplata i cene',
        ua: 'Плани і ціни',
        no: 'Abonnement og prisliste',
        it: 'Abbonamenti e prezzi',
        se: 'Prenumeration och prislista',
    },
    availableFunctions: {
        us: 'Available functions',
        cz: 'Dostupné funkce',
        pl: 'Dostępne funkcje',
        hr: 'Dostupne funkcije',
        si: 'Dostopne funkcije',
        de: 'Verfügbare Funktionen',
        sk: 'Dostupné funkcie',
        es: 'Funciones disponibles',
        ru: 'Доступные функции',
        rs: 'Dostupne funkcije',
        ua: 'Доступні можливості',
        no: 'Tilgjengelige funskjoner',
        it: 'Caratteristiche disponibili',
        se: 'Tillgängliga funktioner',
    },
    creditOrSubscription: {
        us: 'You can either buy credit at a standard price or choose and manage your subscription. All in one place in our shop.',
        cz: 'V našem obchodě můžete zakoupit kredit za standardní cenu nebo si vybrat a spravovat předplatné.',
        pl: 'Możesz kupić kredyt w standardowej cenie lub wybrać subskrypcję i zarządzać nią. Wszystko w jednym miejscu w naszym sklepie.',
        hr: 'Možete kupiti kredit po standardnoj cijeni ili odabrati i upravljati pretplatom. Sve na jednom mjestu u našoj trgovini.',
        si: 'Lahko kupite kredit po standardni ceni ali izberete in upravljate svojoj naročnino. Vse na enem mestu v naši trgovini.',
        de: 'Sie können Kredit zu einem Standardpreis kaufen oder Ihr Abonnement auswählen und verwalten. Alles an einem Ort in unserem Shop.',
        sk: 'Kredit si môžete zakúpiť v našom obchode za štandardnú cenu alebo si vybrať a spravovať predplatné.',
        es: 'Puede comprar crédito a un precio estándar o elegir y gestionar su suscripción. Todo en un solo lugar en nuestra tienda.',
        ru: 'В нашем магазине вы можете приобрести кредит по стандартной цене или выбрать и управлять подпиской.',
        rs: 'Možete kupiti kredit po standardnoj ceni ili odabrati i upravljati pretplatom. Sve na jednom mestu u našoj prodavnici.',
        ua: 'Ви можете купити кредити за стандартною ціною або обрати і налашувати підписку у нашому магазині.',
        no: 'Du kan enten kjøpe kreditt til standardpris eller velge og administrere abonnementet ditt. Alt på ett sted i butikken vår.',
        it: 'Nel nostro negozio è possibile acquistare crediti al prezzo standard o scegliere e gestire il proprio abbonamento.',
        se: 'I vår butik kan du köpa kredit till standardpris eller välja och hantera en prenumeration.',
    },
    goToShop: {
        us: 'Go to shop',
        cz: 'Do obchodu',
        pl: 'Przejdź do sklepu',
        hr: 'Idite u trgovinu',
        si: 'Pojdite v trgovino',
        de: 'Zum Shop gehen',
        sk: 'Do obchodu',
        es: 'Ir a la tienda',
        ru: 'В магазин',
        rs: 'Idite u prodavnicu',
        ua: 'До магазину',
        no: 'Til butikken',
        it: 'Al negozio',
        se: 'Till butiken',
    },
    gainAccess: {
        us: 'Want to gain access to more functions?',
        cz: 'Chcete mít přístup k dalším funkcím?',
        pl: 'Chcesz uzyskać dostęp do dalszych funkcji?',
        hr: 'Želite li dobiti pristup većem broju funkcija?',
        si: 'Želite pridobiti dostop do več funkcij?',
        de: 'Möchten Sie Zugang zu mehr Funktionen haben?',
        sk: 'Chcete získať prístup k ďalším funkciám?',
        es: '¿Quiere acceder a más funciones?',
        ru: 'Хотите получить доступ к большему количеству функций?',
        rs: 'Želite li da dobijete pristup većem broju funkcija?',
        ua: 'Потрібен доступ до більших можливостей?',
        no: 'Vil du ha tilgang til flere funksjoner?',
        it: 'Volete accedere a funzioni aggiuntive?',
        se: 'Vill du få tillgång till flera funktioner?',
    },
    creditValid: {
        us: 'Credit is an addition to your subscription and is valid for 2 years since purchase.',
        cz: 'Kredit je doplněk k vašemu předplatnému a je platný dva roky od nákupu.',
        pl: 'Kredyt jest dodatkiem do subskrypcji i jest ważny przez dwa lata od zakupu.',
        hr: 'Kredit je dodatak vašoj pretplati i vrijedi 2 godine od kupnje.',
        si: 'Kredit je dodatek k vaši naročnini in velja 2 leti od nakupa.',
        de: 'Das Kredit ist ein Zusatz zu Ihrem Abonnement und ist ab dem Kauf 2 Jahre lang gültig.',
        sk: 'Kredit je doplnkom k vášmu predplatnému a platí dva roky od zakúpenia.',
        es: 'El crédito es un complemento a su suscripción y es válido durante 2 años desde la compra.',
        ru: 'Кредит является дополнением к вашей подписке и действителен в течение двух лет с момента покупки.',
        rs: 'Kredit je dodatak vašoj pretplati i važi 2 godine od dana kupovine.',
        ua: 'Кредит це додаток до підписки зі строком дії у 2 роки з дня покупки.',
        no: 'Kreditten er et tillegg til abonnementet ditt og er gyldig i 2 år etter kjøpet.',
        it: "Il credito è un'aggiunta al vostro abbonamento ed è valido per due anni dall'acquisto.",
        se: 'Kredit utgör ett tillägg till prenumerationen och dess giltighetstid är två år.',
    },
    customerPortal: {
        us: 'Go to customer portal',
        cz: 'Přejít na zákaznický portál',
        pl: 'Przejdź do portalu klienta',
        hr: 'Idite na portal za korisnike',
        si: 'Pojdite na portal za uporabnike',
        de: 'Zum Kundenportal gehen',
        sk: 'Prejsť na zákaznícky portál',
        es: 'Ir al portal del cliente',
        ru: 'Перейти на клиентский портал',
        rs: 'Idite na korisnički portal',
        ua: 'Перейти у портал клієнта',
        no: 'Gå til kundeportalen',
        it: 'Vai al portale clienti',
        se: 'Gå till kundportalen',
    },
    productFeatures: {
        us: 'Product features',
        cz: 'Detaily produktu',
        pl: 'Szczegóły produktu',
        hr: 'Značajke proizvoda',
        si: 'Značilnosti izdelka',
        de: 'Produktmerkmale',
        sk: 'Detaily produktu',
        es: 'Características del producto',
        ru: 'Информация о продукте',
        rs: 'Funkcije proizvoda',
        ua: 'Можливості продукту',
        no: 'Produktdetaljer',
        it: 'Dettagli del prodotto',
        se: 'Produktdetaljer',
    },
    buy: {
        us: 'Buy',
        cz: 'Koupit',
        pl: 'Kup',
        hr: 'Kupite',
        si: 'Kupite',
        de: 'Kaufen',
        sk: 'Kúpiť',
        es: 'Comprar',
        ru: 'Купить',
        rs: 'Kupite',
        ua: 'Купити',
        no: 'Kjøpe',
        it: 'Aquisto',
        se: 'Köp',
    },
    year: {
        us: 'Year',
        cz: 'Rok',
        pl: 'Rok',
        hr: 'Godina',
        si: 'Leto',
        de: 'Jahr',
        sk: 'Rok',
        es: 'Año',
        ru: 'Год',
        rs: 'Godina',
        ua: 'Рік',
        no: 'År',
        it: 'Anno',
        se: 'År',
    },
    monthly: {
        us: 'Monthly',
        cz: 'Měsíčně',
        pl: 'Miesięcznie',
        hr: 'Mjesečno',
        si: 'Mesečno',
        de: 'Monatlich',
        sk: 'Mesačne',
        es: 'Mensual',
        ru: 'Ежемесячно',
        rs: 'Mesečno',
        ua: 'На місяць',
        no: 'Månedlig',
        it: 'Mensile',
        se: 'Månatligen',
    },
    yearly: {
        us: 'Yearly',
        cz: 'Ročně',
        pl: 'Rocznie',
        hr: 'Godišnje',
        si: 'Letno',
        de: 'Jährlich',
        sk: 'Ročne',
        es: 'Anual',
        ru: 'Ежегодно',
        rs: 'Godišnje',
        ua: 'На рік',
        no: 'Årlig',
        it: 'Annuale',
        se: 'Årligen',
    },
    monthlySubscription: {
        us: 'Monthly subscription',
        cz: 'Měsíční předplatné',
        pl: 'Subskrypcja miesięczna',
        hr: 'Mjesečna pretplata',
        si: 'Mesečna naročnina',
        de: 'Monatliches Abonnement',
        sk: 'Mesačné predplatné',
        es: 'Suscripción mensual',
        ru: 'Ежемесячная подписка',
        rs: 'Mesečna pretplata',
        ua: 'Місячна підписка',
        no: 'Månedlig abonnement',
        it: 'Abbonamento mensile',
        se: 'Månatlig prenumeration',
    },
    annualSubscription: {
        us: 'Annual subscription',
        cz: 'Roční předplatné',
        pl: 'Subskrypcja roczna',
        hr: 'Godišnja pretplata',
        si: 'Letna naročnina',
        de: 'Jährliches Abonnement',
        sk: 'Ročné predplatné',
        es: 'Suscripción anual',
        ru: 'Ежегодная подписка',
        rs: 'Godišnja pretplata',
        ua: 'Річна підписка',
        no: 'Årlig abonnement',
        it: 'Abbonamento annuale',
        se: 'Årlig prenumeration',
    },
    allStandard: {
        us: 'All standard features',
        cz: 'Všechny standardní funkce',
        pl: 'Wszystkie standardowe funkcje',
        hr: 'Sve standardne značajke',
        si: 'Vse standardne lastnosti',
        de: 'Alle Standardfunktionen',
        sk: 'Všetky štandardné funkcie',
        es: 'Todas las características estándar',
        ru: 'Все стандартные функции',
        rs: 'Sve standardne funkcije',
        ua: 'Всі стандартні можливості',
        no: 'Alle standardfunksjoner',
        it: 'Tutte le funzioni standard',
        se: 'Alla standardfunktioner',
    },
    singleUser: {
        us: 'Single user',
        cz: 'Jeden uživatel',
        pl: 'Jeden użytkownik',
        hr: 'Jedan korisnik',
        si: 'En uporabnik',
        de: 'Einzelner Benutzer',
        sk: 'Jeden používateľ',
        es: 'Usuario único',
        ru: 'Один пользователь',
        rs: 'Jedan korisnik',
        ua: 'Один користувач',
        no: 'Enkeltbruker',
        it: 'Utente singolo',
        se: 'Enskild användare',
    },
    fiveHours: {
        us: '5 hours of credit',
        cz: '5 hodin kreditu',
        pl: '5 godzin kredytu',
        hr: '5 sati kredita',
        si: '5 ur kredita',
        de: '5 Stunden Kredit',
        sk: '5 hodín kreditu',
        es: '5 horas de crédito',
        ru: '5 часов кредита',
        rs: '5 sati kredita',
        ua: '5 годин кредитів',
        no: '5 timer av kreditt',
        it: '5 ore di credito',
        se: '5 timmar av kredit',
    },
    sixtyHours: {
        us: '60 hours of credit',
        cz: '60 hodin kreditu',
        pl: '60 godzin kredytu',
        hr: '60 sati kredita',
        si: '60 ur kredita',
        de: '60 Stunden Kredit',
        sk: '60 hodín kreditu',
        es: '60 horas de crédito',
        ru: '60 часов кредита',
        rs: '60 sati kredita',
        ua: '60 годин кредитів',
        no: '60 timer av kreditt',
        it: '60 ore di credito',
        se: '60 timmar av kredit',
    },
    hundredAndTwentyHours: {
        us: '120 hours of credit',
        cz: '120 hodin kreditu',
        pl: '120 godzin kredytu',
        hr: '120 sati kredita',
        si: '120 ur kredita',
        de: '120 Stunden Kredit',
        sk: '120 hodín kreditu',
        es: '120 horas de crédito',
        ru: '120 часов кредита',
        rs: '120 sati kredita',
        ua: '120 годин кредитів',
        no: '120 timer av kreditt',
        it: '120 ore di credito',
        se: '120 timmar av kredit',
    },
    fiveUsers: {
        us: '5 users',
        cz: '5 uživatelů',
        pl: '5 użytkowników',
        hr: '5 korisnika',
        si: '5 uporabnikov',
        de: '5 Benutzer',
        sk: '5 užívateľov',
        es: '5 usuarios',
        ru: '5 пользователей',
        rs: '5 korisnika',
        ua: '5 користувачів',
        no: '5 brukere',
        it: '5 utenti',
        se: '5 användare',
    },
    fiftyGiga: {
        us: '50 GB of disk space',
        cz: '50 GB úložiště',
        pl: '50 GB przestrzeni dyskowej',
        hr: '50 GB prostora na disku',
        si: '50 GB prostora na disku',
        de: '50 GB Speicherplatz',
        sk: '50 GB úložiska',
        es: '50 GB de espacio en disco',
        ru: '50 ГБ облачного хранилища',
        rs: '50 GB prostora na disku',
        ua: '50 GB місця для зберігання',
        no: '50 GB diskplass',
        it: '50 GB di spazio di archiviazione',
        se: '50 GB lagringsutrymme',
    },
    tenHours: {
        us: '10 hours of credit',
        cz: '10 hodin kreditu',
        pl: '10 godzin kredytu',
        hr: '10 sati kredita',
        si: '10 ur kredita',
        de: '10 Stunden Kredit',
        sk: '10 hodín kreditu',
        es: '10 horas de crédito',
        ru: '10 часов кредита',
        rs: '10 sati kredita',
        ua: '10 годин кредитів',
        no: '10 timer av kreditt',
        it: '10 ore di credito',
        se: '10 timmar av kredit',
    },
    tenUsers: {
        us: '10 users',
        cz: '10 uživatelů',
        pl: '10 użytkowników',
        hr: '10 korisnika',
        si: '10 uporabnikov',
        de: '10 Benutzer',
        sk: '10 používateľov',
        es: '10 usuarios',
        ru: '10 пользователей',
        rs: '10 korisnika',
        ua: '10 користувачів',
        no: '10 brukere',
        it: '10 utenti',
        se: '10 användare',
    },
    tenGiga: {
        us: '10 GB of disk space',
        cz: '10 GB úložiště',
        pl: '10 GB przestrzeni dyskowej',
        hr: '10 GB prostora na disku',
        si: '10 GB prostora na disku',
        de: '10 GB Speicherplatz',
        sk: '10 GB úložiska',
        es: '10 GB de espacio en disco',
        ru: '10 ГБ облачного хранилища',
        rs: '10 GB prostora na disku',
        ua: '10 GB місця для зберігання',
        no: '10 GB diskplass',
        it: '10 GB di spazio di archiviazione',
        se: '10 GB lagringsutrymme',
    },
    hundredGiga: {
        us: '100 GB of disk space',
        cz: '100 GB úložiště',
        pl: '100 GB przestrzeni dyskowej',
        hr: '100 GB prostora na disku',
        si: '100 GB prostora na disku',
        de: '100 GB Speicherplatz',
        sk: '100 GB úložiska',
        es: '100 GB de espacio en disco',
        ru: '100 ГБ облачного хранилища',
        rs: '100 GB prostora na disku',
        ua: '100 GB місця для зберігання',
        no: '100 GB diskplass',
        it: '100 GB di spazio di archiviazione',
        se: '100 GB lagringsutrymme',
    },
    getPlan: {
        us: 'Get plan',
        cz: 'Koupit předplatné',
        pl: 'Kup subskrypcję',
        hr: 'Pretplatite se',
        si: 'Izberite naročnino',
        de: 'Plan erhalten',
        sk: 'Kúpiť predplatné',
        es: 'Obtener plan',
        ru: 'Купить Подписку',
        rs: 'Pretplatite se',
        ua: 'Прибайте план',
        no: 'Kjøpe abonnement',
        it: 'Acquista un abbonamento',
        se: 'Köp prenumeration',
    },
    hour: {
        us: 'hour',
        cz: 'hodina',
        pl: 'godzina',
        hr: 'sat',
        si: 'ura',
        de: 'Stunde',
        sk: 'hodina',
        es: 'hora',
        ru: 'час',
        rs: 'sat',
        ua: 'година',
        no: 'time',
        it: "l'ora",
        se: 'timme',
    },
    payAsYouGoCredit: {
        us: 'Pay-as-you-go credit',
        cz: 'Pay-as-you-go kredit',
        pl: 'Kredyt Pay-as-you-go',
        hr: 'Pay-as-you-go kredit',
        si: 'Pay-as-you-go kredit',
        de: 'Pay-as-you-go Kredit',
        sk: 'Priebežný kredit',
        es: 'Crédito de pago según uso',
        ru: 'Pay-as-you-go kredit',
        rs: 'Pay-as-you-go kredit',
        ua: 'Pay-as-you-go кредити',
        no: 'Kreditt som betales etter behov',
        it: 'Credito a consumo',
        se: 'Kredit med användningsbaserad betalning',
    },
    selectPlan: {
        us: 'Select a plan',
        cz: 'Vyberte předplatné',
        pl: 'Wybierz subskrypcję',
        hr: 'Odaberite pretplatu',
        si: 'Izberite naročnino',
        de: 'Wählen Sie einen Plan',
        sk: 'Vyberte predplatné',
        es: 'Seleccionar un plan',
        ru: 'Выберите Подписку',
        rs: 'Izaberite pretplatu',
        ua: 'Оберіть план',
        no: 'Velge abonnement',
        it: 'Seleziona un abbonamento',
        se: 'Välj prenumeration',
    },
    teamPlan: {
        us: 'Team plan',
        cz: 'Týmové předplatné',
        pl: 'Subskrypcja teamu',
        hr: 'Timska pretplata',
        si: 'Ekipna naročnina',
        de: 'Team-Plan',
        sk: 'Tímové predplatné',
        es: 'Plan de equipo',
        ru: 'Командная Подписка',
        rs: 'Timska pretplata',
        ua: 'Командний план',
        no: 'Teamabonnement',
        it: 'Abbonamento di squadra',
        se: 'Teamprenumeration',
    },
    remainingTeamCredit: {
        us: 'Remaining team credit',
        cz: 'Zbývající kredit týmu',
        pl: 'Pozostały kredyt teamu',
        hr: 'Preostali timski kredit',
        si: 'Preostali ekipni kredit',
        de: 'Verbleibendes Team-Kredit',
        sk: 'Zostávajúci kredit tímu',
        es: 'Crédito restante del equipo',
        ru: 'Оставшийся кредит команды',
        rs: 'Preostali timski kredit',
        ua: 'Залишок кредитів команди',
        no: 'Gjenværende teamkreditt',
        it: 'Credito residuo della squadra',
        se: 'Teamets återstående kredit',
    },
    includingSubscription: {
        us: 'Including subscription credit',
        cz: 'Včetně kreditu z předplatného',
        pl: 'W tym kredyt z subskrypcji',
        hr: 'Uključujući kredit za pretplatu',
        si: 'Vključno s kreditom za naročnino',
        de: 'Inklusives Abo-Kredit',
        sk: 'Vrátane kreditu z predplatného',
        es: 'Incluyendo crédito de suscripción',
        ru: 'Включен кредит Подписки',
        rs: 'Uključujući kredit za pretplatu',
        ua: 'Включно з кредитами підписки',
        no: 'Inkludert abonnementskreditt',
        it: "Credito dell'abbonamento incluso",
        se: 'Inkl. kredit från prenumerationen',
    },
    subscriptionPlan: {
        us: 'Subscription plan',
        cz: 'Předplatné',
        pl: 'Subskrypcja',
        hr: 'Pretplate',
        si: 'Naročnina',
        de: 'Abonnement-Plan',
        sk: 'Predplatné',
        es: 'Plan de suscripción',
        ru: 'Тарифный план',
        rs: 'Plan pretplate',
        ua: 'План підписки',
        no: 'Abonnement',
        it: 'Abbonamenti',
        se: 'Prenumeration',
    },
    noSubscription: {
        us: 'Your team has currently no subscription.',
        cz: 'Váš tým momentálně nemá žádné předplatné.',
        pl: 'Twój team nie ma obecnie żadnej subskrypcji.',
        hr: 'Vaš tim trenutačno nema pretplate.',
        si: 'Vaša ekipa trenutno nima naročnine.',
        de: 'Ihr Team hat derzeit kein Abonnement.',
        sk: 'Váš tím momentálne nemá žiadne predplatné.',
        es: 'Su equipo actualmente no tiene suscripción.',
        ru: 'У вашей команды сейчас нет Подписки.',
        rs: 'Vaš tim trenutno nema pretplatu.',
        ua: 'У вашої команди запраз немає підписки.',
        no: 'Teamet ditt har for øyeblikket ikke noe abonnement.',
        it: 'La tua squadra non ha attualmente alcun abbonamento.',
        se: 'Ditt team har inte någon prenumeration för tillfället.',
    },
    seeOffer: {
        us: 'See our offer below.',
        cz: 'Podívejte se na naši nabídku níže.',
        pl: 'Sprawdź naszą ofertę poniżej.',
        hr: 'Našu ponudu pogledajte u nastavku.',
        si: 'Spodaj si oglejte našo ponudbo.',
        de: 'Siehe unser Angebot unten.',
        sk: 'Pozrite si našu ponuku nižšie.',
        es: 'Vea nuestra oferta a continuación.',
        ru: 'Ознакомьтесь с нашим предложением ниже.',
        rs: 'Pogledajte našu ponudu u nastavku.',
        ua: 'Пропозиція нижче.',
        no: 'Se tilbudet vårt nedenfor.',
        it: 'Consultate la nostra offerta qui di seguito.',
        se: 'Titta på vårt utbud nedan.',
    },
    business: {
        us: 'Business',
        cz: 'Business',
        pl: 'Business',
        hr: 'Business',
        si: 'Business',
        de: 'Business',
        sk: 'Business',
        es: 'Business',
        ru: 'Business',
        rs: 'Business',
        ua: 'Business',
        no: 'Business',
        it: 'Business',
        se: 'Business',
    },
    buyAsYouGo: {
        us: 'Buy credit as you go',
        cz: 'Nákup kreditu dle potřeby',
        pl: 'Kup kredyt pay-as-you-go',
        hr: 'Kupite kredit po potrebi',
        si: 'Kupite kredit po potrebi',
        de: 'Kaufen Sie Kredit nach Bedarf',
        sk: 'Nákup kreditu podľa potreby',
        es: 'Compre crédito a medida que avanza',
        ru: 'Покупка кредита при необходимости',
        rs: 'Kupite kredit po potrebi',
        ua: 'Купуйте кредити як користуєтесь',
        no: 'Kjøp kreditt etter behov',
        it: 'Acquisto di credito secondo le necessità',
        se: 'Köp kredit med användningsbaserad betalning',
    },
    unlimitedCredit: {
        us: 'Unlimited credit',
        cz: 'Neomezený kredit',
        pl: 'Nielimitowany kredyt',
        hr: 'Neograničen kredit',
        si: 'Neomejen kredit',
        de: 'Unbegrenzter Kredit',
        sk: 'Neobmedzený kredit',
        es: 'Crédito ilimitado',
        ru: 'Неограниченный кредит',
        rs: 'Neograničen kredit',
        ua: 'Необмежений кредит',
        no: 'Ubegrenset kreditt',
        it: 'Credito illimitato',
        se: 'Obegränsat kredit',
    },
    unlimitedTeam: {
        us: 'Unlimited team',
        cz: 'Neomezený tým',
        pl: 'Nielimitowany team',
        hr: 'Neograničen tim',
        si: 'Neomejena ekipa',
        de: 'Unbegrenztes Team',
        sk: 'Neobmedzený tím',
        es: 'Equipo ilimitado',
        ru: 'Неограниченная команда',
        rs: 'Neograničen tim',
        ua: 'Необмежена команда',
        no: 'Ubegrenset team',
        it: 'Team illimitato',
        se: 'Obegränsat team',
    },
    unlimitedStorage: {
        us: 'Unlimited storage',
        cz: 'Neomezené úložiště',
        pl: 'Nielimitowana przestrzeń dyskowa',
        hr: 'Neograničen prostor za pohranu',
        si: 'Neomejen prostor za shranjevanje',
        de: 'Unbegrenzter Speicherplatz',
        sk: 'Neobmedzené úložisko',
        es: 'Almacenamiento ilimitado',
        ru: 'Неограниченное облачное хранилище',
        rs: 'Neograničen prostor za skladištenje',
        ua: 'Необмежене зберігання',
        no: 'Ubegrenset lagringskapasitet',
        it: 'Deposito illimitato',
        se: 'Obegränsat lagringsutrymme',
    },
    ranOutCredit: {
        us: 'Ran out of minutes?',
        cz: 'Došel vám kredit?',
        pl: 'Nie masz kredytu?',
        hr: 'Ostali ste bez minuta?',
        si: 'Vam je zmanjkalo minut?',
        de: 'Haben Sie keine Minuten mehr?',
        sk: 'Došiel vám kredit?',
        es: '¿Se quedó sin minutos?',
        ru: 'Закончился кредит?',
        rs: 'Istekli su Vam minuti?',
        ua: 'Не вистачило хвилин?',
        no: 'Tom for kreditt?',
        it: 'Finito il credito?',
        se: 'Har minuterna tagit slut?',
    },
    topUp: {
        us: 'Top up at a standard price',
        cz: 'Dobijte si za standardní cenu',
        pl: 'Doładuj w standardowej cenie',
        hr: 'Dopunite kredit po standardnoj cijeni',
        si: 'Dopolnite kredit po standardni ceni',
        de: 'Aufladen zum Standardpreis',
        sk: 'Dobite si za štandardnú cenu',
        es: 'Recargue a un precio estándar',
        ru: 'Пополните по стандартной цене',
        rs: 'Dopunite kredit po standardnoj ceni',
        ua: 'Поповнити за стандартною ціною',
        no: 'Fylle opp til standardpris',
        it: 'Ricarica al prezzo standard',
        se: 'Fyll på kredit till standardpris',
    },
    usage: {
        us: 'Remaining subscription balance',
        cz: 'V rámci předplatného vám zbývá',
        pl: 'W subskrypcji dostępnych jest jeszcze',
        hr: 'Preostali saldo pretplate',
        si: 'Preostalo stanje naročnine',
        de: 'In Ihrem Abonnement bleibt noch',
        sk: 'V rámci predplatného vám zostáva',
        es: 'Saldo de suscripción restante',
        ru: 'В рамках подписки вам остается',
        rs: 'Preostalo stanje pretplate',
        ua: 'Залишок коштів на підписці',
        no: 'Gjenværende abonnementssaldo',
        it: 'Come parte del vostro abbonamento, avete a disposizione quanto segue\n',
        se: 'Inom prenumerationen återstâr',
    },
    nextPayment: {
        us: 'Next payment',
        cz: 'Příští platba',
        pl: 'Następna płatność',
        hr: 'Sljedeća uplata',
        si: 'Naslednje plačilo',
        de: 'Nächste Zahlung',
        sk: 'Ďalšia platba',
        es: 'Próximo pago',
        ru: 'Следующая оплата',
        rs: 'Sledeća uplata',
        ua: 'Наступна оплата',
        no: 'Neste betaling',
        it: 'Prossimo pagamento',
        se: 'Nästa betalning',
    },
    includedFeatures: {
        us: 'Included features',
        cz: 'Součástí předplatného je',
        pl: 'Subskrypcja obejmuje',
        hr: 'Uključene značajke',
        si: 'Vključene funkcije',
        de: 'Enthaltene Funktionen',
        sk: 'Predplatné zahŕňa',
        es: 'Características incluidas',
        ru: 'В Подписку включено',
        rs: 'Uključene funkcije',
        ua: 'Включені можливості',
        no: 'Inkluderte funksjoner',
        it: "L'abbonamento comprende",
        se: 'I prenumerationen ingår',
    },
    notIncluded: {
        us: 'Not included',
        cz: 'Není součástí předplatného',
        pl: 'Nie zawarte w subskrypcji',
        hr: 'Nije uključeno u pretplatu',
        si: 'Ni vključeno',
        de: 'Nicht enthalten',
        sk: 'Nie je zahrnuté v predplatnom',
        es: 'No incluidas',
        ru: 'Не включено в подписку',
        rs: 'Nije uključeno u pretplatu',
        ua: 'Не включено',
        no: 'Ikke inkludert i abonnementet',
        it: "Non incluso nell'abbonamento",
        se: 'Ej ingår i prenumerationen',
    },
    allFeatures: {
        us: 'You have all available features!',
        cz: 'Máte k dispozici všechny funkce!',
        pl: 'Masz do dyspozycji wszystkie funkcje!',
        hr: 'Imate sve dostupne značajke!',
        si: 'Na voljo imate vse funkcije!',
        de: 'Sie haben alle verfügbaren Funktionen!',
        sk: 'Všetky funkcie sú vám k dispozícii!',
        es: '¡Tiene todas las características disponibles!',
        ru: 'У вас есть все доступные функции!',
        rs: 'Imate sve dostupne funkcije!',
        ua: 'У вас всі доступні можливості!',
        no: 'Du har alle tilgjengelige funksjoner!',
        it: 'Tutte le funzioni sono a vostra disposizione!',
        se: 'Alla funktioner är tillgängliga!',
    },
    managePlan: {
        us: 'Manage plan',
        cz: 'Spravovat předplatné',
        pl: 'Zarządzaj subskrypcją',
        hr: 'Upravljanje pretplatom',
        si: 'Upravljanje naročnine',
        de: 'Plan verwalten',
        sk: 'Spravovať predplatné',
        es: 'Gestionar plan',
        ru: 'Управлять Подпиской',
        rs: 'Upravljanje pretplatom',
        ua: 'Налаштування плану',
        no: 'Administrere abonnementet',
        it: 'Gestisci il tuo abbonamento',
        se: 'Hantera din prenumeration',
    },
    minutesRemaining: {
        us: 'Minutes',
        cz: 'Minuty',
        pl: 'Minuty',
        hr: 'Minute',
        si: 'minute',
        de: 'Minuten',
        sk: 'Minúty',
        es: 'Minutos',
        ru: 'Минуты',
        rs: 'Minuta',
        ua: 'Хвилини',
        no: 'Minutter',
        it: 'Minuti',
        se: 'Minuter',
    },
    storageSpace: {
        us: 'Storage',
        cz: 'Úložiště',
        pl: 'Przestrzeń dyskowa',
        hr: 'Prostor za pohranu',
        si: 'Prostor za shranjevanje',
        de: 'Speicherplatz',
        sk: 'Úložisko',
        es: 'Almacenamiento',
        ru: 'Облачное хранилище',
        rs: 'Prostor za skladištenje',
        ua: 'Зберігання',
        no: 'Lagring',
        it: 'Memmorizzazione',
        se: 'Lagringsutrymme',
    },
    cancelPlan: {
        us: 'Cancel plan',
        cz: 'Zrušit předplatné',
        pl: 'Anuluj subskrypcję',
        hr: 'Otkažite pretplatu',
        si: 'Prekliči naročnino',
        de: 'Plan stornieren',
        sk: 'Zrušiť predplatné',
        es: 'Cancelar plan',
        ru: 'Отменить Подписку',
        rs: 'Otkažite pretplatu',
        ua: 'Відмінити план',
        no: 'Si opp abonnementet',
        it: "Annullamento dell'abbonamento",
        se: 'Avbryt prenumeration',
    },
    custom: {
        us: 'Custom',
        cz: 'Na míru',
        pl: 'Szyte na miarę',
        hr: 'Prilagođeno',
        si: 'Po meri',
        de: 'Benutzerdefiniert',
        sk: 'Na mieru',
        es: 'Personalizado',
        ru: 'По договоренности',
        rs: 'Po meri',
        ua: 'Спеціалізовано',
        no: 'Tilpasset',
        it: 'Su misura',
        se: 'Anpassad',
    },
    free: {
        us: 'Free',
        cz: 'Zdarma',
        pl: 'Bezpłatnie',
        hr: 'Besplatno',
        si: 'Brezplačno',
        de: 'Kostenlos',
        sk: 'Zadarmo',
        es: 'Gratis',
        ru: 'Бесплатно',
        rs: 'Besplatno',
        ua: 'Безкоштовно',
        no: 'Gratis',
        it: 'Gratuito',
        se: 'Kostnadsfritt',
    },
    managePlanToGetFeatures: {
        us: 'Manage plan to get more features',
        cz: 'Získat víc funkcí ve správě předplatného',
        pl: 'Zarządzaj subskrypcją, aby uzyskać więcej funkcji',
        hr: 'Upravljajte planom kako biste dobili više značajki',
        si: 'Upravljajte naročnino, da pridobite več funkcij',
        de: 'Plan verwalten, um mehr Funktionen zu erhalten',
        sk: 'Získať viac funkcií v správe predplatného',
        es: 'Gestione el plan para obtener más funciones',
        ru: 'Получите больше возможностей с помощью управления подписками',
        rs: 'Upravljajte planom kako biste dobili više funkcija',
        ua: 'Налаштувати план, щоб отримати більше можливостей',
        no: 'Administrere abonnementet for å få flere funksjoner',
        it: 'Ottieni più funzioni nella gestione degli abbonamenti',
        se: 'Hantera din prenumeration för att få flera funktioner',
    },
    active: {
        us: 'Active',
        cz: 'Aktivní',
        pl: 'Aktywne',
        hr: 'Aktivno',
        si: 'Aktivno',
        de: 'Aktiv',
        sk: 'Aktívne',
        es: 'Activo',
        ru: 'Активный',
        rs: 'Aktivno',
        ua: 'Активний',
        no: 'Aktiv',
        it: 'Attivo',
        se: 'Aktiv',
    },
    currentPlan: {
        us: 'Current plan',
        cz: 'Vaše předplatné',
        pl: 'Twoja subskrypcja',
        hr: 'Vaša pretplata',
        si: 'Trenutna naročnina',
        de: 'Aktueller Plan',
        sk: 'Vaše predplatné',
        es: 'Plan actual',
        ru: 'Ваша Подписка',
        rs: 'Vaša pretplata',
        ua: 'Поточний план',
        no: 'Ditt abonnement',
        it: 'Il tuo abbonamento',
        se: 'Din prenumeration',
    },
    contactSales: {
        us: 'Contact Sales',
        cz: 'Kontaktujte nás',
        pl: 'Skontaktuj się z nami',
        hr: 'Kontaktirajte s nama',
        si: 'Stopite v stik z nami',
        de: 'Sales kontaktieren',
        sk: 'Kontaktujte nás',
        es: 'Contactar ventas',
        ru: 'Связаться с нами',
        rs: 'Kontaktirajte prodaju',
        ua: 'Зверніться до нас',
        no: 'Kontakt oss',
        it: 'Contattateci',
        se: 'Kontakta oss',
    },
    buyPlans: {
        us: 'Buy another plan to get more features',
        cz: 'Koupit jiné předplatné a získat víc funkcí',
        pl: 'Kup inną subskrypcję i uzyskaj więcej funkcji',
        hr: 'Odaberite drugu pretplatu kako biste dobili više značajki',
        si: 'Kupite drug paket in pridobite več funkcij',
        de: 'Kaufen Sie einen anderen Plan, um mehr Funktionen zu erhalten',
        sk: 'Kúpiť iné predplatné a získať viac funkcií',
        es: 'Compre otro plan para obtener más funciones',
        ru: 'Купите другой план, чтобы получить больше возможностей',
        rs: 'Kupite drugu pretplatu kako biste dobili više funkcija',
        ua: 'Оберіть інший план, щоб отримати більше можливостей',
        no: 'Kjøpe en annen abonnementsplan for å få flere funksjoner',
        it: 'Acquista un altro abbonamento e ottieni più funzioni',
        se: 'Köp en annan prenumeration och få flera funktioner',
    },
    willSwitch: {
        us: 'Your plan will switch to Start plan on',
        cz: 'Vaše předplatné přejde na předplatné Start',
        pl: 'Twoja subskrypcja zostanie zmieniona na subskrypcję Start',
        hr: 'Vaša će se pretplata promijeniti u pretplatu Start',
        si: 'Vaša naročnina se bo spremenila v naročnino Start',
        de: 'Ihr Tarif wechselt zum Tarif Start am',
        sk: 'Vaše predplatné sa zmení na predplatné Start',
        es: 'Su plan cambiará al plan de inicio en',
        ru: 'Ваша подписка изменится на «Start»',
        rs: 'Vaša pretplata će se promeniti u Počni pretplatu',
        ua: 'Ваш план буде переключено на план Старт',
        no: 'Planen din bytter til Start-plan den',
        it: 'Il vostro abbonamento passerà a un abbonamento Start',
        se: 'Din prenumeration byts till Start',
    },
    renewPlan: {
        us: 'Renew plan',
        cz: 'Obnovit předplatné',
        pl: 'Odnów subskrypcję',
        hr: 'Obnovite pretplatu',
        si: 'Obnovi naročnino',
        de: 'Plan erneuern',
        sk: 'Obnoviť predplatné',
        es: 'Renovar plan',
        ru: 'Возобновить подписку',
        rs: 'Obnovite pretplatu',
        ua: 'Поновити план',
        no: 'Fornye planen',
        it: 'Rinnova il tuo abbonamento',
        se: 'Förnya prenumeration',
    },
    cancelPaidPlan: {
        us: 'Cancel paid plan',
        cz: 'Zrušit placené předplatné',
        pl: 'Anuluj płatną subskrypcję',
        hr: 'Otkažite plaćenu pretplatu',
        si: 'Prekliči plačano naročnino',
        de: 'Bezahltes Abo beenden',
        sk: 'Zrušiť platené predplatné',
        es: 'Cancelar plan pagado',
        ru: 'Отменить Подписку',
        rs: 'Otkažite plaćenu pretplatu',
        ua: 'Відмінити платний план',
        no: 'Avbryte et betalt abonnement',
        it: 'Annullare un abbonamento a pagamento',
        se: 'Avbryt betalprenumeration',
    },
    daily: {
        us: 'Daily',
        cz: 'Denně',
        pl: 'Dziennie',
        hr: 'Dnevno',
        si: 'Dnevno',
        de: 'Täglich',
        sk: 'Denne',
        es: 'Diariamente',
        ru: 'Ежедневно',
        rs: 'Dnevno',
        ua: 'Щоденно',
        no: 'Daglig',
        it: 'Ogni giorno',
        se: 'Dagligen',
    },
    fillNameOrCompany: {
        us: 'Fill in your first name and last name, or company name',
        cz: 'Vyplňte jméno a příjmení nebo název společnosti',
        pl: 'Wpisz imię i nazwisko lub nazwę firmy',
        hr: 'Unesite ime i prezime ili naziv tvrtke',
        si: 'Vnesite ime in priimek ali ime podjetja',
        de: 'Geben Sie Ihren Vor- und Nachnamen oder den Namen Ihres Unternehmens ein',
        sk: 'Vyplňte meno a priezvisko alebo názov spoločnosti',
        es: 'Complete su nombre y apellido o nombre de la empresa',
        ru: 'Заполните имя и фамилию или название компании',
        rs: 'Unesite Vaše ime i prezime ili naziv kompanije',
        ua: "Вкажіть ім'я та прізвіще або назву компанії",
        no: 'Fyll ut fornavn og etternavn eller firmanavn',
        it: 'Inserisci il tuo nome o il nome della tua azienda',
        se: 'Ange ditt för- och efternamn eller företagsnamn',
    },
    send: {
        us: 'Send',
        cz: 'Odeslat',
        pl: 'Wyślij',
        hr: 'Pošalji',
        si: 'Pošlji',
        de: 'Senden',
        sk: 'Odoslať',
        es: 'Enviar',
        ru: 'Подтвердить',
        rs: 'Pošalji',
        ua: 'Відправити',
        no: 'Sende',
        it: 'inviare',
        se: 'Skicka',
    },
    remainingPayAsYouGo: {
        us: 'Remaining pay-as-you-go credit',
        cz: 'Zbývající pay-as-you-go kredit',
        pl: 'Pozostały kredyt pay-as-you-go',
        hr: 'Preostali pay-as-you-go kredit',
        si: 'Preostali pay-as-you-go kredit',
        de: 'Verbleibendes Pay-as-you-go Kredit',
        sk: 'Zostávajúci priebežný kredit',
        es: 'Crédito restante de pago según uso',
        ru: 'Оставшийся pay-as-you-go кредит',
        rs: 'Preostali pay-as-you-go kredit',
        ua: 'Залишок кредитів pay-as-you-go',
        no: 'Gjenværende kreditt som betales etter behov',
        it: "Credito residuo dell'abbonamento a pagamento",
        se: 'Återstående kredit med användningsbaserad betalning',
    },
    hourShort: {
        us: 'hour',
        cz: 'hod',
        pl: 'godz.',
        hr: 'sat',
        si: 'ura',
        de: 'Stunde',
        sk: 'hod',
        es: 'hora',
        ru: 'час',
        rs: 'sat',
        ua: 'година',
        no: 'timer',
        it: 'ora',
        se: 'timme',
    },
    minimumCredit: {
        us: 'Minimum amount of credit to purchase is 60 minutes.',
        cz: 'Minimální množství zakoupeného kreditu je 60 minut.',
        pl: 'Minimalnie możesz kupić 60 minut.',
        hr: 'Minimalni iznos kredita za kupnju je 60 minuta.',
        si: 'Najmanjši znesek kredita za nakup je 60 minut.',
        de: 'Der Mindestbetrag des zu erwerbenden Kredits beträgt 60 Minuten.',
        sk: 'Minimálne množstvo zakúpeného kreditu je 60 minút.',
        es: 'La cantidad mínima de crédito a comprar es de 60 minutos.',
        ru: 'Минимальное количество составляет 60 минут.',
        rs: 'Minimalni iznos kredita za kupovinu je 60 minuta.',
        ua: 'Мінімальна кількість кредитів для покупки 60 хвилин.',
        no: 'Minste kredittbeløp som kan kjøpes er 60 minutter.',
        it: 'Il credito minimo acquistabile è di 60 minuti.',
        se: 'Minimikredit att köpa är 60 minuter.',
    },
    fromAmount: {
        us: 'from',
        cz: 'z',
        pl: 'z',
        hr: 'od',
        si: 'od',
        de: 'von',
        sk: 'z',
        es: 'desde',
        ru: 'из',
        rs: 'od',
        ua: 'з',
        no: 'av',
        it: 'di',
        se: 'utav',
    },
    lastPayment: {
        us: 'Last payment status',
        cz: 'Poslední platba',
        pl: 'Ostatnia płatność',
        hr: 'Status posljednje uplate',
        si: 'Status plačila',
        de: 'Letzte Zahlung',
        sk: 'Posledná platba',
        es: 'Estado del último pago',
        ru: 'Статус последнего платежа',
        rs: 'Status poslednje uplate',
        ua: 'Статус останньої оплати',
        no: 'Siste betalingsstatus',
        it: "L'ultimo pagamento",
        se: 'Den senaste betalningen',
    },
    paid: {
        us: 'Paid',
        cz: 'Zaplaceno',
        pl: 'Zapłacono',
        hr: 'Plaćeno',
        si: 'Plačan',
        de: 'Bezahlt',
        sk: 'Zaplatené',
        es: 'Pagado',
        ru: 'Оплачено',
        rs: 'Plaćeno',
        ua: 'Оплачено',
        no: 'Betalt',
        it: 'Pagato',
        se: 'Betalt',
    },
    showSpeakerSign: {
        us: 'Show speaker sign',
        cz: 'Zobrazit značku mluvčího',
        pl: 'Pokaż znak mówcy',
        hr: 'Prikaži znak govornika',
        si: 'Prikaži znak govorca',
        de: 'Sprecherzeichen anzeigen',
        sk: 'Zobraziť značku rečníka',
        es: 'Mostrar signo del orador',
        ru: 'Показывать знак диктора',
        rs: 'Pokaži znak govornika',
        ua: 'Показувати знак доповідача',
        no: 'Vise talerens tegn',
        it: "Visualizza l'etichetta dell'oratore",
        se: 'Visa talartecken',
    },
    directTranscriptionLimit: {
        us: 'Limit of direct transcriptions',
        cz: 'Limit přímých přepisů',
        pl: 'Limit bezpośrednich transkrypcji',
        hr: 'Ograničenje izravnih transkripcija',
        si: 'Omejitev neposrednih prepisov',
        de: 'Limit für direkte Transkriptionen',
        sk: 'Limit priamych prepisov',
        es: 'Límite de transcripciones directas',
        ru: 'Ограничение количества прямых транскрипций',
        rs: 'Ograničenje direktnih transkripcija',
        ua: 'Обмеження на прямі транскрипції',
        no: 'Grense for direkte transkripsjoner',
        it: 'Limite delle trascrizioni dirette',
        se: 'Gränsen för direkta transkriptionen',
    },
    exportTemplates: {
        us: 'Export templates',
        cz: 'Exportovací šablony',
        pl: 'Szablony eksportu',
        hr: 'Izvezi predloške',
        si: 'Izvezi predloge',
        de: 'Vorlagen exportieren',
        sk: 'Exportné šablóny',
        es: 'Exportar plantillas',
        ru: 'Шаблоны экспорта',
        rs: 'Izvoz šablona',
        ua: 'Шаблони експорту',
        no: 'Eksportmaler',
        it: 'Modelli di esportazione',
        se: 'Exportmallar',
    },
    uploadExportTemplate: {
        us: 'Upload export template',
        cz: 'Nahrát exportovací šablonu',
        pl: 'Wgraj szablon eksportu',
        hr: 'Učitajte predložak za izvoz',
        si: 'Naloži predlogo za izvoz',
        de: 'Exportvorlage hochladen',
        sk: 'Nahrať exportnú šablónu',
        es: 'Subir plantilla de exportación',
        ru: 'Загрузка шаблона экспорта',
        rs: 'Učitajte šablon za izvoz',
        ua: 'Завантажити шаблон експорту',
        no: 'Laste opp eksportmaler',
        it: 'Carica modello di esportazione',
        se: 'Ladda upp exportmall',
    },
    exportTemplate: {
        us: 'Export template',
        cz: 'Exportovací šablona',
        pl: 'Szablon eksportu',
        hr: 'Izvezi predložak',
        si: 'Izvezi predlogo',
        de: 'Vorlage exportieren',
        sk: 'Exportná šablóna',
        es: 'Exportar plantilla',
        ru: 'Экспортировать шаблон',
        rs: 'Izvezi šablon',
        ua: 'Шаблон для експорту',
        no: 'Eksportmalen',
        it: 'Modello di esportazione',
        se: 'Exportmall',
    },
    templateExists: {
        us: 'This template already exists',
        cz: 'Tato šablona již existuje',
        pl: 'Ten szablon już istnieje',
        hr: 'Ovaj predložak već postoji',
        si: 'Ta predloga že obstaja',
        de: 'Diese Vorlage existiert bereits',
        sk: 'Táto šablóna už existuje',
        es: 'Esta plantilla ya existe',
        ru: 'Этот шаблон уже существует',
        rs: 'Ovaj šablon već postoji',
        ua: 'Цей шаблон вже існує',
        no: 'Denne malen finnes allerede',
        it: 'Questo modello esiste già',
        se: 'Denna mall finns redan',
    },
    uploadTemplate: {
        us: 'Upload the template file',
        cz: 'Nahrajte soubor s šablonou',
        pl: 'Wgraj plik szablonu',
        hr: 'Učitajte datoteku predloška',
        si: 'Naložite datoteko s predlogo',
        de: 'Upload der Vorlagendatei',
        sk: 'Nahrajte súbor so šablónou',
        es: 'Subir el archivo de la plantilla',
        ru: 'Загрузите файл шаблона',
        rs: 'Učitajte datoteku šablona',
        ua: 'Завантажте файл шаблону',
        no: 'Laste opp malfilen',
        it: 'Carica il file del modello',
        se: 'Ladda upp en mallfil',
    },
    nameTemplate: {
        us: 'Enter name of the template',
        cz: 'Zadejte název šablony',
        pl: 'Wprowadź nazwę szablonu',
        hr: 'Unesite naziv predloška',
        si: 'Vnesite ime predloge',
        de: 'Name der Vorlage eingeben',
        sk: 'Zadajte názov šablóny',
        es: 'Ingresar nombre de la plantilla',
        ru: 'Введите название шаблона',
        rs: 'Unesite ime šablona',
        ua: 'Введіть назву шаблону',
        no: 'Oppgi navn på malen',
        it: 'Inserire un nome per il modello',
        se: 'Ange namnet på mallfilen',
    },
    apiToken: {
        us: 'API token',
        cz: 'API token',
        pl: 'Token API',
        hr: 'API token',
        si: 'API token',
        de: 'API-Token',
        sk: 'API token',
        es: 'Token de API',
        ru: 'API-токен',
        rs: 'API token',
        ua: 'API токен',
        no: 'API token',
        it: 'Token API',
        se: 'API-token',
    },
    authentification: {
        us: 'Authentification',
        cz: 'Autentifikace',
        pl: 'Autoryzacja',
        hr: 'Autentifikacija',
        si: 'Avtentikacija',
        de: 'Authentifizierung',
        sk: 'Autentifikácia',
        es: 'Autenticación',
        ru: 'Аутентификация',
        rs: 'Autentifikacija',
        ua: 'Аутентифікація',
        no: 'Autentisering',
        it: 'Autentificazione',
        se: 'Autentisering',
    },
    copyToClipboard: {
        us: 'Copy to clipboard',
        cz: 'Zkopírovat do schránky',
        pl: 'Kopiuj do schowka',
        hr: 'Kopiraj u međuspremnik',
        si: 'Kopiraj v medpomilnik',
        de: 'In die Zwischenablage kopieren',
        sk: 'Skopírovať do schránky',
        es: 'Copiar al portapapeles',
        ru: 'Копирование в буфер обмена',
        rs: 'Kopiraj u privremenu memoriju',
        ua: 'Скопіювати в буфер обміну',
        no: 'Kopiere til utklippstavla',
        it: 'Copia negli appunti',
        se: 'Kopiera till urklipp',
    },
    textCopied: {
        us: 'Copied to clipboard',
        cz: 'Zkopírováno do schránky',
        pl: 'Skopiowano do schowka',
        hr: 'Kopirano u međuspremnik',
        si: 'Kopirano v medpomilnik',
        de: 'In die Zwischenablage kopiert',
        sk: 'Skopírované do schránky',
        es: 'Copiado al portapapeles',
        ru: 'Скопировано в буфер обмена',
        rs: 'Kopirano u privremenu memoriju',
        ua: 'Скопійовано в буфер обміну',
        no: 'Kopiert til utklippstavla',
        it: 'Copiato negli appunti',
        se: 'Kopierat till urklipp',
    },
    renewToken: {
        us: 'Generate new token',
        cz: 'Vygenerovat nový token',
        pl: 'Wygeneruj nowy token',
        hr: 'Generiraj novi token',
        si: 'Ustvari nov token',
        de: 'Neues Token generieren',
        sk: 'Vygenerovať nový token',
        es: 'Generar nuevo token',
        ru: 'Сгенерировать новый токен',
        rs: 'Generiši novi token',
        ua: 'Згенерувати новий токен',
        no: 'Generere et nytt token',
        it: 'Generare un nuovo token',
        se: 'Gererera en ny token',
    },
    searchFulltext: {
        us: 'Search',
        cz: 'Hledat',
        pl: 'Wyszukaj',
        hr: 'Traži',
        si: 'Iskanje',
        de: 'Suche',
        sk: 'Hľadať',
        es: 'Buscar',
        ru: 'Поиск',
        rs: 'Traži',
        ua: 'Пошук',
        no: 'Søke',
        it: 'Ricerca',
        se: 'Sök',
    },
    unallowedName: {
        us: 'Disallowed name',
        cz: 'Nepovolený název',
        pl: 'Niedozwolona nazwa',
        hr: 'Nedopušteno ime',
        si: 'Nedovoljeno ime',
        de: 'Unzulässiger Name',
        sk: 'Nepovolený názov',
        es: 'Nombre no permitido',
        ru: 'Запрещенное имя',
        rs: 'Nedozvoljeno ime',
        ua: "Заборонене ім'я",
        no: 'Ikke tillatt navn',
        it: 'Nome non autorizzato',
        se: 'Otillåtet namn',
    },
    invalidateToken: {
        us: 'The new token will invalidate the previous token. Generate a new one?',
        cz: 'Nový token zneplatní token předchozí. Vygenerovat nový?',
        pl: 'Nowy token unieważni poprzedni token. Wygenerować nowy?',
        hr: 'Novi token poništit će prethodni token.',
        si: 'Novi token bo razveljavil prejšnji token.',
        de: 'Das neue Token macht das vorherige Token ungültig. Ein neues Token generieren?',
        sk: 'Nový token zneplatňuje predchádzajúci token. Vygenerovať nový?',
        es: 'El nuevo token invalidará el token anterior. ¿Generar uno nuevo?',
        ru: 'Новый токен аннулирует предыдущий. Сгенерировать новый?',
        rs: 'Novi token će poništiti prethodni token. Generisati novi?',
        ua: 'Новий токен зробить попередній токен недійсним. Згенерувати новий?',
        no: 'Det nye tokenet vil gjøre det forrige ugyldig. Generere et nytt et?',
        it: 'Il nuovo token invalida quello precedente. Generare un nuovo token?',
        se: 'Med en ny token blir den tidigare ogiltig. Vill du generera en ty token?',
    },
    readOnlyProject: {
        us: 'The project is read only',
        cz: 'Projekt je pouze pro čtení',
        pl: 'Projekt jest przeznaczony tylko do odczytu',
        hr: 'Projekt je samo za čitanje',
        si: 'Projekt je samo za branje',
        de: 'Das Projekt ist schreibgeschützt',
        sk: 'Projekt je iba na čítanie',
        es: 'El proyecto es de solo lectura',
        ru: 'Проект доступен только для чтения',
        rs: 'Projekt je samo za čitanje',
        ua: 'Проєкт доступний лише для читання',
        no: 'Prosjektet er skrivebeskyttet',
        it: 'Il progetto è di sola lettura',
        se: 'Projektet är skrivskyddat',
    },
    seeDetails: {
        us: 'See details',
        cz: 'Detaily',
        pl: 'Zobacz szczegóły',
        hr: 'Prikaži detalje',
        si: 'Podrobnosti',
        de: 'Siehe Details',
        sk: 'Detaily',
        es: 'Ver detalles',
        ru: 'Детали',
        rs: 'Prikaži detalje',
        ua: 'Детальніше',
        no: 'Se detaljer',
        it: 'Dettagli',
        se: 'Detaljer',
    },
    buyCreditFor: {
        us: 'Pay-as-you-go credit for',
        cz: 'Pay-as-you-go kredit za',
        pl: 'Kredyt pay-as-you-go za',
        hr: 'Pay-as-you-go kredit za',
        si: 'Pay-as-you-go kredit za',
        de: 'Pay-as-you-go Kredit für',
        sk: 'Priebežný kredit za',
        es: 'Crédito de pago según uso para',
        ru: 'Pay-as-you-go кредит за',
        rs: 'Pay-as-you-go kredit za',
        ua: 'Pay-as-you-go кредити',
        no: 'Kreditt som betales etter behov for',
        it: 'Credito a consumo per',
        se: 'Kredit med användningsbaserad betalning för',
    },
    transcribeFor: {
        us: 'Transcribe for',
        cz: 'Přepisujte za',
        pl: 'Transkrybuj za',
        hr: 'Transkribiraj za',
        si: 'Prepiši za',
        de: 'Transkribieren für',
        sk: 'Prepisujte za',
        es: 'Transcribir para',
        ru: 'Транскрибируйте за',
        rs: 'Transkribuj za',
        ua: 'Транскрибувати',
        no: 'Transkribere for ',
        it: 'Trascrivere per',
        se: 'Transkribera för',
    },
    billedYearly: {
        us: 'billed yearly',
        cz: 'placeno ročně',
        pl: 'płatne rocznie',
        hr: 'godišnja naplata',
        si: 'zaračunano letno',
        de: 'Jährlich abgerechnet',
        sk: 'platené ročne',
        es: 'facturado anualmente',
        ru: 'выставляется ежегодно',
        rs: 'godišnja naplata',
        ua: 'оплата за рік',
        no: 'faktureres årlig',
        it: 'pagato annualmente',
        se: 'faktureras årligen',
    },
    twoFreeMonths: {
        us: '2 months free with annual payment!',
        cz: 'Při roční platbě 2 měsíce zdarma!',
        pl: '2 miesiące za darmo przy płatności rocznej!',
        hr: 'Besplatna 2 mjeseca uz godišnje plaćanje!',
        si: 'Brezplačna 2 meseca z letnim plačilom!',
        de: '2 Monate gratis bei jährlicher Zahlung!',
        sk: 'Pri ročnej platbe 2 mesiace zadarmo!',
        es: '¡2 meses gratis con pago anual!',
        ru: '2 месяца бесплатно при покупке годовой Подписки!',
        rs: 'Besplatna 2 meseca uz godišnje plaćanje!',
        ua: '2 місяці безкоштовно з річною оплатою!',
        no: '2 måneder gratis med årlig betaling!',
        it: '2 mesi gratuiti con pagamento annuale!',
        se: 'Med årsvis betalning får du två månader gratis!',
    },
    paymentOf: {
        us: 'Payment of',
        cz: 'Platba',
        pl: 'Płatność',
        hr: 'Plaćanje',
        si: 'Plačilo',
        de: 'Zahlung von',
        sk: 'Platba',
        es: 'Pago de',
        ru: 'Оплата',
        rs: 'Plaćanje',
        ua: 'Оплата',
        no: 'Betaling av',
        it: 'Pagamento',
        se: 'Betalning av',
    },
    failedOn: {
        us: 'failed on',
        cz: 'selhala',
        pl: 'nie powiodła się',
        hr: 'nije uspjelo',
        si: 'nije uspjelo',
        de: 'gescheitert am',
        sk: 'zlyhala',
        es: 'fallado en',
        ru: 'не удалась',
        rs: 'nije uspelo na',
        ua: 'помилка',
        no: 'mislyktes',
        it: 'non riuscito',
        se: 'misslyckades',
    },
    enterMinutes: {
        us: 'Enter number of minutes to purchase',
        cz: 'Zadejte počet minut, které chcete koupit',
        pl: 'Wprowadź liczbę minut, które chcesz kupić',
        hr: 'Unesite broj minuta za kupnju',
        si: 'Vnesite število minut za nakup',
        de: 'Anzahl der zu kaufenden Minuten eingeben',
        sk: 'Zadajte počet minút, ktoré chcete kúpiť',
        es: 'Ingrese la cantidad de minutos a comprar',
        ru: 'Введите количество минут для покупки',
        rs: 'Unesite broj minuta za kupovinu',
        ua: 'Вкажіть кількість хвилин для покупки',
        no: 'Angi antall minutter du vil kjøpe',
        it: 'Inserire il numero di minuti che si desidera acquistare',
        se: 'Ange antalet minuter du vill köpa',
    },
    priceInclVat: {
        us: 'Price (incl. VAT)',
        cz: 'Cena (s DPH)',
        pl: 'Cena (z VAT)',
        hr: 'Cijena (s PDV-om)',
        si: 'Cena (z DDV-jem)',
        de: 'Preis (inkl. MwSt.)',
        sk: 'Cena (vrátane DPH)',
        es: 'Precio (IVA incluido)',
        ru: 'Цена (с НДС)',
        rs: 'Cena (uklj. PDV)',
        ua: 'Ціна (з ПДВ)',
        no: 'Pris (inkl. moms)',
        it: 'Prezzo (con IVA)',
        se: 'Pris (inkl. moms)',
    },
    fromUsers: {
        us: 'users',
        cz: 'uživatelů',
        pl: 'użytkowników',
        hr: 'korisnici',
        si: 'uporabniki',
        de: 'Benutzer',
        sk: 'používateľov',
        es: 'usuarios',
        ru: 'пользователей',
        rs: 'korisnici',
        ua: 'Користувачі',
        no: 'brukere',
        it: 'utenti',
        se: 'användare',
    },
    columns: {
        us: 'Columns',
        cz: 'Sloupce',
        pl: 'Kolumny',
        hr: 'Stupci',
        si: 'Stolpci',
        de: 'Spalten',
        sk: 'Stĺpce',
        es: 'Columnas',
        ru: 'Столбцы',
        rs: 'Stupci',
        ua: 'Колонки',
        no: 'Spalter',
        it: 'Colone',
        se: 'Kolumner',
    },
    left: {
        us: 'left',
        cz: '',
        pl: '',
        hr: '',
        si: '',
        de: '',
        sk: '',
        es: '',
        ru: '',
        rs: '',
        ua: '',
        no: '',
        it: '',
        se: '',
    },
    trashedProjects: {
        us: 'Trashed projects',
        cz: 'Projekty v koši',
        pl: 'Projekty w koszu',
        hr: 'Izbrisani projekti',
        si: 'Projekti v košu za smeti',
        de: 'Entfernte Projekte',
        sk: 'Projekty v koši',
        es: 'Proyectos en la papelera',
        ru: 'Удаленные проекты',
        rs: 'Odbačeni projekti',
        ua: 'Видалені проекти',
        no: 'Prosjekter i papirkurven',
        it: 'Progetti nel cestino',
        se: 'Projekt i papperskorgen',
    },
    trashedProjectDays: {
        us: 'Project is trashed and will be permanently deleted in',
        cz: 'Projekt je v koši. Počet dnů, za něž bude projekt trvale smazán:',
        pl: 'Projekt znajduje się w koszu. Liczba dni, po upływie których projekt zostanie trwale usunięty:',
        hr: 'Projekt je premješten u smeće i bit će trajno izbrisan',
        si: 'Projekt je v košu za smeti in bo trajno izbrisan za',
        de: 'Das Projekt ist entfernt und wird endgültig gelöscht in',
        sk: 'Projekt je v koši. Počet dní, za ktoré bude projekt trvalo zmazaný:',
        es: 'El proyecto está en la papelera y será eliminado permanentemente en',
        ru: 'Проект находится в корзине. Количество дней, в течение которых проект будет удален навсегда:',
        rs: 'Projekat je u otpadu i biće trajno izbrisan za',
        ua: 'Проект знаходиться в кошику. Кількість днів, за які проект буде остаточно видалено:',
        no: 'Prosjektet ligger i papirkurven og vil bli slettet permanent i',
        it: 'Il progetto è stato cestinato e sarà eliminato definitivamente entro',
        se: 'Projektet finns i papperskorgen. Antal dagar innan det raderas permanent:',
    },
    trashedProjectHours: {
        us: 'Project is trashed and will be permanently deleted in',
        cz: 'Projekt je v koši. Počet hodin, za něž bude projekt trvale smazán:',
        pl: 'Projekt znajduje się w koszu. Liczba godzin, po upływie których projekt zostanie trwale usunięty:',
        hr: 'Projekt je premješten u smeće i bit će trajno izbrisan',
        si: 'Projekt je v košu za smeti in bo trajno izbrisan za',
        de: 'Das Projekt ist entfernt und wird endgültig gelöscht in',
        sk: 'Projekt je v koši. Počet hodín, za ktoré bude projekt trvalo zmazaný:',
        es: 'El proyecto está en la papelera y será eliminado permanentemente en',
        ru: 'Проект находится в корзине. Количество часов, в течение которых проект будет удален навсегда:',
        rs: 'Projekat je u otpadu i biće trajno izbrisan za X sati',
        ua: 'Проект знаходиться в кошику. Кількість годин, після яких проект буде безповоротно видалено:',
        no: 'Prosjektet ligger i papirkurven og vil bli slettet permanent i',
        it: 'Il progetto è stato cestinato e sarà eliminato definitivamente entro',
        se: 'Projektet finns i papperskorgen. Antal timmar innan det raderas permanent:',
    },
    deleteProjects: {
        us: 'Delete projects?',
        cz: 'Smazat projekty?',
        pl: 'Usunąć projekty?',
        hr: 'Izbrisati projekte?',
        si: 'Izbriši projekte?',
        de: 'Projekte löschen?',
        sk: 'Zmazať projekty?',
        es: '¿Eliminar proyectos?',
        ru: 'Удалить проекты?',
        rs: 'Obrisati projekte?',
        ua: 'Видалити проєкти?',
        no: 'Slette prosjektene?',
        it: 'Cancellare i progetti?',
        se: 'Radera projekten?',
    },
    deleteProject: {
        us: 'Delete project?',
        cz: 'Smazat projekt?',
        pl: 'Usunąć projekt?',
        hr: 'Izbrisati projekt?',
        si: 'Izbriši projekt?',
        de: 'Projekt löschen?',
        sk: 'Zmazať projekt?',
        es: '¿Eliminar proyecto?',
        ru: 'Удалить проект?',
        rs: 'Obrisati projekt?',
        ua: 'Видалити проект?',
        no: 'Slette prosjektet?',
        it: 'Cancellare il progetto?',
        se: 'Radera projektet?',
    },
    days: {
        us: 'days.',
        cz: 'dní.',
        pl: 'dni.',
        hr: 'dani.',
        si: 'dni',
        de: 'Tage.',
        sk: 'dní.',
        es: 'días.',
        ru: 'дни.',
        rs: 'dana.',
        ua: 'днi.',
        no: 'dager.',
        it: 'giorni.',
        se: 'dagar.',
    },
    hours: {
        us: 'hours.',
        cz: 'hodiny.',
        pl: 'godzin.',
        hr: 'sati.',
        si: 'ur.',
        de: 'Stunden.',
        es: 'horas.',
        ru: 'часы',
        rs: 'sati.',
        ua: 'години.',
        no: 'timer.',
        it: 'ore.',
    },
    size: {
        us: 'Size',
        cz: 'Velikost',
        pl: 'Rozmiar',
        hr: 'veličina',
        si: 'Velikost',
        de: 'Größe',
        sk: 'Veľkosť',
        es: 'Tamaño',
        ru: 'Размер',
        rs: 'Veličina',
        ua: 'Розмір',
        no: 'Størrelse',
        it: 'Dimensione',
        se: 'Storlek',
    },
    waitTillQueueEmpty: {
        us: 'To upload directly into this project, please wait until all your current uploads are complete.',
        cz: 'Pro nahrání přímo do projektu prosím počkejte na dokončení aktuálních nahrávání souborů.',
        pl: 'Aby przesłać plik bezpośrednio do tego projektu, poczekaj, aż wszystkie bieżące pliki zostaną przesłane.',
        hr: 'Za izravan prijenos u ovaj projekt, molimo pričekajte da se dovrše svi trenutni prijenosi.',
        si: 'Če želite naložiti neposredno v ta projekt, počakajte, da se dokončajo vsi trenutni prenosi.',
        de: 'Um direkt in dieses Projekt hochzuladen, warten Sie bitte, bis alle Ihre aktuellen Uploads abgeschlossen sind.',
        sk: 'Pre nahranie priamo do projektu prosím počkajte na dokončenie aktuálnych nahrávania súborov.',
        es: 'Para subir directamente en este proyecto, espere hasta que finalicen todas sus cargas actuales.',
        ru: 'Чтобы загрузить данные непосредственно в этот проект, пожалуйста, дождитесь завершения всех текущих загрузок.',
        rs: 'Da biste učitali direktno u ovaj projekat, sačekajte dok se ne završe sva vaša trenutna učitavanja.',
        ua: 'Щоб завантажити безпосередньо в цей проект, будь ласка, зачекайте, поки всі ваші поточні завантаження будуть завершені.',
        no: 'For å laste opp direkte til dette prosjektet, vennligst vent til alle dine nåværende opplastinger er fullført.',
        it: 'Per caricare direttamente il progetto, attendere il completamento del caricamento dei file in corso.',
        se: 'För att ladda upp direkt till projektet vänligen vänta tills alla nuvarande uppladdningar har slutförts.',
    },
    uploadAgain: {
        us: 'Upload again',
        cz: 'Nahrát znovu',
        pl: 'Prześlij ponownie',
        hr: 'Ponovno učitajte',
        si: 'Naloži znova',
        de: 'Erneut hochladen',
        sk: 'Nahrať znova',
        es: 'Subir de nuevo',
        ru: 'Загрузить снова',
        rs: 'Ponovo učitaj',
        ua: 'Завантажити ще раз',
        no: 'Laste opp igjen',
        it: 'Caricare di nuovo',
        se: 'Ladda upp igen',
    },
    continueWithEmail: {
        us: 'Continue with e-mail',
        cz: 'Pokračovat s e-mailovou adresou',
        pl: 'Kontynuuj wysyłanie wiadomości e-mail',
        hr: 'Nastavite s e-mail adresom',
        si: 'Nadaljuj z e-pošto',
        de: 'Weiter mit E-Mail',
        sk: 'Pokračovať s e-mailovou adresou',
        es: 'Continuar con correo electrónico',
        ru: 'Продолжить отправку по электронной почте',
        rs: 'Nastavite sa e-poštom',
        ua: 'Продовжити з електронною поштою',
        no: 'Fortsette med e-post',
        it: "Continua con l'indirizzo e-mail",
        se: 'Fortsätt med e-postadressen',
    },
    sectionTagsDescription: {
        us: 'In the editor, you can mark the section beginning with heading H2 with any tag.',
        cz: 'V editoru si můžete označit sekci započatou nadpisem H2 libovolným štítkem.',
        pl: 'W edytorze można oznaczyć sekcję rozpoczynającą się od nagłówka H2 dowolnym tagiem.',
        hr: 'U editoru možete označiti odjeljak koji počinje naslovom H2 bilo kojom oznakom.',
        si: 'V urejevalniku lahko razdelek, ki se začne z naslovom H2 označite s katero koli oznako.',
        de: 'Im Editor können Sie den Abschnitt, der mit der Überschrift H2 beginnt, mit einem beliebigen Tag markieren.',
        sk: 'V editore si môžete označiť sekciu začatú nadpisom H2 ľubovoľným štítkom.',
        es: 'En el editor, puedes marcar la sección que comienza con el encabezado H2 con cualquier etiqueta.',
        ru: 'В редакторе вы можете пометить раздел, начинающийся с заголовка H2, любым тегом.',
        rs: 'U editoru možete označiti odeljak koji počinje naslovom H2 bilo kojom oznakom.',
        ua: 'У редакторі ви можете позначити розділ, що починається із заголовка H2, будь-яким тегом.',
        no: 'Du kan merke seksjonen som begynner med overskriften H2 med hvilken som helst etikett i editoren.',
        it: "Nell'editor, è possibile contrassegnare la sezione iniziata dall'intestazione H2 con qualsiasi etichetta.",
        se: 'I editorn kan du markera sektionen som börjar med rubrik 2 med valfri tagg.',
    },
    tagsInstructions: {
        us: 'Organize your own or team work with project and section tags. Within a team, tags are shared with other members.',
        cz: 'Zpřehledněte svou vlastní nebo týmovou práci pomocí projektových štítků a štítků sekcí. V rámci týmu jsou štítky sdíleny s ostatními členy.',
        pl: 'Organizuj pracę własną lub swojego teamu za pomocą tagów projektów i sekcji. W ramach teamu tagi są udostępniane innym członkom.',
        hr: 'Organizirajte vlastiti ili timski rad s oznakama projekta i odjeljka. Oznake se dijele s drugim članovima unutar tima.',
        si: 'Organizirajte svoje ali timsko delo z oznakami projektov in razdelkov. Oznake so v skupni rabi z drugimi člani v skupini.',
        de: 'Organisieren Sie Ihre eigene oder Teamarbeit mit Projekt- und Abschnittstags. Innerhalb eines Teams werden Tags mit anderen Mitgliedern geteilt.',
        sk: 'Prehľadnite svoju vlastnú alebo tímovú prácu pomocou projektových štítkov a štítkov sekcií. V rámci tímu sú štítky zdieľané s ostatnými členmi.',
        es: 'Organiza tu propio trabajo o el de tu equipo con etiquetas de proyecto y secciones. Dentro de un equipo, las etiquetas se comparten con otros miembros.',
        ru: 'Организуйте собственную или командную работу с помощью тегов проектов и разделов. Внутри команды теги могут использоваться совместно с другими членами.',
        rs: 'Organizujte vlastiti ili timski rad s oznakama projekta i odeljka. Oznake se dele s drugim članovima unutar tima.',
        ua: 'Організуйте власну або командну роботу з тегами проектів і розділів. У команді тегами можна ділитися з іншими учасниками.',
        no: 'Organiser ditt eget eller teamets arbeid med prosjekt- og seksjonsetiketter. I et team deles etikettene med andre medlemmer.',
        it: "Organizzate il vostro lavoro o quello del vostro team con etichette di progetto e di sezione. All'interno di un team, le etichette sono condivise con gli altri membri.",
        se: 'Få ordning på ditt eget eller teamets arbete med hjälp av taggar på nivån av projekt och sektioner. Taggarna delas med andra medlemmar i teamet.',
    },
    projectTagsDescription: {
        us: 'Mark your projects with any tag.',
        cz: 'Označte si projekty libovolným štítkem.',
        pl: 'Oznacz swoje projekty dowolnym tagiem.',
        hr: 'Označite svoje projekte bilo kojom oznakom.',
        si: 'Označite svoje projekte s katero koli oznako.',
        de: 'Markieren Sie Ihre Projekte mit beliebigen Tags.',
        sk: 'Označte si projekty ľubovoľným štítkom.',
        es: 'Marca tus proyectos con cualquier etiqueta.',
        ru: 'Пометьте свои проекты любым тегом.',
        rs: 'Označite svoje projekte bilo kojom oznakom.',
        ua: 'Позначайте свої проекти будь-яким тегом.',
        no: 'Marker dine prosjekter med hvilken som helst etikett.',
        it: 'Etichettate i vostri progetti con qualsiasi etichetta.',
        se: 'Markera dina projekt med valfri tagg.',
    },
    abbreviation: {
        us: 'Abbreviation',
        cz: 'Zkratka',
        pl: 'Skrót',
        hr: 'Kratica',
        si: 'Okrajšava',
        de: 'Abkürzung',
        sk: 'Skratka',
        es: 'Abreviatura',
        ru: 'Аббревиатура',
        rs: 'Skraćenica',
        ua: 'Абревіатура',
        no: 'Forkortelse',
        it: 'Abbreviazione',
        se: 'Förkortning',
    },
    featureNotEnabled: {
        us: 'You do not have this feature enabled. Check',
        cz: 'Tuto funkci nemáte zapnutou. Zkontrolujte',
        pl: 'Ta funkcja nie jest włączona. Sprawdź',
        hr: 'Ova značajka nije omogućena. Provjerite',
        si: 'Nimate omogočene te funkcije. Preverite',
        de: 'Diese Funktion ist nicht aktiviert. Überprüfen Sie',
        sk: 'Túto funkciu nemáte zapnutú. Skontrolujte',
        es: 'No tienes esta función habilitada. Revisa',
        ru: 'У вас не включена эта функция. Проверьте',
        rs: 'Nemate omogućenu ovu funkciju. Proverite',
        ua: 'У вас не ввімкнена ця функція. Перевірте',
        no: 'Du har ikke aktivert denne funksjonen. Sjekk',
        it: 'Questa funzione non è abilitata. Controllare',
        se: 'Denna fuktion är inte aktiverad. Kontrollera',
    },
    yourAccountSettings: {
        us: 'your account settings.',
        cz: 'nastavení svého účtu.',
        pl: 'ustawienia swojego konta.',
        hr: 'postavke računa.',
        si: 'nastavitve računa.',
        de: 'Ihre Kontoeinstellungen.',
        sk: 'nastavenia svojho účtu.',
        es: 'la configuración de tu cuenta.',
        ru: 'настройки вашей учетной записи.',
        rs: 'postavke Vašeg naloga',
        ua: 'налаштування вашого акаунта.',
        no: 'kontoinnstillingene dine.',
        it: "e impostazioni dell'account.",
        se: 'dina kontoinställningar.',
    },
    recordingQuality: {
        us: 'Recording quality',
        cz: 'Kvalita nahrávky',
        pl: 'Jakość nagrania',
        hr: 'Kvaliteta snimke',
        si: 'Kakovost posnetka',
        de: 'Qualität der Aufnahme',
        sk: 'Kvalita nahrávky',
        es: 'Calidad de grabación',
        ru: 'Качество записи',
        rs: 'Kvalitet snimanja',
        ua: 'Якість запису',
        no: 'Innspillingskvalitet',
        it: 'Qualità della registrazione',
        se: 'Inspelningskvalitet',
    },
    automaticFill: {
        us: 'Reverse charge is applied automatically based on your country and TIN number.',
        cz: 'Reverse charge je aplikováno automaticky podle vaší země a DIČ.',
        pl: 'Odwrotne obciążenie jest naliczane automatycznie w zależności od kraju i numeru VAT.',
        hr: 'Prijenos porezne obveze primjenjuje se automatski na temelju vaše zemlje i OIB-a.',
        si: 'Obrnjena bremenitev se samodejno zaračuna glede na vašo državo in davčno številko.',
        de: 'Die Umkehrung der Steuerschuldnerschaft wird automatisch auf der Grundlage Ihres Landes und Ihrer Steuernummer vorgenommen.',
        sk: 'Reverse charge je aplikované automaticky podľa vašej krajiny a DIČ.',
        es: 'El cargo inverso se aplica automáticamente según su país y número NIF.',
        ru: 'Обратная плата взимается автоматически в зависимости от страны и номера ИНН.',
        rs: 'Povratna naplata se primenjuje automatski na osnovu vaše zemlje i PIB broja.',
        ua: 'Зворотний збір застосовується автоматично на основі вашої країни та номера ІПН.',
        no: 'Snudd avregning (Reverse charge) påløper automatisk basert på land og organisasjonsnummer.',
        it: 'Il reverse charge viene applicato automaticamente in base al Paese e al numero di partita IVA.',
        se: 'Omvänd skattskyldighet tillämpas automatiskt utifrån ditt land och skatteregistreringsnummer.',
    },
    fillCompany: {
        us: 'Fill in company name when using TIN.',
        cz: 'Vyplňte jméno firmy při vyplnění DIČ.',
        pl: 'Wpisz nazwę firmy, jeśli używasz numeru NIP.',
        hr: 'Ispuni naziv države prilikom upisivanja OIB-a.',
        si: 'Izpolnite ime podejtja pri uporabi davčne številke.',
        de: 'Geben Sie bei Verwendung der Steuernummer den Firmennamen ein.',
        sk: 'Vyplňte meno firmy pri vyplnení DIČ.',
        es: 'Complete el nombre de la empresa cuando utilice el NIF.',
        ru: 'Введите название компании при использовании ИНН.',
        rs: 'Unesite naziv kompanije kada koristite TIN.',
        ua: 'Заповніть назву компанії, якщо ви використовуєте ІПН.',
        no: 'Fyll inn firmanavn ved bruk av organisasjonsnummer.',
        it: 'Inserire il nome della società quando si inserisce il numero di partita IVA.',
        se: 'Ange företagets namn när du anger skatteregistreringsnummer.',
    },
    rating: {
        us: 'Rating',
        cz: 'Ohodnocení',
        pl: 'Oceń Beey',
        hr: 'Ocjena',
        si: 'Ocena',
        de: 'Bewertung',
        sk: 'Hodnotenie',
        es: 'Calificación',
        ru: 'Рейтинг',
        rs: 'Ocena',
        ua: 'Рейтинг',
        no: 'Vurdering',
        it: 'Valutazione',
        se: 'Värdering',
    },
    howSatisfied: {
        us: 'How did we do?',
        cz: 'Jste spokojeni s přepisem?',
        pl: 'Czy jesteś zadowolony z transkrypcji?',
        hr: 'Jeste li zadovoljni transkripcijom?',
        si: 'Ali ste zadovoljni s prepisom?',
        de: 'Wie haben wir abgeschnitten?',
        sk: 'Ste spokojní s prepisom?',
        es: '¿Está satisfecho con la transcripción?',
        ru: 'Удовлетворены ли вы стенограммой?',
        rs: 'Jeste li zadovoljni transkripcijom?',
        ua: 'Ви задоволені стенограмою?',
        no: 'Hvordan klarte vi oss?',
        it: 'Siete soddisfatti della trascrizione?',
        se: 'Är du nöjd med transkriptionen?',
    },
    whereToImprove: {
        us: 'Where can Beey improve?',
        cz: 'Kde se může Beey zlepšit?',
        pl: 'Gdzie Beey może się poprawić?',
        hr: 'U čemu se Beey može poboljšati?',
        si: 'Kje se lahko Beey izboljša?',
        de: 'Wo kann Beey sich verbessern?',
        sk: 'V čom sa môže Beey zlepšiť?',
        es: '¿En qué puede mejorar Beey?',
        ru: 'Где Beey может улучшить свои результаты?',
        rs: 'U čemu Beey može da se poboljša?',
        ua: 'Що можна покращити в Beey?',
        no: 'Hva kan Beey forbedre?',
        it: 'Dove può migliorare Beey?',
        se: 'I vilka områden kan Beey förbättras?',
    },
    recognition: {
        us: 'Word recognition',
        cz: 'Rozpoznání slov',
        pl: 'Rozpoznawanie słów',
        hr: 'Prepoznavanje riječi',
        si: 'Prepoznavanje besed',
        de: 'Worterkennung',
        sk: 'Rozpoznávanie slov',
        es: 'Reconocimiento de palabras',
        ru: 'Распознавание слов',
        rs: 'Prepoznavanje reči',
        ua: 'Розпізнавання слів',
        no: 'Ordgjenkjenning',
        it: 'Riconoscimento delle parole',
        se: 'Ordigenkänning',
    },
    diarization: {
        us: 'Speaker distinction',
        cz: 'Dělení mluvčích',
        pl: 'Rozdzielenie na mówców',
        hr: 'Razlikovanje govornika',
        si: 'Razlikovanje govorcev',
        de: 'Sprecheraufteilung',
        sk: 'Delenie rečníkov',
        es: 'Distinción de oradores',
        ru: 'Различение речи',
        rs: 'Razlikovanje govornika',
        ua: 'Розрізнення мовців',
        no: 'Talergjenkjenning',
        it: 'Segmentazione dei parlanti',
        se: 'Talarigenkänning',
    },
    services: {
        us: 'Services offered',
        cz: 'Nabídka služeb',
        pl: 'Oferowane usługi',
        hr: 'Ponuđene usluge',
        si: 'Ponujene storitve',
        de: 'Angebotene Dienstleistungen',
        sk: 'Ponuka služieb',
        es: 'Servicios ofrecidos',
        ru: 'Предлагаемые услуги',
        rs: 'Ponuđene usluge',
        ua: 'Пропоновані послуги',
        no: 'Tilbudte tjenester',
        it: 'Offerte di servizio',
        se: 'Utbud av tjänster',
    },
    yourIdeas: {
        us: 'Do you have any other ideas or suggestions?',
        cz: 'Máte další připomínky nebo nápady?',
        pl: 'Masz jakieś inne pomysły lub sugestie?',
        hr: 'Imate li još ideja ili prijedloga?',
        si: 'Imate še kakšno idejo ali predlog?',
        de: 'Haben Sie weitere Ideen oder Vorschläge?',
        sk: 'Máte nejaké ďalšie pripomienky alebo nápady?',
        es: '¿Tienes alguna otra idea o sugerencia?',
        ru: 'Есть ли у вас другие идеи или предложения?',
        rs: 'Imate li još neke ideje ili predloge?',
        ua: 'У вас є інші ідеї чи пропозиції?',
        no: 'Har du noen andre ideer eller forslag?',
        it: 'Avete altri commenti o idee?',
        se: 'Har du flera anmärkningar eller förslag?',
    },
    userData: {
        us: 'Media files size only',
        cz: 'Pouze velikost souborů',
        pl: 'Wyłącznie rozmiar pliku',
        hr: 'Samo veličina medijske datoteke',
        si: 'Samo velikost datoteke',
        de: 'Nur Größe der Mediendateien',
        sk: 'Iba veľkosť súborov',
        es: 'Sólo tamaño de los archivos multimedia',
        ru: 'Только размер медиафайлов',
        rs: 'Samo veličina medijskih fajlova',
        ua: 'Тільки розмір медіафайлів',
        no: 'Kun størrelse på mediefiler',
        it: 'Solo la dimensione dei file',
        se: 'Endast storlek av filer',
    },
    allData: {
        us: 'Storage usage',
        cz: 'Velikost zabraného úložiště',
        pl: 'Zużycie przestrzeni dyskowej',
        hr: 'Korištenje pohrane',
        si: 'Uporaba prostora za shranjevanje',
        de: 'Speichernutzung',
        sk: 'Veľkosť zabraného úložiska',
        es: 'Uso de almacenamiento',
        ru: 'Использование хранилища',
        rs: 'Iskorišćenost prostora za skladištenje',
        ua: 'Використання сховища',
        no: 'Bruk av lagringsplass',
        it: 'Dimensione di archiviazione',
        se: 'Använt lagringsutrymme',
    },
    userStatistics: {
        us: 'Media files size',
        cz: 'Velikost souborů',
        pl: 'Rozmiar pliku',
        hr: 'Veličina medijske datoteke',
        si: 'Velikost datoteke',
        de: 'Größe der Mediendateien',
        sk: 'Veľkosť súborov',
        es: 'Tamaño de archivos multimedia',
        ru: 'Размер медиафайлов',
        rs: 'Veličina medijskih fajlova',
        ua: 'Розмір медіафайлів',
        no: 'Størrelsen på mediefiler',
        it: 'Dimensione di file',
        se: 'Storlek av filer',
    },
    editSection: {
        us: 'Edit section',
        cz: 'Upravit sekci',
        pl: 'Edytuj sekcję',
        hr: 'Uredite odjeljak',
        si: 'Uredi razdelek',
        de: 'Abschnitt bearbeiten',
        sk: 'Upraviť sekciu',
        es: 'Editar sección',
        ru: 'Редактировать раздел',
        rs: 'Urediti odeljak',
        ua: 'Редагувати розділ',
        no: 'Redigere seksjonen',
        it: 'Modifica la sezione',
        se: 'Editera sektionen',
    },
    removeSection: {
        us: 'Remove section',
        cz: 'Smazat sekci',
        pl: 'Usuń sekcję',
        hr: 'Uklonite odjeljak',
        si: 'Izbriši razdelek',
        de: 'Abschnitt entfernen',
        sk: 'Odstrániť sekciu',
        es: 'Eliminar sección',
        ru: 'Удалить раздел',
        rs: 'Ukloniti odeljak',
        ua: 'Видалити розділ',
        no: 'Slette seksjonen',
        it: 'Elliminare la sezione',
        se: 'Radera sektionen',
    },
    tagColor: {
        us: 'Color',
        cz: 'Barva',
        pl: 'Kolor',
        hr: 'Boja',
        si: 'Barva',
        de: 'Farbe',
        sk: 'Farba',
        es: 'Color',
        ru: 'Цвет',
        rs: 'Boja',
        ua: 'Колір',
        no: 'Farge',
        it: 'Colore',
        se: 'Färg',
    },
    noTags: {
        us: 'You have no tags',
        cz: 'Nemáte žádné štítky',
        pl: 'Nie masz żadnych tagów',
        hr: 'Nemate oznaka',
        si: 'Nimate oznak',
        de: 'Keine Tags vorhanden',
        sk: 'Nemáte žiadne štítky',
        es: 'No tienes etiquetas',
        ru: 'У вас нет тегов',
        rs: 'Nemate oznake',
        ua: 'У вас немає тегів',
        no: 'Du har ingen etiketter',
        it: 'Non ci sono i tag',
        se: 'Du har inga taggar',
    },
    scoreTerrible: {
        us: 'Terrible',
        cz: 'Otřesné',
        pl: 'Okropna',
        hr: 'Užasno',
        si: 'Grozno',
        de: 'Schlecht',
        sk: 'Hrozné',
        es: 'Terrible',
        ru: 'Ужасное',
        rs: 'Užasno',
        ua: 'Шокуюче',
        no: 'Forferdelig',
        it: 'Pessimo',
        se: 'Fruktansvärd',
    },
    scoreBad: {
        us: 'Bad',
        cz: 'Špatné',
        pl: 'Zła',
        hr: 'Loše',
        si: 'Slabo',
        de: 'Unzureichend',
        sk: 'Zlé',
        es: 'Malo',
        ru: 'Плохое',
        rs: 'Loše',
        ua: 'Погано',
        no: 'Dårlig',
        it: 'Brutto',
        se: 'Dålig',
    },
    scoreAverage: {
        us: 'Average',
        cz: 'Průměrné',
        pl: 'Średnia',
        hr: 'Prosječno',
        si: 'Povprečno',
        de: 'Durchschnittlich',
        sk: 'Priemerné',
        es: 'Regular',
        ru: 'Среднее',
        rs: 'Prosečno',
        ua: 'Середній',
        no: 'Gjennomsnittlig',
        it: 'Medio',
        se: 'Medelmåttig',
    },
    scoreGood: {
        us: 'Good',
        cz: 'Dobré',
        pl: 'Dobra',
        hr: 'Dobro',
        si: 'Dobro',
        de: 'Gut',
        sk: 'Dobré',
        es: 'Bueno',
        ru: 'Хорошее',
        rs: 'Dobro',
        ua: 'Добре',
        no: 'God',
        it: 'Buono',
        se: 'Bra',
    },
    scorePerfect: {
        us: 'Perfect',
        cz: 'Perfektní',
        pl: 'Doskonała',
        hr: 'Savršeno',
        si: 'Odlično',
        de: 'Perfekt',
        sk: 'Perfektné',
        es: 'Perfecto',
        ru: 'Отличное',
        rs: 'Savršeno',
        ua: 'Ідеально',
        no: 'Perfekt',
        it: 'Perfetto',
        se: 'Perfekt',
    },
    weAreSorry: {
        us: 'We are sorry you were not satisfied, we will try better next time! In the meantime here are a few tips',
        cz: 'Mrzí nás, že jste nebyli spokojeni. Budeme se snažit být příště lepší! Prozatím posíláme naše tipy',
        pl: 'Przykro nam, że nie byłeś zadowolony, następnym razem postaramy się jeszcze bardziej! Tymczasem oto kilka wskazówek',
        hr: 'Žao nam je što niste bili zadovoljni, potrudit ćemo se više idući put! U međuvremenu evo nekoliko savjeta',
        si: 'Žal nam je, da niste bili zadovoljni, naslednjič se bomo več potrudili! Medtem pa tukaj najdete nekaj nasvetov',
        de: 'Es tut uns leid, dass Sie nicht zufrieden waren. Wir werden es beim nächsten Mal besser machen! In der Zwischenzeit hier einige Tipps',
        sk: 'Je nám ľúto, že ste neboli spokojní. Budeme sa snažiť byť nabudúce lepší! Zatiaľ posielame naše tipy',
        es: 'Lamentamos que no quedaras satisfecho, ¡intentaremos mejorar la próxima vez! Mientras tanto, aquí tienes algunos consejos',
        ru: 'Нам жаль, что вы остались недовольны, в следующий раз мы постараемся сделать все лучше! А пока вот несколько советов',
        rs: 'Žao nam je što niste bili zadovoljni, potrudićemo se više idući put! U međuvremenu, evo nekoliko saveta',
        ua: 'Нам шкода, що ви залишилися незадоволені, наступного разу ми постараємося краще! А поки що ось кілька порад',
        no: 'Vi beklager at du ikke var fornøyd, vi skal prøve bedre neste gang! Inntil videre har vi noen tips',
        it: 'Ci dispiace che non siate rimasti soddisfatti. Cercheremo di essere migliori la prossima volta! Per ora, vi inviamo i nostri consigli',
        se: 'Det är tråkigt att du inte var nöjd. Vi ska göra vårt bästa för att bli bättre nästa gång! För närvarande skickar vi ett par tips',
    },
    howToUse: {
        us: 'How to use Beey effectively',
        cz: 'Jak efektivně pracovat v Beey',
        pl: 'Jak efektywnie pracować w Beey',
        hr: 'Kako učinkovito upotrebljavati Beey',
        si: 'Kako učinkovito uporabljati Beey',
        de: 'Wie man Beey effektiv nutzt',
        sk: 'Ako efektívne pracovať v Beey',
        es: 'Cómo usar Beey de manera efectiva',
        ru: 'Как эффективно использовать Beey',
        rs: 'Kako efikasno koristiti Beey',
        ua: 'Як ефективно використовувати Beey',
        no: 'Hvordan bruke Beey effektivt',
        it: 'Come lavorare efficacemente da Beey',
        se: 'Hur kan man jobba effektivt i Beey',
    },
    thankYou: {
        us: 'That is great news!🎉 We are happy you are satisfied with Beey.',
        cz: 'To je skvělé!🎉 Jsme rádi, že se jste s Beey spokojeni.',
        pl: 'To świetnie! 🎉 Cieszymy się, że jesteś zadowolony z Beey.',
        hr: 'To su odlične vijesti! 🎉 Drago nam je da ste zadovoljni Beeyjem.',
        si: 'To je odlična novica! 🎉 Veseli nas, da ste zadovoljni z Beeyjem.',
        de: 'Das sind tolle Neuigkeiten!🎉 Wir freuen uns, dass Sie mit Beey zufrieden sind.',
        sk: 'To je skvelé! 🎉 Sme radi, že ste s Beey spokojní.',
        es: '¡Esa es una gran noticia! 🎉 Estamos contentos de que estés satisfecho con Beey.',
        ru: 'Это отличная новость! 🎉 Мы рады, что вы довольны Beey.',
        rs: 'To su odlične vesti! 🎉 Drago nam je da ste zadovoljni Beey-jem.',
        ua: 'Це чудова новина! 🎉 Ми раді, що ви задоволені Beey.',
        no: 'Det er flott! 🎉 Vi er glad for at du er fornøyd med Beey.',
        it: 'È fantastico! 🎉 Siamo felici che tu sia soddisfatto di Beey.',
        se: 'Vad härligt! 🎉 Det gläder oss att du är nöjd med Beey.',
    },
    notEnoughStorage: {
        us: 'You do not have enough storage.',
        cz: 'Nemáte dostatek volného úložitě.',
        pl: 'Nie masz wystarczającej ilości wolnego miejsca.',
        hr: 'Nemate dovoljno prostora za pohranu.',
        si: 'Nimate dovolj prostora za shranjevanje.',
        de: 'Sie haben nicht genug Speicherplatz.',
        sk: 'Nemáte dostatok voľného úložiska.',
        es: 'No tienes suficiente espacio de almacenamiento.',
        ru: 'У вас недостаточно места для хранения.',
        rs: 'Nemate dovoljno memorijskog prostora.',
        ua: 'У вас недостатньо місця для зберігання.',
        no: 'Du har ikke nok lagringsplass.',
        it: 'Lo spazio di archiviazione gratuito non è sufficiente.',
        se: 'Du har inte tillräckligt med lagringsutrymme.',
    },
    infoOnStorage: {
        us: 'Info on storage can be found in our plans section in',
        cz: 'Informace o dostupném úložišti najdete v našich předplatných v sekci',
        pl: 'Aby uzyskać informacje na temat dostępnej przestrzeni w chmurze, zapoznaj się z naszą sekcją subskrypcji',
        hr: 'Informacije o pohrani možete pronaći u našem odjeljku s planovima na',
        si: 'Informacije o skladiščenju najdete v našem razdelku za naročnine',
        de: '\nInformationen zum Speicherplatz finden Sie in unserem Pläne-Bereich unter',
        sk: 'Informácie o dostupnom úložisku nájdete v našich predplatných v sekcii',
        es: 'La información sobre el almacenamiento se puede encontrar en nuestra sección de planes',
        ru: 'Информацию о хранилище можно найти в разделе "Наши планы"',
        rs: 'Informacije o memorijskom prostoru možete pronaći u našem odeljku s planovima na',
        ua: 'Інформацію про обсяг сховища можна знайти в розділі наших тарифних планів',
        no: 'Informasjon om lagringsplass finner du i planseksjonen vår i',
        it: 'Per informazioni sullo spazio di archiviazione disponibile, consultare la sezione abbonamenti',
        se: 'Information om tillgängligt lagringsutrymme hittar du i våra prenumerationer under sektionen',
    },
    teamPlanUsage: {
        us: 'Team plan usage',
        cz: 'Využití týmového předplatného',
        pl: 'Wykorzystanie subskrypcji teamu',
        hr: 'Upotreba timskog plana',
        si: 'Uporaba skupinske naročnine',
        de: 'Nutzung des Teamplans',
        sk: 'Využitie tímového predplatného',
        es: 'Uso del plan de equipo',
        ru: 'Использование командного плана',
        rs: 'Korišćenje timskog plana',
        ua: 'Використання командного плану',
        no: 'Bruk av teamabonnement',
        it: 'Utilizzo di un abbonamento di squadra',
        se: 'Användning av teamets lagringsutrymme',
    },
    translateFromLanguage: {
        us: 'Translate from language',
        cz: 'Přeložit z jazyka',
        pl: 'Przetłumacz z języka',
        hr: 'Prevedi s jezika',
        si: 'Prevedi iz jezika',
        de: 'Sprache übersetzen',
        sk: 'Preložiť z jazyka',
        es: 'Traducir desde el idioma',
        ru: 'Перевести с языка',
        rs: 'Prevedi s jezika',
        ua: 'Перекласти з мови',
        no: 'Oversette fra språk',
        it: 'Tradurre dalla lingua',
        se: 'Översätt från',
    },
    addMember: {
        us: 'Add member',
        cz: 'Přidat člena',
        pl: 'Dodaj członka',
        hr: 'Dodajte člana',
        si: 'Dodaj člana',
        de: 'Mitglied hinzufügen',
        sk: 'Pridať člena',
        es: 'Agregar usuario',
        ru: 'Добавить участника',
        rs: 'Dodajte člana',
        ua: 'Додати учасника',
        no: 'Legge til medlem',
        it: 'Aggiungi un membro ',
        se: 'Lägg till medlem',
    },
    memberEmail: {
        us: "Team member's email",
        cz: 'Email člena týmu',
        pl: 'E-mail członka teamu',
        hr: 'E-mail člana tima',
        si: 'E-poštni naslov člana ekipe',
        de: 'E-Mail des Teammitglieds',
        sk: 'E-mail člena tímu',
        es: 'Correo electrónico del miembro del equipo',
        ru: 'Электронная почта члена команды',
        rs: 'Imejl adresa člana tima',
        ua: 'Email учасника команди',
        no: 'Teammedlemmets e-postadresse',
        it: 'Email del membro del team',
        se: 'Teammedlemmens e-postadress',
    },
    deleteMemberPermanently: {
        us: 'Permanently delete the team member?',
        cz: 'Permanentně vymazat člena týmu?',
        pl: 'Trwale usunąć członka teamu?',
        hr: 'Trajno izbrisati člana tima?',
        si: 'Želite trajno izbrisati člana ekipe?',
        de: 'Teammitglied dauerhaft löschen?',
        sk: 'Trvalo vymazať člena tímu?',
        es: '¿Eliminar permanentemente a un miembro del equipo?',
        ru: 'Удалить члена команды навсегда?',
        rs: 'Trajno obrisati člana tima?',
        ua: 'Назавжди видалити учасника команди?',
        no: 'Slette teammedlemmet permanent?',
        it: 'Cancellare definitivamente un membro del team?',
        se: 'Radera teammedlemmen permanent?',
    },
    failedToAddMember: {
        us: 'Failed to add member',
        cz: 'Vytvoření nového člena se nezdařilo',
        pl: 'Nie udało się utworzyć nowego członka',
        hr: 'Neuspjelo dodavanje člana',
        si: 'Dodajanje člana ni uspelo',
        de: 'Fehler beim Hinzufügen des Mitglieds',
        sk: 'Vytvorenie nového člena sa nepodarilo',
        es: 'No se pudo agregar al miembro',
        ru: 'Не удалось добавить участника',
        rs: 'Neuspešno dodavanje člana',
        ua: 'Не вдалося додати учасника',
        no: 'Teammedlemmets tillegg mislyktes',
        it: 'La creazione di un nuovo membro non è riuscita',
        se: 'Det gick inte att skapa en ny teammedlem',
    },
    failedToDeleteMember: {
        us: 'Failed to delete member',
        cz: 'Nepodařilo se smazat člena týmu',
        pl: 'Nie udało się usunąć członka teamu',
        hr: 'Neuspjelo brisanje člana',
        si: 'Brisanje člana ni uspelo',
        de: 'Fehler beim Löschen des Mitglieds',
        sk: 'Nepodarilo sa odstrániť člena tímu',
        es: 'No se pudo eliminar al miembro',
        ru: 'Не удалось удалить участника',
        rs: 'Neuspešno brisanje člana',
        ua: 'Не вдалося видалити учасника',
        no: 'Teammedlemmets sletting mislyktes',
        it: 'Non è stato possibile eliminare un membro del team',
        se: 'Det gick inte att radera teammedlemmen',
    },
    teamPlanUsageLeft: {
        us: 'Remaining subscription balance',
        cz: 'V rámci předplatného zbývá',
        pl: 'W ramach subskrypcji pozostało',
        hr: 'Preostalo stanje pretplate',
        si: 'Preostalo stanje naročnine',
        de: 'Verbleibendes Abonnement',
        sk: 'V rámci predplatného zostáva',
        es: 'Saldo restante de la suscripción',
        ru: 'Остаток подписки',
        rs: 'Iznos preostale pretplate',
        ua: 'Залишок коштів на підписці',
        no: 'Gjenværende abonnementssaldo',
        it: "C'è ancora tempo per l'abbonamento",
        se: 'Inom prenumerationen återstår',
    },
    audioDelay: {
        us: 'Audio delay',
        cz: 'Zpoždění audia',
        pl: 'Opóźnienie audio',
        hr: 'Kašnjenje zvuka',
        si: 'Zakasnitev zvoka',
        de: 'Audio-Verzögerung',
        sk: 'Zdržanie audia',
        es: 'Retraso de audio',
        ru: 'Задержка звука',
        rs: 'Kašnjenje zvuka',
        ua: 'Затримка звуку',
        no: 'Lydforsinkelse',
        it: 'Ritardo audio',
        se: 'Försening av ljud',
    },
    audioDelayBy: {
        us: 'Delay audio by',
        cz: 'Zpozdit audio o',
        pl: 'Opóźnij audio o',
        hr: 'Odgodi zvuk za',
        si: 'Zakasnitev zvoka za',
        de: 'Audio verzögern  um',
        sk: 'Zdržať audio o',
        es: 'Retrasar audio por',
        ru: 'Задержка звука на',
        rs: 'Odložiti zvuk za',
        ua: 'Затримати звук на',
        no: 'Forsinke lyden med',
        it: "Ritarda l'audio di",
        se: 'Försena ljudet med',
    },
    userHasUnlimitedStorage: {
        us: 'The unlimited storage is allowed',
        cz: 'Tým má povoleno neomezené uložiště',
        pl: 'Team ma dozwoloną nieograniczoną przestrzeń dyskową',
        hr: 'Dopuštena neograničena pohrana',
        si: 'Dovoljeno je neomejeno shranjevanje',
        de: 'Unbegrenzter Speicherplatz ist erlaubt',
        sk: 'Tím má povolené neobmedzené úložisko',
        es: 'El equipo dispone de almacenamiento ilimitado',
        ru: 'Разрешено неограниченное хранение',
        rs: 'Dozvoljena neograničena memorija',
        ua: 'Дозволено необмежене зберігання',
        no: 'Ubegrenset lagringsplass er tillatt',
        it: "Il team ha attivato l'archiviazione illimitata",
        se: 'Teamet har tillgång till obegränsat lagringsutrymme',
    },
    askForDeletion: {
        us: 'Request account deletion',
        cz: 'Požádat o smazání účtu',
        pl: 'Poproś o usunięcie konta',
        hr: 'Zatržite brisanje profila',
        si: 'Zahteva za izbris računa',
        de: 'Kontolöschung anfordern',
        sk: 'Požiadať o vymazanie účtu',
        es: 'Solicitud de eliminación de cuenta',
        ru: 'Запрос на удаление учетной записи',
        rs: 'Zahtevajte brisanje naloga',
        ua: 'Подати запит на видалення акаунта',
        no: 'Be om sletting av kontoen',
        it: "Richiesta di eliminazione dell'account",
        se: 'Begär att kontot ska raderas',
    },
    deleteSelfLink: {
        us: 'https://www.beey.io/en/deletemyaccount/?beeyuser=',
        cz: 'https://www.beey.io/cs/deletemyaccount/?beeyuser=',
        pl: 'https://www.beey.io/en/deletemyaccount/?beeyuser=',
        hr: 'https://www.beey.io/en/deletemyaccount/?beeyuser=',
        si: 'https://www.beey.io/en/deletemyaccount/?beeyuser=',
        de: 'https://www.beey.io/en/deletemyaccount/?beeyuser=',
        sk: 'https://www.beey.io/en/deletemyaccount/?beeyuser=',
        es: 'https://www.beey.io/en/deletemyaccount/?beeyuser=',
        ru: 'https://www.beey.io/en/deletemyaccount/?beeyuser=',
        rs: 'https://www.beey.io/en/deletemyaccount/?beeyuser=',
        ua: 'https://www.beey.io/en/deletemyaccount/?beeyuser=',
        no: 'https://www.beey.io/en/deletemyaccount/?beeyuser=',
        it: 'https://www.beey.io/en/deletemyaccount/?beeyuser=',
        se: 'https://www.beey.io/en/deletemyaccount/?beeyuser=',
    },
    deleteAccount: {
        us: 'Delete account',
        cz: 'Smazat účet',
        pl: 'Usuń konto',
        hr: 'Izbrišite profil',
        si: 'Izbriši račun',
        de: 'Konto löschen',
        sk: 'Zmazať účet',
        es: 'Eliminar cuenta',
        ru: 'Удалить аккаунт',
        rs: 'Obriši nalog',
        ua: 'Видалити акаунт',
        no: 'Slette kontoen',
        it: "Eliminare l'account",
        se: 'Radera kontot',
    },
    max2chars: {
        us: 'Maximum two characters',
        cz: 'Zadejte nejvýše dva znaky',
        pl: 'Wprowadź maksymalnie dwa znaki',
        hr: 'Maksimalno dva znaka',
        si: 'Največ dva znaka',
        de: 'Maximal zwei Zeichen',
        sk: 'Zadajte najviac dva znaky',
        es: 'Máximo dos caracteres',
        ru: 'Не более двух символов',
        rs: 'Najviše dva karaktera',
        ua: 'Максимум два символи',
        no: 'Oppgi maksimum to tegn',
        it: 'Immettere fino a due caratteri',
        se: 'Ange högst två tecken',
    },
    notEnoughStorageLong: {
        us: 'You do not have enough storage. Delete some of your projects or upgrade to another plan.',
        cz: 'Nemáte dostatek volného úložitě. Smažte některé své projekty nebo si kupte předplatné s větším úložištěm.',
        pl: 'Nie masz wystarczająco dużo wolnego miejsca. Usuń niektóre ze swoich projektów lub wykup subskrypcję z większą przestrzenią dyskową.',
        hr: 'Nemate dovoljno prostora za pohranu. Izbrišite neke projekte ili napravite nadogradnju na drugi plan.',
        si: 'Nimate dovolj prostora za shranjevanje.',
        de: 'Sie haben nicht genug Speicherplatz. Löschen Sie einige Ihrer Projekte oder steigen Sie auf einen anderen Plan um.',
        sk: 'Nemáte dostatok voľného úložiska. Zmažte niektoré svoje projekty alebo si kúpte predplatné s väčším úložiskom.',
        es: 'No tienes suficiente espacio libre. Elimina algunos de tus proyectos o compra una suscripción con más almacenamiento.',
        ru: 'У вас недостаточно места для хранения. Удалите некоторые из ваших проектов или перейдите на другой тарифный план.',
        rs: 'Nemate dovoljno memorijskog prostora. Obrišite neke od vaših projekata ili nadogradite na drugi plan.',
        ua: 'У вас недостатньо місця у сховищі. Видаліть деякі проекти або перейдіть на інший тарифний план.',
        no: 'Du har ikke nok lagringsplass. Slett noen av prosjektene dine, eller oppgrader til et annet abonnement.',
        it: 'Lo spazio di archiviazione gratuito non è sufficiente. Elimina alcuni dei tuoi progetti o acquista un abbonamento con più spazio di archiviazione.',
        se: 'Du har inte tillräckligt med lagringsutrymme. Vänligen radera några av projekten eller köp en prenumeration med större lagringsutrymme.',
    },
    unlimitedTeamSize: {
        us: 'Unlimited team size',
        cz: 'Nemezený počet členů',
        pl: 'Nieograniczona ilość czlonków',
        hr: 'Neograničena veličina tima',
        si: 'Neomejena velikost ekipe',
        de: 'Unbegrenzte Teamgröße',
        sk: 'Neobmedzený počet členov',
        es: 'Tamaño de equipo ilimitado',
        ru: 'Неограниченный размер команды',
        rs: 'Neograničena veličina tima',
        ua: 'Необмежений розмір команди',
        no: 'Ubegrenset antall medlemmer',
        it: 'Numero illimitato di membri',
        se: 'Obegränsat antal medlemmar',
    },
    requiredField: {
        us: 'This field is required for billing.',
        cz: 'Toto pole je povinné pro fakturaci.',
        pl: 'Pole wymagane do rozliczeń.',
        hr: 'Ovo polje je obavezno za naplatu.',
        si: 'To polje je obvezno za obračun.',
        de: 'Dieses Feld ist für die Rechnungslegung erforderlich.',
        sk: 'Toto pole je povinné pre fakturáciu.',
        es: 'Este campo es obligatorio para la facturación.',
        ru: 'Это поле необходимо для выставления счетов.',
        rs: 'Ovo polje je obavezno za obračun.',
        ua: "Це поле обов'язкове для виставлення рахунку.",
        no: 'Dette feltet er påkrevd for fakturering.',
        it: 'Questo campo è necessario per la fatturazione.',
        se: 'Detta fält är obligatoriskt för fakturering.',
    },
    invalidFormat: {
        us: 'Invalid format.',
        cz: 'Nesprávný formát.',
        pl: 'Błędny format.',
        hr: 'Nevažeći format.',
        si: 'Neveljaven format.',
        de: 'Ungültiges Format.',
        sk: 'Nesprávný formát.',
        es: 'Formato inválido.',
        ru: 'Неверный формат.',
        rs: 'Nevažeći format.',
        ua: 'Неправильний формат.',
        no: 'Ugyldig format.',
        it: 'Formato errato.',
        se: 'Ogiltigt format.',
    },
    duplicateTag: {
        us: 'This tag already exists',
        cz: 'Tento štítek již existuje',
        pl: 'Ten tag już istnieje',
        hr: 'Ova oznaka već postoji',
        si: 'Ta oznaka že obstaja',
        de: 'Dieser Tag existiert bereits',
        sk: 'Tento štítok už existuje',
        es: 'Esta etiqueta ya existe',
        ru: 'Этот тег уже существует',
        rs: 'Ova oznaka već postoji',
        ua: 'Цей тег вже існує',
        no: 'Denne etiketten finnes allerede',
        it: 'Questa etichetta già esiste',
        se: 'Denna tagg finns redan',
    },
    createPsw: {
        us: 'Create a new password',
        cz: 'Vytvořit nové heslo',
        pl: 'Utwórz nowe hasło',
        hr: 'Kreirajte novu lozinku',
        si: 'Ustvari novo geslo',
        de: 'Neues Passwort erstellen',
        sk: 'Vytvoriť nové heslo',
        es: 'Crea una nueva contraseña',
        ru: 'Создайте новый пароль',
        rs: 'Napravite novu lozinku',
        ua: 'Створити новий пароль',
        no: 'Opprette et nytt passord',
        it: 'Creare una nuova password',
        se: 'Skapa nytt lösenord',
    },
    pleaseLanguage: {
        us: 'Please select a language.',
        cz: 'Vyberte prosím jazyk.',
        pl: 'Wybierz język.',
        hr: 'Molimo odaberite jezik.',
        si: 'Izberite jezik.',
        de: 'Bitte Sprache wählen.',
        sk: 'Vyberte prosím jazyk.',
        es: 'Por favor, selecciona un idioma.',
        ru: 'Пожалуйста, выберите язык.',
        rs: 'Molimo izaberite jezik.',
        ua: 'Будь ласка, виберіть мову.',
        no: 'Vennligst velg et språk.',
        it: 'Selezionare la lingua, per favore.',
        se: 'Vänligen välj språk.',
    },
    memberCreated: {
        us: 'Team member created successfully',
        cz: 'Nový člen týmu byl úspěšně vytvořen',
        pl: 'Pomyślnie utworzono nowego członka zespołu',
        hr: 'Član tima uspješno kreiran',
        si: 'Član ekipe je uspešno ustvarjen',
        de: 'Teammitglied erfolgreich erstellt',
        sk: 'Nový člen tímu bol úspešne vytvorený',
        es: 'Miembro del equipo creado con éxito',
        ru: 'Член команды успешно создан',
        rs: 'Član tima je uspešno kreiran',
        ua: 'Учасника команди успішно створено',
        no: 'Opprettelse av teammedlem vellykket',
        it: 'È stato creato un nuovo membro del team',
        se: 'En ny medlem har skapats',
    },
    memberDeleted: {
        us: 'Team member deleted successfully',
        cz: 'Člen týmu byl úspěšně smazán',
        pl: 'Pomyślnie usunięto członka zespołu',
        hr: 'Član tima uspješno obrisan',
        si: 'Član ekipe je uspešno izbrisan',
        de: 'Teammitglied erfolgreich gelöscht',
        sk: 'Člen tímu bol úspešne zmazaný',
        es: 'Miembro del equipo eliminado con éxito',
        ru: 'Член команды успешно удален',
        rs: 'Član tima je uspešno obrisan',
        ua: 'Учасника команди успішно видалено',
        no: 'Sletting av teammedlemet vellykket',
        it: 'Il membro del team è stato eliminato con successo',
        se: 'Medlemmen har raderats',
    },
    tagAddedSuccessfully: {
        us: 'Tag added successfully',
        cz: 'Štítek byl úspěšně přidán',
        pl: 'Tag dodany pomyślnie',
        hr: 'Oznaka uspješno dodana',
        si: 'Oznaka je uspešno dodana',
        de: 'Tag erfolgreich hinzugefügt',
        sk: 'Štítok bol úspešne pridaný',
        es: 'Etiqueta añadida con éxito',
        ru: 'Тег успешно добавлен',
        rs: 'Oznaka je uspešno dodata',
        ua: 'Тег успішно додано',
        no: 'Etiketten ble lagt til',
        it: "L'etichetta è stata aggiunta con successo",
        se: 'Taggen har skapats',
    },
    failedToAddTag: {
        us: 'Tag creation failed',
        cz: 'Vytvoření štítku se nezdařilo',
        pl: 'Usunięcie tagu nie powiodło się',
        hr: 'Kreiranje oznake nije uspjelo',
        si: 'Ustvarjanje oznake ni uspelo',
        de: 'Erstellung des Tags fehlgeschlagen',
        sk: 'Vytvorenie štítku zlyhalo',
        es: 'Error al crear la etiqueta',
        ru: 'Не удалось создать тег',
        rs: 'Kreiranje oznake nije uspelo',
        ua: 'Не вдалося створити тег',
        no: 'Opprettelse av etiketten mislyktes',
        it: "La creazione dell'etichetta non è riuscita",
        se: 'Det gick inte att skapa taggen',
    },
    tagEdittedSuccessfully: {
        us: 'Tag editted successfully',
        cz: 'Štítek byl úspěšně upraven',
        pl: 'Edycja tagu powiodła się',
        hr: 'Uspješna izmjena oznake',
        si: 'Oznaka je uspešno urejena',
        de: 'Tag erfolgreich bearbeitet',
        sk: 'Štítok bol úspešne upravený',
        es: 'Etiqueta editada con éxito',
        ru: 'Редактирование тега успешно',
        rs: 'Izmena oznake je uspela',
        ua: 'Тег успішно відредаговано',
        no: 'Etiketten ble regigert',
        it: "L'etichetta è stata modificata con successo",
        se: 'Taggen har editerats',
    },
    failedToEditTag: {
        us: 'Tag edit failed',
        cz: 'Úprava štítku se nezdařila',
        pl: 'Edycja tagu nie powiodła się',
        hr: 'Izmjena oznake nije uspjela',
        si: 'Urejanje oznake ni uspelo',
        de: 'Bearbeitung des Tags fehlgeschlagen',
        sk: 'Úprava štítku zlyhala',
        es: 'Error al editar la etiqueta',
        ru: 'Редактирование тега не удалось',
        rs: 'Izmena oznake nije uspela',
        ua: 'Помилка редагування тегу',
        no: 'Redigering av etiketten mislyktes',
        it: 'Modifica etichetta fallita',
        se: 'Det gick inte att editera taggen',
    },
    tagDeletedSuccessfully: {
        us: 'Tag deleted successfully',
        cz: 'Štítek byl úspěšně smazán',
        pl: 'Tag został pomyślnie usunięty',
        hr: 'Oznaka uspješno obrisana',
        si: 'Oznaka je uspešno izbrisana',
        de: 'Tag erfolgreich gelöscht',
        sk: 'Štítok bol úspešne zmazaný',
        es: 'Etiqueta eliminada con éxito',
        ru: 'Успешное удаление тега',
        rs: 'Oznaka je uspešno obrisana',
        ua: 'Тег успішно видалено',
        no: 'Etiketten ble slettet',
        it: "L'etichetta è stata eliminata con successo",
        se: 'Taggen har raderats',
    },
    failedToDeleteTag: {
        us: 'Tag deletion failed',
        cz: 'Smazání štítku se nezdařilo',
        pl: 'Usunięcie tagu nie powiodło się',
        hr: 'Brisanje oznake nije uspjelo',
        si: 'Brisanje oznake ni uspelo',
        de: 'Löschung des Tags fehlgeschlagen',
        sk: 'Zmazanie štítku zlyhalo',
        es: 'Error al eliminar la etiqueta',
        ru: 'Удаление тега не удалось',
        rs: 'Brisanje oznake nije uspelo',
        ua: 'Видалення тегу не вдалося',
        no: 'Sletting av etiketten mislyktes',
        it: "L'eliminazione di un'etichetta non è riuscita",
        se: 'Det gick inte att radera taggen',
    },
    nameOrCompany: {
        us: 'Full name / Company name',
        cz: 'Jméno a příjmení / Název firmy',
        pl: 'Imię i nazwisko / Nazwa firmy',
        hr: 'Ime i prezime/Ime tvrtke',
        si: 'Ime in priimek/Ime podjetja',
        de: 'Vollständiger Name / Firmenname',
        sk: 'Meno a priezvisko / Názov firmy',
        es: 'Nombre completo / Nombre de la empresa',
        ru: 'Полное имя / название компании',
        rs: 'Puno ime / Naziv kompanije',
        ua: "Ім'я та Фамілія / Назва компанії",
        no: 'Fullt navn / Firmanavn',
        it: 'Nome e cognome / Nome della società',
        se: 'Förnamn och efternamn / Företagets namn',
    },
    invalidCompNumber: {
        us: 'Please insert valid number',
        cz: 'Zadejte prosím správné číslo',
        pl: 'Wprowadź prawidłowy numer',
        hr: 'Molimo unesite važeći broj',
        si: 'Vnesite veljavno številko',
        de: 'Bitte gültige Nummer eingeben',
        sk: 'Zadajte prosím správne číslo',
        es: 'Por favor, introduce un número válido',
        ru: 'Пожалуйста, введите действительный номер',
        rs: 'Molimo unesite važeći broj',
        ua: 'Будь ласка, введіть правильний номер',
        no: 'Vennligst sett inn gyldig nummer',
        it: 'Inserire il numero corretto',
        se: 'Vänligen ange ett giltigt nummer',
    },
    otherDetails: {
        us: 'More details',
        cz: 'Další údaje',
        pl: 'Inne informacje',
        hr: 'Više detalja',
        si: 'Več podrobnosti',
        de: 'Mehr Details',
        sk: 'Ďalšie údaje',
        es: 'Más detalles',
        ru: 'Подробнее',
        rs: 'Više detalja',
        ua: 'Більш детальна інформація',
        no: 'Mer informasjon',
        it: 'Altre informazioni',
        se: 'Fler uppgifter',
    },
    showAsOption: {
        us: 'Show as option in project panel',
        cz: 'Zobrazit v nabídce u projektu',
        pl: 'Wyświetl w menu projektu',
        hr: 'Pokaži kao opciju na ploči projekta',
        si: 'Prikaži kot možnost na plošči projekta',
        de: 'Als Option im Projektfenster anzeigen',
        sk: 'Zobraziť v ponuke pri projekte',
        es: 'Mostrar como opción en el panel del proyecto',
        ru: 'Показать как опцию в панели проекта',
        rs: 'Prikaži kao opciju u panelu projekta',
        ua: 'Показати як опцію в панелі проекту',
        no: 'Vise som alternativ i prosjektpanelet',
        it: 'Visualizzazione nel menu del progetto',
        se: 'Visa som alternativ i projektpanelen',
    },
    pinnedOnProject: {
        us: 'Showed on project',
        cz: 'Zobrazen u projektu',
        pl: 'Wyświelanie obok projektu',
        hr: 'Prikazano na projektu',
        si: 'Prikazano na projektu',
        de: 'Im Projekt angezeigt',
        sk: 'Zobrazený pri projekte',
        es: 'Mostrado en el proyecto',
        ru: 'Показано в проекте',
        rs: 'Prikazano u projektu',
        ua: 'Показано в проекті',
        no: 'Vises i prosjektet',
        it: 'Visualizzato accanto al progetto',
        se: 'Visas i projektet',
    },
    showOnProject: {
        us: 'Show on project',
        cz: 'Zobrazit u projektu',
        pl: 'Wyświetl obok projektu',
        hr: 'Prikaži na projektu',
        si: 'Prikaži na projektu',
        de: 'Im Projekt anzeigen',
        sk: 'Zobraziť pri projekte',
        es: 'Mostrar en el proyecto',
        ru: 'Показать в проекте',
        rs: 'Prikazati u projektu',
        ua: 'Показати в проекті',
        no: 'Vise i prosjektet',
        it: 'Mostra nel progetto',
        se: 'Visa i projektet',
    },
    transferOwnership: {
        us: 'Transfer team ownership to another team member',
        cz: 'Převést správcovství týmu na jiného člena týmu',
        pl: 'Przenieś funkcję administratora na innego członka zespołu',
        hr: 'Prenesi vlasništvo tima na drugog člana',
        si: 'Prenos lastništva ekipe na drugega člana ekipe',
        de: 'Übertragung der Teameigentümerschaft auf ein anderes Teammitglied',
        sk: 'Previesť správcovstvo tímu na iného člena tímu',
        es: 'Transferir la propiedad del equipo a otro miembro del equipo',
        ru: 'Передать право владения командой другому члену команды',
        rs: 'Prenesite vlasništvo nad timom na drugog člana tima',
        ua: 'Передати право власності на проект іншому члену команди',
        no: 'Overføre teameierskap til et annet teammedlem',
        it: "Trasferire l'amministrazione del team a un altro membro del team",
        se: 'Överför teamets ägande till en annan teammedlem',
    },
    transfer: {
        us: 'Transfer',
        cz: 'Převést',
        pl: 'Przenieś',
        hr: 'Prenesi',
        si: 'Prenos',
        de: 'Übertragen',
        sk: 'Previesť',
        es: 'Transferir',
        ru: 'Передать',
        rs: 'Prenesite',
        ua: 'Передати',
        no: 'Overføre',
        it: 'Trasferire',
        se: 'Överför',
    },
    confirmTransferOwner: {
        us: 'This action is irreversible. You will lose all team owner privileges.',
        cz: 'Tato akce je nevratná - ztratíte všechna práva správce týmu.',
        pl: 'Ta czynność jest nieodwracalna - użytkownik traci wszystkie uprawnienia administratora zespołu.',
        hr: 'Ova radnja je nepovratna. Izgubit ćete sve privilegije vlasnika tima.',
        si: 'To dejanje je nepovratno. Izgubili boste vse pravice lastnika ekipe.',
        de: 'Diese Handlung ist unumkehrbar. Sie verlieren alle Privilegien als Teameigentümer.',
        sk: 'Táto akcia je nevratná - stratíte všetky práva správcu tímu.',
        es: 'Esta acción es irreversible. Perderás todos tus derechos como administrador del equipo.',
        ru: 'Это действие необратимо. Вы потеряете все привилегии владельца команды.',
        rs: 'Ova akcija je nepovratna. Izgubićete sve privilegije vlasnika tima.',
        ua: 'Ця дія є незворотною. Ви втратите всі привілеї власника команди.',
        no: 'Denne handlingen er irreversibel. Du vil miste alle teameierrettigheter.',
        it: 'Questa azione è irreversibile: si perdono tutti i diritti di amministratore del team.',
        se: 'Detta steg är oåterkalleligt - du kommer att förlora alla administratörens rättigheter.',
    },
    confirmTransferAdmin: {
        us: 'The team owner will lose all team owner privileges.',
        cz: 'Správce týmu ztratí všechna práva správce týmu.',
        pl: 'Użytkownik straci wszystkie prawa administratora.',
        hr: 'Vlasnik tima izgubit će sve privilegije.',
        si: 'Lastnik ekipe bo izgubil vse pravice lastnika ekipe.',
        de: 'Der Teameigentümer verliert alle Privilegien als Teameigentümer.',
        sk: 'Správca tímu stratí všetky práva správcu tímu.',
        es: 'El propietario del equipo perderá todos los privilegios como propietario del equipo.',
        ru: 'Владелец команды потеряет все привилегии владельца команды.',
        rs: 'Vlasnik tima će izgubiti sve privilegije vlasnika tima.',
        ua: 'Власник команди втратить всі привілеї власника команди.',
        no: 'Teameieren mister alle teameierrettigheter.',
        it: "L'amministratore del team perderà tutti i diritti di amministratore del team.",
        se: 'Teamets administratör kommer att förlora alla administratörens rättigheter.',
    },
    chooseMember: {
        us: 'Choose a team member',
        cz: 'Vyberte člena týmu',
        pl: 'Wybierz członka zespołu',
        hr: 'Odaberi člana tima',
        si: 'Izberite člana ekipe',
        de: 'Ein Teammitglied auswählen',
        sk: 'Vyberte člena tímu',
        es: 'Elige un miembro del equipo',
        ru: 'Выбрать члена команды',
        rs: 'Izaberite člana tima',
        ua: 'Виберіть члена команди',
        no: 'Velg et teammedlem',
        it: 'Selezionare un membro del team',
        se: 'Välj teammedlem',
    },
    resolve: {
        us: 'Resolve',
        cz: 'Vyřešit',
        pl: 'Rozwiąż',
        hr: 'Riješite',
        si: 'Odpravi',
        de: 'Beheben',
        sk: 'Vyriešiť',
        es: 'Resolver',
        ru: 'Разрешить',
        rs: 'Rešiti',
        ua: 'Вирішити',
        no: 'Løse',
        it: 'Risolvere',
        se: 'Lös',
    },
    decline: {
        us: 'Decline',
        cz: 'Odmítnout',
        pl: 'Odrzuć',
        hr: 'Odbacite',
        si: 'Zavrni',
        de: 'Ablehnen',
        sk: 'Odmietnuť',
        es: 'Rechazar',
        ru: 'Отказаться',
        rs: 'Odbiti',
        ua: 'Відхилити',
        no: 'Avslå',
        it: 'Rifiutare',
        se: 'Avvisa',
    },
    toCheck: {
        us: 'To check',
        cz: 'Ke kontrole',
        pl: 'Sprawdź',
        hr: 'Za provjeriti',
        si: 'Za preverjanje',
        de: 'Überprüfen',
        sk: 'Ku kontrole',
        es: 'Para revisar',
        ru: 'Проверить',
        rs: 'Proveriti',
        ua: 'Перевірити',
        no: 'For å kontrollere',
        it: 'Per controllare',
        se: 'Att kontrollera',
    },
    everythingResolved: {
        us: 'Everything resolved',
        cz: 'Všechno vyřešeno',
        pl: 'Wszystko rozwiązane',
        hr: 'Sve riješeno',
        si: 'Vse je rešeno',
        de: 'Alles behoben',
        sk: 'Všetko vyriešené',
        es: 'Todo resuelto',
        ru: 'Все решено',
        rs: 'Sve rešeno',
        ua: 'Все вирішено',
        no: 'Alt løst',
        it: 'Tutto risolto',
        se: 'Allt löst',
    },
    nothingToCheck: {
        us: 'Nothing to check',
        cz: 'Nic ke kontrole',
        pl: 'Nic do sprawdzenia',
        hr: 'Nije potrebno provjeriti',
        si: 'Ničesar za preveriti',
        de: 'Nichts zu prüfen',
        sk: 'Nič ku kontrole',
        es: 'Nada para revisar',
        ru: 'Нечего проверять',
        rs: 'Nema ničega za proveru',
        ua: 'Нічого перевіряти',
        no: 'Ingenting å kontrollere',
        it: 'Niente da controllare',
        se: 'Inget att kontrollera',
    },
    notResolved: {
        us: 'Not resolved',
        cz: 'Nevyřešené',
        pl: 'Nierozwiązane',
        hr: 'Nije riješeno',
        si: 'Ni rešeno',
        de: 'Nicht behoben',
        sk: 'Nevyriešené',
        es: 'No resuelto',
        ru: 'Не решено',
        rs: 'Nije rešeno',
        ua: 'Не вирішено',
        no: 'Ikke løst',
        it: 'Non risolto',
        se: 'Olöst',
    },
    cannotIgnore: {
        us: 'Cannot be declined',
        cz: 'Nelze zamítnout',
        pl: 'Nie można odrzucić',
        hr: 'Nije moguće odbaciti',
        si: 'Ni mogoče zavrniti',
        de: 'Kann nicht abgelehnt werden',
        sk: 'Nie je možné zamietnuť',
        es: 'No se puede rechazar',
        ru: 'Не может быть отклонено',
        rs: 'Ne može se odbiti',
        ua: 'Неможливо відхилити',
        no: 'Kan ikke avslås',
        it: 'Non può essere rifiutato',
        se: 'Kan inte avvisas',
    },
    declined: {
        us: 'Declined',
        cz: 'Zamítnuto',
        pl: 'Odrzucono',
        hr: 'Odbijeno',
        si: 'Zavrnjeno',
        de: 'Abgelehnt',
        sk: 'Zamietnuté',
        es: 'Rechazado',
        ru: 'Отклонено',
        rs: 'Odbijeno',
        ua: 'Відхилено',
        no: 'Avslått',
        it: 'Rifiutato',
        se: 'Avvisat',
    },
    resolved: {
        us: 'Resolved',
        cz: 'Vyřešeno',
        pl: 'Rozwiązano',
        hr: 'Riješeno',
        si: 'Odpravljeno',
        de: 'Behoben',
        sk: 'Vyriešené',
        es: 'Resuelto',
        ru: 'Решено',
        rs: 'Rešeno',
        ua: 'Вирішено',
        no: 'Løst',
        it: 'Risolto',
        se: 'Löst',
    },
    problemIgnoreDeclined: {
        us: 'This problem cannot be declined. Please resolve it.',
        cz: 'Problém nelze zamítnout. Vyřeště ho prosím.',
        pl: 'Nie można odrzucić problemu. Proszę go rozwiązać.',
        hr: 'Ovaj problem nije moguće odbaciti. Molimo riješite.',
        si: 'Te težave ni mogoče zavrniti. Prosimo, razrešite to.',
        de: 'Dieses Problem kann nicht zurückgewiesen werden. Bitte beheben Sie es.',
        sk: 'Problém nemožno zamietnuť. Vyriešte ho prosím.',
        es: 'Este problema no puede ser rechazado. Por favor, resuélvelo.',
        ru: 'Эта проблема не может быть отклонена. Пожалуйста, решите ее.',
        rs: 'Ovaj problem se ne može odbiti. Molimo vas da ga rešite.',
        ua: 'Цю проблему не можна відхилити. Будь ласка, вирішіть її.',
        no: 'Dette problemet kan ikke avslås. Vennligst løs det.',
        it: 'Il problema non può essere rifiutato. Si prega di risolverlo.',
        se: 'Problemet kan inte avvisas. Vänligen lös det.',
    },
    problemIgnoreFail: {
        us: 'The problem could not be declined. Please try again.',
        cz: 'Problém se nepovedlo zamítnout. Zkuste to prosím znovu.',
        pl: 'Problemu nie udało się odrzucić. Proszę spróbować ponownie.',
        hr: 'Ovaj problem nije moguće odbaciti. Molimo pokušajte ponovno.',
        si: 'Težave ni bilo mogoče zavrniti. Poskusite znova.',
        de: 'Das Problem konnte nicht zurückgewiesen werden. Bitte versuchen Sie es erneut.',
        sk: 'Problém sa nepodarilo zamietnuť. Skúste to prosím znova.',
        es: 'No se pudo rechazar el problema. Por favor, inténtalo de nuevo.',
        ru: 'Проблема не может быть отклонена. Пожалуйста, попробуйте еще раз.',
        rs: 'Ovaj problem se ne može odbiti. Molimo pokušajte ponovo.',
        ua: 'Проблему не вдалося відхилити. Будь ласка, спробуйте ще раз.',
        no: 'Problemet kunne ikke avslås. Vennligst prøv igjen.',
        it: 'Tornare al problema non risolto. Si prega di riprovarlo, per favore.',
        se: 'Det gick inte att avvisa problemet. Vänligen prova igen.',
    },
    backToUnresolved: {
        us: 'Back to unresolved',
        cz: 'Vrátit k nevyřešeným',
        pl: 'Powrót do nierozwiązanych',
        hr: 'Vrati na neriješeno',
        si: 'Nazaj na nerešeno',
        de: 'Zurück zu ungelöster Version',
        sk: 'Vrátiť k nevyriešeným',
        es: 'Volver a no resuelto',
        ru: 'Назад к нерешенным проблемам',
        rs: 'Nazad na nerešeno',
        ua: 'Повернутися до невирішених',
        no: 'Tilbake til uløst',
        it: "Tornare all'irrisolto",
        se: 'Tillbaka till olösta',
    },
    unshare: {
        us: 'Unshare',
        cz: 'Zrušit sdílení',
    },
    unshareProject: {
        us: 'Unshare project?',
        cz: 'Zrušit sdílení projektu?',
    },
    unshareAlert: {
        us: 'The project will be unshared from all users.',
        cz: 'Projekt již nebude sdílen s žádnými dalšími uživateli.',
    },
    transferMember: {
        us: 'Transfer a member to another team',
        cz: 'Přesunout člena týmu do jiného týmu',
        pl: 'Przesuń członka do innego zespołu',
        hr: 'Prebacite na člana tima',
        si: 'Prenos člana v drugo ekipo',
        de: 'Mitglied zu einem anderen Team hinzufügen',
        sk: 'Presunúť člena tímu do iného tímu',
        es: 'Transferir a un miembro a otro equipo',
        ru: 'Перевод участника в другую команду',
        rs: 'Prebacite člana u drugi tim',
        ua: 'Перевести учасника до іншої команди',
        no: 'Overføre et medlem til et annet team',
        it: 'Spostare un membro del team in un altro team',
        se: 'Överför en teammedlem till en annan team',
    },
    confirmMemberTransfer: {
        us: 'This user\'s projects will be preserved. Reset the user\'s credit before transfer to another team.',
        cz: 'Projekty tohoto uživatele budou zachovány. Před přesunem do jiného týmu vynulujte jeho kredit.',
        pl: 'Projekty tego użytkownika zostaną zachowane. Zresetuj kredyt przed przeniesieniem do innego zespołu.',
        hr: 'Projekti ovog korisnika bit će sačuvani. Osvježite kredit korisnika prije prijenosa na drugi tim.',
        si: 'Projekti tega uporabnika bodo ohranjeni. Ponastavite kredit uporabnika pred prenosom v drugo ekipo.',
        de: 'Die Projekte dieses Benutzers bleiben erhalten. Setzen Sie das Guthaben des Benutzers zurück, bevor Sie ihn zu einem anderen Team hinzufügen.',
        sk: 'Projekty tohto užívateľa budú zachované. Pred presunom do iného tímu vynulujte jeho kredit.',
        es: 'Los proyectos de este usuario se conservarán. Restablezca el crédito del usuario antes de transferirlo a otro equipo.',
        ru: 'Проекты этого пользователя будут сохранены. Перед переводом пользователя в другую команду сбросьте его кредиты.',
        rs: 'Projekti ovog korisnika će biti sačuvani. Resetujte kredit korisnika pre prebacivanja u drugi tim.',
        ua: 'Проекти цього користувача будуть збережені. Обнуліть кредит користувача перед переведенням до іншої команди.',
        no: 'Denne brukerens prosjekter vil bli bevart. Tilbakestill brukerens kreditt før overføring til et annet team.',
        it: 'I progetti di questo utente saranno conservati. Azzerare il suo credito prima di spostarlo in un altro team.',
        se: 'Denna medlems projekt kommer att bevaras. Innan du överför medlemmen vänligen annullera dennes kredit.',
    },
    insertTimeStamp: {
        us: 'Insert word time stamp',
        cz: 'Vložit časovou značku u slova',
    },
    timeStampAlready: {
        us: 'Time stamp is already inserted',
        cz: 'Časová značka je již vložena',
    },
    view: {
        us: 'View',
        cz: 'Zobrazení',
    },
};
